<div *ngIf="graphList && dashboard; else spinner" class="dashboard-wrapper">
    <div class="graph-wrapper" *ngFor="let graph of graphList" [style]="getStyle(graph)">
        <mat-card>
            <div class="graph-view-actions">
                <button mat-icon-button [matMenuTriggerFor]="graphMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #graphMenu="matMenu" xPosition="before">
                    <button mat-menu-item (click)="editGraph(graph)">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                    </button>
                    <button mat-menu-item (click)="delGraph(graph)">
                        <mat-icon>remove</mat-icon>
                        <span>Remover</span>
                    </button>
                </mat-menu>
            </div>
            <main-graph-view [graph]="graph"></main-graph-view>
        </mat-card>
    </div>
    <div class="graph-add">
        <button mat-icon-button (click)="editGraph({dashboard: dashboard})">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<ng-template #spinner>
    <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>