<form [formGroup]="form" (ngSubmit)="ngSubmit()">
    <h1 mat-dialog-title>{{ isEdit ? 'Atualizar' : 'Criar' }}</h1>

    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Nome</mat-label>
            <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field *ngIf="mapList !== undefined">
            <mat-label>Mapas</mat-label>
            <mat-select formControlName="maps" multiple [compareWith]="compareWith">
                <mat-option *ngFor="let map of mapList" [value]="map">
                    {{ map.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button type="submit">Salvar</button>
        <button mat-button [mat-dialog-close]="undefined">Fechar</button>
    </mat-dialog-actions>
</form>