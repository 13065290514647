import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { AuthenticationService } from '../auth/authentication.service';
import { User } from '../auth/user.model';
import { UserService } from '../auth/user.service';
import { Customer } from '../crm/customer.service';
import { Conversation } from '../dialog/conversation.model';
import { ChannelType } from '../dialog/generic-response.model';
import { ConversationMessage, MediaType, ResponseType, Sender } from '../dialog/message.model';
import { AssignmentsService } from '../queue/assignments.service';
import { ConclusionTagService } from '../queue/conclusion-tag.service';
import { ChangeType, QueueAssignment } from '../queue/queue-assignment.model';
import { AgentStompService } from '../stomp/agent-stomp.service';
import { Message, StompMedia, StompMessage } from '../stomp/message.model';

export interface Chat {
  assignment: QueueAssignment,
  customer: Customer;
  dialog: Message[];
  unread: number;
}

function byDate(m1: Message, m2: Message): number {
  if (m1.date < m2.date) {
    return -1;
  }
  if (m1.date > m2.date) {
    return 1;
  }
  return 0;
}

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  private chats: Chat[] = [];
  public OnLoad: Subject<boolean> = new Subject()
  public OnStart: Subject<void> = new Subject<void>()

  public OnMessage: Subject<StompMessage>;
  private assignmentsCache: QueueAssignment[] = [];
  public IsConnected: BehaviorSubject<Boolean>;
  private agentList: User[] = [];
  public Ascending: BehaviorSubject<Boolean>;

  public ByAssignment(a1: QueueAssignment, a2: QueueAssignment) {
    return (a1.update - a2.update) * (this.Ascending.value ? 1 : -1);
  }


  private chatsByAssignment(chat1: Chat, chat2: Chat): number {
    return this.ByAssignment(chat1.assignment, chat2.assignment);
  }

  constructor(
    public _assignments: AssignmentsService,
    public _tags: ConclusionTagService,
    public _stomp: AgentStompService,
    public _auth: AuthenticationService,
    protected http: HttpClient,
    public users: UserService,
    @Inject(APP_CONFIG) public config: ApiConfiguration,

  ) {
    this.Ascending = new BehaviorSubject<Boolean>(true);
    this.init();
    this.OnMessage = this._stomp.OnMessage;
    this.IsConnected = this._stomp.IsConnected;

    this.Ascending.subscribe((value) => {
      this.chats = this.chats.sort((chat1: Chat, chat2: Chat) => this.chatsByAssignment(chat1, chat2));
      this.assignmentsCache = this.assignmentsCache.sort((a1: QueueAssignment, a2: QueueAssignment) => this.ByAssignment(a1, a2)).reverse();
    });
  }

  private processConversation(conversation: Conversation): Message[] {
    return conversation.conversationMessages.map((cm: ConversationMessage): Message => {
      return {
        id: cm.idConversationMessage,
        date: new Date(cm.messageTime),
        sender: cm.sender,
        channel: conversation.channel.type,

        content: cm.content,
        contentType: cm.contentType,
        mediaType: cm.mediaType,
        agent: cm.user,
      }
    }).sort(byDate);
  }

  private retrieveConversation(id: number): Observable<Conversation> {
    return this.http.get<Conversation>(`${this.config.endpoint}/conversation/${id}`)
  }

  public retrieveHistory(customer: Customer): Observable<Conversation[]> {
    return this.http.get<Conversation[]>(`${this.config.endpoint}/api/customers/${customer.id}/history`);
  }

  public markRead(chat: Chat): void {
    this.http.post(`${this.config.endpoint}/api/chats/${chat.assignment.interaction_id}/mark`, {}).subscribe(ok => {
      chat.unread = 0;
    });
  }

  public loadHistory(chat: Chat): void {
    this.retrieveHistory(chat.customer).subscribe(retrieved => {
      let history: Message[] = [];
      for (const conversation of retrieved) {
        history = history.concat(this.processConversation(conversation));
      }
      history.sort(byDate);
      chat.dialog = history;
      this.OnLoad.next(true);
    })
  }

  public loadCustomerHistory(customer: Customer): Promise<Message[]> {
    return new Promise<Message[]>((resolve, reject) => {
      this.retrieveHistory(customer).subscribe(retrieved => {
        if (retrieved.length <= 0) {
          reject("no history for user");
          return;
        }

        let history: Message[] = [];

        for (const conversation of retrieved) {
          history = history.concat(this.processConversation(conversation));
        }
        history.sort(byDate);
        resolve(history);
      }, err => { reject("Error retrieving history") });
    });
  }

  public loadCustomerBotHistory(customer: Customer, botId: number): Promise<Message[]> {
    return new Promise<Message[]>((resolve, reject) => {
      this.retrieveHistory(customer).subscribe(retrieved => {
        if (retrieved.length <= 0) {
          reject("no history for user");
          return;
        }

        let history: Message[] = [];

        for (const conversation of retrieved) {
          if (conversation.bot.id == botId) {
            history = history.concat(this.processConversation(conversation));
          }
        }
        history.sort(byDate);
        resolve(history);
      }, err => { reject("Error retrieving history") });
    });
  }


  private processAssignment(assignment: QueueAssignment) {
    let chat = this.chats.find(value => value.assignment && value.assignment.id == assignment.id);
    if (chat == null) {
      this.retrieveConversation(assignment.conversation).subscribe(conversation => {
        this.chats.push({
          unread: conversation.hasUnreadMessages ? 1 : 0,
          assignment: assignment,
          customer: assignment.customer,
          dialog: this.processConversation(conversation),
        });
        this.chats.sort((chat1: Chat, chat2: Chat) => this.chatsByAssignment(chat1, chat2));
        this.OnLoad.next(true);
      });
    }
  }

  init(): void {
    this.users.list().subscribe(userList => {
      this.agentList = userList;
    });
    this._assignments.list().subscribe(assignments => {
      this.assignmentsCache = assignments.sort(
        (a1: QueueAssignment, a2: QueueAssignment) => this.ByAssignment(a1, a2),
      ).filter(assignment => this.isVisible(assignment)).reverse();
      this.OnLoad.next(true);
      this.OnStart.next();
    });

    this._stomp.OnMessage.subscribe(message => {
      console.log("Update chats");
      this.chats.filter(chat => message.customerDTO && chat.customer.id == message.customerDTO.idCustomer).forEach(chat => {
        if (message.media) {
          message.media.forEach(media => {
            chat.dialog.push({
              id: null,
              date: new Date(),
              sender: Sender.USER,
              content: media.url,
              contentType: ResponseType.MEDIA,
              mediaType: MediaType.UNKNOWN,
              channel: (message.channel) ? message.channel.type : ChannelType.CHAT,
            });
            chat.unread++;
          });
        }
        if (message.responses) {
          message.responses.forEach(response => {
            console.log(response);
          })
        }
        if (message.query && message.query.length > 0) {
          chat.dialog.push({
            id: null,
            date: new Date(),
            sender: Sender.USER,
            channel: (message.channel) ? message.channel.type : ChannelType.CHAT,

            content: message.query,
            contentType: ResponseType.TEXT,
            mediaType: MediaType.UNKNOWN,
          });
          chat.unread++;
        }
      });
    });

    this._stomp.OnChange.subscribe(change => {
      let chat = this.chats.find(chat => chat.assignment && change.assignment == chat.assignment.id);

      if (chat) {
        console.log("Update assignment status");
        chat.assignment.status = change.type;
        if (change.agent) {
          chat.assignment.agent = this.agentList.find(agent => agent.id == change.agent);
        }
      } else {
        console.log("Retrieve new assignment");
        this._assignments.retrieve(change.assignment).subscribe(assignment => {
          this.processAssignment(assignment);
        });
      }
    });
  }

  get chatList() {
    return this.chats.filter(chat => this.isVisible(chat.assignment));
  }

  sendMessage(chat: Chat, message: string) {
    if (chat.assignment == undefined) {
      return;
    }
    this._stomp.sendMessage(chat.assignment.conversation, message);
    chat.dialog.push({
      id: null,
      date: new Date(),
      sender: Sender.AGENT,
      agent: this._auth.current.value || undefined,
      content: message,
      contentType: ResponseType.TEXT,
      mediaType: MediaType.UNKNOWN,
    });
  }

  upload(chat: Chat, query: string, file: File): Promise<StompMedia> {
    let data: FormData = new FormData();
    data.set("file", file);
    data.set("query", query);
    data.set("conversation", `${chat.assignment?.conversation}`);
    return new Promise((resolve, reject) => {
      if (chat.assignment == undefined) {
        reject("conversation is undefined");
        return;
      }
      this.http.post<StompMedia>(`${this.config.endpoint}/customer/upload`, data).subscribe(media => {
        chat.dialog.push({
          id: null,
          date: new Date(),
          sender: Sender.AGENT,
          agent: this._auth.current.value || undefined,
          content: media.url,
          contentType: ResponseType.MEDIA,
          mediaType: MediaType.UNKNOWN,
        });
        this.OnLoad.next(true);
        resolve(media)
      }, err => {
        console.error("Upload error: ", err);
        reject(err);
      });
    });
  }

  public loadMoreChats(count: number) {
    console.log(`Load more chats: ${count}`);
    while (count > 0 && this.assignmentsCache.length > 0) {
      let assignment = this.assignmentsCache.pop();
      if (assignment == undefined) {
        continue;
      }
      this.processAssignment(assignment);
      count = count - 1;
      console.log(`Count: ${count}`);
    }
  }
  get outstanding() {
    return this.assignmentsCache.length;
  }
  private isVisible(assignment: QueueAssignment): boolean {
    return assignment.status == ChangeType.OPEN || assignment.status == ChangeType.WIP
  }
}
