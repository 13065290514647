import { Injectable } from '@angular/core';
import { Plan, Subscription, SubscriptionService } from 'onevoice';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from 'onevoice';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionRequestService {

  planList: Plan[] = [];
  subscriptions: Subscription[] = [];

  onPlanListChanged: BehaviorSubject<any>;
  onSubscriptionChanged: BehaviorSubject<any>;

  constructor(
    private subscriptionService: SubscriptionService,
    private authService: AuthenticationService,
  ) {
    this.onPlanListChanged = new BehaviorSubject([]);
    this.onSubscriptionChanged = new BehaviorSubject([]);
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.planLists(),
        this.listSubscriptions(),
      ]).then(([plans, subscriptions]) => {
        this.planList = plans;
        this.subscriptions = subscriptions.filter((e: any) => e.organization.id === this.authService.current.value?.id);
        resolve(null);
      }, reject);
    })
  }

  planLists(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionService.planLists().subscribe((response: Plan[]) => {
        this.planList = response;
        this.onPlanListChanged.next(this.planList);
        resolve(response);
      }, reject);
    });
  }

  listSubscriptions(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subscriptionService.list().subscribe((response: Subscription[]) => {
        this.subscriptions = response;
        this.onSubscriptionChanged.next(this.subscriptions);
        resolve(response);
      }, reject);
    });
  }
}
