import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntentForwarder } from 'app/alabia/api/intent-forwarder.service';
import { Parameter, ParameterService } from 'app/alabia/api/parameter.service';
import { ActionService, Intent, IntentService } from 'onevoice';

@Component({
    selector: 'alabia-forwarder-dialog',
    templateUrl: './forwarder-dialog.component.html',
    styleUrls: ['./forwarder-dialog.component.scss']
})
export class ForwarderDialogComponent {
    allIntents: Intent[] = [];
    allParameters: Parameter[] = [];

    constructor(
        private reference: MatDialogRef<ForwarderDialogComponent>,

        private _intentService: IntentService,
        private _actionService: ActionService,
        private _parameterService: ParameterService,

        @Inject(MAT_DIALOG_DATA) public data: IntentForwarder
    ) {
        if (!this.data.origin || !this.data.origin.bot) { return; }
        this._intentService.list(this.data.origin.bot.id).subscribe(intents => {
            this.allIntents = intents;
        });

        this._actionService.list(this.data.origin.id).subscribe(actions => {
            if (!actions || actions.length <= 0) {
                return;
            }
            actions.forEach(action => {
                this._parameterService.list(action.id).subscribe(parameters => {
                    this.allParameters = this.allParameters.concat(parameters);
                });
            });
        });
    }

    compareObjects = (a: any, b: any): boolean => {
        return a.id == b.id;
    };
}
