import { AccountForm } from './account-form';

export class CreateAccount {
    organization: string;
    slug: string;
    username: string;
    email: string;
    phone?: number;
    password: string;
    first_name: string;
    last_name: string;

    constructor(data: AccountForm) {
        this.organization = (data.organization) ? data.organization.trim() : "";
        this.slug = this.normalize(data.organization || "");
        this.username = this.normalize(data.username || "");
        this.email = (data.email) ? data.email.trim() : "";
        this.password = data.password || "";
        this.phone = data.phone;
        this.last_name = (data.lastName) ? data.lastName.trim() : "";
        this.first_name = (data.firstName) ? data.firstName.trim() : "";
    }

    normalize(str: string) {
        return str
            .normalize('NFD')
            .trim()
            .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
            .replace(/\s+/g, '-')
            .toLocaleLowerCase();
    }
}
