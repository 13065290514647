import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseModel, BaseService } from '../auth/base.service';
import { MachineMap } from './machine-map.service';

export interface MachineTask extends BaseModel<number> {
  id: number;
  map: MachineMap;
  name: string;
  uniqueId: string;
  description: string;
  type:string;
}

@Injectable({
  providedIn: 'root'
})
export class MachineTaskService extends BaseService<MachineTask, number> {
  protected path: string = `${this.config.endpoint}` + "/hw/tasks";

  public listByMap(map : MachineMap): Observable<MachineTask[]> {

    return this.http.post<MachineTask[]>(`${this.path}/map`,map);
   }
   public listManual(): Observable<MachineTask[]> {

    return this.http.get<MachineTask[]>(`${this.path}/manualTask`);
   }



}
