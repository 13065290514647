import { Injectable } from '@angular/core';
import { Channel } from './channel.model';
import { ChannelService } from './channel.service';

export interface GooglePlayChannel extends Channel {
  apiKey: string;
  appIdentity: string;
  maxEval: number;
}

@Injectable({
  providedIn: 'root'
})
export class GooglePlayChannelService extends ChannelService<GooglePlayChannel> {
  protected path: string = `${this.config.endpoint}/channel/google-play`;
}
