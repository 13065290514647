import { Injectable } from '@angular/core';
import { GenericModel, GenericService } from '../generic.service';

export interface AssetType extends GenericModel<number> {
  title: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class AssetTypeService extends GenericService<number, AssetType> {
  protected path: string = `${this.config.endpoint}/spaces/asset-types`;
}
