<mat-list>
  <mat-list-item>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="testMessage" placeholder="O que você gostaria de dizer?" (keyup.enter)="sendMessage(testMessage)">
      <button mat-icon-button matSuffix type="submit" (click)="sendMessage(testMessage)" color="primary">
        <mat-icon>send</mat-icon>
      </button>
    </mat-form-field>
  </mat-list-item>
  <h3 mat-list-item mat-subheader>Resposta do Robô</h3>
  <mat-list-item>{{ answer }}</mat-list-item>
  <h3 mat-list-item mat-subheader>Intenção</h3>
  <mat-list-item>{{ intent }}</mat-list-item>
</mat-list>
