import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CorpusIntentService } from './corpus-intent.service';
import { CorpusProjectService } from './corpus-project.service';
import { CorpusUtteranceService } from './corpus-utterance.service';
import { IntentResponseService } from './intent-response.service';
import { ParameterService } from './parameter.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        IntentResponseService,
        ParameterService,
        CorpusProjectService,
        CorpusIntentService,
        CorpusUtteranceService
    ]
})
export class APIModule { }
