import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { DataContainerComponent } from './data-container/data-container.component';
import { FacebookCarouselComponent } from './facebook-carousel/facebook-carousel.component';

@NgModule({
  declarations: [
    FacebookCarouselComponent,
    DataContainerComponent,
  ],
  imports: [
    MatTabsModule,
    MatListModule,
    CommonModule
  ],
  exports: [
    DataContainerComponent,
  ]
})
export class FormatsModule { }