import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action, ActionType, Intent, IntentService } from 'onevoice';

@Component({
    selector: 'alabia-action-dialog',
    templateUrl: './action-dialog.component.html',
    styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit {
    public allIntents: Intent[] = [];

    public form: FormGroup;

    constructor(
        private intents: IntentService,
        private reference: MatDialogRef<ActionDialogComponent>,

        @Inject(MAT_DIALOG_DATA) public data: Partial<Action>,
    ) {
        this.form = new FormGroup({
            "name": new FormControl(data.name || "NOVA ACAO", [Validators.required, Validators.minLength(3)]),
            "maxRej": new FormControl(data.maxRej || null, [Validators.required]),
            "maxSil": new FormControl(data.maxSil || null, [Validators.required]),
            "type": new FormControl(data.type || ActionType.DEFAULT, [Validators.required]),
        });
    }

    get actionTypes() {
        return Object.values(ActionType);
    }

    ngOnInit() {
        this.intents.list(this.data.intent?.bot?.id).subscribe(intents => {
            this.allIntents = intents.filter(
                value => value.id != this.data.intent?.id
            );
        });
    }

    compareObjects = (a: any, b: any): boolean => {
        if (a == undefined || b == undefined) {
            return false;
        }
        return a.id == b.id;
    };

    onSubmit() {
        if (this.form.valid) {
            let action: Partial<Action> = {
                ...this.data,
                ...this.form.value,
            };
            this.reference.close(action);
        }
    }
}
