import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Channel {
    id: number;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class ChannelService {
    url: string = `${environment.busService}/facebook`;

    constructor(private _httpClient: HttpClient) {}

    public list(): Observable<Channel[]> {
        return this._httpClient.get<Channel[]>(this.url);
    }
}
