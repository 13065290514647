<form [formGroup]="form" (ngSubmit)="submitTask()">
  <h3 mat-dialog-title>
    {{ title }}
  </h3>

  <mat-dialog-content class="mat-typography">
    <div *ngIf="machineList; else spinner" class="task-list">
      <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>


      <mat-form-field appearance="outline">
        <mat-label>Descrição</mat-label>
        <textarea matInput formControlName="description" rows="8"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of typeList" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Robo</mat-label>
        <mat-select formControlName="map" [compareWith]="compareMap">
          <mat-option *ngFor="let machine of machineList" [value]="machine">
            {{ machine.nickName }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <form [formGroup]="clForm" (ngSubmit)="addChecklist()" *ngIf="data.id !== undefined">
        <mat-list *ngIf="checklist !== undefined && checklist.length > 0">
          <div mat-subheader>Checklist</div>
          <mat-list-item *ngFor="let item of checklist">
            <button mat-icon-button type="button" (click)="deleteChecklist(item)">
              <mat-icon>delete</mat-icon>
            </button>
            {{ item.content }}

          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <mat-form-field appearance="outline" style="margin-top: 16px;">
          <mat-label>Item</mat-label>
          <input matInput type="text" formControlName="content" />

        </mat-form-field>
        <div style="text-align: right;">
          <button mat-icon-button matSuffix type="submit" style=" background-color: purple; color:white; size: 25px;">
            <mat-icon>add</mat-icon>
          </button>
      </div>
      </form>
    </div>

    <ng-template #spinner>
      <mat-spinner class="alabia-view-spinner"></mat-spinner>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button type="submit">Salvar</button>
  </mat-dialog-actions>
</form>
