import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { TtsListComponent } from './tss-list/tts-list.component';
import { TtsCardsComponent } from './tts-cards/tts-cards.component';
import { TtsComponent } from './tts.component';
import { TtsCreateComponent } from './tts-create/tts-create.component';



const routes: Routes = [
  {
    path: "**",
    component: TtsComponent,
    children: []
  }
]


@NgModule({
  declarations: [TtsComponent, TtsListComponent, TtsCardsComponent, TtsCreateComponent],
  imports: [
    RouterModule.forChild(routes),

    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatSnackBarModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSortModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressBarModule,

    FormsModule,

    CommonModule
  ]
})
export class TTSModule { }
