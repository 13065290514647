import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';

export interface SimpleUser {
  name: string;
  cpf: string;
}

export interface Response {
  likelihood: number;
  user: SimpleUser;
}

@Injectable({
  providedIn: 'root'
})
export class BiometryService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public create(name: string, cpf: string): Observable<SimpleUser> {
    let data = new FormData();
    data.set("name", name);
    data.set("cpf", cpf);
    return this.http.post<SimpleUser>(`${this.config.biometry}/create`, data);
  }

  public upload(user: SimpleUser, audio: File): Observable<Boolean> {
    let data = new FormData();
    data.set("cpf", `${user.cpf}`);
    data.set("audio", audio);
    return this.http.post<Boolean>(`${this.config.biometry}/upload`, data)
  }

  public retrieve(audio: File): Observable<Response> {
    let data = new FormData();
    data.set("audio", audio);
    return this.http.post<Response>(`${this.config.biometry}/retrieve`, data);
  }

  public verify(user: SimpleUser, audio: File): Observable<Response> {
    let data = new FormData();
    data.set("cpf", `${user.cpf}`);
    data.set("audio", audio);
    return this.http.post<Response>(`${this.config.biometry}/verify`, data);
  }


  public list(): Observable<SimpleUser[]> {
    return this.http.get<SimpleUser[]>(`${this.config.biometry}/list`);
  }
}
