import { Color } from "ng2-charts";

export let config1: any = {
  spanGaps: false,
  responsive: true,
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 32,
      left: 32,
      right: 32
    }
  },
  elements: {
    point: {
      radius: 4,
      borderWidth: 2,
      hoverRadius: 4,
      hoverBorderWidth: 2
    },
    line: {
      tension: 0
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
          drawBorder: false,
          tickMarkLength: 18
        },
        ticks: {
          fontColor: '#ffffff'
        }
      }
    ],
    yAxes: [
      {
        display: false
      }
    ]
  },
  plugins: {
    filler: {
      propagate: false
    },
    xLabelsOnTop: {
      active: true
    }
  }
};

export let configWidgets: any = {
  responsive: true,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        display: false
      }
    ],
    yAxes: [
      {
        display: false
      }
    ]
  }
};

export let pieConfig: any = {
  responsive: true,
  legend: {
    display: true,
    fullWidth: true,
    position: 'right',
    align: 'center'
  },
  rotation: 1 * Math.PI,
  circumference: 1 * Math.PI,
};

export const colors1: any = [
  {
    borderColor: '#ffffff',
    backgroundColor: '#ffffffb3',
    pointBackgroundColor: '#8e24aa',
    pointHoverBackgroundColor: '#ffffff',
    pointBorderColor: '#ffffff',
    pointHoverBorderColor: '#ffffff'
  }
];

export const colors = [
  '#8e24aa',
  '#ffd9ff',
  '#bf508f',
  '#d77eba',
  '#ff6460',
  '#ffd59f',
  '#ff8d2f',
  '#fed550',
  '#8e24aa',
  '#ffd9ff',
  '#bf508f',
  '#d77eba',
  '#ff6460',
  '#ffd59f',
  '#ff8d2f',
  '#fed550',
  '#8e24aa',
  '#ffd9ff',
  '#bf508f',
  '#d77eba',
  '#ff6460',
  '#ffd59f',
  '#ff8d2f',
  '#fed550',
];

export const colors2: Color[] = [
  {
    borderColor: colors,
    backgroundColor: colors,
    pointBackgroundColor: colors,
    pointHoverBackgroundColor: colors,
    pointBorderColor: '#ffffff',
    pointHoverBorderColor: '#ffffff'
  }
];
