import { Voice } from "./voice.model";


export enum TicketType {
    TTS = "TTS",
    STT = "STT",
}

export interface STTTicketID {
    owner: number;
}

export interface TTSTicketID {
    owner: number;
    voice: Voice;
}

export interface Tickets {
    id: STTTicketID | TTSTicketID;
    type: TicketType;
    tickets: number;
}
