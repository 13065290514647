import { Component, Inject } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
    Prompt,
    MediaType,
    FallbackType,
    PromptService
} from 'app/alabia/api/prompt.service';

@Component({
    selector: 'alabia-prompt-view',
    templateUrl: './prompt-view.component.html',
    styleUrls: ['./prompt-view.component.scss']
})
export class PromptViewComponent {
    MediaType = Object.entries(MediaType).filter(
        value => typeof value[1] != 'number'
    );
    FallbackType = Object.entries(FallbackType).filter(
        value => typeof value[1] != 'number'
    );

    datasource = new MatTableDataSource<Prompt>([]);

    displayedColumns = ['content', 'media', 'fallback', 'remove'];

    constructor(
        private reference: MatDialogRef<PromptViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Prompt[] = [],
        private _promptService: PromptService
    ) {
        this.datasource.data = this.data;
    }

    addMore() {
        this.data.push({});
        this.datasource.data = this.data;
    }

    compareId = (a: any, b: any): boolean => {
        return Number(a) == Number(b);
    };

    removePrompt(prompt: Prompt) {
        this.data = this.data.filter(value => prompt !== value);
        this.datasource.data = this.data;
    }
}
