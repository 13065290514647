import { UploadDialogComponent } from '@alabia/shared/upload-dialog/upload-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Floor, FloorService, Room, RoomService } from 'onevoice';
import { RoomEditComponent } from '../room-edit/room-edit.component';

@Component({
  selector: 'main-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss']
})
export class RoomListComponent implements OnInit {
  public columns: string[] = [
    "name",
    "actions",
  ]
  public datasource: MatTableDataSource<Room> = new MatTableDataSource<Room>();
  public floor?: Floor;

  constructor(
    public service: RoomService,
    public floors: FloorService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) {
  }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.datasource.paginator = value;
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(param => {
      let floorId = param.get("floor");

      if (floorId == null) {
        return;
      }

      this.floors.read(Number(floorId)).subscribe(floor => {
        this.floor = floor;
      })

      this.service.list({
        "floorId": floorId,
      }).subscribe(list => {
        this.datasource.data = list;
        this.runSort();
      })
    });
  }

  private runSort() {
    this.datasource.data.sort((f1, f2) => f2.id - f1.id);
    this.datasource.data = this.datasource.data;
  }

  public editItem(data: Partial<Room> = {}): void {
    data.floor = this.floor;
    this.dialog.open<RoomEditComponent, Partial<Room>, Room>(RoomEditComponent, {
      data: data,
      width: "500px",
      maxWidth: "90%",
    }).afterClosed().subscribe(room => {
      if (room === undefined) {
        console.log("No result received");
        return;
      }

      let action = () => this.service.create(room);
      if (room.id !== undefined) {
        action = () => this.service.update(room);
      }

      action().subscribe(room => {
        let newdata = this.datasource.data.filter(value => value.id != room.id);
        newdata.push(room);
        this.datasource.data = newdata;
        this.runSort();
      })
    });
  }

  public addImage(room: Room) {
    let reference = this.dialog.open<UploadDialogComponent, any, Array<File>>(UploadDialogComponent, {
      width: "700px",
      maxWidth: "90%",
    });

    reference.afterClosed().subscribe(fileList => {
      if (fileList === undefined) {
        return;
      }

      fileList.forEach((file) => {
        console.log(file);
      });
    });
  }
}
