import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Papa } from 'ngx-papaparse';
import { AgentResponse, AgentResponseService, Bot } from 'onevoice';
import { AgentResponseEditComponent, ResponseEditData } from '../agent-response-edit/agent-response-edit.component';


function compareContent(model: string, filter: string) {
  return model.toLowerCase().includes(filter.toLowerCase());
}

@Component({
  selector: 'main-agent-response-list',
  templateUrl: './agent-response-list.component.html',
  styleUrls: ['./agent-response-list.component.scss']
})
export class AgentResponseListComponent {
  public columnsToDisplay = ["title", "content", "edit", "delete"];
  public dataSource: MatTableDataSource<AgentResponse> = new MatTableDataSource<AgentResponse>();

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }

  get length(): number {
    return this.dataSource.data.length;
  }

  get filterContent(): string {
    return "";
  }
  set filterContent(value: string) {
    this.dataSource.filter = value;
  }

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private service: AgentResponseService,
    @Inject(MAT_DIALOG_DATA) public bot: Bot,
    public sanitizer: DomSanitizer,
    private papa: Papa,
  ) {
    this.service.list().subscribe(responses => {
      this.dataSource.data = responses.filter(response => !response.bot || response.bot.id == bot.id);
    });

    this.dataSource.filterPredicate = (data: AgentResponse, filter: string): boolean => {
      return filter.length <= 0 || compareContent(data.title, filter) || compareContent(data.content, filter);
    };
  }

  public openEdit(response?: Partial<AgentResponse>): void {
    if (response === undefined) {
      response = {
        title: "",
        content: "",
        bot: this.bot,
      }
    }

    let reference = this.dialog.open<AgentResponseEditComponent, ResponseEditData, AgentResponse | undefined>(AgentResponseEditComponent, {
      maxWidth: "80%",
      width: "80%",
      data: {
        initial: response,
        bot: this.bot,
      },
    });

    reference.afterClosed().subscribe(response => {
      if (response !== undefined) {
        this.dataSource.data = this.dataSource.data.filter(value => value.id !== response.id).concat(response);
      }
    });
  }

  public deleteResponse(response: AgentResponse): void {
    this.snack.open("Tem certeza que deseja deletar a resposta?", "Sim").onAction().subscribe(_ => {
      this.service.delete(response.id).subscribe(ok => {
        this.dataSource.data = this.dataSource.data.filter(value => value.id !== response.id);
      });
    });
  }

  public exportedContent() {
    let data = this.dataSource.data.filter(value => !!value.bot).map(response => {
      return {
        title: response.title,
        content: response.content
      }
    });

    let blob = this.papa.unparse(data, {});
    // let blob = JSON.stringify(data, null, 4);
    return this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(
        new Blob([blob], {
          type: "text/csv"
        }),
      ),
    )
  }

  public importContent(event: any) {
    for (const file of event.target.files) {
      if ((file as File).name.includes("json")) {

        let reader = new FileReader();
        reader.addEventListener("load", (event) => {
          let data = JSON.parse(event.target?.result as string);
          for (const item of data) {
            this.service.create({
              title: item.title,
              content: item.content,
              bot: this.bot,
            }).subscribe(response => {
              this.dataSource.data = this.dataSource.data.concat(response);
            })
          }
        });
        reader.readAsText(file);
      } else {
        let data = this.papa.parse(file, {
          complete: (result) => {
            

            (result.data as Array<any[]>).forEach((value, index) => {
              if (index == 0) {
                return;
              } 
              
              this.service.create({
                title: value[0],
                content: value[1],
                bot: this.bot,
              }).subscribe(response => {
                this.dataSource.data = this.dataSource.data.concat(response);
              });
            });
          }
        });
      }
    }
  }
}
