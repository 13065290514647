import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { JwtDialogComponent } from './jwt-dialog/jwt-dialog.component';
import { TokenDialogComponent } from './token-dialog/token-dialog.component';
import { TokensComponent } from './tokens.component';

const routes: Routes = [
  {
    path: "**",
    component: TokensComponent,
    children: []
  }
]

@NgModule({
  declarations: [TokensComponent, TokenDialogComponent, JwtDialogComponent],
  entryComponents: [
    TokenDialogComponent,
    JwtDialogComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    CommonModule
  ]
})
export class TokensModule { }
