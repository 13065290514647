<div class="page-layout carded fullwidth inner-scroll">
  <button class="bot-add" mat-fab (click)="createBot()">
    <mat-icon>add</mat-icon>
  </button>

  <div class="alabia-bot-grid">
    <mat-card *ngFor="let bot of enabledList">
      <mat-card-header>
        <mat-card-title>{{ bot.name }}</mat-card-title>
      </mat-card-header>
      <img mat-card-image [src]="'assets/banner.jpg'">
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <p>{{ bot.description }}</p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-card-actions align="end">
        <a mat-button [routerLink]="['/bots', bot.id]">Editar</a>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="disabled-bots" *ngIf="disabledList.length > 0">
    <mat-divider></mat-divider>
    <h2>Robôs Desabilitados</h2>

    <div class="alabia-bot-grid">
      <mat-card *ngFor="let bot of disabledList">
        <mat-card-header>
          <mat-card-title>{{ bot.name }}</mat-card-title>
        </mat-card-header>
        <img mat-card-image [src]="'assets/banner.jpg'">
        <mat-card-content>
          <mat-list>
            <mat-list-item>
              <p>{{ bot.description }}</p>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <mat-card-actions align="end">
          <a mat-button [routerLink]="['/bots', bot.id]">Editar</a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>