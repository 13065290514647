
import { Injectable } from '@angular/core';
import { Related, RelatedService } from '../auth/related.service';

export enum ServiceStatus {
  OK = "OK",
  ERROR = "ERROR",
  NOT_FOUND = "NOT_FOUND",
}

interface Intent {
  id: number;
}

export interface TriggerCustomer extends Related {
  status: ServiceStatus;
  intent: Intent;
}

@Injectable({
  providedIn: 'root'
})
export class TriggerCustomerService extends RelatedService<TriggerCustomer> {
  protected path: string = `${this.config.endpoint}/bots/{related}/customer`;
}
