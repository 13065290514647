<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>{{ verb }} Tag</h1>

    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Nome da Tag</mat-label>
            <input matInput type="text" placeholder="Nome da Tag" formControlName="title">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Descrição da Tag</mat-label>
            <input matInput type="text" placeholder="Descrição da Tag" formControlName="description">
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button type="submit">{{ verb }}</button>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
</form>