import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';''
import { TimedTask } from '../hw/timed-tasks.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerInfoService {

  private objetoSource = new BehaviorSubject<any>(null);
  objeto$ = this.objetoSource.asObservable();

  atualizarObjeto(objeto: TimedTask) {
    this.objetoSource.next(objeto);
  }

}
