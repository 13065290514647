<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Criar Sequência</h1>

  <mat-dialog-content>
    <mat-form-field>
      <input matInput type="text" placeholder="Nova ação" formControlName="name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max REJ</mat-label>
      <mat-select formControlName="maxRej" [compareWith]="compareObjects">
        <mat-option *ngFor="let intent of allIntents" [value]="intent">
          {{ intent.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Max SIL</mat-label>
      <mat-select formControlName="maxSil" [compareWith]="compareObjects">
        <mat-option *ngFor="let intent of allIntents" [value]="intent">
          {{ intent.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Tipo de Ação</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let value of actionTypes" [value]="value">
          {{ value.toString() }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type="submit" [disabled]="!form.valid">Criar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
  </mat-dialog-actions>
</form>