import { Component, OnInit } from '@angular/core';
import { AuthorizationType, Machine, MachineService, Status, User, UserService, WorkScheduler, WorkSchedulerService } from 'onevoice';
import { MatTableDataSource } from '@angular/material/table';
import { EditWorkSchedulerComponent } from './edit-work-scheduler/edit-work-scheduler.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'main-work-scheduler',
  templateUrl: './work-scheduler.component.html',
  styleUrls: ['./work-scheduler.component.scss'],
})
export class WorkSchedulerComponent implements OnInit {

  public displayedColumnsUsers: string[] = ['name', 'lastname', 'location'];
  public dataSourceUsers: MatTableDataSource<User> = new MatTableDataSource<User>();
  public userSelected?: User;
  public userAuthorities: boolean = true;
  public scheduler: WorkScheduler[] = [];
  public allWorkScheduler: WorkScheduler[][] = [[], [], [], [], [], [], []];
  public hours : String [] = [
    "05:00","06:00", "07:00", "08:00", "09:00", "10:00",
    "11:00", "12:00", "13:00", "14:00", "15:00",
    "16:00", "17:00", "18:00", "19:00", "20:00",
    "21:00", "22:00"
]

  constructor(
    private userService: UserService,
    private schedulerService: WorkSchedulerService,
    private workscheduler: WorkSchedulerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isAdmin();
    this.loadUsers();
    this.loadAllWorkScheduler();
    this.fillHours();
  }

  public compare(schedule : WorkScheduler, hour : String){

    if(schedule.start <= hour && schedule.startPause > hour){
      return true
    }
    if(schedule.endPauseTime <= hour && schedule.end >= hour){
      return true
    }
    return false
  }

  public loadAllWorkScheduler(): void {
    this.allWorkScheduler = [[], [], [], [], [], [], []];
    this.workscheduler.list().subscribe(data => {
      this.orgScheduler(data);
    });
  }
  fillHours() {
    this.hours = []
    for (let i = 6; i <= 22; i++) {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      this.hours.push(hour);
    }
    this.allWorkScheduler.forEach(data =>{
      data.forEach(single=>{
        this.hours.push(single.end)
      })
    })
    this.hours = [...new Set(this.hours)].sort();

  }

  ordenarWorkSchedulerPorFirstName(arrayDeWorkScheduler: WorkScheduler[]): WorkScheduler[] {
    return arrayDeWorkScheduler.sort((a, b) => {
      const nameA = a.user.details.first_name.toLowerCase();
      const nameB = b.user.details.first_name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  public orgScheduler(data: WorkScheduler[]): void {
    this.ordenarWorkSchedulerPorFirstName(data);
    data.forEach(single => {
      single.repeat.forEach(day => {
        switch (day) {
          case 0:
            this.allWorkScheduler[0].push(single);
            break;
          case 1:
            this.allWorkScheduler[1].push(single);
            break;
          case 2:
            this.allWorkScheduler[2].push(single);
            break;
          case 3:
            this.allWorkScheduler[3].push(single);
            break;
          case 4:
            this.allWorkScheduler[4].push(single);
            break;
          case 5:
            this.allWorkScheduler[5].push(single);
            break;
          case 6:
            this.allWorkScheduler[6].push(single);
            break;
          default:
            console.error(`Dia inválido: ${day}`);
        }
      });
    });
    console.log(this.allWorkScheduler);
  }

  public isAdmin(){

    this.userService.info().subscribe(data=>{

      this.userAuthorities = !data.authorities.some(authority => authority.authorization === 'CLEANER');
      console.log(this.userAuthorities);
    })

  }
  public repet(day: number[]): string[] {
    day.sort((a, b) => a - b);
    const week = ["Dom", "Seg", "Ter", "Quar", "Quin", "Sex", "Sab"];
    return day.map(d => week[d]);
  }

  private loadUsers(): void {
    this.userService.list().subscribe(data=>{
      console.log(data);
    })
    this.userService.list().subscribe((users) => {

      const userList = users.filter(user =>
        user.authorities.some(auth => auth.authorization === 'CLEANER')
      );
      this.dataSourceUsers.data = userList;
      if (userList.length > 0) {
        this.selectedMachineRow(userList[0]);
      }
    });
  }

  public getScheduler(user: User): void {
    this.scheduler = [];
    this.fillHours();
    this.workscheduler.list().subscribe(list => {
      console.log(list)
      this.scheduler = list.filter(schedule => schedule.user?.id === user.id);
    });

  }


  public selectedMachineRow(user: User): void {
    this.userSelected = user;
    this.getScheduler(user);
  }

  public editScheduler(data: Partial<WorkScheduler> = {}): void {
    const reference = this.dialog.open<EditWorkSchedulerComponent, Partial<WorkScheduler>, WorkScheduler>(EditWorkSchedulerComponent, {
      width: "500px",
      maxWidth: "90%",
      data: data,
    });

    reference.afterClosed().subscribe(task => {
      if (task) {
        this.getScheduler(task.user);
        this.loadAllWorkScheduler();
      }
    });
  }

  public deleteScheduler(data: WorkScheduler): void {
    this.workscheduler.delete(data.id).subscribe(() => {
      this.selectedMachineRow(data.user);
      this.loadAllWorkScheduler();
    });

  }
}
