<h1 mat-dialog-title>Encerrar Chamado</h1>

<mat-dialog-content>
  <mat-stepper #stepper>
    <mat-step>
      <ng-template matStepLabel>Etiqueta</ng-template>
      <ng-template matStepContent>
        <mat-form-field appearance="outline">
          <input matInput cdkFocusInitial type="text" [(ngModel)]="tagFilter">
          <button matSuffix mat-icon-button (click)="openModel()">
            <mat-icon>
              add
            </mat-icon>
          </button>

          <mat-hint>As tags serão filtradas independentes de maiúsculas.</mat-hint>
        </mat-form-field>

        <mat-action-list>
          <button mat-list-item *ngFor="let tag of filtered" (click)="setTag(tag, stepper)"
            title="{{ tag.description }}">
            <h3 matLine>
              {{ tag.title }}
              <mat-icon *ngIf="data.tag && data.tag.id == tag.id">check</mat-icon>
            </h3>
            <p matLine>{{ tag.description }}</p>
          </button>
        </mat-action-list>
      </ng-template>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Observações</ng-template>
      <ng-template matStepContent>
        <mat-form-field appearance="outline">
          <mat-label>Observações</mat-label>
          <textarea matInput cdkFocusInitial rows="4" type="text" placeholder="Observações do chamado"
            [(ngModel)]="data.description"></textarea>
        </mat-form-field>

        <mat-slide-toggle (change)="enableBot($event)">Rehabilitar o robô</mat-slide-toggle>
      </ng-template>
    </mat-step>

  </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="finish()">Encerrar Chamado</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>