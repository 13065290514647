import { Component, OnInit, Input } from '@angular/core';
import { Bot } from 'onevoice';
import {
    ClassifierTrainerService,
    TrainerParams,
    TrainerHistory
} from 'app/alabia/api/classifier-trainer.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, timer } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Component({
    selector: 'alabia-trainer',
    templateUrl: './trainer.component.html',
    styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {
    @Input() bot?: Bot;

    public advanced: boolean = false;
    public training: boolean = true;
    public history: TrainerHistory[] = [];
    public timer: Observable<any>;
    public status: string = '';

    params: TrainerParams = {
        epochs: 40,
        dropout: 0.5,
        recurrent_dropout: 0.5,
        num_layers: 1
    };

    constructor(
        private _service: ClassifierTrainerService,
        public snackBar: MatSnackBar
    ) {
        this.timer = timer(0, 10000);
    }

    ngOnInit() {
        if (this.bot && this.bot.id) {
            let botid = this.bot.id;
            this.timer
                .pipe(concatMap(_ => this._service.status(botid)))
                .subscribe(value => {
                    this.training = value.status == this._service.RUNNING;
                    if (value.status == this._service.DONE) {
                        this.snackBar.open(
                            'Seu treinamento foi realisado com sucesso.',
                            'OK'
                        );
                        this.updateHistory();
                    }
                    if (value.status != this.status) {
                        this.updateHistory();
                        this.status = value.status;
                    }
                });
        }
    }

    updateHistory() {
        if (!this.bot || !this.bot.id) { return; }
        this._service.history(this.bot.id).subscribe(history => {
            this.history = history.reverse();
        });
    }

    start() {
        if (!this.bot || !this.bot.id) { return; }
        this._service.start(this.bot.id, this.params).subscribe(answ => {
            this.training = true;
            this.snackBar.open('Treinamento iniciado!', 'OK', {
                duration: 1000
            });
        });
    }
}
