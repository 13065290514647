import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaDialogComponent } from 'app/alabia/shared/media-dialog/media-dialog.component';
import { GenericResponse, MediaType, ResponseType, UploadedMedia, UploadedMediaService } from 'onevoice';

function isTemplate(content: string): boolean {
  return content.includes("{{#") || content.includes("{{^");
}


function jsonValidate(control: AbstractControl): ValidationErrors | null {
  var rt = control.get("type");

  if (rt && rt.value && rt.value == ResponseType.JSON) {
    var content = control.get("content");
    try {
      if (content && content.value) {
        JSON.parse(content.value)
      }
    } catch (error) {
      if (content && content.value && isTemplate(content.value)) {
        return null;
      }

      return {
        "content": "O conteúdo do cartão precisa estar corretamente formatado como JSON"
      }
    }
  }

  return null;
}

@Component({
  selector: 'alabia-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss']
})
export class ResponseComponent {
  public submitText: string;

  public form: FormGroup;
  public media?: UploadedMedia;

  public TypeList = Object.values(ResponseType);
  public MediaList = Object.values(MediaType);
  public ResponseType = ResponseType;
  public MediaType = MediaType;

  constructor(
    public dialog: MatDialog,
    private storage: UploadedMediaService,
    @Inject(MAT_DIALOG_DATA) public data: GenericResponse,
    private reference: MatDialogRef<ResponseComponent>,
  ) {
    this.submitText = (this.data.id === undefined || this.data.id === null) ? "Criar" : "Atualizar";
    this.form = new FormGroup({
      "type": new FormControl(data.type, [Validators.required]),
      "content": new FormControl(data.content, [Validators.required, Validators.min(5)]),
      "mediaType": new FormControl(data.mediaType, [Validators.required]),
    }, [jsonValidate]);
  }

  public selectedType(): ResponseType {
    return this.form.value.type
  }

  public onSubmit(): void {
    this.reference.close({
      ... this.data,
      ... this.form.value,
    });
  }

  public openMediaDialog() {
    let reference = this.dialog.open<MediaDialogComponent, any, UploadedMedia>(MediaDialogComponent, {
      width: "80%",
      maxWidth: "800px",
    });
    reference.afterClosed().subscribe(media => {
      if (!media) { return; }
      this.media = media;
      this.storage.url(media).subscribe(url => {
        this.form.setValue({
          ...this.form.value,
          content: url,
          mediaType: media.type || MediaType.UNKNOWN,
        });
      });
    });
  }
}
