import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Machine, MachineService, MachineTask, MachineTaskService, MapUnit, MapUnitService, Redirect, RedirectType } from 'onevoice';

@Component({
  selector: 'main-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  public RedirectType = RedirectType;
  unitList: MapUnit[] = []
  machines: Machine[] = []

  type : RedirectType = RedirectType.UNDEFINED;
  public RedirectTypeTexts = {
    [RedirectType.UNDEFINED]: "Não definido",
    [RedirectType.MACHINE_TASK]: "Tarefas",
    [RedirectType.MANUAL_TASK]: "Manual",
    [RedirectType.MAINTENANCE_TASK]: "Manutenção",
  }

  public form: FormGroup = new FormGroup({
    "destination": new FormControl(0, Validators.min(1)),
    "type": new FormControl(RedirectType.UNDEFINED, Validators.required),
  });
  public taskList: MachineTask[] = [];
  allTasks: MachineTask[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<Redirect>,
    private service: MachineTaskService,
    public unitSrevice: MapUnitService,
    public reference: MatDialogRef<EditComponent>,
    public mapUnitService: MapUnitService,
    public machineService : MachineService,
  ) {
    if (this.isEdit || data.type) {
      this.form.setValue({
        destination: this.data.destination,
        type: this.data.type,
      });

    }

    service.listManual().subscribe(taskList => {
      this.taskList = taskList;
      this.allTasks = taskList;
      console.log(this.taskList)
    })
    this.listUnit()
  }
  public listUnit() {
    this.mapUnitService.list().subscribe(unitsList => {
      this.unitList = unitsList;
      console.log(unitsList);

    })
    this.loadMachines();
  }
  loadMachines(){
    this.machineService.list().subscribe(machines=>{
      this.machines = machines;
    })
  }
  selectUnit(event: any) {
    this.taskList = this.allTasks
    if (event != "Todos") {
      this.taskList = []

      this.allTasks.forEach(task => {
        if (task.map?.unit?.id == event.value.id)
          this.taskList.push(task)
      })
    }

  }

  get redirectTypes() {
    return Object.values(RedirectType);
  }

  get isEdit() {
    return this.data.id !== undefined;
  }

  public submitData() {
    console.log(this.data)
    console.log(this.form.value)
    let response: Partial<Redirect> = {
      ...this.data,
      ...this.form.value,
    }
    console.log(this.data)
    this.reference.close(response);
  }

  public filterTasks(event : any){

      this.taskList = this.allTasks
        if(event ==  "MANUAL_TASK" ){
          this.type = event;
          this. taskList = this.taskList.filter(task => task.type == "MANUAL")
          console.log(this.taskList)
        }
        if(event ==  "MAINTENANCE_TASK" ){
          this.type = event;
          this. taskList = this.taskList.filter(task => task.type == "MAINTENANCE")
          console.log(this.taskList)
        }
  }
}
