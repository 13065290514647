import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NormalizeTextDirective } from './NormalizeText.directive';
import { OnlynumberDirective } from './OnlyNumbers.directive';
import { PhoneMaskDirective } from './PhoneMask.directive';

@NgModule({
  declarations: [
    OnlynumberDirective,
    PhoneMaskDirective,
    NormalizeTextDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OnlynumberDirective,
    PhoneMaskDirective,
    NormalizeTextDirective
  ]
})
export class DirectivesModule { }
