import { Component, OnInit, Input } from '@angular/core';
import {
    FacebookHook,
    FacebookHookService
} from 'app/alabia/api/facebook-hook.service';
import { Bot } from 'onevoice';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'alabia-channels',
    templateUrl: './channels.component.html',
    styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
    @Input()
    fbHook?: FacebookHook;

    @Input()
    bot?: Bot;

    constructor(
        private _facebookService: FacebookHookService,
        public snackBar: MatSnackBar
    ) { }

    ngOnInit() { }

    updateHook() {
        if (this.fbHook && this.fbHook.id) {
            this._facebookService.update(this.fbHook).subscribe(_ => {
                this.message('Canal do facebook atualizado');
            });
        } else if (this.fbHook) {
            this._facebookService.create(this.fbHook).subscribe(hook => {
                this.fbHook = hook;
                this.message('Criado canal do facebook');
            });
        }
    }

    message(content: string) {
        this.snackBar.open(content, 'entendi', {
            duration: 10000
        });
    }
}
