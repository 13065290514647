import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'main-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
  @ViewChild("mediaUploadInput")
  public input?: ElementRef;

  constructor(
    public reference: MatDialogRef<UploadDialogComponent>,
  ) { }

  uploadFiles() {
    this.reference.close(this.files);
  }

  get files(): Array<File> {
    if (this.input && this.input.nativeElement.files) {
      return Array.from(this.input.nativeElement.files);
    }
    return Array.from([]);
  }
}
