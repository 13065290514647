import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { User } from '../auth/user.model';
import { WeekRepete } from './schedule.service';
import { Time } from '@angular/common';

export interface WorkScheduler extends Ownable{

  user : User;
  start : String;
  startPause : String;
  endPauseTime: String;
  end:String;
  repeat: number[];

}
@Injectable({
  providedIn: 'root'
})
export class WorkSchedulerService extends OwnableService<WorkScheduler>{

  protected path: string = `${this.config.endpoint}/hm/workscheduler`;

}

