import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { Bot } from './bot.model';

export interface AgentResponse extends Ownable {
  bot: Bot | null;
  title: string;
  content: string;
}

@Injectable({
  providedIn: 'root'
})
export class AgentResponseService extends OwnableService<AgentResponse> {
  protected path: string = `${this.config.endpoint}/agent/responses`;
}
