import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Parameter } from './parameter.service';

export interface Prompt {
    id?: number;
    idMediaType?: number;
    idFallbackType?: number;
    intentActionParameter?: Parameter;

    content?: string;
}

export enum MediaType {
    AUDIO = 1,
    IMAGE = 2,
    TEXT = 3,
    VIDEO = 4,
    FACEBOOK = 5
}

export enum FallbackType {
    NONE = 0,
    TOTAL_EXCEEDED = 1,
    REJ = 2,
    SIL = 3
}

@Injectable({
    providedIn: 'root'
})
export class PromptService {
    url: string = `${environment.coreService}/api/prompts`;

    constructor(private _httpClient: HttpClient) {}

    list(parameter?: number): Observable<Prompt[]> {
        let params: HttpParams = new HttpParams();
        if (parameter) {
            params = params.set('parameter', `${parameter}`);
        }
        return this._httpClient.get<Prompt[]>(this.url, {
            params: params
        });
    }

    get(id: number): Observable<Prompt> {
        return this._httpClient.get<Prompt>(`${this.url}/${id}`);
    }

    create(data: Prompt): Observable<Prompt> {
        return this._httpClient.post<Prompt>(this.url, data);
    }

    update(data: Prompt): Observable<Prompt> {
        return this._httpClient.post<Prompt>(`${this.url}/${data.id}`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    delete(data: Prompt): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
