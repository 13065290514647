<div class="page-layout carded fullwidth inner-scroll">
  <div class="content">
    <div class="center p-24 pb-56" fusePerfectScrollbar>
      <table class="token-list" mat-table [dataSource]="tokenList">
        <ng-container matColumnDef="id">
          <th class="token-id" mat-header-cell *matHeaderCellDef>ID</th>
          <td class="token-id" mat-cell *matCellDef="let token">{{ token.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th class="token-name" mat-header-cell *matHeaderCellDef>Nome</th>
          <td class="token-name" mat-cell *matCellDef="let token">{{ token.name }}</td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th class="token-action-view" mat-header-cell *matHeaderCellDef></th>
          <td class="token-action-view" mat-cell *matCellDef="let token">
            <button mat-icon-button (click)="getSession(token)">
              <mat-icon>fingerprint</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th class="token-action-edit" mat-header-cell *matHeaderCellDef></th>
          <td class="token-action-edit" mat-cell *matCellDef="let token">
            <button mat-icon-button (click)="editToken(token)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th class="token-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="token-action-delete" mat-cell *matCellDef="let token">
            <button mat-icon-button (click)="deleteToken(token)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let token; columns: columnsToDisplay"></tr>
      </table>
    </div>
  </div>
</div>

<button class="token-create" mat-fab (click)="createToken()">
  <mat-icon>add</mat-icon>
</button>
