<div>

    <table class="tts-list mat-elevation-z2" mat-table [dataSource]="datasource" matSort matSortActive="id"
        matSortDirection="desc" id="table" style="overflow-x: scroll">
        <ng-container matColumnDef="id">
            <th class="tts-id" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="ordem">
                Ordem
            </th>
            <td class="tts-id" mat-cell *matCellDef="let item">
                {{ getHistoryOrder(item) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="text">
            <th class="tts-text" mat-header-cell *matHeaderCellDef>Texto</th>
            <td class="tts-text" mat-cell *matCellDef="let item">{{ item.text }}</td>
        </ng-container>

        <ng-container matColumnDef="voice-model">
            <th class="tts-voice-model" mat-header-cell *matHeaderCellDef>Modelo</th>
            <td class="tts-voice-model" mat-cell *matCellDef="let item">{{ getVoiceName(item) }}</td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th class="tts-duration" mat-header-cell *matHeaderCellDef>Duração</th>
            <td class="tts-duration" mat-cell *matCellDef="let item" matTooltip="{{item.duration}} segundos"
                aria-label="Button that displays a tooltip when focused or hovered over">{{ item.duration }}s</td>
        </ng-container>

        <ng-container matColumnDef="download">
            <th class="tts-action-download" mat-header-cell *matHeaderCellDef>Ações</th>
            <td class="tts-action-download" mat-cell *matCellDef="let item">
                <a mat-icon-button href="{{ baseURL }}/{{ item.filename }}">
                    <mat-icon matTooltip="Download"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        cloud_download</mat-icon>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th class="tts-action-delete" mat-header-cell *matHeaderCellDef>
                <button mat-icon-button type="button" (click)="changeView()">
                    <mat-icon matTooltip="Mostrar grade"
                        aria-label="Button that displays a tooltip when focused or hovered over">apps</mat-icon>
                </button>
            </th>
            <td class="tts-action-delete" mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="deleteItem(item)">
                    <mat-icon matTooltip="Delete"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
    </table>
    <mat-paginator class="mat-elevation-z2" id="idPaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="20"
        showFirstLastButtons>
    </mat-paginator>
</div>