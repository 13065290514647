import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileInputComponent } from './file-input.component';



@NgModule({
  exports: [
    FileInputComponent,
  ],
  declarations: [
    FileInputComponent,
  ],
  imports: [
    CommonModule
  ]
})
export class FileInputModule { }
