<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">{{
        isCreate ? "Novo projeto" : "Renomear projeto"
      }}</span>
      <button
        mat-icon-button
        aria-label="Close dialog"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" (ngSubmit)="ngSubmit()">
    <p>{{ isCreate ? "Criar um novo projeto de geração de voz" : "" }}</p>
    <mat-dialog-content>
      <mat-form-field>
        <mat-label>Título do projeto</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Descrição</mat-label>
        <textarea matInput formControlName="description" rows="4"></textarea>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button [disabled]="!form.valid" type="submit">
        {{ isCreate ? "Criar" : "Salvar" }}
      </button>
      <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>
