export enum IAMEndpoints {
    signup = '/auth/signup',
    organization = '/auth/organization',
    me = '/auth/me',
    login = '/auth/login',
    new_user = '/users',
    delete_user = '/users/',
    edit_user = '/profiles/',
    org_users = '/users',
    user_permissions = '/permissions/',
    user_skills = '/user/skill',
    delete_user_skills = '/user/skill/',
    update_user_skills = '/user/skill/',
}
