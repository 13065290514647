import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, BiometryService, SimpleUser } from 'onevoice';
import { CreateComponent } from './create/create.component';
import { UploadComponent } from './upload/upload.component';
import { VerifyComponent } from './verify/verify.component';


@Component({
  selector: 'alabia-biometrics',
  templateUrl: './biometrics.component.html',
  styleUrls: ['./biometrics.component.scss']
})
export class BiometricsComponent implements OnInit {
  public title: string = "Biometria";
  public icon: string = "mic";
  public columnsToDisplay = ["id", "username", "verify", "record"];

  public datasource = new MatTableDataSource<SimpleUser>([]);

  @ViewChild(MatPaginator, { static: false })
  public paginator?: MatPaginator;

  constructor(
    private snack: MatSnackBar,
    private auth: AuthenticationService,
    private biometric: BiometryService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.auth.systemToken().then(token => {
      this.biometric.list().subscribe(all => {
        this.datasource.data = all.sort((a, b) => (a.name < b.name) ? 1 : -1);
        if (this.paginator) {
          this.datasource.paginator = this.paginator;
        }
      });
    });
  }


  public createUser() {
    let reference = this.dialog.open<CreateComponent, SimpleUser, SimpleUser>(CreateComponent, {
      data: {
        cpf: "",
        name: "",
      }
    });

    reference.afterClosed().subscribe(user => {
      if (user) {
        this.biometric.create(user.name, user.cpf).subscribe(created => {
          this.datasource.data = this.datasource.data.concat([created]);
        });
      }
    });
  }

  public createRecording(user: SimpleUser) {
    let reference = this.dialog.open<UploadComponent, SimpleUser, File>(UploadComponent, {
      data: user,
    });

    reference.afterClosed().subscribe(file => {
      if (file) {
        this.biometric.upload(user, file).subscribe(ok => {
          if (!ok) {
            this.snack.open("Não foi possível salvar o áudio", "OK");
          } else {
            this.snack.open("Áudio salvo com sucesso", "OK");
          }
        }, err => {
          if (err.status !== undefined) {
            this.snack.open("O arquivo de áudio é muito pequeno.", "OK");
          }
          else {
            this.snack.open("Ocorreu um erro não previsto no upload do arquivo", "OK");
          }
        });
      }
    });
  }

  public verifyUser(user: SimpleUser) {
    let reference = this.dialog.open<VerifyComponent, SimpleUser, File>(VerifyComponent, {
      data: user,
    });

    reference.afterClosed().subscribe(file => {
      if (file) {
        this.biometric.verify(user, file).subscribe(response => {
          if (response && response.likelihood > -0.9) {
            this.snack.open(`Resultado: ${response.likelihood}`, "OK");
          } else if (response) {
            this.snack.open("O áudio enviado não foi suficiente para verificar o usuário.", "OK");
          } else {
            this.snack.open("Ocorreu um erro durante a verificação do usuário", "OK");
          }
        });
      }
    });
  }
}
