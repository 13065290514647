import { User } from "../auth/user.model";
import { CustomerChannelType } from "../crm/customer-channel.model";
import { ChannelType } from "../dialog/generic-response.model";
import { MediaType, ResponseType, Sender } from "../dialog/message.model";

interface StompChannel {
    id: number;
    type: ChannelType;
}


interface StompCustomer {
    idCustomer: number;
    firstName: string;
    lastName: string;
}

interface StompInteraction {
    channel: StompChannel;

    idCustomer: number;
    idInteraction: number;
    interactionTime: number;
    sessionId: String;

}

interface IntentData {
    id: number;
    name: string;
    life_span: number;
    closing: boolean;
}

export interface StompMedia {
    url: string;
    type: MediaType;
}


enum UpstreamResponseType {
    TEST = "TEST",
    GENERIC = "GENERIC",
    ERROR = "ERROR",
}

interface UpstreamResponse {
    type: UpstreamResponseType;
    credential: number;
    session: number;
    error?: string;
    data?: any;
    media?: StompMedia[];
}

interface CompositeResponse {
    id?: number | null;
    upstream?: UpstreamResponse;
    intent?: IntentData;

    content: string;
    contentType: ResponseType;
    mediaType: MediaType;
}

export enum AnswerMode {
    MANUAL = "MANUAL",
    AUTO = "AUTO",
    SEMIAUTO = "SEMIAUTO",
}

export interface StompMessage {
    subject?: string;
    query?: string;
    refactored?: string;

    channel?: StompChannel;
    media?: StompMedia[];

    customerDTO?: StompCustomer;
    interactionDTO?: StompInteraction;

    idBot?: number;
    idConversation?: number;

    quiet?: boolean;
    complete?: boolean;
    nonsense?: boolean;

    customerChannelId?: string;

    responses: CompositeResponse[];

    answerMode?: AnswerMode;
}


export interface Message {
    id?: number | null;
    sender: Sender;
    channel?: ChannelType,

    content: string;
    contentType: ResponseType;
    mediaType: MediaType;

    date: Date;
    agent?: User;
}
