<div id="alabia-view-content" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">Projeto: {{ title }}</h1>
  </div>

  <mat-spinner class="alabia-view-spinner" *ngIf="project === undefined || script === undefined"></mat-spinner>
  <div class="alabia-view-container" *ngIf="project !== undefined && script !== undefined">
    <mat-card class="mb-24">
      <mat-card-header>
        <mat-card-title>{{ project.name }}</mat-card-title>
        <button mat-mini-fab type="button" (click)="editProject()">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-card-subtitle>{{ project.description }}</mat-card-subtitle>
      </mat-card-header>

      <mat-divider></mat-divider>
      <mat-card-actions>
        <button mat-raised-button type="button" (click)="editProject()">
          Editar
        </button>
        <button mat-raised-button type="button" (click)="generateVoiceProject()">
          Gerar
        </button>
        <button mat-raised-button type="button" *ngIf="executionList.length > 0" (click)="openDownloads(project)">
          Download
        </button>
      </mat-card-actions>
      <mat-card-footer *ngIf="processing">
        <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>

    <div class="inf-contain">
      <mat-card class="inf-card">
        <mat-card-header>
          <mat-card-title>
            <mat-icon>description</mat-icon>
            Script
          </mat-card-title>
          <mat-card-subtitle>{{ script.data.length }}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card class="inf-card">
        <mat-card-header>
          <mat-card-title>
            <mat-icon>graphic_eq</mat-icon>
            Humor
          </mat-card-title>
          <mat-card-subtitle>{{ moodSizeID.length }}
            <div class="mat-subheading-2">/{{ moodList.length }}</div>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>

      <mat-card class="inf-card" id="inf-card-voices" [routerLink]="['/voices']">
        <mat-card-header>
          <mat-card-title>
            <mat-icon>record_voice_over</mat-icon>
            Voz
          </mat-card-title>
          <mat-card-subtitle>{{ voiceSizeID.length }}
            <div class="mat-subheading-2">/{{ voiceList.length }}</div>
          </mat-card-subtitle>
        </mat-card-header>
      </mat-card>
    </div>

    <mat-tab-group class="mat-elevation-z2" color="accent">
      <mat-tab label="Lista">
        <form [formGroup]="createForm" (ngSubmit)="createScriptLine()" class="mat-elevation-z8" #formDirective="ngForm">
          <table mat-table class="detailTable" [dataSource]="script" fixedLayout="true">
            <ng-container matColumnDef="order">
              <th mat-header-cell *matHeaderCellDef>Ordem</th>
              <td mat-cell *matCellDef="let item" (click)="editScript(item)">
                {{ item.order }}
              </td>
              <td mat-footer-cell *matFooterCellDef="let item"></td>
            </ng-container>

            <ng-container matColumnDef="text">
              <th mat-header-cell *matHeaderCellDef>Texto</th>
              <td mat-cell *matCellDef="let item" (click)="editScript(item)">
                {{ item.text }}
              </td>
              <td mat-footer-cell *matFooterCellDef="let item">
                <mat-form-field>
                  <mat-label>Texto</mat-label>
                  <input matInput #textInput type="text" formControlName="text" />
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="mood">
              <th mat-header-cell *matHeaderCellDef>Humor</th>
              <td mat-cell *matCellDef="let item" (click)="editScript(item)">
                {{ item.mood.name }}
              </td>
              <td mat-footer-cell *matFooterCellDef="let item">
                <mat-form-field>
                  <mat-label>Humor</mat-label>
                  <mat-select formControlName="mood" [compareWith]="compareWith">
                    <mat-option *ngFor="let mood of moodList" [value]="mood">
                      {{ mood.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="voice">
              <th mat-header-cell *matHeaderCellDef>Voz</th>
              <td mat-cell *matCellDef="let item" (click)="editScript(item)">
                {{ item.voice.title }}
              </td>
              <td mat-footer-cell *matFooterCellDef="let item">
                <mat-form-field>
                  <mat-label>Voz</mat-label>
                  <mat-select formControlName="voice" [compareWith]="compareWith">
                    <mat-option *ngFor="let voice of voiceList" [value]="voice">{{ voice.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th [ngStyle]="{ width: actionSize }" mat-header-cell *matHeaderCellDef>
                Ações
                <button mat-icon-button (click)="actionsView()" type="button">
                  <mat-icon *ngIf="actionView" style="vertical-align: unset" matTooltip="Minimizar"
                    aria-label="Example icon-button with a menu">minimize
                  </mat-icon>
                  <mat-icon *ngIf="!actionView" matTooltip="Maximizar" aria-label="Example icon-button with a menu">
                    unfold_more</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let item">
                <div class="actionsMax" *ngIf="actionView">
                  <button mat-icon-button (click)="editScript(item)" type="button" matTooltip="Editar"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button (click)="copyScript(item)" type="button" matTooltip="Duplicar"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                    <mat-icon>file_copy</mat-icon>
                  </button>
                  <button mat-icon-button (click)="changeOrder(item, -1)" type="button" matTooltip="Subir ordem"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <button mat-icon-button (click)="changeOrder(item, +1)" type="button" matTooltip="Descer ordem"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                    <mat-icon>expand_more</mat-icon>
                  </button>

                  <button mat-icon-button (click)="removeScriptLine(item)" type="button" matTooltip="Deletar"
                    aria-label="Button that displays a tooltip when focused or hovered over">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
                <div class="actionsMax" *ngIf="!actionView">
                  <button mat-icon-button [matMenuTriggerFor]="menu" type="button" matTooltip="Opções"
                    aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editScript(item)" type="button">
                      <mat-icon>edit</mat-icon>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item (click)="editScript(item)" type="button">
                      <mat-icon>file_copy</mat-icon>
                      <span>Duplicar</span>
                    </button>
                    <button mat-menu-item (click)="changeOrder(item, -1)" type="button">
                      <mat-icon>expand_less</mat-icon>
                      <span>Subir ordem</span>
                    </button>
                    <button mat-menu-item (click)="changeOrder(item, +1)" type="button">
                      <mat-icon>expand_more</mat-icon>
                      <span>Descer ordem</span>
                    </button>

                    <button mat-menu-item (click)="removeScriptLine(item)" type="button">
                      <mat-icon>close</mat-icon>
                      <span>Excluir script</span>
                    </button>
                  </mat-menu>
                </div>
              </td>
              <td class="detail-action" mat-footer-cell *matFooterCellDef="let item">
                <button mat-icon-button type="submit" matTooltip="Adicionar"
                  aria-label="Button that displays a tooltip when focused or hovered over">
                  <mat-icon>add</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="scriptColumns"></tr>
            <tr mat-row *matRowDef="let item; columns: scriptColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="scriptColumns"></tr>
          </table>
        </form>
      </mat-tab>
      <mat-tab label="Cards">
        <div class="scriptCards mat-elevation-z2">
          <div class="scriptTitle mat-headline">Scripts</div>
          <mat-divider></mat-divider>
          <mat-card class="scriptCardTemplate" id="scriptCard" *ngFor="let script of script.data">
            <mat-dialog-content>
              <mat-form-field class="text-area" style="min-height: 90px">
                <mat-label>Texto</mat-label>
                <textarea matInput rows="3" disabled>{{
                  script.text
                }}</textarea>
              </mat-form-field>
              <div class="order mat-headline">
                <button mat-icon-button (click)="changeOrder(script, -1)" type="button" matTooltip="Subir ordem"
                  aria-label="Button that displays a tooltip when focused or hovered over">
                  <mat-icon>expand_less</mat-icon>
                </button>
                <div>{{ script.order }}</div>
                <button mat-icon-button (click)="changeOrder(script, +1)" type="button" matTooltip="Descer ordem"
                  aria-label="Button that displays a tooltip when focused or hovered over">
                  <mat-icon>expand_more</mat-icon>
                </button>
              </div>
              <mat-divider style="width: 87vw; margin: 0 auto 0 auto; left: auto"></mat-divider>
              <table>
                <td>
                  <mat-form-field>
                    <mat-label>Humor</mat-label>
                    <input matInput value="{{ script.mood.name }}" disabled />
                  </mat-form-field>
                </td>
                <td style="width: 0.5vw"></td>
                <div style="
                    height: 20px;
                    border-left: 1px solid rgba(0, 0, 0, 0.12);
                    padding-right: 1.5vw;
                  "></div>
                <td>
                  <mat-form-field>
                    <mat-label>Voz</mat-label>
                    <input matInput value="{{ script.mood.name }}" disabled />
                  </mat-form-field>
                </td>
                <td style="width: 45vw; text-align: end">
                  <mat-dialog-actions>
                    <mat-hint matTooltip="Quantidade de caracteres">{{ script.text.length }}
                    </mat-hint>
                    <div class="divider"></div>
                    <button mat-icon-button (click)="editScript(script)" type="button" matTooltip="Editar"
                      aria-label="Button that displays a tooltip when focused or hovered over">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button (click)="copyScript(script)" type="button" matTooltip="Duplicar"
                      aria-label="Button that displays a tooltip when focused or hovered over">
                      <mat-icon>file_copy</mat-icon>
                    </button>

                    <button mat-icon-button (click)="removeScriptLine(script)" type="button" matTooltip="Deletar"
                      aria-label="Button that displays a tooltip when focused or hovered over">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-dialog-actions>
                </td>
              </table>
            </mat-dialog-content>
          </mat-card>
          <mat-card class="scriptCardTemplate">
            <form [formGroup]="createForm" (ngSubmit)="createScriptLine()" #formDirective="ngForm">
              <mat-dialog-content>
                <mat-form-field class="text-area" style="min-height: 90px">
                  <mat-label>Texto</mat-label>
                  <textarea (ngModelChange)="textChange()" matInput formControlName="text" rows="3"></textarea>
                </mat-form-field>
                <div class="order mat-headline">
                  <button mat-icon-button type="button" disabled>
                    <mat-icon>expand_less</mat-icon>
                  </button>
                  <div>{{ script.data.length + 1 }}</div>
                  <button mat-icon-button type="button" disabled>
                    <mat-icon>expand_more</mat-icon>
                  </button>
                </div>
                <mat-divider style="width: 87vw; margin: 0 auto 0 auto; left: auto"></mat-divider>
                <table>
                  <td>
                    <mat-form-field>
                      <mat-label>Humor</mat-label>
                      <mat-select formControlName="mood" [compareWith]="compareWith">
                        <mat-option *ngFor="let mood of moodList" [value]="mood">
                          {{ mood.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td style="width: 0.5vw"></td>
                  <div style="
                      height: 20px;
                      border-left: 1px solid rgba(0, 0, 0, 0.12);
                      padding-right: 1.5vw;
                    "></div>
                  <td>
                    <mat-form-field>
                      <mat-label>Voz</mat-label>
                      <mat-select formControlName="voice" [compareWith]="compareWith">
                        <mat-option *ngFor="let voice of voiceList" [value]="voice">
                          {{ voice.title }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                  <td style="width: 45vw; text-align: end">
                    <mat-dialog-actions>
                      <mat-hint matTooltip="Quantidade de caracteres">
                        {{ charCount.length }}
                      </mat-hint>
                      <div class="divider"></div>
                      <button mat-icon-button type="submit" matTooltip="Adicionar"
                        aria-label="Button that displays a tooltip when focused or hovered over">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-dialog-actions>
                  </td>
                </table>
              </mat-dialog-content>
            </form>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>