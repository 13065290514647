<form [formGroup]="form" (ngSubmit)="ngSubmit()">
    <h1 mat-dialog-title>{{ isCreate ? 'Criar Voz' : 'Renomear Voz' }}</h1>

    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Nome da Voz</mat-label>
            <input matInput type="text" formControlName="title">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Base da Voz</mat-label>
            <mat-select formControlName="base" [compareWith]="compareBases">
                <mat-option *ngFor="let base of baseList" [value]="base">
                    {{ base.description }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [disabled]="!form.valid" type="submit">{{ isCreate ? 'Criar' : 'Salvar' }}</button>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
</form>