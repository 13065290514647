<div class="info" *ngIf="isShowing" [@infoAnimation]>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ultrices sem nec nulla porta faucibus. Aenean
  sed ex at velit volutpat accumsan. Sed et tortor elementum, tristique dui non, tincidunt est. Duis condimentum id
  velit sit amet aliquet. Integer nec efficitur dolor. Morbi sed purus sit amet metus sodales tristique id eget
  urna. Donec sed mi tellus. Pellentesque fermentum sem a ultrices efficitur. Vestibulum volutpat est a dui auctor,
  at volutpat massa ultrices. Sed id maximus metus. Integer tempor velit at neque ultricies, vel varius risus
  elementum. Quisque tempor dignissim magna pellentesque iaculis. Mauris tempus ligula neque. Ut at lacus.
</div>
<form *ngIf="isShowing && secondStepSignup" [@formAnimation] [formGroup]="secondStepSignup" (ngSubmit)="onSubmit(secondStepSignup)"
  ngNativeValidate class="form" #secondStepForm="ngForm">
  <h3 class="form--title">Fale sobre você</h3>
  <mat-form-field appearance="outline">
    <mat-label for="firstName">Nome</mat-label>
    <input matInput type="text" id="firstName" name="firstName" formControlName="firstName" required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label for="lastName">Sobrenome</mat-label>
    <input matInput type="text" id="lastName" name="lastName" formControlName="lastName" required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label for="phone">Telefone</mat-label>
    <input matInput phoneMask type="tel" id="phone" name="phone" formControlName="phone" required>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label for="username">Nome de usuário</mat-label>
    <input matInput normalizeText type="text" id="username" name="username" formControlName="username" required>
    <mat-hint>Somente letras e números</mat-hint>
  </mat-form-field>

  <div class="button-container">
    <button mat-raised-button class="button button__back" type="button" (click)="screenOutputHandler(1, 'backward')">Voltar</button>
    <button mat-raised-button class="button button__next" type="submit"
      [disabled]="secondStepSignup.invalid">Criar conta</button>
  </div>
</form>