<div id="alabia-voice-snippet-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">{{ title }}</h1>
  </div>

  <!-- Generate Áudio -->
  <form (submit)="submitAudio()">
    <input #fileInput hidden multiple name="content" type="file" (change)="selectFile($event)">

    <mat-card id="alabia-voice-snippet-card" class="m-24">
      <mat-form-field appearance="outline" style="grid-column: span 1;">
        <mat-label>Humor</mat-label>
        <mat-select name="mood" [(ngModel)]="mood">
          <mat-option *ngFor="let item of moodList" [value]="item">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" style="grid-column: span 3;">
        <mat-label>Transcrição</mat-label>
        <button mat-icon-button matPrefix type="button" (click)="fileInput.click()">
          <mat-icon>add</mat-icon>
        </button>
        <input matInput name="text" type="text" [(ngModel)]="text">
        <button mat-icon-button matSuffix type="submit"
          [disabled]="text.length == 0 || files == null || files.length == 0">
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>

      <button mat-raised-button (click)="openWizard()">Wizard</button>
      <button mat-raised-button (click)="openModelDialog()">Treinamento</button>
      <button mat-raised-button [routerLink]="['split']">Cortar</button>
      <div class="toggle-editable">
        <mat-slide-toggle [checked]="editable" (change)="editable = $event.checked">Editar</mat-slide-toggle>
      </div>
    </mat-card>
  </form>



  <div class="content mt-16">
    <div class="center p-24 pt-0 pb-56" fusePerfectScrollbar>
      <table class="voice-snippet-list" mat-table [dataSource]="datasource">
        <ng-container matColumnDef="id">
          <th class="voice-snippet-id" mat-header-cell *matHeaderCellDef>ID</th>
          <td class="voice-snippet-id" mat-cell *matCellDef="let item">{{ item.id }}</td>
        </ng-container>

        <ng-container matColumnDef="text">
          <th class="voice-snippet-text" mat-header-cell *matHeaderCellDef>Transcrição</th>
          <td class="voice-snippet-text" mat-cell *matCellDef="let item">
            <input matInput type="text" [disabled]="!editable" [(ngModel)]="item.text" (change)="editItem(item)" (focus)="playAudio(item)">
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th class="voice-snippet-date" mat-header-cell *matHeaderCellDef>Data</th>
          <td class="voice-snippet-date" mat-cell *matCellDef="let item">
            {{ item.created | date:"short" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="play">
          <th class="voice-snippet-action-play" mat-header-cell *matHeaderCellDef></th>
          <td class="voice-snippet-action-play" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="playItem(item)">
              <mat-icon>play_circle_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th class="voice-snippet-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="voice-snippet-action-delete" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="deleteItem(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100, 500, 1000]" [pageSize]="100" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>