<form [formGroup]="form" (ngSubmit)="ngSubmit()">
    <h1 mat-dialog-title>Renomear script</h1>
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Texto</mat-label>
            <textarea matInput formControlName="text" rows="3"></textarea>
            <!-- <input type="text" matInput formControlName="text"> -->
        </mat-form-field>

        <mat-form-field>
            <mat-label>Humor</mat-label>
            <mat-select formControlName="mood" [compareWith]="compareWith">
                <mat-option *ngFor="let mood of moodList" [value]="mood">{{ mood.name }}</mat-option>
            </mat-select>

        </mat-form-field>

        <mat-form-field>
            <mat-label>Voz</mat-label>
            <mat-select formControlName="voice" [compareWith]="compareWith">
                <mat-option *ngFor="let voice of voiceList" [value]="voice">
                    {{ voice.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-dialog-actions>
            <button mat-raised-button [disabled]="!form.valid" type="submit">Salvar</button>
            <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
        </mat-dialog-actions>

    </mat-dialog-content>
</form>