import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mood, MoodService, ScriptLine, Voice, VoiceService } from 'onevoice';

@Component({
  selector: 'main-script-edit',
  templateUrl: './script-edit.component.html',
  styleUrls: ['./script-edit.component.scss']
})
export class ScriptEditComponent implements OnInit {
  public form: FormGroup;
  public moodList: Mood[] = [];
  public voiceList: Voice[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<ScriptLine>,
    public moodService: MoodService,
    public voiceService: VoiceService,
    private reference: MatDialogRef<ScriptEditComponent>
  ) {
    this.form = new FormGroup({
      "text": new FormControl(data.text, [Validators.required, Validators.minLength(3)]),
      "mood": new FormControl(data.mood, [Validators.required]),
      "voice": new FormControl(data.voice, [Validators.required])
    })
  }

  ngOnInit(): void {
    this.moodService.list().subscribe(moodList => this.moodList = moodList);
    this.voiceService.list().subscribe(voiceList => this.voiceList = voiceList);
  }


  ngSubmit() {
    this.reference.close({
      ...this.data,
      ...this.form.value
    });
  }

  compareWith(o1: { id: number }, o2: { id: number }) {
    return o1.id == o2.id;
  }
}
