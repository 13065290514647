<h1 mat-dialog-title>Criar Sequência</h1>

<mat-dialog-content>
    <mat-form-field>
        <mat-label>Próxima intenção</mat-label>
        <mat-select [(ngModel)]="data.destination" [compareWith]="compareObjects">
            <mat-option *ngFor="let intent of allIntents" [value]="intent">
                {{ intent.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Parâmetro</mat-label>
        <mat-select [(ngModel)]="data.trigger" [compareWith]="compareObjects">
            <mat-option *ngFor="let parameter of allParameters" [value]="parameter">
                {{ parameter.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input matInput type="text" [(ngModel)]="data.answer" placeholder="Resposta que define esta sequência">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data">Criar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>
