import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Action } from 'onevoice';
import { Observable } from 'rxjs';

export enum ParameterScope {
    INTENT = "INTENT",
    INTERACTION = "INTERACTION",
    ASSIGNMENT = "ASSIGNMENT"
}

export interface Parameter {
    id?: number; // This is the ID.
    action?: Action;
    name?: string;

    validValues?: string;
    validationService?: string;

    order?: number;
    required?: boolean;
    scope: ParameterScope;
}

@Injectable({
    providedIn: 'root'
})
export class ParameterService {
    url: string = `${environment.coreService}/api/parameters`;

    constructor(private _httpClient: HttpClient) { }

    list(action?: number): Observable<Parameter[]> {
        let params: HttpParams = new HttpParams();
        if (action) {
            params = params.set('action', `${action}`);
        }
        return this._httpClient.get<Parameter[]>(this.url, {
            params: params
        });
    }

    get(id: number): Observable<Parameter> {
        return this._httpClient.get<Parameter>(`${this.url}/${id}`);
    }

    create(data: Parameter): Observable<Parameter> {
        return this._httpClient.post<Parameter>(this.url, data);
    }

    update(data: Parameter): Observable<Parameter> {
        return this._httpClient.post<Parameter>(
            `${this.url}/${data.id}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        );
    }

    delete(data: Parameter): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
