import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Floor, FloorService } from 'onevoice';
import { FloorEditComponent } from '../floor-edit/floor-edit.component';

@Component({
  selector: 'main-floor-list',
  templateUrl: './floor-list.component.html',
  styleUrls: ['./floor-list.component.scss']
})
export class FloorListComponent implements OnInit {
  public columns: string[] = [
    "name",
    "maps",
    "actions",
  ]
  public datasource: MatTableDataSource<Floor> = new MatTableDataSource<Floor>();

  constructor(
    public service: FloorService,
    public dialog: MatDialog,
  ) { }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.datasource.paginator = value;
  }

  public ngOnInit(): void {
    this.service.list().subscribe(list => {
      this.datasource.data = list;
      this.runSort();
    });
  }

  private runSort() {
    this.datasource.data.sort((f1, f2) =>  f2.id - f1.id);
    this.datasource.data = this.datasource.data;
  }

  public editItem(data: Partial<Floor> = {}): void {
    this.dialog.open<FloorEditComponent, Partial<Floor>, Floor>(FloorEditComponent, {
      data: data,
      width: "500px",
      maxWidth: "90%",
    }).afterClosed().subscribe(floor => {
      if (floor === undefined) {
        console.log("No result received");
        return;
      }

      let action = () => this.service.create(floor);
      if (floor.id !== undefined) {
        action = () => this.service.update(floor);
      }

      action().subscribe(floor => {
        let newdata = this.datasource.data.filter(value => value.id != floor.id);
        newdata.push(floor);
        this.datasource.data = newdata;
        this.runSort();
      })
    });
  }

  public mapNames(floor: Floor) : string {
    return floor.maps.map(value => value.title).join(", ");
  }
}
