import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Chart, ChartType } from 'chart.js';
import { AuthenticationService, MachineExecution, MachineExecutionService } from 'onevoice';

@Component({
  selector: 'main-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {




  constructor(
    private serviceMachineExecution: MachineExecutionService,
    public auth: AuthenticationService,
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

}
