<mat-tab-group id="tab-group">

  <mat-tab label="Visão Geral">
    <ng-template matTabContent>
      <main-simple-dashboard></main-simple-dashboard>
    </ng-template>
  </mat-tab>

  <mat-tab label="Visão Detalhada">
    <ng-template matTabContent>
      <main-extended-dashboard></main-extended-dashboard>
    </ng-template>
  </mat-tab>

  <mat-tab *ngFor="let dash of dashList" [label]="dash">
    <ng-template matTabContent>
      <main-custom-dashboard [dashboard]="dash" [graphList]="graphList[dash]"></main-custom-dashboard>
    </ng-template>
  </mat-tab>

  <mat-tab disabled>
    <ng-template mat-tab-label>
      <button *ngIf="!adding" mat-icon-button (click)="adding = true;">
        <mat-icon>add</mat-icon>
      </button>

      <div *ngIf="adding">
        <form [formGroup]="form" (ngSubmit)="addDash()">
          <mat-form-field>
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" type="text">
          </mat-form-field>
        </form>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>