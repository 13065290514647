<div id="alabia-tts-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">TTS</h1>
  </div>

  <!-- Generate Áudio -->
  <form (submit)="generateAudio()">
    <mat-card id="alabia-tts-card" class="m-24">
      <mat-form-field appearance="outline">
        <mat-label>Modelo</mat-label>
        <mat-select name="model" [(ngModel)]="voiceModel">
          <mat-option *ngFor="let item of voices" value="{{ item }}">{{ item }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Gerar áudio</mat-label>
        <input matInput name="content" type="text" [(ngModel)]="generate">
        <button mat-icon-button matSuffix type="submit" [disabled]="generate.length == 0">
          <mat-icon *ngIf="!generating">send</mat-icon>
          <mat-icon class="rotating" *ngIf="generating">loop</mat-icon>
        </button>
        <mat-hint>Limite restante: {{ limitValue }}</mat-hint>
      </mat-form-field>
    </mat-card>
  </form>

  <div class="content">
    <div class="center p-24 pt-0 pb-56" fusePerfectScrollbar>
      <table class="tts-list" mat-table [dataSource]="datasource">
        <ng-container matColumnDef="id">
          <th class="tts-id" mat-header-cell *matHeaderCellDef>ID</th>
          <td class="tts-id" mat-cell *matCellDef="let item">{{ item.id }}</td>
        </ng-container>

        <ng-container matColumnDef="text">
          <th class="tts-text" mat-header-cell *matHeaderCellDef>Texto</th>
          <td class="tts-text" mat-cell *matCellDef="let item">{{ item.text }}</td>
        </ng-container>

        <ng-container matColumnDef="voice-model">
          <th class="tts-voice-model" mat-header-cell *matHeaderCellDef>Modelo</th>
          <td class="tts-voice-model" mat-cell *matCellDef="let item">{{ item.model }}</td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th class="tts-duration" mat-header-cell *matHeaderCellDef></th>
          <td class="tts-duration" mat-cell *matCellDef="let item">{{ item.duration }}s</td>
        </ng-container>

        <ng-container matColumnDef="download">
          <th class="tts-action-download" mat-header-cell *matHeaderCellDef></th>
          <td class="tts-action-download" mat-cell *matCellDef="let item">
            <a mat-icon-button href="{{ baseURL }}/{{ item.filename }}">
              <mat-icon>cloud_download</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th class="tts-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="tts-action-delete" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="deleteItem(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>