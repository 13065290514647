import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { SubscriptionRequestService } from '../api/subscription-request.service';
import { SubscriptionComponent } from './subscription.component';


registerLocaleData(localePt);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SubscriptionComponent,
    resolve: {
      subscription: SubscriptionRequestService
    }
  }
]

@NgModule({
  declarations: [
    SubscriptionComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FuseSharedModule,
    MatIconModule,
  ],
  exports: [
    SubscriptionComponent,
  ],
  providers: [
    SubscriptionRequestService,
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
})
export class SubscriptionModule { }
