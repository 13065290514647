import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Machine, MachineScheduler, MachineSchedulerService, ScheduleService, ScheduleType, TaskSchedule, UserService, WeekRepete } from 'onevoice';
import { ScheduleEditComponent } from '../schedule-edit/schedule-edit.component';

const WEEK_DAYS: { [key: string]: string } = {
  "friday": "Sex.",
  "monday": "Seg.",
  "saturday": "Sab.",
  "sunday": "Dom.",
  "thursday": "Qui.",
  "tuesday": "Ter.",
  "wednesday": "Qua.",
}

@Component({
  selector: 'main-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {
  public columns: string[] = [
    "name",
    "task",
    "location",
    "repete",
    "start",
    "actions",
    "delete",
  ]
  public datasource: MatTableDataSource<MachineScheduler> = new MatTableDataSource<MachineScheduler>();

  constructor(
    public service: MachineSchedulerService,
    public dialog: MatDialog,
  ) {
  }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.datasource.paginator = value;
  }

  public ngOnInit(): void {
    this.loadData();

  }
  public loadData(){
    this.service.list().subscribe(list => {
      console.log(list)
      list.forEach(item =>{
        if(!item){
          console.log('falso')
        }
      })
      this.datasource.data = list;
      this.runSort();
    })
  }
  private runSort() {
    this.datasource.data.sort((f1, f2) => f2.id - f1.id);
    this.datasource.data = this.datasource.data;
  }

  public editItem(data: Partial<MachineScheduler> = {}): void {
    console.log(data)
    this.dialog.open<ScheduleEditComponent, Partial<MachineScheduler>, MachineScheduler>(ScheduleEditComponent, {
      data: data,
      width: "500px",
      maxWidth: "90%",
    }).afterClosed().subscribe(room => {
      if (room === undefined) {
        console.log("No result received");
        return;
      }

      let action = () => this.service.saveManual(room);


      action().subscribe(room => {
        let newdata = this.datasource.data.filter(value => value.id != room.id);
        newdata.push(room);
        this.datasource.data = newdata;
        this.runSort();
      })
    });
  }
  public delete( item : MachineScheduler){
    this.service.delete(item.id).subscribe(data=>{
      this.loadData()
    })
  }
  public repet(day : number[]){
    var week = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    var days : String[] = []
    day.forEach(day=>{
      days.push(week[day])
    })
    return days;
  }
  public repeteString(repete: WeekRepete): string {
    if (!repete) {
      return '';
    }

    const answ = [];
    for (const [key, value] of Object.entries(repete)) {
      if (value) {
        answ.push(WEEK_DAYS[key]);
      }
    }
    return answ.join(", ");
  }

}
