<div *ngIf="dataLoaded; else spinner" class="task-list">
  <table mat-table [dataSource]="tasksToday">
      <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
              Nome
          </th>
          <td mat-cell *matCellDef="let element">
              {{ element.machineTask.name }}
          </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
            Tipo
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.machineTask.type || 'Recorrente' }}
        </td>
    </ng-container>
      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>
            Início
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.startTime }}
        </td>
    </ng-container>
      <ng-container matColumnDef="map">
          <th mat-header-cell *matHeaderCellDef>
              Mapa
          </th>
          <td mat-cell *matCellDef="let element">
              {{ element.machineTask.map.title }}
          </td>
      </ng-container>
      <ng-container matColumnDef="tarefa">
        <th mat-header-cell *matHeaderCellDef>
            Tarefa
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.machineTask.name }}
        </td>
    </ng-container>

      <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
              Ações
          </th>
          <td mat-cell *matCellDef="let element">
              <a mat-icon-button [routerLink]="['/cleaning/tasks/',element.machineTask.id]" (click)="setScheduleInfo(element)" >
                  <mat-icon>add_circle_outline</mat-icon>
              </a>

          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="fields"></tr>
      <tr mat-row *matRowDef="let row; columns: fields;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
      aria-label="Selecionar quantidade de elementos na página">
  </mat-paginator>

</div>

<ng-template #spinner>
  <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>
