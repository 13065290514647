import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';

export enum ApiLimit {
  TTS = "media.tts",
  STT = "media.stt",
}

@Injectable({
  providedIn: 'root'
})
export class LimitService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public check(): Observable<Boolean> {
    return this.http.post<Boolean>(`${this.config.endpoint}/limit/add`, new FormData());
  }

  public show(limit: ApiLimit): Observable<number> {
    const data: FormData = new FormData();
    data.set('limit', limit);
    return this.http.post<number>(`${this.config.endpoint}/limit/show`, data);
  }
}
