import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { UserSkill } from './user-skill.model';

@Injectable({
  providedIn: 'root'
})
export class UserSkillService extends OwnableService<UserSkill> {
  protected path: string = `${this.config.endpoint}/user/skill`;
}
