<h1 mat-dialog-title>Enviar arquivo</h1>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>Observações</mat-label>
    <input matInput type="text" placeholder="Mensagem" [(ngModel)]="message">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="uploadFile.click()">Selecionar</button>
  <button mat-button (click)="finish()">Enviar</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>

  <mat-spinner *ngIf="sending" diameter="16"></mat-spinner>
  <small *ngIf="sending">Enviado: {{ sent }} de {{ fileCount() }}</small>
</mat-dialog-actions>

<input #uploadFile hidden type="file" multiple (change)="selectFiles($event)">