import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel } from './channel.model';
import { ChannelService } from './channel.service';

export interface FacebookPageChannel extends Channel {
  messenger: boolean;
  comments: boolean;
}

const SCOPES = [
  // Facebook scopes
  'public_profile',
  'email',
  'pages_read_user_content',
  'pages_manage_engagement',
  'pages_messaging',
  'pages_manage_metadata',

  // Instagram
  'instagram_basic',
  'instagram_manage_comments',
]

@Injectable({
  providedIn: 'root'
})
export class FacebookPageChannelService extends ChannelService<FacebookPageChannel>  {
  protected path: string = `${this.config.endpoint}/channel/facebook-page`;
  private appId?: string;

  private getAppId() {
    return new Promise((resolve, reject) => {
      if (this.appId !== undefined) {
        resolve(this.appId);
      }
      this.http.get<string>(`${this.path}/app-config`).subscribe(appId => {
        this.appId = appId;
        resolve(this.appId);
      }, err => reject(err));
    })
  }

  private returnUrl(redirect: string) {
    return `${window.location.protocol}//${window.location.host}${redirect}`;
  }

  public openDialog(redirect: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let url: string = encodeURIComponent(this.returnUrl(redirect));
      let scope: string = encodeURIComponent(SCOPES.join(","));

      this.getAppId().then(appId => {
        resolve(`https://www.facebook.com/dialog/oauth?client_id=${appId}&redirect_uri=${url}&scope=${scope}`);
      }, err => reject(err));
    });
  }

  public wizard(redirect: string, code: string, bot: number): Observable<FacebookPageChannel[]> {
    let url: string = this.returnUrl(redirect);
    let data: FormData = new FormData();

    data.set("code", code);
    data.set("returnUrl", url);
    data.set("botId", `${bot}`);
    return this.http.post<FacebookPageChannel[]>(`${this.path}/wizard`, data);
  }
}
