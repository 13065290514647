<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">
        Nova Tarefa
      </span>
      <button
        mat-icon-button
        aria-label="Close dialog"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" (ngSubmit)="submitTask()">
    <mat-dialog-content>
      <div *ngIf="mapList; else spinner" class="task-list">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-hint>Dê um nome à sua tarefa</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Descrição</mat-label>
          <textarea matInput formControlName="description" rows="8"></textarea>
          <mat-hint>Markdown com uma descrição da tarefa</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Mapa</mat-label>
          <mat-select formControlName="map" [compareWith]="compareMap">
            <mat-option *ngFor="let map of mapList" [value]="map">{{
              map.title
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-template #spinner>
        <mat-spinner class="alabia-view-spinner"></mat-spinner>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button type="submit" [disabled]="!form.valid">Criar</button>
      <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>
