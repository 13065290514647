import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { AssetEditComponent } from './asset-edit/asset-edit.component';
import { AssetListComponent } from './asset-list/asset-list.component';
import { FloorEditComponent } from './floor-edit/floor-edit.component';
import { FloorListComponent } from './floor-list/floor-list.component';
import { RoomEditComponent } from './room-edit/room-edit.component';
import { RoomListComponent } from './room-list/room-list.component';

let routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "floors",
  },
  {
    path: "floors",
    component: FloorListComponent
  },
  {
    path: "floors/:floor/rooms",
    component: RoomListComponent
  },
  {
    path: "floors/:floor/rooms/:room/assets",
    component: AssetListComponent
  }
];

@NgModule({
  declarations: [
    FloorListComponent,
    FloorEditComponent,
    RoomListComponent,
    RoomEditComponent,
    AssetListComponent,
    AssetEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      }
    }
  ]
})
export class SpacesModule { }
