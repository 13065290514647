<div class="switch" [style.width.px]="size" [style.font-size.px]="fontSize">
  <form action="">
    <div class="switch-toggle switch-ios">

      <ng-container *ngFor="let btn of btns">
        <input [id]="btn.value" name="view" type="radio" [checked]="btn.checked" [disabled]="btn.disabled">
        <label [for]="btn.value" (click)="clickLabel(btn)"> {{btn.name}} </label>

      </ng-container>
      <a class="header-switch__slider" [style.backgroundColor]="backgroundColor"></a>
    </div>
  </form>
</div>
