<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{dialogTitle}}</span>
      <button mat-icon-button aria-label="Close dialog" (click)="matDialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form *ngIf="channelForm" [formGroup]="channelForm" ngNativeValidate>
    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
      <div class="" *ngIf="platform === 'Messenger'">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput name="name" formControlName="name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Token de Acesso</mat-label>
            <input matInput name="accessToken" formControlName="accessToken" required>
            <mat-hint>Token de acesso para acessar o Facebook</mat-hint>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Token de Verificação</mat-label>
            <input matInput name="verifyToken" formControlName="verifyToken" required>
            <mat-hint>Token de verificação cadastrar o webhook</mat-hint>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <p class="media-type-title">Tipos de mídias permitidas</p>
          <mat-list>
            <ng-container *ngFor="let mediaType of fbMediaTypes">
              <mat-list-item *ngIf="mediaType.isEnabled">
                <mat-checkbox name="{{ mediaType.inputName }}" formControlName="{{ mediaType.inputName }}">
                  {{ mediaType.name }}
                </mat-checkbox>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </div>
      </div>

      <div class="" *ngIf="platform === 'URA'">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Endereço do Servidor</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Usuario</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Senha</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Context</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Extension</mat-label>
            <input matInput name="skill_name" required>
          </mat-form-field>
        </div>
      </div>

      <div class="" *ngIf="platform === 'Chat'">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput name="name" required>
          </mat-form-field>
        </div>
      </div>

      <div class="" *ngIf="platform === 'Whatsapp'">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput name="name" formControlName="name" required>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Conta</mat-label>
            <input matInput name="account" formControlName="account" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Token</mat-label>
            <input matInput name="token" formControlName="token" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Número</mat-label>
            <input matInput name="number" formControlName="number" required>
          </mat-form-field>
        </div>
      </div>

      <div class="" *ngIf="platform === 'Google Play'">
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nome</mat-label>
            <input matInput name="name" formControlName="name" required>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Chave da API</mat-label>
            <input matInput name="apiKey" formControlName="apiKey" required>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Applicativo</mat-label>
            <input matInput name="appIdentity" formControlName="appIdentity" required>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nota de Corte</mat-label>
            <input matInput name="maxEval" formControlName="maxEval" required>
          </mat-form-field>
        </div>

      </div>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="action === 'edit'" type="button" mat-button class="mr-8" aria-label="Delete" matTooltip="Delete"
        (click)="matDialogRef.close(['delete', channelForm])">
        DELETAR
      </button>

      <button *ngIf="action !== 'edit'" type="submit" mat-button class="save-button" aria-label="SAVE"
        (click)="saveData(true)" [disabled]="channelForm.invalid">
        ADICIONAR
      </button>

      <button *ngIf="action === 'edit'" type="submit" mat-button class="save-button" aria-label="SAVE"
        (click)="saveData(false)" [disabled]="channelForm.invalid">
        SALVAR
      </button>
    </div>
  </form>
</div>