<h1 mat-dialog-title>Criar Intenção</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" [(ngModel)]="data.name" (keypress)="updateAutocomplete()" [matAutocomplete]="intent_auto">
    <mat-autocomplete #intent_auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions" [value]="option">{{ option }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-list-item>
    <mat-form-field>
      <input matInput type="text" placeholder="Descrição" [(ngModel)]="data.description">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="data.maxFallbacks" placeholder="Máximo de interações para essa intenção concluir" min="1">
    </mat-form-field>
  </mat-list-item>

  <mat-list-item>
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="data.lifespan" placeholder="Tempo de vida, em milisegundos" min="1000">
    </mat-form-field>
  </mat-list-item>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data">Criar</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>
