import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { SpeechProject } from './project.model';

export enum ExecutionStatus {
  REQUESTED = "REQUESTED",
  PROCESSING = "PROCESSING",
  DONE = "DONE",
  FAILED = "FAILED",
}

export interface ProjectExecution extends Ownable {
  project: SpeechProject;
  status: ExecutionStatus;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectExecutionService extends OwnableService<ProjectExecution> {
  protected path: string = `${this.config.mediaservice}/project/execution`;

  public downloadUrl(data: ProjectExecution): string {
    return `${this.path}/${data.id}/download`;
  }
}
