import { Component } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/types';

@Component({
  selector: 'alabia-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent {
  public functionalities: FuseNavigationItem[] = [];

  constructor(public navigation: FuseNavigationService) {
    this.functionalities = navigation.getFlatNavigation(navigation.getCurrentNavigation().filter(value => value.id !== "homepage")
    )
  }
}
