import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardListService, Graph } from 'onevoice';


@Component({
  selector: 'alabia-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    "name": new FormControl("", [Validators.required, Validators.minLength(3)]),
  });
  public adding: boolean = false;
  public graphList: { [name: string]: Graph[] } = {};

  constructor(public service: DashboardListService) { }

  public ngOnInit(): void {
    this.service.list().subscribe(list => {
      for (const graph of list) {
        if (this.graphList[graph.dashboard] === undefined) {
          this.graphList[graph.dashboard] = [];
        }
        this.graphList[graph.dashboard].push(graph);
      }
    });
  }

  get dashList(): string[] {
    return Object.keys(this.graphList);
  }

  public addDash() {
    console.debug("Adding a new dashboard");
    this.adding = false;
    this.graphList[this.form.value.name] = [];
    this.form.reset();
  }
}
