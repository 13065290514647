import { CommonModule, getLocaleNumberSymbol, NumberSymbol, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';


import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { NuMarkdownModule } from '@ng-util/markdown';
import { ChartsModule } from 'ng2-charts';
import { MyManualTasksComponent } from './my-manual-tasks/my-manual-tasks.component'
import { MachineMenuComponent } from './machine-menu/machine-menu.component';
import { EditMachinesComponent } from './machines/edit-machines/edit-machines.component';
import { ListMachinesComponent } from './machines/list-machines/list-machines.component';
import { EditMapsComponent } from './maps/edit-maps/edit-maps.component';
import { ListMapsComponent } from './maps/list-maps/list-maps.component';
import { MapsComponent } from './robot-dashboard/maps/maps.component';
import { RobotDashboardComponent } from './robot-dashboard/robot-dashboard.component';
import { ZoneComponent } from './robot-dashboard/zone/zone.component';
import { EditComponent } from './tasks/edit/edit.component';
import { ListComponent } from './tasks/list/list.component';
import { ManualComponent } from './tasks/manual/manual.component';
import { WebcamModule } from 'ngx-webcam';


registerLocaleData(localept, 'pt');
// const routes: Routes = [
//   {
//     path: ":id",
//     component: ManualComponent
//   }
// ];

const routes: Routes = [
  {
    path: "tasks",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ListComponent,
      },
      {
        path: ":id",
        component: ManualComponent,
      },

    ]
  },
  {
    path: "maps",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ListMapsComponent,
      }
    ]
  },
  {
    path: "instances",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ListMachinesComponent,
      }
    ]
  },
  {
    path: "manualtask",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: MyManualTasksComponent,
      }
    ]
  },
  {
    path: "",
    pathMatch: "full",
    component: MachineMenuComponent
  },
  {
    path: 'robot-dashboard',
    pathMatch: 'full',
    component: RobotDashboardComponent
  }

];

registerLocaleData(localept, 'pt');

getLocaleNumberSymbol('pt', NumberSymbol.CurrencyDecimal);


@NgModule({
  declarations: [
    MyManualTasksComponent,
    ManualComponent,
    ListComponent,
    EditComponent,
    ListMapsComponent,
    EditMapsComponent,
    EditMachinesComponent,
    ListMachinesComponent,
    MachineMenuComponent,
    RobotDashboardComponent,
    ZoneComponent,
    MapsComponent,

  ],
  exports: [RobotDashboardComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSelectModule,
    WebcamModule,
    MatInputModule,
    MatPaginatorModule,
    MatListModule,

    ReactiveFormsModule,
    NuMarkdownModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    ChartsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatCheckboxModule,

    FormsModule,
    HttpClientModule,
    MatDatepickerModule

  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ]
})
export class HwModule { }
