<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">
        Novo Mapa
      </span>
      <button mat-icon-button aria-label="Close dialog" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" (ngSubmit)="submitMap()">
    <mat-dialog-content>

      <mat-form-field appearance="outline">
        <mat-label>{{data.title == undefined ? "Dê um nome ao seu mapa" : data.title}}</mat-label>
        <input matInput type="text" formControlName="title"/>
      </mat-form-field>



      <mat-form-field appearance="outline">
        <mat-label>UUID</mat-label>
        <input matInput type="text" formControlName="uniqueId" />
        <mat-hint>Seu mapa precisa de um identificador único</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{data.unit == undefined ? 'Localização' : data.unit.name}}</mat-label>
        <mat-select [(value)]="default.data[0]" formControlName="location">

          <mat-option *ngFor="let location of dataSourceMapUnit.data" [value]="location" (click)="selectUnit(location)">
            {{location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>

      <button mat-raised-button type="submit" [disabled]="!form.valid">Criar</button>
      <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>
