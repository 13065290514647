import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service'


export interface MapUnit extends Ownable {
  name: string;
  description: string;
  uniqueId: string;
}

@Injectable({
  providedIn: 'root'
})

export class MapUnitService extends OwnableService<MapUnit> {
  protected path: string = `${this.config.endpoint}/hw/mapunit`;


  public save(mapUnit : MapUnit){

    return this.http.post<any>(this.path +"/save" ,mapUnit)

  }
}
