import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { ChatService } from 'app/alabia/chat/chat.service';
import { environment } from 'environments/environment';
import { AgentResponse, AgentResponseService, AgentService, ApiConfiguration, APP_CONFIG, AssignmentsService, Bot, ChangeType, ChannelType, Chat, MediaType, MemoryService, Message, ResponseType, Sender, STAR_RATING, User } from 'onevoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseSelectorComponent } from '../response-selector/response-selector.component';
import { TagData, TagDialogComponent } from '../tag-dialog/tag-dialog.component';
import { UploadComponent } from '../upload/upload.component';

@Component({
    selector: 'chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatViewComponent implements OnInit, OnDestroy, AfterViewInit {
    public chat?: Chat;
    public star_rating?: string;
    public Sender: any = Sender;

    @ViewChild(FusePerfectScrollbarDirective, { static: false })
    directiveScroll?: FusePerfectScrollbarDirective;

    @ViewChild('replyTextArea')
    set replyField(replyField: ElementRef<HTMLTextAreaElement>) {
        console.log("Get reply field");
        console.log(replyField);
        replyField.nativeElement.focus();
        this.focusReplyInput = () => {
            console.log("Get focus");
            setTimeout(() => replyField.nativeElement.focus(), 1000);
        }
    }

    public focusReplyInput: () => void = () => { };

    public replyMessage: FormControl;
    public tooglePicker: boolean = false;

    private _unsubscribeAll: Subject<any> = new Subject();

    public MediaType = MediaType;
    public ContentType = ResponseType;
    private agentResponseList: AgentResponse[] = [];

    constructor(
        public chatService: ChatService,
        public memoryService: MemoryService,

        public agent: AgentService,
        public assignments: AssignmentsService,
        private dialogService: MatDialog,
        private sanitizer: DomSanitizer,
        private agentResponse: AgentResponseService,
        private _bottomSheet: MatBottomSheet,
        @Inject(APP_CONFIG) private config: ApiConfiguration,
    ) {
        this.agentResponse.list().subscribe(list => {
            this.agentResponseList = list;
        });

        this.replyMessage = new FormControl("", (control: AbstractControl): ValidationErrors | null => {
            if (control.value.length > this.limit()) {
                return Validators.minLength(this.limit())(control);
            }
            return null;
        })
    }

    ngOnInit(): void {
        this.chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatData => {
                if (chatData) {
                    this.chat = chatData;
                    this.star_rating = undefined;
                    this.readyToReply();
                    if (this.chat.assignment) {
                        this.memoryService.list(this.chat.assignment.bot, this.chat.customer).subscribe(memory => {
                            memory.forEach(item => {
                                if (item.name.includes(STAR_RATING)) {
                                    this.star_rating = item.value;
                                }
                            })
                        })
                    }
                }
            });
    }

    ngAfterViewInit(): void {
        this.readyToReply();
        this.agent.OnMessage.subscribe(_ => this.scrollToBottom());
    }

    public openKeyboardEmoji() {
        let reference = this._bottomSheet.open(PickerComponent, {
            panelClass: "emoji-panel",
        });
        let instance = reference.instance;
        instance.emojiClick.subscribe(value => {
            console.log("Received", value.emoji.native);
            this.replyMessage.setValue(this.replyMessage.value + value.emoji.native)
        });
        instance.style = {
            "width": "100%",
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    shouldShowContactAvatar(message: Message, index: number): boolean {
        return message.sender == Sender.USER && this.isFirstMessageOfGroup(message, index);
    }

    contactAvatar(message?: Message): string {
        if (!message && this.chat) {
            message = this.chat.dialog[this.chat.dialog.length - 1];
        }
        return message && message.channel === ChannelType.FACEBOOK ? 'assets/images/avatars/facebook.png' : 'assets/images/avatars/chat.png';
    }

    contactName(): string {
        if (!this.chat) { return "" }
        return this.chat.customer.firstName;
    }

    isFirstMessageOfGroup(message: Message, index: number): boolean {
        if (!this.chat) {
            return true;
        }
        return (index === 0 || index > 0 && this.chat.dialog[index - 1].sender !== message.sender);
    }

    isLastMessageOfGroup(message: Message, index: number): boolean {
        if (!this.chat) {
            return true;
        }
        if (index + 1 < this.chat.dialog.length) {
            return this.isHuman(this.chat.dialog[index + 1]) !== this.isHuman(message);
        }
        return true;
    }

    isHuman(message: Message): boolean {
        return message.sender == Sender.USER;
    }

    public goToPermalink(message: Message) {
        var url: string = `${this.config.endpoint}/messages/${message.id}/original`;
        window.open(url, "_blank");
    }

    selectContact(): void {
        if (this.chat) {
            this.chatService.selectContact(this.chat.customer);
        }
    }

    readyToReply(): void {
        setTimeout(() => {
            this.focusReplyInput();
            this.scrollToBottom();
        }, 1000);
    }

    scrollToBottom(speed?: number): void {
        speed = speed || 400;
        if (this.directiveScroll) {
            this.directiveScroll.update();

            setTimeout(() => {
                if (this.directiveScroll) {
                    this.directiveScroll.scrollToBottom(0, speed);
                }
            });
        }
    }

    reply(event: Event): void {
        event.preventDefault();

        if (!this.replyMessage || !this.chat) {
            return;
        }
        this.agent.sendMessage(this.chat, this.replyMessage.value);

        // Reset the reply form
        this.replyMessage.setValue("");
        this.readyToReply();
    }

    backAssignment() {
        if (this.chat && this.chat.assignment) {
            this.assignments.updateAssignment(this.chat.assignment, ChangeType.OPEN);
        }
    }

    closeAssignment() {
        let data: TagData = {
            description: "",
            status: ChangeType.REPLIED,
        };

        if (this.chat && this.chat.assignment) {
            data.description = this.chat.assignment.description;
            data.tag = this.chat.assignment.tag;
        }

        let reference = this.dialogService.open<TagDialogComponent, TagData, TagData>(TagDialogComponent, {
            data: data,
            maxWidth: "80%",
            width: "800px",
        });
        reference.afterClosed().subscribe(data => {
            if (data && data.tag && this.chat && this.chat.assignment) {
                this.assignments.updateAssignment(this.chat.assignment, data.status, data.tag, data.description);
                this.chat.assignment.tag = data.tag;
                this.chat.assignment.description = data.description;
            }
        })
    }

    loadHistory() {
        if (this.chat) {
            this.agent.loadHistory(this.chat);
        }
    }

    uploadDialog() {
        this.dialogService.open<UploadComponent, Chat, boolean>(UploadComponent, {
            data: this.chat,
            maxWidth: "80%",
            width: "500px",
        }).afterClosed().subscribe(ok => {
            console.log("Finished: ", ok);
        });
    }

    public processText(content: string) {
        if (content == null) {
            return ""
        }
        if (content.startsWith("#")) {
            try {
                let command: any = JSON.parse(atob(content.replace("#", "")));
                return `Execute: ${command.intent}`;
            } catch (error) {

            }
        }
        return content;
    }

    public processDownload(content: string) {
        if (content.startsWith("http://") || content.startsWith("https://")) {
            return this.sanitizer.bypassSecurityTrustUrl(content);
        }
        return this.sanitizer.bypassSecurityTrustUrl(`${environment.onevoiceStaticUrl}${content}`);
    }

    public openAgentResponses() {
        let reference = this.dialogService.open<ResponseSelectorComponent, Bot | undefined, AgentResponse[]>(ResponseSelectorComponent, {
            data: this.chat?.assignment?.bot,
            width: "800px",
            maxWidth: "80%",
        });

        reference.afterClosed().subscribe(selected => {
            if (selected) {
                this.replyMessage.setValue(selected.map(
                    value => value.content,
                ).reduce(
                    (prev: string, curr: string) => `${curr}\n\n${prev}`,
                    this.replyMessage.value,
                ).trim());
            }
        });
    }

    public userFullname(user: User) {
        let fullname = `${user.details.first_name || ""} ${user.details.last_name || ""}`.trim();
        if (fullname.length <= 0) {
            fullname = user.email;
        }
        return fullname;
    }

    public limit() {
        if (this.chat && this.chat.dialog.length > 0 && this.chat.dialog[this.chat.dialog.length - 1].channel == ChannelType.GOOGLE_PLAY) {
            return 350;
        }
        return 4096;
    }
}
