import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Colors, Label, PluginServiceGlobalRegistrationAndOptions, SingleDataSet } from 'ng2-charts';
import { AssignmentsService, Bot, BotMemory, BotService, ChangeType, ConclusionTag, ConclusionTagService, IntentService, MemoryService, QueueAssignment, ReportService as ReportServiceApi, User, UserService } from 'onevoice';
import { Subject } from 'rxjs';
import { ChannelService } from '../../api/channel.service';
import { ReportApiRequestService } from '../../api/report-api-request.service';
import { ReportService } from '../../api/report.service';
import * as options from '../options';

const EvaluationAgentMemory = "Pontuação do Atendimento";
const EvaluationBotMemory = "Pontuação do Bot"

function hourlyInitial(): { [id: string]: number; } {
  let data: { [id: string]: number; } = {};

  for (let index = 0; index < 24; index++) {
    data[`${index}`.padStart(2, "0")] = 0;
  }
  return data;
}

@Component({
  selector: 'main-extended-dashboard',
  templateUrl: './extended-dashboard.component.html',
  styleUrls: ['./extended-dashboard.component.scss']
})
export class ExtendedDashboardComponent implements OnInit {
  public appColors: Colors[] = options.colors2;
  public widgetConfig: ChartOptions = options.configWidgets;
  public colors = [options.colors];

  public singleColor = [
    {
      borderColor: '#8e24aa',
      backgroundColor: '#8e24aa',
      pointBackgroundColor: '#8e24aa',
      pointHoverBackgroundColor: '#8e24aa',
      pointBorderColor: '#ffffff',
      pointHoverBorderColor: '#ffffff'
    }
  ];

  public from: Date;
  public to: Date;
  public agent?: User;
  public tag: ConclusionTag[] = [];
  public bot?: Bot;

  private _unsubscribeAll: Subject<any>;

  public userList: User[] = [];
  public tagList: ConclusionTag[] = [];
  public assignmentList: QueueAssignment[] = [];
  public convCount: number = 0;
  public botList: Bot[] = [];

  public userSelectable: boolean = true;

  public opened: QueueAssignment[] = [];
  public wip: QueueAssignment[] = [];
  public done: QueueAssignment[] = [];
  public filteredAssignments: QueueAssignment[] = [];
  public avgTime: number = 0.0;

  @Input()
  public set user(single: User) {
    this.userList = [single];
    this.userSelectable = false;
  }

  public lineChartColor: Colors[] = [{
    borderColor: ['#8e24aa'],
    backgroundColor: ['#8e24aa'],
    pointBackgroundColor: ['#8e24aa'],
    pointHoverBackgroundColor: ['#8e24aa'],
    pointBorderColor: '#ffffff',
    pointHoverBorderColor: '#ffffff'
  }];

  public lineChartConfig: ChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,

        },
      }],
      xAxes: [{
        display: true,
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };



  public hourChartConfig: ChartOptions = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,

        },
      }],
      xAxes: [{
        display: true,
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, context) => {
          return this.displayTime(value);
        },
      },
    },
  };


  public doughnutChartConfig: ChartOptions = {
    legend: {
      display: false,
    },
  };

  public showLabels: PluginServiceGlobalRegistrationAndOptions[] = [
    pluginDataLabels as PluginServiceGlobalRegistrationAndOptions,
    {
      id: "customScale",
      beforeLayout: (chart: any, options: any) => {
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE
        let grace = 0.05;

        chart.data.datasets.forEach((dataset: any) => {
          max = Math.max(max, Math.max(...dataset.data));
          min = Math.min(min, Math.min(...dataset.data))
        })

        if (chart.options.scales === undefined) {
          return;
        }

        chart.options.scales.yAxes.forEach((axe: any) => {
          axe.ticks.suggestedMax = max + grace * max;
          axe.ticks.suggestedMin = min - grace * min;
        })
      }
    }
  ];

  public answeredAssignmentsLabels: Label[] = [];
  public answeredAssignmentsData: SingleDataSet = [];

  public openedAssignmentsLabels: Label[] = [];
  public openedAssignmentsData: SingleDataSet = [];

  public combinedAssignments: ChartDataSets[] = [];


  public slaLabels: Label[] = [];
  public slaData: SingleDataSet = [];

  public tagLabels: Label[] = [];
  public tagData: SingleDataSet = [];

  public firstResponseLabels: Label[] = [];
  public firstResponseData: SingleDataSet = [];

  public hourlyLabels: Label[] = [];
  public hourlyData: SingleDataSet = [];

  public evaluationLabels: Label[] = [];
  public evaluationData: SingleDataSet = [];

  public evaluationBotLabels: Label[] = [];
  public evaluationBotData: SingleDataSet = [];

  public activityPeakLabels: Label[] = [];
  public activityPeakData: SingleDataSet = [];

  public ratioData: ChartDataSets[] = [];
  public ratioLabels: Label[] = [];

  public slaPerLabels: Label[] = [];
  public slaPerData: SingleDataSet = [];


  public TIMESTEP = {
    "yyyy-MM-dd": "Diário",
    "yyyy-MM": "Mensal",
    "yyyy": "Anual",
    "yyyy-MM-ddTHH": "Hora",
  }
  public timestep: string = Object.keys(this.TIMESTEP)[0];
  timestepList: [string, string][];

  constructor(
    private report: ReportService,
    private intents: IntentService,
    private channels: ChannelService,
    private _reportServiceApi: ReportServiceApi,
    private _ReportApiRequestService: ReportApiRequestService,
    private assignments: AssignmentsService,
    private userService: UserService,
    private tagService: ConclusionTagService,
    private botService: BotService,
    private memoryService: MemoryService,
  ) {
    this._unsubscribeAll = new Subject();
    userService.list().subscribe(userList => {
      this.userList = userList;
    })
    tagService.list().subscribe(tagList => {
      this.tagList = tagList;
    });
    botService.list().subscribe(botList => {
      this.botList = botList;
    });
    let now = new Date();
    this.to = new Date(now.getTime() + 1000 * 60 * 60 * 24);
    this.from = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30);

    this.timestepList = Object.entries(this.TIMESTEP);
  }

  ngOnInit(): void {
    this.updateAssignments();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public displayTime(delta: number): string {
    let hour = Math.floor(delta);
    let minute = Math.floor((delta - hour) * 60);
    let second = Math.floor(((delta - hour) * 60 - minute) * 60);
    let raw = `${hour}h ${minute}m ${second}s`;
    return raw.replace("0m", "").replace("0h", "").replace("0s", "").replace("  ", " ").trim();
  }

  private convertTimestep(): number {
    if (this.timestep.includes("HH")) return 1000 * 60 * 60;
    if (this.timestep.includes("dd")) return 1000 * 60 * 60 * 24;
    if (this.timestep.includes("MM")) return 1000 * 60 * 60 * 24 * 30;
    return 1000 * 60 * 60 * 365;
  }

  private initDataWithDates(dates: (number | undefined | null)[], initval: number = 0) {
    let fixeddates = dates.filter(value => !!value) as number[];
    let min = Math.min(...fixeddates), max = Math.max(...fixeddates);
    let data: { [id: string]: number; } = {};

    for (let curr = min; curr < max; curr = curr + this.convertTimestep()) {
      data[this.keyFromData(new Date(curr))] = initval;
    }
    return data;
  }

  updateAssignments() {
    this.assignments.list(true, this.from, this.to).subscribe(assignmentList => {
      this.assignmentList = assignmentList;

      this.filteredAssignments = this.filterAssignments(assignmentList);

      this.opened = this.filteredAssignments.filter(value => value.status == ChangeType.OPEN);
      this.wip = this.filteredAssignments.filter(value => value.status == ChangeType.WIP);
      this.done = this.filteredAssignments.filter(value => value.status == ChangeType.DONE);

      this.avgTime = this.calcAvgTime();



      let answeredAssignments: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.answered));
      for (const assignment of this.done) {
        if (assignment.answered !== null && assignment.answered !== undefined) {
          let key = this.keyFromData(new Date(assignment.answered));
          this.increment(answeredAssignments, key);
        }
      }
      let { keys, dataset } = this.generateData(answeredAssignments);
      this.answeredAssignmentsLabels = keys;
      this.answeredAssignmentsData = dataset;

      let openedAssignments: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.answered));
      for (const assignment of this.assignmentList) {
        let key = this.keyFromData(new Date(assignment.created));
        this.increment(openedAssignments, key);
      }
      ({ keys, dataset } = this.generateData(openedAssignments));
      this.openedAssignmentsLabels = keys;
      this.openedAssignmentsData = dataset;


      this.combinedAssignments = [
        {
          label: "Abertos",
          data: this.openedAssignmentsData,
        },
        {
          label: "Fechados",
          data: this.answeredAssignmentsData,
        }
      ];

      let slaRaw: { [id: string]: number; } = this.initDataWithDates(this.done.map(value => value.update));
      let slaCount: { [id: string]: number; } = this.initDataWithDates(this.done.map(value => value.update));
      for (const assignment of this.done) {
        let enddate = Math.max(assignment.update || 0, assignment.answered || 0);
        if (enddate <= 100) {
          continue;
        }
        let key = this.keyFromData(new Date(enddate));
        this.increment(slaRaw, key, this.timeDelta(assignment.created, enddate));
        this.increment(slaCount, key)
      }

      ({ keys, dataset } = this.generateData(this.divideData(slaRaw, slaCount)));
      this.slaLabels = keys;
      this.slaData = dataset;
      console.log("SLA", this.slaData);

      let slaPerRaw: number[] = [];
      for (const assignment of this.done) {
        let enddate = Math.max(assignment.update || 0, assignment.answered || 0);
        if (enddate <= 100) {
          continue;
        }
        slaPerRaw.push(this.timeDelta(assignment.created, enddate));
      }

      this.slaPerLabels = ["< 2 horas", "< 24 horas", "< 48horas", ">= 48horas"];
      this.slaPerData = [
        slaPerRaw.filter(value => value < 2.0).length,
        slaPerRaw.filter(value => 2.0 <= value && value < 24.0).length,
        slaPerRaw.filter(value => 24.0 <= value && value < 48.0).length,
        slaPerRaw.filter(value => value >= 48.0).length,
      ];

      let firstResponseRaw: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.answered));
      let firstResponseCount: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.answered));
      for (const assignment of this.filteredAssignments) {
        if (assignment.answered == null || assignment.answered == undefined) {
          continue;
        }
        let key = this.keyFromData(new Date(assignment.answered));
        this.increment(firstResponseRaw, key, this.timeDelta(assignment.created, assignment.answered));
        this.increment(firstResponseCount, key)
      }

      ({ keys, dataset } = this.generateData(this.divideData(firstResponseRaw, firstResponseCount)));
      this.firstResponseLabels = keys;
      this.firstResponseData = dataset;
      console.log("First Response", dataset);

      let tagRaw: { [id: string]: number; } = {};
      for (const assignment of this.filteredAssignments) {
        if (assignment.tag == null || assignment.tag == undefined) {
          continue;
        }
        let key = `${assignment.tag?.title} - ${assignment.tag?.description}`;
        this.increment(tagRaw, key)
      }
      ({ keys, dataset } = this.generateDataByValue(tagRaw));
      this.tagLabels = keys;
      this.tagData = dataset;

      let hourlyRequest: { [id: string]: number; } = hourlyInitial();

      for (const assignment of this.filteredAssignments) {
        let key = formatDate(new Date(assignment.created), "HH", "en-US");
        this.increment(hourlyRequest, key)
      }
      ({ keys, dataset } = this.generateData(hourlyRequest));
      this.hourlyLabels = keys;
      this.hourlyData = dataset;
    });

    this.memoryService.list().subscribe((memoryList: BotMemory[]) => {
      let evaluationRaw: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.update));
      let evaluationCount: { [id: string]: number; } = this.initDataWithDates(this.filteredAssignments.map(value => value.update));

      for (const memory of memoryList) {
        if (memory.assignment && memory.name == EvaluationAgentMemory && this.isNumber(memory.value)) {
          let assignment = this.filteredAssignments.find(assignment => assignment.id == memory.assignment);

          if (assignment != null) {
            let key = this.keyFromData(new Date(assignment.update));
            this.increment(evaluationRaw, key, Number(memory.value));
            this.increment(evaluationCount, key)
          }
        }
      }

      let { keys, dataset } = this.generateData(this.divideData(evaluationRaw, evaluationCount));
      this.evaluationLabels = keys;
      this.evaluationData = dataset;


      evaluationRaw = {};
      evaluationCount = {};

      for (const memory of memoryList) {
        if (memory.assignment && memory.name == EvaluationBotMemory && this.isNumber(memory.value)) {
          let assignment = this.filteredAssignments.find(assignment => assignment.id == memory.assignment);

          if (assignment != null) {
            let key = this.keyFromData(new Date(assignment.update));
            this.increment(evaluationRaw, key, Number(memory.value));
            this.increment(evaluationCount, key)
          }
        }
      }

      ({ keys, dataset } = this.generateData(this.divideData(evaluationRaw, evaluationCount)));
      this.evaluationBotLabels = keys;
      this.evaluationBotData = dataset;

    });

    this._reportServiceApi.conversationCount(this.from, this.to).subscribe(count => {
      this.convCount = count;
    });

    this._reportServiceApi.activityPeak(this.from, this.to, this.bot ? this.bot.id : undefined).subscribe(data => {
      let activityPeakRaw: { [id: string]: number; } = hourlyInitial();

      for (const item of data) {
        activityPeakRaw[item[0] as string] = item[1] as number;
      }
      let { keys, dataset } = this.generateData(activityPeakRaw);
      this.activityPeakLabels = keys;
      this.activityPeakData = dataset;
    });

    this._reportServiceApi.agenBotRatio(this.from, this.to, this.bot ? this.bot.id : undefined).subscribe(data => {
      const AGENT = 1, ROBOT = 2;
      let agentCount: { [id: string]: number; } = {};
      let botCount: { [id: string]: number; } = {};

      for (const item of data) {
        let [date, sender, count] = item;

        if (sender == AGENT) {
          agentCount[date as string] = count;
          botCount[date as string] = botCount[date] || 0;
        }
        else if (sender == ROBOT) {
          agentCount[date as string] = agentCount[date] || 0;
          botCount[date as string] = count;
        }
        else {
          // User sent this
        }
      }
      let { keys, dataset } = this.generateData(agentCount);
      let agentData = dataset;

      ({ keys, dataset } = this.generateData(botCount));
      let botData = dataset;

      this.ratioData = [
        {
          label: "Bot",
          data: botData,
        },
        {
          label: "Agent",
          data: agentData,
        }
      ];
      this.ratioLabels = keys;
    });

  }

  private isNumber(value: any) {
    try {
      Number(value);
    } catch {
      return false;
    }
    return true;
  }

  public OPEN_HOURS: { [id: number]: number[] } = {
    1: [8, 18],
    2: [8, 18],
    3: [8, 18],
    4: [8, 18],
    5: [8, 18],
    6: [8, 12],
  }

  // returns a time delta in hours
  public timeDelta(date1: number, date2: number) {
    if (date1 >= date2) {
      return 0.00;
    }
    let val1 = this.nextValidDate(new Date(date1)),
      val2 = this.nextValidDate(new Date(date2));

    let current = val1, hours: number = 0.0;

    while (!this.sameDay(current, val2)) {
      if (this.isWorkDay(current)) {
        hours += this.filterHours(this.closing(current) - (current.getHours() + current.getMinutes() / 60.0), current);
      }
      current.setDate(current.getDate() + 1);
      if (this.isWorkDay(current)) {
        hours += this.filterHours((current.getHours() + current.getMinutes() / 60.0) - this.opening(current), current);
      }
    }
    hours += (val2.getTime() - current.getTime()) / (1000.0 * 60.0 * 60.0);

    return Math.max(Math.trunc(hours * 100.0) / 100.0, 0.0);
  }

  private filterHours(value: number, date: Date) {
    return Math.min(
      0.0,
      Math.max(
        this.closing(date) - this.opening(date),
        value
      )
    );
  }

  public isWorkDay(date: Date): boolean {
    return !!this.OPEN_HOURS[date.getDay()];
  }

  public opening(date: Date) {
    return this.OPEN_HOURS[date.getDay()][0];
  }
  public closing(date: Date) {
    return this.OPEN_HOURS[date.getDay()][1];
  }

  public sameDay(date1: Date, date2: Date) {
    return date1.getFullYear() >= date2.getFullYear() && date1.getMonth() >= date2.getMonth() && date1.getDate() >= date2.getDate();
  }

  public nextValidDate(date: Date) {
    while (!this.OPEN_HOURS[date.getDay()]) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }

    let [start, finish] = this.OPEN_HOURS[date.getDay()];

    if (date.getHours() < start) {
      date.setHours(start);
      date.setMinutes(0);
    } else if (date.getHours() >= finish) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }

    while (!this.OPEN_HOURS[date.getDay()]) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }
    return date;
  }

  private divideData(data1: { [id: string]: number; }, data2: { [id: string]: number; }) {
    let data: { [id: string]: number; } = {};
    Object.keys(data1).forEach(key => {
      data[key] = data2[key] ? Math.trunc(data1[key] * 100.0 / data2[key]) / 100.0 : 0.0;
    });
    return data;
  }

  private increment(data: { [id: string]: number; }, key: string, value: number = 1.0) {
    data[key] = (key in data ? data[key] : 0.0) + value;
  }

  private generateData(data: { [id: string]: number; }): { keys: Label[], dataset: SingleDataSet } {
    let keys = Object.keys(data).sort((a, b) => (a < b) ? -1 : 1);
    let dataset = keys.map(value => data[value]);
    return { keys, dataset };
  }

  private generateDataByValue(data: { [id: string]: number; }): { keys: Label[], dataset: SingleDataSet } {
    let keys = Object.keys(data).sort((a, b) => (data[a] > data[b]) ? -1 : 1);
    let dataset = keys.map(value => data[value]);
    return { keys, dataset };
  }


  private keyFromData(data: Date) {
    return formatDate(data, this.timestep, "en-US");
  }

  private filterAssignments(list: QueueAssignment[]): QueueAssignment[] {
    if (this.tag !== undefined) {
      list = list.filter(value => this.tag.length == 0 || (value.tag && this.tag.find(t => value.tag && value.tag.id == t.id)));
    }

    if (this.agent !== undefined) {
      list = list.filter(value => value.agent?.id == this.agent?.id);
    }
    if (this.bot !== undefined) {
      list = list.filter(value => value.bot.id == this.bot?.id);
    }
    return list;
  }

  private calcAvgTime() {
    let data = this.filteredAssignments.filter(value => value.answered !== undefined);
    let avg = 0.0;
    for (const value of data) {
      if (value.answered) {
        avg = avg + this.timeDelta(value.created, value.answered);
      }
    }
    if (data.length <= 0) {
      return 0.0;
    }
    return Math.trunc((avg / data.length) * 100.0) / 100.0;
  }
}
