<div *ngIf="customerData" id="contacts" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="header accent p-16" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center" fxFlex>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <a class="btn-back" mat-button matRipple (click)="backButton()">
          <mat-icon>arrow_back</mat-icon>
        </a>
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          supervisor_account
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{ this.customerData.firstName ? this.customerData.firstName + ' ' + this.customerData.lastName : 'Customer #' + this.customerData.id }}
        </span>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="center p-24" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="content">

        <div class="customer-view-content">
          <alabia-customer-details [customerData]="this.customerData"></alabia-customer-details>
        </div>

      </div>
      <!-- / CONTENT -->
    </div>
  </div>
</div>