import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { AuthenticationService, MachineExecutionService, ScheduleService, TaskSchedule, TimedTask, MachineScheduler, MachineSchedulerService, MachineExecution, MachineTask, MachineMapService, MachineMap, MapUnit, MapUnitService, MachineTaskService } from 'onevoice';


@Component({
  selector: 'cleaning-calendar',
  templateUrl: './cleaning-calendar.component.html',
  styleUrls: ['./cleaning-calendar.component.scss'],
})
export class CleaningCalendarComponent {

  currentDate: Date = new Date();
  hours: string[] = []
  weekDays: number[] = [];
  weekTasks: TimedTask[][] = [];
  public startDay?: Date;
  public endDay?: Date;
  allTasksInScheduler: MachineTask[] = [];
  executedTasksExecution: MachineExecution[] = [];


  calendarDays: Date[] = [];
  schedule: MachineScheduler[] = [];
  offplanExecutions: MachineTask[] = [];
  pendingTasks: MachineTask[] = [];
  executedOnPlan: MachineTask[] = [];
  unitList: MapUnit[] = []


  dayText: String = ""


  scheduler: TimedTask[][] = []
  taskStatus: string = 'hourglass_empty'
  machineExecutionList: MachineExecution[] = [];

  schedulerMonday: TimedTask[] = [];
  schedulerThursday: TimedTask[] = [];
  schedulerWednesday: TimedTask[] = [];
  schedulerTuesday: TimedTask[] = [];
  schedulerFriday: TimedTask[] = [];
  schedulerSaturday: TimedTask[] = [];
  executedTasks: MachineTask[] = [];

  schedulerSunday: TimedTask[] = [];

  constructor(
    public executionService: MachineExecutionService,
    public auth: AuthenticationService,
    public serviceScheduler: MachineSchedulerService,
    public mapUnitService: MapUnitService,
  ) { }
  ngOnChanges(): void {

  }

  selectDay(data: MachineScheduler[]): void {
    this.resetScheduler()
    data.forEach(schedule => {
      schedule.timedTaskList.forEach(task => {
        task.machine = schedule.machine;
        task.repeat.forEach(day => {
          switch (day) {
            case 1:
              this.schedulerSunday.push(task);
              break;
            case 2:
              this.schedulerMonday.push(task);
              break;
            case 3:
              this.schedulerTuesday.push(task);
              break;
            case 4:
              this.schedulerWednesday.push(task);
              break;
            case 5:
              this.schedulerThursday.push(task);
              break;
            case 6:
              this.schedulerFriday.push(task)
              break;
            case 7:
              this.schedulerSaturday.push(task);
              break;

          }
        })
      })

    })
    this.scheduler.push(this.schedulerSunday);
    this.scheduler.push(this.schedulerMonday);
    this.scheduler.push(this.schedulerTuesday);
    this.scheduler.push(this.schedulerWednesday);
    this.scheduler.push(this.schedulerThursday);
    this.scheduler.push(this.schedulerFriday);
    this.scheduler.push(this.schedulerSaturday);

    this.orderTasks()

    this.weekTasks = this.scheduler.slice(0, 7)

  }

  resetScheduler(){
    this.scheduler = [];
    this.schedulerSunday = [];
    this.schedulerMonday = [];
    this.schedulerThursday = [];
    this.schedulerWednesday = [];
    this.schedulerTuesday = [];
    this.schedulerFriday = [];
    this.schedulerSaturday = [];
  }
  loadExecutions() {

    this.startDay = new Date();
    this.startDay.setHours(0, 0, 0, 0);
    this.endDay = new Date(this.startDay);
    this.endDay.setDate(this.startDay.getDate() + 1);

    let params = new HttpParams();
    params = params.append('startDate', this.startDay.getTime());
    params = params.append('endDate', this.endDay.getTime());

    this.executionService.list(params).subscribe(data => {
      this.machineExecutionList = data;
      data.forEach(execution => {
        this.executedTasks.push(execution.task)

      })

      this.getPending()
    })

  }
  orderTasks(): void {

    this.scheduler.forEach(scheduler => {
      scheduler.sort((a, b) => {
        if (a.startTime < b.startTime) return -1;

        if (a.startTime > b.startTime) return 1;
        return 0;
      })

    })
  }
  getStatus(task: TimedTask): String {
    this.executedTasksExecution.forEach(single => {
      if (single.task == task.machineTask) {
        return "check_circle"
      } else {
        return "circle";
      }
    })
    return "circle";
  }
  ngOnInit(): void {
    this.auth.systemToken().then(_ => {
      this.loadScheduler();
      this.loadExecutions();
      this.listMaps();
    });

  }
  loadScheduler(): void {
    this.serviceScheduler.list().subscribe(task => {
      this.schedule = task;
      this.selectDay(task);
      this.listHours(task);
      task.forEach(singleData=>{
        singleData.timedTaskList.forEach(singleTask=>{
          this.allTasksInScheduler.push(singleTask.machineTask)
        })
      })
    })


  }

  listMaps() {

    this.mapUnitService.list().subscribe(mapsList => {
      this.unitList = mapsList;
    })

  }
  listHours(schedulers: MachineScheduler[]) {
    schedulers.forEach(scheduler => {
      scheduler.timedTaskList.forEach(task => {
        this.hours.push(task.startTime.slice(0, -6))
      })
    })
    this.hours = this.hours.filter((item, index, self) => {
      return self.indexOf(item) === index;

    });
    this.weekCalendar();
    this.hours.sort()
  }

  selectUnit(event: any) {
    if(event == 'all'){
      return this.loadScheduler();
    }
    this.schedule = [];
    this.serviceScheduler.list().subscribe(list => {
        list.forEach(single=>{
          if(single.machine.location.name == event.name){
            this.schedule.push(single)
          }
        })
        this.selectDay(this.schedule);
        this.listHours(this.schedule);
        this.weekCalendar();

    })

  }



  getDayText(day: number): string {
    var week = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    return week[day]

  }
  getPending() {
    this.executedTasks.forEach(taskExecuted => {
      this.allTasksInScheduler.forEach(taskSchedule => {
        if (taskExecuted == taskSchedule) {
          this.executedOnPlan.push(taskExecuted)
        }
      })
      this.getOffplanTasks()
    })
    // this.offplanExecutions = this.executedTasks.filter(task => !todayTasks.some(t => t.id === task.id));
    let schedulerToday = this.scheduler[this.currentDate.getDay()];
    let tasksToday : MachineTask[] = [];

    schedulerToday.forEach(single=>{
      tasksToday.push(single.machineTask)
    })

    this.pendingTasks = tasksToday.filter(x => !this.executedTasks.some(t => t.id === x.id))
    this.getOffplanTasks()
  }
  weekCalendar(): void {

    const firstDayWeek = 0;
    const lastDayWeek = 6;

    const currentDay = new Date();

    while (currentDay.getDay() !== 0) {
      currentDay.setDate(currentDay.getDate() - 1);
    }

    while (currentDay.getDay() < lastDayWeek) {
      this.weekDays.push(currentDay.getDate())
      currentDay.setDate(currentDay.getDate() + 1)
    }
    this.weekDays.push(currentDay.getDate())
  }


  status(task: MachineTask, day: number) {

    let status = 'hourglass_empty'
    if (day <= this.currentDate.getDate()) {
      this.machineExecutionList.forEach(single => {
        if (task.name == single.task.name) {
          if (single.start != undefined && single.end == undefined) {
            status = "hourglass_bottom"
          }
          if (single.start != undefined && single.end != undefined) {
            status = "hourglass_full"
          }
        }

      })
    }

    return status
  }



  tasksToDay() {

  }
  getOffplanTasks(): void {
    let todayTasks : MachineTask[] = [];
    this.scheduler[this.currentDate.getDay()].forEach(tiemdTask=>{
      todayTasks.push(tiemdTask.machineTask)
    })
    console.log(this.executedTasks);
    this.offplanExecutions = this.executedTasks.filter(task => !todayTasks.some(t => t.id === task.id));

  }

  // getOffplanTasks() {
  //   this.machineExecutionList.forEach(executedTask => {
  //     this.scheduler[this.currentDate.getDay()].forEach(taskInScheduler => {
  //       if (executedTask.task == taskInScheduler.machineTask) {
  //         this.offplanExecutions.push(executedTask)
  //       }
  //     })
  //   })

  //   let timedtasks : MachineTask[] = []
  //   this.scheduler[this.currentDate.getDay()].forEach(timedTask=>{
  //     timedtasks.push(timedTask.machineTask)
  //   })

  //   this.offplanExecutions = this.machineExecutionList.filter(exec => !timedtasks.includes(exec.task))

  // }
}
