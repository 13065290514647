
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FacebookHook, FacebookHookService } from 'app/alabia/api/facebook-hook.service';
import { WhatsappHookService } from 'app/alabia/api/whatsapp-hook.service';
import { Bot, BotService, Channel, EmailChannel, EmailChannelService, FacebookPageChannel, FacebookPageChannelService, GooglePlayChannelService, YoutubeChannel, YoutubeChannelService } from 'onevoice';
import { ChannelDialogComponent } from '../../dialogs/channel-dialog/channel-dialog.component';
import { ChannelEditData, EmailChannelEditComponent, FacebookPageEditComponent, YoutubeEditComponent } from '../../dialogs/channel-edit/channel-edit.component';
import { EmailChannel as EmailChannelName, FacebookPage, GooglePlay, YouTubeChannel } from '../../platforms';

export interface channelTypes {
    name: string;
    class: string;
    icon_path: string;
    isEnabled: boolean;
}

export interface channelList {
    type: any;
    channels: any[];
}

@Component({
    selector: 'alabia-channel-detail',
    templateUrl: './channel-detail.component.html',
    styleUrls: ['./channel-detail.component.scss'],
})
export class ChannelDetailComponent implements OnInit {
    bot?: Bot;
    hooks: FacebookHook[] = [];
    dialogRef: any;
    myChannelsCount: number = 0;

    media: boolean = false;

    channelList: channelList[] = [];

    font_awesome_path: string = '../../../../../assets/icons/font-awesome';

    channelTypes: channelTypes[] = [
        {
            class: 'messenger',
            name: 'Messenger',
            icon_path: `${this.font_awesome_path}/facebook-messenger.svg`,
            isEnabled: false,

        },
        {
            class: 'facebook-page',
            name: FacebookPage,
            icon_path: `${this.font_awesome_path}/facebook-messenger.svg`,
            isEnabled: true,

        },
        {
            class: 'youtube-channel',
            name: YouTubeChannel,
            icon_path: `${this.font_awesome_path}/google-play.svg`,
            isEnabled: true,
        },
        {
            class: 'whatsapp',
            name: 'Whatsapp',
            icon_path: `${this.font_awesome_path}/whatsapp.svg`,
            isEnabled: true,

        },
        {
            class: 'google-play',
            name: GooglePlay,
            icon_path: `${this.font_awesome_path}/google-play.svg`,
            isEnabled: true,

        },
        {
            class: 'email-channel',
            name: EmailChannelName,
            icon_path: `${this.font_awesome_path}/google-play.svg`,
            isEnabled: true,

        },

        {
            class: 'default',
            name: 'Chat',
            icon_path: `${this.font_awesome_path}/comment-alt.svg`,
            isEnabled: false,

        },
        {
            class: 'default',
            name: 'URA',
            icon_path: `${this.font_awesome_path}/phone-square-alt.svg`,
            isEnabled: false,

        },
        {
            class: 'default',
            name: 'SMS',
            icon_path: `${this.font_awesome_path}/sms.svg`,
            isEnabled: false,

        },
        {
            class: 'telegram',
            name: 'Telegram',
            icon_path: `${this.font_awesome_path}/telegram-plane.svg`,
            isEnabled: false,

        },
        {
            class: 'skype',
            name: 'Skype',
            icon_path: `${this.font_awesome_path}/skype.svg`,
            isEnabled: false,

        },
        {
            class: 'slack',
            name: 'Slack',
            icon_path: `${this.font_awesome_path}/slack.svg`,
            isEnabled: false,
        },
    ];

    constructor(
        private route: ActivatedRoute,
        private _botService: BotService,
        private _facebookService: FacebookHookService,
        private _whatsappService: WhatsappHookService,
        private _googleService: GooglePlayChannelService,
        private _emailService: EmailChannelService,
        private _fbService: FacebookPageChannelService,
        private _ytService: YoutubeChannelService,
        private _matDialog: MatDialog,
    ) { }

    ngOnInit() {
        this.getBotChannels();
    }

    getBotChannels() {
        this.route.paramMap.subscribe(params => {
            let botid = Number(params.get('id'));
            this._botService.read(botid).subscribe(bot => {
                this.bot = bot;
                this.channelList = [];
                this._facebookService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder('Messenger', hooks));
                });
                this._whatsappService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder('Whatsapp', hooks));
                });

                this._googleService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder(GooglePlay, hooks));
                });


                this._emailService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder(EmailChannelName, hooks));
                });

                this._fbService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder(FacebookPage, hooks));
                });

                this._ytService.list().subscribe(hooks => {
                    hooks = hooks.filter(hook => hook.idBot == botid)
                    console.log(hooks)
                    this.channelList.push(this.channelListBuilder(YouTubeChannel, hooks));
                });

            });
            let url = this.route.snapshot.url;
            this.media = url[url.length - 1].path == 'media';
        });
    }

    newChannel(type: string) {
        if (type == EmailChannelName && this.bot !== undefined) {
            let reference = this._matDialog.open<
                EmailChannelEditComponent,
                ChannelEditData<EmailChannel>,
                EmailChannel
            >(EmailChannelEditComponent, {
                panelClass: 'channel-edit-wrapper',
                width: "90%",
                maxWidth: "500px",
                data: {
                    bot: this.bot,
                    channel: {},
                }
            });

            reference.afterClosed().subscribe(channel => {
                if (channel) {
                    this._emailService.create(channel).subscribe(created => {
                        this.getBotChannels()
                    })
                }
            })
            return;
        } else if (type == FacebookPage && this.bot !== undefined) {
            sessionStorage.setItem("botId", `${this.bot.id}`);
            this._fbService.openDialog("/bots/facebook/channel").then(url => {
                window.location.href = url;
            });
            return;
        } else if (type == YouTubeChannel && this.bot !== undefined) {
            sessionStorage.setItem("botId", `${this.bot.id}`);
            this._ytService.start().subscribe(url => {
                window.location.href = url;
            });
            return;
        }

        this.dialogRef = this._matDialog.open(ChannelDialogComponent, {
            panelClass: 'channel-form-dialog',
            data: {
                action: 'new',
                platform: type,
                bot: this.bot,
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: any) => {
                if (!response) {
                    return;
                }

                response = { ...response, ...{ idBot: (this.bot) ? this.bot.id : 0 } };

                switch (type) {
                    case 'Messenger':
                        this._facebookService.create(response).subscribe(
                            res => this.getBotChannels(),
                            err => alert(`Erro ao criar canal de ${type}`)
                        );
                        break;

                    case 'Whatsapp':
                        this._whatsappService.create(response).subscribe(
                            res => this.getBotChannels(),
                            err => alert(`Erro ao criar canal de ${type}`)
                        );

                        break;

                    case GooglePlay:
                        this._googleService.create(response).subscribe(
                            res => this.getBotChannels(),
                            err => alert(`Erro ao criar canal de ${type}`)
                        );
                        break;

                }
            });
    }

    channelListBuilder(type: string, data: any[]) {
        return {
            type: this.channelTypes[this.channelTypes.map((el) => el.name).indexOf(type)],
            channels: data.filter(value => this.bot && value.idBot == this.bot.id)
        };
    }

    editChannel(channel: any, platform: any) {
        if (platform == EmailChannelName && this.bot !== undefined) {
            let reference = this._matDialog.open<
                EmailChannelEditComponent,
                ChannelEditData<EmailChannel>,
                EmailChannel
            >(EmailChannelEditComponent, {
                panelClass: 'channel-edit-wrapper',
                width: "90%",
                maxWidth: "500px",
                data: {
                    bot: this.bot,
                    channel: channel,
                }
            });

            reference.afterClosed().subscribe(channel => {
                if (channel) {
                    this._emailService.update(channel).subscribe(updated => {
                        this.getBotChannels()
                    })
                }
            })
            return;
        } else if (platform == FacebookPage && this.bot !== undefined) {
            let reference = this._matDialog.open<
                FacebookPageEditComponent,
                ChannelEditData<FacebookPageChannel>,
                FacebookPageChannel
            >(FacebookPageEditComponent, {
                panelClass: 'channel-edit-wrapper',
                width: "90%",
                maxWidth: "500px",
                data: {
                    bot: this.bot,
                    channel: channel,
                }
            });

            reference.afterClosed().subscribe(channel => {
                if (channel) {
                    this._fbService.update(channel).subscribe(updated => {
                        this.getBotChannels()
                    })
                }
            })
            return;
        } else if (platform == YouTubeChannel && this.bot !== undefined) {
            let reference = this._matDialog.open<
                YoutubeEditComponent,
                ChannelEditData<YoutubeChannel>,
                YoutubeChannel
            >(YoutubeEditComponent, {
                panelClass: 'channel-edit-wrapper',
                width: "90%",
                maxWidth: "500px",
                data: {
                    bot: this.bot,
                    channel: channel,
                }
            });

            reference.afterClosed().subscribe(channel => {
                if (channel) {
                    this._ytService.update(channel).subscribe(updated => {
                        this.getBotChannels()
                    })
                }
            })
            return;

        }

        this.dialogRef = this._matDialog.open(ChannelDialogComponent, {
            panelClass: 'channel-form-dialog',
            data: {
                action: 'edit',
                platform: platform,
                bot: this.bot,
                channel: channel
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: any[]) => {
                if (!response) { return; }

                console.log(platform)

                const actionType: string = response[0];
                const formData: FormGroup = response[1];

                switch (actionType) {
                    case 'save':
                        this.saveData(formData, platform);
                        break;
                    case 'delete':
                        this.deleteData(formData, platform);
                        break;
                }
            });
    }

    saveData(formData: FormGroup, platform: any) {
        switch (platform) {
            case 'Messenger':
                this._facebookService.update(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao atualizar canal!')
                );
                break;
            case 'Whatsapp':
                this._whatsappService.update(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao atualizar canal!')
                );
                break;
            case GooglePlay:
                this._googleService.update(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao atualizar canal!')
                );
                break;
        }
    }

    deleteData(formData: FormGroup, platform: any) {
        
        switch (platform) {
            case 'Messenger':
                this._facebookService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;
            case 'Whatsapp':
                this._whatsappService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;
            case GooglePlay:
                this._googleService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;

            case EmailChannelName:
                this._emailService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;

            case FacebookPage:
                this._fbService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;

            case YouTubeChannel:
                this._ytService.delete(formData.value).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;
        }
    }

    public deleteChannel(channel: Channel, platform: string) {
        switch (platform) {
            case 'Messenger':
                this._facebookService.delete(channel).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;
            case 'Whatsapp':
                this._whatsappService.delete(channel).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;
            case GooglePlay:
                this._googleService.delete(channel.id).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                );
                break;

            case EmailChannelName:
                this._emailService.delete(channel.id).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;

            case FacebookPage:
                this._fbService.delete(channel.id).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;
            case YouTubeChannel:
                this._ytService.delete(channel.id).subscribe(
                    res => this.getBotChannels(),
                    err => alert('Erro ao deletar canal!')
                )
                break;

        }

    }
}
