<div class="header">
    <h3 class="header__title">{{ bot?.name }}</h3>
    <img class="header__image" [src]="'assets/banner.jpg'">
    <div>
        <p>{{ bot?.description }}</p>
    </div>
</div>

<div class="body">
    <div class="body__navigation" (click)="handleNavigation(['/bots', bot?.id], true)">
        <mat-icon>feedback</mat-icon>
        <span> Intenções {{ amountIntents ? '(' + amountIntents + ')' : '' }} </span>
    </div>
    <div class="body__navigation" (click)="handleNavigation(['/bots', bot?.id, 'channels'])">
        <mat-icon>power</mat-icon>
        <span> Canais </span>
    </div>

    <div class="body__navigation" (click)="openTriggerDialog()">
        <mat-icon>apps</mat-icon>
        <span>Eventos</span>
    </div>


    <div class="body__navigation" (click)="handleNavigation(['/bots', bot?.id, 'simulador'])">
        <mat-icon>play_circle_outline</mat-icon>
        <span> Simulador </span>
    </div>
    <div class="body__navigation" (click)="handleNavigation(['/bots', bot?.id, 'treinamento'])">
        <mat-icon>school</mat-icon>
        <span> Treinamento </span>
    </div>

    <div class="body__navigation" (click)="openTagList()">
        <mat-icon>bookmark</mat-icon>
        <span>Etiquetas</span>
    </div>

    <div class="body__navigation" (click)="openAgentResponseList()">
        <mat-icon>bookmark</mat-icon>
        <span>Respostas</span>
    </div>

    <div class="slider">
        <div class="off" [ngClass]="{'on' : botActive }">
            <mat-slide-toggle [(ngModel)]="botActive"></mat-slide-toggle>
            <span class="slider__title">Ativo</span>
        </div>
    </div>

    <div class="body__button">
        <button class="body__button-item" (click)="editBot()"> Editar robô </button>
        <button class="body__button-item" (click)="deleteBot()"> Remover robô </button>
    </div>
</div>