import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AgentResponse, AgentResponseService, Bot } from 'onevoice';

export interface ResponseEditData {
  bot: Bot;
  initial: Partial<AgentResponse>;
}

@Component({
  selector: 'main-agent-response-edit',
  templateUrl: './agent-response-edit.component.html',
  styleUrls: ['./agent-response-edit.component.scss']
})
export class AgentResponseEditComponent {
  public form: FormGroup;
  public actionName: string = "Criar";

  public globalResponse: boolean = false;

  constructor(
    private reference: MatDialogRef<AgentResponseEditComponent>,
    private service: AgentResponseService,
    @Inject(MAT_DIALOG_DATA) public data: ResponseEditData
  ) {
    this.form = new FormGroup({
      "title": new FormControl(data.initial.title, [Validators.required]),
      "content": new FormControl(data.initial.content, [Validators.required]),
    });
    if (data.initial.id !== undefined) {
      this.actionName = "Atualizar";
    }

    this.globalResponse = (!this.data.initial.bot)
  }

  public globalChange(event: MatSlideToggleChange) {
    this.globalResponse = event.checked;
  }

  public onSubmit(): void {
    let form: Partial<AgentResponse> = {
      ...this.data.initial,
      ...this.form.value,
    }
    if (this.globalResponse) {
      form.bot = null;
    } else {
      form.bot = this.data.bot;
    }

    if (this.data.initial.id !== undefined) {
      this.service.update(form).subscribe(updated => {
        this.reference.close(updated);
      });
    } else {
      this.service.create(form).subscribe(created => {
        this.reference.close(created);
      });
    }
  }
}
