<div class="body">
    <mat-card class="card-title mat-elevation-z6" style="overflow-y: scroll;">
        <mat-card-header class="title" >Planejamento diário </mat-card-header>
        <div class="line"></div>
        <div class="content">
            <mat-card-content>
                <div class="card-task-daily">
                    <div class="task-name">
                        <div>
                            <div class="title-task">
                                <div class="sub-title">Planejado</div>
                                <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px;">
                                    {{allTasksInScheduler.length}} </div>
                                    <div class="internal-card">
                            </div>
                                <div class="task-daily">
                                    <div *ngFor="let task of allTasksInScheduler" class="single-task">
                                        <div style="display: flex;">
                                            <div class="material-symbols-outlined" id="robot-icon">
                                                {{taskStatus(task)}}
                                            </div>
                                            <div style="margin-left:0px; width: 200px; ">
                                                {{task.name.replace("execute_task_","").slice(0,20)}}

                                            </div>
                                        </div>
                                        <div style="justify-content: flex-end;">
                                            <!-- {{task.start.toString().slice(0,-3)}} -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="vertical-line"></div>
                        <div>
                            <div class="title-task">
                                <div class="sub-title">Pendente</div>
                                <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px; ">
                                    {{pendingTasks.length}}
                                </div>
                            </div>
                            <div class="internal-card">
                                <div class="task-daily">
                                    <div *ngFor="let execution of pendingTasks">
                                        <div style="display:flex">
                                            <div class="material-symbols-outlined">
                                                circle
                                            </div>
                                            <div>
                                                {{execution.name.replace("execute_task_","")}}
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </div>

    </mat-card>

    <mat-card class="card-title mat-elevation-z6" style="overflow-y: scroll;">
        <mat-card-header class="title" >Tarefas executadas </mat-card-header>
        <div class="line"></div>
        <div class="content">
            <mat-card-content>
                <div class="card-task-daily">
                    <div class="task-name">
                        <div>
                            <div class="title-task">
                                <div class="sub-title">Executado</div>
                                <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px;">
                                    {{executedTasks.length}} </div>
                                    <div class="internal-card">
                            </div>
                                <div class="task-daily">
                                    <div *ngFor="let task of executedTasks" class="single-task">
                                        <div style="display: flex;">
                                            <div class="material-symbols-outlined" id="robot-icon">
                                                check_circle
                                            </div>
                                            <div style="margin-left:0px; width: 200px; ">
                                                {{task.name.replace("execute_task_","")}}
                                            </div>
                                        </div>
                                        <div style="justify-content: flex-end;">
                                            <!-- {{task.start.toString().slice(0,-3)}} -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="vertical-line"></div>
                        <div>
                            <div class="title-task">
                                <div class="sub-title">Fora do planejamento</div>
                                <div class="tasks-today-number" style="font-size: 22px;margin-left: 20px; ">
                                    {{offplanExecutions.length}}
                                </div>
                            </div>
                            <div class="internal-card">
                                <div class="task-daily">
                                    <div *ngFor="let execution of offplanExecutions">
                                        <div style="display:flex">
                                            <div class="material-symbols-outlined">
                                                check_circle
                                            </div>
                                            <div>
                                                {{execution.task.name.replace("execute_task_","")}}
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </div>

    </mat-card>
</div>
