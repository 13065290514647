import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestMediaComponent } from './request-media/request-media.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    RequestMediaComponent
  ],
  exports: [
    RequestMediaComponent
  ],
  imports: [
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    CommonModule
  ]
})
export class DialogModule { }
