import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'empty-data-dialog',
  templateUrl: './empty-data-dialog.component.html',
  styleUrls: ['./empty-data-dialog.component.scss']
})
export class EmptyDataDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EmptyDataDialogComponent>
  ) { }

  ngOnInit(): void {
  }

}
