import { Injectable } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color, Label, PluginServiceGlobalRegistrationAndOptions } from 'ng2-charts';
import { DashboardListService, Graph } from 'onevoice';
import { DataService, GraphDataResponse } from './data.service';
import { colors2 } from '../options'

export interface GraphKind {
  name: string;
  title: string;

  options: ChartOptions;
  colors: Color[];
  chartType: ChartType;
  plugins: PluginServiceGlobalRegistrationAndOptions[];

  dataset(graph: Partial<Graph>): ChartDataSets[];
  labels(graph: Partial<Graph>): Label[];
  clone(): GraphKind;
}

function displayTime(delta: number, context: any): string {
  let hour = Math.floor(delta);
  let minute = Math.floor((delta - hour) * 60);
  let second = Math.floor(((delta - hour) * 60 - minute) * 60);
  let raw = `${hour}h ${minute}m ${second}s`;
  return raw.replace("0m", "").replace("0h", "").replace("0s", "").replace("  ", " ").trim();
}


const lineChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: true,

      },
    }],
    xAxes: [{
      display: true,
    }],
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
    },
  },
};

const hourChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,

  legend: {
    display: true,
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        beginAtZero: true,

      },
    }],
    xAxes: [{
      display: true,
    }],
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'end',
      formatter: displayTime,
    },
  },
};
const doughnutChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,

  legend: {
    display: false,
  },
};

const appColors: Color[] = colors2;

class GraphKindWithData implements GraphKind {
  public _dataset?: ChartDataSets[];
  public _labels?: Label[];

  public plugins: PluginServiceGlobalRegistrationAndOptions[] = [
    pluginDataLabels as PluginServiceGlobalRegistrationAndOptions,
    {
      id: "customScale",
      beforeLayout: (chart: any, options: any) => {
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE
        let grace = 0.05;

        chart.data.datasets.forEach((dataset: any) => {
          max = Math.max(max, Math.max(...dataset.data));
          min = Math.min(min, Math.min(...dataset.data))
        })

        if (chart.options.scales === undefined) {
          return;
        }

        chart.options.scales.yAxes.forEach((axe: any) => {
          axe.ticks.suggestedMax = max + grace * max;
          axe.ticks.suggestedMin = min - grace * min;
        })
      },
    }
  ];


  private callData(graph: Partial<Graph>): void {
    this.data(graph).then(({ labels, dataset }) => {
      this._labels = labels;
      this._dataset = dataset;
    });
  }

  public dataset(graph: Partial<Graph>): ChartDataSets[] {
    if (!this._dataset) {
      this.callData(graph);
      this._labels = [];
      this._dataset = [];

    }
    return this._dataset;
  }
  public labels(graph: Partial<Graph>): Label[] {
    if (!this._labels) {
      this.callData(graph);
      this._labels = [];
      this._dataset = [];
    }
    return this._labels;
  }

  constructor(
    public name: string,
    public title: string,
    public chartType: ChartType,
    public options: ChartOptions,
    public data: (graph: Partial<Graph>) => Promise<GraphDataResponse>,
    private service: DataService,

    public colors: Color[] = [
      {
        borderColor: '#8e24aa',
        backgroundColor: '#8e24aa',
        pointBackgroundColor: '#8e24aa',
        pointHoverBackgroundColor: '#8e24aa',
        pointBorderColor: '#ffffff',
        pointHoverBorderColor: '#ffffff'
      }
    ]) {
    this.service.OnUpdate.subscribe(_ => {
      this.reset();
    });
  }

  public reset() {
    this._labels = undefined;
    this._dataset = undefined;
  }

  public clone(): GraphKind {
    return new GraphKindWithData(this.name, this.title, this.chartType, this.options, this.data, this.service, this.colors);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardService {
  readonly KindList: GraphKind[] = [
    new GraphKindWithData(
      "chamados-respondidos",
      "Chamados Respondidos",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.answered(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "chamados-abertos",
      "Chamados Abertos",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.opened(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "abertos-v-respondidos",
      "Chamados Abertos vs Respondidos",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.combined(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "sla",
      "Primeira Resposta",
      "bar",
      hourChartConfig,
      (graph: Partial<Graph>) => this.dataService.SLA(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "sla-percent",
      "Primeira Resposta (Percentil)",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.SLAPercentil(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "peak-assignment",
      "Pico de Chamados",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.peakAssignments(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "tags",
      "Etiquetas",
      "horizontalBar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.tagCount(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "tags-pizza",
      "Etiquetas (Pizza)",
      "doughnut",
      doughnutChartConfig,
      (graph: Partial<Graph>) => this.dataService.tagCount(graph),
      this.dataService,
      appColors
    ),


    new GraphKindWithData(
      "eval",
      "Avaliações",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.evaluation(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "peak-activity",
      "Pico de Atividade",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.peakActivity(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "ratio",
      "Razão Robo / Humano",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.agentBotRation(graph),
      this.dataService,
    ),

    new GraphKindWithData(
      "play-store-avg",
      "Avaliações da Play Store",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.playstore(graph),
      this.dataService
    ),


    new GraphKindWithData(
      "play-store-ratio",
      "Avaliações da Play Store",
      "doughnut",
      doughnutChartConfig,
      (graph: Partial<Graph>) => this.dataService.playstoreDoughnut(graph),
      this.dataService,
      appColors
    ),

    new GraphKindWithData(
      "messages-count",
      "Mensagens trocadas",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.messagesChart(graph),
      this.dataService
    ),

    new GraphKindWithData(
      "failed-intent",
      "Intents falhas",
      "doughnut",
      doughnutChartConfig,
      (graph: Partial<Graph>) => this.dataService.failedIntent(graph),
      this.dataService,
      appColors
    ),

    new GraphKindWithData(
      "failed-intent-multi",
      "Intents falhas em linha",
      "bar",
      lineChartConfig,
      (graph: Partial<Graph>) => this.dataService.failedIntentMultiDate(graph),
      this.dataService,
    ),
  ];

  constructor(
    public dashService: DashboardListService,
    private dataService: DataService
  ) { }

  public getKind(graph: Partial<Graph>): GraphKind | undefined {
    return this.KindList.find(value => value.name == graph.kind)?.clone();
  }

  public remove(graph: Graph) {
    return this.dashService.delete(graph.id);
  }
}
