import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChartsModule } from 'ng2-charts';
import { ReportApiRequestService } from '../api/report-api-request.service';
import { AddressableDashboardComponent } from './addressable-dashboard/addressable-dashboard.component';
import { CustomDashboardComponent } from './custom-dashboard/custom-dashboard.component';
import { GraphEditorComponent } from './custom-dashboard/graph-editor/graph-editor.component';
import { GraphViewComponent } from './custom-dashboard/graph-view/graph-view.component';
import { DashboardComponent } from './dashboard.component';
import { ExtendedDashboardComponent } from './extended-dashboard/extended-dashboard.component';
import { SimpleDashboardComponent } from './simple-dashboard/simple-dashboard.component';

const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
    },
    {
        path: "custom",
        children: [
            {
                path: ":name",
                component: AddressableDashboardComponent,
            }
        ]
    }
]

@NgModule({
    declarations: [
        DashboardComponent,
        SimpleDashboardComponent,
        ExtendedDashboardComponent,
        CustomDashboardComponent,
        GraphEditorComponent,
        GraphViewComponent,
        AddressableDashboardComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        ChartsModule,
        CommonModule,
        FuseWidgetModule,
        FuseSharedModule,
        MatTableModule,
        MatTabsModule,
        MatRippleModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatDialogModule,
        MatCardModule,
        MatMenuModule,
        MatDatepickerModule,
        RouterModule,
    ],
    providers: [
        ReportApiRequestService
    ],
    exports: [
        ExtendedDashboardComponent
    ]
})
export class DashboardModule { }
