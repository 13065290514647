<div class="signup-container">
  <div class="signup-container__logo">
    <img src="../../../../assets/images/logos/logo.png" alt="" srcset="">
    <p>Alabia One Voice</p>
  </div>
  <alabia-signup-step1 *ngIf="screens[0]" (nextScreen)="handleScreenChange($event)" (stepData)="handleStepData($event)" [returnData]="accountForm"></alabia-signup-step1>
  <alabia-signup-step2 *ngIf="screens[1]" (nextScreen)="handleScreenChange($event)" (stepData)="handleStepData($event)"></alabia-signup-step2>
  <alabia-signup-step3 *ngIf="screens[2]" (nextScreen)="handleScreenChange($event)" (stepData)="handleStepData($event)" [returnData]="organizationForm"></alabia-signup-step3>
  <alabia-signup-step4 *ngIf="screens[3]" (nextScreen)="handleScreenChange($event)" (stepData)="handleStepData($event)" [returnData]="organizationForm"></alabia-signup-step4>
  <alabia-signup-step5 *ngIf="screens[4]" (nextScreen)="handleScreenChange($event)"></alabia-signup-step5>

</div>