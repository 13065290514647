import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'alabia-modal-registration',
  templateUrl: './modal-registration.component.html',
  styleUrls: ['./modal-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalRegistrationComponent {
  accountForm: FormGroup;

  constructor(
    public matDialogRef: MatDialogRef<ModalRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.accountForm = this.createAccountForm();
  }

  createAccountForm(): FormGroup {
    return new FormGroup({
      organization: new FormControl(''),
      username: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl(''),
      password: new FormControl(''),
      confirmPassword: new FormControl('')
    }, { validators: passwordMatchValidator });
  }

  onInputPass() {
    let confirm = this.accountForm
      .get('confirmPassword');
    if (!confirm) {
      return;
    }
    confirm
      .setErrors(this.accountForm.hasError('passwordMismatch') ? [{ 'passwordMismatch': true }] : null);
  }

}

export const passwordMatchValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
  let password = formGroup.get('password'),
    confirm = formGroup.get('confirmPassword')
  if (password && confirm && password.value === confirm.value) {
    return null
  }
  return { passwordMismatch: true };
}
