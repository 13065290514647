import { PrivacyComponent } from '@alabia/flatpages/privacy/privacy.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule} from '@angular/material/dialog'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NuMarkdownModule } from '@ng-util/markdown';
import { TranslateModule } from '@ngx-translate/core';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from 'environments/environment';
import 'hammerjs';
import { ApiConfiguration, APP_CONFIG, AuthenticationService } from 'onevoice';
import { AlabiaModule } from './alabia/alabia.module';
import { AuthenticationModule } from './alabia/authentication/authentication.module';
import { SignupModule } from './alabia/signup/signup.module';
import { FakeDbService } from './fake-db/fake-db.service';

const appRoutes: Routes = [
    {
        path: "",
        loadChildren: () => AlabiaModule
    },
    {
        path: "privacidade",
        component: PrivacyComponent,
    }
];

const config: ExtraOptions = {
    useHash: false,
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, config),
        //BackendApiModule.forRoot(new ApiConfiguration(environment.main)),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Fake Datas
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        NuMarkdownModule.forRoot(),

        // App modules
        LayoutModule,
        AlabiaModule,
        AuthenticationModule,
        SignupModule,


    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationService, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: APP_CONFIG, useValue: new ApiConfiguration(environment.main, environment.sttService, environment.biometrics, environment.mediaStorage) },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
