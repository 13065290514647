import { AccountForm } from './account-form';

export class UpdateOrganization {
    website: string;
    industry: string;
    role: string;
    company_size: string;

    constructor(data: AccountForm) {
        this.website = (data.website) ? data.website.trim() : "";
        this.industry = (data.industry) ? data.industry.trim() : "";
        this.role = (data.role) ? data.role.trim() : "";
        this.company_size = (data.company_size) ? data.company_size.trim() : "";
    }

    normalize(str: string) {
        return str
            .normalize('NFD')
            .trim()
            .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
            .replace(/\s+/g, '-')
            .toLocaleLowerCase();
    }
}


