<div id="contacts" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

    <!-- HEADER -->
    <div class="header accent p-16" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center">

        <!-- APP TITLE -->
        <div fxLayout="row" fxLayoutAlign="start center">
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">
                    supervisor_account
                </mat-icon>
                <span class="logo-text h1">
                    Usuários
                </span>
            </div>

        </div>
        <!-- / APP TITLE -->

        <!-- SEARCH -->
        <div class="ml-sm-32 search-outter-wrapper">
            <div class="search-wrapper mt-16 mt-sm-0">
                <div class="search">
                    <mat-icon class="search-icon">search</mat-icon>
                    <input [formControl]="searchInput" placeholder="Procure por um usuário">
                </div>
            </div>
        </div>
        <!-- / SEARCH -->

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <!-- CENTER -->
        <div class="center p-24 pb-56" fusePerfectScrollbar>

            <!-- CONTENT -->
            <div class="content">
                <div id="user-list">
                    <mat-table #table [dataSource]="dataSource">
                        <!-- Avatar Column -->
                        <ng-container matColumnDef="avatar">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <img class="avatar" alt="Foto de perfil" [src]="users.avatar(user.id)"
                                    onError="this.src = 'assets/images/avatars/profile.jpg'">
                            </mat-cell>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <p class="text-truncate font-weight-600">{{ getUserName(user) }}</p>
                            </mat-cell>
                        </ng-container>

                        <!-- Email Column -->
                        <ng-container matColumnDef="email">
                            <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <p class="email text-truncate">
                                    {{user.email}}
                                </p>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="location">
                          <mat-header-cell *matHeaderCellDef>Localização</mat-header-cell>
                          <mat-cell *matCellDef="let user">
                              <p class="text-truncate font-weight-600">{{ user.details.location != null ? user.details.location.name : "Não deifinido"}}</p>
                          </mat-cell>
                      </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="auth">
                            <mat-header-cell *matHeaderCellDef>Autorização</mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <p class="text-truncate font-weight-600">{{ authorities(user.authorities) }}</p>
                            </mat-cell>
                        </ng-container>

                        <!-- Buttons Column -->
                        <ng-container matColumnDef="buttons">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                    (click)="$event.stopPropagation();">
                                    <mat-icon class="secondary-text">more_vert</mat-icon>
                                </button>

                                <mat-menu #moreMenu="matMenu">
                                    <button mat-menu-item aria-label="remove" (click)="deleteUser(user)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Remove</span>
                                    </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let user; columns: displayedColumns;" class="contact"
                            (click)="editUser(user)" matRipple>
                        </mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

    </div>
    <!-- / CONTENT-->

</div>

<!-- ADD CONTACT BUTTON -->
<button mat-fab class="accent" id="add-contact-button" (click)="newContact()" aria-label="add contact">
    <mat-icon>person_add</mat-icon>
</button>
<!-- / ADD CONTACT BUTTON -->
