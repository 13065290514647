<div class="body">
  <table class="table">
    <tr>
      <th></th>
      <th *ngFor="let task of weekTasks; index as j">
        <div>{{getDayText(j)}}</div>

        <div class="day" *ngIf="currentDate.getDate() != weekDays[j]">{{weekDays[j]}}</div>

        <div class="day" *ngIf="currentDate.getDate() == weekDays[j]" mainTodayColor>{{weekDays[j]}}</div>
        <!-- <div *ngIf="machineExecutionList !=[]">
                    <div *ngFor="let execution of machineExecutionList; index as a">{{execution.start}}</div>
                </div> -->

      </th>


    </tr>
    <tr *ngFor="let hour of hours; index as i" class="column">
      <td>
        <div class="box-hour">
          {{hour}}:00

        </div>

      </td>
      <td *ngFor="let task of weekTasks; index as j">
        <div *ngFor="let singleTask of task; index as l">
          <div *ngIf="singleTask.startTime.slice(0,-6) == hour" class="task" mainBorderLeftColor>
            <div>
              <mat-icon class="material-symbols-outlined">
                home

              </mat-icon>
              {{singleTask.machine.location.name}}

            </div>
            <div>
              <mat-icon class="material-symbols-outlined">
                map

              </mat-icon>
              {{singleTask.machineTask.map.title}}

            </div>


            <div>
              <mat-icon class="material-symbols-outlined">
                pin_drop

              </mat-icon>
              {{singleTask.machineTask.name.replace('execute_task_','')}}

            </div>
            <div>
              <mat-icon class="material-symbols-outlined">
                <span class="material-symbols-outlined" style="text-align: center;">
                  robot_2
                  </span>
              </mat-icon>
              {{singleTask.machine.model.model}}

            </div>
            <div class="mini-card-start-end-type">
              <mat-icon class="material-symbols-outlined">
                schedule
              </mat-icon>
              {{singleTask.startTime.toString().slice(0,-3)}}
            </div>

            <!-- <div *ngFor="let single of singleTask.task" class="taskList">
                            <div *ngIf="machineExecutionList.length > 0">

                                <mat-icon class="material-symbols-outlined">
                                    {{status(single,weekDays[j])}}

                                </mat-icon>

                            </div>
                            {{single.name.replace("execute_task_","").slice(0,20)}}
                        </div> -->
          </div>

        </div>

      </td>
    </tr>

  </table>

</div>
