import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AgentService, AssignmentsService, AuthenticationService, Bot, BotService, Customer, CustomerService, Message, ResponseType, Sender, User } from 'onevoice';
import { Subject } from 'rxjs';

@Component({
  selector: 'alabia-customer-history',
  templateUrl: './customer-history.component.html',
  styleUrls: ['./customer-history.component.scss'],
})
export class CustomerHistoryComponent implements OnInit {
  public icon: string = "supervisor_account";
  public title: string = "Histórico";
  public botList: Bot[] = [];

  Sender: any = Sender;

  private _unsubscribeAll: Subject<any>;
  public history?: Message[];
  public customerData?: Customer;

  constructor(
    public service: AgentService,
    public assignment: AssignmentsService,
    public auth: AuthenticationService,
    public bots: BotService,
    public route: ActivatedRoute,
    public customers: CustomerService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      let bot = Number(params["bot"]);
      this.customers.read(Number(params["id"])).subscribe((customer: Customer) => {
        this.bots.list().subscribe(botList => {
          this.botList = botList;
        });
        this.bots.read(bot).subscribe(bot => {
          this.title = `${customer.firstName} ${customer.lastName}`.trim();
          if (this.title.length <= 0) {
            this.title = `Cliente #${customer.id}`;
          }
          this.title = this.title + ` - ${bot.name}`;
        });

        this.customerData = customer;

        this.service.loadCustomerBotHistory(this.customerData, bot).then(history => {
          console.log(history);
          this.history = history;
        }, err => { });
      }, err => console.error('not ok'));
    })
  }

  public jsonMessage(message: Message) {
    return message.contentType == ResponseType.JSON
  }


  scrollToBottom(speed?: number): void {
    speed = speed || 400;
  }

  isHuman(message: Message): boolean {
    return message.sender == Sender.USER;
  }


  isFirstMessageOfGroup(message: Message, index: number): boolean {
    if (!this.history) {
      return true;
    }
    return (index === 0 || index > 0 && this.history[index - 1].sender !== message.sender);
  }

  isLastMessageOfGroup(message: Message, index: number): boolean {
    if (!this.history) {
      return true;
    }
    if (index + 1 < this.history.length) {
      return this.isHuman(this.history[index + 1]) !== this.isHuman(message);
    }
    return true;
  }

  public processText(content: string) {
    if (content.startsWith("#")) {
      try {
        let command: any = JSON.parse(atob(content.replace("#", "")));
        return `Execute: ${command.intent}`;
      } catch (error) {

      }
    }
    return content;
  }

  public userFullname(user: User) {
    let fullname = `${user.details.first_name || ""} ${user.details.last_name || ""}`.trim();
    if (fullname.length <= 0) {
      fullname = user.email;
    }
    return fullname;
  }
}
