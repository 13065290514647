import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { Voice } from './voice.model';

@Injectable({
  providedIn: 'root'
})
export class VoiceService extends OwnableService<Voice> {
  protected path: string = `${this.config.mediaservice}/voice`;
}
