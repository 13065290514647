import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthMethod, Bot, Channel, ChannelStatus, EmailChannel, FacebookPageChannel, ReceiveMethod, Security, YoutubeChannel } from 'onevoice';

export interface ChannelEditData<T extends Channel> {
  channel: Partial<T>;
  bot: Bot;
}

enum DialogType {
  EMAIL = "EMAIL",
  FACEBOOK_PAGE = "FACEBOOK_PAGE",
  YOUTUBE = "YOUTUBE",
}

export abstract class ChannelEditComponent<T extends Channel> {
  public title: string = "Criar canal";
  public saveAction: string = "Adicionar";
  public color: ThemePalette = "accent";
  public form: FormGroup;

  public abstract TYPE: DialogType;
  public DialogType = DialogType;

  constructor(
    public reference: MatDialogRef<ChannelEditComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelEditData<T>,
  ) {
    this.form = new FormGroup({
      "name": new FormControl(data.channel.name || "", [Validators.required, Validators.minLength(3)]),
      "status": new FormControl(data.channel.status || ChannelStatus.ACTIVE, [Validators.required]),
    }, []);

    if (data.channel.id !== undefined) {
      this.title = "Editar canal"
      this.saveAction = "Atualizar";
    }
  }

  public onSubmit() {
    let response: T = {
      ...this.data.channel,
      ...this.form.value,
      idBot: this.data.bot.id,
    }
    this.reference.close(response);
  }

  get statusList() {
    return Object.values(ChannelStatus);
  }

  public statusName(status: ChannelStatus) {
    let defaultValue = status.toString();
    switch (status) {
      case ChannelStatus.ACTIVE:
        return "Ativo";
      case ChannelStatus.DISABLED:
        return "Desativado";
      default:
        return defaultValue;
    }
  }

  get securityList() {
    return Object.values(Security);
  }

  get methods() {
    return Object.values(AuthMethod);
  }

  get receiveMethods() {
    return Object.values(ReceiveMethod);
  }

  public AuthMethod = AuthMethod;
  public ReceiveMethod = ReceiveMethod;
}

@Component({
  selector: "email-channel-edit",
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss']
})
export class EmailChannelEditComponent extends ChannelEditComponent<EmailChannel> {
  public TYPE: DialogType = DialogType.EMAIL;

  constructor(
    public reference: MatDialogRef<EmailChannelEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelEditData<EmailChannel>,
  ) {
    super(reference, data);

    this.form.addControl(
      "from",
      new FormControl(data.channel.from || "", [Validators.required, Validators.email]),
    );
    this.form.addControl(
      "username",
      new FormControl(data.channel.username || "", [Validators.required]),
    );
    this.form.addControl(
      "password",
      new FormControl(data.channel.password || "", [Validators.required]),
    );
    this.form.addControl(
      "method",
      new FormControl(data.channel.method || AuthMethod.LOGIN, [Validators.required])
    );
    this.form.addControl(
      "receiveMethod",
      new FormControl(data.channel.receiveMethod || ReceiveMethod.POLLING, [Validators.required]),
    )

    this.form.addControl(
      "smtpServer",
      new FormControl(data.channel.smtpServer || "", [Validators.required, Validators.minLength(3)]),
    );
    this.form.addControl(
      "smtpPort",
      new FormControl(data.channel.smtpPort || 587, [Validators.required, Validators.min(1), Validators.max(65535)]),
    );
    this.form.addControl(
      "smtpSecurity",
      new FormControl(data.channel.smtpSecurity || Security.TLS, [Validators.required]),
    );

    this.form.addControl(
      "imapServer",
      new FormControl(data.channel.imapServer || "", [Validators.required, Validators.minLength(3)]),
    );
    this.form.addControl(
      "imapPort",
      new FormControl(data.channel.imapPort || 993, [Validators.required, Validators.min(1), Validators.max(65535)]),
    );
    this.form.addControl(
      "imapSecurity",
      new FormControl(data.channel.imapSecurity || Security.TLS, [Validators.required]),
    );

    this.title = `${this.title} de Email`;
  }
}


@Component({
  selector: "facebook-page-channel-edit",
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss']
})
export class FacebookPageEditComponent extends ChannelEditComponent<EmailChannel> {
  public TYPE: DialogType = DialogType.FACEBOOK_PAGE;

  constructor(
    public reference: MatDialogRef<FacebookPageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelEditData<FacebookPageChannel>,
  ) {
    super(reference, data);

    this.form.addControl(
      "messenger",
      new FormControl(data.channel.messenger || false, []),
    );
    this.form.addControl(
      "comments",
      new FormControl(data.channel.comments || false, []),
    );
    this.title = `${this.title} de Página do Facebook`;
  }
}

@Component({
  selector: "youtube-channel-edit",
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss']
})
export class YoutubeEditComponent extends ChannelEditComponent<EmailChannel> {
  public TYPE: DialogType = DialogType.YOUTUBE;

  constructor(
    public reference: MatDialogRef<YoutubeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelEditData<YoutubeChannel>,
  ) {
    super(reference, data);
    this.title = `${this.title} de Canal do YouTube`;
  }
}