import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Bot, BotService } from 'onevoice';
import { Intent, IntentService } from 'onevoice';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IntentDialogComponent } from 'app/alabia/bot/dialogs/intent-dialog/intent-dialog.component';

import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'alabia-simulador',
    templateUrl: './simulador.component.html',
    styleUrls: ['./simulador.component.scss'],
    animations: fuseAnimations,

})
export class SimuladorComponent {
    bot?: Bot;
    intents: Intent[] = [];
    onIntentsChanged: BehaviorSubject<any>;

    selected?: Intent;
    selectedClear: Subject<boolean> = new Subject<boolean>();

    @ViewChild('filter', { static: true }) filter?: ElementRef;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private route: ActivatedRoute,
        private _botService: BotService,
        private _intentService: IntentService,
        public dialog: MatDialog,
    ) {
        this.selectedClear.subscribe(() => this.selected = undefined);
        this._unsubscribeAll = new Subject();
        this.onIntentsChanged = new BehaviorSubject({});
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const botid = Number(params.get('id'));
            this._botService.read(botid).subscribe(bot => {
                this.bot = bot;

                this._intentService.list(bot.id).subscribe((intents: Intent[]) => {

                    this.intents = intents;
                    this.intents.forEach(intent => (intent.bot = bot));

                    if (params.has('intent')) {
                        const intentId = Number(params.get('intent'));
                        this.selected = this.intents.find(intent => intent.id === intentId);
                    }

                    this.onIntentsChanged.next(this.intents);
                });
            });
        });
    }

    public createIntent(): void {
        const reference = this.dialog.open<
            IntentDialogComponent,
            Partial<Intent>,
            Partial<Intent>
        >(IntentDialogComponent, {
            width: '500px',
            maxWidth: '95%',
            data: {
                name: 'NOVA INTENÇÃO',
                description: '',

                maxFallbacks: 2,
                lifespan: 10000,

                bot: this.bot
            }
        });

        reference.afterClosed().subscribe(intent => {
            if (!intent) {
                return;
            }
            this._intentService.create(intent).subscribe(created => {
                this.selected = created;
                this.intents.push(created);
            });
        });
    }

    public intentDeleted(intent: Intent): void {
        this.intents = this.intents.filter(value => value.id !== intent.id);
        this.selected = undefined;
    }
}
