import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface TrainerParams {
    epochs?: number;
    dropout?: number;
    recurrent_dropout?: number;
    num_layers?: number;
}

export interface TrainerStart {
    status: string;
}

export interface TrainerStatus {
    status: string;
}

export interface TrainerHistory {
    id: number;

    project: number;
    state: string;

    status: string;
    start: string;
    end: string;
}

@Injectable({
    providedIn: 'root'
})
export class ClassifierTrainerService {
    RUNNING: string = 'running';
    DONE: string = 'done';
    NOT_FOUND: string = 'not found';

    constructor(private _httpClient: HttpClient) { }

    start(_case: number, params: TrainerParams): Observable<TrainerStart> {
        let body = new HttpParams();
        Object.values(params)
        for (const item of Object.entries(params)) {
            const [key, value] = item;
            body = body.set(key, value);
        }

        return this._httpClient.post<TrainerStart>(
            `${environment.trainingService}/start/${_case}/state/chat/`,
            body
        );
    }

    status(_case: number): Observable<TrainerStatus> {
        return this._httpClient.get<TrainerStatus>(
            `${environment.trainingService}/status/${_case}/state/chat/`
        );
    }

    history(project: number): Observable<TrainerHistory[]> {
        return this._httpClient.get<TrainerHistory[]>(
            `${environment.trainingService}/history/`,
            {
                params: {
                    project_id: `${project}`
                }
            }
        );
    }
}
