import { Time } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService, MachineExecution, MachineExecutionService, MachineScheduler, MachineSchedulerService, MachineTask, TaskSchedule } from 'onevoice';
import { scheduled } from 'rxjs';

@Component({
  selector: 'main-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {


  @Input() teste: any;

  date: Date = new Date();
  today: number = this.date.getDay();
  taskHours: Time[] = []
  currentDate: Date = new Date()
  tasks: TaskSchedule[][] = []

  startDay?: Date
  endDay?: Date

  allTasksInScheduler: MachineTask[] = [];
  scheduler: MachineScheduler[] = [];
  pendingTasks : MachineTask[] = [];


  executedTasksExecution: MachineExecution[] = [];
  offplanExecutions: MachineExecution[] = [];
  executedOnPlan: MachineExecution[] = [];
  executedTasks: MachineTask[] = [];
  taskExecutedOnPlan : MachineTask[] = [];
  MachineTask: String[] = []
  checkedTask: String[] = []


  constructor(
    public auth: AuthenticationService,
    public service: MachineSchedulerService,
    public executionService: MachineExecutionService,
  ) { }


  ngOnInit(): void {
    this.auth.systemToken().then(_ => {
      this.loadData();
    });
  }

  loadData(): void {

    this.getExecutions()
    this.getSchedulers()
  }
  getSchedulers() {

    this.service.list().subscribe(scheduler => {
      scheduler.forEach(timedTask => {
        timedTask.timedTaskList.forEach(task => {
          task.repeat.forEach(day => {
            if (day == this.today) {
              this.allTasksInScheduler.push(task.machineTask);
            }
          })
        })
      })
      this.scheduler = scheduler
    })
  }


  getExecutions() {

    this.startDay = new Date();
    this.startDay.setHours(0, 0, 0, 0);
    this.endDay = new Date(this.startDay);
    this.endDay.setDate(this.startDay.getDate() + 1);

    let params = new HttpParams();
    params = params.append('startDate', this.startDay.getTime());
    params = params.append('endDate', this.endDay.getTime());

    let taskNames = ['']

    this.executionService.list(params).subscribe(data => {
      data.forEach(execution => {
        taskNames.push(execution.task.name)
        this.executedTasks.push(execution.task)
      })
      this.getPending()

    })
  }

  getPending() {
    this.executedTasksExecution.forEach(executed => {
      this.allTasksInScheduler.forEach(schedule => {
        if (executed.task == schedule) {
          this.executedOnPlan.push(executed)
          this.taskExecutedOnPlan.push(executed.task)
        }
      })
    })

    this.pendingTasks = this.allTasksInScheduler.filter(x => {
      return this.taskExecutedOnPlan.indexOf(x) < 0
    })
    this.getOffplanTasks()
  }
  taskStatus(task: MachineTask): String {
    this.executedTasksExecution.forEach(single => {
      if (single.task == task) {
        return "check_circle"
      } else {
        return "circle";
      }
    })
    return "circle";
  }

  getOffplanTasks() {

    this.executedTasksExecution.forEach(executedTask=>{
      this.allTasksInScheduler.forEach(taskInScheduler=>{
        if(executedTask.task == taskInScheduler){
          this.offplanExecutions.push(executedTask)
        }
      })
    })

  }

  // orderTasks(): void {
  //   this.todayScheduler.sort(function (a, b) {
  //     if (a.startTime < b.startTime) return -1;
  //     if (a.startTime > b.startTime) return 1;
  //     return 0;
  //   })
  // }

}
