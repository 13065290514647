import { Component, Input, OnInit } from '@angular/core';
import { Graph } from 'onevoice';
import { CustomDashboardService, GraphKind } from '../custom-dashboard.service';

@Component({
  selector: 'main-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss']
})
export class GraphViewComponent implements OnInit {
  @Input()
  public graph: Partial<Graph> = {};

  public fullcreen: boolean = false;
  private _kind?: GraphKind;

  constructor(public service: CustomDashboardService) { }

  public ngOnInit(): void { }

  get kind() {
    if (this._kind === undefined) {
      this._kind = this.service.getKind(this.graph);
      console.log(this._kind?.colors);
    }
    return this._kind;
  }
}
