import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'onevoice';
import { UserService } from 'onevoice';

@Component({
  selector: 'cleaning-menu',
  templateUrl: './cleaning-menu.component.html',
  styleUrls: ['./cleaning-menu.component.scss']
})
export class CleaningMenuComponent implements OnInit {
  public userAuthorities: boolean = true;

  constructor(
    private userService: UserService,

  ) { }

  ngOnInit(): void {
    this.isAdmin();
  }

  public isAdmin(){

    this.userService.info().subscribe(data=>{

      this.userAuthorities = data.authorities.some(authority => authority.authorization === 'ADMIN' || authority.authorization === "MANAGER");
      console.log(data)
      console.log(this.userAuthorities);
    })

  }
}
