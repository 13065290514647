<div *ngIf="task !== undefined && finished == false;" class="task-form">
  <div class="task-info">
    <mat-card>
      <mat-card-title>{{ task.name }}</mat-card-title>

      <mat-card-subtitle>{{ task.type }}</mat-card-subtitle>


      <mat-card-subtitle>{{ task.map.title }}</mat-card-subtitle>


      <mat-card-content *ngIf="task.description">
        <!-- <nu-markdown-preview [value]="task.description"></nu-markdown-preview> -->
      </mat-card-content>


      <mat-form-field appearance="fill" class="taskType" *ngIf="!isAdmin">
        <mat-label>Tipo de tarefa</mat-label>
        <mat-select [disabled]="able" [(ngModel)]="typeExecution">
          <mat-option *ngFor="let type of typeTask" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="fill" class="taskType"*ngIf="isAdmin">
        <mat-label>Colaborador</mat-label>
        <mat-select [(ngModel)]="operator">
          <mat-option *ngFor="let user of listOfUsers" [value]="user.details.first_name">
            {{user.details.first_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br>
      <div *ngIf="isAdmin">
        <mat-form-field appearance="fill">
          <mat-label>Início:</mat-label>
          <input type="datetime-local" matInput [(ngModel)]="startTime">
        </mat-form-field>


        <mat-form-field appearance="fill" style="margin-left: 30px;">
          <mat-label>Fim </mat-label>
          <input type="datetime-local" matInput [(ngModel)]="endTime">
        </mat-form-field>
    </div>
      <mat-list *ngIf="isAdmin">
        <mat-list-item *ngFor="let item of checklistItens">
          <mat-checkbox (change)="addCheck(item, $event)" *ngIf="task.type == 'MANUAL'">
            {{ item.content }}
          </mat-checkbox>
          <mat-checkbox (change)="addCheck(item, $event)" *ngIf="task.type == 'MAINTENANCE'">
            {{ item.machine.nickName }}

          </mat-checkbox>
        </mat-list-item>
      </mat-list>
      <mat-list *ngIf="!isAdmin">
        <mat-list-item *ngFor="let item of checklistItens">
          <mat-checkbox (change)="addCheck(item, $event)" [disabled]="able" *ngIf="task.type == 'MANUAL'" >
            {{ item.content }}
          </mat-checkbox>
          <mat-checkbox (change)="addCheck(item, $event)" [disabled]="able" *ngIf="task.type == 'MAINTENANCE'">
            {{ item.machine.nickName }}

          </mat-checkbox>
        </mat-list-item>
      </mat-list>
      <mat-form-field class="example-full-width comments" *ngIf="!isAdmin">
        <mat-label>Comentários :</mat-label>
        <textarea [(ngModel)]="comments" matInput placeholder="Ex: Realizei a limpeza parcialmente pois ..."
          [disabled]="able"></textarea>
      </mat-form-field>


      <mat-form-field class="example-full-width comments" *ngIf="isAdmin">
        <mat-label>Comentários :</mat-label>
        <textarea [(ngModel)]="comments" matInput placeholder="Ex: Realizei a limpeza parcialmente pois ..."></textarea>
      </mat-form-field>

      <button *ngIf="haveImage == false" mat-raised-button color="accent" (click)="addImage()" [disabled]="able">
        <span class="material-symbols-outlined">
          photo_camera
        </span>
        Adicionar fotos
      </button>


      <div class="container mt-5" *ngIf="haveImage">
        <div *ngIf="haveImage" class="col-md-12">
          <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)"></webcam>
        </div>


        <div class="col-md-12">

          <button mat-raised-button color="accent" (click)="getSnapshot()">
            Tirar fotos
          </button>
          <button *ngIf="haveImage == true" mat-raised-button color="warn" class="btn-removeImage"
            (click)="removeImage()">
            Remove fotos
          </button>
        </div>
        <div class="col-12">
          <div *ngIf="webcamImage.length > 0" id="results">Aquivos selecionados: </div>
          <div *ngFor="let image of webcamImage" class="image-list">
            <img [src]="image.imageAsDataUrl" />
          </div>
        </div>
      </div>
      <mat-card-actions *ngIf="!isAdmin">
        <button mat-raised-button color="accent" *ngIf="!running()" (click)="startTask()">Iniciar</button>
        <button mat-raised-button *ngIf="running()" (click)="endTask()" color="warn">Finalizar</button>
      </mat-card-actions>
      <mat-card-actions *ngIf="isAdmin">
        <button mat-raised-button color="accent" (click)="create()">Registrar</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #spinner>
  <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>

<div *ngIf="task !== undefined && finished == true" class="finished">
  <mat-card>
    <mat-card-title class="finish-title"> Atividade executada : {{task.name}}</mat-card-title>
    <div style="margin-bottom: 30px; font-size: 20px;"> Tipo de execução: {{typeExecution}}</div>
    <div style="font-size:20px"><span class="material-symbols-outlined">
        task_alt
      </span> Tarefas executadas: </div>
    <div *ngFor="let element of executionList;" class="finish-list">

      <div>
        <div class="material-symbols-outlined">
          check_circle
        </div>
        <div *ngIf="task.type == 'MANUAL'">{{element.item.content}}</div>
        <div *ngIf="task.type == 'MAINTENANCE'">{{element.item.machine.nickName}}</div>
      </div>
    </div>


    <div class="finish-list-area">
    </div>
    <div *ngIf="comments.length >0" class="finish-list-comment">
      Comentário: {{comments}}

    </div>
    <div *ngIf="webcamImage.length > 0" id="results">Aquivos selecionados: </div>
    <div *ngFor="let image of webcamImage" class="image-list">
      <div>
        <img [src]="image.imageAsDataUrl" />
      </div>
    </div>

    <section>
      <button mat-raised-button color="accent" [routerLink]="['/cleaning/manualtask']">Voltar para Lista</button>
    </section>
    <mat-card-content>
    </mat-card-content>
  </mat-card>
</div>
