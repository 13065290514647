import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Voice, VoiceBase, VoiceBaseService } from 'onevoice';

@Component({
  selector: 'alabia-voice-create',
  templateUrl: './voice-create.component.html',
  styleUrls: ['./voice-create.component.scss']
})
export class VoiceCreateComponent {
  public isCreate: boolean;
  public form: FormGroup;
  public baseList: VoiceBase[] = [];

  constructor(
    private reference: MatDialogRef<VoiceCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<Voice>,

    public bases: VoiceBaseService
  ) {
    this.bases.list().subscribe(baseList => {
      this.baseList = baseList;
    });
    this.isCreate = (data.title == null || data.title.length == 0);
    this.form = new FormGroup({
      "title": new FormControl(data.title || "", [Validators.required, Validators.minLength(3)]),
      "base": new FormControl(data.base || undefined, [Validators.required])
    });
  }

  public ngSubmit() {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    });
  }

  public compareBases(b1: VoiceBase, b2: VoiceBase) {
    return b1.id == b2.id;
  }
}
