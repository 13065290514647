import { Injectable } from '@angular/core';
import { GenericModel, GenericService } from '../generic.service';
import { AssetType } from './asset-type.service';
import { Room } from './room.service';

export interface Asset extends GenericModel<number> {
  title: string;
  description: string;
  type: AssetType;
  room: Room;
}

@Injectable({
  providedIn: 'root'
})
export class AssetService extends GenericService<number, Asset> {
  protected path: string = `${this.config.endpoint}/spaces/assets`;
}
