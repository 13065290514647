import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Mood, MoodService, Voice, VoiceService, VoiceSnippet, VoiceSnippetService } from 'onevoice';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'main-voice-splitter',
  templateUrl: './voice-splitter.component.html',
  styleUrls: ['./voice-splitter.component.scss']
})
export class VoiceSplitterComponent implements OnInit {
  private voice?: Voice;
  public moodList?: Mood[];
  public mood?: Mood;

  public working: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private TIMEOUT = 15000;
  private LAST_TIMEOUT = 5000;

  public uploadForm = new FormGroup({
    "upload": new FormControl(null, [Validators.required]),
    "mood": new FormControl(null, [Validators.required]),
  });

  public updateForm = new FormGroup({
    "snippets": new FormArray([]),
  });
  public snippetList: VoiceSnippet[] = [];
  public audio: HTMLAudioElement;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,

    private voices: VoiceService,
    private moods: MoodService,
    private snippets: VoiceSnippetService,

    private snack: MatSnackBar,
  ) {
    this.audio = new Audio();
  }



  public ngOnInit(): void {
    this.route.paramMap.subscribe(map => {
      const pk = Number(map.get("id"));

      this.voices.read(pk).subscribe(voice => {
        this.voice = voice;
      });

      this.moods.list().subscribe(moods => {
        this.moodList = moods;
        this.mood = moods[0];
      })

      this.auth.systemToken().then(_ => {
        console.log("Requested token");
      });
    });

  }

  public submitUpload(stepper: MatStepper) {
    if (!this.voice || !this.mood) {
      return;
    }

    this.working.next(true);
    console.log("Hi");
    this.snippets.splitter(this.voice.id, this.uploadForm.value.mood, this.uploadForm.value.upload).subscribe(uuid => {
      console.log("Split: " + uuid);
      this.getSplitResult(uuid);
      stepper.next();
    }, err => {
      console.log(err);
      this.working.next(false);
    });
  }

  private getSplitResult(uuid: string): void {
    this.snippets.splitResult(uuid).subscribe(snippetsList => {
      console.log("Splitting");
      if (snippetsList.length > 0) {
        this.working.next(false);
        this.snippetList = snippetsList;

        this.updateForm.setControl("snippets", new FormArray(
          this.snippetList.map(
            (snippet: VoiceSnippet) => new FormGroup({
              "text": new FormControl(snippet.text),
            })
          )
        ));
      } else {
        setTimeout(() => this.getSplitResult(uuid), this.TIMEOUT);
      }
    });
  }

  public finishStepper(stepper: MatStepper) {
    stepper.next();
    setTimeout(() => {
      let id = this.route.snapshot.paramMap.get("id");
      this.router.navigateByUrl(`/voices/${id}`);
    }, this.LAST_TIMEOUT);
  }

  public playAudio(index: number): void {
    if (index >= this.snippetList.length) {
      console.error("Could not find audio to play");
      return;
    }

    let snippet = this.snippetList[index];
    this.audio.pause()
    this.audio.src = this.auth.authMediaUrl(this.snippets.snippetMediaUrl(snippet))
    this.audio.play();
  }

  public updateTranscription(index: number): void {
    if (index >= this.snippetList.length) {
      console.error("Could not find audio to play");
      return;
    }

    let snippet = this.snippetList[index];
    let form = this.snippetArray[index];
    if (form.valid) {
      this.snippets.update(snippet.id, form.value.text).subscribe((_) => {
        this.snack.open("Áudio atualizado com sucesso.", "OK");
      });
    }
  }

  get snippetArray(): AbstractControl[] {
    let array = this.updateForm.get("snippets");
    if (array == null) {
      return [];
    }
    return (array as FormArray).controls;
  }

  public removeAudio(index: number) {
    console.log("Remove audio");
    let snippet = this.snippetList[index];

    (this.updateForm.get("snippets") as FormArray).removeAt(index);
    this.snippetList = this.snippetList.filter(value => value.id !== snippet.id);

    this.snippets.delete(snippet).subscribe(ok => {
      console.log("Deleted")
    });
  }

  public onPaste(index: number, event: ClipboardEvent) {
    let content = event.clipboardData;
    if (!content) {
      return;
    }
    let data = content.getData("text").trim().split("\n");
    if (data.length <= 1) {
      return;
    }
    event.preventDefault();
    for (const update of data) {
      if (index >= this.snippetArray.length) {
        return;
      }

      if (update.length > 0) {
        this.snippetArray[index].setValue({ "text": update });
        this.updateTranscription(index);
      }
      index++;
    }
  }
}
