<mat-list>
  <h3 mat-list-item mat-subheader>Medias recebidas</h3>
  <mat-divider></mat-divider>
  <mat-list-item *ngFor="let response of responses">
    <button mat-icon-button (click)="playSound(response.filename)">
      <mat-icon>play_circle_outline</mat-icon>
    </button>
    {{ response.transcription }}
  </mat-list-item>
</mat-list>
