import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription, SubscriptionService } from 'onevoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'onevoice';
import { SubscriptionRequestService } from '../api/subscription-request.service';

export enum ScrollDirection {
  RIGHT = 'right',
  LEFT = 'left'
}


export interface Plan {
  id: number;
  title: string;
  description: string;
  price: number;
  active: boolean;
}

@Component({
  selector: 'alabia-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, AfterViewInit {

  @ViewChild('planCarouselBox', { static: false }) planCarouselBox?: ElementRef;

  public plans: Plan[] = [];

  public scrollDirection = ScrollDirection;
  public showNavigation = false;
  public currentUserData?: Subscription;

  private _x = 0;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _subscriptionRequestService: SubscriptionRequestService,
    public authService: AuthenticationService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._subscriptionRequestService.onSubscriptionChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(subscriptions => {
        this.currentUserData = subscriptions[0];
      });

    this._subscriptionRequestService.onPlanListChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(plans => {
        this.plans = plans;
      })
  }

  ngAfterViewInit() {
    this._canShowArrowsNavigation();
  }

  private _canShowArrowsNavigation() {
    if (this.planCarouselBox) {
      this.showNavigation = (this.planCarouselBox.nativeElement.offsetWidth !== this.planCarouselBox.nativeElement.scrollWidth);
    }
  }

  private _allowedRange(maxWidth: number, toSum: number) {
    this._x += toSum;

    if (this._x < 0) {
      this._x = 0;
    }

    if (this._x >= (maxWidth - toSum)) {
      this._x = maxWidth - toSum
    }
    return this._x;
  }

  public scroll(direction: ScrollDirection) {
    if (!this.planCarouselBox) {
      return;
    }
    const width = this.planCarouselBox.nativeElement.offsetWidth;
    const maxWidth = this.planCarouselBox.nativeElement.scrollWidth;
    const screenMaxWidth = window.innerWidth;
    const sum = direction === ScrollDirection.LEFT ? -width : width;

    const x = this._allowedRange(maxWidth, sum);

    this.planCarouselBox.nativeElement.scrollTo(x, 0);
  }


}
