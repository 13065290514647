import { Component, Input, OnInit } from '@angular/core';
import {
  CorpusIntent, CorpusIntentService
} from 'app/alabia/api/corpus-intent.service';
import {
  CorpusProject,
  CorpusProjectService
} from 'app/alabia/api/corpus-project.service';
import {
  CorpusUtterance, CorpusUtteranceService
} from 'app/alabia/api/corpus-utterance.service';
import { Intent } from 'onevoice';

@Component({
  selector: 'alabia-corpus',
  templateUrl: './corpus.component.html',
  styleUrls: ['./corpus.component.scss']
})
export class CorpusComponent implements OnInit {
  @Input()
  public intent?: Intent;

  public utterance: string = "";
  public list: CorpusUtterance[] = [];
  public filter: CorpusUtterance[] = [];
  public corpusIntent?: CorpusIntent;
  public corpusProject?: CorpusProject;

  constructor(
    private projectService: CorpusProjectService,
    private intentService: CorpusIntentService,
    private utteranceService: CorpusUtteranceService
  ) { }

  ngOnInit(): void {
    this.projectService.get(this.intent?.bot?.id || 0).subscribe(
      project => {
        this.corpusProject = project;
        this.intentService.list(this.intent?.bot?.id || 0).subscribe(intents => {
          let intent = intents.find(value => value.name === this.intent?.name);
          if (!intent) { return; }
          this.corpusIntent = intent;

          if (!this.corpusIntent) {
            return;
          }

          this.utteranceService
            .list(this.corpusIntent.id)
            .subscribe(utterances => {
              this.list = utterances.reverse();
              this.filter = this.list;
            });
        });
      },
      err => {
        if (err.status === 404) {
          this.projectService
            .create(
              {
                id: this.intent?.bot?.id,
                name: (this.intent?.bot?.name || ""),
                description: this.intent?.bot?.description
              }
            )
            .subscribe(created => {
              this.corpusProject = created;
            });
        }
      }
    );
  }

  addUtterance() {
    if (!this.corpusIntent) {
      this.intentService
        .create({
          name: this.intent?.name,
          project_id: this.intent?.bot?.id,
          description: this.intent?.description
        })
        .subscribe(created => {
          this.corpusIntent = created;
          this.addUtterance();
        });
      return;
    }

    let found = this.list.filter(value => value.content && value.content.toLowerCase() == this.utterance.toLowerCase());
    if (found.length > 0) {
      console.log("Utterance already exists");
      this.utterance = "";
      return;
    }
    this.utteranceService
      .create({
        intent_id: this.corpusIntent.id,
        content: this.utterance
      })
      .subscribe(created => {
        this.handleFilters(true, created);
        this.utterance = '';
      });
  }

  removeUtterance(utterance: CorpusUtterance): void {
    this.utteranceService.delete(utterance).subscribe(() => {
      this.handleFilters(false, utterance);
    });
  }

  filterList(toFilter: string) {
    this.filter = this.list.filter(i => i.content && i.content.toLocaleLowerCase().includes(toFilter.toLocaleLowerCase()));
    this.utteranceService.listLength = this.filter.length;
  }

  handleFilters(addElseRemove: boolean, newOrOldItemList: CorpusUtterance) {
    addElseRemove
      ? this.list.unshift(newOrOldItemList)
      : this.list = this.list.filter(value => value.id !== newOrOldItemList.id);

    this.filter = this.list;
  }
}
