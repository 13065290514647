import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { MapUnit } from './map-unit.service';

export interface MachineMap extends Ownable {
  title: string;
  uniqueId: string;
  path: string;
  type:string;
  unit:MapUnit;
}

@Injectable({
  providedIn: 'root'
})
export class  MachineMapService extends OwnableService<MachineMap> {
  protected path: string = `${this.config.endpoint}/hw/maps`;
}
