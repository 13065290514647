import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MachineMap, MachineMapService } from 'onevoice';
import { EditMapsComponent } from '../edit-maps/edit-maps.component';

@Component({
  selector: 'main-list-maps',
  templateUrl: './list-maps.component.html',
  styleUrls: ['./list-maps.component.scss']
})
export class ListMapsComponent implements OnInit {
  public datasource: MatTableDataSource<MachineMap> = new MatTableDataSource<MachineMap>();

  public fields: string[] = [
    "title",
    "actions",
  ];

  constructor(public service: MachineMapService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.service.list().subscribe(mapList => {
      this.datasource.data = mapList;
    });
  }

  @ViewChild(MatPaginator) set paginator(pager: MatPaginator) {
    this.datasource.paginator = pager;
  }

  public editMap(map: Partial<MachineMap> = {}): void {
    let reference = this.dialog.open<EditMapsComponent, Partial<MachineMap>, Partial<MachineMap>>(EditMapsComponent, {
      width: '700px',
      maxWidth: "90%",
      data: map,
    });

    reference.afterClosed().subscribe(map => {
      if (!map) {
        return;
      }

      let mapAction = () => this.service.create(map);
      if (map.id !== undefined) {
        mapAction = () => this.service.update(map);
      }

      mapAction().subscribe(map => {
        let data = this.datasource.data.filter(value => value.id != map.id);
        data.push(map);
        data.sort((m1, m2) => m2.id - m1.id);
        this.datasource.data = data;
      })
    })
  }
}
