<form [formGroup]="form" (ngSubmit)="ngSubmit()">
  <h1 mat-dialog-title>{{ isEdit ? 'Atualizar' : 'Criar' }}</h1>
  <mat-dialog-content>

    <mat-form-field *ngIf="!isEdit">
      <mat-label>Colaborador:</mat-label>
      <mat-select type="text" matInput formControlName="user">
        <mat-option *ngFor="let user of usersList" [value]="user" (click)="selectUser(user)">
          {{user.details.first_name + " " + user.details.last_name}}
        </mat-option>
      </mat-select>
      <div *ngIf="form.get('user')?.invalid && form.get('user')?.touched">
        <small class="error" *ngIf="form.get('user')?.hasError('required')">Usuário é obrigatório.</small>
      </div>
    </mat-form-field>

    <div style="font-size: 20px; margin-bottom: 10px;"*ngIf="isEdit">
     Usuário:    {{ userName() || ''}}
   </div>
    <mat-form-field *ngIf="datamap != []">
      <mat-label>Mapa</mat-label>
      <mat-select type="text" matInput [formControl]="myControl" (valueChange)="getTasks($event)">
        <mat-option *ngFor="let map of mapList" [value]="map">
          {{map.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="taskList.length > 0">
      <mat-label>Tarefa</mat-label>
      <mat-select formControlName="timedTaskList" [compareWith]="compareWith">
        <mat-option *ngFor="let task of taskList" [value]="task" (click)="selectTask(task)">
          {{ task.id + " - " + task.name.replace("execute_task_","") }}
        </mat-option>
      </mat-select>
      <div *ngIf="form.get('timedTaskList')?.invalid && form.get('timedTaskList')?.touched">
        <small class="error" *ngIf="form.get('timedTaskList')?.hasError('required')">Selecione pelo menos uma tarefa.</small>
      </div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Início</mat-label>
      <input matInput type="time" formControlName="startTime" step="1">
      <small class="error" *ngIf="form.get('startTime')?.hasError('required')">Hora é obrigatória.</small>
      <small class="error" *ngIf="form.get('startTime')?.hasError('pattern')">Formato inválido (HH:mm).</small>
  </mat-form-field>


    <div class="schedule-form-wrapper">


      <mat-button-toggle-group multiple>
        <mat-button-toggle value="sunday" (click)="insertDay(1)">
          Dom.
        </mat-button-toggle>
        <mat-button-toggle value="monday" (click)="insertDay(2)">
          Seg.
        </mat-button-toggle>
        <mat-button-toggle value="tuesday" (click)="insertDay(3)">
          Ter.
        </mat-button-toggle>
        <mat-button-toggle value="wednesday" (click)="insertDay(4)">
          Qua.
        </mat-button-toggle>
        <mat-button-toggle value="thursday" (click)="insertDay(5)">
          Qui.
        </mat-button-toggle>
        <mat-button-toggle value="friday" (click)="insertDay(6)">
          Sex.
        </mat-button-toggle>
        <mat-button-toggle value="saturday" (click)="insertDay(7)">
          Sáb.
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div>
      <button (click)="addTimedTask()" type="button">Adicionar</button>
    </div>



    <div *ngIf="tasksSelected != []">
      <mat-list *ngFor="let singleTask of tasksSelected">
        <mat-list-item style="display: flex; justify-content:space-between;">
          <div mat-stroked-button color="warn" style="margin-left: 50px; display: flex;"
            (click)="deleteFromSelecetedList(singleTask)">
            <mat-icon>delete</mat-icon>
            {{singleTask.machineTask.name.replace("execute_task_","") + " "}}
            <div>{{textDay(singleTask.repeat)}}</div>
          </div>

        </mat-list-item>

      </mat-list>
    </div>
    <div>
      <mat-list>
        <mat-list-item style="display: flex; justify-content:space-between;">
          <div mat-stroked-button color="warn" style="margin-left: 50px; display: flex;">


          </div>

        </mat-list-item>

      </mat-list>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button type="submit">Salvar</button>
    <button mat-button [mat-dialog-close]="undefined">Fechar</button>
  </mat-dialog-actions>
</form>
