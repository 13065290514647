import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bot, BotService, BotStatus, CustomerChannelType } from 'onevoice';
import { BotCreateComponent } from './dialogs/bot-create/bot-create.component';
import { TagListDialogComponent } from './dialogs/tag-list-dialog/tag-list-dialog.component';

function byBotId(a: Bot, b: Bot): number {
    return b.id - a.id;
}

@Component({
    selector: 'alabia-bot',
    templateUrl: './bot.component.html',
    styleUrls: ['./bot.component.scss']
})
export class BotComponent implements OnInit {
    bots: Bot[] = [];

    constructor(
        private _botService: BotService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this._botService.list().subscribe(bots => {
            this.bots = bots.sort(byBotId);
        });
    }

    createBot() {
        const reference = this.dialog.open<BotCreateComponent, Partial<Bot>, Bot>(
            BotCreateComponent,
            {
                width: '500px',
                maxWidth: '95%',
                data: {
                    name: '',
                    description: '',
                    presentation: '',
                    status: BotStatus.ENABLED,
                    open: '',
                    close: '',
                    away: '',

                    backgroundColor: "purple",
                    foregroundColor: "white",
                    backgroundColor2: "grey",
                    contacts: [CustomerChannelType.EMAIL, CustomerChannelType.MOBILE],
                    identifiers: [],
                }
            }
        );

        reference.afterClosed().subscribe(bot => {
            if (bot) {
                this.bots.push(bot);
            }
        });
    }

    public openTagList(): void {
        const reference = this.dialog.open<TagListDialogComponent, void, void>(TagListDialogComponent, {
            width: "800px",
            maxWidth: "95%",
        });
    }

    public get enabledList(): Bot[] {
        return this.bots.filter(value => value.status === BotStatus.ENABLED);
    }

    public get disabledList(): Bot[] {
        return this.bots.filter(value => value.status === BotStatus.DISABLED);
    }
}
