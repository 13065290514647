import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Customer, CustomerService, InformationType } from 'onevoice';

@Component({
  selector: 'alabia-customer-visualizer',
  templateUrl: './customer-visualizer.component.html',
  styleUrls: ['./customer-visualizer.component.scss'],
  animations: fuseAnimations,
})

export class CustomerVisualizerComponent implements OnInit {
  @Output() navigation = new EventEmitter();

  customerData?: Customer;
  customerInfo: any = {};
  informationTypes: InformationType[] = [];

  constructor(
    private _router: Router,
    private _location: Location,
    private _route: ActivatedRoute,
    private _customerService: CustomerService,
  ) {
    const urlSplit = this._router.url.split('?')[0].split('/');
    const customerID = Number(urlSplit[2]);

    const page = urlSplit[urlSplit.length - 1];

    this._customerService.read(customerID).subscribe(
      (customer: Customer) => {
        this.customerData = customer;
      },
      err => console.error('not ok'),
    );
  }

  ngOnInit() { }

  get returnUrl() {
    return this._route.snapshot.queryParams['returnUrl'];
  }

  backButton() {
    if (this.returnUrl) {
      this._router.navigateByUrl('/dashboard');
    } else {
      this._router.navigateByUrl('/customer');
    }
  }
}
