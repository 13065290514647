import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChecklistItem, ChecklistItemService, Machine, MachineMap, MachineMapService, MachineService, MachineTask, MapUnit, MapUnitService } from 'onevoice';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'main-edit-maintenance',
  templateUrl: './edit-maintenance.component.html',
  styleUrls: ['./edit-maintenance.component.scss']
})
export class EditMaintenanceComponent implements OnInit {

  public form: FormGroup;
  public clForm: FormGroup = new FormGroup({
    "content": new FormControl("", Validators.required),
    "area": new FormControl("", Validators.required),
  });
  public machine: Machine[] = [];
  public machineList: Machine[] = [];

  public unitList?: MapUnit[];
  public machineMap!: MachineMap;
  public typeList: string[] = ['MAINTENANCE']
  public checklist?: ChecklistItem[];
  constructor(
    public dialog: MatDialog,
    public reference: MatDialogRef<EditMaintenanceComponent>,
    public machines: MachineService,
    public checklists: ChecklistItemService,
    public mapUnitService: MapUnitService,
    @Inject(MAT_DIALOG_DATA) public data: Partial<MachineTask> = {},
  ) {

    this.form = new FormGroup({
      "map": new FormControl(data.map || undefined, [Validators.required]),
      "name": new FormControl(data.name || "", [Validators.required]),
      "description": new FormControl(data.description || "", [Validators.required]),
      "type": new FormControl(data.type || "", [Validators.required]),

    }, []);

    this.machines.list().subscribe(machineList => {

      this.machineList = machineList

    })

    this.listUnit();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  public listUnit() {
    this.mapUnitService.list().subscribe(unitsList => {
      this.unitList = unitsList;
      console.log(this.unitList)
      console.log(this.machineMap)

    })
    this.form.value
  }

  get title(): string {
    return (this.data.id) ? "Editar Tarefa" : "Criar Tarefa";
  }


public submitTask(): void {

  this.reference.close({
    ...this.data,
    ...this.form.value,
  })

  console.log(this.data)
}

public compareMap(map1: MachineMap, map2: MachineMap) {
  return map1.id == map2.id
}
public compareUnit(unit1:MapUnit, unit2:MapUnit) {
  return unit1 && unit2 ? unit1.id === unit2.id : unit1 === unit2;
}
public addChecklist() {
  let item: Partial<ChecklistItem> = {
    task: this.data,
    ...this.clForm.value,
  };
  this.checklists.create(item).subscribe(created => {
    if (this.checklist) {
      this.checklist.push(created);
    }
    this.clForm.reset({ content: "" });
  });
}

public deleteChecklist(checklist: ChecklistItem) {
  this.checklists.delete(checklist.id).subscribe(_ => {
    this.checklist = this.checklist?.filter(value => value.id != checklist.id);
  })
}
}
