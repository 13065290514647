import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Mood, MoodService, Voice, VoiceModel, VoiceModelService } from 'onevoice';

@Component({
  selector: 'main-voice-model-dialog',
  templateUrl: './voice-model-dialog.component.html',
  styleUrls: ['./voice-model-dialog.component.scss']
})
export class VoiceModelDialogComponent implements OnInit {
  public mood?: Mood;
  public moodList: Mood[] = [];

  public datasource: MatTableDataSource<VoiceModel> = new MatTableDataSource<VoiceModel>();

  @ViewChild(MatPaginator, { static: false })
  set setPaginator(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }

  public columns: string[] = [
    "date",
    "mood",
    "status",
    "actions",
  ]

  constructor(
    public service: VoiceModelService,
    public moodService: MoodService,
    @Inject(MAT_DIALOG_DATA) public voice: Voice,
    private reference: MatDialogRef<VoiceModelDialogComponent>,
    private snack: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.service.list(this.voice).subscribe(modelList => {
      this.datasource.data = modelList;
    });
    this.moodService.list().subscribe(moodList => {
      this.moodList = moodList;
    })
  }

  submitTraining() {
    if (this.mood) {
      this.service.train(this.voice, this.mood).subscribe(voiceModel => {
        this.reference.close(voiceModel);
      })
    }
  }

  setActive(model: VoiceModel): void {
    this.service.setActive(model).subscribe(ok => {
      if (ok) {
        this.snack.open("O modelo foi ativado!", "OK");
        this.ngOnInit();
      }
    })
  }
}
