<div *ngIf="taskList.data ; else spinner" class="task-list">
    <table mat-table [dataSource]="taskList">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                Nome
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="map">
            <th mat-header-cell *matHeaderCellDef>
                Mapa
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.map.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                Ações
            </th>
            <td mat-cell *matCellDef="let element">
                    <a mat-icon-button [routerLink]="[element.id]">
                        <mat-icon>add_circle_outline</mat-icon>
                    </a>
                <button mat-icon-button (click)="editTask(element)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fields"></tr>
        <tr mat-row *matRowDef="let row; columns: fields;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
        aria-label="Selecionar quantidade de elementos na página">
    </mat-paginator>


    <button class="alabia-fab" mat-fab (click)="editTask()">
        <mat-icon>add</mat-icon>
    </button>
</div>

<ng-template #spinner>
    <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>
