<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <span class="material-symbols-outlined">
      schedule
    </span>
    <h1 class="logo-text">Escala de trabalho</h1>
    <br />
  </div>
  <div class="mat-headline" class="title-dashboard">Colaboradores</div>
  <div class="header-range">
    <div style="display: flex;">

      <div class="map-layout-col2">
        <table mat-table [dataSource]="dataSourceUsers" class="mat-elevation-z2">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let item">
              {{ item.details.first_name }}
            </td>
          </ng-container>


          <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef>Sobrenome</th>
            <td mat-cell *matCellDef="let item">{{ item.details.last_name }}</td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Localização</th>
            <td mat-cell *matCellDef="let item">
              {{item.details.location == undefined ? "Não definido" : item.details.location.name}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsUsers" (click)="selectedMachineRow(row)" [ngStyle]="{
          'background-color':
            userSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'}"></tr>
        </table>
        <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
          [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
        </mat-paginator>
      </div>
      <div>
        <div class="mat-elevation-z2 description">

          <div style="display:flex;">
            <div style="margin: 50px;">
              <div>
                <table>
                  <tr>
                    <td>Colaborador: </td>
                    <td>{{userSelected?.details?.first_name + " " + userSelected?.details?.last_name}}</td>
                  </tr>
                  <tr>
                    <td>Localização: </td>
                    <td>{{userSelected?.details?.location?.name}}</td>
                  </tr>
                  <tr>
                    <td>Perfil: </td>
                    <td *ngFor="let skill of userSelected?.authorities">
                      {{skill.authorization}}
                    </td>
                  </tr>
                </table>
              </div>

              <button *ngIf="userAuthorities" mat-icon-button (click)="editScheduler(userSelected)">
                <mat-icon><span class="material-symbols-outlined">
                    add_circle
                  </span></mat-icon>
              </button>
            </div>
            <div class="schedule">
              <div *ngFor="let single of scheduler">

                <div>
                  <div>
                    Inicio: {{single.start}}
                  </div>
                  <div>
                    Almoço: {{single.startPause}}
                  </div>
                  <div>
                    Retorno do almoço: {{single.endPauseTime}}
                  </div>

                  <div>
                    Fim de expediente : {{single.end}}
                  </div>
                </div>
                <div>
                  <div>
                  {{ repet(single.repeat)}}
                </div>
                </div>
                <div>
                </div>

                <button mat-icon-button (click)="deleteScheduler(single)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>


        </div>
        <div class="mat-elevation-z2 description">
          <table class="dias-semana-table">
            <thead>
              <tr>
                <th>Hora</th>
                <th>Domingo</th>
                <th>Segunda-feira</th>
                <th>Terça-feira</th>
                <th>Quarta-feira</th>
                <th>Quinta-feira</th>
                <th>Sexta-feira</th>
                <th>Sábado</th>
              </tr>
            </thead>
            <tr *ngFor="let hour of hours; let i = index">

              <td>{{ hour }}</td>
            <td *ngFor="let single of allWorkScheduler">

              <tr *ngFor="let day of single ">
                <div *ngIf="compare(day,hour) " style="margin: 3px;	border-radius: 5px;" [ngClass]="{
                  'workhour': day.end.slice(0,2) != hour.slice(0,2),
                  'lastworkhour': day.end.slice(0,2) == hour.slice(0,2)
                }" >
                    {{day.user.details.first_name}}   {{day.user.details.last_name}} {{day.end.slice(0,2) == hour.slice(0,2) && day.end.slice(-2) != hour.slice(-2) ? day.end : "" }}
                  </div>
              </tr>
            </td>
          </table>
        </div>
      </div>
    </div>
    <!-- <button class="alabia-fab" mat-fab (click)="editTask()">
    <mat-icon>add</mat-icon>
  </button> -->

  </div>
