import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  url: string = `${environment.busService}/api/report`;

  constructor(private _httpClient: HttpClient) { }


  public topIntents() {
    let path = "intents/top";
    return this._httpClient.get<number[][]>(`${this.url}/${path}`);
  }

  public conversationsPerChannel() {
    let path = "conversations/channel";
    return this._httpClient.get<number[][]>(`${this.url}/${path}`);
  }

  public conversationsPerMonth() {
    let path = "conversations/month";
    return this._httpClient.get<any[][]>(`${this.url}/${path}`);
  }

  public messagesPerMonth() {
    let path = "messages/month";
    return this._httpClient.get<any[][]>(`${this.url}/${path}`);
  }

  public usersPerMonth() {
    let path = "users/month";
    return this._httpClient.get<any[][]>(`${this.url}/${path}`);
  }
}
