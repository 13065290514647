import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { AuthenticationService, Voice, VoiceService } from 'onevoice';
import { VoiceCreateComponent } from './voice-create/voice-create.component';

function ByID(v1: Voice, v2: Voice) {
  return v2.id - v1.id;
}

@Component({
  selector: 'alabia-voice-content',
  templateUrl: './voice-content.component.html',
  styleUrls: ['./voice-content.component.scss']
})
export class VoiceContentComponent implements OnInit {
  public icon: string = "settings_voice";
  public title: string = "Vozes";

  public columnsToDisplay: string[] = [
    "title", "actions"
  ];

  public datasource = new MatTableDataSource<Voice>([]);
  @ViewChild(MatPaginator, { static: false }) public paginator?: MatPaginator;


  constructor(public voices: VoiceService, public auth: AuthenticationService, public dialog: MatDialog, public router: Router) {
  }

  ngOnInit() {
    this.auth.systemToken().then(_ => {
      console.log("Requested token");

      this.voices.list().subscribe(voices => {
        this.datasource.data = voices.sort(ByID);
        if (this.paginator) { this.datasource.paginator = this.paginator; }
      });
    });
  }

  createVoice() {
    let reference = this.dialog.open<VoiceCreateComponent, Partial<Voice>, Voice>(VoiceCreateComponent, {
      width: '500px',
      maxWidth: '95%',
      data: { title: "" },
    });
    reference.afterClosed().subscribe((data: Voice | undefined) => {
      if (data) {
        this.voices.create(data).subscribe(created => {
          this.datasource.data = this.datasource.data.concat([created]);
        });
      }
    });
  }

  editVoice(voice: Voice) {
    let reference = this.dialog.open<VoiceCreateComponent, Partial<Voice>, Voice>(VoiceCreateComponent, {
      width: '500px',
      maxWidth: '95%',
      data: voice,
    });

    reference.afterClosed().subscribe((data: Voice | undefined) => {
      if (data) {
        this.voices.update(data).subscribe(updated => {
          this.datasource.data = this.datasource.data.filter(
            value => value.id !== updated.id
          ).concat([updated]).sort(ByID);
        });
      }
    });
  }

  deleteItem(voice: Voice) { }
}
