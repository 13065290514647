import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConclusionTag, ConclusionTagService } from 'onevoice';

@Component({
  selector: 'main-tag-create',
  templateUrl: './tag-create.component.html',
  styleUrls: ['./tag-create.component.scss']
})
export class TagCreateComponent implements OnInit {
  public verb: string = "Criar";
  public form: FormGroup = new FormGroup({
    "title": new FormControl("", [Validators.required, Validators.minLength(3)]),
    "description": new FormControl("", [Validators.required, Validators.minLength(3)]),
  });

  constructor(
    private reference: MatDialogRef<TagCreateComponent>,
    private service: ConclusionTagService,
    @Inject(MAT_DIALOG_DATA) private data: Partial<ConclusionTag>,
  ) {
    this.form.setValue({
      title: data.title || "",
      description: data.description || "",
    });
    if (data.id) {
      this.verb = "Atualizar";
    }
  }

  public ngOnInit(): void {
  }

  public onSubmit(): void {
    console.log(this.form.value);

    if (this.data.id === undefined) {
      this.service.create(this.form.value).subscribe(created => {
        this.reference.close(created);
      });
    } else {
      this.service.update({ ...this.form.value, id: this.data.id }).subscribe(updated => {
        this.reference.close(updated);
      });

    }
  }
}
