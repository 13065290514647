<div class="alabia-generic-wrapper">
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="name">
            <th class="floors-name" mat-header-cell *matHeaderCellDef>Nome</th>
            <td class="floors-name" mat-cell *matCellDef="let item">
                {{ item.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="maps">
            <th class="floors-name" mat-header-cell *matHeaderCellDef>Mapas</th>
            <td class="floors-name" mat-cell *matCellDef="let item">
                {{ mapNames(item) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th class="floors-actions" mat-header-cell *matHeaderCellDef>Ações</th>
            <td class="floors-actions" mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="editItem(item)">
                    <mat-icon>edit</mat-icon>
                </button>
                <a [routerLink]="['/spaces', 'floors', item.id, 'rooms']" mat-icon-button >
                    <mat-icon>visibility</mat-icon>
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let item; columns: columns"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    <button class="alabia-fab " mat-fab (click)="editItem()">
        <mat-icon>add</mat-icon>
    </button>
</div>