import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService, AuthorizationType, Redirect, RedirectService, RedirectType } from 'onevoice';
import { EditComponent } from '../edit/edit.component';
import { QrcodeService } from '../qrcode.service';


@Component({
  selector: 'main-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  public redirect?: Redirect;

  public RedirectType = RedirectType;

  constructor(
    public redirects: RedirectService,
    public service: QrcodeService,
    private _route: ActivatedRoute,
    private _router: Router,
    private auth: AuthenticationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this._route.params.subscribe((param: Params) => {
      let redirectId = param["id"];

      this.redirects.read(redirectId).subscribe(redirect => {
        console.log("Redirecting browser");
        console.log(redirect);
        this.redirect = redirect;
        console.log(redirect)

        if (!this.isManager()) {
          window.location.href = this.service.getRedirectUrl(redirect);
        }
      }, err => {
        this.navigateWhenAnonymous();
      });
    });
  }

  public navigateWhenAnonymous(): void {
    window.location.href = "https://www.alabia.com.br";
  }

  public isManager(): boolean {
    let current = this.auth.current.value;
    if (current === null) {
      console.log("Auth current is null");
      return false;
    }

    for (const authority of current.authorities) {
      if (authority.authorization == AuthorizationType.ADMIN || authority.authorization == AuthorizationType.HR) {
        return true;
      }
    }
    return false;
  }

  public editRedirect(redirect: Partial<Redirect> = {}) {
    let reference = this.dialog.open<EditComponent, Partial<Redirect>, Partial<Redirect>>(EditComponent, {
      width: "700px",
      maxWidth: "90%",
      data: redirect,
    });

    reference.afterClosed().subscribe(redirect => {
      if (redirect === undefined) {
        return;
      }

      if (redirect.id) {
        this.redirects.update(redirect).subscribe(updated => {
          this.redirect = updated;
        });
      } else {
        this.redirects.create(redirect).subscribe(created => {
          this.redirect = created;
        });
      }
    });
  }
}
