<div class="header accent"></div>

<div class="menu" [routerLink]="['real-time']">
  <mat-icon class="menu__icon">
    timelapse
  </mat-icon>
  <h3 class="menu__title">
    Tempo Real
  </h3>
  <span class="menu__description">
    Acompanhe todas as atividades de sua equipe em tempo real
  </span>
</div>

<div class="menu" [routerLink]="['dashboard']">
  <mat-icon class="menu__icon">
    dashboard
  </mat-icon>
  <h3 class="menu__title">
    Dashboard
  </h3>
  <span class="menu__description">
    Informações e gráficos sobre a equipe de limpeza
  </span>
</div>

<div class="menu" [routerLink]="['calendar']">
  <mat-icon class="menu__icon">
    event
  </mat-icon>
  <h3 class="menu__title">
    Calendário
  </h3>
  <span class="menu__description">
    Planeje e acompanhe todas as atividades de sua equipe
  </span>
</div>
<div class="menu" [routerLink]="['scheduler']" *ngIf="userAuthorities">
  <mat-icon class="menu__icon">
    scheduler
  </mat-icon>
  <h3 class="menu__title">
    Escala de trabalho
  </h3>
  <span class="menu__description">
    Planeje e acompanhe todas as atividades de sua equipe
  </span>
</div>

<div class="menu" [routerLink]="['maps']" *ngIf="userAuthorities">
  <mat-icon class="menu__icon">
    map
  </mat-icon>
  <h3 class="menu__title">
    Mapas
  </h3>
  <span class="menu__description">
    Crie e gerencie seus mapas e tarefas
  </span>
</div>
<div class="menu" [routerLink]="['tasks']" *ngIf="userAuthorities">
  <mat-icon class="menu__icon">
    done_all
  </mat-icon>
  <h3 class="menu__title">
    Tarefas manuais
  </h3>
  <span class="menu__description">
    Lista com todas as tarefas manuais
  </span>
</div>

<div class="menu" [routerLink]="['manualtask']">
  <mat-icon class="menu__icon">
    done_all
  </mat-icon>
  <h3 class="menu__title">
    Minhas tarefas
  </h3>
  <span class="menu__description">
    Lista com tarefas manuais
  </span>
</div>
<!-- <div class="menu" [routerLink]="['member']">
  <mat-icon class="menu__icon">
    groups
  </mat-icon>
  <h3 class="menu__title">
    Membros
  </h3>
  <span class="menu__description">
    Acompanhe cada membro da sua equipe e suas atividades
  </span>
</div> -->

<!-- <div class="menu" [routerLink]="['report']">
  <mat-icon class="menu__icon">
    assessment
  </mat-icon>
  <h3 class="menu__title">
    Analise
  </h3>
  <span class="menu__description">
    Análise os problemas relatados por membros da sua equipe
  </span>
</div> -->
<!--
<div class="menu" [routerLink]="['maintenance']">
  <mat-icon class="menu__icon">
    <span class="material-symbols-outlined">
      engineering
    </span>
  </mat-icon>
  <h3 class="menu__title">
    Manutenção
  </h3>
  <span class="menu__description">
    Acompanhe o histórico de manutenções e as futuras manutenções.
  </span>
</div> -->
