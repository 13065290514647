import { getLocaleNumberFormat, getLocaleNumberSymbol, NumberFormatStyle, NumberSymbol } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, QueryList, ViewChildren, LOCALE_ID, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { AuthenticationService, ConclusionTagService, MachineExecution, MachineExecutionService, MachineMap, MachineMapService, MachineTask, MachineTaskService } from 'onevoice';

function ByDate(p1: MachineExecution, p2: MachineExecution) {
  return p2.start - p1.start;
}


@Component({
  selector: 'robot-dashboard',
  templateUrl: './robot-dashboard.component.html',
  styleUrls: ['./robot-dashboard.component.scss']
})


export class RobotDashboardComponent implements OnInit, AfterViewInit {
  public selectedTab: string = 'Áreas';
  public selectedIndex: number = 0;
  public dataExecution = new MatTableDataSource<MachineExecution>([]);
  public dataExecutionSlice = new MatTableDataSource<MachineExecution>([]);
  public dataExecutionRange = new MatTableDataSource<MachineExecution>([]);
  public dataMap = new MatTableDataSource<MachineMap>([]);
  public dataTask = new MatTableDataSource<MachineTask>([]);
  public datatest = new MatTableDataSource<MachineExecution>([]);
  public chart?: Chart;
  public chart2?: Chart;
  public ctx: any;
  public ctx2: any;
  public chartZone?: Chart;
  public months: any[] = [];

  public days: any[] = [];
  public axisXfull: any[] = [];
  public axisXmonth: any[] = [];
  public axisXdays: any[] = [];
  public axisXhour: any[] = [];

  public tasksLenght: number = 0;
  public zoneLenght: number = 0;
  public mapsLenght: number = 0;
  public timeLenght: number = 0;
  public mapLenght: number = 0;
  public memberTasks: number[] = [];
  public memberMaps: number[] = [];
  public memberZone: number[] = [];
  public memberTime: number[] = [];
  public totalAreaLenght: number = 0;
  public historicView: string = '';

  // Date
  public endDay?: Date;
  public startDay?: Date;
  public today: Date = new Date();
  public minDate: Date = new Date(1647905904 * 1000);

  public members: string[] = [];
  public gap: string = 'mensal';
  public range = new FormGroup({
    start: new FormControl,
    end: new FormControl,
  });


  // axis Y all
  public yAllZone: number[] = [];
  public yAllArea: number[] = [];
  public yAllTask: number[] = [];
  public yAllMap: number[] = [];
  public yAllTime: number[] = [];

  // axis Y month
  public yMonthZone: number[] = [];
  public yMonthArea: number[] = [];
  public yMonthTask: number[] = [];
  public yMonthMap: number[] = [];
  public yMonthTime: number[] = [];

  // axis Y day
  public yDayZone: number[] = [];
  public yDayArea: number[] = [];
  public yDayTask: number[] = [];
  public yDayMap: number[] = [];
  public yDayTime: number[] = [];

  // axis Y chats
  public yAxisZone: number[] = [];
  public yAxisArea: number[] = [];
  public yAxisTask: number[] = [];
  public yAxisMap: number[] = [];
  public yAxisTime: number[] = [];


  public columnsToDisplay: string[] = [
    "id", "name", "mapName", "operator"
  ];


  @ViewChild(MatPaginator) public paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public barChartLabels: any[] = [];
  public barChartLabelsMember: any[] = [];
  public barChartDataMemberTask: any[] = [];
  public barChartDataMemberTime: any[] = [];
  public barChartDataMemberZone: any[] = [];
  public barChartDataMemberMaps: any[] = [];
  public barChartDataZone: any[] = [];
  public barChartDataTask: any[] = [];
  public barChartDataMap: any[] = [];
  public barChartDataTime: any[] = [];
  public barChartDataMember: any[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;

  public lineChartConfig: ChartOptions = {
    legend: {
      display: true,
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    scales: {
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,
        },
      }],
      xAxes: [{
        display: true,
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public polarChartConfig: ChartOptions = {
    legend: {
      display: true,
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true,
        },
      }],
      xAxes: [{
        display: false,

      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',

      },
    },
  };

  public doughnutChartConfig: ChartOptions = {
    legend: {
      display: false,
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart'
    },
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          beginAtZero: true,
        },
      }],
      xAxes: [{
        display: false,
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };


  constructor(
    private serviceMachineExecution: MachineExecutionService,
    private serviceMachineMap: MachineMapService,
    private serviceMachineTask: MachineTaskService,
    private auth: AuthenticationService,
    // private params: HttpParams,
  ) { }

  ngOnInit(): void {
    this.auth.systemToken().then(_ => {
      // this.loadRange();
      // this.loadIntervalData();
      this.loadData();
      // this.interval();
    });
  }

  ngAfterViewInit(): void {
    // this.barChartLabels = this.axisXfull;
    // this.barChartLabelsMember = this.members;
    // console.log("after view")
    // console.log(this.eixoY)


    if (this.paginator) {
      this.dataExecutionSlice.paginator = this.paginator;
    }

    if (this.sort) {
      this.dataExecutionSlice.sort = this.sort;
    }
  }

  // public loadRange(): void {
  //   this.range = new FormGroup({
  //     start: new FormControl<Date | null>(null),
  //     end: new FormControl<Date | null>(null),
  //   });
  // }


  public loadData(): void {
    this.serviceMachineExecution.list().subscribe(history => {
      // this.dataExecution.data = history;
      this.dataExecution = new MatTableDataSource<MachineExecution>(history.sort(ByDate));

      // this.dataExecution.data = history.sort((a, b) => a.data.createdAt - b.data.createdAt);
      // this.dataExecution.data.sort((a, b) => a.data.createdAt - b.data.createdAt);
      // this.startDay = new Date(this.dataExecution.data[0].data.createdAt * 1000);
      // this.endDay = this.today;
      this.dataExecutionSlice.data = this.dataExecution.data.slice(0, 100);
      // this.dataExecutionSlice.data.sort((a, b) => a.data.createdAt - b.data.createdAt);
      // this.datatest.data = history.slice(0,100);
      // this.axisXgenerate();
      // this.loadDataChart();
      // this.interval();
      console.log("machine execution")
      console.log(this.dataExecution.data)
      this.axisXgenerate();

    });
  }

  public loadDataChart() {
    this.dataExecution.data.sort((a, b) => a.data.createdAt - b.data.createdAt);
    console.log(this.dataExecution.data)


    // this.startDay = new Date(this.dataExecution.data[0].data.createdAt * 1000);
    // this.axisXgenerate


    // gerando valores Geral  ( consertar, os valores nao podem ser relidos, apenas atribuidos novamente)
    if (this.gap == 'mensal') {

      this.startDay = new Date(this.dataExecution.data[0].data.createdAt * 1000);
      let month = new Date(this.startDay.getFullYear(), this.startDay.getMonth(), 1);

      if (month != this.months[0]) {

        // gerando o eixo X
        while (month < this.today) {
          this.months.push(month);
          let monthName = month.toLocaleString('default', { month: 'long' });
          let yearName = month.toLocaleString('default', { year: 'numeric' });
          let xAxiosName = monthName + '/' + yearName.toString()
          this.axisXfull.push(xAxiosName);
          month = new Date(month.getFullYear(), month.getMonth() + 1, 1);
        }

        // atribuir 0 ao eixo Y
        this.yAllTask = new Array(this.axisXfull.length).fill(0);
        this.yAllZone = new Array(this.axisXfull.length).fill(0);
        this.yAllTime = new Array(this.axisXfull.length).fill(0);
        this.yAllMap = new Array(this.axisXfull.length).fill(0);
        this.yAllArea = new Array(this.axisXfull.length).fill(0);

        // this.memberTasks = new Array(this.axisXfull.length).fill(0);
        // this.memberMaps = new Array(this.axisXfull.length).fill(0);
        // this.memberZone = new Array(this.axisXfull.length).fill(0);
        // this.memberTime = new Array(this.axisXfull.length).fill(0);

        this.dataExecution.data.map(e => {
          let date = new Date(e.data.createdAt * 1000);
          for (let i = 0; i < this.months.length; i++) {
            if (date.getMonth() == this.months[i].getMonth() && date.getFullYear() == this.months[i].getFullYear()) {

              // ************* members ******************
              if (this.members.indexOf(e.data.operator) == -1) {
                this.members.push(e.data.operator);
              }

              // ********** task *******
              if (this.yAllTask)
                this.yAllTask[i] += e.data.tasks.length;
              this.tasksLenght += e.data.tasks.length;

              if (this.memberTasks[this.members.indexOf(e.data.operator)] == undefined) {
                this.memberTasks[this.members.indexOf(e.data.operator)] = e.data.tasks.length;
              } else {
                this.memberTasks[this.members.indexOf(e.data.operator)] += e.data.tasks.length;
              }


              // ********** area limpa *********
              this.yAllZone[i] += e.data.coverageArea;
              this.yAllZone[i] = parseFloat(this.yAllZone[i].toFixed(3));
              // this.eixoYzone[i] = this.eixoYzone[i];
              this.zoneLenght += e.data.coverageArea;


              // ********** total area ********
              this.yAllArea[i] += e.data.totalArea;
              this.yAllArea[i] = parseFloat(this.yAllArea[i].toFixed(3));
              // this.totalArea[i] = parseFloat(this.eixoYzone[i].toFixed(5));
              // this.totalArea[i] = this.eixoYzone[i];
              this.totalAreaLenght += e.data.totalArea;


              // member area
              if (this.memberZone[this.members.indexOf(e.data.operator)] == undefined) {
                this.memberZone[this.members.indexOf(e.data.operator)] = e.data.coverageArea;
                this.memberZone[this.members.indexOf(e.data.operator)] = parseFloat(this.memberZone[this.members.indexOf(e.data.operator)].toFixed(2));
              } else {
                this.memberZone[this.members.indexOf(e.data.operator)] += e.data.coverageArea;
                this.memberZone[this.members.indexOf(e.data.operator)] = parseFloat(this.memberZone[this.members.indexOf(e.data.operator)].toFixed(2));
              }

              // ********** maps  **********
              this.yAllMap[i]++;
              this.mapLenght++;

              // member maps
              if (this.memberMaps[this.members.indexOf(e.data.operator)] == undefined) {
                this.memberMaps[this.members.indexOf(e.data.operator)] = 1;
              } else {
                this.memberMaps[this.members.indexOf(e.data.operator)]++;
              }

              // ********* time ***********
              this.yAllTime[i] += e.duration / 60;
              this.yAllTime[i] = parseFloat((this.yAllTime[i]).toFixed(2));
              this.timeLenght += e.duration / 60;

              // member time
              if (this.memberTime[this.members.indexOf(e.data.operator)] == undefined) {
                this.memberTime[this.members.indexOf(e.data.operator)] = e.duration / 60;
                this.memberTime[this.members.indexOf(e.data.operator)] = parseFloat(this.memberTime[this.members.indexOf(e.data.operator)].toFixed(2));
              } else {
                this.memberTime[this.members.indexOf(e.data.operator)] += e.duration / 60;
                this.memberTime[this.members.indexOf(e.data.operator)] = parseFloat(this.memberTime[this.members.indexOf(e.data.operator)].toFixed(2));
              }
            }


          }
        });
      }

      this.yAxisZone = this.yAllZone;
      this.yAxisArea = this.yAllArea;
      this.yAxisTask = this.yAllTask;
      this.yAxisMap = this.yAllMap;
      this.yAxisTime = this.yAllTime;


    }

    // ************** MENSAL **********************

    else if (this.gap == 'diario') {
      console.log("oooooooooooooooooooooi")
      this.startDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1)
      // let day = new Date(this.endDay.getFullYear(), this.endDay.getMonth(), 1);
      let day = this.startDay;
      console.log("----- hoje -----")
      console.log(day)
      // console.log("----- amanha ----")
      // day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
      // console.log(this.endDay)
      // console.log("---- fim ----")
      // console.log(this.endDay)

      if (this.days.length == 0) {
        console.log("primeiro valor do vetor X")
        console.log(this.days[0])

        // eixo X
        while (day < this.today) {
          this.days.push(day);
          let monthName = day.toLocaleString('default', { month: 'long' });
          let dayName = day.toLocaleString('default', { day: 'numeric' });
          let xAxisName = dayName + '/' + monthName.toString()
          this.axisXmonth.push(xAxisName);
          day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);
          console.log("hoje2: ")
          console.log(day)
        }
        // atribuir 0 ao eixo Y
        this.yMonthTask = new Array(this.axisXmonth.length).fill(0);
        this.yMonthZone = new Array(this.axisXmonth.length).fill(0);
        this.yMonthTime = new Array(this.axisXmonth.length).fill(0);
        this.yMonthMap = new Array(this.axisXmonth.length).fill(0);
        this.yMonthArea = new Array(this.axisXmonth.length).fill(0);

        // params date 
        let params = new HttpParams();
        params = params.append('startDate', this.startDay.getTime())
        params = params.append('endDate', this.today.getTime())
        // params.set('startDate', this.startDay.getTime() )
        console.log('testando os parametros')
        console.log(params)
        this.serviceMachineExecution.list(params).subscribe(history => {
          this.dataExecutionRange.data = history;
        });

        console.log('testando os dados: ')
        console.log(this.dataExecutionRange.data)

        this.dataExecutionRange.data.map(e => {
          let date = new Date(e.data.createdAt * 1000);
          for (let i = 0; i < this.days.length; i++) {
            if (date.getMonth() == this.days[i].getMonth() && date.getFullYear() == this.days[i].getFullYear() && date.getFullYear() == this.days[i].getFullYear() && date.getDate() == this.days[i].getDate()) {

              // ********** task *******
              this.yMonthTask[i] += e.data.tasks.length;
            }
          }
        });

      }

      // this.barChartLabels = this.axisXfull;
      this.yAxisTask = this.yMonthTask;
      this.barChartLabels = this.axisXmonth;


      console.log("eixo x dias")
      console.log(this.axisXmonth)

      console.log("eixo y tasks")
      console.log(this.yMonthTask)

    }

    // console.log("eixo X")
    // console.log(this.months)
    // console.log(this.axisXfull)

    // console.log("eixo Y")
    // console.log(this.eixoY)

    // console.log("eixo Y zone")
    // console.log(this.eixoYtime)

    // console.log("membros")
    // console.log(this.members)

    // console.log("matriz")
    // console.log(this.memberTasks)

    // console.log("area de cobertura")
    // console.log(this.totalAreaLenght)

    // console.log("area de limpa")
    // console.log(this.zoneLenght)
    this.loadChart();
  }

  public loadIntervalData(): void {
    // params date 
    let params = new HttpParams();

    if (this.startDay && this.endDay) {
      params = params.append('startDate', this.startDay.getTime())
      params = params.append('endDate', this.endDay.getTime())
      this.serviceMachineExecution.list(params).subscribe(history => {
        // this.dataExecutionRange.data = history;
        // this.dataExecution.data = history;
        this.dataExecution = new MatTableDataSource<MachineExecution>(history.sort(ByDate));
        this.dataExecutionSlice.data = this.dataExecution.data.slice(0, 100)
        // this.dataExecution.data.sort((a, b) => a.data.createdAt - b.data.createdAt);

        console.log('testando os dados2: ')
        console.log(this.dataExecution.data)
        this.axisXgenerate();
      });
    }
  }

  public axisXgenerate(): void {
    this.dataExecution.data.sort((a, b) => a.start - b.start);
    let date = new Date(this.dataExecution.data[0]?.start);
    // this.startDay = new Date(this.dataExecution.data[0].data.createdAt * 1000);
    if (this.startDay == undefined || this.historicView == 'all') {
      this.startDay = new Date(this.dataExecution.data[0].start);
      this.endDay = this.today;
    }

    this.axisXdays.splice(0, this.axisXdays.length);
    this.axisXmonth.splice(0, this.axisXmonth.length);
    this.axisXhour.splice(0, this.axisXmonth.length);

    this.yMonthZone.splice(0, this.yMonthZone.length);
    this.yMonthArea.splice(0, this.yMonthArea.length);
    this.yMonthTask.splice(0, this.yMonthTask.length);
    this.yMonthMap.splice(0, this.yMonthMap.length);
    this.yMonthTime.splice(0, this.yMonthTime.length);

    this.yDayZone.splice(0, this.yDayZone.length)
    this.yDayArea.splice(0, this.yDayArea.length)
    this.yDayTask.splice(0, this.yDayTask.length)
    this.yDayMap.splice(0, this.yDayMap.length)
    this.yDayTime.splice(0, this.yDayTime.length)

    this.memberZone.splice(0, this.members.length);
    this.memberTasks.splice(0, this.members.length);
    this.memberMaps.splice(0, this.members.length);
    this.memberTime.splice(0, this.members.length);
    this.members.splice(0, this.members.length);

    // this.range.get('start')?.value
    // this.range.get('end')?.value
    // (this.startDay)
    if (this.startDay) {
      this.axisXdays[0] = this.startDay.toLocaleString('default', { day: 'numeric' }) + '/' + this.startDay.toLocaleString('default', { month: 'long' }) + '/' + this.startDay.toLocaleString('default', { year: 'numeric' });
      this.axisXmonth[0] = this.startDay.toLocaleString('default', { month: 'long' }) + '/' + this.startDay.toLocaleString('default', { year: 'numeric' });
      // this.axisXhour[0] = this.startDay.toLocaleString('default', { hour: 'numeric' }) + ' horas - ' + this.startDay.toLocaleString('default', { day: 'numeric' }) + '/' + this.startDay.toLocaleString('default', { month: 'long' }) + '/' + this.startDay.toLocaleString('default', { year: 'numeric' });
      // this.axisXhour[0] = date.toLocaleString('default', { hour: 'numeric' }) + ' horas - ' + date.toLocaleString('default', { day: 'numeric' }) + '/' + date.toLocaleString('default', { month: 'long' }) + '/' + date.toLocaleString('default', { year: 'numeric' });

      this.yDayZone[0] = 0;
      this.yDayArea[0] = 0;
      this.yDayTask[0] = 0;
      this.yDayMap[0] = 0;
      this.yDayTime[0] = 0;

      this.yMonthZone[0] = 0;
      this.yMonthArea[0] = 0;
      this.yMonthTask[0] = 0;
      this.yMonthMap[0] = 0;
      this.yMonthTime[0] = 0;

      this.zoneLenght = 0;
      this.totalAreaLenght = 0;
      this.tasksLenght = 0;
      this.mapLenght = 0;
      this.timeLenght = 0;
    }

    let somaLimpa: any[] = [];
    let somaCobertura: any[] = [];
    let somatarefas: any[] = [];
    let somaMapa: any[] = [];
    let somaHora: any[] = [];

    // this.dataExecutionRange.data.map(e => {
    this.dataExecution.data.map(e => {
      let date = new Date(e.start);
      // console.log("---------------------------")
      // console.log(e.data.coverageArea)
      somaLimpa.push(e.data.coverageArea)
      somaCobertura.push(e.data.totalArea)
      somatarefas.push(e.data.tasks?.length)
      somaHora.push(e.duration)
      // console.log("---------------------------")

      // ************* members ******************
      if (this.members.indexOf(e.data.operator) == -1 && e.data.operator != undefined) {
        this.members.push(e.data.operator);

        this.memberTasks[this.memberTasks.length] = 0;
        this.memberMaps[this.memberMaps.length] = 0;
        this.memberTime[this.memberTime.length] = 0;
        this.memberZone[this.memberZone.length] = 0;
      }
      // console.log("************************ antes do erro ***********************")
      // console.log(this.axisXhour)

      if (this.historicView == 'today') {
        if (this.axisXhour.length == 0 || date.toLocaleString('default', { hour: 'numeric' }) != this.axisXhour[this.axisXhour.length - 1].split(' horas')[0]) {
          this.axisXhour.push(date.toLocaleString('default', { hour: 'numeric' }) + ' horas');
          console.log("************************ adicionar eixo X hora ***********************")
          console.log(this.axisXhour)
          console.log('axisXdays')
          console.log(this.axisXdays)
          this.yDayZone[this.axisXhour.length - 1] = 0;
          this.yDayArea[this.axisXhour.length - 1] = 0;
          this.yDayTask[this.axisXhour.length - 1] = 0;
          this.yDayMap[this.axisXhour.length - 1] = 0;
          this.yDayTime[this.axisXhour.length - 1] = 0;
        }

        // area limpa
        this.yDayZone[this.axisXhour.length - 1] += e.data.coverageArea;
        this.zoneLenght += e.data.coverageArea;
        this.memberZone[this.members.indexOf(e.data.operator)] += e.data.coverageArea;


        // area de cobertura
        this.yDayArea[this.axisXhour.length - 1] += e.data.totalArea;
        this.totalAreaLenght += e.data.totalArea;

        // tarefas
        this.yDayTask[this.axisXhour.length - 1] += e.data.tasks?.length;
        this.tasksLenght += e.data.tasks?.length;
        this.memberTasks[this.members.indexOf(e.data.operator)] += e.data.tasks?.length;

        // mapas
        this.yDayMap[this.axisXhour.length - 1]++;
        this.mapLenght++;
        this.memberMaps[this.members.indexOf(e.data.operator)]++;

        // tempo
        this.yDayTime[this.axisXhour.length - 1] += e.duration;
        this.timeLenght += e.duration / 60;
        this.memberTime[this.members.indexOf(e.data.operator)] += e.duration / 60;




      } else {
        if (date.toLocaleString('default', { day: 'numeric' }) != this.axisXdays[this.axisXdays.length - 1].split('/')[0]) {
          if (date.toLocaleString('default', { day: 'numeric' }) != 'Invalid Date') {
            this.axisXdays[this.axisXdays.length] = date.toLocaleString('default', { day: 'numeric' }) + '/' + date.toLocaleString('default', { month: 'long' }) + '/' + date.toLocaleString('default', { year: 'numeric' });


            this.yDayZone[this.axisXdays.length - 1] = 0;
            this.yDayArea[this.axisXdays.length - 1] = 0;
            this.yDayTask[this.axisXdays.length - 1] = 0;
            this.yDayMap[this.axisXdays.length - 1] = 0;
            this.yDayTime[this.axisXdays.length - 1] = 0;

            if (date.toLocaleString('default', { month: 'long' }) != this.axisXmonth[this.axisXmonth.length - 1].split('/')[0] && date.toLocaleString('default', { month: 'long' }) != 'Invalid Date') {
              this.axisXmonth[this.axisXmonth.length] = date.toLocaleString('default', { month: 'long' }) + '/' + date.toLocaleString('default', { year: 'numeric' });

              this.yMonthZone[this.axisXmonth.length - 1] = 0;
              this.yMonthArea[this.axisXmonth.length - 1] = 0;
              this.yMonthTask[this.axisXmonth.length - 1] = 0;
              this.yMonthMap[this.axisXmonth.length - 1] = 0;
              this.yMonthTime[this.axisXmonth.length - 1] = 0;
            }
          }
        }

        if (e.data.coverageArea) {
          this.yDayZone[this.axisXdays.length - 1] += e.data.coverageArea;
          this.yDayZone[this.axisXdays.length - 1] = parseFloat(this.yDayZone[this.axisXdays.length - 1].toFixed(2));
          this.yMonthZone[this.axisXmonth.length - 1] += e.data.coverageArea;
          // this.yMonthZone[this.axisXmonth.length - 1] = parseFloat(this.yMonthZone[this.axisXmonth.length - 1].toFixed(2));
          this.zoneLenght += e.data.coverageArea;
          this.memberZone[this.members.indexOf(e.data.operator)] += e.data.coverageArea;
        }

        if (e.data.totalArea) {
          this.yDayArea[this.axisXdays.length - 1] += parseFloat(e.data.totalArea.toFixed(2));
          this.yDayArea[this.axisXdays.length - 1] = parseFloat(this.yDayArea[this.axisXdays.length - 1].toFixed(2));
          this.yMonthArea[this.axisXmonth.length - 1] += parseFloat(e.data.totalArea.toFixed(2));
          this.totalAreaLenght += e.data.totalArea;
        }

        if (e.data.tasks?.length) {
          this.yDayTask[this.axisXdays.length - 1] += e.data.tasks?.length;
          this.yMonthTask[this.axisXmonth.length - 1] += e.data.tasks?.length;
          this.tasksLenght += e.data.tasks.length;
          this.memberTasks[this.members.indexOf(e.data.operator)] += e.data.tasks.length;
        }

        this.yDayMap[this.axisXdays.length - 1]++;
        this.yMonthMap[this.axisXmonth.length - 1]++;
        this.mapLenght++;
        this.memberMaps[this.members.indexOf(e.data.operator)]++;

        this.yDayTime[this.axisXdays.length - 1] += e.duration;
        this.yDayTime[this.axisXdays.length - 1] = parseFloat(this.yDayTime[this.axisXdays.length - 1].toFixed(2));
        this.yMonthTime[this.axisXmonth.length - 1] += parseFloat(e.duration.toFixed(2));
        this.timeLenght += e.duration / 60;
        this.memberTime[this.members.indexOf(e.data.operator)] += e.duration / 60;
        this.memberTime[this.members.indexOf(e.data.operator)] = parseFloat(this.memberTime[this.members.indexOf(e.data.operator)].toFixed(2));
      }
    });

    console.log('eixo dias')
    console.log(this.axisXdays)
    console.log('eixo mes')
    console.log(this.axisXmonth)

    console.log('area limpa')
    console.log(this.yDayZone)
    console.log(this.yMonthZone)
    console.log('area total')
    console.log(this.yDayArea)
    console.log('tarefas')
    console.log(this.yDayTask)
    console.log('mapa')
    console.log(this.yDayMap)
    console.log('duracao')
    console.log(this.yDayTime)
    console.log('eixo x membros')
    console.log(this.members)
    console.log('eixo y membros')
    console.log(this.memberTasks)

    console.log('eixo x das horas')
    console.log(this.axisXhour)

    console.log('soma area limpa')
    console.log(somaLimpa)

    console.log('somaLimpa')
    console.log(somaLimpa)
    console.log('somaCobertura')
    console.log(somaCobertura)
    console.log('somatarefas')
    console.log(somatarefas)
    console.log('somaHora')
    console.log(somaHora)



    this.dataView();
  }

  public dataView(): void {
    if (this.gap == 'mensal') {
      // axis X
      this.barChartLabels = this.axisXmonth;
      this.barChartLabelsMember = this.members;


      // axis Y
      this.yAxisZone = this.yMonthZone;
      this.yAxisArea = this.yMonthArea;
      this.yAxisTask = this.yMonthTask;
      this.yAxisMap = this.yMonthMap;
      this.yAxisTime = this.yMonthTime;

    } else if (this.gap == 'diario') {

      // axis X
      this.barChartLabels = this.axisXdays;
      this.barChartLabelsMember = this.members;

      // axis Y
      this.yAxisZone = this.yDayZone;
      this.yAxisArea = this.yDayArea;
      this.yAxisTask = this.yDayMap;
      this.yAxisMap = this.yDayMap;
      this.yAxisTime = this.yDayTime;

    } else if (this.gap == 'hoje') {
      // axis X
      this.barChartLabels = this.axisXhour;
      this.barChartLabelsMember = this.members;

      // axis Y
      this.yAxisZone = this.yDayZone;
      this.yAxisArea = this.yDayArea;
      this.yAxisTask = this.yDayMap;
      this.yAxisMap = this.yDayMap;
      this.yAxisTime = this.yDayTime;
    }
    this.loadChart();
  }

  public loadChart(): void {
    this.barChartDataZone = [
      {
        data: this.yAxisZone, label: 'Área limpa (metros)',
        fill: true,
        backgroundColor: 'rgba(252, 78, 18, .2)',
        borderColor: '#fc4e12',
        pointBackgroundColor: '#fc4e12',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      },
      {
        data: this.yAxisArea, label: 'Área de combertura',
        fill: true,
        backgroundColor: 'rgba(94, 186, 217, .3)',
        borderColor: '#5ebad9',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)'
      }
    ];

    this.barChartDataTask = [
      {
        data: this.yAxisTask, label: 'Tarefas conclídas',
        fill: true,
        backgroundColor: 'rgba(128, 226, 32, .4)',
        borderColor: '#80e220',
        pointBackgroundColor: '#77ea7e',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      }
    ];


    this.barChartDataMap = [
      {
        data: this.yAxisMap,
        label: 'Mapas',
        backgroundColor: [
          'rgba(154, 117, 252, .5)',
        ],
        borderColor: [
          'rgb(142, 36, 170)',
        ],
        pointBackgroundColor: 'rgba(154, 117, 252, 1)',
        pointBorderColor: '#FFF',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(142, 36, 170)',
        borderWidth: 3
      },
    ];

    this.barChartDataTime = [
      {
        data: this.yAxisTime,
        label: 'Minutos',
        backgroundColor: [
          'rgba(30, 165, 252, .3',
        ],
        borderColor: [
          '#1EA5FC',
        ],
        pointBackgroundColor: '#1EA5FC',
        pointBorderColor: '#FFF',
        borderWidth: 3
      },
    ];


    this.barChartDataMemberTask = [
      {
        indexAxis: 'y',
        data: this.memberTasks, label: 'Tarefas concluídas',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ]
      },
    ];

    this.barChartDataMemberZone = [
      {
        indexAxis: 'y',
        data: this.memberZone, label: 'Áreas limpas (m²)',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ]
      },
    ];

    this.barChartDataMemberTime = [
      {
        indexAxis: 'y',
        data: this.memberTime, label: 'Tempo controlando a máquina',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ]
      },
    ];

    this.barChartDataMemberMaps = [
      {
        indexAxis: 'y',
        data: this.memberMaps, label: 'Mapas concluídos',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(201, 203, 207, 0.2)'
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)'
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ]
      },
    ];
  }

  public changeTab(index: MatTabChangeEvent): void {
    this.selectedTab = index.tab.textLabel;
    // this.loadChart();
  }

  public changeTabIndex(index: number) {
    this.selectedIndex = index;
    // this.loadChart();
  }

  public dataViewChange() {
    // console.log(this.gap);
    this.dataView()
    // this.loadDataChart();
  }

  public interval() {
    // console.log(this.range.get('start')?.value)
    this.historicView = '';
    let start = this.range.get('start')?.value
    let end = this.range.get('end')?.value
    if (end != null && start != null) {
      this.startDay = new Date(start.format())
      this.endDay = new Date(end.format())
      if (start.format() == end.format()) {
        console.log("eeeeeeeeeentreeeeeeeeeeeeeeeeeeeeeiiiiiiiiiiiiii ********")
        this.historicView = 'today';
        this.gap = 'hoje';
      }
      this.startDay.getMonth() != this.endDay.getMonth() ? this.gap = 'mensal' : this.gap = 'diario';
      this.endDay.setUTCHours(23, 59, 59, 999);
      console.log("data inicial")
      console.log(this.startDay)
      console.log("data final")
      console.log(this.endDay)
      console.log("hitorico view")
      console.log(this.historicView)

      this.loadIntervalData();
    }
  }

  public historicViewChange(item: string) {
    if (item == 'all') {
      this.range.reset();
      this.loadData();
    } else {
      this.startDay = this.today;
      this.endDay = this.today;
      this.gap = 'hoje';
      this.endDay.setUTCHours(23, 59, 59, 999);
      this.loadIntervalData();
    }
  }
}
