import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FacebookHook, FacebookHookService } from 'app/alabia/api/facebook-hook.service';
import { Bot, GooglePlayChannel } from 'onevoice';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChannelManagerForm } from 'app/models/channel-manager-form';
import { WhatsappHook } from 'app/alabia/api/whatsapp-hook.service';
import { EmailChannel as EmailChannelName, GooglePlay } from '../../platforms';
import { EmailChannel } from 'projects/onevoice/src/lib/channel/email-channel.service';

export interface FacebookMediaTypes {
  name: string;
  inputName: string;
  isEnabled: boolean;
}


@Component({
  selector: 'alabia-channel-dialog',
  templateUrl: './channel-dialog.component.html',
  styleUrls: ['./channel-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChannelDialogComponent implements OnInit {
  action: string;
  platform: string;
  dialogTitle: string = "";
  bot: Bot;
  facebookHook: FacebookHook = {};
  whatsappHook: WhatsappHook = {};
  googlePlay: Partial<GooglePlayChannel> = {};
  emailChannel: Partial<EmailChannel> = {};
  channelForm?: FormGroup;

  fbMediaTypes?: FacebookMediaTypes[];

  constructor(
    public matDialogRef: MatDialogRef<ChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,

  ) {
    this.action = _data.action;
    this.platform = _data.platform;
    this.bot = _data.bot;
    this.open(_data);
  }

  ngOnInit() { }

  open(_data: any): void {
    this.fbMediaTypes = [
      {
        name: 'Texto',
        inputName: 'hasText',
        isEnabled: Boolean(this.bot.textEnabled),
      },
      {
        name: 'Áudio',
        inputName: 'hasAudio',
        isEnabled: Boolean(this.bot.audioEnabled),
      },
      {
        name: 'Image',
        inputName: 'hasImage',
        isEnabled: Boolean(this.bot.imageEnabled),
      },
      {
        name: 'Vídeo',
        inputName: 'hasVideo',
        isEnabled: Boolean(this.bot.videoEnabled),
      },
    ];

    if (this.action === 'edit') {
      this.dialogTitle = `Editar canal de ${this.platform}`;

      switch (this.platform) {
        case 'Messenger':
          this.facebookHook = _data.channel;
          break;
        case 'Whatsapp':
          this.whatsappHook = _data.channel;
          break;

        case GooglePlay:
          this.googlePlay = _data.channel;
          break;

        case EmailChannelName:
          this.emailChannel = _data.channel;
          break;

      }

      this.channelForm = this.createChannelForm(false);
    } else {
      this.dialogTitle = `Novo canal de ${this.platform}`;

      switch (this.platform) {
        case 'Messenger':
          this.facebookHook = new ChannelManagerForm({});
          break;
        case 'Whatsapp':
          this.whatsappHook = new ChannelManagerForm({});
          break;
        case GooglePlay:
          this.googlePlay = new ChannelManagerForm({});
          break;
        case EmailChannelName:
          this.emailChannel = new ChannelManagerForm({});
      }

      this.channelForm = this.createChannelForm(true);
    }
  }

  createChannelForm(isNewChannel: boolean): FormGroup {
    switch (this.platform) {
      case 'Messenger':
        return this._formBuilder.group({
          id: isNewChannel ? '' : this.facebookHook.id,
          name: isNewChannel ? '' : this.facebookHook.name,
          description: isNewChannel ? '' : this.facebookHook.description,
          accessToken: isNewChannel ? '' : this.facebookHook.accessToken,
          verifyToken: isNewChannel ? '' : this.facebookHook.verifyToken,
          hasAudio: isNewChannel ? '' : this.facebookHook.hasAudio,
          hasImage: isNewChannel ? '' : this.facebookHook.hasImage,
          hasText: isNewChannel ? '' : this.facebookHook.hasText,
          hasVideo: isNewChannel ? '' : this.facebookHook.hasVideo,
        });
      case 'Whatsapp':
        return this._formBuilder.group({
          id: isNewChannel ? '' : this.whatsappHook.id,
          name: isNewChannel ? '' : this.whatsappHook.name,
          description: isNewChannel ? '' : this.whatsappHook.description,
          account: isNewChannel ? '' : this.whatsappHook.account,
          token: isNewChannel ? '' : this.whatsappHook.token,
          number: isNewChannel ? '' : this.whatsappHook.number,
        });
      case GooglePlay:
        return this._formBuilder.group({
          id: isNewChannel ? '' : this.googlePlay.id,
          name: isNewChannel ? '' : this.googlePlay.name,
          apiKey: isNewChannel ? '' : this.googlePlay.apiKey,
          appIdentity: isNewChannel ? '' : this.googlePlay.appIdentity,
          maxEval: isNewChannel ? 3 : this.googlePlay.maxEval,
        });

      case EmailChannelName:
        return this._formBuilder.group({
          id: isNewChannel ? '' : this.emailChannel.id,
          name: isNewChannel ? '' : this.emailChannel.name,
          status: isNewChannel ? '' : this.emailChannel.status,
        })
    }
    return this._formBuilder.group({});
  }

  saveData(isNewChannel?: boolean) {
    isNewChannel && this.channelForm
      ? this.matDialogRef.close(this.channelForm.value)
      : this.matDialogRef.close(['save', this.channelForm]);
  }
}
