import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { AgentDataModule } from './agent-data/agent-data.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { BiometricsModule } from './biometrics/biometrics.module';
import { BotModule } from './bot/bot.module';
import { ChatModule } from './chat/chat.module';
import { CleaningManagementModule } from './cleaning-management/cleaning-management.module';
import { CustomerModule } from './customer/customer.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PrivacyComponent } from './flatpages/privacy/privacy.component';
import { HomepageModule } from './homepage/homepage.module';
import { HwModule } from './hw/hw.module';
import { OrganizationComponent } from './organization/organization.component';
import { QrcodeModule } from './qrcode/qrcode.module';
import { SchedulerModule } from './scheduler/scheduler.module';
import { SignupModule } from './signup/signup.module';
import { SkillManagerModule } from './skill-manager/skill-manager.module';
import { SpacesModule } from './spaces/spaces.module';
import { STTModule } from './stt/stt.module';
import { SubscriptionModule } from './subscription/subscription.module';
import { TokensModule } from './tokens/tokens.module';
import { TTSOldModule } from './tts-old/tts.module';
import { TTSModule } from './tts/tts.module';
import { UsersModule } from './users/users.module';
import { VoiceContentModule } from './voice-content/voice-content.module';
import { VoiceProjectModule } from './voice-project/voice-project.module';
import { WidgetCreatorModule } from './widget-creator/widget-creator.module';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: "agent",
        loadChildren: () => AgentDataModule,
    },
    {
        path: 'login',
        loadChildren: () => AuthenticationModule,
    },
    {
        path: "organization",
        component: OrganizationComponent,
    },
    {
        path: 'signup',
        loadChildren: () => SignupModule
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => HomepageModule
    },
    {
        path: 'chat',
        canActivate: [AuthGuard],
        loadChildren: () => ChatModule
    },
    {
        path: 'manage-users',
        canActivate: [AuthGuard],
        loadChildren: () => UsersModule
    },
    {
        path: 'bots',
        canActivate: [AuthGuard],
        loadChildren: () => BotModule
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        // component: DashboardComponent,
        loadChildren: () => DashboardModule,
    },
    {
        path: 'widget-creator',
        canActivate: [AuthGuard],
        loadChildren: () => WidgetCreatorModule
    },
    {
        path: 'skill-manager',
        canActivate: [AuthGuard],
        loadChildren: () => SkillManagerModule
    },
    {
        path: 'customer',
        canActivate: [AuthGuard],
        loadChildren: () => CustomerModule
    },
    {
        path: 'subscription',
        canActivate: [AuthGuard],
        loadChildren: () => SubscriptionModule
    },
    {
        path: "token",
        canActivate: [AuthGuard],
        loadChildren: () => TokensModule
    },
    {
        path: "machines",
        canActivate: [AuthGuard],
        loadChildren: () => HwModule
    },

    {
        path: "stt",
        canActivate: [AuthGuard],
        loadChildren: () => STTModule
    },
    {
        path: "tts",
        canActivate: [AuthGuard],
        loadChildren: () => TTSModule
    },
    {
        path: "tts-voice",
        canActivate: [AuthGuard],
        loadChildren: () => TTSOldModule
    },

    {
        path: "voices",
        canActivate: [AuthGuard],
        loadChildren: () => VoiceContentModule
    },
    {
        path: "biometrics",
        canActivate: [AuthGuard],
        loadChildren: () => BiometricsModule
    },
    {
        path: "voice-projects",
        canActivate: [AuthGuard],
        loadChildren: () => VoiceProjectModule
    },
    {
        path: "qrcode",
        loadChildren: () => QrcodeModule
    },
    {
        path: "cleaning",
        canActivate: [AuthGuard],
        loadChildren: () => CleaningManagementModule
    },
    {
        path: "spaces",
        canActivate: [AuthGuard],
        loadChildren: () => SpacesModule,
    },
    {
        path: "scheduler",
        canActivate: [AuthGuard],
        loadChildren: () => SchedulerModule,
    }
];

@NgModule({
    declarations: [
        OrganizationComponent,
        PrivacyComponent,
    ],
    exports: [
        OrganizationComponent,
        PrivacyComponent,
    ],
    imports: [
        RouterModule.forChild(routes),
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
        FormsModule,
        DashboardModule,
        CommonModule,
    ],
})
export class AlabiaModule { }
