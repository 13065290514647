<h1 mat-dialog-title>Criar um Token</h1>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input matInput type="text" [(ngModel)]="data.name">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Ativo</mat-label>
    <mat-select [(ngModel)]="data.active">
      <mat-option [value]="true">Ativo</mat-option>
      <mat-option [value]="false">Desativado</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data">Criar</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>
