import { Ownable } from '../auth/ownable.service';

export enum QueueStatus {
    ACTIVE, DISABLED
}

export interface Queue extends Ownable {
    name: string;
    status: QueueStatus;
}
