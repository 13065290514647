<form [formGroup]="form" (ngSubmit)="ngSubmit()">
  <h3 mat-dialog-title>
    <!-- {{ title }} -->
  </h3>
  <div>

  </div>
  <mat-dialog-content class="mat-typography">
    <div class="userInfo">
      <div>
        Nome: {{data.details?.first_name + " " + data.details?.last_name}}
      </div>
      <div>
        Localização: {{data.details?.location?.name}}
      </div>
      <div>
        Perfil: {{data.authorities?.toString()}}
      </div>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Início : </mat-label>
        <input matInput type="time" formControlName="start" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Intervalo : </mat-label>
        <input matInput type="time" formControlName="startPause" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Fim do intervalo : </mat-label>
        <input matInput type="time" formControlName="endPauseTime" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Fim : </mat-label>
        <input matInput type="time" formControlName="end" />
      </mat-form-field>

      <mat-button-toggle-group multiple>
        <mat-button-toggle value="sunday" (click)="insertDay(0)">
          Dom.
        </mat-button-toggle>
        <mat-button-toggle value="monday" (click)="insertDay(1)">
          Seg.
        </mat-button-toggle>
        <mat-button-toggle value="tuesday" (click)="insertDay(2)">
          Ter.
        </mat-button-toggle>
        <mat-button-toggle value="wednesday" (click)="insertDay(3)">
          Qua.
        </mat-button-toggle>
        <mat-button-toggle value="thursday" (click)="insertDay(4)">
          Qui.
        </mat-button-toggle>
        <mat-button-toggle value="friday" (click)="insertDay(5)">
          Sex.
        </mat-button-toggle>
        <mat-button-toggle value="saturday" (click)="insertDay(6)">
          Sáb.
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- <mat-form-field appearance="outline">
        <mat-label>Descrição</mat-label>
        <textarea matInput formControlName="description" rows="8"></textarea>
      </mat-form-field>
 -->



    </div>

    <ng-template #spinner>
      <mat-spinner class="alabia-view-spinner"></mat-spinner>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button type="submit">Salvar</button>
  </mat-dialog-actions>
</form>
