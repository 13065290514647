import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { ChannelType } from 'onevoice';
import { BehaviorSubject, Subject } from 'rxjs';
import { Format, FormatControl, FormatField, FormatListField } from '../../response-format/format.model';

export interface Button {
  type: string;
  payload?: string;
  title: string;
  url?: string;
}

export interface Element {
  image_url: string;
  subtitle: string;
  title: string;

  buttons: Button[];
}

export interface Payload {
  template_type: string;
  text: string;
  buttons: Button[];
  elements: Element[];
}

export interface Attachment {
  type: string;
  payload: Payload;
}

export interface FacebookCarouselData {
  attachment: Attachment;
}

@Component({
  selector: 'oneui-facebook-carousel',
  templateUrl: './facebook-carousel.component.html',
  styleUrls: ['./facebook-carousel.component.scss']
})
export class FacebookCarouselComponent {
  @Output()
  public select: EventEmitter<string> = new EventEmitter<string>();

  public updater: BehaviorSubject<FacebookCarouselData | null> = new BehaviorSubject<FacebookCarouselData | null>(null);

  constructor() {
    this.updater.subscribe(data => {
      console.debug("Updating data");
    });
  }

  public getImage(url: string): string {
    return url;
  }
}

export const FacebookCarousel = new Format(
  "Facebook carousel",
  ChannelType.FACEBOOK,
  [
    new FormatField<Attachment>("attachment", [
      new FormatField<string>("type", new FormatControl("template", [], { "template": "template" })),
      new FormatField<Payload>("payload", [
        new FormatField<string>("template_type", new FormatControl("button", [Validators.required], { "button": "button" })),
        new FormatField<string>("text", new FormatControl("", [])),


        new FormatField<Element>("elements", new FormatListField<Button>(3, [

          new FormatField<string>("title", new FormatControl("", [Validators.required])),
          new FormatField<string>("subtitle", new FormatControl("", [Validators.required])),
          new FormatField<string>("image_url", new FormatControl("", [Validators.required])),



          new FormatField<Button>("buttons", new FormatListField<Button>(3, [
            new FormatField<string>("type", new FormatControl("", [Validators.required], { "Retorno": "postback", "Link": "web_url" })),
            new FormatField<string>("payload", new FormatControl("", [])),
            new FormatField<string>("title", new FormatControl("", [Validators.required])),
            new FormatField<string>("url", new FormatControl("", [])),
          ])),


        ])),


        new FormatField<Button>("buttons", new FormatListField<Button>(3, [
          new FormatField<string>("type", new FormatControl("", [Validators.required], { "Retorno": "postback", "Link": "web_url" })),
          new FormatField<string>("payload", new FormatControl("", [])),
          new FormatField<string>("title", new FormatControl("", [Validators.required])),
          new FormatField<string>("url", new FormatControl("", [])),
        ])),
      ]),
    ])
  ],
  FacebookCarouselComponent,
);


export const ChatCarousel = new Format(
  "Facebook carousel",
  ChannelType.CHAT,
  [
    new FormatField<Attachment>("attachment", [
      new FormatField<string>("type", new FormatControl("template", [], { "template": "template" })),
      new FormatField<Payload>("payload", [
        new FormatField<string>("template_type", new FormatControl("button", [Validators.required], { "button": "button" })),
        new FormatField<string>("text", new FormatControl("", [])),

        new FormatField<Element>("elements", new FormatListField<Button>(3, [

          new FormatField<string>("title", new FormatControl("", [Validators.required])),
          new FormatField<string>("subtitle", new FormatControl("", [Validators.required])),
          new FormatField<string>("image_url", new FormatControl("", [Validators.required])),



          new FormatField<Button>("buttons", new FormatListField<Button>(3, [
            new FormatField<string>("type", new FormatControl("", [Validators.required], { "Retorno": "postback", "Link": "web_url" })),
            new FormatField<string>("payload", new FormatControl("", [])),
            new FormatField<string>("title", new FormatControl("", [Validators.required])),
            new FormatField<string>("url", new FormatControl("", [])),
          ])),


        ])),



        new FormatField<Button>("buttons", new FormatListField<Button>(3, [
          new FormatField<string>("type", new FormatControl("", [Validators.required], { "Retorno": "postback", "Link": "web_url" })),
          new FormatField<string>("payload", new FormatControl("", [])),
          new FormatField<string>("title", new FormatControl("", [Validators.required])),
          new FormatField<string>("url", new FormatControl("", [])),
        ])),
      ]),
    ])
  ],
  FacebookCarouselComponent,
);
