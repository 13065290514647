<div class="dialog-content-wrapper">
  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Criar uma nova conta</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0 mat-dialog-content--single-column" fusePerfectScrollbar>

    <form name="accountForm" [formGroup]="accountForm" class="compose-form" fxLayout="column" fxFlex>

      <div class="form-group--larger-left">
        <mat-form-field appearance="outline">
          <mat-label>Nome da Organização</mat-label>
          <input matInput name="organization" formControlName="organization" type="text" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput name="username" normalizeText formControlName="username" type="text" required>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput name="firstName" formControlName="firstName" type="text" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Sobrenome</mat-label>
          <input matInput name="lastName" formControlName="lastName" type="text" required>
        </mat-form-field>
      </div>

      <div class="form-group--larger-left">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput name="email" formControlName="email" type="email" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input matInput name="phone" formControlName="phone" type="text" phoneMask inputmode="numeric">
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input matInput name="password" formControlName="password" type="password" required #pass
          (input)="onInputPass()">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Confirmação de senha</mat-label>
        <input matInput name="password" formControlName="confirmPassword" type="password" required #confirmPass
          (input)="onInputPass()">
        <mat-error>As senhas não correspondem</mat-error>
      </mat-form-field>

    </form>

    <div mat-dialog-actions class="m-0" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-raised-button color="accent" (click)="matDialogRef.close(accountForm)" class="save-button"
        [disabled]="accountForm.invalid" aria-label="SAVE">
        Criar conta
      </button>

    </div>
  </div>

</div>
