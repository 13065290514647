import { AgentResponseListComponent } from '@alabia/bot/dialogs/agent-response-list/agent-response-list.component';
import { TagListDialogComponent } from '@alabia/bot/dialogs/tag-list-dialog/tag-list-dialog.component';
import { TriggerDialogComponent } from '@alabia/bot/dialogs/trigger-dialog/trigger-dialog.component';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Bot, BotService, BotStatus } from 'onevoice';
import { Subject } from 'rxjs';
import { BotCreateComponent } from '../../dialogs/bot-create/bot-create.component';

@Component({
    selector: 'alabia-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
    public BotStatus = BotStatus;

    @Input()
    public bot?: Bot;

    @Input()
    public clearIntent?: Subject<boolean>;

    @Input()
    public amountIntents?: number;

    constructor(
        private _botService: BotService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public router: Router
    ) {
    }

    public set botActive(value: boolean) {
        if (this.bot) {
            this._botService.changeStatus(this.bot, value).subscribe(bot => this.bot = bot);
        }

    }
    public get botActive(): boolean {
        return this.bot !== undefined && this.bot.status == BotStatus.ENABLED;
    }

    public handleNavigation(route: any[], clearSelected?: boolean): void {
        this.router.navigate(route);
        if (clearSelected) {
            this.clearSelected();
        }
    }

    clearSelected(): void {
        if (!this.clearSelected && this.clearIntent) {
            this.clearIntent.next(true);
        }
    }

    message(content: string): void {
        this.snackBar.open(content, 'entendi', {
            duration: 10000
        });
    }

    editBot(): void {
        const reference = this.dialog.open(BotCreateComponent, {
            width: '500px',
            maxWidth: '95%',
            data: this.bot
        });
        reference.afterClosed().subscribe((bot?: Bot) => this.bot = bot || this.bot);
    }

    deleteBot(): void {
        if (!environment.production) {
            this.snackBar
                .open('Deseja realmente deletar este robô?', 'Sim', {
                    duration: 5000
                })
                .onAction()
                .subscribe((_: any) => {
                    if (this.bot) {
                        this._botService.delete(this.bot.id).subscribe(_ => {
                            this.message('O robô foi removido!');
                        });
                    }
                });
        }
    }



    openTriggerDialog() {
        if (this.bot !== undefined) {
            this.dialog.open<TriggerDialogComponent, Bot, void>(TriggerDialogComponent, {
                maxWidth: "80%",
                width: "500px",
                data: this.bot,
            });
        }
    }

    openTagList() {
        this.dialog.open<TagListDialogComponent, void, void>(TagListDialogComponent, {
            maxWidth: "80%",
            width: "80%",
        });
    }

    openAgentResponseList() {
        this.dialog.open<AgentResponseListComponent, Bot, void>(AgentResponseListComponent, {
            maxWidth: "80%",
            width: "80%",
            data: this.bot,
        })
    }
}
