import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MachineScheduler, MachineSchedulerService, MachineTask, MachineTaskService, SchedulerInfoService, TimedTask, UserService } from 'onevoice';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'main-my-manual-tasks',
  templateUrl: './my-manual-tasks.component.html',
  styleUrls: ['./my-manual-tasks.component.scss']
})
export class MyManualTasksComponent implements OnInit {

  public currenteDate: Date = new Date();
  public task? : MachineTask;
  public taskList: MatTableDataSource<MachineTask> = new MatTableDataSource<MachineTask>();
  public scheduler: MachineScheduler[] = [];
  public tasksToday: MatTableDataSource<TimedTask> = new MatTableDataSource<TimedTask>();
  public dataLoaded: boolean = false;
  public path : String  = ''
  public fields: string[] = [
    "name",
    "type",
    "start",
    "map",
    "tarefa",
    "actions",
  ];

  constructor(

    public tasks: MachineTaskService,
    public schedulerService: MachineTaskService,
    public machineScheduler: MachineSchedulerService,
    public dialog: MatDialog,
    public timedTaskInfo : SchedulerInfoService,
    public users : UserService,
    private _route: ActivatedRoute,


  ) { }

  userProfile(){
    console.log('log')
      this.users.info().subscribe( data =>{

        const isAdmin = data.authorities.some(authority => authority.authorization === "ADMIN");
        if(isAdmin){
          this.path = "cleaning/tasks/bymanger"
        }
        console.log(this.path);
      })
  }
  setScheduleInfo(timedTask : TimedTask){

    this.timedTaskInfo.atualizarObjeto(timedTask);
  }

  public ngOnInit(): void {
    this.userProfile()

    this.machineScheduler.listByUser().subscribe(scheduler => {
      console.log(scheduler);
      this.todayTask(scheduler);
      console.log(this.tasksToday.data)
      this.dataLoaded = true;
    })


    this._route.params.subscribe((param: Params) => {
      let pk = param["id"] as number;

      if (pk !== undefined) {
        this.tasks.read(pk).subscribe((task) => {
          console.log(task)
          this.task = task;


        });
      }
    });
  }
  getAuth(){
    this.users.info().subscribe(profile =>{
      console.log(profile);
    })
  }
  public todayTask(scheduler: MachineScheduler[]) {
    scheduler.forEach(schedule => {
      console.log(schedule)
      schedule.timedTaskList.forEach(task => {
        task.repeat.forEach(day => {
          if (day == this.currenteDate.getDay()) {
            this.tasksToday.data.push(task);
          }
        })
      })
    })
  }

  @ViewChild(MatPaginator)
  set paginator(pager: MatPaginator) {
    this.tasksToday.paginator = pager;
  }


}
