<ng-container *ngIf="authService.current.value">
    <!-- PROGRESS BAR -->
    <fuse-progress-bar></fuse-progress-bar>
    <!-- / PROGRESS BAR -->

    <!-- VERTICAL LAYOUT 1 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
        <vertical-layout-1></vertical-layout-1>
    </ng-container>
    <!-- / VERTICAL LAYOUT 1 -->

    <!-- VERTICAL LAYOUT 2 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
        <vertical-layout-2></vertical-layout-2>
    </ng-container>
    <!-- / VERTICAL LAYOUT 2 -->

    <!-- VERTICAL LAYOUT 3 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
        <vertical-layout-3></vertical-layout-3>
    </ng-container>
    <!-- / VERTICAL LAYOUT 3 -->

    <!-- HORIZONTAL LAYOUT 1 -->
    <ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
        <horizontal-layout-1></horizontal-layout-1>
    </ng-container>
    <!-- / HORIZONTAL LAYOUT 1 -->

</ng-container>

<ng-container *ngIf="!authService.current.value">
    <alabia-login *ngIf="this.router.url.split('?')[0]  === '/login'"></alabia-login>
    <alabia-signup *ngIf="this.router.url.split('?')[0]  === '/signup'"></alabia-signup>
    <main-privacy *ngIf="this.router.url.split('?')[0]  === '/privacidade'"></main-privacy>
</ng-container>