import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacebookPageChannelService } from 'onevoice';

@Component({
  selector: 'main-facebook-channel',
  templateUrl: './facebook-channel.component.html',
  styleUrls: ['./facebook-channel.component.scss']
})
export class FacebookChannelComponent implements OnInit {
  public message: string = "Iniciando componente de criação de canal";

  constructor(private router: Router, private route: ActivatedRoute, private service: FacebookPageChannelService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let botId = Number(sessionStorage.getItem("botId"));
      this.message = `Criando canal para o robo ${botId}`;

      this.service.wizard("/bots/facebook/channel", params['code'], botId).subscribe(channels => {
        this.message = "Canal criado com sucesso. Redirecionando";
        console.log(channels);
        this.router.navigateByUrl(`/bots/${botId}/channels`);
      });
    })
    console.log(this.route.snapshot.queryParams);
  }
}
