import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mainBackgroundToday]'
})
export class BackgroundTodayDirective {


 
  constructor(el:ElementRef) {
    el.nativeElement.style.backgroundColor = 'purple'

  }
}
