import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mainTodayColor]'
})
export class TodayColorDirective {

  constructor(el: ElementRef) {

    this.alterColor(el);

  }

  alterColor(el: ElementRef) {


    console.log(el.nativeElement.innerHTML)

    el.nativeElement.style.backgroundColor = 'purple';
    el.nativeElement.style.color = '#FFFFFF';
  }

 
}

