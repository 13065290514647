<form (submit)="generateAudio()">
    <div class="card-contain">
        <!-- <mat-card id="alabia-tts-card-advanced" class="m-24 mt-0" *ngIf="ttsView"> -->
        <mat-card id="alabia-tts-card-advanced" class="m-24 mt-0">
            <mat-form-field id="field-model" color="accent" appearance="outline">
                <mat-label>Vozes</mat-label>
                <mat-select name="model" [(ngModel)]="voiceModel" (ngModelChange)="changeVoice()"
                    [compareWith]="compareWith">
                    <mat-option *ngFor="let ticket of tickets" [value]="ticket">
                        {{ getVoice(ticket).title }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field color="accent" appearance="outline">
                <mat-label>Texto para áudio</mat-label>
                <textarea matInput name="content" type="text" [(ngModel)]="generate" (ngModelChange)="textCount()"
                    rows="4" [maxlength]=" voiceModel ? voiceModel.tickets : 0"></textarea>
                <button mat-icon-button matSuffix type="button" id='{{refreshIcon}}' (click)="refreshText()"
                    [disabled]="generate.length == 0">
                    <mat-icon matTooltip="Apagar texto"
                        aria-label="Button that displays a tooltip when focused or hovered over">refresh</mat-icon>
                </button>
                <mat-hint *ngIf="voiceModel">
                    Limite restante: {{ charCount.length }}/{{ voiceModel.tickets }}
                </mat-hint>
            </mat-form-field>
            <button mat-raised-button id="submit-button" type="submit" [disabled]="generate.length == 0">
                <mat-icon *ngIf="!generating" matTooltip="Gerar áudio"
                    aria-label="Button that displays a tooltip when focused or hovered over">send</mat-icon>
                <mat-icon class="rotating" *ngIf="generating">loop</mat-icon>
                Gerar
            </button>
        </mat-card>

        <mat-card id="alabia-tts-inf-card" class="m-24 mt-0" *ngIf="voiceModel">
            <h2>Plano: {{ getVoice(voiceModel).title }}</h2>
            <mat-card-subtitle style="padding-top: 30px;">Tickets: {{voiceModel?.tickets}} </mat-card-subtitle>
        </mat-card>
    </div>
</form>