import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Client, IFrame, IMessage } from '@stomp/stompjs';
import { BehaviorSubject, Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { AuthenticationService } from '../auth/authentication.service';
import { TokenService } from '../auth/token.service';
import { MediaType, ResponseType } from '../dialog/message.model';
import { QueueChange } from '../queue/queue-assignment.model';
import { StompMessage } from './message.model';


@Injectable({
  providedIn: "root",
})
export class AgentStompService implements OnDestroy {
  private client?: Client;

  public static QUEUE_CHANGES: string = "/user/queue/changes";

  public OnMessage: Subject<StompMessage> = new Subject<StompMessage>();
  public OnChange: Subject<QueueChange> = new Subject<QueueChange>();
  public IsConnected: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

  private QUEUE_MESSAGES(org: number): string {
    return `/queue/a-${org}`;
  }

  constructor(
    @Inject(APP_CONFIG) public config: ApiConfiguration,
    private storage: TokenService,
    private auth: AuthenticationService,
  ) {
    this.init();
  }

  public ngOnDestroy(): void {
    console.log("Service destroyed");
    this.destroy();
  }

  public init(): void {
    if (this.storage.token == null || this.storage.token.length <= 0) {
      throw "No token";
    }
    if (this.auth.current.value == null || this.auth.current.value.organization.id == undefined) {
      throw "No user"
    }
    let org = this.auth.current.value.organization.id;
    let client = new Client({
      webSocketFactory: () => new SockJS(`${this.config.endpoint}/alabia-conversation-service?access_token=${this.storage.token}`),
      onConnect: (receipt: IFrame) => {
        this.IsConnected.next(true);
        console.log("Connected to agent stomp");
        client.subscribe(this.QUEUE_MESSAGES(org), (message: IMessage) => {
          console.debug(message);
          try {
            this.OnMessage.next(JSON.parse(message.body));
          } catch (error) {
            console.error(error);
          }
        });
        client.subscribe(AgentStompService.QUEUE_CHANGES, (change: IMessage) => {
          console.debug(change);
          try {
            this.OnChange.next(JSON.parse(change.body));
          } catch (error) {
            console.error(error);
          }
        });
      },
      onDisconnect: (receipt: IFrame) => {
        this.IsConnected.next(false);
        console.log("Disconnecting from agent stomp");
      },
    });
    this.client = client;
    this.client.activate();
  }

  public destroy(): void {
    console.log("Destroy stomp agent");
    if (this.client) {
      this.client.deactivate();
      this.client = undefined;
    }
  }

  public sendChange(change: QueueChange) {
    console.debug("Sending change: ", change);

    if (!this.client) {
      console.error("Attempting to call socket without a client");
      return;
    }
    this.client.publish({
      destination: "/app/queue/changes",
      body: JSON.stringify(change),
    })
  }

  public sendMessage(conversation: number, text: string) {
    console.debug("Sending message: ", text);
    let message: StompMessage = {
      idConversation: conversation,
      query: text,
      responses: [{
        content: text,
        contentType: ResponseType.TEXT,
        mediaType: MediaType.UNKNOWN,
      }]
    };

    if (!this.client) {
      console.error("Attempting to call socket without a client");
      return;
    }
    this.client.publish({
      destination: "/app/customer",
      skipContentLengthHeader: true,
      body: JSON.stringify(message)
    })
  }
}
