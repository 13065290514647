<div class="create-component">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title" style="padding: 24px; width: 90vw">
          {{title}}
        </span>
        <button
          mat-icon-button
          aria-label="Close dialog"
          [mat-dialog-close]="false"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <form [formGroup]="form" (ngSubmit)="submitUnitMap()">
      <mat-dialog-content>
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-hint>Dê um nome ao seu mapa</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Descrição:</mat-label>
            <input matInput type="text" formControlName="description" />
            <mat-hint>Descrição da unidade</mat-hint>
          </mat-form-field>
        <!-- <mat-form-field appearance="outline">
          <mat-label>UUID</mat-label>
          <input matInput type="text" formControlName="uniqueId" />
          <mat-hint>Seu mapa precisa de um identificador único</mat-hint>
        </mat-form-field> -->
      </mat-dialog-content>

      <mat-dialog-actions>
        <button mat-raised-button type="submit" [disabled]="!form.valid">{{title =="Editar Unidade" ? "Salvar" :"Criar"}}</button>
        <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
      </mat-dialog-actions>
    </form>
  </div>
