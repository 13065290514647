import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { BiometricsComponent } from './biometrics.component';
import { CreateComponent } from './create/create.component';
import { UploadComponent } from './upload/upload.component';
import { VerifyComponent } from './verify/verify.component';


const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: BiometricsComponent,
    children: []
  },
];


@NgModule({
  entryComponents: [CreateComponent, UploadComponent, VerifyComponent],
  declarations: [BiometricsComponent, CreateComponent, UploadComponent, VerifyComponent],
  imports: [
    RouterModule.forChild(routes),

    MatSnackBarModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    MatDialogModule,

    MatInputModule,
    FormsModule,
    CommonModule
  ]
})
export class BiometricsModule { }
