<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>Avatar</h3>

    <mat-dialog-content>
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Avatar</mat-label>
            <oneui-file-input matInput formControlName="file"></oneui-file-input>
            <mat-hint>Um arquivo JPEG, de até 2MB.</mat-hint>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="submit" mat-button>Enviar</button>
    </mat-dialog-actions>
</form>