import { Injectable } from '@angular/core';
import { BaseModel, BaseService } from '../auth/base.service';

export enum MapType {
  UNKNOWN = "UNKNOWN",
  GAUSSIAN_ROBOTICS_50 = "GAUSSIAN_ROBOTICS_50",
  GAUSSIAN_ROBOTICS_40 = "GAUSSIAN_ROBOTICS_40",
  GAUSSIAN_ROBOTICS_75 = "GAUSSIAN_ROBOTICS_75",
  GAUSSIAN_ROBOTICS_PHANTAS = "GAUSSIAN_ROBOTICS_PHANTAS",
}

export interface MachineModel extends BaseModel<number> {
  maker: string;
  model: string;
  mapType: MapType;
}


@Injectable({
  providedIn: 'root'
})
export class MachineModelService extends BaseService<MachineModel, number> {
  protected path: string = `${this.config.endpoint}/hw/models`;
}
