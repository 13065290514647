<h1 mat-dialog-title>Criar Contexto</h1>

<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" [(ngModel)]="data.name" placeholder="Nome do Contexto">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" min="1" [(ngModel)]="data.interactionSpan" placeholder="Quantidade Máxima de interações">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" min="1000" [(ngModel)]="data.timeSpan" placeholder="Duração do contexto na memória do robô, em milisegundos">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data">Criar</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>
