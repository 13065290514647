import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Bot, BotService } from 'onevoice';

import { Intent, IntentService } from 'onevoice';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'alabia-treinador',
  templateUrl: './treinador.component.html',
  styleUrls: ['./treinador.component.scss']
})
export class TreinadorComponent implements OnInit {

  bot?: Bot;
  intents: Intent[] = [];
  onIntentsChanged: BehaviorSubject<any>;

  selected: Intent | null = null;
  selectedClear: Subject<boolean> = new Subject<boolean>();

  @ViewChild('filter', { static: true }) filter?: ElementRef;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private route: ActivatedRoute,
    private _botService: BotService,
    private _intentService: IntentService,
    public dialog: MatDialog,
  ) {
    this.selectedClear.subscribe(() => this.selected = null);
    this._unsubscribeAll = new Subject();
    this.onIntentsChanged = new BehaviorSubject({});
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const botid = Number(params.get('id'));
      this._botService.read(botid).subscribe(bot => {
        this.bot = bot;

        this._intentService.list(bot.id).subscribe((intents: Intent[]) => {

          this.intents = intents;
          this.intents.forEach(intent => (intent.bot = bot));

          if (params.has('intent')) {
            const intentId = Number(params.get('intent'));
            let intent = this.intents.find(intent => intent.id === intentId)
            if (intent) {
              this.selected = intent;
            }
          }

          this.onIntentsChanged.next(this.intents);
        });
      });
    });
  }
}
