import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { VoiceSnippet } from './voice-snippet.model';

@Injectable({
  providedIn: 'root'
})
export class VoiceSnippetService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public list(voice: number): Observable<VoiceSnippet[]> {
    return this.http.get<VoiceSnippet[]>(`${this.config.mediaservice}/voice/snippet`, {
      params: {
        voice: `${voice}`,
      },
    });
  }

  public create(voice: number, mood: number, text: string, content: File): Observable<VoiceSnippet> {
    let data = new FormData();

    data.set("voice", voice.toString());
    data.set("mood", mood.toString());
    data.set("text", text);
    data.set("file", content);

    return this.http.post<VoiceSnippet>(`${this.config.mediaservice}/voice/snippet`, data);
  }

  public update(id: number, text: string): Observable<boolean> {
    let data = new FormData();
    data.set("text", text);
    return this.http.post<boolean>(`${this.config.mediaservice}/voice/snippet/${id}/update`, data);
  }

  public delete(snipper: VoiceSnippet): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.mediaservice}/voice/snippet/${snipper.id}`);
  }

  public splitter(voice: number, mood: number, content: File): Observable<string> {
    let data = new FormData();

    data.set("voice", voice.toString());
    data.set("mood", mood.toString());
    data.set("file", content);

    return this.http.post(`${this.config.mediaservice}/voice/snippet/splitter`, data, {
      responseType: "text",
    });
  }

  public splitResult(uuid: string): Observable<VoiceSnippet[]> {
    return this.http.get<VoiceSnippet[]>(`${this.config.mediaservice}/voice/snippet/splitter/${uuid}`);
  }

  public snippetMediaUrl(snippet: VoiceSnippet) {
    return `${this.config.mediaservice}/voice/snippet/${snippet.id}/redirect`;
  }
}
