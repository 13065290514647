import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CreateAccount, AccountForm } from 'app/models';
import { IAMEndpoints } from 'app/models/iam-endpoints';
import { UpdateOrganization } from 'app/models/update-organization';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private url = environment.iam;

  constructor(private http: HttpClient) { }

  createAcount(param: AccountForm) {
    const body = new CreateAccount(param);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    return this.http.post(this.url + IAMEndpoints.signup, body, httpOptions);
  }

  updateOrganization(param: AccountForm) {
    const body = new UpdateOrganization(param);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(this.url + IAMEndpoints.organization, body, httpOptions);
  }
}
