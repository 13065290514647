import { Injectable } from '@angular/core';
import { Redirect, RedirectType } from 'onevoice';

@Injectable({
  providedIn: 'root'
})
export class QrcodeService {
  constructor() { }

  get protocol(): string {
    // return "https:";
    return window.location.protocol;
  }

  get location(): string {
    // return "www.alabiabot.com";
    return window.location.host;
  }

  public getRedirectUrl(redirect: Redirect): string {
    switch (redirect.type) {
      case RedirectType.MACHINE_TASK:
      default:
        return `${this.protocol}//${this.location}/machines/tasks/${redirect.destination}`;
    }
  }

  public getPublicUrl(redirect: Redirect): string {
    return `${this.protocol}//${this.location}/qrcode/${redirect.id}`;
  }
}
