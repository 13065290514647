import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuthenticationService,
  Machine,
  MachineExecutionService,
  MachineMap,
  MachineMapService,
  MachineModel,
  MachineModelAsset,
  MachineModelAssetService,
  MachineService,
  MachineTask,
  MachineTaskService,
  MapUnit,
  MapUnitService
} from 'onevoice';
import { Observable } from 'rxjs';
import { CreateMachineComponent } from './create-machine/create-machine.component';
import { CreateMapComponent } from './create-map/create-map.component';
import { CreateTaskComponent } from './create-task/create-task.component';
import { CreateUnitComponent } from './create-unit/create-unit.component';
import { DatePipe } from '@angular/common'
@Component({
  selector: 'cleaning-maps',
  templateUrl: './cleaning-maps.component.html',
  styleUrls: ['./cleaning-maps.component.scss'],
})
export class CleaningMapsComponent implements OnInit, AfterViewInit {
  public displayedColumnsMap: string[] = ['id', 'title'];
  public displayedColumnsMapUnit: string[] = ['id', 'title'];
  public displayedColumnsMachinie: string[] = ['id', 'title', 'nickname', 'model', 'location']
  public displayedColumns: string[] = ['id', 'title', 'description'];
  public displayedColumnsUnitMap: string[] = ['id', 'name'];

  public machineModelAsset: MachineModelAsset | undefined;
  public dataView = 'list';
  public taskAllView: boolean = true;
  public mapSelected?: MachineMap;
  public unitSelected?: MapUnit;
  public machineSelected?: Machine;
  public taskByMapSelected: MachineTask[] = [];
  public machinesByLocation: Machine[] = []

  public progressBarMaps: boolean = true;
  public progressBarMachines: boolean = true;
  public progressBarTasks: boolean = true;
  public progressBarUnits: boolean = true;

  public taskSelected?: MachineTask;
  public dataSourceMaps: MatTableDataSource<MachineMap> =
    new MatTableDataSource<MachineMap>();
  public dataSourceMachines: MatTableDataSource<Machine> =
    new MatTableDataSource<Machine>();
  public dataSourceTaskList: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();
  public dataSourceMapUnit: MatTableDataSource<MapUnit> =
    new MatTableDataSource<MapUnit>();
  public dataSourceFilterTask: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();


  public obsTask?: Observable<any>;

  public lastExec: string = '0';

  public lastExecTask : string = ''

  public dataSourceMapUnitFilter: MatTableDataSource<MapUnit> =
    new MatTableDataSource<MapUnit>();
  public dataSourceMachineFilter: MatTableDataSource<Machine> =
    new MatTableDataSource<Machine>();

  @ViewChildren(MatPaginator) public paginator = new QueryList<MatPaginator>();

  constructor(
    private auth: AuthenticationService,
    private serviceMap: MachineMapService,
    private serviceMachineTask: MachineTaskService,
    private mapUnitService: MapUnitService,
    public machineService: MachineService,
    public dialogs: MatDialog,
    public machineExecutionService: MachineExecutionService,
    public machineModelAssetService: MachineModelAssetService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.auth.systemToken().then((_) => {
      this.loadMaps();
      this.loadTask();
      this.loadUnit();
      this.loadMachines();

    });

  }

  public lastExecutionBytask(task : number){
    this.machineExecutionService.getLastExecutionByTask(task).subscribe(data=>{
      console.log(data)
    })
  }
  public lastExecution(id: number) {
    this.lastExec = '0';
    this.machineExecutionService.listByMachine(id).subscribe(data => {
      this.lastExec = this.datePipe.transform(new Date(data.start), 'dd/MM/yyyy HH:mm') || '';
    })
  }
  ngAfterViewInit(): void {
    this.dataSourceMaps.paginator = this.paginator.toArray()[0];
    this.dataSourceMachines.paginator = this.paginator.toArray()[1];
    this.dataSourceMapUnit.paginator = this.paginator.toArray()[2];
    this.dataSourceTaskList.paginator = this.paginator.toArray()[3];
  }
  get modelAsset() {
    return
  }
  private loadTask(): void {
    this.serviceMachineTask.list().subscribe((taskList) => {
      this.dataSourceTaskList.data = taskList;
      this.taskSelected = this.dataSourceTaskList.data[0];

      let cont = 0;
      this.dataSourceTaskList.data.forEach(data => {
        cont += 1
        if (data.type == 'MANUAL') {
          console.log(cont)
          console.log(data)
        }
      })
      this.dataSourceFilterTask.data = taskList.filter((e) => {
        return e.id == this.taskSelected?.id;
      });
      this.obsTask = this.dataSourceFilterTask.connect();
      this.progressBarTasks = false;
    });
  }

  private loadUnit(): void {
    this.mapUnitService.list().subscribe((unitList) => {
      this.progressBarUnits = false;
      this.dataSourceMapUnit.data = unitList;
      this.unitSelected = this.dataSourceMapUnit.data[0];
      this.getMachinesByLocation(this.unitSelected);
      console.log(this.dataSourceMapUnit.data)
      this.dataSourceMapUnitFilter.data = unitList.filter((e) => {
        return e.id == this.unitSelected?.id;
      });
    });
  }

  private loadMachines(): void {
    this.machineService.list().subscribe((machineList) => {
      this.dataSourceMachines.data = machineList;
      this.machineSelected = this.dataSourceMachines.data[0];
      this.lastExecution(this.machineSelected.id);
      this.machineModelAsset = undefined;
      this.updateMachineModelAsset(this.machineSelected.model)
      this.progressBarMachines = false;
      console.log("Machines list")
      console.log(this.dataSourceMachines.data)
      this.dataSourceMachineFilter.data = machineList.filter((e) => {
        return e.id == this.machineSelected?.id;
      });
    });
  }

  private loadMaps(): void {
    this.serviceMap.list().subscribe((mapList) => {
      this.dataSourceMaps.data = mapList;
      this.mapSelected = this.dataSourceMaps.data[0];
      this.filterTask();
      this.progressBarMaps = false;
    });
  }
  public updateMachineModelAsset(model: MachineModel) {
    this.machineModelAssetService.list().subscribe(asset => {
      asset.forEach(data => {
        if (data.model.id == model.id) {
          this.machineModelAsset = data;
          console.log(this.machineModelAsset)
        }
      })
    })
  }
  public getMachinesByLocation(location: MapUnit) {
    this.machineService.list().subscribe(machines =>
      this.machinesByLocation = machines.filter(machine => machine.location.id == location.id)
    )
  }


  private loadTaskByMap(map: MachineMap) {
    this.taskByMapSelected = this.dataSourceTaskList.data.filter(task => task.map.id == map.id)
    console.log(this.taskByMapSelected)
  }

  private filterTask(): void {
    this.dataSourceFilterTask.data = this.dataSourceTaskList.data.filter(
      (task) => {
        return task.map.id == this.mapSelected?.id;
      }
    );
  }

  public selectedMapRow(map: MachineMap): void {
    this.mapSelected = map;
    this.taskAllView = false;
    console.log('Mapa');
    console.log(this.mapSelected);
    this.loadTaskByMap(map)
    this.filterTask();
  }

  public selectedMapUnitRow(unit: MapUnit): void {
    this.unitSelected = unit;
    this.taskAllView = false;
    console.log('Unit');
    console.log(this.unitSelected);
    this.filterTask();
    this.getMachinesByLocation(unit);

  }
  public selectedMachineRow(machine: Machine): void {
    this.lastExecution(machine.id);
    this.machineSelected = machine;
    this.taskAllView = false;
    console.log('Machine');
    console.log(this.machineSelected);
    this.filterTask();

  }

  public selectedTaskRow(task: MachineTask) {
    this.lastExecutionBytask(task.id);
    this.taskSelected = task;
    console.log('tarefa');
    console.log(this.taskSelected);
  }

  public createMachine(machine: Partial<Machine> = {}): void {
    let reference = this.dialogs.open<
      CreateMachineComponent,
      Partial<Machine>,
      Partial<Machine>
    >(CreateMachineComponent, {
      width: '700px',
      maxWidth: '90%',
      data: machine,
    });


    reference.afterClosed().subscribe((machine) => {
      if (!machine) {
        return;
      }
      let machineAction = () => this.machineService.create(machine);
      if (machine.id !== undefined) {
        machineAction = () => this.machineService.update(machine);
      }

      machineAction().subscribe((machine) => {
        let data = this.dataSourceMachines.data.filter(
          (value) => value.id != machine.id
        );
        data.push(machine);
        data.sort((m1, m2) => m2.id - m1.id);
        this.dataSourceMachines.data = data;
      });
    });
  }

  public createMap(map: Partial<MachineMap> = {}): void {
    let reference = this.dialogs.open<
      CreateMapComponent,
      Partial<MachineMap>,
      Partial<MachineMap>
    >(CreateMapComponent, {
      width: '700px',
      maxWidth: '90%',
      data: map,
    });


    reference.afterClosed().subscribe((map) => {
      if (!map) {
        return;
      }

      let mapAction = () => this.serviceMap.create(map);
      if (map.id !== undefined) {
        mapAction = () => this.serviceMap.update(map);
      }

      mapAction().subscribe((map) => {
        let data = this.dataSourceMaps.data.filter(
          (value) => value.id != map.id
        );
        data.push(map);
        data.sort((m1, m2) => m2.id - m1.id);
        this.dataSourceMaps.data = data;
      });
    });
  }
  public createTask(data: Partial<MachineTask> = {}): void {
    let reference = this.dialogs.open<
      CreateTaskComponent,
      Partial<MachineTask>,
      MachineTask
    >(CreateTaskComponent, {
      width: '500px',
      maxWidth: '90%',
      data: data,
    });

    reference.afterClosed().subscribe((task) => {
      if (!task) {
        return;
      }

      this.serviceMachineTask
        .create(task)
        .subscribe(
          (task) =>
          (this.dataSourceTaskList.data = this.dataSourceTaskList.data.concat(
            [task]
          ))
        );
    });
  }
  public createMapUnit(data: Partial<MapUnit> = {}): void {
    let reference = this.dialogs.open<
      CreateUnitComponent,
      Partial<MapUnit>,
      MapUnit
    >(CreateUnitComponent, {
      width: '500px',
      maxWidth: '90%',
      data: data,
    });

    reference.afterClosed().subscribe((task) => {
      if (!task) {
        return;
      }

      this.mapUnitService
        .create(task)
        .subscribe(
          (task) =>
          (this.dataSourceMapUnit.data = this.dataSourceMapUnit.data.concat(
            [task]
          ))
        );
    });
  }
  public updateMapUnit(data: Partial<MapUnit> = {}): void {
    let reference = this.dialogs.open<
      CreateUnitComponent,
      Partial<MapUnit>,
      MapUnit
    >(CreateUnitComponent, {
      width: '500px',
      maxWidth: '90%',
      data: data,
    });

    reference.afterClosed().subscribe((task) => {
      if (!task) {
        return;
      }

      this.mapUnitService
        .update(task)
        .subscribe(
          (task) =>
            this.loadUnit()
        );
    });
  }
}
