<h1 mat-dialog-title>Enviar áudio</h1>

<mat-dialog-content>
  <span>Áudio para voz: {{ data.name }}.</span>

  <p>Leia a seguinte frase:</p>
  <p>{{line}}</p>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="toggleMicrofone()">
    <span *ngIf="!recording">Gravar</span>
    <span *ngIf="recording">Parar</span>
  </button>
  <button mat-raised-button (click)="listen()">Escutar</button>

  <button mat-button [mat-dialog-close]="file">Salvar</button>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>