import { ResponseComponent } from '@alabia/bot/dialogs/response/response.component';
import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormatsFor } from 'one-ui';
import { GenericResponse, GenericResponseService, MediaType, ResponseType } from 'onevoice';

const shouldFormat = false;


@Component({
  selector: 'main-response-view',
  templateUrl: './response-view.component.html',
  styleUrls: ['./response-view.component.scss']
})
export class ResponseViewComponent {
  public ResponseType = ResponseType;
  public MediaType = MediaType;

  @Input()
  public response?: GenericResponse;

  @Output()
  public update: EventEmitter<GenericResponse> = new EventEmitter<GenericResponse>();
  @Output()
  public delete: EventEmitter<GenericResponse> = new EventEmitter<GenericResponse>();

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private service: GenericResponseService,
    private resolver: ComponentFactoryResolver,
  ) { }

  @ViewChildren("templateElement", { read: ViewContainerRef })
  public set templateHost(reference: QueryList<ViewContainerRef>) {
    reference.forEach((reference: ViewContainerRef) => {
      if (!this.response) {
        return;
      }
      let response = this.response;
      let formats = FormatsFor(response.channel);

      if (!shouldFormat) { return; }

      for (const format of formats) {
        if (format.component && format.validate(response.content)) {
          reference.clear();
          let component = reference.createComponent<any>(
            this.resolver.resolveComponentFactory(format.component),
          );
          component.instance.updater.next(JSON.parse(response.content))
          return;
        }
      }
      console.debug("No format found");
    });
  }


  editResponse() {
    let reference = this.dialog.open<ResponseComponent, GenericResponse, GenericResponse | null>(ResponseComponent, {
      width: "80%",
      maxWidth: "500px",
      data: this.response,
    });

    reference.afterClosed().subscribe((response?: GenericResponse | null) => {
      if (!response) { return; }

      console.log(`Updating response`);
      this.service.update(response).subscribe(updated => {
        this.update.next(updated);
      });
    })
  }

  deleteResponse() {
    console.log("Sending event");
    this.delete.next(this.response);
  }

  public changeResponseOrder(response: GenericResponse, delta: number) {
    response.order = response.order + delta;
    this.service.update(response).subscribe(
      updated => {
        this.update.next(updated)
      },
      err => {
        this.snack.open(`Erro ao tentar atualizar resposta de ${response.type}`, 'Entendi', { duration: 10000 });
      },
    );
  }

  public responseImage(type: ResponseType): string {
    return `/assets/icons/response-type/${type}.png`
  }
}
