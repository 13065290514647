import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Machine, MachineService } from 'onevoice';
import { EditMachinesComponent } from '../edit-machines/edit-machines.component';

@Component({
  selector: 'main-list-machines',
  templateUrl: './list-machines.component.html',
  styleUrls: ['./list-machines.component.scss']
})
export class ListMachinesComponent implements OnInit {
  public datasource: MatTableDataSource<Machine> = new MatTableDataSource<Machine>();
  public fields: string[] = [
    "name",
    "model",
    "actions",
  ];

  constructor(public service: MachineService, public dialog: MatDialog) { }

  public ngOnInit(): void {
    this.service.list().subscribe(machineList => {
      machineList = machineList.sort((m1, m2) => m2.id - m1.id)
      this.datasource.data = machineList;
    });
  }

  public editMachine(machine: Partial<Machine> = {}): void {
    let reference = this.dialog.open<EditMachinesComponent, Partial<Machine>, Partial<Machine>>(EditMachinesComponent, {
      width: "700px",
      maxWidth: "90%",
      data: machine,
    });

    reference.afterClosed().subscribe(machine => {
      if (!machine) {
        return;
      }

      let machineAction = () => this.service.create(machine);
      if (machine.id !== undefined) {
        machineAction = () => this.service.update(machine);
      }

      machineAction().subscribe(machine => {
        let data = this.datasource.data.filter(value => value.id != machine.id);
        data.push(machine);
        data.sort((m1, m2) => m2.id - m1.id);
        this.datasource.data = data;
      });
    });
  }

  public deleteMachine(machine: Machine): void {
    this.service.delete(machine.id).subscribe(_ => {
      let data = this.datasource.data.filter(value => value.id != machine.id);
      data.sort((m1, m2) => m2.id - m1.id);
      this.datasource.data = data;
    });
  }
}
