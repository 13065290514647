import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule, MatInput } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { RouterModule, Routes } from '@angular/router';
import { FileInputModule } from 'one-ui';
import { VoiceContentComponent } from './voice-content.component';
import { VoiceCreateComponent } from './voice-create/voice-create.component';
import { VoiceModelDialogComponent } from './voice-model-dialog/voice-model-dialog.component';
import { VoiceSnippetComponent } from './voice-snippet/voice-snippet.component';
import { VoiceSplitterComponent } from './voice-splitter/voice-splitter.component';
import { VoiceWizardComponent } from './voice-wizard/voice-wizard.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: VoiceContentComponent,
    children: []
  },
  {
    path: ":id",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: VoiceSnippetComponent,
      },
      {
        path: "split",
        pathMatch: "full",
        component: VoiceSplitterComponent,
      }
    ],
  }
]


@NgModule({
  entryComponents: [VoiceCreateComponent, VoiceWizardComponent],
  declarations: [
    VoiceContentComponent,
    VoiceCreateComponent,
    VoiceSnippetComponent,
    VoiceWizardComponent,
    VoiceModelDialogComponent,
    VoiceSplitterComponent,
  ],
  imports: [
    RouterModule.forChild(routes),

    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCardModule,
    MatSnackBarModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,

    FileInputModule,

    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ]
})
export class VoiceContentModule { }
