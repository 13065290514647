import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { Queue } from './queue.model';

@Injectable({
  providedIn: 'root'
})
export class QueueService extends OwnableService<Queue> {
  protected path: string = `${this.config.endpoint}/queues`;
}
