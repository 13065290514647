import { Component, OnInit } from '@angular/core';
import { ServiceTokenService, ServiceToken } from 'onevoice';
import { MatDialog } from '@angular/material/dialog';
import { TokenDialogComponent } from './token-dialog/token-dialog.component';
import { JwtDialogComponent } from './jwt-dialog/jwt-dialog.component';

@Component({
  selector: 'alabia-tokens',
  templateUrl: './tokens.component.html',
  styleUrls: ['./tokens.component.scss']
})
export class TokensComponent implements OnInit {
  public tokenList: ServiceToken[] = [];
  public columnsToDisplay = ["id", "name", "view", "edit", "delete"];

  constructor(
    public tokens: ServiceTokenService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.tokens.list().subscribe(data => {
      this.tokenList = data;
    })

  }

  createToken() {
    let reference = this.dialog.open<TokenDialogComponent, Partial<ServiceToken>, ServiceToken>(TokenDialogComponent, {
      maxWidth: "400px",
      data: {
        name: "",
        active: true,
      }
    });

    reference.afterClosed().subscribe(data => {
      if (data) {
        this.tokens.create(data).subscribe(created => {
          this.tokenList = this.tokenList.concat([created]);
        });
      }
    });
  }

  editToken(token: ServiceToken) {
    let reference = this.dialog.open<TokenDialogComponent, ServiceToken, ServiceToken>(TokenDialogComponent, {
      maxWidth: "400px",
      data: { ...token }
    });

    reference.afterClosed().subscribe(data => {
      if (data) {
        this.tokens.update(data).subscribe(updated => {
          this.tokenList = this.tokenList.map(value => {
            if (value.id == updated.id) {
              return updated;
            }
            return value;
          });
        });
      }
    });
  }

  deleteToken(token: ServiceToken) {
    if (!token.id) { return; }
    this.tokens.delete(token.id).subscribe(_ => {
      this.tokenList = this.tokenList.filter(value => value.id != token.id);
    });
  }

  getSession(token: ServiceToken) {
    if (!token.id) { return; }
    this.tokens.retrieve(token.id).subscribe(session => {
      this.dialog.open<JwtDialogComponent, string, any>(JwtDialogComponent, {
        maxWidth: "400px",
        data: session,
      });
    });
  }
}
