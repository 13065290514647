<h1 mat-dialog-title>Mudar Senha</h1>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>Senha Atual</mat-label>
    <input matInput type="password" placeholder="Senha atual" [(ngModel)]="oldPassword">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Nova Senha</mat-label>
    <input matInput type="password" placeholder="Nova Senha" [(ngModel)]="newPassword">
  </mat-form-field>

  <mat-form-field>
    <mat-label>Confirmar senha</mat-label>
    <input matInput type="password" placeholder="Confirmar senha" [(ngModel)]="checkPassword">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="changePassword()" [disabled]="!isValid()">Atualizar</button>
  <button mat-button [mat-dialog-close]="null">Cancelar</button>
</mat-dialog-actions>