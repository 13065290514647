<div id="products" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <!-- <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"> -->
                <mat-icon class="logo-icon s-32 mr-16">
                    school
                </mat-icon>
                <!-- <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"> -->
                <span class="logo-text h1">
                    Treinamento
                </span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <div class="content">

            <div class="bot-details">
                <alabia-sidenav [bot]="bot" [clearIntent]="selectedClear" [amountIntents]="intents?.length">
                </alabia-sidenav>
            </div>

            <!-- CONTENT CARD -->

            <div class="content-card" [ngClass]="{'content-card--intent-view': selected }">
                <alabia-treinador-content [botData]="bot"></alabia-treinador-content>
            </div>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>