import { Component, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { AlabiaPhoneModule } from 'alabia-phone';

declare const JSONViewer: any;

@Component({
  selector: 'alabia-simulador-content',
  templateUrl: './simulador-content.component.html',
  styleUrls: ['./simulador-content.component.scss']
})
export class SimuladorContentComponent implements AfterViewInit {

  @ViewChild('localAudio', { static: true }) localAudio?: ElementRef;
  @ViewChild('remoteAudio', { static: true }) remoteAudio?: ElementRef;
  @ViewChild('ringtone', { static: true }) ringtone?: ElementRef;
  @ViewChild('ringbacktone', { static: true }) ringbacktone?: ElementRef;

  @ViewChild('chatFrame', { static: true }) chatFrame?: ElementRef;
  @ViewChild('json', { static: true }) jsonDetails?: ElementRef;
  @ViewChild('intent', { static: true }) jsonIntent?: ElementRef;

  public safeURL: SafeResourceUrl = '';
  public debuggerContent = '';
  public botId: number = 0;
  // public phone = new AlabiaPhoneModule();

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {

    this.route.params
      .subscribe(({ id }) => {
        this.botId = id;
        this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chatSimulator + this.botId + '?isSimulation=true');
      });

    // this.phone.connetPhone();
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any): void {
    if (event.data.type !== 'webpackWarnings') {
      this.debugMessage(event.data);
    }
  }

  public ngAfterViewInit(): void {
    // this.phone.setupPhone(this.localAudio, this.remoteAudio, this.ringbacktone, this.ringtone);
  }

  private debugMessage(data: any): void {
    if (this.jsonDetails && this.jsonIntent) {
      this.insertOnPage(this.jsonDetails, data, 1);
      this.insertOnPage(this.jsonIntent, data.responses, -1);
    }
  }

  public insertOnPage(element: ElementRef, jsonObj: {}, openedLevels: number): void {
    const jsonViewer = new JSONViewer();

    element.nativeElement
      .insertBefore(jsonViewer.getContainer(), element.nativeElement.firstChild);
    jsonViewer.showJSON(jsonObj, -1, openedLevels);
  }

}
