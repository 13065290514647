<h1 mat-dialog-title>Enviar áudio</h1>

<mat-dialog-content>
  <span *ngIf="data.name">Verificando voz: {{ data.name }}.</span>
  <span *ngIf="!data.name">Verificando voz contra todos.</span>

  <p>Leia a seguinte frase:</p>
  <p>{{line}}</p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="toggleMicrofone()">
    <span *ngIf="!recording">Gravar</span>
    <span *ngIf="recording">Parar</span>
  </button>
  <button mat-raised-button (click)="listen()">Escutar</button>
  <button mat-button [mat-dialog-close]="file">Verificar</button>

  <button mat-button [mat-dialog-close]="false">Sair</button>
</mat-dialog-actions>