import { MediaType, ResponseType } from "./message.model";

export enum ChannelType {
    TEST = "TEST",
    CHAT = "CHAT",
    FACEBOOK = "FACEBOOK",
    URA = "URA",
    WHATSAPP = "WHATSAPP",
    GOOGLE_PLAY = "GOOGLE_PLAY",
    EMAIL = "EMAIL",
    FACEBOOK_MESSENGER = "FACEBOOK_MESSENGER",
    FACEBOOK_COMMENT = "FACEBOOK_COMMENT",
    YOUTUBE_COMMENT = "YOUTUBE_COMMENT",
    INSTAGRAM_COMMENT = "INSTAGRAM_COMMENT",

}

export interface GenericResponse {
    id?: number;
    order: number;
    type: ResponseType;
    mediaType: MediaType;
    channel: ChannelType;
    content: string;
}
