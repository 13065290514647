import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Intent } from 'onevoice';

export interface IntentResponse {
    id?: number;
    intent?: Intent;
    idType: number;
    idContent: number;
}

@Injectable({
    providedIn: 'root'
})
export class IntentResponseService {
    url = `${environment.coreService}/api/responses`;

    constructor(private _httpClient: HttpClient) { }

    list(intent?: number): Observable<IntentResponse[]> {
        let params: HttpParams = new HttpParams();
        if (intent) {
            params = params.set('intent', `${intent}`);
        }
        return this._httpClient.get<IntentResponse[]>(this.url, {
            params: params
        });
    }

    get(id: number): Observable<IntentResponse> {
        return this._httpClient.get<IntentResponse>(`${this.url}/${id}`);
    }

    create(data: IntentResponse): Observable<IntentResponse> {
        return this._httpClient.post<IntentResponse>(this.url, data);
    }

    update(data: IntentResponse): Observable<IntentResponse> {
        return this._httpClient.post<IntentResponse>(
            `${this.url}/${data.id}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        );
    }

    delete(data: IntentResponse): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
