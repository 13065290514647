<h1 mat-dialog-title>Criar Pergunta para Parâmetro</h1>

<mat-dialog-content>
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef>Conteúdo da Pergunta</th>
            <td mat-cell *matCellDef="let data">
                <mat-form-field style=" margin-bottom: -0.8em" appearance="standard">
                    <textarea matInput type="text" [(ngModel)]="data.content"></textarea>
                </mat-form-field>
            </td>
            <td mat-footer-cell *matFooterCellDef colspan="4">
                <button mat-icon-button matTooltip="Adicionar novo parâmetro à ação." (click)="addMore()">
                  <mat-icon>add</mat-icon>
                </button>
              </td>
        </ng-container>

        <ng-container matColumnDef="media">
            <th class="fit-content" mat-header-cell *matHeaderCellDef>Tipo de Mídia</th>
            <td class="fit-content" mat-cell *matCellDef="let data">
                <mat-form-field style=" margin-bottom: -0.8em" appearance="standard">
                    <mat-select matNativeControl required [(ngModel)]="data.idMediaType" [compareWith]="compareId">
                        <mat-option *ngFor="let item of MediaType" [value]="item[0]">
                            {{ item[1] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="fallback">
            <th class="fit-content" mat-header-cell *matHeaderCellDef>Tipo de Fallback</th>
            <td class="fit-content" mat-cell *matCellDef="let data">
                <mat-form-field style=" margin-bottom: -0.8em" appearance="standard">
                    <mat-select matNativeControl required [(ngModel)]="data.idFallbackType" [compareWith]="compareId">
                        <mat-option *ngFor="let item of FallbackType" [value]="item[0]">
                            {{ item[1] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="remove">
            <th class="fit-content" mat-header-cell *matHeaderCellDef>Deletar</th>
            <td class="fit-content" mat-cell *matCellDef="let data">
                <button mat-icon-button (click)="removePrompt(data)">
                    <mat-icon>close</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="['content']"></tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data">Salvar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>
