import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Context {
    id?: number;
    name?: string;

    interactionSpan?: number;
    timeSpan?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ContextService {
    url: string = `${environment.coreService}/api/contexts`;

    constructor(private _httpClient: HttpClient) {}

    list(): Observable<Context[]> {
        return this._httpClient.get<Context[]>(this.url);
    }

    get(id: number): Observable<Context> {
        return this._httpClient.get<Context>(`${this.url}/${id}`);
    }

    create(data: Context): Observable<Context> {
        return this._httpClient.post<Context>(this.url, data);
    }

    update(data: Context): Observable<Context> {
        return this._httpClient.post<Context>(`${this.url}/${data.id}`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    delete(data: Context): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
