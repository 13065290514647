import { Pipe, PipeTransform } from '@angular/core';
import { Chat, Customer, CustomerIdentifier, IdentifierType, QueueAssignment } from 'onevoice';

function cleanCPF(text: string) {
  return text.replace(/\-/g, "").replace(/\./g, "").replace(/ /g, "");
}

@Pipe({
  name: 'chatFilter'
})
export class ChatFilterPipe implements PipeTransform {
  private compareString(full: string, part: string): boolean {
    return full.toLowerCase().includes(part.toLowerCase())
  }

  private compareIdentifier(value: CustomerIdentifier, text: string): boolean {
    switch (value.type) {
      case IdentifierType.CPF:
        return this.compareString(cleanCPF(value.identifier), cleanCPF(text));;
      default:
        return this.compareString(value.identifier, text);
    }
  }

  private containIdentifier(identifiers: CustomerIdentifier[], text: string) {
    return identifiers.filter(
      value => this.compareIdentifier(value, text)
    ).length > 0;
  }

  private matchCustomer(customer: Customer, text: string): boolean {
    return this.compareString(customer.firstName || "", text) || this.compareString(customer.lastName || "", text) || this.containIdentifier(customer.identifiers || [], text);
  }

  private matchAssignment(assignment: QueueAssignment | undefined, text: string) {
    return assignment && this.compareString(assignment.protocol_id || "", text);
  }

  transform(chats: Chat[], searchText: string): Chat[] {
    return chats.filter(chat => this.matchCustomer(chat.customer, searchText) || this.matchAssignment(chat.assignment, searchText));
  }
}
