import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, Organization, OrganizationService, Status, TokenService } from 'onevoice';
import { environment } from 'environments/environment';

@Component({
  selector: 'alabia-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  public action: string = `${environment.coreService}/auth/organization`;
  public organization: Organization = {
    name: "",
    slug: "organization-",
    status: Status.INFO_REQUIRED,
  };

  constructor(
    private service: OrganizationService,
    private auth: AuthenticationService,
    private router: Router,
    private storage: TokenService,

  ) {
    this.organization.slug = this.organization.slug + (new Date().getTime());
  }

  ngOnInit() {
    if (this.auth.current.value && this.auth.current.value.organization !== null) {
      this.router.navigateByUrl('/');
    }
  }

  createOrganization() {
    this.service.create(this.organization).subscribe(created => {
      this.auth.refresh().then(_ => {
        this.router.navigateByUrl('/');
      });
    });
  }
}
