import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeType, ChannelType, Chat, Message, User } from 'onevoice';

@Component({
  selector: 'alabia-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent {
  @Input() selectedChat: boolean = false;
  @Input() chat?: Chat;

  @Output() selected = new EventEmitter();

  public ChangeType = ChangeType;

  public output() {
    this.selected.emit();
  }

  contactAvatar(chat: Chat): string {
    return this.lastMessage(chat).channel === ChannelType.FACEBOOK ? '/assets/images/avatars/facebook.png' : '/assets/images/avatars/chat.png';
  }

  lastMessage(chat: Chat): Message {
    return chat.dialog[chat.dialog.length - 1];
  }

  public userFullname(user: User) {
    let fullname = `${user.details.first_name || ""} ${user.details.last_name || ""}`.trim();
    if (fullname.length <= 0) {
      fullname = user.email;
    }
    return fullname;
  }

}
