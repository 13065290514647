import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';

export interface Related {
  id?: number
}


@Injectable({
  providedIn: 'root'
})
export abstract class RelatedService<T extends Related> {
  protected abstract path: string;

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public list(related: number): Observable<T[]> {
    return this.http.get<T[]>(`${this.path}`.replace("{related}", related.toString()), {
      responseType: "json",
      observe: "body",
    });
  }

  public create(related: number, data: T): Observable<T> {
    return this.http.post<T>(`${this.path}`.replace("{related}", related.toString()), data, {
      responseType: "json",
      observe: "body",
    });
  }

  public read(related: number, id: number): Observable<T> {
    return this.http.get<T>(`${this.path}/${id}`.replace("{related}", related.toString()), {
      responseType: "json",
      observe: "body",
    });
  }

  public update(related: number, id: number, data: T): Observable<T> {
    return this.http.post<T>(`${this.path}/${id}`.replace("{related}", related.toString()), data, {
      responseType: "json",
      observe: "body",
    });
  }

  public delete(related: number, id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${id}`.replace("{related}", related.toString()));
  }
}
