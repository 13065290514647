import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { UserCreateRequest, User, UserDetailRequest } from './user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: ApiConfiguration) { }

  public list(): Observable<User[]> {
    return this.http.get<User[]>(`${this.config.endpoint}/users`);
  }

  public create(data: UserCreateRequest): Observable<User> {
    return this.http.post<User>(`${this.config.endpoint}/users`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  public info(): Observable<User> {
    return this.http.post<User>(`${this.config.endpoint}/auth/me`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  public userExist(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.config.endpoint}/users/email`, {
      params: { email }, // Passando o email como query parameter
      headers: {
        "Content-Type": "application/json", // Cabeçalho opcional para GET
      },
    });
  }


  public update(userId: number, data: UserDetailRequest): Observable<User> {
    return this.http.post<User>(`${this.config.endpoint}/profiles/${userId}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public delete(user: User): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.endpoint}/users/${user.id}`);
  }

  public avatar(userId: number): string {
    return `${this.config.endpoint}/users/${userId}/avatar`;
  }

  public uploadAvatar(file: File) {
    let data = new FormData();
    data.set("file", file);
    return this.http.post<boolean>(`${this.config.endpoint}/users/avatar`, data);
  }
}
