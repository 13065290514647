import { Inject, Injectable } from '@angular/core';
import { Client, IFrame, IMessage } from '@stomp/stompjs';
import { BehaviorSubject, Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { TokenService } from '../auth/token.service';
import { CustomerChannelType } from '../crm/customer-channel.model';
import { ChannelType } from '../dialog/generic-response.model';
import { Sender } from '../dialog/message.model';
import { Message, StompMessage } from './message.model';

interface CustomerInformation {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerStompService {
  public IsConnected: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public OnMessage: Subject<Message> = new Subject<any>();
  public bot: number = 1;

  private customer?: CustomerInformation;

  private client: Client;

  constructor(
    private storage: TokenService,
    @Inject(APP_CONFIG) public config: ApiConfiguration,
  ) {
    const client = new Client({
      webSocketFactory: () => new SockJS(`${this.config.endpoint}/messenger`),
      onConnect: (frame: IFrame) => {
        this.IsConnected.next(true);
        client.subscribe("/user/messenger", (message) => this.receiveMessage(message))
      },
      onDisconnect: (receipt: IFrame) => {
        this.IsConnected.next(false);
      },
    });
    client.activate();
    this.client = client;
  }

  public receiveMessage(message: IMessage): void {
    try {
      let parsed = JSON.parse(message.body) as StompMessage;
      parsed.responses.map((composite): Message => {
        return {
          id: composite.id,
          channel: parsed.interactionDTO?.channel.type || ChannelType.CHAT,
          contentType: composite.contentType,
          mediaType: composite.mediaType,
          sender: Sender.ROBOT,
          date: new Date(),
          content: composite.content,
        }
      }).forEach(
        message => this.OnMessage.next(message)
      );
    } catch (error) {
      console.error(error);
    }
  }

  public setBot(id: number) {
    this.bot = id;
  }

  public setCustomer(data: CustomerInformation) {
    this.customer = data;
  }

  public sendMessage(content: string) {
    if (!this.customer) {
      return;
    }
    const body: string = JSON.stringify({
      content: content,
      firstname: this.customer.firstname,
      lastname: this.customer.lastname,
      email: this.customer.email,
      phone: this.customer.phone,
    });

    this.client.publish({
      skipContentLengthHeader: true,
      destination: '/upstream/messenger',
      headers: { 'bot-id': `${this.bot}` },
      body: body,
    });
  }
}
