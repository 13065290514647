import { ChannelType } from "onevoice";
import { Format } from "../response-format/format.model";
import { ChatCarousel, FacebookCarousel } from "./facebook-carousel/facebook-carousel.component";

export const Formats = [
    FacebookCarousel,
    ChatCarousel,
]

export function FormatsFor(channel: ChannelType): Format<any>[] {
    return Formats.filter(format => format.channel == channel);
}
