import { FuseNavigation } from '@fuse/types';
import { AuthorizationType } from 'onevoice';


export const navigation: FuseNavigation[] = [
    {
        id: 'homepage',
        title: 'Home',
        type: 'item',
        icon: 'home',
        url: '/home',
        permission: []
    },
    {
        id: 'applications',
        title: 'Produtos',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'chat',
                title: 'Transbordo',
                description: 'Aqui você pode assumir conversas com seus clientes',
                type: 'item',
                icon: 'chat_bubble',
                url: '/chat',
                permission: [AuthorizationType.AGENT],
            },
            {
                id: 'bot-manager',
                title: 'Gerenciador de Robôs',
                description: 'Crie e gerencie seus robôs de atendimento',
                type: 'item',
                icon: 'adb',
                url: '/bots',
                permission: [AuthorizationType.ADMIN],
            },
            {
                id: 'widget',
                title: 'Criador de Widget',
                description: 'Crie e personalize seu cliente webchat para disponibilizar na(s) página(s) da sua organização',

                type: 'item',
                icon: 'color_lens',
                url: '/widget-creator',
                permission: [AuthorizationType.ADMIN],

            },
        ],
        permission: []
    },
    {
        id: 'management',
        title: 'Administração',
        type: 'group',
        icon: 'business',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                description: 'Acompanhe em tempo real os principais KPI\'s do seu negócio',
                type: 'item',
                icon: 'data_usage',
                url: '/dashboard',
                permission: [AuthorizationType.ADMIN],

            },
            {
                id: 'crm',
                title: 'CRM',
                description: 'Gerencie os contatos da sua empresa dentro do sistema',

                type: 'item',
                icon: 'supervisor_account',
                url: '/customer',
                permission: [AuthorizationType.ADMIN],

            },
            {
                id: 'users',
                title: 'Gerenciador de Usuários',
                description: 'Cadastre e gerencie usuários e suas respectivas permissões',
                type: 'item',
                icon: 'verified_user',
                url: '/manage-users',
                permission: [AuthorizationType.ADMIN , AuthorizationType.MANAGER],

            },
            {
                id: 'skills',
                title: 'Gerenciador de Skills',
                description: 'Adicione e gerencie os skills para os usuários',
                type: 'item',
                icon: 'widgets',
                url: '/skill-manager',
                permission: [AuthorizationType.ADMIN],

            },
            {
                id: "service-token",
                title: 'Tokens',
                description: "Gerencie os tokens de serviço da sua conta",

                type: "item",
                icon: "vpn_key",
                url: "/token",
                permission: [AuthorizationType.ADMIN],

            },
            {
                id: "qrcode",
                title: 'Códigos QR',
                description: "Gerencie os seus códigos QR",

                type: "item",
                icon: "code",
                url: "/qrcode",
                permission: [AuthorizationType.ADMIN],
            },
            {
                id: "robot-dashboard",
                title: 'Robôs',
                description: "Gerencie os robôs físicos de sua empresa",

                type: "item",
                icon: "android",
                url: "/machines",
                permission: [AuthorizationType.ADMIN],

            },
            {
                id: "cleaning-menu",
                title: 'Limpeza',
                description: "Gerencie sua equipe de limpeza",

                type: "item",
                icon: "local_laundry_service",
                url: "/cleaning",
                permission: [AuthorizationType.ADMIN, AuthorizationType.MANAGER, AuthorizationType.CLEANER],
            },
            {
                id: "scheduler",
                title: "Agendador",
                description: "Agendar as tasks dos robos",

                type: "item",
                icon: "android",
                url: "/scheduler",
                permission: [AuthorizationType.ADMIN,AuthorizationType.MANAGER],
            },
        ],
        permission: []
    },
    {
        id: 'voice-products',
        title: 'Voz',
        type: 'group',
        icon: 'settings_voice',

        children: [
            {
                id: "stt",
                title: "STT",
                description: "Transcreva seus áudios",

                type: 'item',
                icon: "mic",
                url: "/stt",
                permission: [AuthorizationType.VOICE]
            },
            {
                id: "tts",
                title: "TTS",
                description: "Crie áudios a partir de texto",

                type: 'item',
                icon: "speaker",
                url: "/tts",
                permission: [AuthorizationType.VOICE]
            },
            {
                id: "voices",
                title: "Vozes",
                description: "Crie vozes para o seu TTS",

                type: "item",
                icon: "record_voice_over",
                url: "/voices",
                permission: [AuthorizationType.VOICE]

            },
            {
                id: "biometric",
                title: "Biometria",
                description: "Biometria de Voz",

                type: "item",
                icon: "hearing",
                url: "/biometrics",
                permission: [AuthorizationType.VOICE]
            },
            {
                id: "voice-projects",
                type: "item",

                title: "Projetos",
                description: "Projetos de Geração de Voz",
                icon: "format_align_justify",
                url: "/voice-projects",
                permission: [AuthorizationType.VOICE],
            }
        ],
        permission: [AuthorizationType.VOICE],
    }
];
