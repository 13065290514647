<!-- A.I. -->

<div class="search-wrapper">
  <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>search</mat-icon>
    <input (keyup)="filterList(phrase.value)" #phrase  placeholder="Busque por uma frase">
  </div>
</div>

<br>
<br>
<mat-divider></mat-divider>

<mat-list-item>
  <mat-form-field>
    <input matInput type="text" placeholder="Nova frase" [(ngModel)]="utterance">
    <button matSuffix mat-icon-button (click)="addUtterance()">
      <mat-icon class="add">add</mat-icon>
    </button>
  </mat-form-field>
</mat-list-item>

<mat-list-item *ngFor="let utterance of filter">
  <!-- {{ utterance.content }} -->
  <mat-form-field>
    <input matInput type="text" [(ngModel)]="utterance.content">
    <button matSuffix mat-icon-button (click)="removeUtterance(utterance)">
      <mat-icon class="close">close</mat-icon>
    </button>
  </mat-form-field>
</mat-list-item>