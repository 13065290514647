<h1 mat-dialog-title>{{ data.id === undefined ? 'Criar Robô' : 'Editar Robô' }}</h1>

<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Nome do Robô</mat-label>
        <input matInput type="text" [(ngModel)]="data.name" placeholder="Nome do Robô">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Descrição do Robô</mat-label>
        <input matInput type="text" [(ngModel)]="data.description" placeholder="Descrição do Robô">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Frase do Webchat</mat-label>
        <textarea matInput type="text" [(ngModel)]="data.bannerText" placeholder="Frase do Webchat"></textarea>
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Frase de Apresentação</mat-label>
        <input matInput type="text" [(ngModel)]="data.presentation" placeholder="Frase de Apresentação">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Aviso de indisponibilidade de agentes</mat-label>
        <input matInput type="text" [(ngModel)]="data.away" placeholder="Aviso de indisponibilidade de agentes">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Integração de CRM</mat-label>
        <input matInput type="text" [(ngModel)]="data.customer" placeholder="Integração de CRM">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Integração de PUSH</mat-label>
        <input matInput type="text" [(ngModel)]="data.notification" placeholder="Integração de PUSH">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Abertura</mat-label>
        <input matInput type="time" [(ngModel)]="data.open" placeholder="Abertura">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Fechamento</mat-label>
        <input matInput type="time" [(ngModel)]="data.close" placeholder="Fechamento">
    </mat-form-field>

    <mat-label class="scoreSliderText">Score</mat-label>
    <mat-slider thumbLabel [displayWith]="formatLabel" class="scoreSlider" step="0.1" tickInterval="1" min="0" max="1"
        [(ngModel)]="data.score"></mat-slider>

    <mat-form-field *ngIf="data.id" appearance="outline">
        <mat-label>Intenção de Avaliação</mat-label>
        <mat-select [(ngModel)]="feedback">
            <mat-option [value]="null">Nenhuma intenção</mat-option>
            <mat-option *ngFor="let intent of intentList" [value]="intent">{{ intent.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <mat-form-field appearance="outline">
        <mat-label>Cor de Fundo</mat-label>
        <input matInput type="text" [(ngModel)]="data.backgroundColor" placeholder="Cor de Fundo">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Segunda Cor de Fundo</mat-label>
        <input matInput type="text" [(ngModel)]="data.backgroundColor2" placeholder="Segunda Cor de Fundo">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Cor do Texto</mat-label>
        <input matInput type="text" [(ngModel)]="data.foregroundColor" placeholder="Cor do Texto">
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Cor do Frase do Banner</mat-label>
        <input matInput type="text" [(ngModel)]="data.bannerColor" placeholder="Cor do Frase do Banner">
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Identificadores</mat-label>
        <mat-select [(ngModel)]="data.identifiers" multiple>
            <mat-option *ngFor="let item of identifierList()" [value]="item">{{ item }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Informações de Contato</mat-label>
        <mat-select [(ngModel)]="data.contacts" multiple>
            <mat-option *ngFor="let item of contactList()" [value]="item">{{ item }}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button *ngIf="data.id" (click)="addAvatar()">Avatar</button>
    <button mat-button *ngIf="data.id" (click)="addBanner()">Background</button>
    <button mat-button *ngIf="data.id" (click)="addLogo()">Logo</button>


    <button mat-button (click)="saveBot()">{{ data.id === undefined ? 'Criar' : 'Atualizar' }}</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>