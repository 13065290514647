import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Machine, MachineMap, MachineMapService, MapUnit, MapUnitService } from 'onevoice';
import * as uuid from 'uuid';

@Component({
  selector: 'create-map',
  templateUrl: './create-map.component.html',
  styleUrls: ['./create-map.component.scss']
})
export class CreateMapComponent implements OnInit{
  public form: FormGroup;
  public dataSourceMapUnit: MatTableDataSource<MapUnit> =
  new MatTableDataSource<MapUnit>();
  public default : MatTableDataSource<Machine> =
  new MatTableDataSource<Machine>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MachineMap,
    private reference: MatDialogRef<CreateMapComponent, MachineMap>,
    private mapUnitService: MapUnitService,
    private machineMapService: MachineMapService,

  ) {
    this.form = new FormGroup({
      "title": new FormControl(data.title || "", [Validators.required]),
      "uniqueId": new FormControl(data.uniqueId || uuid.v4(), [Validators.required]),
      "location": new FormControl(data.unit || "", [Validators.required]),
    }, []);
  }

  ngOnInit(): void {
    this.loadUnit();

    console.log(this.data)
  }

  get title(): string {
    return (this.data.id !== undefined) ? "Editar Mapa" : "Criar Mapa";
  }
  public submitMap(): void {
    this.data.type = "MANUAL";

    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  private loadUnit(): void {

    this.mapUnitService.list().subscribe((unitList) => {
      this.dataSourceMapUnit.data = unitList;

    });

  }

  public selectUnit( unit : MapUnit): void{
    this.data.unit = unit;

  }
}
