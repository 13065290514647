import { cpf } from 'cpf-cnpj-validator';

export enum IdentifierType {
    CPF = "CPF", RNE = "RNE", CNH = "CNH",
}

export interface CustomerIdentifier {
    id: number;
    type: IdentifierType;
    identifier: string;
}

export function ValidateIdentifier(type: IdentifierType, value: string): boolean {
    switch (type) {
        case IdentifierType.CPF:
            return cpf.isValid(value);

        default:
            return true;

    }
}