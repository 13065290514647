import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { GenericResponse } from './generic-response.model';
import { ResponseType } from './message.model';

@Injectable({
  providedIn: 'root'
})
export class GenericResponseService {
  private path = `${this.config.endpoint}/responses`;

  constructor(@Inject(APP_CONFIG) private config: ApiConfiguration, private http: HttpClient) { }

  getTypes(): Observable<ResponseType> {
    return this.http.get<ResponseType>(`${this.path}/types`);
  }

  list(intent: number): Observable<GenericResponse[]> {
    return this.http.get<GenericResponse[]>(`${this.path}?intent=${intent}`);
  }

  get(id: number): Observable<GenericResponse> {
    return this.http.get<GenericResponse>(`${this.path}/${id}`);
  }

  create(intent: number, data: GenericResponse): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(`${this.path}?intent=${intent}`, data);
  }

  update(data: GenericResponse): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(`${this.path}/${data.id}`, data);
  }

  delete(data: GenericResponse): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${data.id}`);
  }
}
