<h2 mat-dialog-title>Respostas</h2>

<mat-dialog-content>
    <mat-form-field appearance="outline">
        <mat-label>Filtro</mat-label>
        <input matInput cdkFocusInitial [(ngModel)]="filterString" type="text">
    </mat-form-field>

    <mat-selection-list #responses>
        <mat-list-option *ngFor="let option of responseList" [value]="option" [hidden]="hideItem(option)">
            <h4 matLine>{{ option.title }}</h4>
            <p matLine>{{ option.content }}</p>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="selectOptions(responses)">Escolher</button>
    <button mat-raised-button [mat-dialog-close]="[]">Cancelar</button>
</mat-dialog-actions>