import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface CorpusIntent {
    id?: number;
    project_id?: number;
    name?: string;
    description?: string;
    counter?: number;
}

@Injectable({
    providedIn: 'root'
})
export class CorpusIntentService {
    url: string = `${environment.corpusService}/intents`;

    constructor(private _httpClient: HttpClient) {}

    list(project_id?: number): Observable<CorpusIntent[]> {
        let data: HttpParams = new HttpParams();
        if (project_id) {
            data = data.set('project_id', `${project_id}`);
        }
        return this._httpClient.get<CorpusIntent[]>(this.url, {
            params: data
        });
    }

    get(id: number): Observable<CorpusIntent> {
        return this._httpClient.get<CorpusIntent>(`${this.url}/${id}`);
    }

    create(data: CorpusIntent): Observable<CorpusIntent> {
        return this._httpClient.post<CorpusIntent>(this.url, data);
    }

    update(data: CorpusIntent): Observable<CorpusIntent> {
        return this._httpClient.post<CorpusIntent>(`${this.url}/${data.id}`, data);
    }

    delete(data: CorpusIntent): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
