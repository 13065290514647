import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericModel, GenericService } from '../generic.service';
import { MachineTask } from '../hw/machine-task.service';
import { Floor } from './floor.service';

export interface Room extends GenericModel<number> {
  name: string;
  floor: Floor;
}

@Injectable({
  providedIn: 'root'
})
export class RoomService extends GenericService<number, Room> {
  protected path: string = `${this.config.endpoint}/spaces/rooms`;

  public listTasks(id: number): Observable<MachineTask[]> {
    return this.http.get<MachineTask[]>(`${this.path}/${id}/tasks`, {
      responseType: 'json',
    });
  }

  public addTasks(id: number, tasks: MachineTask[]): Observable<Boolean> {
    return this.http.post<Boolean>(`${this.path}/${id}/tasks`, tasks.map(task => task.id), {
      responseType: 'json',
      headers: this.JSON_HEADER,
    });
  }

  public rmTasks(id: number, tasks: MachineTask[]): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${id}/tasks`, {
      responseType: 'json',
      headers: this.JSON_HEADER,
      body: tasks.map(task => task.id),
    });
  }
}
