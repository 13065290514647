import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { GenericModel } from '../generic.service';

export interface Ownable extends GenericModel<number> {
  created?: number;
  updated?: number;
}


@Injectable({
  providedIn: 'root'
})
export abstract class OwnableService<Model extends Ownable> {
  protected abstract path: string;
  protected related: string = "related_id";

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(related?: number): Observable<Model[]> {
    let params = new HttpParams();
    if (related) {
      params = params.set(this.related, `${related}`);
    }
    return this.http.get<Model[]>(`${this.path}`, {
      responseType: "json",
      observe: "body",
      params: params,
    });
  }

  public create(data: Partial<Model>): Observable<Model> {
    return this.http.post<Model>(`${this.path}`, data, {
      responseType: "json",
      observe: "body",

      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    });
  }

  public read(id: number): Observable<Model> {
    return this.http.get<Model>(`${this.path}/${id}`, {
      responseType: "json",
      observe: "body",
    });
  }

  public update(data: Partial<Model>): Observable<Model> {
    return this.http.post<Model>(`${this.path}/${data.id}`, data, {
      responseType: "json",
      observe: "body",

      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    });
  }

  public delete(id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${id}`);
  }
}
