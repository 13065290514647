import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkillManagerDialogComponent } from 'app/alabia/skill-manager/skill-manager-dialog/skill-manager-dialog.component';

export interface Filter {
  value?: string;
  allValues?: boolean;
  allDates?: boolean;
  initialDate?: string;
  finalDate?: string;
  agentType?: string;
  agent?: string;
}

export class FilterForm {
  value?: string;
  allValues?: boolean;
  allDates?: boolean;
  initialDate?: string;
  finalDate?: string;
  agentType?: string;
  agent?: string;

  constructor(filter: any) {
    {
      this.value = '';
      this.allValues = false;
      this.allDates = false;
      this.initialDate = '';
      this.finalDate = '';
      this.agentType = '';
      this.agent = '';
    }
  }
}

export interface Agents {
  name: string;
  type: string;
}


@Component({
  selector: 'alabia-customer-filter-dialog',
  templateUrl: './customer-filter-dialog.component.html',
  styleUrls: ['./customer-filter-dialog.component.scss']
})
export class CustomerFilterDialogComponent implements OnInit {

  allValues: boolean = true;
  allTime: boolean = true;
  filter: Filter = {};
  filterForm?: FormGroup;

  agents: Agents[] = [
    {
      name: 'Fernanda',
      type: 'human',
    },
    {
      name: 'C3PO',
      type: 'bot',
    },
    {
      name: 'Júlia',
      type: 'human',
    },
    {
      name: 'R2D2',
      type: 'bot',
    },
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public matDialogRef: MatDialogRef<SkillManagerDialogComponent>,
    private _formBuilder: FormBuilder,
  ) {
    this.open(_data)
  }

  ngOnInit() { }

  open(_data: any): void {
    if (_data.filterData) {
      this.filter = _data.filterData;
      this.filterForm = this.createFilterForm(false);
    } else {
      this.filter = new FilterForm({});
      this.filterForm = this.createFilterForm(true);
    }
  }

  createFilterForm(isEmptyFilter: boolean): FormGroup {
    return this._formBuilder.group({
      value: new FormControl({ value: isEmptyFilter || this.filter.allValues ? '' : this.filter.value, disabled: this.filter.allValues }),
      allValues: isEmptyFilter ? false : this.filter.allValues,
      initialDate: new FormControl({ value: isEmptyFilter || this.filter.allDates ? '' : this.filter.initialDate, disabled: this.filter.allDates }),
      finalDate: new FormControl({ value: isEmptyFilter || this.filter.allDates ? '' : this.filter.finalDate, disabled: this.filter.allDates }),
      allDates: isEmptyFilter ? false : this.filter.allDates,
      agentType: isEmptyFilter ? '' : this.filter.agentType,
      agent: isEmptyFilter ? '' : this.filter.agent,
    });
  }

  filterData() {
    if (this.filterForm) {
      this.matDialogRef.close(this.filterForm.value);
    }
  }

  clear() {
    this.filterForm = this.createFilterForm(true);
  }

  filterCheckbox() {
    this.filter = (this.filterForm) ? this.filterForm.value : {};
    this.filterForm = this._formBuilder.group({
      value: new FormControl({ value: this.filter.allValues ? '' : this.filter.value, disabled: this.filter.allValues }),
      allValues: this.filter.allValues,
      initialDate: new FormControl({ value: this.filter.allDates ? '' : this.filter.initialDate, disabled: this.filter.allDates }),
      finalDate: new FormControl({ value: this.filter.allDates ? '' : this.filter.finalDate, disabled: this.filter.allDates }),
      allDates: this.filter.allDates,
      agentType: this.filter.agentType,
      agent: this.filter.agent,
    });
  }

}
