<div class="scriptCards mat-elevation-z2" matSort matSortActive="id" matSortDirection="asc">
    <div class="scriptTitle mat-headline" style="margin-bottom: 10px">Histórico TTS</div>
    <button id="listView-icon" mat-icon-button type="button" (click)="changeView()">
        <mat-icon matSuffix matTooltip="Mostrar grade"
            aria-label="Button that displays a tooltip when focused or hovered over">view_list</mat-icon>
    </button>

    <ng-container matColumnDef="id">
        <h5 class="sort-icon" mat-sort-header sortActionDescription="ordem">Ordem</h5>
    </ng-container><br>

    <div class="scriptTitle mat-subheading-1" style="margin-top: -30px;">Seu texto convertido em voz</div>
    <mat-divider></mat-divider>

    <mat-card class="scriptCardTemplate" id="scriptCard" *ngFor="let tts of obs | async">
        <!-- <mat-card class="scriptCardTemplate" id="scriptCard" *ngFor="let tts of datasource.data"> -->
        <mat-form-field class="text-area">
            <mat-label>Texto para áudio</mat-label>
            <textarea matInput rows="3" disabled>{{tts.text}}</textarea>
        </mat-form-field>
        <div class="order mat-headline">
            <h6 style="margin: 10px auto 0 auto;">Ordem</h6>
            <div>{{datasource.data.indexOf(tts) + 1}}</div>
        </div>
        <mat-divider style="width: 87vw; margin: 0 auto 0 auto; left: auto;"></mat-divider>
        <table style="padding-top: 10px;">
            <td style="width: 15vw;">
                <mat-form-field>
                    <mat-label>Modelo</mat-label>
                    <input matInput value={{tts.model}} disabled>
                </mat-form-field>
            </td>
            <td style="width: .5vw;"></td>
            <div id="solid-divider"
                style="height: 30px; border-left: 1px solid rgba(0, 0, 0, 0.12); padding-right: 1.5vw;">
            </div>
            <td style="width: 15vw;">
                <mat-form-field>
                    <mat-label>Duração</mat-label>
                    <input matInput value="{{tts.duration}} segundos" disabled>
                </mat-form-field>
            </td>
            <td style="width: 55vw; text-align: end;">
                <mat-card-actions>
                    <mat-hint matTooltip="Quantidade de caracteres">{{removeSpace(tts.text)}} caracteres usados
                    </mat-hint>
                    <div class="divider"></div>
                    <a mat-icon-button href="{{ baseURL }}/{{ tts.filename }}">
                        <mat-icon matTooltip="Download"
                            aria-label="Button that displays a tooltip when focused or hovered over">
                            cloud_download</mat-icon>
                    </a>

                    <button mat-icon-button (click)="deleteItem(tts)">
                        <mat-icon matTooltip="Delete"
                            aria-label="Button that displays a tooltip when focused or hovered over">
                            delete</mat-icon>
                    </button>

                </mat-card-actions>
            </td>
        </table>
    </mat-card>
    <mat-paginator class="mat-elevation-z2" id="idPaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="20"
        showFirstLastButtons>
    </mat-paginator>
</div>