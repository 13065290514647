<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>map</mat-icon>
    <h1 class="logo-text">Análise</h1>
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">
      Notas de manutenção
    </div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab>
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button
          mat-mini-fab
          type="button"
          color="primary"
          [disabled]="dataView == 'grid'"
          (click)="changeView('grid')"
        >
          <mat-icon>apps</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button
          mat-mini-fab
          type="button"
          color="primary"
          [disabled]="dataView == 'list'"
          (click)="changeView('list')"
        >
          <mat-icon>list_view</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="report-layout">
    <div class="report-layout-col1">
      <mat-card>
        <mat-list>
          <div mat-subheader>Visualização</div>
          <mat-list-item>
            <mat-checkbox [checked]="checked">
              <div mat-line>Todos</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Em aberto</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Finalizado</div>
            </mat-checkbox>
          </mat-list-item>

          <div mat-subheader>Data</div>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mês</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Semana</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Hoje</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field appearance="fill">
              <mat-label>Escolha uma data</mat-label>
              <input matInput [matDatepicker]="picker" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </mat-list-item>

          <div mat-subheader>Mapa</div>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mapa 1</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mapa 2</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mapa 3</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mapa 4</div>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox>
              <div mat-line>Mapa 5</div>
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-card>
    </div>
    <div class="report-layout-col2">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z2"
        *ngIf="dataView == 'list'"
      >
        <ng-container matColumnDef="numero">
          <th mat-header-cell *matHeaderCellDef>Numero</th>
          <td mat-cell *matCellDef="let item">{{ item.numero }}</td>
        </ng-container>

        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">{{ item.nome }}</td>
        </ng-container>

        <ng-container matColumnDef="mapa">
          <th mat-header-cell *matHeaderCellDef>Mapa</th>
          <td mat-cell *matCellDef="let item">{{ item.mapa }}</td>
        </ng-container>

        <ng-container matColumnDef="tarefa">
          <th mat-header-cell *matHeaderCellDef>Tarefa</th>
          <td mat-cell *matCellDef="let item">{{ item.tarefa }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let item">
            {{ item.status ? "Concluído" : "Em aberto" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let item">{{ item.data }}</td>
        </ng-container>

        <ng-container matColumnDef="member">
          <th mat-header-cell *matHeaderCellDef>Member</th>
          <td mat-cell *matCellDef="let item">{{ item.member }}</td>
        </ng-container>

        <ng-container matColumnDef="fotos">
          <th mat-header-cell *matHeaderCellDef>Fotos</th>
          <td mat-cell *matCellDef="let item">{{ item.fotos }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Ações</th>
          <td mat-cell *matCellDef="let item">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="member-detail-layout-grid" *ngIf="dataView == 'grid'">
        <mat-card *ngFor="let item of dataSource">
          <div class="mat-typography">
            <h2>{{ item.nome }}</h2>
            {{ item.mapa }} - {{ item.tarefa }}
            <div class="card-imgs">
              <div *ngFor="let img of item.fotos">
                <a href="" style="display: flex; padding-right: 10px">
                  <mat-icon>image</mat-icon>
                  {{ img }}
                </a>
              </div>
            </div>
            <h5>
              <mat-icon style="vertical-align: bottom">person </mat-icon
              >{{ item.member }}
            </h5>
            <h5>{{ item.data }}</h5>
            Descrição
            <div class="card-text">
              <p>
                {{ item.descricao }}
              </p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <mat-card-content>
            <section class="example-section">
              <mat-slide-toggle
                color="accent"
                [checked]="item.status"
                [(ngModel)]="item.status"
              >
                {{ item.status ? "Concluído" : "Em aberto" }}
              </mat-slide-toggle>
            </section>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
