<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">
        Robô
      </span>
      <button mat-icon-button aria-label="Close dialog" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" (ngSubmit)="submitMap()">
    <mat-dialog-content>

      <div class="text-static" *ngIf="data.id!= undefined">
        <span>Id : </span>
        <mat-label>
          <span> {{data.name}}</span>
        </mat-label>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>{{data.nickName == undefined ? "Dê um nome ao seu robô" : data.nickName}}</mat-label>
        <input matInput type="text" formControlName="nickName"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{data.model == undefined ? 'Modelo' : data.model.model}}</mat-label>
        <mat-select [(value)]="default.data[0]" formControlName="location">

          <mat-option *ngFor="let model of dataSourceMachineModel.data" [value]="model" (click)="selectedModel(model)">
            {{model.model}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{data.location == undefined ? 'Localização' : data.location.name}}</mat-label>
        <mat-select [(value)]="default.data[0]" formControlName="location">

          <mat-option *ngFor="let location of dataSourceMapUnit.data" [value]="location" (click)="selectedMap(location)">
            {{location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button type="submit">Salvar</button>
      <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>
