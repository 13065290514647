import { Injectable } from '@angular/core';
import { BaseModel, BaseService } from '../auth/base.service';
import { Machine } from './machine.service';

import { Observable } from 'rxjs';
import { TimedTask } from './timed-tasks.service';
import { User } from '../auth/user.model';

export interface MachineScheduler extends BaseModel<number>{
  machine : Machine ;
  date : string;
  user : User;
  startTime : string;
  timedTaskList : TimedTask[];

}
@Injectable({
  providedIn: 'root'
})

export class MachineSchedulerService extends BaseService<MachineScheduler, number> {

  protected path:string = `${this.config.endpoint}` + "/hw/taskscheduler"

  public listAll(): Observable<MachineScheduler[]> {

    return this.http.get<MachineScheduler[]>(`${this.path}`);
  }

  public listByUser(): Observable<MachineScheduler[]> {

    return this.http.get<MachineScheduler[]>(`${this.path}/manual`);
  }

  public  saveManual(  machinescheduler: MachineScheduler):Observable<MachineScheduler>{

    console.log(`${this.path}` + "/uploadManual")
    return this.http.post<MachineScheduler>(`${this.path}` + "/uploadManual",machinescheduler);
  }
  public pendScheduler(): Observable<MachineScheduler[]> {

    return this.http.get<MachineScheduler[]>(`${this.path}/notExecuted`);
  }

  public todayTasksScheduled(): Observable<MachineScheduler[]> {

    return this.http.get<MachineScheduler[]>(`${this.path}/tasksToday`);
  }
}
