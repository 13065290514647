import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Tickets } from './tickets.model';
import { Voice } from './voice.model';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  list(): Observable<Tickets[]> {
    return this.http.get<Tickets[]>(`${this.config.mediaservice}/tickets`);
  }

  voices(): Observable<Voice[]> {
    return this.http.get<Voice[]>(`${this.config.mediaservice}/voice`)
  }
}
