<mat-tab-group *ngIf="intent">

  <mat-tab label="Informações Básicas">

    <div class="tab-content p-24">

      <!-- Intent -->
      <div class="title-intent">
        <mat-form-field>
          <input matInput class="title" type="text" [(ngModel)]="intent.name" (keypress)="updateAutocomplete()" readonly
            [matAutocomplete]="intent_auto" (change)="saveIntent()">

          <mat-autocomplete #intent_auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">{{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-divider></mat-divider>
      </div>

      <div class="container">

        <div class="left-1">
          <mat-list>

            <!-- Informações -->
            <h3 mat-list-item mat-subheader>Informações</h3>

            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Descrição</mat-label>
                <input matInput type="text" placeholder="Descrição" [(ngModel)]="intent.description"
                  (change)="saveIntent()">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Máximo de interações</mat-label>
                <input matInput type="number" [(ngModel)]="intent.maxFallbacks"
                  placeholder="Máximo de interações para essa intenção concluir" min="1" (change)="saveIntent()">
              </mat-form-field>
            </mat-list-item>

            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Tempo de vida</mat-label>
                <input matInput type="number" [(ngModel)]="intent.lifespan" placeholder="Tempo de vida, em milisegundos"
                  min="1000" (change)="saveIntent()">
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </div>

        <div class="left-2">
          <mat-divider></mat-divider>
          <mat-list>
            <!-- Next -->
            <h3 mat-list-item mat-subheader>Sequências</h3>
            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Intenção Sequencial</mat-label>
                <mat-select [(ngModel)]="next" (selectionChange)="setNext($event)">
                  <mat-option [value]="null">Removed</mat-option>
                  <mat-option *ngFor="let other of otherIntents" [value]="other.id">{{ other.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>

            <!-- Actions -->
            <h3 mat-list-item mat-subheader>Ação</h3>
            <div *ngFor="let action of actions">
              <mat-list-item>
                <mat-form-field appearance="outline">
                  <input matInput type="text" placeholder="Nome da ação" [(ngModel)]="action.name"
                    (change)="updateAction(action)">
                  <button matSuffix mat-icon-button (click)="removeAction(action)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </mat-list-item>

              <mat-list-item>
                <mat-form-field appearance="outline">
                  <mat-label>Max REJ</mat-label>
                  <mat-select [(ngModel)]="action.maxRej" [compareWith]="compareObjects"
                    (selectionChange)="updateAction(action)">
                    <mat-option *ngFor="let intent of otherIntents" [value]="intent">
                      {{ intent.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>
              <mat-list-item>
                <mat-form-field appearance="outline">
                  <mat-label>Max SIL</mat-label>
                  <mat-select [(ngModel)]="action.maxSil" [compareWith]="compareObjects"
                    (selectionChange)="updateAction(action)">
                    <mat-option *ngFor="let intent of otherIntents" [value]="intent">
                      {{ intent.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-list-item>

              <mat-list-item>
                <mat-form-field appearance="outline">
                  <mat-label>Tipo de Ação</mat-label>
                  <mat-select [(ngModel)]="action.type" (selectionChange)="updateAction(action)">
                    <mat-option *ngFor="let value of actionTypes" [value]="value">
                      {{ value.toString() }}
                    </mat-option>
                  </mat-select>
                  <button matSuffix mat-icon-button (click)="openActionProperties(action)">
                    <mat-icon>add</mat-icon>
                  </button>

                </mat-form-field>
              </mat-list-item>

              <mat-list-item>
                <table mat-table [dataSource]="getParameters(action)">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let parameter">{{ parameter.name }}</td>
                    <td mat-footer-cell *matFooterCellDef colspan="4">
                      <button mat-icon-button matTooltip="Adicionar novo parâmetro à ação."
                        (click)="createParameter(action)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="required">
                    <th class="fit-content" mat-header-cell *matHeaderCellDef>Obrigatório</th>
                    <td class="fit-content" mat-cell *matCellDef="let parameter">
                      {{ parameter.required }}</td>
                  </ng-container>

                  <ng-container matColumnDef="prompts">
                    <th class="fit-content" mat-header-cell *matHeaderCellDef>Perguntas</th>
                    <td class="fit-content" mat-cell *matCellDef="let parameter">
                      <button mat-icon-button (click)="createPrompt(parameter)">
                        <mat-icon>add</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="edit">
                    <th class="fit-content" mat-header-cell *matHeaderCellDef>Editar</th>
                    <td class="fit-content" mat-cell *matCellDef="let parameter">
                      <button mat-icon-button (click)="createParameter(action, parameter)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="remove">
                    <th class="fit-content" mat-header-cell *matHeaderCellDef>Remover</th>
                    <td class="fit-content" mat-cell *matCellDef="let parameter">
                      <button mat-icon-button (click)="removeParameter(action, parameter)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  <tr mat-footer-row *matFooterRowDef="['name']"></tr>
                </table>
              </mat-list-item>
            </div>
            <mat-list-item>
              <button class="last" mat-button type="button" (click)="createAction()">
                <span> Adicionar Ação </span>
                <mat-icon>add</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
        </div>


        <div class="right-1">
          <mat-divider class="divider"></mat-divider>
          <mat-list *ngIf="intent && intent.id">
            <!-- Contextos -->

            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Contextos de entrada</mat-label>
                <mat-select [(ngModel)]="inContexts" [compareWith]="compareContext" multiple
                  (selectionChange)=saveContexts(true)>
                  <mat-option *ngFor="let context of allContexts" [value]="context">
                    {{ context.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>

            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Contextos de saída</mat-label>
                <mat-select [(ngModel)]="outContexts" [compareWith]="compareContext" multiple
                  (selectionChange)=saveContexts(false)>
                  <mat-option *ngFor="let context of allContexts" [value]="context">
                    {{ context.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-list-item>

            <mat-list-item>
              <button class="last" mat-button type="button" (click)="createContext()">
                <span> Novo Contexto </span>
                <mat-icon>add</mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
        </div>

        <div class="right-2">
          <mat-divider></mat-divider>
          <!-- Forwarders -->
          <mat-list>
            <h3 mat-list-item mat-subheader *ngIf="actions.length > 0">Intenções Sequenciais</h3>
            <mat-list-item *ngIf="actions.length > 0">
              <table mat-table [dataSource]="intentForwarders">
                <ng-container matColumnDef="intent">
                  <th mat-header-cell *matHeaderCellDef>Intenção</th>
                  <td mat-cell *matCellDef="let forwarder">{{ forwarder.destination.name }}</td>
                  <td mat-footer-cell *matFooterCellDef colspan="4">
                    <button mat-icon-button matTooltip="Adicionar nova sequência." (click)="createForwarder()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="trigger">
                  <th class="fit-content" mat-header-cell *matHeaderCellDef>Parâmetro</th>
                  <td class="fit-content" mat-cell *matCellDef="let forwarder">
                    {{ forwarder.trigger.name }}</td>
                </ng-container>

                <ng-container matColumnDef="answer">
                  <th class="fit-content" mat-header-cell *matHeaderCellDef>Resposta</th>
                  <td class="fit-content" mat-cell *matCellDef="let forwarder">{{ forwarder.answer }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                  <th class="fit-content" mat-header-cell *matHeaderCellDef>Remover</th>
                  <td class="fit-content" mat-cell *matCellDef="let forwarder">
                    <button mat-icon-button (click)="removeForwarder(forwarder)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="forwarderColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: forwarderColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['intent']"></tr>
              </table>
            </mat-list-item>

            <mat-list-item *ngFor="let response of restResponses">
              <mat-form-field appearance="outline">
                <input matInput type="text" [(ngModel)]="response.url" (change)="updateRestResponse(response)">
                <button matSuffix mat-icon-button (click)="removeRestResponse(response)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline">
                <mat-label>Nova Integração</mat-label>
                <input matInput type="text" placeholder="Nova integração" [(ngModel)]="newRestResponse">
                <button matSuffix mat-icon-button (click)="addRestResponse()">
                  <mat-icon>add</mat-icon>
                </button>
                <mat-hint>Uma url onde reside um serviço REST capaz de processar uma conversa.
                </mat-hint>
              </mat-form-field>
            </mat-list-item>

            <br>
            <mat-divider></mat-divider>
          </mat-list>

          <div class="intent-button">
            <button class="intent-button__item" mat-list-item (click)="testIntent()">
              Testar Intenção
            </button>
            <button class="intent-button__item" mat-list-item (click)="deleteIntent()">
              Remover Intenção
            </button>
          </div>

        </div>
      </div>


    </div>

  </mat-tab>

  <mat-tab label="Frases de treinamento">

    <div class="tab-content p-24">

      <div class="block__element" [ngClass]="{ 'block__element--show-less' : showLess }">
        <mat-list>
          <alabia-corpus [intent]="intent"></alabia-corpus>
        </mat-list>
        <div class="show-button" *ngIf="corpusUtteranceService.listLength > 5">
          <div class="continue">...</div>
          <button class="last" mat-button type="button" (click)="showLess = !showLess">
            <span> Ver {{ showLess ? 'mais' : 'menos' }} </span>
            <mat-icon>{{ showLess ? 'add' : 'remove' }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>Respostas</ng-template>
    <div class="tab-content">
      <mat-tab-group [disableRipple]="true" (selectedTabChange)="changeTab($event)">

        <mat-tab *ngFor="let tab of tabResponse; let i = index" [label]="tab">

          <button class="alabia-generic-response-add" mat-raised-button (click)="openCreateResponse(tabActive)">
            Nova Resposta
          </button>

          <div class="alabia-generic-response" *ngFor="let response of responses | responseFilter:tab">
            <main-response-view [response]="response" (update)="updateResponse($event)"
              (delete)="deleteResponse($event)">
            </main-response-view>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-tab>
</mat-tab-group>