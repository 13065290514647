import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage.component';

const routes: Routes = [
  {
    path: '**',
    component: HomepageComponent,
    children: []
  }
];

@NgModule({
  declarations: [HomepageComponent],
  imports: [
    RouterModule.forChild(routes),
    MatIconModule,
    CommonModule
  ]
})
export class HomepageModule { }
