<mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}">

  <!-- <ng-container matColumnDef="checkbox">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let skill">
      <mat-checkbox [(ngModel)]="checkboxes[skill.id]" (ngModelChange)="onSelectedChange(skill.id)"
        (click)="$event.stopPropagation()">
      </mat-checkbox>
    </mat-cell>
  </ng-container> -->

  <!-- Skill Name Column -->
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Skill </mat-header-cell>
    <mat-cell *matCellDef="let skill"> {{skill.name}}</mat-cell>
  </ng-container>

  <!-- Buttons Column -->
  <ng-container matColumnDef="buttons">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let skill">
      <div fxFlex="row" fxLayoutAlign="end center">
        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More" (click)="$event.stopPropagation();">
          <mat-icon class="secondary-text">more_vert</mat-icon>
        </button>

        <mat-menu #moreMenu="matMenu">
          <button mat-menu-item aria-label="remove" (click)="deleteSkill(skill)">
            <mat-icon>delete</mat-icon>
            <span>Remover</span>
          </button>
        </mat-menu>
      </div>

    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let skill columns: displayedColumns;" class="contact" (click)="editSkill(skill)" matRipple>
  </mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
