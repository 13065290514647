import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MachineMap } from 'onevoice';
import * as uuid from 'uuid';

@Component({
  selector: 'main-edit-maps',
  templateUrl: './edit-maps.component.html',
  styleUrls: ['./edit-maps.component.scss']
})
export class EditMapsComponent {
  public form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MachineMap,
    private reference: MatDialogRef<EditMapsComponent, MachineMap>,
  ) {
    this.form = new FormGroup({
      "title": new FormControl(data.title || "", [Validators.required]),
      "uniqueId": new FormControl(data.uniqueId || uuid.v4(), [Validators.required]),
    }, []);
  }

  get title(): string {
    return (this.data.id !== undefined) ? "Editar Mapa" : "Criar Mapa";
  }

  public submitMap(): void {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }
}
