import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleUser } from 'onevoice';
import * as alib from '../audio';
import { getSampleLine } from '../data';

@Component({
  selector: 'alabia-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  private state?: alib.RecordingState;
  public file?: File;
  public recording: Boolean = false;
  private audio: HTMLAudioElement = new Audio();
  public line: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: SimpleUser) { }

  ngOnInit() {
    this.line = getSampleLine();
  }

  startMicrofone() {
    alib.start({
      channels: 1,
      sampleRate: 16000,
    }).then(
      state => {
        this.state = state;
        this.recording = true;
      },
      error => console.log(error));
  }

  stopMicrofone() {
    if (!this.state) { return; }
    alib.stop(this.state).then(file => {
      this.file = file;
      this.recording = false;
    });
  }

  toggleMicrofone() {
    if (this.recording) {
      this.stopMicrofone();
    } else {
      this.startMicrofone();
    }
  }

  listen() {
    if (!this.audio.paused) {
      this.audio.pause();
      return;
    }
    if (!this.file) { return; }
    let reader = new FileReader();
    reader.onloadend = (event) => {
      if (!reader.result) { return; }
      let dataURL = reader.result.toString();
      this.audio.src = dataURL;
      this.audio.play();
    }
    reader.readAsDataURL(this.file);
  }
}
