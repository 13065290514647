import { Component, OnInit, Input } from '@angular/core';
import { FacebookHook } from 'app/alabia/api/facebook-hook.service';
import { Bot } from 'onevoice';
import { STTResponseService, STTResponse } from 'app/alabia/api/sttresponse.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'alabia-medias',
    templateUrl: './medias.component.html',
    styleUrls: ['./medias.component.scss']
})
export class MediasComponent implements OnInit {
    @Input()
    hook?: FacebookHook;

    @Input()
    bot?: Bot;

    responses: STTResponse[] = [];
    mediaUrl: string = `${environment.sttService}/recorded/`;

    constructor(
        private sttResponseService: STTResponseService,
        public snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.sttResponseService.list().subscribe(responses => {
            this.responses = responses.reverse();
        });
    }

    message(content: string) {
        this.snackBar.open(content, 'entendi', {
            duration: 10000
        });
    }

    playSound(filename: string) {
        let url: string = `${this.mediaUrl}${filename}`;

        let audio = new Audio();
        audio.src = url;

        audio.load();
        audio.play().then(
            _ => { },
            error => {
                this.message(
                    'Não foi possível tocar o áudio associado com essa mensagem'
                );
            }
        );
    }
}
