<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>map</mat-icon>
    <h1 class="logo-text">Equipe</h1>
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Membros</div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createUser()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab type="button" color="primary" (click)="changeView('grid')" [disabled]="dataView == 'grid'">
          <mat-icon>apps</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab type="button" color="primary" (click)="changeView('list')" [disabled]="dataView == 'list'">
          <mat-icon>list_view</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="map-layout" *ngIf="dataView == 'list'">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let item">{{ item.details.first_name + " " + item.details.last_name }}</td>
      </ng-container>

      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef>Organização</th>
        <td mat-cell *matCellDef="let item">{{ item.organization.name }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let item">{{ item.status }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let item">{{ item.email }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Telefone</th>
        <td mat-cell *matCellDef="let item">{{ item.details.phone }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let item">
          <button mat-stroked-button type="button" (click)="selectMember(item)">
            Mais detalhes
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="member-grid-layout" *ngIf="dataView == 'grid'">
    <mat-card *ngFor="let item of dataSource.data">
      <mat-card-actions style="margin-bottom: -10px" align="end">
        <!-- <mat-icon>more_horiz</mat-icon> -->

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item type="button" (click)="createUser(item)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button mat-menu-item type="button" (click)="deleteUser(item)">
            <mat-icon>clear</mat-icon>
            <span>Excluir</span>
          </button>
        </mat-menu>


      </mat-card-actions>
      <mat-card-content align="center">
        <div>

          <span [ngStyle]="{ 'background-color': '#F67EA5' }" class="grid-members-img">
          </span>
        </div>
        <div class="mat-subheading-2">
          {{ item.details.first_name + " " + item.details.last_name }}
        </div>
        <div class="mat-subheading-1">
          {{ item.organization.name }}
        </div>
        <div class="mat-body-1">
          {{ item.status }}
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions style="text-align: center">
        <button mat-flat-button color="accent" (click)="selectMember(item)">
          Visualizar
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="member-detail-layout" *ngIf="dataView == 'detail'">
    <div class="member-detail-layout-col1">
      <div class="col1-menu">
        <mat-card>
          <mat-card-title style="color: rgba(0, 0, 0, 0.54); font-size: 14px;">
            <mat-icon style="padding-right: 10px;">
              groups
            </mat-icon>
            Membros
          </mat-card-title>
        </mat-card>
      </div>
      <div class="col1-members">
        <mat-card>
          <div class="members-section">
            <div class="members" *ngFor="let item of dataSource.data" [id]="selectedMember == item ? 'membro' : null"
              (click)="selectMember(item)">
              <div class="members-icon">
                <button mat-icon-button>
                  <mat-icon style="color: rgba(0, 0, 0, 0.54)">more_horiz</mat-icon>
                </button>
              </div>
              <div class="members-flex">
                <div>
                  <span [ngStyle]="{ 'background-color': '#F67EA5' }" class="members-img">
                  </span>
                </div>
                <div>
                  <h3>{{ item.details.first_name + " " + item.details.last_name }}</h3>
                  <h5>{{ item.organization.name }}</h5>
                  <!-- [ngStyle]="{ -->
                  <!-- color: item.status == 'Online' ? '#31e159' : '#bd0b1c' -->
                  <!-- }" -->
                  <h5>
                    {{ item.status }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="member-detail-layout-col2">
      <div class="col2-menu">
        <mat-tab-group color="accent" (selectedTabChange)="changeTab($event)" style="background-color: #fff">
          <mat-tab label="atividades">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">assignment</mat-icon>
              Atividades
            </ng-template>
          </mat-tab>

          <mat-tab label="historico">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">history</mat-icon>
              Histórico
            </ng-template>
          </mat-tab>
          <mat-tab label="calendario">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">event</mat-icon>
              Calendário
            </ng-template>
          </mat-tab>
          <mat-tab label="desempenho">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">assessment</mat-icon>
              Desempenho
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <div class="task-body mat-elevation-z2" *ngIf="tabView == 'atividades'">
          <div class="task-step">
            <mat-stepper orientation="vertical" color="accent" *ngFor="let execution of dataSourceExecutions"#stepper>
              <mat-step label="Tarefa {{execution.id}}" *ngIf="execution.operator == selectedMember?.details?.first_name" [completed]="false" >
                <div class="typography">
                  <h3> {{execution.operator}} </h3>
                </div>
                <div class="step-task-layout">
                  <div class="task-col1">
                    {{execution.trigger}}
                  </div>
                  <div class="task-col2">
                    Descrição
                    <!-- <h5>Descrição</h5> -->
                    <table>
                      <tr>
                        <td>área</td>
                        <td style="text-align: end">{{execution.area.covered}}</td>
                      </tr>
                      <tr>
                        <td>temp. estimado</td>
                        <td style="text-align: end">
                          {{ (execution.duration / 60).toFixed(0) | number }} min
                          {{ (execution.duration % 60) }} sec</td>
                      </tr>
                      <tr>
                        <td>tamanho</td>
                        <td style="text-align: end">{{execution.area.covered}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="task-col3">
                    <div class="progrss-col3">
                      <mat-progress-spinner diameter="80" color="accent" mode="determinate" value="{{percentProgress(execution)}}">
                      </mat-progress-spinner>
                      <span style="
                          text-align: center;
                          top: -59px;
                          font-size: 22px;
                          position: relative;
                          left: 20px;
                        ">{{percentProgress(execution)}}</span>
                    </div>
                  </div>
                </div>
                <div style="text-align: end">
                  <button mat-raised-button matStepperNext>Next</button>
                </div>
              </mat-step>

            </mat-stepper>
          </div>
        </div>
        <div class="history-body mat-elevation-z2" *ngIf="tabView == 'historico'">
          <table mat-table [dataSource]="dataSourceTask" class="mat-elevation-z2" >
            <ng-container matColumnDef="num">
              <th mat-header-cell *matHeaderCellDef>Numero</th>
              <td mat-cell *matCellDef="let item">{{ item.id }}</td>
            </ng-container>

            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef>Nome</th>
              <td mat-cell *matCellDef="let item">{{ item.task.name.replace("execute_task_","") }}</td>
            </ng-container>



            <ng-container matColumnDef="mapa">
              <th mat-header-cell *matHeaderCellDef>Mapa</th>
              <td mat-cell *matCellDef="let item">{{ item.task.map.title }}</td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let item">{{ item.end == undefined ? "Pendente" :"Concluido" }}</td>
            </ng-container>

            <ng-container matColumnDef="duracao">
              <th mat-header-cell *matHeaderCellDef>Duração</th>
              <td mat-cell *matCellDef="let item">{{ item.duration }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Ações</th>
              <td mat-cell *matCellDef="let item">
                <button mat-stroked-button type="button" (click)="changeView('detail')">
                  Visualizar
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsTask"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTask"></tr>
          </table>
        </div>
        <div class="calendar-body mat-elevation-z2" *ngIf="tabView == 'calendario'">
          calendario
        </div>
        <div class="report-body mat-elevation-z2" *ngIf="tabView == 'desempenho'">
          analise
        </div>
      </div>
    </div>
    <div class="member-detail-layout-col3">
      <mat-card class="col3-details" *ngIf="selectedMember">
        <mat-card-actions style="margin-bottom: -10px; padding: 16px" align="end">
          <mat-icon>edit</mat-icon>
        </mat-card-actions>
        <mat-card-content align="center">
          <div>
            <!-- [ngStyle]="{ 'background-color': selectedMember?.img }" -->
            <span class="col3-details-img">
            </span>
          </div>
          <div class="mat-title">{{ selectedMember.details.first_name + " " + selectedMember.details.last_name}}</div>
          <div class="mat-subheading-1">{{ selectedMember.organization.name }}</div>
          <div class="mat-body-1">{{ selectedMember.status }}</div>
        </mat-card-content>
        <div class="inf-member mat-typography">
          <h4>
            <mat-icon style="vertical-align: bottom">phone</mat-icon>
            {{ selectedMember.details.phone }}
          </h4>
          <h4>
            <mat-icon style="vertical-align: bottom">email</mat-icon>
            {{ selectedMember.details.email }}
          </h4>
          <h4>
            <mat-icon style="vertical-align: bottom">location_on</mat-icon>Brasília
          </h4>
          <h4>
            <mat-icon style="vertical-align: bottom">cake</mat-icon>aniversario
          </h4>
        </div>
        <mat-divider></mat-divider>
        <div class="day-progress">
          <h3>Progresso diário</h3>
          <ul>
            <li>
              <mat-card-title class="mat-tipography">
                <h4 style="text-align: start">Tarefas</h4>
                <h4>{{dataSourceTask.length}}</h4>
              </mat-card-title>
              <mat-progress-bar mode="determinate" color="accent" value="100"></mat-progress-bar>
            </li>
            <li>
              <mat-card-title class="mat-tipography">
                <h4 style="text-align: start">Área</h4>
                <h4>{{todayArea}} m<sup>2</sup></h4>
              </mat-card-title>
              <mat-progress-bar mode="determinate" color="accent" value="100"></mat-progress-bar>
            </li>
            <li>
              <mat-card-title class="mat-tipography">
                <h4 style="text-align: start">Tempo</h4>
                <h4>{{((todayTime/360)).toFixed(2)}} h</h4>
              </mat-card-title>
              <mat-progress-bar mode="determinate" color="accent" value="100"></mat-progress-bar>
            </li>
          </ul>
        </div>
      </mat-card>
    </div>
  </div>
</div>
