import { NgModule } from '@angular/core';
import { ResponseFilterPipe } from './responseFilter.pipe';
import { ReversePipe } from './reverse.pipe';

@NgModule({
  declarations: [
    ReversePipe,
    ResponseFilterPipe
  ],
  exports: [
    ReversePipe,
    ResponseFilterPipe
  ]
})
export class PipesModule {
}
