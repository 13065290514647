<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>map</mat-icon>
    <h1 class="logo-text">Mapas e Tarefas</h1>
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Mapas</div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createMap()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createMap(mapSelected)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>


      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>
      <div class="actions-button">
        <button mat-mini-fab color="primary" [disabled]="dataView == 'list'">
          <mat-icon>list_view</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="map-layout">
    <div class="map-layout-col1">
      <mat-card style="height: auto; padding-bottom: 15px">

        <h2 style="text-align: center" *ngIf="mapSelected">
          Mapa {{ dataSourceMaps.data.indexOf(mapSelected) + 1}} <br />
          {{ mapSelected.title }}

        </h2>
        <div class="map-img mat-elevation-z2" *ngIf="mapSelected?.path !=null">
          <img src="{{mapSelected?.path}}" class="tag-img-map" />
        </div>
        <div class="map-expansion">
          <mat-accordion>
            <mat-expansion-panel [expanded]="mapSelected?.path ==null">
              <mat-expansion-panel-header expandedHeight="auto">
                <mat-panel-title> Detalhes </mat-panel-title>
              </mat-expansion-panel-header>
              <table *ngIf="mapSelected">
                <tr>
                  <td>Nome</td>
                  <td>{{ mapSelected.title }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Tarefas associadas: </td>

                  <td >
                  <tr *ngFor="let task of taskByMapSelected">
                    {{task.name}}
                  </tr>
                </td>
                </tr>
                <tr>
                </tr>
              </table>
            </mat-expansion-panel>

            <!-- <mat-expansion-panel expandedHeight="auto">
              <mat-expansion-panel-header>
                <mat-panel-title> Tarefas </mat-panel-title>
              </mat-expansion-panel-header>
              <td>
                <tr>Tarefas associadas</tr>
              </td>
              <td>
                <tr></tr>

              </td>
              <td>
                <tr>{{taskByMapSelected.length}} </tr>

              </td>
            </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </mat-card>
    </div>
    <div class="map-layout-col2">
      <mat-progress-bar *ngIf="progressBarMaps" mode="indeterminate"></mat-progress-bar>

      <table mat-table [dataSource]="dataSourceMaps" class="mat-elevation-z2">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Nº</th>
          <td mat-cell *matCellDef="let item">
            {{ dataSourceMaps.data.indexOf(item) + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">{{ item.title }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Localização</th>
          <td mat-cell *matCellDef="let item">{{ item.location == undefined ? 'Não definido' : item.location.title }}</td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMap"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMap" (click)="selectedMapRow(row)" [ngStyle]="{
            'background-color':
              mapSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
          }"></tr>
      </table>
      <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
        [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Robos</div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createMachine()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createMachine(machineSelected)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>apps</mat-icon>
        </button>
      </div>

      <!-- <div class="actions-button">
        <button mat-mini-fab color="primary" [disabled]="dataView == 'list'">
          <mat-icon>list_view</mat-icon>
        </button>
      </div> -->
    </div>
  </div>

  <div class="map-layout">
    <div class="map-layout-col1">
      <mat-card style="height: auto; padding-bottom: 15px">
        <h2 style="text-align: center" *ngIf="machineSelected">
          Robo {{ dataSourceMachines.data.indexOf(machineSelected) +1 }} <br />
          {{ machineSelected.model.model }}

          <!-- mapSelected.nome + " - " + mapSelected.andar -->
        </h2>
        <div class="map-img mat-elevation-z2" *ngIf="machineModelAsset != undefined">
          <img src="{{machineModelAsset?.imgPath}}" class="tag-img-map"
            style="transform: rotate(0deg);width: 200px;height: 200px;" />
        </div>
        <div class="map-expansion">
          <mat-accordion>
            <mat-expansion-panel [expanded]="machineModelAsset == undefined">
              <mat-expansion-panel-header expandedHeight="auto">
                <mat-panel-title> Detalhes </mat-panel-title>
              </mat-expansion-panel-header>
              <table *ngIf="machineSelected">
                <tr>
                  <td>Nome</td>
                  <td>{{ machineSelected.nickName }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Fabricante</td>
                  <td>{{ machineSelected.model.maker }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Modelo</td>
                  <td>{{ machineSelected.model.model }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Localização</td>
                  <td>{{ machineSelected.location ? machineSelected.location.name : "Não definido"}}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Ultima execução</td>
                  <td>{{ lastExec === '0' ? "Sem registro" : lastExec }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
              </table>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
      </mat-card>
    </div>
    <div class="map-layout-col2">
      <mat-progress-bar *ngIf="progressBarMachines" mode="indeterminate"></mat-progress-bar>

      <table mat-table [dataSource]="dataSourceMachines" class="mat-elevation-z2">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Nº</th>
          <td mat-cell *matCellDef="let item">
            {{ dataSourceMachines.data.indexOf(item) + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Identificador</th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef>Modelo</th>
          <td mat-cell *matCellDef="let item">{{ item.model.model }}</td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef>Localização</th>
          <td mat-cell *matCellDef="let item">
            {{item.location == undefined ? "Não definido" : item.location.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="nickname">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">
            {{item.nickName == undefined ? "Não definido" : item.nickName}}
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsMachinie"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMachinie" (click)="selectedMachineRow(row)" [ngStyle]="{
            'background-color':
              machineSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
          }"></tr>
      </table>
      <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
        [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Unidade</div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createMapUnit()">
          <mat-icon>add</mat-icon>
        </button>
        <div class="actions-button">
          <button mat-mini-fab type="button" (click)="updateMapUnit(unitSelected)">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary" [disabled]="dataView == 'list'">
          <mat-icon>list_view</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="map-layout">
    <div class="map-layout-col1">
      <mat-card style="height: auto; padding-bottom: 15px">
        <h2 style="text-align: center" *ngIf="unitSelected">
          Unidade {{ dataSourceMapUnit.data.indexOf(unitSelected) +1 }} <br />
          {{ unitSelected.name }}

        </h2>
        <!-- <div class="map-img mat-elevation-z2"></div> -->
        <div class="map-expansion">
          <mat-accordion>
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header expandedHeight="auto">
                <mat-panel-title> Detalhes </mat-panel-title>
              </mat-expansion-panel-header>
              <table *ngIf="unitSelected">
                <tr>
                  <td>Nome</td>
                  <td>{{ unitSelected.name }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td>Descrição</td>
                  <td>{{ unitSelected.description }}</td>
                  <!-- <td>mapSelected.nome</td> -->
                </tr>
                <tr>
                  <td style="display: block;">Maquinas</td>

                  <td>
                <tr *ngFor="let machine of machinesByLocation">
                  {{machine.nickName}}
                  <td>
                    {{" - " + machine.model.model}}
                  </td>
                </tr>
                </td>
                </tr>

              </table>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
      </mat-card>
    </div>
    <div class="map-layout-col2">
      <mat-progress-bar *ngIf="progressBarUnits" mode="indeterminate"></mat-progress-bar>

      <table mat-table [dataSource]="dataSourceMapUnit" class="mat-elevation-z2">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Nº</th>
          <td mat-cell *matCellDef="let item">
            {{ dataSourceMapUnit.data.indexOf(item) + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMapUnit"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMapUnit" (click)="selectedMapUnitRow(row)" [ngStyle]="{
            'background-color':
              unitSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
          }"></tr>
      </table>
      <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
        [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Tarefas</div>
    <div id="mat-headline-actions">
      <div class="actions-button">
        <button mat-mini-fab type="button" (click)="createTask()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>filter_list</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary">
          <mat-icon>apps</mat-icon>
        </button>
      </div>

      <div class="actions-button">
        <button mat-mini-fab color="primary" [disabled]="dataView == 'list'">
          <mat-icon>list_view</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="task-layout">
    <div class="task-layout-col1">
      <mat-card style="height: auto; padding-bottom: 15px">
        <h2 style="text-align: center">
          <button mat-mini-fab color="#fff">
            <mat-icon> meeting_room </mat-icon>
          </button>
          {{ taskSelected ? taskSelected.name.replace("execute_task_", "") : ''}}
        </h2>
        <div class="task-img">
          <br />
          <h3>Detalhes</h3>
          <table>
            <tr>
              <td>Mapa</td>
              <td *ngIf="taskSelected != undefined">{{ taskSelected.map.title }}</td>
            </tr>

          </table>
        </div>
        <div class="task-expansion">
          <mat-accordion>
            <mat-expansion-panel *ngIf="taskSelected != undefined" expanded="true">
              <mat-expansion-panel-header expandedHeight="auto">
                <mat-panel-title> Itens da tarefa </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list>
                <table *ngIf="unitSelected">
                  <tr>
                    <td>Nome</td>
                    <td>{{ taskSelected.name.replace("execute_task_", "") }}</td>
                  </tr>
                  <tr>
                    <td>Descrição</td>
                    <td>{{ taskSelected.description }}</td>
                  </tr>
                  <tr>
                    <td>Unidade </td>
                    <td>{{ taskSelected.map.unit.name }}</td>
                  </tr>
                  <tr>
                    <td>Mapa</td>
                    <td>{{ taskSelected.map.title }}</td>
                  </tr>
                  <tr>
                    <td>Tipo</td>
                    <td>{{ taskSelected.type == undefined ? "Auto" : "Auto"}}</td>
                  </tr>


                </table>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </mat-card>
    </div>

    <div class="task-layout-col2">
      <mat-progress-bar *ngIf="progressBarTasks" mode="indeterminate"></mat-progress-bar>

      <table mat-table [dataSource]="dataSourceTaskList" class="mat-elevation-z2">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Nº</th>
          <td mat-cell *matCellDef="let item">
            {{ dataSourceTaskList.data.indexOf(item) + 1}}
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let item">{{ item.name.replace("execute_task_", "") }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMapUnit"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMapUnit" (click)="selectedTaskRow(row)" [ngStyle]="{
            'background-color':
            taskSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
          }"></tr>
      </table>
      <mat-paginator #paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
        [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
