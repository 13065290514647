import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChannelType, GenericResponse, MediaType, ResponseType } from 'onevoice';
import { Button, CardJson } from './../../../../models/card-json';

@Component({
  selector: 'alabia-response-card',
  templateUrl: './response-card.component.html',
  styleUrls: ['./response-card.component.scss']
})
export class ResponseCardComponent implements OnInit {
  @Input() response: GenericResponse = {
    type: ResponseType.TEXT,
    mediaType: MediaType.UNKNOWN,
    channel: ChannelType.CHAT,
    order: 0,
    content: "",
  };

  @Input() add = false;
  @Output() saveResponse = new EventEmitter();
  @Output() updateResponse = new EventEmitter();
  @Output() deleteResponse = new EventEmitter();

  public buttons: Button[] = [];
  public textOfCard = '';
  public card?: CardJson;

  public ResponseType = ResponseType;

  ngOnInit(): void {
    if (this.response.type === ResponseType.JSON) {
      this.verifyCardStructure(this.response.content);
    }
  }

  private verifyCardStructure(responseContent: string): void {
    try {
      
      const isTemplate = responseContent.includes("{{#") || responseContent.includes("{{^");
      const isCard = responseContent.includes('"template_type":"button"') && !isTemplate;
      if (isCard) {
        this.card = JSON.parse(responseContent);
  
        if (this.card) {
          this.buttons = this.card.attachment.payload.buttons;
          this.textOfCard = this.card.attachment.payload.text;
        }
      }
    } catch (error) {
      
    }
  }

  public buildCardJson(): CardJson {
    return {
      attachment: {
        type: 'template',
        payload: {
          template_type: 'button',
          text: this.textOfCard,
          buttons: this.buttons,
        },
      },
    };
  }

  public removeButton(index: number): void {
    this.buttons.splice(index, 1);
    this.update();
  }

  public addButton(tipo: string, conteudo: string, acao: string): void {
    if (tipo && conteudo && acao) {
      const button: Button = { type: tipo, title: conteudo };
      const option = tipo === 'postback' ? 'payload' : 'url';
      button[option] = acao;

      this.buttons.push(button);
      this.update();
    }
  }

  public editButton(tipo: string, conteudo: string, acao: string, index: number): void {
    if (tipo && conteudo && acao) {
      const button: Button = { type: tipo, title: conteudo };
      const option = tipo === 'postback' ? 'payload' : 'url';
      button[option] = acao;

      this.buttons.splice(index, 1, button);
      this.update();
    }
  }

  public update(): void {
    this.card = this.buildCardJson();
    this.response.content = JSON.stringify(this.card);
    this.updateResponse.emit(this.response);
  }

  public delete(response: GenericResponse): void {
    this.deleteResponse.emit(response);
  }

  public output(): void {
    this.saveResponse.emit(this.card);
  }
}