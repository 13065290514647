<div class="box">
  <mat-form-field appearance="outline">
    <mat-label>TEXTO DO CARD</mat-label>
    <textarea name="message" [(ngModel)]="textOfCard" autosize #textCard (change)="update()" matInput></textarea>
  </mat-form-field>

  <fieldset class="box__button" *ngFor="let button of buttons; let i = index">
    <div class="box__button-type">
      <select (change)="editButton(tipoEdit.value, conteudoEdit.value, acaoEdit.value, i)"
        class="box__button-type-select" [value]="button.type" #tipoEdit>
        <option disabled selected value> -- tipo -- </option>
        <option value="postback">postback</option>
        <option value="web_url">url</option>
      </select>
      <input placeholder="CONTEÚDO" class="text-input box__button-type-action" name="message"
        (change)="editButton(tipoEdit.value, conteudoEdit.value, acaoEdit.value, i)" #conteudoEdit matInput
        [value]="button.title">
    </div>
    <input placeholder="AÇÃO OU URL" class="text-input" name="message"
      (change)="editButton(tipoEdit.value, conteudoEdit.value, acaoEdit.value, i)" #acaoEdit matInput
      [value]="button.type === 'web_url' ? button.url : button.payload">

    <button matSuffix mat-icon-button class="box__button-addOrRemove box__button-addOrRemove--add"
      (click)="removeButton(i)">
      <mat-icon>close</mat-icon>
    </button>
  </fieldset>

  <fieldset class="box__button">
    <legend>Novo botão</legend>

    <div class="box__button-type">
      <select class="box__button-type-select" #tipo>
        <option disabled selected value> -- tipo -- </option>
        <option value="postback">postback</option>
        <option value="web_url">url</option>
      </select>
      <input placeholder="conteúdo" class="text-input box__button-type-action" name="message" (change)="buildCardJson()"
        matInput #conteudo>
    </div>
    <input placeholder="ação ou url" #acao class="text-input" name="message" (change)="buildCardJson()" matInput>

    <button matSuffix mat-icon-button class="box__button-addOrRemove box__button-addOrRemove--add"
      (click)="addButton(tipo.value, conteudo.value, acao.value)">
      <mat-icon>add</mat-icon>
    </button>
  </fieldset>
</div>
<button class="save" [ngClass]="{'disabled' : !buttons.length} " mat-button
  [disabled]="!buttons.length || !textOfCard.length" (click)="add ? output() : delete(response)">
  {{ add ? 'ADICIONAR' : 'REMOVER' }}
</button>