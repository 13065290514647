<h1 mat-dialog-title>Trigger Customer</h1>

<mat-dialog-content>
    <mat-form-field *ngFor="let trigger of triggers" appearance="outline">
        <mat-label>{{ trigger.status }}</mat-label>
        <mat-select [(ngModel)]="trigger.intent.id">
            <mat-option *ngFor="let intent of intentList" [value]="intent.id">{{ intent.name }}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="submit()">Atualizar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>