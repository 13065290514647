import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { Condition } from './condition.model';

@Injectable({
  providedIn: 'root'
})
export class ConditionService extends OwnableService<Condition> {
  protected path: string = `${this.config.endpoint}/conditions`;
}
