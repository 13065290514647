<div class="form" *ngIf="isShowing" [@formAnimation]>
  <h3 class="form--title">Selecione seu plano</h3>

  <div class="container">
    <div class="card" *ngFor="let plan of plans">
      <input type="radio" name="num_employees" id="1_10_employees" class="card-input" />
      <label for="1_10_employees" class="card-label">
        <div class="card-label-header">
          <p class="card-label-header--plan-name">{{ plan.name | titlecase }}</p>
          <p class="card-label-header--plan-price">{{ plan.price | uppercase }}</p>
          <p class="card-label-header--plan-payment-period">{{ plan.billing_time | titlecase }}</p>
        </div>
      </label>
      <button mat-raised-button class="card-plan-select">Escolher</button>
    </div>
  </div>
  <div class="button-container">
    <button mat-raised-button class="button button__back" (click)="screenOutputHandler(4, 'backward')">Voltar</button>
  </div>
</div>