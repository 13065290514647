import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Organization } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url: string = `${this.config.endpoint}/auth/organization`;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: ApiConfiguration,
  ) { }

  public create(data: Organization): Observable<Organization> {
    return this.http.put<Organization>(this.url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
