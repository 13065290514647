<div class="alabia-generic-wrapper">
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="title">
            <th class="asset-title" mat-header-cell *matHeaderCellDef>Nome</th>
            <td class="asset-title" mat-cell *matCellDef="let item">
                {{ item.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th class="asset-type" mat-header-cell *matHeaderCellDef>Tipo</th>
            <td class="asset-type" mat-cell *matCellDef="let item">
                {{ item.type.title }}
            </td>
        </ng-container>


        <ng-container matColumnDef="actions">
            <th class="asset-actions" mat-header-cell *matHeaderCellDef>Ações</th>
            <td class="asset-actions" mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="editItem(item)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let item; columns: columns"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    <button class="alabia-fab " mat-fab (click)="editItem()">
        <mat-icon>add</mat-icon>
    </button>
</div>