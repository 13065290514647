import { HttpParams } from '@angular/common/http';
import { Component, OnChanges, OnInit } from '@angular/core';
import { sign } from 'crypto';
import { AuthenticationService, MachineExecution, MachineExecutionService, MachineTask, MachineScheduler, TimedTask, MachineSchedulerService } from 'onevoice';

@Component({
  selector: 'main-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit, OnChanges {

  currentDate: Date = new Date();
  calendarDays: Date[] = [];
  schedule: MachineScheduler[] = [];
  dayText: String = ""
  weekTasks: TimedTask[][] = [];
  weekDays: number[] = [];
  scheduler: TimedTask[][] = []
  taskStatus: string = 'hourglass_empty'
  machineExecutionList: MachineExecution[] = [];

  public startDay?: Date;
  public endDay?: Date;

  hours: string[] = []

  schedulerMonday: TimedTask[] = [];
  schedulerThursday: TimedTask[] = [];
  schedulerWednesday: TimedTask[] = [];
  schedulerTuesday: TimedTask[] = [];
  schedulerFriday: TimedTask[] = [];
  schedulerSaturday: TimedTask[] = [];
  schedulerSunday: TimedTask[] = [];

  constructor(
    public executionService: MachineExecutionService,
    public auth: AuthenticationService,
    public serviceScheduler: MachineSchedulerService,
  ) { }
  ngOnChanges(): void {

    console.log("final")
  }

  selectDay(data: MachineScheduler[]): void {
    data.forEach(schedule => {
      schedule.timedTaskList.forEach(task => {
        task.machine = schedule.machine;
        task.repeat.forEach(day => {
          switch (day) {
            case 1:
              this.schedulerSunday.push(task);
              break;
            case 2:
              this.schedulerMonday.push(task);
              break;
            case 3:
              this.schedulerTuesday.push(task);
              break;
            case 4:
              this.schedulerWednesday.push(task);
              break;
            case 5:
              this.schedulerThursday.push(task);
              break;
            case 6:
              this.schedulerFriday.push(task)
              break;
            case 7:
              this.schedulerSaturday.push(task);
              break;

          }
        })
      })

    })
    this.scheduler.push(this.schedulerSunday);
    this.scheduler.push(this.schedulerMonday);
    this.scheduler.push(this.schedulerTuesday);
    this.scheduler.push(this.schedulerWednesday);
    this.scheduler.push(this.schedulerThursday);
    this.scheduler.push(this.schedulerFriday);
    this.scheduler.push(this.schedulerSaturday);

    this.orderTasks()

    this.weekTasks = this.scheduler.slice(0, 7)
  }

  loadExecutions() {

    this.startDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.weekDays[0]);
    this.endDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.weekDays[6]);
    let params = new HttpParams();
    params = params.append('startDate', this.startDay.getTime());
    params = params.append('endDate', this.endDay.getTime());
    this.executionService.list(params).subscribe(data => {
      data.forEach(execution => {
        this.machineExecutionList.push(execution)
      })

    })
  }
  orderTasks(): void {

    this.scheduler.forEach(scheduler => {
      scheduler.sort((a, b) => {
        if (a.startTime < b.startTime) return -1;

        if (a.startTime > b.startTime) return 1;
        return 0;
      })

    })
  }

  ngOnInit(): void {
    this.auth.systemToken().then(_ => {
      this.loadScheduler();

      this.loadExecutions()
    });

  }
  loadScheduler(): void {
    this.serviceScheduler.list().subscribe(task => {
      this.schedule = task;
      this.selectDay(task);
      this.listHours(task);
      console.log(this.schedule)
    })


  }

  listHours(schedulers: MachineScheduler[]) {
    schedulers.forEach(scheduler => {
      scheduler.timedTaskList.forEach(task => {
        this.hours.push(task.startTime.slice(0,-6))
      })
    })
    this.hours = this.hours.filter((item, index, self) => {
      return self.indexOf(item) === index;

    });
    this.weekCalendar();
    this.hours.sort()
  }

  getHours() {

  }

  getDayText(day: number): string {
    var week = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
    return week[day]

  }

  weekCalendar(): void {

    const firstDayWeek = 0;
    const lastDayWeek = 6;

    const currentDay = new Date();

    while (currentDay.getDay() !== 0) {
      currentDay.setDate(currentDay.getDate() - 1);
    }

    while (currentDay.getDay() < lastDayWeek) {
      this.weekDays.push(currentDay.getDate())
      currentDay.setDate(currentDay.getDate() + 1)
    }
    this.weekDays.push(currentDay.getDate())
  }


  status(task: MachineTask, day: number) {


    let status = 'hourglass_empty'
    if (day <= this.currentDate.getDate()) {
      this.machineExecutionList.forEach(single => {
        if (task.name == single.task.name) {
          if (single.start != undefined && single.end == undefined) {
            status = "hourglass_bottom"
          }
          if (single.start != undefined && single.end != undefined) {
            status = "hourglass_full"
          }
        }

      })
    }

    return status
  }



  tasksToDay() {

  }

}
