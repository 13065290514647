import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AssignmentsService, AuthorizationType, PermissionAuthority, User, UserService } from 'onevoice';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UserDialogComponent } from './user-dialog/user-dialog.component';

function userFullname(user: User): string {
    return `${user.details.first_name} ${user.details.last_name}`.trim();
}

function filterUser(user: User, text: string): boolean {
    return userFullname(user).toLowerCase().includes(text.toLowerCase());
}

function ByName(u1: User, u2: User): number {
    let un1 = userFullname(u1).toLowerCase();
    let un2 = userFullname(u2).toLowerCase();

    if (un1 < un2) {
        return -1;
    } else if (un1 > un2) {
        return 1;
    }
    return 0;
}

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
    public searchInput: FormControl = new FormControl('');

    private _unsubscribeAll: Subject<any>;
    private userList: User[] = [];

    @ViewChild(MatPaginator, { static: false })
    set paginator(reference: MatPaginator) {
        this.dataSource.paginator = reference;
    }
    public dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
    displayedColumns = [
        'avatar', 'name', 'email','location',
        'auth', 'buttons'
    ];


    constructor(
        private dialog: MatDialog,
        private assignments: AssignmentsService,
        public users: UserService,
        private snack: MatSnackBar,
    ) {
        this._unsubscribeAll = new Subject();

        this.users.list().subscribe(userList => {
            this.userList = userList;
            this.dataSource.data = userList.sort(ByName);
            console.log(this.userList)
        })
    }

    ngOnInit(): void {
        this.searchInput.valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe(searchText => {
                this.dataSource.data = this.userList.filter(value => filterUser(value, searchText)).sort(ByName);
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    newContact(): void {
        this.editUser({});
    }

    public authorities(arr: PermissionAuthority[]) {
        const translate: { [key: string]: string } = {
            [AuthorizationType.ROBOT.toString()]: 'Edição',
            [AuthorizationType.ADMIN.toString()]: 'Administrador',
            [AuthorizationType.AGENT.toString()]: 'Atendente',
            [AuthorizationType.HR.toString()]: 'Recursos Humanos',
            [AuthorizationType.VOICE.toString()]: 'Vozes',
            [AuthorizationType.CLEANER.toString()]: 'Serv. Gerais',
        }
        return arr
            .map((item: PermissionAuthority): string => translate[item.authorization.toString()])
            .reduce(
                (uniq: string[], item: string) => uniq.includes(item) ? uniq : [...uniq, item],
                [],
            ).join(', ')
    }


    editUser(user: Partial<User>): void {
        let reference = this.dialog.open<UserDialogComponent, Partial<User>, User>(UserDialogComponent, {
            width: "800px",
            panelClass: "user-dialog-container",
            data: user
        });

        reference.afterClosed()
            .subscribe((user?: User) => {
                if (user) {
                    this.userList = this.userList.filter(value => value.id != user.id).concat([user]).sort(ByName);
                    this.dataSource.data = this.userList.sort(ByName);
                }
            });
    }

    deleteUser(user: User): void {
        let confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        confirmDialogRef.componentInstance.confirmMessage = 'Tem certeza em deletar este usuário?';
        confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.users.delete(user).subscribe(_ => {
                    this.snack.open("O usuário foi removido!", "OK");
                    this.dataSource.data = this.dataSource.data.filter(value => value.id !== user.id);
                });
            }
        });
    }

    public getUserName(user: User): string {
        if (user.details != null && user.details.first_name && user.details.last_name) {
            return userFullname(user);
        }
        return user.email;
    }
}
