import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAMEndpoints } from 'app/models';
import { SkillManagerForm } from 'app/models/skill-manager-form';
import { Skill } from 'app/models/skill';

@Injectable({
  providedIn: 'root'
})
export class SkillsRequestService {

  private base_url = environment.iam;

  constructor(
    private _http: HttpClient,
  ) { }

  public createSkill(skill: SkillManagerForm) {
    const body = new Skill(skill);
    return this._http.post(this.base_url + IAMEndpoints.user_skills, body);
  }

  public listSkills() {
    return this._http.get(this.base_url + IAMEndpoints.user_skills);
  }

  public updateSkill(id: number, skill: SkillManagerForm) {
    const body = new Skill(skill);
    return this._http.post(this.base_url + IAMEndpoints.update_user_skills + id, body);
  }

  public deleteSkill(id: number) {
    return this._http.delete(this.base_url + IAMEndpoints.delete_user_skills + id);
  }


}
