<h1 mat-dialog-title>Wizard de Criação de Voz</h1>

<mat-dialog-content>
  <p>{{ nextSnippet }}</p>

  <button mat-raised-button (click)="toggleMicrofone()">
    <span *ngIf="!recording">Gravar</span>
    <span *ngIf="recording">Parar</span>
  </button>
  <button mat-raised-button (click)="listen()">Escutar</button>
  <button mat-raised-button (click)="save()">Salvar</button>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Fechar</button>
</mat-dialog-actions>