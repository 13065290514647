import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Intent } from './intent.service';

export enum ActionType {
  DEFAULT = "DEFAULT",
  QUESTION = "QUESTION",
  TALK_HUMAN = "TALK_HUMAN",
  EMAIL_USERS = "EMAIL_USERS",
}

export interface Action {
  id: number;
  intent: Intent;

  maxRej: Intent;
  maxSil: Intent;

  name: string;
  order: number;

  type: ActionType;
}

export interface ActionProperty {
  id: number;
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  url: string = `${this.config.endpoint}/api/actions`;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: ApiConfiguration
  ) { }

  list(intent?: number): Observable<Action[]> {
    let params: HttpParams = new HttpParams();
    if (intent) {
      params = params.set('intent', `${intent}`);
    }
    return this.http.get<Action[]>(this.url, {
      params: params
    });
  }

  get(id: number): Observable<Action> {
    return this.http.get<Action>(`${this.url}/${id}`);
  }

  create(data: Partial<Action>): Observable<Action> {
    return this.http.post<Action>(this.url, data);
  }

  update(data: Action): Observable<Action> {
    return this.http.post<Action>(`${this.url}/${data.id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  delete(data: Action): Observable<any> {
    return this.http.delete(`${this.url}/${data.id}`);
  }

  addProperty(action: Action, data: Partial<ActionProperty>): Observable<ActionProperty> {
    return this.http.post<ActionProperty>(`${this.url}/${action.id}/property`, data);

  }

  listProperty(action: Action) {
    return this.http.get<ActionProperty[]>(`${this.url}/${action.id}/property`);
  }

  deleteProperty(action: Action, property: ActionProperty): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/${action.id}/property/${property.id}`);
  }
}
