import { Pipe, PipeTransform } from '@angular/core';
import { ChannelType, GenericResponse } from 'onevoice';

@Pipe({
    name: 'responseFilter',
    pure: false
})
export class ResponseFilterPipe implements PipeTransform {
    transform(items: GenericResponse[], filter: ChannelType): GenericResponse[] {
        if (!items || !filter) {
            return items;
        }
        return items.filter((item: GenericResponse) => item.channel === filter);
    }
}
