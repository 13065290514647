import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
    ApplicationRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';

import { AgentService, AnswerMode, AssignmentsService, Chat } from 'onevoice';
import { ChatService } from './chat.service';

@Component({
    selector: 'chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChatComponent implements OnInit, OnDestroy {
    selectedChat: Chat | null = null;

    // Private
    private _unsubscribeAll: Subject<any>;
    private audio: HTMLAudioElement;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _ar: ApplicationRef,
        private _cdr: ChangeDetectorRef,
        private _chatService: ChatService,
        public service: AgentService,
        public assignments: AssignmentsService,

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.audio = new Audio('assets/sounds/swiftly.ogg');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chatData => {
                this.selectedChat = chatData;
                if (chatData) {
                    this.service.markRead(chatData);
                }
            });

        this.service.OnLoad.subscribe(_ => {
            this.tick();
        })
        this.service.OnMessage.subscribe(message => {
            if (this.selectedChat && message.customerDTO && this.selectedChat.customer.id == message.customerDTO.idCustomer) {
                this.selectedChat.unread = 0;
            } else {
                if (message.answerMode && message.answerMode != AnswerMode.AUTO) {
                    this.audio.play();
                }
            }
            this.tick();
        });
        this.assignments.OnChange.subscribe(_ => {
            this.tick();
        });
    }

    tick() {
        this._ar.tick();
        this._cdr.detectChanges();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
