<mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
    <mat-cell *matCellDef="let customer">
      {{ customer.firstName ? customer.firstName + ' ' + customer.lastName : 'Customer #' + customer.id }}
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let customer columns: displayedColumns;" class="customer"
    [routerLink]="['/customer', customer.id]" matRipple>
  </mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 500]" showFirstLastButtons></mat-paginator>
