import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'alabia-signup-step2',
  templateUrl: './signup-step2.component.html',
  styleUrls: ['./signup-step2.component.scss', '../signup-step.shared.scss'],
  animations: [
    trigger('formAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateX(100%)', opacity: '0%' }))
      ])
    ]),
    trigger('infoAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('700ms ease', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateY(100%)', opacity: '0%' }))
      ])
    ]),
  ],
})
export class SignupStep2Component implements OnInit {

  public isShowing: boolean = true;
  public secondStepSignup?: FormGroup;
  @Output() nextScreen = new EventEmitter();
  @Output() stepData = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.secondStepSignup = this.secondStepForm();
  }

  public screenOutputHandler(currentScreen: any, step: any) {
    this.isShowing = !this.isShowing;
    setTimeout(() => {
      this.nextScreen.emit({ currentScreen, step });
    }, 700);
  }

  secondStepForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      username: new FormControl(''),
    });
  }

  onSubmit(formData: any) {
    if (formData.valid) {
      this.stepData.emit({ step: 1, signupData: formData.value });
      // this.screenOutputHandler(1, 'forward');
    }
  }

}
