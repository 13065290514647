import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatStepper } from '@angular/material/stepper';
import { ChangeType, ConclusionTag, ConclusionTagService } from 'onevoice';
import { TagCreateComponent } from '../tag-create/tag-create.component';

function checkContent(full: string, part: string): boolean {
  return full.toLowerCase().includes(part.toLowerCase())
}


export interface TagData {
  tag?: ConclusionTag;
  description: string;
  status: ChangeType;
}

@Component({
  selector: 'alabia-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss']
})
export class TagDialogComponent implements OnInit {
  public tags: ConclusionTag[] = [];
  public tagFilter: string = "";

  get filtered(): ConclusionTag[] {
    return this.tags.filter(
      value => checkContent(value.title, this.tagFilter) || checkContent(value.description, this.tagFilter),
    );
  }

  constructor(
    private dialog: MatDialog,
    private tagService: ConclusionTagService,
    @Inject(MAT_DIALOG_DATA) public data: TagData,
    public reference: MatDialogRef<TagDialogComponent>,
  ) {
  }

  ngOnInit() {
    this.tagService.list().subscribe(tags => {
      this.tags = tags;
    });
  }

  finish() {
    console.log(this.data);
    this.reference.close(this.data);
  }

  public setTag(tag: ConclusionTag, stepper: MatStepper): void {
    this.data.tag = tag;
    stepper.next();
  }

  openModel() {
    let reference = this.dialog.open<TagCreateComponent, Partial<ConclusionTag>, ConclusionTag>(TagCreateComponent, {
      width: "500px",
      maxWidth: "80%",
      data: {
        title: this.tagFilter,
      },
    });

    reference.afterClosed().subscribe(tag => {
      if (tag) {
        this.tags.push(tag);
      }
    });
  }

  enableBot(event: MatSlideToggleChange) {
    this.data.status = (event.checked ? ChangeType.DONE : ChangeType.REPLIED)
  }
}
