import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Parameter } from './parameter.service';
import { LusEntity } from './lus-entity.service';

export interface Entity {
    id?: number;

    intentActionParameter?: Parameter;
    lusEntity?: LusEntity;

    validValues?: string;
    invalidValues?: string;
}

@Injectable({
    providedIn: 'root'
})
export class EntitiesService {
    url: string = `${environment.coreService}/api/entities`;

    constructor(private _httpClient: HttpClient) {}

    list(parameter?: number ): Observable<Entity[]> {
        let params: HttpParams = new HttpParams();
        if (parameter) {
            params = params.set('parameter', `${parameter}`);
        }
        return this._httpClient.get<Entity[]>(this.url, {
            params: params
        });
    }

    get(id: number): Observable<Entity> {
        return this._httpClient.get<Entity>(`${this.url}/${id}`);
    }

    create(data: Entity): Observable<Entity> {
        return this._httpClient.post<Entity>(this.url, data);
    }

    update(data: Entity): Observable<Entity> {
        return this._httpClient.post<Entity>(`${this.url}/${data.id}`, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    delete(data: Entity): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
