import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { ScriptLine, SpeechProject } from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ScriptLineService {
  protected path: string = `${this.config.mediaservice}/script`;

  constructor(private http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(project: SpeechProject): Observable<ScriptLine[]> {
    return this.http.get<ScriptLine[]>(`${this.path}/${project.id}`);
  }

  public create(project: SpeechProject, data: Partial<ScriptLine>): Observable<ScriptLine> {
    return this.http.post<ScriptLine>(`${this.path}/${project.id}`, data, {
      responseType: "json",
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    });
  }

  public delete(script: ScriptLine) {
    return this.http.delete<boolean>(`${this.path}/${script.project.id}/${script.id}`);
  }

  public update(script: ScriptLine): Observable<ScriptLine> {
    return this.http.post<ScriptLine>(`${this.path}/update/${script.id}`, script);
  }
  
}
