import { Component, OnInit } from '@angular/core';
import { AuthenticationService, User } from 'onevoice';

@Component({
  selector: 'main-agent-data',
  templateUrl: './agent-data.component.html',
  styleUrls: ['./agent-data.component.scss']
})
export class AgentDataComponent implements OnInit {
  public user: User;

  constructor(public auth: AuthenticationService) {
    if (auth.current.value == null) {
      throw "User must be not null";
    }
    this.user = auth.current.value;
  }

  ngOnInit(): void {
  }

}
