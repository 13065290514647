import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { DirectivesModule } from 'app/directives/directives.module';
import { SkillService } from '../api/skill.service';
import { SelectedBarComponent } from './selected-bar/selected-bar.component';
import { SkillListComponent } from './skill-list/skill-list.component';
import { SkillManagerDialogComponent } from './skill-manager-dialog/skill-manager-dialog.component';
import { SkillManagerComponent } from './skill-manager.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SkillManagerComponent,
    resolve: {
      skill: SkillService
    }
  }
];

@NgModule({
  declarations: [
    SkillManagerComponent,
    SkillListComponent,
    SkillManagerDialogComponent,
    SelectedBarComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,

    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSelectModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,

    DirectivesModule
  ],
  providers: [
    SkillService
  ],
  entryComponents: [
    SkillManagerDialogComponent
  ],

})
export class SkillManagerModule { }
