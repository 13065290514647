import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorizationType, Bot, BotService, MapUnit, MapUnitService, PermissionAuthority, PermissionRequest, PermissionService, User, UserService } from 'onevoice';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from '../../alert/alert-component/alert-component.component';

function passwordConfimation(control: AbstractControl): ValidationErrors | null {
  control.get("password")?.value == control.get("confirm")?.value;
  return null;
}

@Component({
  selector: 'main-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent {
  public title: string = "Novo Usuário";
  public action: string = "Criar";
  public create: boolean = true;
  public unitList: MapUnit[] = [];
  public form: FormGroup;

  public authorities: FormControl = new FormControl([], []);
  public chats: FormControl = new FormControl([], []);
  public robots: FormControl = new FormControl([], []);


  public AuthorizationList: { key: AuthorizationType, value: string }[] = [
    { key: AuthorizationType.ADMIN, value: 'Administrador' },
    { key: AuthorizationType.MANAGER, value: 'Gerente' },
    { key: AuthorizationType.HR, value: 'Recursos Humanos' },
    { key: AuthorizationType.VOICE, value: "Vozes" },
    { key: AuthorizationType.CLEANER, value: "Serviços Gerais" },
  ];
  public robotList: Bot[] = [];

  private addToControl(controll: FormControl, scope: string) {
    this.robotList.filter(robot => robot.id.toString() == scope).forEach(
      robot => controll.setValue(controll.value.concat([robot]))
    )
  }

  constructor(
    public dialog: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private user: Partial<User>,
    private service: UserService,
    private permissions: PermissionService,
    private bots: BotService,
    public snack: MatSnackBar,
    public location: MapUnitService,
    public alert: MatDialog,
  ) {
    this.form = new FormGroup({
      "first_name": new FormControl(user.details?.first_name || "", [Validators.required, Validators.minLength(2)]),
      "last_name": new FormControl(user.details?.last_name || "", [Validators.required, Validators.minLength(2)]),
      "email": new FormControl(user.email || "", [Validators.email, Validators.required, Validators.minLength(5)]),
      "phone": new FormControl(user.details?.phone || "", []),
      "location": new FormControl(user.details?.location || "", [Validators.required, Validators.required, Validators.minLength(5)])

    })
    this.bots.list().subscribe(list => {
      this.robotList = list;
    });
    this.loadUnits();
    if (user.id !== undefined) {
      this.title = "Editar Usuário";
      this.action = "Alterar";
      this.create = false;

      this.permissions.list(user.id).subscribe(list => {
        for (const permission of list) {
          if (permission.authorization == AuthorizationType.AGENT) {
            this.addToControl(this.chats, permission.scope);
          } else if (permission.authorization == AuthorizationType.ROBOT) {
            this.addToControl(this.robots, permission.scope);
          } else {
            this.authorities.setValue(this.authorities.value.concat([permission.authorization]));
          }
        }
      });
    } else {
      this.form.addControl("password", new FormControl("", [Validators.required]));
      this.form.addControl("confirm", new FormControl("", [Validators.required]));
      this.form.addValidators(passwordConfimation);
    }
  }
  public validEmail(email: string) {
    this.service.userExist(email).subscribe(data => {
      if (data) {
        this.alert.open(AlertDialogComponent, {
          data: {
            message: 'Email já cadastrado',
          },
        });
      }
    })

  }

  private addPermissions(user: User) {
    let positive: PermissionRequest[] = [];
    let negative: PermissionRequest[] = [];

    let orgpermissions = this.authorities.value as AuthorizationType[];
    let editable = this.robots.value as Bot[];
    let chats = this.chats.value as Bot[];

    if (user.organization.id) {
      for (const _type of this.AuthorizationList.map(value => value.key)) {
        if (orgpermissions.find(value => value == _type)) {
          positive.push({
            type: _type,
            scope: user.organization.id.toString()
          });
        } else {
          negative.push({
            type: _type,
            scope: user.organization.id.toString()
          });
        }
      }
    }

    for (const robot of this.robotList) {
      if (editable.find(value => value.id == robot.id)) {
        positive.push({
          type: AuthorizationType.ROBOT,
          scope: robot.id.toString()
        });
      } else {
        negative.push({
          type: AuthorizationType.ROBOT,
          scope: robot.id.toString()
        });
      }

      if (chats.find(value => value.id == robot.id)) {
        positive.push({
          type: AuthorizationType.AGENT,
          scope: robot.id.toString()
        });
      } else {
        negative.push({
          type: AuthorizationType.AGENT,
          scope: robot.id.toString()
        });
      }

    }

    this.permissions.add(user.id, positive).subscribe(ok => { });
    this.permissions.remove(user.id, negative).subscribe(ok => { });
  }
  public loadUnits() {
    this.location.list().subscribe(list => {
      this.unitList = list;
    })
  }

  public setLocation(location: MapUnit) {
    this.form.value.location = location;
  }
  public onSubmit(): void {
    if (this.user.id) {
      this.service.update(this.user.id, {
        ...this.form.value,
      }).subscribe(user => {
        this.snack.open("O usuário foi atualizado com sucesso!", "OK", { duration: 10000 });
        this.addPermissions(user);
        this.dialog.close(user);
      })
    } else {
      this.validEmail(this.form.value.email)
      this.service.create({
        ...this.form.value,
      }).subscribe(user => {
        this.snack.open("O usuário foi criado com sucesso!", "OK", { duration: 10000 });
        this.addPermissions(user);
        this.dialog.close(user);
      })
    }
  }
}
