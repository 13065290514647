import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormatsModule } from 'one-ui';
import { BackendApiRequestService } from '../api/backend-api-request.service';
import { ChatService } from '../chat/chat.service';
import { CustomerFilterDialogComponent } from './customer-filter-dialog/customer-filter-dialog.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerDetailsComponent } from './customer-visualizer/customer-details/customer-details.component';
import { CustomerHistoryComponent } from './customer-visualizer/customer-history/customer-history.component';
import { CustomerVisualizerComponent } from './customer-visualizer/customer-visualizer.component';
import { CustomerComponent } from './customer.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: CustomerComponent,
    resolve: {
      customer: BackendApiRequestService
    }
  },
  {
    path: ':id',
    pathMatch: 'full',
    component: CustomerVisualizerComponent,
    resolve: {
      // chat: ChatService,
      customer: BackendApiRequestService
    }
  },
  {
    path: ':id/history/:bot',
    pathMatch: 'full',
    component: CustomerHistoryComponent,
    resolve: {
      // chat: ChatService,
      customer: BackendApiRequestService
    }
  },
];

@NgModule({
  declarations: [
    CustomerComponent,
    CustomerFilterDialogComponent,
    CustomerVisualizerComponent,
    CustomerListComponent,
    CustomerDetailsComponent,
    CustomerHistoryComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule,
    MatSelectModule,
    MatDividerModule,
    MatTabsModule,
    MatPaginatorModule,
    FuseSharedModule,
    FuseConfirmDialogModule,
    FuseSidebarModule,
    FormatsModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    ChatService
  ],
  entryComponents: [
    CustomerFilterDialogComponent,
  ]
})
export class CustomerModule { }
