import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { fuseAnimations } from '@fuse/animations';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import { ChatService } from 'app/alabia/chat/chat.service';
import { AgentService, AssignmentsService, AuthenticationService, Bot, BotService, ChangeType, Chat, QueueAssignment } from 'onevoice';
import { Subject } from 'rxjs';

@Component({
  selector: 'chat-chats-sidenav',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ChatChatsSidenavComponent implements OnInit, OnDestroy {
  chatSearch: any = { name: '' };
  searchText: string = "";

  public chats: Chat[] = [];

  public selected: Chat | null = null;
  private _unsubscribeAll: Subject<any> = new Subject();

  public bots: Bot[] = [];
  public selectedBots: Bot[] = [];

  public ChangeType = ChangeType;

  public LOAD_COUNT = 50;
  public INIT_COUNT = 50;

  constructor(
    private _ar: ApplicationRef,
    private _cdr: ChangeDetectorRef,

    public chatService: ChatService,
    public botService: BotService,
    public agent: AgentService,
    public assignments: AssignmentsService,
    private _fuseMatSidenavHelperService: FuseMatSidenavHelperService,
    public _mediaObserver: MediaObserver,
    private auth: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.chatService.onChatSelected.subscribe(chat => {
      this.selected = chat;
    });

    this.botService.list().subscribe(bots => {
      this.bots = bots;
    });

    this.agent.OnLoad.subscribe(_ => {
      this._ar.tick();
      this._cdr.detectChanges();
    })
    this.assignments.OnChange.subscribe(change => {
      this._ar.tick();
      this._cdr.detectChanges();
    });
    this.agent.OnStart.subscribe(() => {
      this.agent.loadMoreChats(this.INIT_COUNT);
    })
  }

  private isFromBot(assignment: QueueAssignment) {
    if (this.selectedBots.length <= 0) {
      return true;
    }
    for (const bot of this.selectedBots) {
      if (assignment && bot.id == assignment.bot.id) {
        return true;
      }
    }
    return false;
  }

  get filteredChats() {
    return this.agent.chatList.filter((chat: Chat) => this.isFromBot(chat.assignment));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getChat(chat: Chat): void {
    this.chatService.selectChat(chat);

    if (!this._mediaObserver.isActive('gt-md')) {
      this._fuseMatSidenavHelperService.getSidenav('chat-left-sidenav').toggle();
    }
  }

  public loadAssignments() {
    this.agent.loadMoreChats(this.LOAD_COUNT);
  }

  changeLeftSidenavView(view: string | undefined): void {
    this.chatService.onLeftSidenavViewChanged.next(view);
  }

  toggleSorting() {
    this.agent.Ascending.next(!this.agent.Ascending.value);
  }
}
