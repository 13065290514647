import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { MediaType } from '../dialog/message.model';

export interface UploadedMedia extends Ownable {
  path?: string;
  type?: MediaType;
}


@Injectable({
  providedIn: 'root'
})
export class UploadedMediaService extends OwnableService<UploadedMedia> {
  protected path: string = `${this.config.endpoint}/uploaded-media`;

  public upload(file: File): Observable<UploadedMedia> {
    let form = new FormData();
    form.set("file", file);
    return this.http.post<UploadedMedia>(`${this.path}/upload`, form, {
      responseType: "json",
      observe: "body",
    });
  }

  public redirect(data: UploadedMedia): string {
    return `${this.path}/${data.id}/redirect`;
  }

  public url(data: UploadedMedia): Observable<string> {
    return this.http.get(`${this.path}/${data.id}/url`, {
      responseType: 'text',
    });
  }
}
