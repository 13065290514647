<div class="info" *ngIf="isShowing" [@infoAnimation]>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ultrices sem nec nulla porta faucibus. Aenean
  sed ex at velit volutpat accumsan. Sed et tortor elementum, tristique dui non, tincidunt est. Duis condimentum id
  velit sit amet aliquet. Integer nec efficitur dolor. Morbi sed purus sit amet metus sodales tristique id eget
  urna. Donec sed mi tellus. Pellentesque fermentum sem a ultrices efficitur. Vestibulum volutpat est a dui auctor,
  at volutpat massa ultrices. Sed id maximus metus. Integer tempor velit at neque ultricies, vel varius risus
  elementum. Quisque tempor dignissim magna pellentesque iaculis. Mauris tempus ligula neque. Ut at lacus.
</div>

<form *ngIf="isShowing && fourthStepSignup" [@formAnimation] [formGroup]="fourthStepSignup" (ngSubmit)="onSubmit(fourthStepSignup)"
  ngNativeValidate class="form" #secondStepForm="ngForm">

  <h3 class="form--title">Qual o tamanho de sua empresa?</h3>
  <input type="radio" name="company_size" id="1_10_employees" formControlName="company_size" class="radio-input"
    value="1-10" required />
  <label for="1_10_employees" class="radio-label">
    <div class="radio-text">
      <p class="radio-text--title">1 - 10</p>
      <p class="radio-text--description">Funcionários</p>
    </div>
  </label>
  <input type="radio" name="company_size" id="10_100_employees" formControlName="company_size" class="radio-input"
    value="10-100" required />
  <label for="10_100_employees" class="radio-label">
    <div class="radio-text">
      <p class="radio-text--title">10 - 100</p>
      <p class="radio-text--description">Funcionários</p>
    </div>
  </label>
  <input type="radio" name="company_size" id="over_100_employees" formControlName="company_size" class="radio-input"
    value="100+" required />
  <label for="over_100_employees" class="radio-label">
    <div class="radio-text">
      <p class="radio-text--title">100 +</p>
      <p class="radio-text--description">Funcionários</p>
    </div>
  </label>

  <div class="button-container">
    <button mat-raised-button class="button button__back" type="button"
      (click)="screenOutputHandler(3, 'backward')">Voltar</button>
    <button mat-raised-button class="button button__next" type="submit"
      [disabled]="!fourthStepSignup || fourthStepSignup.invalid">Próximo</button>
  </div>
</form>