import { Component, OnInit, Input } from '@angular/core';
import { Bot } from 'onevoice';
import { BotClientService } from 'app/alabia/api/bot-client.service';
import { IntentService } from 'onevoice';

@Component({
    selector: 'alabia-messenger',
    templateUrl: './messenger.component.html',
    styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit {
    @Input()
    bot?: Bot;

    public testMessage: string = '';
    public answer: string = 'Resposta';
    public intent: string = 'NONE';

    constructor(
        private _botClient: BotClientService,
        private _intentService: IntentService
    ) { }

    ngOnInit() {
        this._botClient.updates.subscribe(response => {
            for (const message of response.customResponse) {
                this.answer = message.content;
            }
            this._intentService
                .get(response.currentIntent.idIntent)
                .subscribe(intent => {
                    if (intent.name) {
                        this.intent = intent.name;
                    }
                });
        });
    }

    sendMessage(message: string) {
        if (!this.bot || !this.bot.id) { return }
        this._botClient.sendMessage(this.bot.id, message);
        this.testMessage = '';
    }
}
