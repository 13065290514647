import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { MachineTask } from '../hw/machine-task.service';

export interface WeekRepete {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export enum ScheduleType {
  HUMAN = "HUMAN",
  ROBOT_MANUAL = "ROBOT_MANUAL",
  ROBOT_AUTO = "ROBOT_AUTO",
}

export interface TaskSchedule extends Ownable {
  name: string;
  task: MachineTask[];
  repete: WeekRepete;
  start: Time;
  end: Time;
  type: ScheduleType;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends OwnableService<TaskSchedule> {
  protected path: string = `${this.config.endpoint}/schedule`;

  public today(): Observable<TaskSchedule[]> {
    return this.http.get<TaskSchedule[]>(`${this.path}/today`);
  }
}
