<div id="alabia-view-content" class="page-layout carded fullwidth inner-scroll">
  <!-- <button class="alabia-voice-project-add" mat-fab (click)="adicionar()">
            <mat-icon>add</mat-icon>
        </button> -->

  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">{{ title }}</h1>
  </div>

  <!-- <mat-spinner class="alabia-view-spinner" *ngIf="projectList === undefined"></mat-spinner>
        <div class="alabia-view-container" *ngIf="projectList !== undefined">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Novo projeto
                        </mat-panel-title>
                        <mat-panel-description>
                            Criar um novo projeto de geração de voz
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <form [formGroup]="createForm" (ngSubmit)="createProject()">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput type="text" formControlName="name" placeholder="Nome do Projeto">
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Descrição</mat-label>
                            <textarea matInput formControlName="description" placeholder="Descrição do Projeto"></textarea>
                        </mat-form-field>

                        <button mat-icon-button type="submit">
                            <mat-icon>add</mat-icon>
                        </button>
                    </form>
                </mat-expansion-panel>

                <mat-expansion-panel *ngFor="let project of projectList">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ project.name }}
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ project.description }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>
                        <div *ngIf="retrieveProject(project) | async as scripts">
                            Vozes: {{ getVoices(scripts) || "Nenhuma voz uilizada" }}
                        </div>
                    </ng-template>

                    <mat-action-row>
                        <a mat-button [routerLink]="[project.id]">Editar</a>
                        <button mat-button (click)="generateVoiceProject(project)">Gerar</button>
                        <button mat-button (click)="openDownloads(project)">Download</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </div> -->

  <!-- nova listagem -->
  <mat-progress-bar
    style="position: fixed; z-index: 10"
    color="warn"
    *ngIf="processing"
    mode="indeterminate"
  >
  </mat-progress-bar>

  <div class="project m-24">
    <div class="center p-24 pt-0 pb-56" fusePerfectScrollbar>
      <div class="inf-contain">
        <mat-card class="inf-card" (click)="changeTab(0)">
          <mat-card-header>
            <mat-card-title>
              <mat-icon>folder</mat-icon>
              Projetos
            </mat-card-title>
            <mat-card-subtitle>{{ datasource.data.length }}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>

        <mat-card class="inf-card" (click)="changeTab(1)">
          <mat-card-header>
            <mat-card-title>
              <mat-icon>star_border</mat-icon>
              Favoritos
            </mat-card-title>
            <mat-card-subtitle>{{
              datasourceFavorite.data.length
            }}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>

      <!-- change view icon -->
      <div class="view-action" *ngIf="!this.isMobile">
        <button
          *ngIf="view_icon"
          (click)="changeViewIcon()"
          mat-icon-button
          type="button"
          matTooltip="Mostrar grade"
          aria-label="Button that displays a tooltip when focused or hovered over"
        >
          <mat-icon>apps</mat-icon>
        </button>
        <button
          *ngIf="!view_icon"
          (click)="changeViewIcon()"
          mat-icon-button
          type="button"
          matTooltip="Mostrar lista"
          aria-label="Button that displays a tooltip when focused or hovered over"
        >
          <mat-icon>list_view</mat-icon>
        </button>

        <div *ngIf="!view_icon" class="sort-card">
          <div matSort matSortActive="id" matSortDirection="desc">
            <ng-container matColumnDef="id" *ngIf="selectedIndex === 0">
              <h5
                class="sort-icon"
                mat-sort-header
                sortActionDescription="ordem"
              >
                Ordem
              </h5>
            </ng-container>
          </div>
          <div matSort matSortActive="id" matSortDirection="desc">
            <ng-container matColumnDef="id" *ngIf="selectedIndex === 1">
              <h5
                class="sort-icon"
                mat-sort-header
                sortActionDescription="ordem"
              >
                Ordem
              </h5>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="view-action" *ngIf="this.isMobile">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          type="button"
          matTooltip="Opções"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            *ngIf="view_icon"
            mat-menu-item
            type="button"
            (click)="changeViewIcon()"
          >
            <mat-icon>apps</mat-icon>
            <span>Grade</span>
          </button>

          <button
            *ngIf="!view_icon"
            mat-menu-item
            type="button"
            (click)="changeViewIcon()"
          >
            <mat-icon>list_view</mat-icon>
            <span>Lista</span>
          </button>

          <div
            *ngIf="!view_icon"
            matSort
            matSortActive="id"
            matSortDirection="desc"
          >
            <div matColumnDef="id" mat-sort-header *ngIf="selectedIndex === 0">
              <button mat-menu-item type="button">
                <mat-icon>sort</mat-icon>
                <span>Ordem</span>
              </button>
            </div>
          </div>
          <div
            *ngIf="!view_icon"
            matSort
            matSortActive="id"
            matSortDirection="desc"
          >
            <div matColumnDef="id" mat-sort-header *ngIf="selectedIndex === 1">
              <button mat-menu-item type="button">
                <mat-icon>sort</mat-icon>
                <span>Ordem</span>
              </button>
            </div>
          </div>
        </mat-menu>
      </div>

      <!-- tabs -->
      <mat-tab-group
        class="mat-elevation-z2"
        [dynamicHeight]="!isMobile"
        color="accent"
        [selectedIndex]="selectedIndex"
        (selectedTabChange)="changeTab($event)"
      >
        <mat-tab label="Principal" (click)="changeTab(0)">
          <div>
            <table
              *ngIf="view_icon"
              class="voice-project-list"
              mat-table
              [dataSource]="datasource"
              matSort
              matSortActive="id"
              matSortDirection="desc"
            >
              <ng-container matColumnDef="favorite">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">
                  <button mat-icon-button>
                    <mat-icon (click)="selected(item)" *ngIf="!item.favorite"
                      >star_border</mat-icon
                    >
                    <mat-icon
                      (click)="selected(item)"
                      *ngIf="item.favorite"
                      class="icon-color"
                      >star
                    </mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="ordem"
                >
                  Ordem
                </th>
                <td mat-cell *matCellDef="let item; let i = index">
                  {{ datasource.data.indexOf(item) + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="name"
                >
                  Nome
                </th>
                <td mat-cell *matCellDef="let item">{{ item.name }}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descrição</th>
                <td mat-cell *matCellDef="let item">{{ item.description }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  Ações
                  <button mat-icon-button (click)="actionsView()" type="button">
                    <mat-icon
                      *ngIf="actionView"
                      style="vertical-align: unset"
                      matTooltip="Minimizar"
                      aria-label="Example icon-button with a menu"
                      >minimize
                    </mat-icon>
                    <mat-icon
                      *ngIf="!actionView"
                      matTooltip="Maximizar"
                      aria-label="Example icon-button with a menu"
                      >unfold_more</mat-icon
                    >
                  </button>
                </th>
                <mat-icon>edit</mat-icon>
                <td mat-cell *matCellDef="let item">
                  <div class="actionsMax" *ngIf="actionView">
                    <button
                      mat-icon-button
                      [routerLink]="[item.id]"
                      type="button"
                      matTooltip="Editar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="generateVoiceProject(item)"
                      type="button"
                      matTooltip="Gerar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>playlist_play</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="openDownloads(item)"
                      type="button"
                      matTooltip="Download"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>get_app</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="deleteProject(item)"
                      type="button"
                      matTooltip="Deletar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <div class="actionsMin" *ngIf="!actionView">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      type="button"
                      matTooltip="Opções"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        mat-menu-item
                        [routerLink]="[item.id]"
                        type="button"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="generateVoiceProject(item)"
                        type="button"
                      >
                        <mat-icon>playlist_play</mat-icon>
                        <span>Gerar</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="openDownloads(item)"
                        type="button"
                      >
                        <mat-icon>get_app</mat-icon>
                        <span>Download</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="deleteProject(item)"
                        type="button"
                      >
                        <mat-icon>close</mat-icon>
                        <span>Deletar</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr
                mat-row
                *matRowDef="let item; columns: columnsToDisplay; let i = index"
              ></tr>
            </table>
          </div>

          <!-- card -->
          <div class="allCards" *ngIf="!view_icon">
            <div class="grid-view" [style.width.vw]="displayCard">
              <mat-card
                class="card-min mat-elevation-z2"
                *ngFor="let project of obs | async; let i = index"
                [ngStyle]="{
                  'box-shadow':
                    projectDetail === project
                      ? '2px 2px 2px 2px rgba(142, 36, 170, .3)'
                      : '0px 3px 1px -2px rgba(0, 0, 0, .20), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)'
                }"
              >
                <div class="card-icon-fav">
                  <mat-icon
                    (click)="selected(project)"
                    *ngIf="!project.favorite"
                    >star_border
                  </mat-icon>
                  <mat-icon
                    (click)="selected(project)"
                    *ngIf="project.favorite"
                    id="fav-color"
                    >star
                  </mat-icon>
                </div>

                <mat-card-actions (click)="cardShowInf(project)">
                  <button type="menu" class="show-id" mat-fab>
                    <span class="icon-id">#</span
                    ><span class="order-number"
                      >{{ datasource.data.indexOf(project) + 1 }}
                    </span>
                  </button>
                </mat-card-actions>

                <mat-card-title (click)="cardShowInf(project)">{{
                  project.name
                }}</mat-card-title>
                <mat-card-subtitle (click)="cardShowInf(project)"
                  >{{ project.description }}
                </mat-card-subtitle>
                <mat-divider inset></mat-divider>
                <mat-progress-bar
                  color="accent"
                  *ngIf="processing && progressBarId == project.id"
                  mode="indeterminate"
                ></mat-progress-bar>

                <mat-card-actions>
                  <div class="card-actions mat-elevation-z8">
                    <button
                      [routerLink]="[project.id]"
                      mat-icon-button
                      type="button"
                      matTooltip="Editar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="generateVoiceProject(project)"
                      type="button"
                      matTooltip="Gerar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>playlist_play</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="openDownloads(project)"
                      type="button"
                      matTooltip="Download"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>get_app</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="deleteProject(project)"
                      type="button"
                      matTooltip="Deletar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </div>

            <!-- card detail -->
            <div
              class="cardInf"
              *ngIf="
                displayCard == 58 &&
                selectedIndex === 0 &&
                !isMobile &&
                !view_icon
              "
            >
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header
                    id="panelCard"
                    collapsedHeight="auto"
                    expandedHeight="auto"
                  >
                    <button mat-icon-button id="PanelIconFav">
                      <mat-icon
                        (click)="selected(projectDetail)"
                        *ngIf="!projectDetail?.favorite"
                      >
                        star_border</mat-icon
                      >
                      <mat-icon
                        (click)="selected(projectDetail)"
                        *ngIf="projectDetail?.favorite"
                        class="icon-color"
                        >star
                      </mat-icon>
                    </button>

                    <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                      Título projeto:
                    </mat-panel-title>

                    <mat-panel-title id="panelTitle" class="mat-headline">
                      {{ projectDetail?.name }}
                    </mat-panel-title>

                    <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                      Descrição:
                    </mat-panel-title>

                    <mat-panel-title id="panelSubitle" class="mat-subheading-2">
                      {{ projectDetail?.description }}
                    </mat-panel-title>

                    <div class="inf-contain-panel">
                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>description</mat-icon>
                            Scripts
                          </mat-card-title>
                          <mat-card-subtitle>{{
                            script.data.length
                          }}</mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>

                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>graphic_eq</mat-icon>
                            Moods
                          </mat-card-title>
                          <mat-card-subtitle
                            >{{ moodSizeID.length }}
                            <div class="mat-subheading-2">
                              /{{ moodList.length }}
                            </div>
                          </mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>

                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>record_voice_over</mat-icon>
                            Voices
                          </mat-card-title>
                          <mat-card-subtitle
                            >{{ voiceSizeID.length }}
                            <div class="mat-subheading-2">
                              /{{ voiceList.length }}
                            </div>
                          </mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>
                    </div>
                  </mat-expansion-panel-header>

                  <ng-template matExpansionPanelContent>
                    <div class="line">
                      <mat-panel-title
                        style="color: rgba(0, 0, 0, 0.54); padding-top: 30px"
                      >
                        Data de criação:
                      </mat-panel-title>

                      <mat-panel-title
                        id="panelSubitle"
                        class="mat-subheading-2"
                      >
                        {{ projectDetail?.created | date: "dd/MM/yyyy" }}
                      </mat-panel-title>

                      <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                        Data da ultima modificação:
                      </mat-panel-title>

                      <mat-panel-title
                        id="panelSubitle"
                        class="mat-subheading-2"
                      >
                        {{ projectDetail?.updated | date: "dd/MM/yyyy" }}
                      </mat-panel-title>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>

              <div
                id="panelActions"
                class="card-actions mat-elevation-z8"
                *ngIf="projectDetail"
              >
                <button
                  [routerLink]="[projectDetail.id]"
                  mat-icon-button
                  type="button"
                  matTooltip="Editar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>edit</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="generateVoiceProject(projectDetail)"
                  matTooltip="Gerar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>playlist_play</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="openDownloads(projectDetail)"
                  matTooltip="Download"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>get_app</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="deleteProject(projectDetail)"
                  matTooltip="Deletar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-paginator
            #paginator
            [pageSizeOptions]="[6, 12, 24, 48, 96]"
            [pageSize]="24"
            showFirstLastButtons
          >
          </mat-paginator>
        </mat-tab>

        <mat-tab label="Favoritos" (click)="changeTab(1)">
          <div [ngStyle]="{ display: display }">
            <table
              *ngIf="view_icon"
              class="voice-project-list"
              mat-table
              [dataSource]="datasourceFavorite"
              matSort
              matSortActive="id"
              matSortDirection="desc"
            >
              <ng-container matColumnDef="favorite">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">
                  <button mat-icon-button>
                    <mat-icon (click)="selected(item)" *ngIf="!item.favorite"
                      >star_border</mat-icon
                    >
                    <mat-icon
                      (click)="selected(item)"
                      *ngIf="item.favorite"
                      class="icon-color"
                      >star
                    </mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="ordem"
                >
                  Ordem
                </th>
                <td mat-cell *matCellDef="let item; let i = index">
                  {{ datasourceFavorite.data.indexOf(item) + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  sortActionDescription="name"
                >
                  Nome
                </th>
                <td mat-cell *matCellDef="let item">{{ item.name }}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descrição</th>
                <td mat-cell *matCellDef="let item">{{ item.description }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  Ações
                  <button mat-icon-button (click)="actionsView()" type="button">
                    <mat-icon
                      *ngIf="actionView"
                      style="vertical-align: unset"
                      matTooltip="Minimizar"
                      aria-label="Example icon-button with a menu"
                      >minimize
                    </mat-icon>
                    <mat-icon
                      *ngIf="!actionView"
                      matTooltip="Maximizar"
                      aria-label="Example icon-button with a menu"
                      >unfold_more</mat-icon
                    >
                  </button>
                </th>
                <mat-icon>edit</mat-icon>
                <td mat-cell *matCellDef="let item">
                  <div class="actionsMax" *ngIf="actionView">
                    <button
                      mat-icon-button
                      [routerLink]="[item.id]"
                      type="button"
                      matTooltip="Editar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="generateVoiceProject(item)"
                      type="button"
                      matTooltip="Gerar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>playlist_play</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="openDownloads(item)"
                      type="button"
                      matTooltip="Download"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>get_app</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="deleteProject(item)"
                      type="button"
                      matTooltip="Deletar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <div class="actionsMin" *ngIf="!actionView">
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      type="button"
                      matTooltip="Opções"
                      aria-label="Example icon-button with a menu"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        mat-menu-item
                        [routerLink]="[item.id]"
                        type="button"
                        matTooltip="Editar"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      >
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="generateVoiceProject(item)"
                        type="button"
                        matTooltip="Gerar"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      >
                        <mat-icon>playlist_play</mat-icon>
                        <span>Gerar</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="openDownloads(item)"
                        type="button"
                        matTooltip="Download"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      >
                        <mat-icon>get_app</mat-icon>
                        <span>Download</span>
                      </button>

                      <button
                        mat-menu-item
                        (click)="deleteProject(item)"
                        type="button"
                        matTooltip="Deletar"
                        aria-label="Button that displays a tooltip when focused or hovered over"
                      >
                        <mat-icon>close</mat-icon>
                        <span>Deletar</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
            </table>
          </div>
          <div class="allCards" *ngIf="!view_icon">
            <div class="grid-view" [style.width.vw]="displayCard">
              <mat-card
                class="card-min mat-elevation-z2"
                *ngFor="let project of obsFavorite | async; let i = index"
                [ngStyle]="{
                  'box-shadow':
                    projectDetail === project
                      ? '2px 2px 2px 2px rgba(142, 36, 170, .3)'
                      : '0px 3px 1px -2px rgba(0, 0, 0, .20), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12)'
                }"
              >
                <div class="card-icon-fav">
                  <mat-icon
                    (click)="selected(project)"
                    *ngIf="!project.favorite"
                    >star_border
                  </mat-icon>
                  <mat-icon
                    (click)="selected(project)"
                    *ngIf="project.favorite"
                    id="fav-color"
                    >star
                  </mat-icon>
                </div>

                <mat-card-actions (click)="cardShowInf(project)">
                  <button type="menu" class="show-id" mat-fab>
                    <span class="icon-id">#</span
                    ><span class="order-number">{{
                      datasource.data.indexOf(project) + 1
                    }}</span>
                  </button>
                </mat-card-actions>

                <mat-card-title (click)="cardShowInf(project)">{{
                  project.name
                }}</mat-card-title>
                <mat-card-subtitle (click)="cardShowInf(project)"
                  >{{ project.description }}
                </mat-card-subtitle>
                <mat-divider inset></mat-divider>
                <mat-progress-bar
                  color="accent"
                  *ngIf="processing && progressBarId == project.id"
                  mode="indeterminate"
                ></mat-progress-bar>

                <mat-card-actions>
                  <div class="card-actions mat-elevation-z8">
                    <button
                      [routerLink]="[project.id]"
                      mat-icon-button
                      type="button"
                      matTooltip="Editar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="generateVoiceProject(project)"
                      type="button"
                      matTooltip="Gerar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>playlist_play</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="openDownloads(project)"
                      type="button"
                      matTooltip="Download"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>get_app</mat-icon>
                    </button>

                    <button
                      mat-icon-button
                      (click)="deleteProject(project)"
                      type="button"
                      matTooltip="Deletar"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-card-actions>
              </mat-card>
            </div>
            <div
              class="cardInf"
              *ngIf="
                displayCard == 58 &&
                selectedIndex === 1 &&
                !isMobile &&
                !view_icon
              "
            >
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header
                    id="panelCard"
                    collapsedHeight="auto"
                    expandedHeight="auto"
                  >
                    <button mat-icon-button id="PanelIconFav">
                      <mat-icon
                        (click)="selected(projectDetail)"
                        *ngIf="!projectDetail?.favorite"
                      >
                        star_border</mat-icon
                      >
                      <mat-icon
                        (click)="selected(projectDetail)"
                        *ngIf="projectDetail?.favorite"
                        class="icon-color"
                        >star
                      </mat-icon>
                    </button>

                    <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                      Título projeto:
                    </mat-panel-title>

                    <mat-panel-title id="panelTitle" class="mat-headline">
                      {{ projectDetail?.name }}
                    </mat-panel-title>

                    <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                      Descrição:
                    </mat-panel-title>

                    <mat-panel-title id="panelSubitle" class="mat-subheading-2">
                      {{ projectDetail?.description }}
                    </mat-panel-title>

                    <div class="inf-contain-panel">
                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>description</mat-icon>
                            Scripts
                          </mat-card-title>
                          <mat-card-subtitle>{{
                            script.data.length
                          }}</mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>

                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>graphic_eq</mat-icon>
                            Moods
                          </mat-card-title>
                          <mat-card-subtitle
                            >{{ moodSizeID.length }}
                            <div class="mat-subheading-2">
                              /{{ moodList.length }}
                            </div>
                          </mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>

                      <mat-card class="inf-card-panel">
                        <mat-card-header>
                          <mat-card-title>
                            <mat-icon>record_voice_over</mat-icon>
                            Voices
                          </mat-card-title>
                          <mat-card-subtitle
                            >{{ voiceSizeID.length }}
                            <div class="mat-subheading-2">
                              /{{ voiceList.length }}
                            </div>
                          </mat-card-subtitle>
                        </mat-card-header>
                      </mat-card>
                    </div>
                  </mat-expansion-panel-header>

                  <ng-template matExpansionPanelContent>
                    <div class="line">
                      <mat-panel-title
                        style="color: rgba(0, 0, 0, 0.54); padding-top: 30px"
                      >
                        Data de criação:
                      </mat-panel-title>

                      <mat-panel-title
                        id="panelSubitle"
                        class="mat-subheading-2"
                      >
                        {{ projectDetail?.created | date: "dd/MM/yyyy" }}
                      </mat-panel-title>

                      <mat-panel-title style="color: rgba(0, 0, 0, 0.54)">
                        Data da ultima modificação:
                      </mat-panel-title>

                      <mat-panel-title
                        id="panelSubitle"
                        class="mat-subheading-2"
                      >
                        {{ projectDetail?.updated | date: "dd/MM/yyyy" }}
                      </mat-panel-title>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
              </mat-accordion>

              <div
                id="panelActions"
                class="card-actions mat-elevation-z8"
                *ngIf="projectDetail"
              >
                <button
                  [routerLink]="[projectDetail.id]"
                  mat-icon-button
                  type="button"
                  matTooltip="Editar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>edit</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="generateVoiceProject(projectDetail)"
                  matTooltip="Gerar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>playlist_play</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="openDownloads(projectDetail)"
                  matTooltip="Download"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>get_app</mat-icon>
                </button>

                <button
                  mat-icon-button
                  type="button"
                  (click)="deleteProject(projectDetail)"
                  matTooltip="Deletar"
                  aria-label="Button that displays a tooltip when focused or hovered over"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <mat-paginator
            [pageSizeOptions]="[6, 12, 24, 48, 96]"
            [pageSize]="24"
            showFirstLastButtons
          >
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!-- botao de adicionar -->
<button class="alabia-voice-project-add" mat-fab (click)="createProject()">
  <mat-icon>add</mat-icon>
</button>
