import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Customer } from '../crm/customer.service';
import { Bot } from './bot.model';

export const STAR_RATING: string = "star_rating";

export interface BotMemory {
  id: number;
  name: string;
  value: string;
  assignment: number | null;
  timestamp: number;
}

@Injectable({
  providedIn: 'root'
})
export class MemoryService {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(bot?: Bot, customer?: Customer): Observable<BotMemory[]> {
    if (bot && customer) {
      return this.http.get<BotMemory[]>(
        `${this.config.endpoint}/memory/${bot.id}/customer/${customer.id}`,
      )
    } else if (bot) {
      return this.http.get<BotMemory[]>(
        `${this.config.endpoint}/memory/${bot.id}`,
      )
    }
    return this.http.get<BotMemory[]>(
      `${this.config.endpoint}/memory`,
    )

  }
}
