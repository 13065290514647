import { Injectable } from '@angular/core';
import { BaseModel, BaseService } from '../auth/base.service';
import { ChecklistItem } from './checklist-item.service';
import { MachineExecution } from './machine-execution.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export interface ChecklistExecution extends BaseModel<number> {
  checked: boolean;
  execution: MachineExecution;
  item: ChecklistItem;
}

@Injectable({
  providedIn: 'root'
})
export class ChecklistExecutionService extends BaseService<ChecklistExecution, number> {
  protected path: string = `${this.config.endpoint}/hw/checklist/execution`;

  public getCheckListByExecution(id : number): Observable<ChecklistExecution[]>{
      const params = new HttpParams().set('id', id.toString());

    return this.http.get<ChecklistExecution[]>(`${this.path}/byExecution`, {params});

  }
}
