<div class="chat-simulator">
  <iframe [src]="safeURL" #chatFrame allow="microphone" class="chat-simulator__content"></iframe>
  <mat-tab-group class="chat-simulator__debugger" [disableRipple]="true">
    <mat-tab label="Detalhes da conversa">
      <div class="chat-simulator__debugger-code" #json></div>
    </mat-tab>

    <mat-tab label="Intenção associada">
      <div class="chat-simulator__debugger-code" #intent></div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- make calls -->

<!-- <audio #remoteAudio autoPlay></audio>
<audio #localAudio autoPlay></audio>
<audio #ringtone src="assets/sounds/incoming.mp3" loop></audio>
<audio #ringbacktone src="assets/sounds/outgoing.mp3" loop></audio>

<div id="sip-dialpad" class="dropdown-menu">
  <ul class="pad">
    <li class="paditem" *ngFor="let item of phone.padItems; let i = index">
      <button (click)="phone.sendDTMF(item)" class="btn btn-default digit">{{item}}</button>
    </li>
  </ul>

  <div class="digitCall">
    <button mat-fab color="accent" aria-label="Ligar">
      <mat-icon md-font-set="material-icons" (click)="phone.makeCall('2')">phone</mat-icon>
    </button>

    <div>
      <button ng-disabled="true" mat-fab color="primary" (click)="phone.hangup()" aria-label="Desligar">
        <mat-icon (click)="phone.hangup()">delete</mat-icon>
      </button>
    </div>
  </div>
</div> -->