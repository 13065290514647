import { MapUnit } from "../hw/map-unit.service";

export enum AuthorizationType {
  ADMIN = "ADMIN",
  HR = "HR",
  MANAGER = "MANAGER",
  AGENT = "AGENT",
  ROBOT = "ROBOT",
  VOICE = "VOICE",
  CLEANER = "CLEANER",
}

export interface Profile {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  default: AuthorizationType;
  location : MapUnit;
}


export enum Status {
  INFO_REQUIRED = "INFO_REQUIRED", OK = "OK"
}

export interface Organization {
  id?: number;
  name: string;
  slug: string;

  status: Status;

  enable_voices?: boolean;
}

export interface PermissionAuthority {
  authorization: AuthorizationType;
  scope: string;
}

export interface User {
  id: number;
  organization: Organization;
  email: string;
  password: string;

  details: Profile;
  authorities: PermissionAuthority[];
  status: string;
}

export interface UserDetailRequest {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  default: AuthorizationType;
}

export interface UserCreateRequest extends UserDetailRequest {
  password: string;
}
