<h1 mat-dialog-title>Enviar arquivo</h1>

<mat-dialog-content>
    <ul class="filelist-name">
        <li *ngFor="let file of getList()">{{ file.name }}</li>
    </ul>
    <div><small *ngIf="fileCount() > 0">Selected: {{ fileCount() }}</small></div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="uploadFile.click()">Selecionar</button>
    <button mat-button (click)="finish()">Enviar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>

    <mat-spinner *ngIf="sending" diameter="16"></mat-spinner>
    <small *ngIf="sending">Enviado: {{ sent }} de {{ fileCount() }}</small>
</mat-dialog-actions>
<input #uploadFile hidden style="display: none; visibility: hidden;" type="file" multiple
    (change)="selectFiles($event)">