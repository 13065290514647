import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Skill } from 'app/models/skill';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SkillsRequestService } from '../skill-manager/skills-request.service';

@Injectable({
  providedIn: 'root'
})
export class SkillService implements Resolve<any>{

  onSkillChanged: BehaviorSubject<any>;
  onSearchTextChanged: Subject<any>;
  onSelectedSkillsChanged: BehaviorSubject<any>;


  skillList: any[] = [];
  searchText: string = "";

  selectedSkills: string[] = [];


  constructor(
    private _skillsRequests: SkillsRequestService,
  ) {
    this.onSkillChanged = new BehaviorSubject([]);
    this.onSearchTextChanged = new Subject();
    this.onSelectedSkillsChanged = new BehaviorSubject([]);

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getSkills()]).then(([files]) => {
        this.onSearchTextChanged.subscribe(searchText => {
          this.searchText = searchText;
          this.getSkills();
        });
        resolve(this.skillList);
      }, reject);
    });
  }

  getSkills(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._skillsRequests.listSkills().subscribe((response?: any) => {
        if (!response) { resolve([]); return }
        if (this.searchText) {
          this.skillList = this._filter(response, this.searchText)
        } else {
          this.skillList = response;
        }

        this.onSkillChanged.next(this.skillList);
        resolve(this.skillList);
      }, reject);
    }
    );
  }

  private _filter(element: any[], searchTerm: string | RegExp) {
    return element.filter(it => new RegExp(searchTerm, "i").test(it.name));
  }

  updateSkills() {
    this.getSkills();
  }

  updateSkill(skill: Skill): void {
    if (skill.id) {
      this._skillsRequests.updateSkill(skill.id, skill).subscribe(
        res => this.getSkills(),
        err => alert('Erro ao editar dados do usuário')
      );
    }
  }

  deleteSkill(skill: Skill): void {
    this._skillsRequests.deleteSkill(skill.id || 0).subscribe(
      res => {
        const skillIndex = this.skillList.indexOf(skill);
        this.skillList.splice(skillIndex, 1);
        this.onSkillChanged.next(this.skillList);
      },

      err => alert('Não foi possível deletar usuário')
    );
  }

  toggleSelectedSkill(id: string): void {
    if (this.selectedSkills.length > 0) {
      const index = this.selectedSkills.indexOf(id);

      if (index !== -1) {
        this.selectedSkills.splice(index, 1);

        this.onSelectedSkillsChanged.next(this.selectedSkills);

        return;
      }
    }

    this.selectedSkills.push(id);

    this.onSelectedSkillsChanged.next(this.selectedSkills);
  }

  selectSkills(filterParameter?: any, filterValue?: any): void {
    this.selectedSkills = [];
    this.onSelectedSkillsChanged.next(this.selectedSkills);
  }

  deselectSkills(): void {
    this.selectedSkills = [];
    this.onSelectedSkillsChanged.next(this.selectedSkills);
  }

  deleteSelectedSkills(): void {
    for (const skillId of this.selectedSkills) {
      const skill = this.skillList.find(_skill => {
        return _skill.id === skillId;
      });
      const skillIndex = this.skillList.indexOf(skill);
      this.skillList.splice(skillIndex, 1);
    }
    this.onSkillChanged.next(this.skillList);
    this.deselectSkills();
  }

}

