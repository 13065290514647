import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { ApiLimit, AuthenticationService, LimitService, STTHistory, STTService } from 'onevoice';

function byId(a: STTHistory, b: STTHistory) {
  return b.id - a.id;
}

@Component({
  selector: 'alabia-stt',
  templateUrl: './stt.component.html',
  styleUrls: ['./stt.component.scss']
})
export class SttComponent implements OnInit {
  public icon: string = "mic";

  history: STTHistory[] = [];
  columnsToDisplay = [
    "id", "transcription", "play", "download", "delete"
  ]

  public datasource: MatTableDataSource<STTHistory> = new MatTableDataSource<STTHistory>();

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }

  public limitValue: number = 0;
  private files?: FileList;

  constructor(
    private service: STTService,
    private auth: AuthenticationService,
    private snack: MatSnackBar,
    private limits: LimitService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.auth.systemToken().then((token: any) => {
      this.service.list().subscribe((history: STTHistory[]) => {
        this.history = history.sort(byId);
        this.datasource.data = this.history;
      });
    });
    this.limits.check().subscribe(_ => {
      this.limits.show(ApiLimit.STT).subscribe(value => {
        this.limitValue = value;
      });
    });

  }

  deleteItem(item: STTHistory) {
    this.service.delete(item.id).subscribe((ok: Boolean) => {
      if (ok) {
        this.history = this.history.filter(value => value.id !== item.id);
        this.datasource.data = this.history;
      }
    });
  }

  submitAudio() {
    if (!this.files) {
      return;
    }
    for (let index = 0; index < this.files.length; index++) {
      let file = this.files[index];
      console.log(file);

      this.service.convert(file).subscribe(response => {

        this.history = [{
          transcription: response.results[0].text,
          id: 0,
          filename: "",
        }].concat(this.history);

        this.datasource.data = this.history;
        this.limits.show(ApiLimit.STT).subscribe(value => {
          this.limitValue = value;
        });
      }, err => {
        if (err.status == 429) {
          this.snack.open("A sua franquia de transcrição de áudio não é suficiente.", "OK");
        } else {
          this.snack.open("Aconteceu um erro durante a transcrição do áudio. Tente novamente.", "OK");
        }
      });
    }
    this.files = undefined;
  }

  selectFile(event: any) {
    this.files = event.target.files;
  }

  playItem(item: STTHistory): void {
    new Audio(`${environment.staticBaseURL}/${item.filename}`).play();
  }

  downloadUrl(item: STTHistory): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(`${environment.staticBaseURL}/${item.filename}`);
  }
}
