import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChecklistItem, ChecklistItemService, Machine, MachineMap, MachineMapService, MachineService, MachineTask, MachineTaskService, MapUnit, MapUnitService } from 'onevoice';

@Component({
  selector: 'main-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent {
  public form: FormGroup;
  public clForm: FormGroup = new FormGroup({
    "content": new FormControl("", Validators.required),
    "machine": new FormControl("", Validators.required),
    "area": new FormControl("", Validators.required),
  });
  public mapList : MachineMap[] = [];
  public allMapList : MachineMap[] = [];
  public machines : Machine[] = [];

  public unitList?: MapUnit[];
  public machineMap! : MachineMap ;
  public typeList : String[] = ['MANUAL']
  public typeSelected : String = 'MANUAL'
  public checklist?: ChecklistItem[];
  constructor(
    public dialog: MatDialog,
    public reference: MatDialogRef<EditComponent>,
    public tasks: MachineTaskService,
    public maps: MachineMapService,
    public checklists: ChecklistItemService,
    public mapUnitService : MapUnitService,
    public machineService : MachineService,
    @Inject(MAT_DIALOG_DATA) public data: Partial<MachineTask> = {},
  ) {

    this.listMachines();
    this.form = new FormGroup({
      "map": new FormControl(data.map || undefined, [Validators.required]),
      "name": new FormControl(data.name || "", [Validators.required]),
      "description": new FormControl(data.description || "", [Validators.required]),
      "type": new FormControl(data.type || "", [Validators.required]),

    }, []);
    console.log(data)
    this.maps.list().subscribe(mapList => {
      console.log(this.mapList)
      mapList.forEach(map=>{
        if(map.type == "MANUAL")
        this.mapList.push(map);
      console.log(this.mapList)
      })
      this.allMapList = this.mapList;
    });

    this.checklists.list().subscribe(list => {
      this.checklist = list.filter(value => data?.id && value.task?.id == data.id);
      console.log('checklist')
      console.log(this.checklist)
    })
    console.log(this.form.value.type)
    this.data.type? this.selectType(this.data.type) : this.selectType('');


    this.listUnit();
  }

  get title(): string {

    return (this.data.id) ? "Editar Tarefa" : "Criar Tarefa";

  }

  public listMachines(){
    this.machineService.list().subscribe(machines=>{
      this.machines = machines;
    })
  }

  public listUnit(){
    this.mapUnitService.list().subscribe( unitsList =>{
      this.unitList = unitsList;
      console.log(this.unitList)
      console.log(this.machineMap)

    })
    this.form.value
  }
  public getMapByUnit(){

      this.mapList.forEach(map=>{
        if(map.unit.id == this.form.get("unit")?.value.id){
          this.form.value
        }
      })
    }

  public submitTask(): void {
    console.log(this.data)
    console.log(this.form.value)
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
    this.tasks
    console.log(this.data)
  }

  public selectType(type : String){
    console.log(type);
    if(type == undefined){
      return
    }
    this.typeSelected = type;

  }

  public compareMap(map1: MachineMap, map2: MachineMap) {
    return map1.id == map2.id
  }
  public compareMachine(map1: Machine, map2: Machine) {
    return map1.id == map2.id
  }
  public compareUnit(unit1:MapUnit, unit2:MapUnit) {
    return unit1 && unit2 ? unit1.id === unit2.id : unit1 === unit2;
  }
  public addChecklist() {

    let item: Partial<ChecklistItem> = {
      task: this.data,
      ...this.clForm.value,
    };
    if(item.task?.type == "MANUAL"){
      item.machine = undefined;
     }
     console.log(item)
    this.checklists.create(item).subscribe(created => {
      if (this.checklist) {
        this.checklist.push(created);
      }
      this.clForm.reset({ content: "" });
    });
  }

  public deleteChecklist(checklist: ChecklistItem) {
    this.checklists.delete(checklist.id).subscribe(_ => {
      this.checklist = this.checklist?.filter(value => value.id != checklist.id);
    })
  }
}
