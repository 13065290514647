import { Component, ComponentFactoryResolver, EventEmitter, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ChannelType } from 'onevoice';
import { BehaviorSubject } from 'rxjs';
import { FormatsFor } from '../formats';

@Component({
  selector: 'oneui-data-container',
  templateUrl: './data-container.component.html',
  styleUrls: ['./data-container.component.css']
})
export class DataContainerComponent {
  @Input()
  public data: any;
  @Input()
  public channel: ChannelType = ChannelType.CHAT;

  @Output()
  public select: EventEmitter<string> = new EventEmitter<string>();

  public showRaw: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @ViewChild('messagecontainer', { read: ViewContainerRef })
  set entry(entry: ViewContainerRef) {
    let formatList = FormatsFor(this.channel);
    for (const format of formatList) {
      if (format.component) {
        console.log("Create factory");
        let factory = this.resolver.resolveComponentFactory(format.component);
        console.log("Create component");
        let reference = entry.createComponent(factory);
        console.log("Use reference", reference.instance);
        reference.instance.updater.next(JSON.parse(this.data));
        reference.instance.select = this.select;
        this.showRaw.next(false);
        return;
      }
    }
  }

  constructor(private resolver: ComponentFactoryResolver) {
    console.log("PASSOU AQUI PATRAO");
  }

  public get rawContent(): string {
    return this.data;
  }
}
