import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChartOptions } from 'chart.js';
import { Colors, Label, SingleDataSet } from 'ng2-charts';
import { AssignmentsService, BotService, ConclusionTagService, IntentService, MemoryService, ReportService as ReportServiceApi, UserService } from 'onevoice';
import { Subject } from 'rxjs';
import { ChannelService } from '../../api/channel.service';
import { ReportApiRequestService } from '../../api/report-api-request.service';
import { ReportService } from '../../api/report.service';
import * as options from '../options';



@Component({
  selector: 'main-simple-dashboard',
  templateUrl: './simple-dashboard.component.html',
  styleUrls: ['./simple-dashboard.component.scss']
})
export class SimpleDashboardComponent implements OnInit, OnDestroy {
  public appColors: Colors[] = options.colors2;
  public widgetConfig: ChartOptions = options.configWidgets;
  public colors = [options.colors];

  displayedColumns: string[] = [
    'id',
    'firstName'
  ];

  latestsCustomersDataSource = new MatTableDataSource();

  public cppData: SingleDataSet = [];
  public cppLabels: Label[] = [];
  public cppConfig: ChartOptions = options.pieConfig;

  public cpmData: SingleDataSet = [];
  public cpmLabels: Label[] = [];

  public mpmData: SingleDataSet = [];
  public mpmLabels: Label[] = [];
  public mpmConfig: ChartOptions = options.config1;
  public mpmColors: Colors[] = options.colors1;

  public tiData: SingleDataSet = [];
  public tiLabels: Label[] = [];
  public tiConfig: ChartOptions = options.pieConfig;

  public upmData: SingleDataSet = [];
  public upmLabels: Label[] = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private report: ReportService,
    private intents: IntentService,
    private channels: ChannelService,
    private reportServiceApi: ReportServiceApi,
    private reportApiRequestService: ReportApiRequestService,
    private assignments: AssignmentsService,
    private userService: UserService,
    private tagService: ConclusionTagService,
    private botService: BotService,
    private memoryService: MemoryService,

  ) { }

  ngOnInit(): void {
    this.channels.list().subscribe(channels => {
      this.report.conversationsPerChannel().subscribe(data => {
        const labels: string[] = [];
        const counts: number[] = [];

        data.forEach(([channel, count]) => {
          const channelSearched = channels.find(value => value.id === channel);

          if (channelSearched) {
            labels.push(channelSearched.name);
            counts.push(count);
          }
        });

        this.cppData = counts;
        this.cppLabels = labels;
      });
    });

    this.report.conversationsPerMonth().subscribe(data => {
      const labels: string[] = [];
      const counts: number[] = [];
      data.forEach(([month, count]) => {
        counts.push(count);
        labels.push(month);
      });
      this.cpmData = counts;
      this.cpmLabels = labels;
    });

    this.report.messagesPerMonth().subscribe(data => {
      const labels: string[] = [];
      const counts: number[] = [];
      data.forEach(([month, count]) => {
        counts.push(count);
        labels.push(month);
      });
      this.mpmData = counts;
      this.mpmLabels = labels;
    });

    this.intents.list().subscribe(intents => {
      this.report.topIntents().subscribe(data => {
        const labels: string[] = [];
        const counts: number[] = [];
        data.sort((a, b) => b[1] - a[1]);
        data.forEach(([label, count]) => {
          counts.push(count);
          let intent = intents.find(intent => intent.id === label)
          if (intent) {
            labels.push(intent.name || "");
          }
        });
        this.tiData = counts//.sort((a, b) => b - a);
        this.tiLabels = labels;
      });
    });

    this.report.usersPerMonth().subscribe(data => {

      const labels: string[] = [];
      const counts: number[] = [];
      data.forEach(([month, count]) => {
        counts.push(count);
        labels.push(month);
      });
      this.upmData = counts;
      this.upmLabels = labels;
    });
    let subscription = this.reportApiRequestService.onLastestCustomerChanges.subscribe((data) => {
      this.latestsCustomersDataSource.data = data;
    })
    this._unsubscribeAll.next((_: any) => {
      subscription.unsubscribe();
    })
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
