import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { fuseAnimations } from '@fuse/animations';
import { SkillManagerDialogComponent } from './skill-manager-dialog/skill-manager-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { SkillService } from '../api/skill.service'
import { Skill } from 'app/models/skill';
import { SkillsRequestService } from './skills-request.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'alabia-skill-manager',
  templateUrl: './skill-manager.component.html',
  styleUrls: ['./skill-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class SkillManagerComponent implements OnInit {
  dialogRef: any;
  skills: Skill[] = [];
  searchInput: FormControl;
  hasSelectedSkills: boolean = false;
  private _unsubscribeAll: Subject<any>;


  constructor(
    private _matDialog: MatDialog,
    private _skillService: SkillService,
    private _skillRequestService: SkillsRequestService,
  ) {
    this.searchInput = new FormControl('');
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._skillService.onSelectedSkillsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedSkills => {
        this.hasSelectedSkills = selectedSkills.length > 0;
      });

    this.searchInput.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchText => {
        this._skillService.onSearchTextChanged.next(searchText);
      });
  }

  newSkill(): void {
    this.dialogRef = this._matDialog.open(SkillManagerDialogComponent, {
      panelClass: 'skill-form-dialog',
      data: {
        action: 'new'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: Skill) => {
        if (!response) {
          return;
        }

        this._skillRequestService.createSkill(response)
          .subscribe(
            res => this._skillService.updateSkills(),
            err => alert('Erro ao adicionar as permissões')
          ),
          (err: any) => alert('Erro ao criar usuário!')
      });
  }
}
