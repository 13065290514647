import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { CustomerInformationService } from 'onevoice';
import { Customer } from '../../models/customer';
import { BackendApiRequestService } from '../api/backend-api-request.service';
import { CustomerFilterDialogComponent } from './customer-filter-dialog/customer-filter-dialog.component';

@Component({
  selector: 'alabia-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})

export class CustomerComponent implements OnInit {
  dialogRef: any;
  viewCustomer: boolean = false;
  customerData: Customer[] = [];
  filterData: any = {};
  customerInfo: any[] = [];

  public search: string = "";

  constructor(
    private _matDialog: MatDialog,
    private _backendApiRequestService: BackendApiRequestService,
    private _customerInformationService: CustomerInformationService,

  ) {
    this.customerInfo = this._backendApiRequestService.customerInformation;
  }

  ngOnInit() { }

  filter(): void {
    this.dialogRef = this._matDialog.open(CustomerFilterDialogComponent, {
      panelClass: 'customer-dialog',
      data: {
        filterData: this.filterData
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (!response) {
          return;
        }

        this.filterData = response;
      });
  }

  handleNavigation(event: any) {
    const data = event.data;
    const action = event.action;

    switch (action) {
      case 'open':
        return new Promise(() => {
          this._customerInformationService.list(data.id).subscribe(
            res => {
              this.customerInfo = res;
              this.customerData = data;
              this.viewCustomer = true;
            },
            err => console.error('not ok'),
          );
        });

      case 'close':
        this.customerData = [];
        this.viewCustomer = false;
        break;
    }
    return new Promise(() => { });
  }
}
