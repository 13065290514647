import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, UserService, WorkScheduler, WorkSchedulerService } from 'onevoice';
import { WorkSchedulerComponent } from '../work-scheduler.component';

@Component({
  selector: 'main-edit-work-scheduler',
  templateUrl: './edit-work-scheduler.component.html',
  styleUrls: ['./edit-work-scheduler.component.scss']
})
export class EditWorkSchedulerComponent {

  public form: FormGroup;
  public scheduler? : WorkScheduler;
  public days: number[] = [];



  constructor(
    public reference: MatDialogRef<WorkSchedulerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<User> = {},
    private workscheduler: WorkSchedulerService,
    private userService : UserService,

  ) {
    if(data.id!=undefined){this.getScheduler(data.id)};
    this.form = new FormGroup({
      "start": new FormControl(this.scheduler?.start || undefined, [Validators.required]),
      "startPause": new FormControl(this.scheduler?.startPause || "", [Validators.required]),
      "endPauseTime": new FormControl(this.scheduler?.endPauseTime || "", [Validators.required]),
      "end": new FormControl(this.scheduler?.end || "", [Validators.required]),
      "user": new FormControl(this.scheduler?.user || data, [Validators.required]),
      "repeat": new FormControl(this.scheduler?.repeat || this.days, [Validators.required]),

    }, []);

  }

  public getScheduler(user : number){
      this.workscheduler.list().subscribe(list=>{
        list.forEach(single=>{
          if(single.user?.id == user){
            this.scheduler = single;
          }
        })
      })
  }
  insertDay(day: number): void {

    if (this.days.includes(day)) {
      this.days = this.days.filter(single => single !== day)
    } else {
      this.days.push(day)
    }
    this.days.sort((a, b) => a - b);

    console.log(this.days)
  }
  public ngSubmit() {
    console.log(this.form.value)
    this.workscheduler.create(this.form.value).subscribe(res=>{
      console.log(res)
    })
    this.reference.close({

      ...this.scheduler,
      ...this.form.value,
    });
  }

}
