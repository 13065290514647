<div id="products" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16">
                    power
                </mat-icon>
                <span class="logo-text h1">
                    Canais
                </span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <div class="content">

            <div class="bot-details">
                <alabia-sidenav [bot]="bot"></alabia-sidenav>
            </div>

            <div class="content-card">
                <mat-tab-group mat-stretch-tabs>
                    <mat-tab label="Meus Canais">
                        <div class="channel-container">
                            <ng-container *ngFor="let chL of channelList">
                                <ng-container *ngFor="let ch_detail of chL.channels">
                                    <div class="card-channel">
                                        <div class="card-channel--icon" [ngClass]="chL.type.class">
                                            <img src="{{ chL.type.icon_path }}" alt="{{ chL.type.name }}">
                                        </div>
                                        <p class="card-channel--title">{{ch_detail.name}}</p>

                                        <button mat-icon-button class="card-channel--add" [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    </div>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item
                                            (click)="editChannel(ch_detail, chL.type.name)">Editar</button>
                                        <button mat-menu-item
                                            (click)="deleteChannel(ch_detail, chL.type.name)">Remover</button>
                                    </mat-menu>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-tab>
                    <mat-tab label="Configurar Novo Canal">
                        <div class="channel-container">
                            <ng-container *ngFor="let type of channelTypes">
                                <div class="card-channel" *ngIf="type.isEnabled" (click)="newChannel(type.name)">
                                    <div class="card-channel--icon" [ngClass]="type.class">
                                        <img src="{{type.icon_path}}" alt="{{ type.name }}">
                                    </div>
                                    <p class="card-channel--title">{{type.name}}</p>
                                    <p class="card-channel--add">
                                        <mat-icon>add</mat-icon>
                                    </p>
                                </div>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
    <!-- / CENTER -->
</div>