<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button aria-label="Close dialog" (click)="matDialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form *ngIf="skillForm" [formGroup]="skillForm">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nome</mat-label>
                    <mat-icon matSuffix class="secondary-text">widgets</mat-icon>
                    <input matInput name="name" formControlName="name" minlength="2" required>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div *ngIf="skillForm" mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="action === 'edit'" mat-button class="mr-8" (click)="matDialogRef.close(['delete',skillForm])"
            aria-label="Delete" matTooltip="Delete">
            DELETAR
        </button>

        <button *ngIf="action !== 'edit'" mat-button class="save-button" [disabled]="skillForm.invalid"
            aria-label="SAVE" (click)="saveData(true)">
            ADICIONAR
        </button>

        <button *ngIf="action === 'edit'" mat-button class="save-button" [disabled]="skillForm.invalid"
            aria-label="SAVE" (click)="saveData(false)">
            SALVAR
        </button>
    </div>
</div>