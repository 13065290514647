<h2 mat-dialog-title>Downloads</h2>

<mat-dialog-content>
    <mat-spinner class="alabia-view-spinner" *ngIf="!executions"></mat-spinner>

    <table mat-table class="alabia-wide-table" [dataSource]="datasource" *ngIf="!!executions">
        <ng-container matColumnDef="icons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let execution">
                <button mat-mini-fab type="menu" disabled>
                    <mat-icon>folder_open</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let execution">{{ getMessage(execution.status) }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th class="detail-mood" mat-header-cell *matHeaderCellDef>Data</th>
            <td class="detail-mood" mat-cell *matCellDef="let execution">
                {{ execution.created | date:'dd/MM/YYYY HH:mm' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="link">
            <th class="detail-voice" mat-header-cell *matHeaderCellDef>Link</th>
            <td class="detail-voice" mat-cell *matCellDef="let execution">
                <a mat-raised-button [href]="URL(execution)" download="projeto.zip">
                    Download
                </a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let execution; columns: columns"></tr>
    </table>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button mat-dialog-close>Voltar</button>
</mat-dialog-actions>