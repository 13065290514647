<div id="widget-creator" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- APP TITLE -->
    <div fxLayout="row" fxLayoutAlign="start center">

      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">color_lens
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Criador de Widget
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <!-- CENTER -->
    <div class="center p-24 pb-56" fusePerfectScrollbar>

      <!-- CONTENT -->
      <div class="content">
        <div class="general-column">
          <div #form class="form">
            <mat-form-field appearance="outline">
              <mat-label for="organization">Cor primária</mat-label>
              <input matInput type="color" id="organization" name="organization" [ngModel]="primary_color"
                (ngModelChange)="onColorChange('primary_color',$event)">
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="bots && bots.length > 0">
              <mat-label>Bot</mat-label>
              <mat-select [ngModel]="selected_bot" (ngModelChange)="onBotChange($event)">
                <mat-option *ngFor="let option of bots" [value]="option.id">
                  {{ option.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <p class="withoutBots" *ngIf="!bots || bots.length === 0">
              Não há nenhum bot criado. <a href="#" routerLink="../bots"><b>Clique aqui</b></a> para criar um robô!
            </p>

          </div>

          <div class="preview">
            <p class="title">Pré-visualização</p>
            <!-- BUTTON PREVIEW -->
            <div class="button-container">
              <div class="button-preview">
                <p class="title">Abrir</p>
                <div class="circle" [style.background-color]="primary_color">
                  <svg id="alabia-chat-icon" width="48px" height="48px" viewBox="0 0 40 40" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Generator: Sketch 48 (47235) - http://www.bohemiancoding.com/sketch -->
                    <title>alabia</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="alabia" [style.fill]="secondary_color" fill-rule="nonzero">
                        <g id="brand-logo-(1)" transform="translate(9.000000, 9.000000)">
                          <path
                            d="M11.1710324,0 C8.50311934,0 5.73814719,0.419047618 3.8539867,1.55833333 C0.797837127,3.4047619 -0.0963201702,7.01071429 0.00801840166,10.3309524 C0.0977981032,13.202381 0.88276387,16.2857143 2.96832206,18.4071429 C4.95803438,20.4297619 9.04907698,21.3392857 11.8383139,21.3392857 C11.9644908,21.3392857 12.0979471,21.4285714 12.0979471,21.5940476 L12.0979471,24.4916667 C12.0979471,24.777381 12.3248228,25 12.6160002,25 C12.6329856,25 12.6487577,24.9964286 12.6645298,24.9952381 C12.7749346,24.9857143 12.8719937,24.9452381 12.949641,24.8821429 C14.3557853,23.7035714 15.7607163,22.5035714 17.1171177,21.2702381 C18.4177101,20.0869048 19.8905825,18.8714286 20.907277,17.4547619 C23.0522839,14.4666667 23.3701526,10.2416667 22.6591944,6.73928571 C21.9749275,3.36904761 19.5351035,1.175 16.1149821,0.476190475 C14.7076247,0.189285714 12.9617734,0 11.1710324,0 M11.1710324,1.21309524 C12.7737213,1.21309524 14.4394988,1.37261905 15.8638416,1.66309524 C18.8945131,2.28214286 20.876946,4.16904761 21.4471685,6.97619047 C21.7953681,8.69166668 21.8584565,10.472619 21.6303676,12.1261905 C21.3780138,13.9488095 20.7944457,15.5059524 19.8966487,16.7559524 C19.1274551,17.8285714 18.0185544,18.8202381 16.946051,19.7797619 C16.7203885,19.9809524 16.4959392,20.1821429 16.2763429,20.3809524 C15.2802735,21.2880952 14.2720718,22.1619048 13.3330246,22.9595238 L13.3330246,21.5940476 C13.3330246,20.7845238 12.6633166,20.127381 11.8383139,20.127381 C9.19102597,20.127381 5.4942861,19.2285714 3.8576264,17.5654762 C1.82059778,15.4928571 1.31103731,12.4666667 1.24309592,10.2940476 C1.177581,8.19166668 1.51364826,4.39642857 4.50306966,2.59047619 C5.97230236,1.70238095 8.34054527,1.21309524 11.1710324,1.21309524"
                            id="Fill-20"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="button-preview">
                <p class="title">Fechar</p>
                <div class="circle" [style.background-color]="primary_color">
                  <svg id="alabia-chat-close-icon" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Close" [style.fill]="secondary_color" fill-rule="nonzero">
                        <g id="close" transform="translate(14.000000, 14.000000)">
                          <path
                            d="M7.62601627,5.78918918 L11.8536585,1.54054054 C12.0487805,1.34594595 12.0487805,1.05405406 11.8536585,0.859459461 L11.203252,0.178378378 C11.00813,-0.0162162161 10.7154471,-0.0162162161 10.5203252,0.178378378 L6.26016258,4.42702704 C6.13008127,4.55675677 5.93495933,4.55675677 5.80487802,4.42702704 L1.54471545,0.145945946 C1.34959349,-0.0486486486 1.05691057,-0.0486486486 0.861788617,0.145945946 L0.178861788,0.827027029 C-0.0162601625,1.02162162 -0.0162601625,1.31351352 0.178861788,1.50810811 L4.43902439,5.75675678 C4.56910569,5.88648652 4.56910569,6.08108111 4.43902439,6.21081085 L0.146341463,10.4918919 C-0.0487804876,10.6864865 -0.0487804876,10.9783784 0.146341463,11.172973 L0.829268292,11.8540541 C1.02439024,12.0486486 1.31707317,12.0486486 1.51219512,11.8540541 L5.77235773,7.60540544 C5.90243904,7.4756757 6.09756098,7.4756757 6.22764229,7.60540544 L10.4878049,11.8540541 C10.6829268,12.0486486 10.9756098,12.0486486 11.1707317,11.8540541 L11.8536585,11.172973 C12.0487805,10.9783784 12.0487805,10.6864865 11.8536585,10.4918919 L7.62601627,6.24324324 C7.49593496,6.1135135 7.49593496,5.91891892 7.62601627,5.78918918 Z"
                            id="path-1"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <!-- BUTTON PREVIEW -->
          </div>
        </div>
        <div class="snippet-column">
          <div class="snippet">
            <div class="snippet-bar">
              Código
              <button class="snippet-bar copy-button" *ngIf="selected_bot !== null && selected_bot > 0"
                (click)="copyToClipboard(snippet)">
                <mat-icon>filter_none</mat-icon> Copiar
              </button>
            </div>
            <textarea #snippet>
&lt;script src="https://static.alabiabot.com/widget/alabia-chat.js"&gt;&lt;/script&gt;
&lt;script&gt;
  window.onload = function () &#123;
    new AlabiaChat()
    .withAppKey({{selected_bot}})
    .withEnvironment("production")
    .withButton(&#123; color: '{{primary_color}}' &#125;)
    .build();
  &#125;
&lt;/script&gt;</textarea>
          </div>
        </div>
      </div>
      <!-- /CONTENT -->
    </div>
    <!-- / CENTER -->
  </div>
  <!-- / CONTENT-->
</div>