import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule, Routes } from '@angular/router';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChatStartComponent } from 'app/alabia/chat/chat-start/chat-start.component';
import { ChatViewComponent } from 'app/alabia/chat/chat-view/chat-view.component';
import { ChatComponent } from 'app/alabia/chat/chat.component';
import { ChatService } from 'app/alabia/chat/chat.service';
import { ChatChatsSidenavComponent } from 'app/alabia/chat/sidenavs/left/chats/chats.component';
import { ChatLeftSidenavComponent } from 'app/alabia/chat/sidenavs/left/left.component';
import { ChatContactSidenavComponent } from 'app/alabia/chat/sidenavs/right/contact/contact.component';
import { ChatRightSidenavComponent } from 'app/alabia/chat/sidenavs/right/right.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { FormatsModule } from 'one-ui';
import { ToggleBtnModule } from './../../shared/toggle-btn/toggle-btn.module';
import { EllipsisPipe } from './ellipsis';
import { ResponseSelectorComponent } from './response-selector/response-selector.component';
import { ChatFilterPipe } from './sidenavs/left/chats/chat-filter.pipe';
import { ChatItemComponent } from './sidenavs/left/chats/chat-item/chat-item.component';
import { TagCreateComponent } from './tag-create/tag-create.component';
import { TagDialogComponent } from './tag-dialog/tag-dialog.component';
import { UploadComponent } from './upload/upload.component';


const routes: Routes = [
    {
        path: '**',
        component: ChatComponent,
        children: [],
    }
];

@NgModule({
    declarations: [
        ChatComponent,
        ChatViewComponent,
        ChatStartComponent,
        ChatChatsSidenavComponent,
        ChatLeftSidenavComponent,
        ChatRightSidenavComponent,
        ChatContactSidenavComponent,
        ChatItemComponent,
        TagDialogComponent,
        UploadComponent,

        EllipsisPipe,
        TagCreateComponent,
        ResponseSelectorComponent,
        ChatFilterPipe,
    ],
    entryComponents: [
        TagDialogComponent,
        UploadComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatRadioModule,
        MatSidenavModule,
        MatTabsModule,
        MatToolbarModule,
        MatDialogModule,
        MatOptionModule,
        MatSelectModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatListModule,
        MatSelectFilterModule,
        MatStepperModule,
        ToggleBtnModule,
        MatSlideToggleModule,
        MatBottomSheetModule,
        FormatsModule,
        PickerModule,

        FuseSharedModule
    ],
    providers: [ChatService]
})
export class ChatModule { }
