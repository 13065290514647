<form [formGroup]="form" (ngSubmit)="submitData()">
  <h1 mat-dialog-title>{{ isEdit ? 'Atualizar' : 'Criar' }}</h1>

  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Tipo de Redirecionamento</mat-label>
      <mat-select formControlName="type" (selectionChange)="filterTasks($event.value)">
        <mat-option *ngFor="let type of redirectTypes" [value]="type">
          {{ RedirectTypeTexts[type] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="type === 'MANUAL_TASK'">
      <mat-label> Unidade: </mat-label>
      <mat-select (selectionChange)="selectUnit($event)">
        <mat-option *ngFor="let unit of unitList" [value]="unit">
          {{ unit.name }}
        </mat-option>
        <mat-option>
          Todos
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="form.controls.type.value == RedirectType.MANUAL_TASK">
      <mat-label>Alvo</mat-label>
      <mat-select formControlName="destination">
        <mat-option *ngFor="let task of taskList" [value]="task.id">
          {{ task.map.title }} - {{ task.name }} - {{ task.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="form.controls.type.value == RedirectType.MAINTENANCE_TASK">
      <mat-label>Alvo</mat-label>
      <mat-select formControlName="destination">
        <mat-option *ngFor="let task of taskList" [value]="task.id">
          {{ task.map.title }} - {{ task.name }} - {{ task.description}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type="submit">Salvar</button>
    <button mat-button [mat-dialog-close]="undefined">Fechar</button>
  </mat-dialog-actions>
</form>
