import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from '../auth/user.model';
import { OwnableService } from '../auth/ownable.service';
import { CustomerChannelType } from '../crm/customer-channel.model';
import { IdentifierType } from '../crm/customer-identifier.model';
import { Bot } from './bot.model';


export interface PublicBot {
  foreground: string;
  background: string;
  background2: string;

  identifiers: IdentifierType[];
  contacts: CustomerChannelType[];
  bannerText: string;
  bannerColor: string;
}

@Injectable({
  providedIn: 'root'
})
export class BotService extends OwnableService<Bot> {
  protected path: string = `${this.config.endpoint}/api/bots`;

  public changeStatus(bot: Bot, status: boolean): Observable<Bot> {
    const change = status ? 'ENABLED' : 'DISABLED';
    return this.http.post<Bot>(`${this.path}/${bot.id}/status`, JSON.stringify(change), {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  public setFeedback(bot: Bot, feedback?: number): Observable<Boolean> {
    let data = new FormData();
    if (feedback) {
      data.set("feedback", `${feedback}`);
    }
    return this.http.post<Boolean>(`${this.path}/${bot.id}/feedback`, data);
  }

  public publicInfo(botid: number): Observable<PublicBot> {
    return this.http.get<PublicBot>(`${this.path}/${botid}/public`);
  }

  public upload(botid: number, file: File): Observable<Boolean> {
    let form = new FormData();
    form.set("file", file);
    return this.http.post<Boolean>(`${this.path}/${botid}/avatar`, form)
  }

  public uploadBanner(botid: number, file: File): Observable<Boolean> {
    let form = new FormData();
    form.set("file", file);
    return this.http.post<Boolean>(`${this.path}/${botid}/banner`, form)
  }

  public uploadLogo(botid: number, file: File): Observable<Boolean> {
    let form = new FormData();
    form.set("file", file);
    return this.http.post<Boolean>(`${this.path}/${botid}/logo`, form)
  }


  public avatar(botid: number, defaultAvatar: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.path}/${botid}/avatar`, { responseType: "text" }).subscribe(
        value => { resolve(value) },
        error => { resolve(defaultAvatar) },
      );
    });
  }

  public banner(botid: number, defaultBanner: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.path}/${botid}/banner`, { responseType: "text" }).subscribe(
        value => { resolve(value) },
        error => { resolve(defaultBanner) },
      );
    });
  }

  public logo(botid: number, defaultBanner: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.path}/${botid}/logo`, { responseType: "text" }).subscribe(
        value => { resolve(value) },
        error => { resolve(defaultBanner) },
      );
    });
  }



  public clone(bot: Bot, org: Organization) {
    let form = new FormData();
    form.set("id", `${bot.id}`);
    form.set("org", `${org.id}`);
    return this.http.post<Boolean>(`${this.path}/clone`, form);
  }
}
