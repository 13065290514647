import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Organization } from './user.model';


export interface Plan {
  id: number;
  title: string;
  description: string;
  price: number;

  active: boolean;
}

export interface Subscription {
  id: number;
  organization: Organization;
  plan: Plan;

  start: number;
  end: number;
}



@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: ApiConfiguration) { }

  public planLists(): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${this.config.endpoint}/plans`, {
      observe: "body",
    });
  }

  public list(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`${this.config.endpoint}/subscriptions`, {
      observe: "body",
    });
  }
}
