<!-- SIDENAV HEADER -->
<div class="sidenav-header" *ngIf="contact">

    <!-- CONTACT TOOLBAR -->
    <mat-toolbar class="accent">

        <!-- TOOLBAR TOP -->
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">

            <div>Informações de Contato</div>

            <button mat-icon-button fuseMatSidenavToggler="chat-right-sidenav" aria-label="close">
                <mat-icon>close</mat-icon>
            </button>

        </mat-toolbar-row>
        <!-- / TOOLBAR TOP -->

        <!-- TOOLBAR BOTTOM -->
        <mat-toolbar-row class="toolbar-bottom" fxLayout="column" fxLayoutAlign="center center">
            <!-- <img [src]="contact.avatar" class="avatar contact-avatar huge" alt="{{contact.firstName}}" /> -->
            <div class="contact-name my-8">{{contact.firstName}} {{ contact.lastName }}</div>
            <!-- <div class="contact-history">
                <p class="contact-history-title">Resumo do último atendimento</p>
                <p class="contact-history-date">Data: 00/00/0000</p>
                <p class="contact-history-subject">Motivo: XPTO</p>
                <p class="contact-history-agent">Agente: XPTO</p>
                <p class="contact-history-channel">Canal: XPTO</p>
            </div> -->
        </mat-toolbar-row>
        <!-- / TOOLBAR BOTTOM -->
    </mat-toolbar>
    <!-- / CONTACT TOOLBAR -->
</div>

<!-- SIDENAV CONTENT -->
<div class="sidenav-content" fxFlex fxLayout="column" *ngIf="contact">

    <div *ngFor="let part of contact.identifiers" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ part.type }}</mat-label>
            <input matInput name="campo" [value]="part.identifier">
        </mat-form-field>
    </div>

    <div *ngFor="let part of getContacts()" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ part.channel }}</mat-label>
            <input matInput name="campo" [value]="part.identifier" disabled>
        </mat-form-field>
    </div>

    <div *ngFor="let part of informationList" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ part.information.name }}</mat-label>
            <input matInput name="campo" [value]="part.content">
        </mat-form-field>
    </div>

    <mat-divider></mat-divider>
    <h3 matSubheader>Informações da Conversa</h3>

    <div *ngFor="let memory of memoryList" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{ memory.name }}</mat-label>
            <input matInput name="campo" [value]="memory.value">
        </mat-form-field>
    </div>

</div>