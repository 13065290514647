import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Graph } from 'onevoice';
import { CustomDashboardService } from './custom-dashboard.service';
import { GraphEditorComponent } from './graph-editor/graph-editor.component';

function ByOrder(graph1: Graph, graph2: Graph): number {
  return graph1.id - graph2.id;
}

@Component({
  selector: 'main-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.scss']
})
export class CustomDashboardComponent implements OnInit {
  @Input()
  public dashboard?: string;
  @Input()
  public graphList?: Graph[];

  constructor(
    public service: CustomDashboardService,
    private dialog: MatDialog,
    public cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.graphList) {
      this.graphList.sort(ByOrder);
    }
  }

  public editGraph(graph: Partial<Graph>) {
    let reference = this.dialog.open<GraphEditorComponent, Partial<Graph>, Graph>(GraphEditorComponent, {
      width: "95%",
      maxWidth: "500px",
      data: graph,
    });

    reference.afterClosed().subscribe(graph => {
      if (this.graphList && graph) {
        this.graphList = this.graphList.filter(value => value.id !== graph.id).concat([graph]).sort(ByOrder);
        this.cdr.detectChanges();
      }
    })
  }

  public delGraph(graph: Graph) {
    let reference = this.dialog.open<FuseConfirmDialogComponent>(FuseConfirmDialogComponent);
    reference.componentInstance.confirmMessage = "Tem certeza que deseja removed este gráfico?";

    reference.afterClosed().subscribe(ok => {
      if (ok) {
        this.service.remove(graph).subscribe(_ => {
          if (!this.graphList) {
            return;
          }
          this.graphList = this.graphList.filter(value => value.id !== graph.id).sort(ByOrder);
        });
      }
    });
  }

  public getStyle(graph: Graph) {
    return {
      "grid-column": `span ${graph.span}`,
      "grid-row": `span ${graph.span}`,
    }
  }
}
