<h1 mat-dialog-title>Enviar Arquivo</h1>

<mat-dialog-content>
    <button mat-button (click)="mediaUploadInput.click()">Selecionar</button>

    <mat-list>
        <mat-list-item *ngFor="let file of files">{{ file.name }}</mat-list-item>
    </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="uploadFiles()">Salvar</button>
    <button mat-button [mat-dialog-close]>Cancelar</button>
</mat-dialog-actions>

<input #mediaUploadInput hidden style="display: none; visibility: hidden;" type="file">