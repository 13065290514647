<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>assessment</mat-icon>
    <h1 class="logo-text">Gerenciamento</h1>
    <br />
  </div>

  <div class="header-range">
    <div class="mat-headline" id="title-dashboard">Visão Geral</div>

    <div id="form-field">
      <mat-form-field
        appearance="outline"
        color="accent"
        style="padding-right: 10px; width: 200px"
      >
        <mat-label>Histórico</mat-label>
        <!-- [(value)]="historicView" -->
        <mat-select
          [(ngModel)]="historicView"
          (selectionChange)="historicViewChange($event.value)"
        >
          <mat-option value="all">Geral</mat-option>
          <mat-option value="today">Hoje</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Selecione o intervalo</mat-label>
        <mat-date-range-input
          [formGroup]="range"
          [rangePicker]="picker"
          [min]="minDate"
          [max]="today"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Data inicial"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="Data Final"
            (dateChange)="interval()"
          />
        </mat-date-range-input>
        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
        <!-- <mat-hint>dd/mm/aaaa – dd/mm/aaaa</mat-hint> -->
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>
    </div>
  </div>

  <div
    class="mat-subheading-2"
    id="subtitle-dashboard"
    *ngIf="startDay && endDay"
  >
    {{
      startDay.toLocaleString("default", { day: "numeric" }) +
        " de " +
        startDay.toLocaleString("default", { month: "long" }) +
        " de " +
        startDay.toLocaleString("default", { year: "numeric" }) +
        " - "
    }}
    {{
      endDay.toLocaleString("default", { day: "numeric" }) +
        " de " +
        endDay.toLocaleString("default", { month: "long" }) +
        " de " +
        endDay.toLocaleString("default", { year: "numeric" })
    }}
  </div>

  <div class="cards ml-12 mr-12">
    <!-- Cards -->
    <div
      class="functionality mat-elevation-z2"
      id="{{ selectedTab == 'Áreas' ? selectedTab : null }}"
      (click)="changeTabIndex(0)"
    >
      <div class="mat-title">Área de cobertura</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-typography" style="color: #9a75fc">
            <h1>
              {{ zoneLenght.toFixed(0) | number }}
            </h1>
          </div>
          <div>m²</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
              yAxisZone.length
                ? ((zoneLenght / yAxisZone.length).toFixed(1) | number)
                : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">m²/mês</div>
          <div *ngIf="gap == 'diario'">m²/dia</div>
          <div *ngIf="gap == 'hoje'">m²/hora</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #31d159">
            {{
              totalAreaLenght
                ? (((zoneLenght * 100) / totalAreaLenght).toFixed(2) | number)
                : 0
            }}%
          </div>
          <div>taxa de sucesso</div>
        </div>
      </div>
    </div>

    <div
      class="functionality mat-elevation-z2"
      id="{{ selectedTab == 'Tarefas' ? selectedTab : null }}"
      (click)="changeTabIndex(1)"
    >
      <div class="mat-title">Tarefas</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ tasksLenght | number }}
          </div>
          <div>concluídas</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
              yAxisTask.length
                ? ((tasksLenght / yAxisTask.length).toFixed(2) | number)
                : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">média (mês)</div>
          <div *ngIf="gap == 'diario'">média (dia)</div>
          <div *ngIf="gap == 'hoje'">m²/hora</div>
        </div>
        <!-- <div class="divider"></div>
                <div class="desc-column">
                    <div class="mat-headline" style="color: #31D159;">{{ eixoY.length ? ((((eixoY[eixoY.length - 3] -
                        eixoY[eixoY.length - 4]) / eixoY[eixoY.length - 4])*100).toFixed(2) | number ) : 0 }}%
                    </div>
                    <div>variação</div>
                </div> -->
      </div>
    </div>

    <div
      class="functionality mat-elevation-z2"
      id="{{ selectedTab == 'Mapas' ? selectedTab : null }}"
      (click)="changeTabIndex(2)"
    >
      <div class="mat-title">Mapas de limpeza</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ mapLenght | number }}
          </div>
          <div>concluídos</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
              yAxisMap.length
                ? ((mapLenght / yAxisMap.length).toFixed(1) | number)
                : 0
            }}
          </div>
          <div *ngIf="gap == 'mensal'">média (mês)</div>
          <div *ngIf="gap == 'diario'">média (dia)</div>
          <div *ngIf="gap == 'hoje'">média (hora)</div>
        </div>
        <!-- <div class="divider"></div>
                <div class="desc-column">
                    <div class="mat-headline" style="color: #31D159;">{{ eixoYmap.length ? ((((eixoYmap[eixoYmap.length
                        -
                        3] - eixoYmap[eixoYmap.length - 4]) / eixoYmap[eixoYmap.length -
                        4])*100).toFixed(2) | number ) : 0 }}%
                    </div>
                    <div>variação</div>
                </div> -->
      </div>
    </div>

    <div
      class="functionality mat-elevation-z2"
      id="{{ selectedTab == 'Tempo' ? selectedTab : null }}"
      (click)="changeTabIndex(4)"
    >
      <div class="mat-title">Tempo de execução</div>
      <div class="description-layout">
        <div class="desc-column">
          <div class="mat-headline" style="color: #9a75fc">
            {{ (timeLenght / 60).toFixed(2) | number }}
          </div>
          <div>Horas</div>
        </div>
        <div class="divider"></div>
        <div class="desc-column">
          <div class="mat-headline" style="color: #1ea5fc">
            {{
              yAxisTime.length
                ? ((timeLenght / (60 * yAxisTime.length)).toFixed(2) | number)
                : 0
            }}
          </div>
          <div>duração/mês</div>
        </div>
        <!-- <div class="divider"></div>
                <div class="desc-column">
                    <div class="mat-headline" style="color: #31D159;">{{ eixoYtime.length ?
                        ((((eixoYtime[eixoYtime.length - 3] - eixoYtime[eixoYtime.length - 4]) /
                        eixoYtime[eixoYtime.length - 4])*100).toFixed(2) | number ) : 0 }}%</div>
                    <div>variação</div>
                </div> -->
      </div>
    </div>
  </div>

  <!-- tabs -->
  <mat-tab-group
    class="mat-elevation-z2 ml-16 mr-16 mt-12"
    color="accent"
    (selectedTabChange)="changeTab($event)"
    [selectedIndex]="selectedIndex"
  >
    <mat-tab label="Áreas">
      <mat-card>
        <ng-template matTabContent>
          <div
            style="display: block; height: 50px; text-align: end; padding: 8px"
          >
            <mat-button-toggle-group
              style="text-align: end"
              [(ngModel)]="gap"
              aria-label="Font Style"
              (change)="dataView()"
            >
              <mat-button-toggle *ngIf="axisXmonth.length > 1" value="mensal"
                >Mensal</mat-button-toggle
              >
              <mat-button-toggle *ngIf="this.gap != 'hoje'" value="diario"
                >Diário</mat-button-toggle
              >
              <mat-button-toggle *ngIf="this.gap == 'hoje'" value="hoje"
                >Hoje</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              height="100"
              [datasets]="barChartDataZone"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [options]="lineChartConfig"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <!-- <mat-card-actions align="end"> -->
          <div style="padding: 8px; text-align: end">
            <button mat-raised-button color="accent">Visualizar</button>
          </div>
          <!-- </mat-card-actions> -->
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Tarefas">
      <mat-card>
        <ng-template matTabContent>
          <div
            style="display: block; height: 50px; text-align: end; padding: 8px"
          >
            <mat-button-toggle-group
              style="text-align: end"
              [(ngModel)]="gap"
              aria-label="Font Style"
              (change)="dataView()"
            >
              <mat-button-toggle value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle value="diario">Diário</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div>
            <!-- <canvas id="chart"></canvas> -->
            <canvas
              baseChart
              height="100"
              [datasets]="barChartDataTask"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [options]="lineChartConfig"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end">
            <button mat-raised-button color="accent">Visualizar</button>
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Mapas">
      <mat-card>
        <ng-template matTabContent>
          <div
            style="display: block; height: 50px; text-align: end; padding: 8px"
          >
            <mat-button-toggle-group
              style="text-align: end"
              [(ngModel)]="gap"
              aria-label="Font Style"
              (change)="dataView()"
            >
              <mat-button-toggle value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle value="diario">Diário</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="barChartDataMap"
              height="100"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [options]="lineChartConfig"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end">
            <button mat-raised-button color="accent">Visualizar</button>
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
    <mat-tab label="Tempo">
      <mat-card>
        <ng-template matTabContent>
          <div
            style="display: block; height: 50px; text-align: end; padding: 8px"
          >
            <mat-button-toggle-group
              style="text-align: end"
              [(ngModel)]="gap"
              aria-label="Font Style"
              (change)="dataView()"
            >
              <mat-button-toggle value="mensal">Mensal</mat-button-toggle>
              <mat-button-toggle value="diario">Diário</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="barChartDataTime"
              height="100"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [options]="lineChartConfig"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
          <mat-divider></mat-divider>
          <div style="padding: 8px; text-align: end">
            <button mat-raised-button color="accent">Visualizar</button>
          </div>
        </ng-template>
      </mat-card>
    </mat-tab>
  </mat-tab-group>

  <div id="inf-cards-layout" style="height: auto" class="m-12 mt-16">
    <div class="card-inf mat-elevation-z2">
      <div class="title-inf mat-subheading-1">
        <mat-icon> list </mat-icon>
        Lista
      </div>
      <div style="padding-left: 20px">
        Lista com as últimas atividades dos robôs
      </div>

      <table
        mat-table
        [dataSource]="dataExecutionSlice"
        matSort
        matSortActive="id"
        matSortDirection="desc"
      >
        <ng-container matColumnDef="id">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="ordem"
          >
            Ordem
          </th>
          <td mat-cell *matCellDef="let item">{{ item.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            sortActionDescription="name"
          >
            Nome
          </th>
          <td mat-cell *matCellDef="let item">{{ item.data.name }}</td>
        </ng-container>

        <ng-container matColumnDef="mapName">
          <th mat-header-cell *matHeaderCellDef>Mapa</th>
          <td mat-cell *matCellDef="let item">{{ item.data.mapName }}</td>
        </ng-container>

        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef>Membro</th>
          <td mat-cell *matCellDef="let item">{{ item.operator }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let item; columns: columnsToDisplay; let i = index"
        ></tr>
      </table>

      <mat-paginator #paginator [pageSize]="6" showFirstLastButtons>
      </mat-paginator>
    </div>

    <!-- <div class="card-inf mat-elevation-z2">
            <div class="title-inf mat-subheading-1">
                <mat-icon>
                    list
                </mat-icon>
                Planejamento dirário {{ selectedTab }} - (Executado x Planejado)
            </div>
            <div style="display: block">
                <canvas baseChart [datasets]="barChartDataZone" height="100" [labels]="barChartLabels"
                    [options]="doughnutChartConfig" chartType="pie">
                </canvas>
            </div>
        </div> -->

    <div class="card-inf mat-elevation-z2">
      <div class="title-inf mat-subheading-1">
        <mat-icon> show_chart </mat-icon>
        Gráfico dos colaboradores por {{ selectedTab }}
      </div>
      <!-- zone -->
      <div style="display: block" *ngIf="selectedTab == 'Áreas'">
        <canvas
          baseChart
          [datasets]="barChartDataMemberZone"
          height="200px"
          [labels]="barChartLabelsMember"
          [options]="lineChartConfig"
          chartType="horizontalBar"
        >
        </canvas>
      </div>
      <!-- tasks -->
      <div style="display: block" *ngIf="selectedTab == 'Tarefas'">
        <canvas
          baseChart
          [datasets]="barChartDataMemberTask"
          height="200px"
          [labels]="barChartLabelsMember"
          [options]="lineChartConfig"
          chartType="horizontalBar"
        >
        </canvas>
      </div>
      <!-- maps -->
      <div style="display: block" *ngIf="selectedTab == 'Mapas'">
        <canvas
          baseChart
          [datasets]="barChartDataMemberMaps"
          height="200px"
          [labels]="barChartLabelsMember"
          [options]="lineChartConfig"
          chartType="horizontalBar"
        >
        </canvas>
      </div>
      <!-- time -->
      <div style="display: block" *ngIf="selectedTab == 'Tempo'">
        <canvas
          baseChart
          [datasets]="barChartDataMemberTime"
          height="200px"
          [labels]="barChartLabelsMember"
          [options]="lineChartConfig"
          chartType="horizontalBar"
        >
        </canvas>
      </div>
    </div>
    <!-- <mat-card>
            <mat-card-title style="background-color: gray;">Actions Buttons</mat-card-title>
            <mat-card-subtitle>Start</mat-card-subtitle>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card> -->
  </div>
</div>
