import { ReportService } from '@alabia/api/report.service';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { AssignmentsService, BotMemory, ChangeType, Graph, GraphPeriod, GraphStep, MemoryService, QueueAssignment, ReportService as ReportServiceApi, STAR_RATING } from 'onevoice';
import { Subject } from 'rxjs';

export interface GraphDataResponse { labels: Label[], dataset: ChartDataSets[] }

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public OnUpdate: Subject<void> = new Subject<void>();
  public OPEN_HOURS: { [id: number]: number[] } = {
    1: [8, 18],
    2: [8, 18],
    3: [8, 18],
    4: [8, 18],
    5: [8, 18],
    6: [8, 12],
  }
  private assignmentList: QueueAssignment[] = [];
  private memoryList: BotMemory[] = [];

  constructor(
    private assignments: AssignmentsService,
    private memory: MemoryService,
    private _reportServiceApi: ReportServiceApi,
    private reportService: ReportService,

  ) {
    this.assignments.list(true).subscribe(list => {
      this.assignmentList = list;
      this.OnUpdate.next();
    });
    this.memory.list().subscribe(list => {
      this.memoryList = list;
      this.OnUpdate.next();
    })
  }

  private ToTimestep(ts?: GraphStep) {
    if (ts === undefined) {
      ts = GraphStep.DAILY;
    }
    switch (ts) {

      case GraphStep.DAILY:
        return "yyyy-MM-dd";

      case GraphStep.MONTHLY:
        return "yyyy-MM";

      case GraphStep.YEARLY:
        return "yyyy";

      case GraphStep.HOURLY:
        return "yyyy-MM-ddTHH";


      default:
        return "yyyy-MM-dd";
    }
  }

  private convertTimestep(timestep: string): number {
    if (timestep.includes("HH")) return 1000 * 60 * 60;
    if (timestep.includes("dd")) return 1000 * 60 * 60 * 24;
    if (timestep.includes("MM")) return 1000 * 60 * 60 * 24 * 30;
    return 1000 * 60 * 60 * 365;
  }

  private initDataWithDates(dates: (number | undefined | null)[], timestep: string, initval: number = 0) {
    let fixeddates = dates.filter(value => !!value) as number[];
    let min = Math.min(...fixeddates), max = Math.max(...fixeddates);
    let data: { [id: string]: number; } = {};

    for (let curr = min; curr < max; curr = curr + this.convertTimestep(timestep)) {
      data[this.keyFromData(new Date(curr), timestep)] = initval;
    }
    return data;
  }

  private isNumber(value: any) {
    try {
      Number(value);
    } catch {
      return false;
    }
    return true;
  }

  // returns a time delta in hours
  public timeDelta(date1: number, date2: number) {
    if (date1 >= date2) {
      return 0.00;
    }
    let val1 = this.nextValidDate(new Date(date1)),
      val2 = this.nextValidDate(new Date(date2));

    let current = val1, hours: number = 0.0;

    while (!this.sameDay(current, val2)) {
      if (this.isWorkDay(current)) {
        hours += this.filterHours(this.closing(current) - (current.getHours() + current.getMinutes() / 60.0), current);
      }
      current.setDate(current.getDate() + 1);
      if (this.isWorkDay(current)) {
        hours += this.filterHours((current.getHours() + current.getMinutes() / 60.0) - this.opening(current), current);
      }
    }
    hours += (val2.getTime() - current.getTime()) / (1000.0 * 60.0 * 60.0);

    return Math.max(Math.trunc(hours * 100.0) / 100.0, 0.0);
  }

  private filterHours(value: number, date: Date) {
    return Math.min(
      0.0,
      Math.max(
        this.closing(date) - this.opening(date),
        value
      )
    );
  }

  public isWorkDay(date: Date): boolean {
    return !!this.OPEN_HOURS[date.getDay()];
  }

  public opening(date: Date) {
    return this.OPEN_HOURS[date.getDay()][0];
  }
  public closing(date: Date) {
    return this.OPEN_HOURS[date.getDay()][1];
  }

  public sameDay(date1: Date, date2: Date) {
    return date1.getFullYear() >= date2.getFullYear() && date1.getMonth() >= date2.getMonth() && date1.getDate() >= date2.getDate();
  }

  public nextValidDate(date: Date) {
    while (!this.OPEN_HOURS[date.getDay()]) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }

    let [start, finish] = this.OPEN_HOURS[date.getDay()];

    if (date.getHours() < start) {
      date.setHours(start);
      date.setMinutes(0);
    } else if (date.getHours() >= finish) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }

    while (!this.OPEN_HOURS[date.getDay()]) {
      date.setDate(date.getDate() + 1);
      date.setHours(this.OPEN_HOURS[date.getDay()] ? this.OPEN_HOURS[date.getDay()][0] : 0);
      date.setMinutes(0);
    }
    return date;
  }

  private divideData(data1: { [id: string]: number; }, data2: { [id: string]: number; }) {
    let data: { [id: string]: number; } = {};
    Object.keys(data1).forEach(key => {
      data[key] = data2[key] ? Math.trunc(data1[key] * 100.0 / data2[key]) / 100.0 : 0.0;
    });
    return data;
  }

  private increment(data: { [id: string]: number; }, key: string, value: number = 1.0) {
    data[key] = (key in data ? data[key] : 0.0) + value;
  }

  private generateData(data: { [id: string]: number; }, label: string): GraphDataResponse {
    let keys = Object.keys(data).sort((a, b) => (a < b) ? -1 : 1);
    let dataset = keys.map(value => data[value]);
    return { labels: keys, dataset: [{ label: label, data: dataset }] };
  }

  private generateDataByValue(data: { [id: string]: number; }, label: string): GraphDataResponse {
    let keys = Object.keys(data).sort((a, b) => (data[a] > data[b]) ? -1 : 1);
    let dataset = keys.map(value => data[value]);
    return {
      labels: keys,
      dataset: [{
        label: label,
        data: dataset,
      }]
    };
  }

  private keyFromData(data: Date, timestep: string) {
    return formatDate(data, timestep, "en-US");
  }

  public sample(graph: Partial<Graph>) {
    return { labels: [], dataset: [] }
  }

  private filterAssignments(list: QueueAssignment[], graph: Partial<Graph>): QueueAssignment[] {
    if (graph.tags !== undefined) {
      let tags = graph.tags;
      list = list.filter(value => tags.length == 0 || (value.tag && tags.find(t => value.tag && value.tag.id == t)));
    }

    if (graph.agents !== undefined) {
      let agents = graph.agents;
      list = list.filter(value => agents.length == 0 || (value.agent && agents.find(t => value.agent && value.agent.id == t)));
    }
    if (graph.bots !== undefined) {
      let bots = graph.bots;
      list = list.filter(value => bots.length == 0 || (value.bot && bots.find(t => value.bot && value.bot.id == t)));
    }
    return list.filter(value => this.withinTimeframe(value.created, graph));
  }
  private withinTimeframe(value: number, graph: Partial<Graph>): boolean {
    let { to, from } = this.makeTimeframe(graph);
    return from <= value && value < to;
  }
  private makeTimeframe(graph: Partial<Graph>): { to: number; from: number; } {
    let now = new Date();
    let period = graph.period;

    const HOUR = 1000 * 60 * 60,
      DAY = 24 * HOUR,
      WEEK = 7 * DAY,
      MONTH = 30 * DAY,
      YEAR = 365 * DAY;

    let fromDate = graph.periodStart ? new Date(graph.periodStart) : now
    let toDate = graph.periodEnd ? new Date(graph.periodEnd) : new Date(now.getTime() - MONTH);

    if (!period) {
      period = GraphPeriod.MONTH_TO_DATE;
    }

    switch (period) {
      case GraphPeriod.LAST_MONTH:
        fromDate = new Date(now.getTime() - MONTH);
        fromDate.setHours(0, 0, 0, 0);
        fromDate.setDate(1);

        toDate = new Date(now.getTime());
        toDate.setHours(0, 0, 0, 0);
        fromDate.setDate(30);

        return {
          from: fromDate.getTime(),
          to: toDate.getTime(),
        };

      case GraphPeriod.LAST_WEEK:
        fromDate = new Date(now.getTime() - WEEK - fromDate.getDay() * DAY);
        fromDate.setHours(0, 0, 0, 0);

        toDate = new Date(now.getTime() - fromDate.getDay() * DAY);
        toDate.setHours(0, 0, 0, 0);

        return {
          from: fromDate.getTime(),
          to: toDate.getTime(),
        };

      case GraphPeriod.LAST_DAY:
        fromDate = new Date(now.getTime() - DAY);
        fromDate.setHours(0, 0, 0, 0);

        toDate = new Date(now.getTime() - DAY);
        toDate.setHours(24, 0, 0, 0);
        return {
          from: fromDate.getTime(),
          to: toDate.getTime(),
        };
      case GraphPeriod.WEEK_TO_DATE:
        return {
          from: now.getTime() - WEEK,
          to: now.getTime(),
        };
      case GraphPeriod.DAY_TO_DATE:
        return {
          from: now.getTime() - DAY,
          to: now.getTime(),
        };
      case GraphPeriod.CUSTOM:
        return {
          from: fromDate.getTime(),
          to: toDate.getTime(),
        };

      case GraphPeriod.MONTH_TO_DATE:
      default:
        return {
          from: now.getTime() - MONTH,
          to: now.getTime(),
        };
    }
  }

  public answered(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let done = this.filterAssignments(this.assignmentList, graph).filter(
        value => value.status == ChangeType.DONE || value.status == ChangeType.REPLIED,
      );
      let answeredAssignments: { [id: string]: number; } = this.initDataWithDates(done.map(value => value.answered), this.ToTimestep(graph.timestep));
      for (const assignment of done) {
        if (assignment.answered !== null && assignment.answered !== undefined) {
          let key = this.keyFromData(new Date(assignment.answered), this.ToTimestep(graph.timestep));
          this.increment(answeredAssignments, key);
        }
      }
      resolve(this.generateData(answeredAssignments, "Chamados Respondidos"));
    });
  }


  public opened(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let opened = this.filterAssignments(this.assignmentList, graph);
      let openedAssignments: { [id: string]: number; } = this.initDataWithDates(opened.map(value => value.answered), this.ToTimestep(graph.timestep));
      for (const assignment of opened) {
        let key = this.keyFromData(new Date(assignment.created), this.ToTimestep(graph.timestep));
        this.increment(openedAssignments, key);
      }
      resolve(this.generateData(openedAssignments, "Chamados Abertos"));
    });
  }

  public combined(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      Promise.all([this.opened(graph), this.answered(graph)]).then(([data1, data2]) => {
        resolve({ labels: data1.labels, dataset: data1.dataset.concat(data2.dataset) })
      })
    });
  }


  public SLA(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {

      let done = this.filterAssignments(this.assignmentList, graph).filter(value => value.status == ChangeType.DONE || value.status == ChangeType.REPLIED);

      let slaRaw: { [id: string]: number; } = this.initDataWithDates(done.map(value => value.update), this.ToTimestep(graph.timestep));
      let slaCount: { [id: string]: number; } = this.initDataWithDates(done.map(value => value.update), this.ToTimestep(graph.timestep));
      for (const assignment of done) {
        let enddate = Math.max(assignment.update || 0, assignment.answered || 0);
        if (enddate <= 100) {
          continue;
        }
        let key = this.keyFromData(new Date(enddate), this.ToTimestep(graph.timestep));
        this.increment(slaRaw, key, this.timeDelta(assignment.created, enddate));
        this.increment(slaCount, key)
      }

      resolve(this.generateData(this.divideData(slaRaw, slaCount), "Primeira Resposta"));
    });
  }


  public SLAPercentil(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {

      let done = this.filterAssignments(this.assignmentList, graph).filter(value => value.status == ChangeType.DONE || value.status == ChangeType.REPLIED);

      let slaPerRaw: number[] = [];
      for (const assignment of done) {
        let enddate = Math.max(assignment.update || 0, assignment.answered || 0);
        if (enddate <= 100) {
          continue;
        }
        slaPerRaw.push(this.timeDelta(assignment.created, enddate));
      }

      let slaPerLabels = [
        "< 30 minutos",
        "< 2 horas",
        "< 4 horas",
        "< 8 horas",
        "< 24 horas",
        "< 48horas",
        ">= 48horas"];
      let slaPerData = [
        slaPerRaw.filter(value => value < 0.5).length,
        slaPerRaw.filter(value => 0.5 <= value && value < 2.0).length,
        slaPerRaw.filter(value => 2.0 <= value && value < 4.0).length,
        slaPerRaw.filter(value => 4.0 <= value && value < 8.0).length,
        slaPerRaw.filter(value => 8.0 <= value && value < 24.0).length,
        slaPerRaw.filter(value => 24.0 <= value && value < 48.0).length,
        slaPerRaw.filter(value => value >= 48.0).length,
      ];
      resolve({
        labels: slaPerLabels,
        dataset: [
          {
            label: "Primeira Resposta",
            data: slaPerData
          }
        ]
      })
    });
  }

  public firstResponse(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {

      let assignments = this.filterAssignments(this.assignmentList, graph);
      let firstResponseRaw: { [id: string]: number; } = this.initDataWithDates(assignments.map(value => value.answered), this.ToTimestep(graph.timestep));
      let firstResponseCount: { [id: string]: number; } = this.initDataWithDates(assignments.map(value => value.answered), this.ToTimestep(graph.timestep));

      for (const assignment of assignments) {
        if (assignment.answered == null || assignment.answered == undefined) {
          continue;
        }
        let key = this.keyFromData(new Date(assignment.answered), this.ToTimestep(graph.timestep));
        this.increment(firstResponseRaw, key, this.timeDelta(assignment.created, assignment.answered));
        this.increment(firstResponseCount, key)
      }

      resolve(this.generateData(this.divideData(firstResponseRaw, firstResponseCount), "Primeira Resposta"));
    });
  }

  public tagCount(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let assignments = this.filterAssignments(this.assignmentList, graph);

      let tagRaw: { [id: string]: number; } = {};
      for (const assignment of assignments) {
        if (assignment.tag == null || assignment.tag == undefined) {
          continue;
        }
        let key = `${assignment.tag?.title} - ${assignment.tag?.description}`;
        this.increment(tagRaw, key)
      }
      resolve(this.generateDataByValue(tagRaw, "Tags"));
    });
  }

  private hourlyInitial(): { [id: string]: number; } {
    let data: { [id: string]: number; } = {};

    for (let index = 0; index < 24; index++) {
      data[`${index}`.padStart(2, "0")] = 0;
    }
    return data;
  }


  public peakAssignments(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let assignments = this.filterAssignments(this.assignmentList, graph);
      let hourlyRequest: { [id: string]: number; } = this.hourlyInitial();

      for (const assignment of assignments) {
        let key = formatDate(new Date(assignment.created), "HH", "en-US");
        this.increment(hourlyRequest, key)
      }
      resolve(this.generateData(hourlyRequest, "Pico de Chamados "));
    });
  }

  public evaluation(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let assignments = this.filterAssignments(this.assignmentList, graph);

      let evaluationRaw: { [id: string]: number; } = this.initDataWithDates(assignments.map(value => value.update), this.ToTimestep(graph.timestep));
      let evaluationCount: { [id: string]: number; } = this.initDataWithDates(assignments.map(value => value.update), this.ToTimestep(graph.timestep));

      for (const memory of this.memoryList) {
        if (memory.assignment && memory.name == graph.extra && this.isNumber(memory.value)) {
          let assignment = assignments.find(assignment => assignment.id == memory.assignment);

          if (assignment != null) {
            let key = this.keyFromData(new Date(assignment.update), this.ToTimestep(graph.timestep));
            this.increment(evaluationRaw, key, Number(memory.value));
            this.increment(evaluationCount, key)
          }
        }
      }

      resolve(this.generateData(this.divideData(evaluationRaw, evaluationCount), "" + graph.extra));
    });
  }

  public peakActivity(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {

      let { to, from } = this.makeTimeframe(graph);

      this._reportServiceApi.activityPeak(
        new Date(from),
        new Date(to), graph.bots && graph.bots.length == 1 ? graph.bots[0] : undefined,
      ).subscribe(data => {
        let activityPeakRaw: { [id: string]: number; } = this.hourlyInitial();

        for (const item of data) {
          activityPeakRaw[item[0] as string] = item[1] as number;
        }
        resolve(this.generateData(activityPeakRaw, "Pico de Atividade"));
      });
    });
  }

  public messagesChart(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let { to, from } = this.makeTimeframe(graph);

      this.reportService.messagesPerMonth().subscribe(data => {
        let messageCount: { [id: string]: number; } = this.initDataWithDates(data.map(
          ([date, count]) => new Date(date as string).getTime()
        ), this.ToTimestep(graph.timestep));

        for (const [date, count] of data) {
          if (!date) {
            continue;
          }
          let key = this.keyFromData(new Date(date as string), this.ToTimestep(graph.timestep));
          messageCount[key] = (messageCount[key] || 0) + (count as number);
        }
        resolve(this.generateData(messageCount, "Mensagens"));
      });
    });
  }


  public agentBotRation(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let { to, from } = this.makeTimeframe(graph);

      this._reportServiceApi.agenBotRatio(
        new Date(from),
        new Date(to), graph.bots && graph.bots.length == 1 ? graph.bots[0] : undefined,
      ).subscribe(data => {
        const AGENT = 1, ROBOT = 2;

        let agentCount: { [id: string]: number; } = this.initDataWithDates(data.map(
          ([date, sender, count]) => new Date(date as string).getTime()
        ), this.ToTimestep(GraphStep.DAILY));

        let botCount: { [id: string]: number; } = this.initDataWithDates(data.map(
          ([date, sender, count]) => new Date(date as string).getTime()
        ), this.ToTimestep(GraphStep.DAILY));

        for (const item of data) {
          let [date, sender, count] = item;

          if (sender == AGENT) {
            agentCount[date as string] = count;
            botCount[date as string] = botCount[date] || 0;
          }
          else if (sender == ROBOT) {
            agentCount[date as string] = agentCount[date] || 0;
            botCount[date as string] = count;
          }
          else {
            // User sent this
          }
        }
        let { labels, dataset } = this.generateData(agentCount, "Agente");
        let agentData = dataset;

        ({ labels, dataset } = this.generateData(botCount, "Robo"));
        let botData = dataset;

        resolve({ labels, dataset: agentData.concat(botData) });
      });
    });
  }


  public playstore(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let { to, from } = this.makeTimeframe(graph);

      let memoryList = this.memoryList.filter(
        memory => from <= memory.timestamp && memory.timestamp <= to && memory.name == STAR_RATING && this.isNumber(memory.value),
      );

      let evaluationRaw: { [id: string]: number; } = this.initDataWithDates(memoryList.map(value => value.timestamp), this.ToTimestep(graph.timestep));
      let evaluationCount: { [id: string]: number; } = this.initDataWithDates(memoryList.map(value => value.timestamp), this.ToTimestep(graph.timestep));

      for (const memory of memoryList) {
        let key = this.keyFromData(new Date(memory.timestamp), this.ToTimestep(graph.timestep));
        this.increment(evaluationRaw, key, Number(memory.value));
        this.increment(evaluationCount, key);
      }

      resolve(this.generateData(this.divideData(evaluationRaw, evaluationCount), "Avaliações da Play Store"));
    });

  }

  public playstoreDoughnut(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let { to, from } = this.makeTimeframe(graph);

      let memoryList = this.memoryList.filter(
        memory => from <= memory.timestamp && memory.timestamp <= to && memory.name == STAR_RATING && this.isNumber(memory.value),
      );

      let evaluationRaw: { [id: string]: number; } = {};
      for (const memory of memoryList) {
        this.increment(evaluationRaw, `${memory.value} est.`, 1);
      }

      resolve(this.generateData(evaluationRaw, "Avaliações da Play Store"));
    });

  }

  public failedIntent(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let assignments = this.filterAssignments(this.assignmentList, graph);

      let data: { [id: string]: number; } = {};
      for (const assignment of assignments) {
        if (assignment.intent == null || assignment.intent == undefined) {
          continue;
        }
        let key = `${assignment.intent.name}`;
        this.increment(data, key)
      }
      resolve(this.generateDataByValue(data, "Intent"));
    });
  }

  public failedIntentMultiDate(graph: Partial<Graph>): Promise<GraphDataResponse> {
    return new Promise((resolve, reject) => {
      let assignments = this.filterAssignments(this.assignmentList, graph);

      let data: { [intent: string]: { [id: string]: number; } } = {};
      for (const assignment of assignments) {
        if (assignment.intent == null || assignment.intent == undefined) {
          continue;
        }

        let key = `${assignment.intent.name}`;
        if (data[key] == undefined || data[key] == null) {
          data[key] = this.initDataWithDates(assignments.map(assignment => assignment.created), this.ToTimestep(graph.timestep));
        }

        let date = this.keyFromData(new Date(assignment.created), this.ToTimestep(graph.timestep))
        this.increment(data[key], date)
      }


      let label: Label[] = [], content: ChartDataSets[] = [];

      for (const key of Object.keys(data)) {
        let { labels, dataset } = this.generateData(data[key], key);
        label = labels;
        content = content.concat(dataset);
      }
      resolve({
        labels: label,
        dataset: content,
      });
    });
  }

}