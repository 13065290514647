import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root'
})
export class FuseMatSidenavHelperService {
    sidenavInstances: { [id: string]: MatSidenav };

    /**
     * Constructor
     */
    constructor() {
        this.sidenavInstances = {};
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set sidenav
     *
     * @param id
     * @param instance
     */
    setSidenav(id: string, instance: MatSidenav): void {
        this.sidenavInstances[id] = instance;
    }

    /**
     * Get sidenav
     *
     * @param id
     * @returns {any}
     */
    getSidenav(id: string): any {
        if (!id) {
            return;
        }
        return this.sidenavInstances[id];
    }
}
