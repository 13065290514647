import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Action, ActionProperty, ActionService } from 'onevoice';

@Component({
  selector: 'main-action-properties',
  templateUrl: './action-properties.component.html',
  styleUrls: ['./action-properties.component.scss']
})
export class ActionPropertiesComponent {
  public properties?: ActionProperty[];
  public form: FormGroup = new FormGroup({
    "name": new FormControl("", [Validators.required]),
    "value": new FormControl("", [Validators.required]),
  });

  public datasource: MatTableDataSource<ActionProperty> = new MatTableDataSource<ActionProperty>()
  public columns: string[] = ["name", "value", "actions"];

  constructor(
    private actions: ActionService,
    private reference: MatDialogRef<ActionPropertiesComponent>,
    @Inject(MAT_DIALOG_DATA) private action: Action,
  ) {
    this.actions.listProperty(action).subscribe(properties => {
      this.setProperties(properties);
    });
  }

  private setProperties(properties: ActionProperty[]) {
    this.properties = properties;
    this.datasource.data = properties;
  }

  private addProperties(property: ActionProperty) {
    let list = this.properties;
    if (list) {
      list = list.concat([property]);
      this.setProperties(list);
    }
  }

  private removeProperty(property: ActionProperty) {
    let list = this.properties;
    if (list) {
      list = list.filter(value => value.id !== property.id);
      this.setProperties(list);
    }
  }

  public onSubmit() {
    let property: Partial<ActionProperty> = {
      ...this.form.value,
    }
    this.actions.addProperty(this.action, property).subscribe(created => {
      this.addProperties(created);
      this.form.reset();
    });
  }

  public remove(property: ActionProperty) {
    this.actions.deleteProperty(this.action, property).subscribe(ok => {
      this.removeProperty(property);
    })
  }
}
