import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { Machine, MachineMap, MachineService, MapUnit, MapUnitService, MapType, MachineModelService, MachineModel } from 'onevoice';
import * as uuid from 'uuid';


@Component({
  selector: 'main-create-machine',
  templateUrl: './create-machine.component.html',
  styleUrls: ['./create-machine.component.scss']
})
export class CreateMachineComponent implements OnInit {
  public form: FormGroup;
  public dataSourceMapUnit: MatTableDataSource<MapUnit> =
  new MatTableDataSource<MapUnit>();
  public dataSourceMachineModel: MatTableDataSource<MachineModel> =
  new MatTableDataSource<MachineModel>();
  public unitSelected : any;
  // public dataLocationSelected : MapUnit;
  public default : MatTableDataSource<Machine> =
  new MatTableDataSource<Machine>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Machine,
    private reference: MatDialogRef<CreateMachineComponent, Machine>,
    private machineService: MachineService,
    private mapUnitService: MapUnitService,
    private machineModels: MachineModelService,

  ) {
    this.form = new FormGroup({
      "name": new FormControl(data.name || "", [Validators.required]),
      "nickName": new FormControl(data.nickName || "", [Validators.required]),
      "machineModel": new FormControl(data.model || "", [Validators.required]),
      "location": new FormControl(data.location || "", [Validators.required]),
    }, []);
  }
  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    this.loadUnit();
    this.loadModels();
    // this.getUnit()
    console.log(this.data)
  }

  get title(): string {
    return (this.data.id !== undefined) ? "Editar robô" : "Criar robô";
  }

  public submitMap(): void {
    this.machineService.update(this.data)
    console.log(this.data)

    console.log(this.form.value)
    console.log(this.data)
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }
  public selectedMap(value  : MapUnit){
    this.data.location = value

  }
  public selectedModel(value  : MachineModel){
    this.data.model = value

  }
  private loadUnit(): void {
    this.mapUnitService.list().subscribe((unitList) => {
      this.dataSourceMapUnit.data = unitList;

    });

  }

  private loadModels(): void {
    this.machineModels.list().subscribe((machineModel) => {
      this.dataSourceMachineModel.data = machineModel;

    });

  }

}
