import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalRegistrationComponent } from '../modal-registration/modal-registration.component';
import { FormGroup } from '@angular/forms';
import { AuthenticationService, LimitService, Status } from 'onevoice';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService } from 'app/alabia/api/account.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'alabia-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  dialogRef: any;

  constructor(
    public _matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthenticationService,
    private accountService: AccountService,
    private limitService: LimitService,
  ) { }

  ngOnInit() {
    let currentUser = this.authService.current.value;

    if (currentUser) {
      if (currentUser.organization == null) {
        this.router.navigateByUrl('/organization');
      } else if (currentUser.organization.status === Status.OK) {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl('/signup');
      }
    }
  }

  get returnUrl() {
    return this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  public onSubmit(username: string, pass: string) {
    this.authService
      .login(username, pass)
      .then(
        () => this._handleAuthStatus(),
        err => {
          alert("Usuário ou senha incorretos")
        }
      );
  }

  private _handleAuthStatus() {
    let user = this.authService.current.value;
    if (!user) {
      return;
    }
    if (user.organization == null) {
      this.router.navigateByUrl('/organization');
    } else if (user.organization.status !== Status.OK) {
      this.router.navigateByUrl('/signup');
    } else {
      this.router.navigateByUrl(this.returnUrl)
    }

    this.limitService.check().subscribe(ok => { console.log(ok) });
  }

  public modalRegistation() {
    this.dialogRef = this._matDialog.open(ModalRegistrationComponent, {
      panelClass: 'mail-compose-dialog'
    });

    this.dialogRef.afterClosed()
      .subscribe((response: FormGroup) => {
        if (!response) {
          return;
        }
        const formData: FormGroup = response;

        this.accountService.createAcount(formData.value).subscribe(
          res => alert('Conta criada com sucesso!'),
          err => alert('Problema ao criar usuário.')
        );
      });
  }
}
