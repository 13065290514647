import { InjectionToken } from '@angular/core';

export class ApiConfiguration {
    constructor(
        public endpoint: string,
        public mediaservice: string,
        public biometry: string,

        public mediaStorage: string,
    ) { }

    public login(): string {
        return `${this.endpoint}/auth/login`;
    }
}

export const APP_CONFIG = new InjectionToken<ApiConfiguration>('Application configuration');
