import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { AuthorizationType, PermissionAuthority } from './user.model';

export interface PermissionRequest {
  type: AuthorizationType;
  scope: string;

}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private http: HttpClient, 
    @Inject(APP_CONFIG) private config: ApiConfiguration,
  ) { }

  public list(user_id: number): Observable<PermissionAuthority[]> {
    return this.http.get<PermissionAuthority[]>(`${this.config.endpoint}/permissions/${user_id}`);
  }

  public add(user_id: number, permissions: PermissionRequest[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.endpoint}/permissions/${user_id}`, permissions);
  }

  public remove(user_id: number, permissions: PermissionRequest[]): Observable<boolean> {
    return this.http.delete<boolean>(`${this.config.endpoint}/permissions/${user_id}`, {
      body: permissions,
    });
  }
}
