import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'alabia-signup-step4',
  templateUrl: './signup-step4.component.html',
  styleUrls: ['./signup-step4.component.scss', '../signup-step.shared.scss'],
  animations: [
    trigger('formAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateX(100%)', opacity: '0%' }))
      ])
    ]),
    trigger('infoAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('700ms ease', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateY(100%)', opacity: '0%' }))
      ])
    ]),
  ],
})
export class SignupStep4Component implements OnInit {

  public isShowing: boolean = true;
  @Input() returnData: any;
  @Output() nextScreen = new EventEmitter();
  @Output() stepData = new EventEmitter();

  public fourthStepSignup?: FormGroup;

  constructor() { }

  ngOnInit() {
    this.fourthStepSignup = this.fourthStepForm();

  }

  public screenOutputHandler(currentScreen: any, step: any) {
    this.isShowing = !this.isShowing;
    setTimeout(() => {
      this.nextScreen.emit({ currentScreen, step });
    }, 700);
  }


  fourthStepForm(): FormGroup {
    return new FormGroup({
      company_size: new FormControl(this.returnData['company_size']),
    });
  }

  onSubmit(formData: any) {
    if (formData.valid) {
      this.stepData.emit({ step: 3, organizationData: formData.value });
      // this.screenOutputHandler(3, 'forward');
    }
  }

}
