import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'onevoice';

@Component({
  selector: 'alabia-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public oldPassword: string = "";
  public newPassword: string = "";
  public checkPassword: string = "";

  constructor(
    private auth: AuthenticationService,
    private reference: MatDialogRef<ChangePasswordComponent>,
  ) { }

  ngOnInit() {
  }

  public isValid(): boolean {
    return this.newPassword.length > 0 && this.newPassword == this.checkPassword;
  }

  public changePassword() {
    this.auth.changePassword(this.oldPassword, this.newPassword).subscribe(ok => {
      this.reference.close(ok);
    });
  }
}
