import { Injectable } from '@angular/core';
import { Client, IFrame } from '@stomp/stompjs';
import { environment } from 'environments/environment';
import { TokenService } from 'onevoice';
import { Subject } from 'rxjs';
import * as SockJS from 'sockjs-client';

@Injectable({
    providedIn: 'root'
})
export class BotClientService {
    private client: Client;
    public updates: Subject<any> = new Subject<any>();

    private randomString(): string {
        return Math.random()
            .toString(36)
            .replace(/[^0-9]+/g, '');
    }

    constructor(public token: TokenService) {
        this.client = new Client({
            connectHeaders: {
                'user-name': 'web-ui',
                idCustomer: this.randomString(),
                idClient: '1',
                idChannel: '3'
            },
            webSocketFactory: () => {
                return new SockJS(`${environment.conversationService}?access_token=${this.token.token}`);
            },
            onConnect: (receipt: IFrame) => {
                this.client.subscribe('/user/queue/c', message => {
                    try {
                        this.updates.next(JSON.parse(message.body));
                    } catch (error) {
                        console.error(error);
                    }
                });
            }
        });
        this.client.activate();
    }

    public sendMessage(botId: number, message: string) {
        this.client.publish({
            skipContentLengthHeader: true,
            destination: '/app/agent',
            body: JSON.stringify({
                query: message,
                customerDTO: {
                    idCustomer: 0,
                    idClient: 0,
                    firstName: 'John',
                    lastName: 'Doe',
                    email: 'john.doe@example.org',
                    lastContact: 0,
                    phoneList: []
                },
                interactionDTO: {
                    idChannel: 4,
                    idClient: 0,
                    idCustomer: 0,
                    idInteraction: 0,
                    interactionTime: 0,
                    sessionId: ''
                },
                idBot: botId,
                idConversation: 0,
                idHook: 0,
                quiet: false,
                customerChannelId: 'botmanager',
                answerMode: 1,
            })
        });
    }
}
