import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'oneui-request-media',
  templateUrl: './request-media.component.html',
  styleUrls: ['./request-media.component.css']
})
export class RequestMediaComponent implements OnInit {
  public OnSend: Subject<boolean> = new Subject<boolean>();
  public Send: Subject<File> = new Subject<File>()

  public files?: FileList;

  public sent: number = 0;
  public sending: boolean = false;
  public errors: boolean = false;

  constructor(private reference: MatDialogRef<RequestMediaComponent>) { }

  ngOnInit() {
    this.OnSend.subscribe((ok) => {
      console.debug("OK");
      if (!ok) {
        this.errors = true;
      }
      this.sent += 1;

      if (this.files && this.sent == this.files.length && !this.errors) {
        this.sending = false;
        this.reference.close(true);
      }
    })
  }

  fileCount(): number {
    return (this.files) ? this.files.length : 0;
  }

  finish() {
    if (!this.files) { return; }
    this.sending = true;
    this.sent = 0;
    this.errors = false;
    for (let i = 0; i < this.files.length; i += 1) {
      this.Send.next(this.files[i]);
    }
  }

  public getList(): File[] {
    let files: File[] = [];
    if (!this.files) { return files }
    for (let i = 0; i < this.files.length; i += 1) {
      files.push(this.files[i]);
    }
    return files;
  }

  selectFiles(event: Event) {
    let target = event.target as HTMLInputElement;
    if (target.files) {
      this.files = target.files;
    }
  }
}
