<!-- TEMPORARIAMENTE  INDISPONÍVEL PARA TESTES -->
<ng-container *ngIf="false">
    <div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="logo">
            <img class="logo-icon" src="assets/images/alabia/logo_one.png">
            <span class="logo-text secondary-text">ONE VOICE</span>
        </div>

        <div class="buttons">

            <!-- <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button> -->

            <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()">
                <!-- (click)="toggleSidebarOpened()" fxHide.gt-md> -->
                <mat-icon class="secondary-text">arrow_back</mat-icon>
            </button>

        </div>

    </div>
</ng-container>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
</div>