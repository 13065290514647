export enum CustomerChannelType {
    SESSION = "SESSION",
    WHATSAPP = "WHATSAPP",
    FACEBOOK = "FACEBOOK",
    MOBILE = "MOBILE",
    EMAIL = "EMAIL",
    GOOGLE_PLAY = "GOOGLE_PLAY",
    FACEBOOK_COMMENT = "FACEBOOK_COMMENT",
    YOUTUBE_PROFILE = "YOUTUBE_PROFILE",
    YOUTUBE_COMMENT = "YOUTUBE_COMMENT",
    INSTAGRAM = "INSTAGRAM",
}

export interface CustomerChannel {
    id?: number;
    channel: CustomerChannelType;
    identifier: string;
}
