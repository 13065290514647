import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Asset, AssetService, Room, RoomService } from 'onevoice';
import { AssetEditComponent } from '../asset-edit/asset-edit.component';

@Component({
  selector: 'main-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {
  public columns: string[] = [
    "title",
    "type",
    "actions",
  ]
  public datasource: MatTableDataSource<Asset> = new MatTableDataSource<Asset>();
  public room?: Room;

  constructor(
    public service: AssetService,
    public rooms: RoomService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
  ) { }

  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    this.datasource.paginator = value;
  }

  private runSort() {
    this.datasource.data.sort((f1, f2) => f2.id - f1.id);
    this.datasource.data = this.datasource.data;
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(param => {
      let floorId = param.get("floor"),
        roomId = param.get("room");

      if (floorId == null || roomId == null) {
        return;
      }

      this.rooms.read(Number(roomId)).subscribe(room => {
        this.room = room;
      })

      this.service.list({
        "roomId": roomId,
      }).subscribe(list => {
        this.datasource.data = list;
        this.runSort();
      })
    });
  }

  public editItem(data: Partial<Asset> = {}): void {
    data.room = this.room;
    this.dialog.open<AssetEditComponent, Partial<Asset>, Asset>(AssetEditComponent, {
      data: data,
      width: "500px",
      maxWidth: "90%",
    }).afterClosed().subscribe(asset => {
      if (asset === undefined) {
        console.log("No result received");
        return;
      }

      let action = () => this.service.create(asset);
      if (asset.id !== undefined) {
        action = () => this.service.update(asset);
      }

      action().subscribe(asset => {
        let newdata = this.datasource.data.filter(value => value.id != asset.id);
        newdata.push(asset);
        this.datasource.data = newdata;
        this.runSort();
      })
    });
  }

}
