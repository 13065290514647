import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChartOptions, ChartType } from 'chart.js';
import {
  AuthenticationService,
  Machine,
  MachineExecution,
  MachineExecutionService,
  MachineMap,
  MachineMapService,
  MachineService,
  MachineTask,
  MachineTaskService,
  MapUnit,
  MapUnitService,
  TimedTask,
  TimedTasksService,
  TriggerType,
} from 'onevoice';
import { forkJoin, Observable } from 'rxjs';
import { EmptyDataDialogComponent } from './empty-data-dialog/empty-data-dialog.component';
import { Action } from 'rxjs/internal/scheduler/Action';
import { __asyncValues } from 'tslib';
import { start } from '@alabia/biometrics/audio';

function ByDate(p1: MachineExecution, p2: MachineExecution) {
  return p2.start - p1.start;
}

const machineSurname = [
  'GS101-0100-CBM-J000',
  'GS101-0100-8BM-6000',
  'GS101-0100-CBM-2000',
  'GS101-0100-QBM-9000',
  'GS101-0100-7BM-5000',
  'GS142-0120-DAM-9000',
  'GS100-0030-N1N-2000',
  'GS101-0100-ABM-G000',
  'GS101-0100-LBM-9000',
  'GS142-0120-LBM-W000',
  'GS100-0030-M1N-3000',
];

const machineName = [
  'Esfregar50_1962',
  'Esfregar50_1963',
  'Esfregar50_1964',
  'Esfregar50_2044',
  'Esfregar50_1966',
  'Vacuum40_0497',
  'GS75_1592',
  'Esfregar50_1965',
  'Esfregar50_2053',
  'Vacuum40_0509',
  'GS75_1593',
];


@Component({
  selector: 'cleaning-dashboard',
  templateUrl: './cleaning-dashboard.component.html',
  styleUrls: ['./cleaning-dashboard.component.scss'],
})
export class CleaningDashboardComponent implements OnInit {
  public selectedTab: string = 'Áreas';
  public selectedIndex: number = 0;
  public dataExecution = new MatTableDataSource<MachineExecution>([]);
  public dataExecutionSlice = new MatTableDataSource<MachineExecution>([]);
  public dataExecutionManual = new MatTableDataSource<MachineExecution>([]);
  public datatest = new MatTableDataSource<MachineExecution>([]);
  public dataMachine = new MatTableDataSource<Machine>([]);
  public dataExecutionStatic: MachineExecution[] = []
  public unitList: MapUnit[] = []
  public cleanModeList = new Map();
  public chart?: Chart;
  public chart2?: Chart;
  public taskFilterWidth: boolean = true;
  public ctx: any;
  public ctx2: any;
  public chartZone?: Chart;
  public months: any[] = [];
  public expandList: boolean = false;
  public chartHeight: string = '200';
  public manualExecNumber = 0;
  public isLoading: boolean = false;
  public monthList: string[] = [];
  public options = {
    style: 'decimal',
    decimal: 'BRL',
  };
  public tasksNotExecutedByUnit: number[] = [];
  public scheduledTasks: number[] = [];
  public scheduledTasksnotExecuted: number[] = [];
  public scheduledLoad: boolean = false;

  public tasksMenu: string[] = ['tarefa 1', 'tarefa 2', 'tarefa 3'];
  public dataMap = new MatTableDataSource<MachineMap>([]);
  public dataTask = new MatTableDataSource<any>([]);
  public taksOptions = new MatTableDataSource<any>([]);
  public taskScheduler = new MatTableDataSource<any>([]);
  public filterControl = new FormControl('');
  public filteredOptions?: Observable<MachineTask[]>;

  public days: any[] = [];
  public axisXfull: any[] = [];
  public axisXmonth: any[] = [];
  public axisXdays: any[] = [];
  public axisXhour: any[] = [];

  public tasksLenght: number = 0;
  public zoneLenght: number = 0;
  public mapsLenght: number = 0;
  public timeLenght: number = 0;
  public mapLenght: number = 0;

  public memberTasks: number[] = [];
  public memberMaps: number[] = [];
  public memberZone: number[] = [];
  public memberTime: number[] = [];
  public totalAreaLenght: number = 0;
  public historicView: string = 'all';
  public cleaningModeListViewName: string[] = [];
  public cleaningUnitName: string[] = [];
  public memberView: string[] = [];
  public mapView?: MachineMap;
  private controlMapFilter: boolean = true;
  public taskView?: MachineTask;
  public trigger: TriggerType = TriggerType.MANUAL;
  public timedTasks: TimedTask[][] = [];
  public allTimedTasks: MachineTask[] = [];
  public tasksScheduledNotExecuted: MachineTask[] = [];
  public machineTaskScheduledByLastThreeMonths: MachineTask[][] = [];
  public machineTaskScheduledNotExecutedLasThreeMonths: MachineTask[][] = [];



  // Date
  public endDay?: Date;
  public startDay?: Date;
  public today: Date = new Date();
  public dateParams?: Date;
  public endParams?: Date;
  public scheduledChartType: ChartType = 'bar'
  public minDate: Date = new Date(2022, 0, 1, 0);

  public members: string[] = [];
  public membersFilter: any[] = [];
  public machineExecutionByUnit: number[] = []
  public gap: string = 'mensal';
  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  // axis Y all
  public yAllZone: number[] = [];
  public yAllArea: number[] = [];
  public yAllTask: number[] = [];
  public yAllMap: number[] = [];
  public yAllTime: number[] = [];

  // axis Y month
  public yMonthZone: number[] = [];
  public yMonthArea: number[] = [];
  public yMonthTask: number[] = [];
  public yMonthMap: number[] = [];
  public yMonthTime: number[] = [];
  public aScheduler: number[] = [];
  public bScheduler: number[] = [];
  public cScheduler: number[] = [];
  // axis Y day
  public yDayZone: number[] = [];
  public yDayArea: number[] = [];
  public yDayTask: number[] = [];
  public yDayTaskCanceled : number[] = [];

  public yDayMap: number[] = [];
  public yDayTime: number[] = [];

  // axis Y chats
  public yAxisZone: number[] = [];
  public yAxisArea: number[] = [];
  public yAxisTask: number[] = [];
  public yAxisTaskCanceled: number[] = [];

  public yAxisMap: number[] = [];
  public yAxisTime: number[] = [];
  public yAxisCleanMode: number[] = [];

  public columnsToDisplay: string[] = [
    'id',
    'name',
    'mapName',
    'model',
    'duration',
    'operator',
    'area',
    'start',
  ];

  @ViewChild(MatPaginator) public paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild('chartPrint', { static: false }) public chartPrint?: Chart;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public barChartLabels: any[] = [];
  public barChartLabelsMember: any[] = [];
  public barChartDataMemberTask: any[] = [];
  public barChartDataMemberTime: any[] = [];
  public barChartDataMemberZone: any[] = [];
  public barChartDataMemberMaps: any[] = [];
  public barChartDataZone: any[] = [];
  public barChartDataTask: any[] = [];
  public barChartDataMap: any[] = [];
  public barChartDataTime: any[] = [];
  public barChartDataUnit: any[] = [];
  public barChartCleanMode: any[] = [];
  public barChartDataMember: any[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = true;
  public optionsMachine = machineSurname;
  public machineName = machineName;
  public machineTaskScheduledByLastThreeDays: MachineTask[][] = [];
  public machineTaskScheduledByLastThreeDaysNumber: number[] = [];

  public numberOfExecutionsByCleaningMode: number[] = [];
  public numberOfExecutionsByUnit: number[] = [];


  public lineChartConfig: ChartOptions = {
    legend: {
      display: true,
    },
    responsive: true,
    animation: {
      duration: 1000,
      easing: 'easeOutQuart',
    },
    scales: {
      yAxes: [
        {
          display: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          display: true,
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartConfig: ChartOptions = {

  }
  public doughnutChartConfig: ChartOptions = {
    legend: {
      display: false,
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuart',
    },
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          display: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  executedScheduled: any;
  public daysList: number[] = [];
  public listScheduledNotExecutedLastDays: MachineTask[][] = [];
  public canceledTasks: MachineExecution[] = [];

  public scheduledTasksLastDays: number[] = [];
  public yMonthTaskCanceled: number[] = [];

  constructor(
    private serviceMachineExecution: MachineExecutionService,
    private serviceMachineMap: MachineMapService,
    private serviceMachineTask: MachineTaskService,
    private mapUnitService: MapUnitService,
    private auth: AuthenticationService,
    private serviceMachine: MachineService,
    public dialog: MatDialog,
    public timedTaskService: TimedTasksService,
  ) { }

  ngOnInit(): void {
    this.auth.systemToken().then((_) => {
      this.loadData();
      this.loadTask();
      this.loadMaps();
      this.loadUnits();
      this.executionMachineByCleainingMode();
      this.loadDataNotExecuted();
      this.listScheduledTasks();
      this.listScheduledTasksLastThreeMonths();
      // this.listMachineTaskScheduledNotExecutedLastThreeMonths();
      this.listScheduledTasksLastThreeDays()
    });
  }

  ngAfterViewInit(): void {
    this.loadTableOptions();

  }
  public loadDataNotExecuted() {

    let startParam = this.dateParams || new Date();
    startParam = new Date();
    startParam.setDate(this.today.getDay() - 90);
    startParam.setDate(1);
    startParam.setHours(0, 0, 0, 0);
    let endParam = new Date();

    let info: MachineTask[] = [];

    this.timedTaskService.scheduledNotExecuted(startParam.getTime(), endParam.getTime()).subscribe(data => {

      info = data.filter(single => single != undefined);
      info = info.filter(single => single.map != undefined);
      this.tasksScheduledNotExecuted = info;
      this.tasksNotExecutedByUnit[0] = info.length
      // info.forEach(task => {
      //   this.unitList.forEach(unit => {
      //     if (unit.id === task.map.unit.id) {
      //       let index = this.unitList.findIndex(uni => uni.id === unit.id);
      //       if (index !== -1) {
      //         this.tasksNotExecutedByUnit[index] = (this.tasksNotExecutedByUnit[index] || 0) + 1;
      //       }
      //     }
      //   });
      // });

      this.numberExecutionsByUnit();

    });
  }

  public listScheduledTasksLastThreeMonths() {
    this.monthList = [];
    this.machineTaskScheduledByLastThreeMonths = [];

    // Definir os períodos
    let startParam = new Date();
    startParam.setDate(this.today.getDate() - 60);
    startParam.setDate(1);
    startParam.setHours(0, 0, 0, 0);
    this.monthList.push(this.getMonthName(startParam.getMonth()));

    let endParam = new Date();
    endParam.setDate(endParam.getDate() - 30);
    endParam.setDate(1);
    endParam.setDate(endParam.getDate() - 1);

    const request1 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime());

    // Segundo período
    startParam = new Date();
    startParam.setDate(this.today.getDate() - 30);
    startParam.setDate(1);
    startParam.setHours(0, 0, 0, 0);
    this.monthList.push(this.getMonthName(startParam.getMonth()));

    endParam = new Date();
    endParam.setDate(1);
    endParam.setDate(endParam.getDate() - 1);

    const request2 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime());

    // Terceiro período
    startParam = new Date();
    startParam.setDate(1);
    startParam.setHours(0, 0, 0, 0);
    this.monthList.push(this.getMonthName(startParam.getMonth()));

    endParam = new Date();

    const request3 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime());

    // Combinar todas as requisições com forkJoin
    forkJoin([request1, request2, request3]).subscribe(
      ([response1, response2, response3]) => {
        // Processar os resultados
        this.machineTaskScheduledByLastThreeMonths[0] = response1.filter(single => single != undefined && single.map != undefined);
        this.machineTaskScheduledByLastThreeMonths[1] = response2.filter(single => single != undefined && single.map != undefined);
        this.machineTaskScheduledByLastThreeMonths[2] = response3.filter(single => single != undefined && single.map != undefined);

        // Atualizar os dados da interface
        this.machineTaskScheduledByLastThreeMonths.forEach((data, index) => {
          this.scheduledTasks[index] = data.length;
        });

        // Chamar a próxima função após tudo estar concluído
        this.listMachineTaskScheduledNotExecutedLastThreeMonths();

      },
      (error) => {
        console.error("Erro ao carregar tarefas agendadas:", error);
      }
    );
  }
  public listScheduledTasksNotExecutedLastThreeDays() {

    let startParam = new Date();
    startParam.setDate(this.today.getDate() - 2);
    startParam.setHours(0, 0, 0, 0);

    let endParam = new Date();
    endParam.setDate(endParam.getDate() - 2);
    endParam.setHours(23, 59, 59)

    const request1 = this.timedTaskService.scheduledNotExecuted(startParam.getTime(), endParam.getTime())


    startParam = new Date();
    startParam.setDate(this.today.getDate() - 1);
    startParam.setHours(0, 0, 0, 0);

    endParam = new Date();
    endParam.setDate(endParam.getDate() - 1);
    endParam.setHours(23, 59, 59)

    const request2 = this.timedTaskService.scheduledNotExecuted(startParam.getTime(), endParam.getTime())

    startParam = new Date();
    startParam.setHours(0, 0, 0, 0);

    endParam = new Date();
    endParam.setHours(23, 59, 59)

    const request3 = this.timedTaskService.scheduledNotExecuted(startParam.getTime(), endParam.getTime())

    forkJoin([request1, request2, request3]).subscribe(
      ([response1, response2, response3]) => {
        // Processar os resultados
        this.listScheduledNotExecutedLastDays[0] = response1.filter(single => single != undefined && single.map != undefined);
        this.listScheduledNotExecutedLastDays[1] = response2.filter(single => single != undefined && single.map != undefined);
        this.listScheduledNotExecutedLastDays[2] = response3.filter(single => single != undefined && single.map != undefined);

        // Atualizar os dados da interface
        this.listScheduledNotExecutedLastDays.forEach((data, index) => {
          this.scheduledTasksLastDays[index] = data.length;
        });

        // Chamar a próxima função após tudo estar concluído
        this.listMachineTaskScheduledNotExecutedLastThreeMonths();

      },
      (error) => {
        console.error("Erro ao carregar tarefas agendadas:", error);
      }
    );
  }
  public listScheduledTasksLastThreeDays() {
    // this.monthList = [];
    this.listScheduledTasksNotExecutedLastThreeDays()
    // this.machineTaskScheduledByLastThreeMonths = [];

    // Definir os períodos
    let startParam = new Date();
    startParam.setDate(this.today.getDate() - 2);
    startParam.setHours(0, 0, 0, 0);
    this.daysList.push(startParam.getDate());

    let endParam = new Date();
    endParam.setDate(endParam.getDate() - 2);
    endParam.setHours(23, 59, 59)

    const request1 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime())

    // Segundo período
    startParam = new Date();
    startParam.setDate(this.today.getDate() - 1);
    startParam.setHours(0, 0, 0, 0);
    this.daysList.push(startParam.getDate());


    endParam = new Date();
    endParam.setHours(23, 59, 59)
    endParam.setDate(endParam.getDate() - 1);

    const request2 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime());

    // Terceiro período
    startParam = new Date();
    startParam.setHours(0, 0, 0, 0);

    this.daysList.push(startParam.getDate());

    endParam = new Date();

    const request3 = this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime());

    // Combinar todas as requisições com forkJoin
    forkJoin([request1, request2, request3]).subscribe(
      ([response1, response2, response3]) => {
        // Processar os resultados
        this.machineTaskScheduledByLastThreeDays[0] = response1.filter(single => single != undefined && single.map != undefined);
        this.machineTaskScheduledByLastThreeDays[1] = response2.filter(single => single != undefined && single.map != undefined);
        this.machineTaskScheduledByLastThreeDays[2] = response3.filter(single => single != undefined && single.map != undefined);

        // Atualizar os dados da interface
        this.machineTaskScheduledByLastThreeDays.forEach((data, index) => {
          this.machineTaskScheduledByLastThreeDaysNumber[index] = data.length;
        });

        console.log(this.machineTaskScheduledByLastThreeDaysNumber)
      },
      (error) => {
        console.error("Erro ao carregar tarefas agendadas:", error);
      }
    );
  }

  public listMachineTaskScheduledNotExecutedLastThreeMonths() {
    this.machineTaskScheduledNotExecutedLasThreeMonths = [];

    const startParams: Date[] = [];
    const endParams: Date[] = [];

    // Parâmetros para os últimos 3 meses
    const today = new Date();

    // Mês -2
    const start1 = new Date(today.getFullYear(), today.getMonth() - 2, 1);
    const end1 = new Date(today.getFullYear(), today.getMonth() - 1, 0);
    startParams.push(start1);
    endParams.push(end1);

    // Mês -1
    const start2 = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const end2 = new Date(today.getFullYear(), today.getMonth(), 0);
    startParams.push(start2);
    endParams.push(end2);

    // Mês atual
    const start3 = new Date(today.getFullYear(), today.getMonth(), 1);
    const end3 = new Date();
    startParams.push(start3);
    endParams.push(end3);

    // Executa todas as requisições em paralelo
    const requests = startParams.map((start, index) => {
      const end = endParams[index];
      return this.timedTaskService.scheduledNotExecuted(start.getTime(), end.getTime());
    });




    forkJoin(requests).subscribe({
      next: (results) => {
        results.forEach((data, index) => {
          const filteredData = (data || []) // Garante que 'data' seja um array
            .filter(single => single != null) // Remove elementos nulos ou indefinidos
            .filter(single => single.map != null); // Garante que 'map' existe

          this.machineTaskScheduledNotExecutedLasThreeMonths[index] = filteredData;
        });

        // Atualiza os dados de execução por mês
        this.machineTaskScheduledNotExecutedLasThreeMonths.forEach((data, index) => {
          this.scheduledTasksnotExecuted[index] = data.length;
        });

        // Chama o método loadChart após todas as requisições completarem
        this.executedScheduled = this.scheduledTasks.map((scheduled, index) => {
          const notExecuted = this.scheduledTasksnotExecuted[index] || 0; // Garante que valores ausentes sejam tratados como 0
          return scheduled - notExecuted;
        });



        this.scheduledLoad = true;
        this.loadChart();

      },
      error: (err) => {
        console.error("Erro ao carregar as tarefas agendadas:", err);
      }
    });


  }

  public getMonthName(monthNumber: number): string {
    const monthNames = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
    return monthNames[monthNumber];
  }
  public listScheduledTasks() {

    let startParam = this.dateParams || new Date();
    startParam = new Date();
    startParam.setDate(this.today.getDay() - 90);
    startParam.setDate(1);
    startParam.setHours(0, 0, 0, 0);
    let endParam = new Date();

    let info: MachineTask[] = [];
    let params = new HttpParams();
    this.timedTaskService.getScheduled(startParam.getTime(), endParam.getTime()).subscribe(data => {

      info = data.filter(single => single != undefined);
      info = info.filter(single => single.map != undefined);
      this.allTimedTasks = info;
      // info.forEach(task => {
      //   this.unitList.forEach(unit => {
      //     if (unit.id === task.map.unit.id) {
      //       let index = this.unitList.findIndex(uni => uni.id === unit.id);
      //       if (index !== -1) {
      //         this.scheduledTasks[index] = (this.scheduledTasks[index] || 0) + 1;
      //       }
      //     }
      //   });
      // });
      // this.scheduledTasks[0] = this.allTimedTasks.length;

    });
  }


  private loadTableOptions() {
    if (this.paginator) {
      this.dataExecutionSlice.paginator = this.paginator;
    }

    if (this.sort) {
      this.dataExecutionSlice.sort = this.sort;
    }
  }

  public loadTask(): void {
    this.serviceMachineTask.list().subscribe((tasks) => {
      this.dataTask.data = tasks;
    });
  }

  // public loadCleaningModels(){
  //     let cleanModes : string[];
  //     this.dataExecution.data.forEach(single =>{
  //       cleansingle.data.cleaningMode
  //     })
  // }
  public loadClaningModes() {

    this.cleanModeList.set('__尘推', 'Varrer');
    this.cleanModeList.set('__洗地', 'Esfregar');
    this.cleanModeList.set('__喷雾消毒', 'Desinfetar');
    this.cleanModeList.set('__消杀', 'Desinfetar ');
    this.cleanModeList.set('Sweep', 'Varrer');
    this.cleanModeList.set('vacuum', 'Aspirar');
    this.cleanModeList.set('dust mop', 'Varrer');
    this.cleanModeList.set('light_cleaning', 'Esfregar');
    this.cleanModeList.set('middle_cleaning', 'Esfregar');
    this.cleanModeList.set('heavy_cleaning', 'Esfregar');
    this.cleanModeList.set('custom_clean', 'Esfregar');
    this.cleanModeList.set('custom_cleaning', 'Esfregar');
    this.cleanModeList.set('suction_cleaning', 'Aspirar');
    this.cleanModeList.set('__滚刷尘推', 'Varrer');


    this.cleanModeList.set('清洗', 'Esfregar');
    this.cleanModeList.set('__light_cleaning', 'Esfregar');
    this.cleanModeList.set('___suction_cleaning_滚刷尘推', 'Aspirar');



  }

  public loadMaps(): void {
    this.serviceMachineMap.list().subscribe((maps) => {
      this.dataMap.data = maps;
    });
  }
  public loadUnits(): void {
    this.mapUnitService.list().subscribe(list => {
      this.unitList = list;
      list.forEach(units => {
        this.cleaningUnitName.push(units.name);

      })
    })
  }
  public numberExecutionsByUnit() {
    this.unitList.forEach(unit => {
      this.numberOfExecutionsByUnit[this.unitList.indexOf(unit)] = this.scheduledTasks[this.unitList.indexOf(unit)] - this.tasksNotExecutedByUnit[this.unitList.indexOf(unit)];
    })
  }



  public executionMachineByCleainingMode() {
    this.cleaningModeListViewName.forEach(cleaningMode => {
      this.numberOfExecutionsByCleaningMode[this.cleaningModeListViewName.indexOf(cleaningMode)]
        = this.loadNumberOfExecutions(cleaningMode)
    })
  }

  public loadCleaningMode() {
    this.loadClaningModes()
    this.dataExecution.data.forEach(single => {
      if (single.trigger == "AUTO") {
        if (this.cleanModeList.has(single.data.cleaningMode)) {
          this.cleaningModeListViewName.push(this.cleanModeList.get(single.data.cleaningMode))
          single.data.cleaningMode = this.cleanModeList.get(single.data.cleaningMode)
        } else {
          this.cleaningModeListViewName.push(single.data.cleaningMode)
        }
      }
      // if (single.trigger == "MANUAL") {
      //   this.cleaningModeListViewName.push(single.trigger)

      // }
      this.cleaningModeListViewName = [...new Set(this.cleaningModeListViewName)]
    })
    this.executionMachineByCleainingMode()
  }


  public loadData(): void {
    this.isLoading = true;
    this.dateParams = new Date();
    this.dateParams.setDate(this.today.getDay() - 90);
    this.dateParams.setDate(1);
    this.dateParams.setHours(0, 0, 0, 0);
    this.endParams = new Date();
    let params = new HttpParams();
    params = params.append('startDate', this.dateParams.getTime());
    this.serviceMachine.list().subscribe((machines) => {
      this.dataMachine = new MatTableDataSource<Machine>(machines);
    });
    this.serviceMachineExecution.list(params).subscribe(history => {
      this.dataExecution = new MatTableDataSource<MachineExecution>(
        history.sort(ByDate)
      );
      this.canceledTasks = [];
      this.dataExecution.data.forEach(exec=>{
        if(exec.duration < 360 ){
          this.canceledTasks.push(exec);
        }

      })
      console.log(this.canceledTasks)
      this.dataExecutionStatic = this.dataExecution.data;
      this.dataExecutionSlice.data = this.dataExecution.data.slice(0, 500);
      this.loadCleaningMode();
      this.axisXgenerate();
      this.numberOfManualExec(this.dataExecutionStatic);

    });
  }
  public filterByCleaningMode(model: string) {
    this.filterByHumanOrRobot('AUTO')
    if (model == 'all') {
      this.dataExecution.data = this.dataExecutionStatic
      this.resetData()
      return;
    }
    this.dataExecution.data = this.dataExecutionStatic.filter(single => single.data.cleaningMode == model)
    this.resetData()
  }
  public numberOfManualExec(list: MachineExecution[]) {
    this.manualExecNumber = 0;
    list.forEach(exec => {
      if (exec.trigger == 'MANUAL') {
        this.manualExecNumber += 1
      }
    })
  }
  get successRate() {
    let data = this.dataExecution.data.map(value => value.completeness);
    return data.reduce((acc, cur) => acc + cur, 0) / data.length
  }

  public filterByHumanOrRobot(model: string) {
    if (model == 'all') {
      this.axisXgenerate()
      this.resetData()
      return this.loadIntervalData()
    }
    this.dataExecution.data = this.dataExecutionStatic.filter(single => single.trigger == model)
    this.resetData()
    this.axisXgenerate()
  }
  public resetData() {
    this.axisXgenerate()
    this.memberViewChange();
    this.mapViewChange();
    this.taskViewChange();
    this.dataExecutionSlice.data = this.dataExecution.data.slice(0, 500);
    this.loadTableOptions();
    this.axisXgenerate();
    this.loadCleaningMode();
  }

  public loadNumberOfExecutions(type: string) {

    if (type === "MANUAL") {
      return this.dataExecution.data.filter(data => data.trigger == type).length
    }

    return this.dataExecution.data.filter(data => data.data?.cleaningMode === type).length ?? 0;

  }
  public loadNumbeExecByUnit(type: string) {
    return this.dataExecution.data.filter(data => data.task.map.unit.name == type).length

  }
  public percentage(type: string) {

    if (type == "MANUAL") {
      let typeLenght = this.dataExecution.data.filter(data => data.trigger == type).length
      let percentage = (typeLenght / this.dataExecution.data.length * 100).toFixed(2) + " %"
      return percentage;
    }
    let typeLenght = this.dataExecution.data.filter(data => data.data?.cleaningMode == type).length
    if (typeLenght < 1) {
      return " %"
    }
    let percentage = (typeLenght / this.dataExecution.data.length * 100).toFixed(2) + " %"
    return percentage
  }


  public filterByUnit(model: string) {

    if (model == 'all') {
      this.dataExecution.data = this.dataExecutionStatic;
      this.resetData()
      return
    }
    this.dataExecution.data = this.dataExecutionStatic.filter(execution => execution.machine && execution.machine.location && execution.machine.location.id === Number(model)
    )
    this.axisXgenerate()
    this.resetData()
  }

  public loadIntervalData(): void {
    this.isLoading = !this.isLoading;
    let params = new HttpParams();
    if (this.startDay && this.endDay) {
      params = params.append('startDate', this.startDay.getTime());
      params = params.append('endDate', this.endDay.getTime());
      this.serviceMachineExecution.list(params).subscribe((history) => {
        this.dataExecution = new MatTableDataSource<MachineExecution>(
          history.sort(ByDate)
        );
        this.dataExecutionStatic = this.dataExecution.data;
        this.memberViewChange();
        this.mapViewChange();
        this.taskViewChange();
        this.dataExecutionSlice.data = this.dataExecution.data.slice(0, 500);
        this.loadTableOptions();
        this.axisXgenerate();
        this.loadCleaningMode();
      });
    }
  }
  public chartTypeMonth() {
    let type: ChartType = "bar";
    this.scheduledChartType = type;
  }

  public chartTypeDay() {
    let type: ChartType = "line";
    this.scheduledChartType = type;
  }


  public axisXgenerate(): void {
    if (this.dataExecution.data.length == 0) {
      this.resetAxis();
      this.resetCards();
      this.loadCleaningMode();
      this.isLoading = !this.isLoading;
      this.openDialog();

      return;
    }
    this.dataExecution.data.sort((a, b) => a.start - b.start);
    if (this.startDay == undefined || this.historicView == 'all') {
      if (this.dataExecution.data.length > 0) {
        this.startDay = new Date(this.dataExecution.data[0].start);
        this.endDay = this.today;
      }
    }

    this.resetAxis();

    if (this.startDay) {
      // valor inicial do dia no eixo X
      this.axisXdays[0] =
        this.startDay.toLocaleString('default', { day: 'numeric' }) +
        '/' +
        this.startDay.toLocaleString('default', { month: 'long' }) +
        '/' +
        this.startDay.toLocaleString('default', { year: 'numeric' });

      // valor inicial do mes no eixo X
      this.axisXmonth[0] =
        this.startDay.toLocaleString('default', { month: 'long' }) +
        '/' +
        this.startDay.toLocaleString('default', { year: 'numeric' });

      this.yDayZone[0] = 0;
      this.yDayArea[0] = 0;
      this.yDayTask[0] = 0;
      this.yDayTaskCanceled[0] = 0;
      this.yDayMap[0] = 0;
      this.yDayTime[0] = 0;

      this.yMonthZone[0] = 0;
      this.yMonthArea[0] = 0;
      this.yMonthTask[0] = 0;
      this.yMonthTaskCanceled[0] = 0;

      this.yMonthMap[0] = 0;
      this.yMonthTime[0] = 0;

      this.zoneLenght = 0;
      this.totalAreaLenght = 0;
      this.tasksLenght = 0;
      this.mapLenght = 0;
      this.timeLenght = 0;
    }

    this.dataExecution.data.map((e) => {
      let date = new Date(e.start);

      // Members
      if (e.operator != undefined && this.members.indexOf(e.operator) == -1) {
        this.members.push(e.operator);
        if (this.membersFilter.indexOf(e.operator) == -1) {
          this.membersFilter.push(e.operator);
        }

        this.memberTasks[this.memberTasks.length] = 0;
        this.memberMaps[this.memberMaps.length] = 0;
        this.memberTime[this.memberTime.length] = 0;
        this.memberZone[this.memberZone.length] = 0;
      }

      //  HISTORICO DO DIA
      if (this.historicView == 'today') {
        if (
          this.axisXhour.length == 0 ||
          date.toLocaleString('default', { hour: 'numeric' }) !=
          this.axisXhour[this.axisXhour.length - 1].split(' horas')[0]
        ) {
          this.axisXhour.push(
            date.toLocaleString('default', { hour: 'numeric' }) + ' horas'
          );

          this.yDayZone[this.axisXhour.length - 1] = 0;
          this.yDayArea[this.axisXhour.length - 1] = 0;
          this.yDayTask[this.axisXhour.length - 1] = 0;
          this.yDayTaskCanceled[this.axisXhour.length - 1] = 0;
          this.yDayMap[this.axisXhour.length - 1] = 0;
          this.yDayTime[this.axisXhour.length - 1] = 0;
        }


        // **** area limpa ****
        if (e.area.covered) {
          // area limpa do dia
          this.yDayZone[this.axisXhour.length - 1] += e.area.covered;
          // this.yDayZone[this.axisXhour.length - 1] = parseFloat(
          //   this.yDayZone[this.axisXhour.length - 1].toFixed(2)
          // );

          // area limpa card
          this.zoneLenght += e.area.covered;

          // area limpa membro
          this.memberZone[this.members.indexOf(e.operator)] += e.area.covered;
          // this.memberZone[this.members.indexOf(e.operator)] = parseFloat(
          //   this.memberZone[this.members.indexOf(e.operator)].toFixed(2)
          // );

          // **** area de cobertura ****
          // area total do dia
          this.yDayArea[this.axisXhour.length - 1] += e.area.total;
          // this.yDayArea[this.axisXhour.length - 1] = parseFloat(
          //   this.yDayArea[this.axisXhour.length - 1].toFixed(2)
          // );

          // area total card
          this.totalAreaLenght += e.data.totalArea;
        }

        // **** tarefas ****
        if (e.data.tasks) {
          // tarefas do dia
          this.yDayTask[this.axisXhour.length - 1] += e.data.tasks.length;
          // this.yDayTaskCanceled[this.axisXhour.length - 1] += e.data.tasks.
          // tarefas card
          this.tasksLenght += e.data.tasks.length;

          // tarefas por membros
          this.memberTasks[this.members.indexOf(e.data.operator)] +=
            e.data.tasks.length;
        } else {
          this.yDayTask[this.axisXhour.length - 1] += 1;
          this.tasksLenght += 1;
          this.memberTasks[this.members.indexOf(e.operator)] += 1;
        }

        // **** mapas ****
        // mapas do dia
        this.yDayMap[this.axisXhour.length - 1]++;
        console.log(this.axisXhour)
        // mapas card
        this.mapLenght++;

        // mapas membros
        e.data.operator
          ? this.memberMaps[this.members.indexOf(e.data.operator)]++
          : this.memberMaps[this.members.indexOf(e.operator)]++;

        // **** tempo ****
        // tempo por dia
        this.yDayTime[this.axisXhour.length - 1] += e.duration / 3600;
        // this.yDayTime[this.axisXhour.length - 1] = parseFloat(
        //   this.yDayTime[this.axisXhour.length - 1].toFixed(2)
        // );

        // tempo card
        this.timeLenght += e.duration / 3600;

        // tempo membro
        this.memberTime[this.members.indexOf(e.operator)] += e.duration / 3600;
        // this.memberTime[this.members.indexOf(e.operator)] = parseFloat(
        //   this.memberTime[this.members.indexOf(e.operator)].toFixed(2)
        // );
      } else {
        if (
          date.toLocaleString('default', { day: 'numeric' }) !=
          this.axisXdays[this.axisXdays.length - 1].split('/')[0]
        ) {
          if (
            date.toLocaleString('default', { day: 'numeric' }) != 'Invalid Date'
          ) {
            this.axisXdays[this.axisXdays.length] =
              date.toLocaleString('default', { day: 'numeric' }) +
              '/' +
              date.toLocaleString('default', { month: 'long' }) +
              '/' +
              date.toLocaleString('default', { year: 'numeric' });

            this.yDayZone[this.axisXdays.length - 1] = 0;
            this.yDayArea[this.axisXdays.length - 1] = 0;
            this.yDayTask[this.axisXdays.length - 1] = 0;
            this.yDayMap[this.axisXdays.length - 1] = 0;
            this.yDayTime[this.axisXdays.length - 1] = 0;

            if (
              date.toLocaleString('default', { month: 'long' }) !=
              this.axisXmonth[this.axisXmonth.length - 1].split('/')[0] &&
              date.toLocaleString('default', { month: 'long' }) !=
              'Invalid Date'
            ) {
              this.axisXmonth[this.axisXmonth.length] =
                date.toLocaleString('default', { month: 'long' }) +
                '/' +
                date.toLocaleString('default', { year: 'numeric' });

              this.yMonthZone[this.axisXmonth.length - 1] = 0;
              this.yMonthArea[this.axisXmonth.length - 1] = 0;
              this.yMonthTask[this.axisXmonth.length - 1] = 0;
              this.yMonthTaskCanceled[this.axisXmonth.length - 1] = 0;
              this.yMonthMap[this.axisXmonth.length - 1] = 0;
              this.yMonthTime[this.axisXmonth.length - 1] = 0;
            }
          }
        }

        // area limpa
        if (e.area) {
          // area limpa dia
          this.yDayZone[this.axisXdays.length - 1] += e.area.covered;
          // this.yDayZone[this.axisXdays.length - 1] = parseFloat(
          //   this.yDayZone[this.axisXdays.length - 1].toFixed(2)
          // );

          // area limpa mes
          this.yMonthZone[this.axisXmonth.length - 1] += e.area.covered;
          // this.yMonthZone[this.axisXmonth.length - 1] = parseFloat(
          //   this.yMonthZone[this.axisXmonth.length - 1].toFixed(2)
          // );

          this.zoneLenght += e.area.covered;

          // area limpa membro
          this.memberZone[this.members.indexOf(e.operator)] += e.area.covered;
          // this.memberZone[this.members.indexOf(e.operator)] = parseFloat(
          //   this.memberZone[this.members.indexOf(e.operator)].toFixed(2)
          // );
        }

        // area de cobertura
        if (e.area) {
          // area de cobertura dias
          this.yDayArea[this.axisXdays.length - 1] += e.area.total;
          this.yDayArea[this.axisXdays.length - 1] = this.yDayArea[this.axisXdays.length - 1];
          // area de cobertura mes
          this.yMonthArea[this.axisXmonth.length - 1] += e.area.total;
          // this.yMonthArea[this.axisXmonth.length - 1] = parseFloat(
          //   this.yMonthArea[this.axisXmonth.length - 1].toFixed(2)
          // );
          this.totalAreaLenght += e.area.total;
        }

        // tarefas
        if (e.data.tasks) {

          // tarefas dias
          this.yDayTask[this.axisXdays.length - 1] += e.data.tasks.length;
          this.yDayTaskCanceled[this.axisXdays.length - 1] += e.data.tasks.length/2;

          // tarefas mes
          this.yMonthTask[this.axisXmonth.length - 1] += e.data.tasks.length;
          if(e.duration < 300){
            this.yMonthTaskCanceled[this.axisXmonth.length - 1] += 1;

          }
          this.tasksLenght += e.data.tasks.length;
          this.memberTasks[this.members.indexOf(e.operator)] +=
            e.data.tasks.length;
        } else {
          // tarefas dias
          this.yDayTask[this.axisXdays.length - 1] += 1;
          this.yDayTaskCanceled[this.axisXdays.length - 1] += 1;

          // tarefas mes
          this.yMonthTask[this.axisXmonth.length - 1] += 1;
          this.tasksLenght += 1;
          this.memberTasks[this.members.indexOf(e.operator)] += 1;
        }

        // mapas
        // mapas dia
        this.yDayMap[this.axisXdays.length - 1]++;
        // mapas mes
        this.yMonthMap[this.axisXmonth.length - 1]++;
        this.mapLenght++;
        // mapas membros
        this.memberMaps[this.members.indexOf(e.operator)]++;

        // tempo
        // tempo dia
        this.yDayTime[this.axisXdays.length - 1] += e.duration / 3600;
        // this.yDayTime[this.axisXdays.length - 1] = parseFloat(
        //   this.yDayTime[this.axisXdays.length - 1].toFixed(2)
        // );
        // tempo mes
        this.yMonthTime[this.axisXmonth.length - 1] += e.duration / 3600;
        // this.yMonthTime[this.axisXmonth.length - 1] = parseFloat(
        //   this.yMonthTime[this.axisXmonth.length - 1].toFixed(2)
        // );

        this.timeLenght += e.duration / 3600;
        if (e.operator) {
          this.memberTime[this.members.indexOf(e.operator)] +=
            e.duration / 3600;
          // this.memberTime[this.members.indexOf(e.operator)] = parseFloat(
          //   this.memberTime[this.members.indexOf(e.operator)].toFixed(2)
          // );
        } else {
          this.memberTime[this.members.indexOf(e.operator)] +=
            e.duration / 3600;
          // this.memberTime[this.members.indexOf(e.operator)] = parseFloat(
          //   this.memberTime[this.members.indexOf(e.operator)].toFixed(2)
          // );
        }
      }
    });
    console.log("this.yMonthTaskCanceled")

    console.log(this.yMonthTaskCanceled)
    console.log("this.yMonthTask")

    console.log(this.yMonthTask)
    this.loadTypeToggle();
    this.dataView();
  }

  public loadTypeToggle(): void {
    if (this.axisXmonth.length > 1) {
      this.gap = 'mensal';
    } else if (this.axisXdays.length > 1) {
      this.gap = 'diario';
    } else {
      this.gap = 'hoje';
    }
  }

  private resetAxis() {
    this.axisXdays.splice(0, this.axisXdays.length);
    this.axisXmonth.splice(0, this.axisXmonth.length);
    this.axisXhour.splice(0, this.axisXhour.length);

    this.yMonthZone.splice(0, this.yMonthZone.length);
    this.yMonthArea.splice(0, this.yMonthArea.length);
    this.yMonthTask.splice(0, this.yMonthTask.length);
    this.yMonthMap.splice(0, this.yMonthMap.length);
    this.yMonthTime.splice(0, this.yMonthTime.length);

    this.yDayZone.splice(0, this.yDayZone.length);
    this.yDayArea.splice(0, this.yDayArea.length);
    this.yDayTask.splice(0, this.yDayTask.length);
    this.yDayTaskCanceled.splice(0, this.yDayTask.length);
    this.yDayMap.splice(0, this.yDayMap.length);
    this.yDayTime.splice(0, this.yDayTime.length);

    this.memberZone.splice(0, this.members.length);
    this.memberTasks.splice(0, this.members.length);
    this.memberMaps.splice(0, this.members.length);
    this.memberTime.splice(0, this.members.length);
    this.members.splice(0, this.members.length);
  }

  private resetCards() {
    this.zoneLenght = 0;
    this.totalAreaLenght = 0;
    this.tasksLenght = 0;
    this.mapLenght = 0;
    this.timeLenght = 0;
  }

  public dataView(): void {
    if (this.gap == 'mensal') {
      // axis X
      this.barChartLabels = this.axisXmonth;
      this.barChartLabelsMember = this.members;

      // axis Y
      this.yAxisZone = this.yMonthZone;
      this.yAxisArea = this.yMonthArea;
      this.yAxisTask = this.yMonthTask;
      this.yAxisTaskCanceled = this.yMonthTaskCanceled;
      this.yAxisMap = this.yMonthMap;
      this.yAxisTime = this.yMonthTime;
      this.aScheduler = this.executedScheduled;
      this.bScheduler = this.scheduledTasksnotExecuted;
      this.cScheduler = this.scheduledTasks;
    } else if (this.gap == 'diario') {
      // axis X
      this.barChartLabels = this.axisXdays;
      this.barChartLabelsMember = this.members;

      // axis Y
      // this.yAxisZone = this.yDayZone;
      this.yAxisZone = this.yDayZone;
      this.yAxisArea = this.yDayArea;
      this.yAxisTask = this.yDayMap;

      this.yAxisMap = this.yDayMap;
      this.yAxisTime = this.yDayTime;

      this.aScheduler = this.scheduledTasksLastDays;
      this.bScheduler = this.scheduledTasksnotExecuted;
      this.cScheduler = this.machineTaskScheduledByLastThreeDaysNumber;
    } else if (this.gap == 'hoje') {
      // axis X
      this.barChartLabels = this.axisXhour;
      this.barChartLabelsMember = this.members;

      // axis Y
      this.yAxisZone = this.yDayZone;
      this.yAxisArea = this.yDayArea;
      this.yAxisTask = this.yDayMap;
      this.yAxisMap = this.yDayMap;
      this.yAxisTime = this.yDayTime;
    }
    this.loadChart();

  }

  public loadChart(): void {
    console.log(this.yAxisTask)
    console.log(this.yAxisTaskCanceled)
    this.barChartDataZone = [
      {
        data: this.yAxisZone.map(value => value.toFixed(2)),
        label: 'Área limpa (metros)',
        fill: true,
        backgroundColor: 'rgba(252, 78, 18, .2)',
        borderColor: '#fc4e12',
        pointBackgroundColor: '#fc4e12',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
      {
        data: this.yAxisArea.map(value => value.toFixed(2)),
        label: 'Área de cobertura',
        fill: true,
        backgroundColor: 'rgba(94, 186, 217, .3)',
        borderColor: '#5ebad9',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)',
      },
    ];

    this.barChartDataTask = [
      {
        data: this.yAxisTask,
        label: 'Tarefas conclídas',
        fill: true,
        backgroundColor: 'rgba(128, 226, 32, .4)',
        borderColor: '#80e220',
        pointBackgroundColor: '#77ea7e',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
      },
      {
        data: this.yAxisTaskCanceled,
        label: 'Canceladas',
        fill: true,
        backgroundColor: 'yellow',
        borderColor: '#80e220',
        pointBackgroundColor: '#77ea7e',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'blue',
      }
    ];

    this.barChartDataMap = [
      {
        data: this.yAxisMap,
        label: 'Mapas',
        backgroundColor: ['rgba(154, 117, 252, .5)'],
        borderColor: ['rgb(142, 36, 170)'],
        pointBackgroundColor: 'rgba(154, 117, 252, 1)',
        pointBorderColor: '#FFF',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(142, 36, 170)',
        borderWidth: 3,
      },
    ];

    this.barChartDataTime = [
      {
        data: this.yAxisTime.map(value => value.toFixed(2)),
        label: 'Horas',
        backgroundColor: ['rgba(30, 165, 252, .3'],
        borderColor: ['#1EA5FC'],
        pointBackgroundColor: '#1EA5FC',
        pointBorderColor: '#FFF',
        borderWidth: 3,
      },
    ];

    this.barChartDataUnit = [
      {
        data: this.executedScheduled,
        label: 'Executadas',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: '#36A2EB',
        borderWidth: 3,
      },
      {
        data: this.scheduledTasksnotExecuted,
        label: 'Não Executadas',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: '#FF6384',
        borderWidth: 3,
      },
      {
        data: this.scheduledTasks,
        label: 'Planejado',
        backgroundColor: 'rgba(255, 206, 86, 0.5)',
        borderColor: '#FFCE56',
        borderWidth: 3,
      },
    ];


    this.barChartCleanMode = [
      {
        indexAxis: 'y',
        data: this.numberOfExecutionsByCleaningMode,
        label: 'Modo de limpeza',
        backgroundColor: [
          'rgba(61, 74, 251, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(151, 153, 157, 0.2)',
          'rgba(195, 49, 73, 0.3)',
          'rgba(242, 78, 30, 0.5)',
          'rgba(26, 188, 254, 0.5)',
          'rgba(10, 207, 131, 0.2)',
          'rgba(162, 89, 255, 0.2)',
          'rgba(240, 200, 8, 0.3)',
          'rgba(28, 15, 19, 0.2)',
        ],
        borderColor: [
          'rgb(61, 74, 251)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(61, 74, 251)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        hoverBorderColor: [
          'rgb(61, 74, 251)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
      },
    ];
    this.barChartDataMemberTask = [
      {
        indexAxis: 'y',
        data: this.memberTasks,
        label: 'Tarefas concluídas',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(151, 153, 157, 0.2)',
          'rgba(195, 49, 73, 0.3)',
          'rgba(242, 78, 30, 0.5)',
          'rgba(26, 188, 254, 0.5)',
          'rgba(10, 207, 131, 0.2)',
          'rgba(162, 89, 255, 0.2)',
          'rgba(240, 200, 8, 0.3)',
          'rgba(28, 15, 19, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
      },
    ];

    this.barChartDataMemberZone = [
      {
        indexAxis: 'y',
        data: this.memberZone,
        label: 'Áreas limpas (m²)',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(151, 153, 157, 0.2)',
          'rgba(195, 49, 73, 0.3)',
          'rgba(242, 78, 30, 0.5)',
          'rgba(26, 188, 254, 0.5)',
          'rgba(10, 207, 131, 0.2)',
          'rgba(162, 89, 255, 0.2)',
          'rgba(240, 200, 8, 0.3)',
          'rgba(28, 15, 19, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
      },
    ];

    this.barChartDataMemberTime = [
      {
        indexAxis: 'y',
        data: this.memberTime,
        label: 'Tempo controlando a máquina',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(151, 153, 157, 0.2)',
          'rgba(195, 49, 73, 0.3)',
          'rgba(242, 78, 30, 0.5)',
          'rgba(26, 188, 254, 0.5)',
          'rgba(10, 207, 131, 0.2)',
          'rgba(162, 89, 255, 0.2)',
          'rgba(240, 200, 8, 0.3)',
          'rgba(28, 15, 19, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(151, 153, 157)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
      },
    ];

    this.barChartDataMemberMaps = [
      {
        indexAxis: 'y',
        data: this.memberMaps,
        label: 'Mapas concluídos',
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(195, 49, 73, 0.3)',
          'rgba(242, 78, 30, 0.5)',
          'rgba(26, 188, 254, 0.5)',
          'rgba(10, 207, 131, 0.2)',
          'rgba(162, 89, 255, 0.2)',
          'rgba(240, 200, 8, 0.3)',
          'rgba(28, 15, 19, 0.2)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        borderWidth: 2,
        hoverBackgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
        hoverBorderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(195, 49, 73)',
          'rgb(242, 78, 30)',
          'rgb(26, 188, 254)',
          'rgb(10, 207, 131)',
          'rgb(162, 89, 255)',
          'rgb(240, 200, 8)',
          'rgb(28, 15, 19)',
        ],
      },
    ];
    this.isLoading = false;
  }

  public changeTab(index: MatTabChangeEvent): void {
    this.selectedTab = index.tab.textLabel;
  }

  public changeTabIndex(index: number) {
    this.selectedIndex = index;
  }

  public dataViewChange() {
    this.dataView();
  }

  public interval() {
    this.historicView = '';
    let start = this.range.get('start')?.value;
    let end = this.range.get('end')?.value;
    if (end != null && start != null) {
      this.startDay = new Date(start.format());
      this.endDay = new Date(end.format());
      if (start.format() == end.format()) {
        this.historicView = 'today';
        this.gap = 'hoje';
      } else if (this.startDay.getMonth() != this.endDay.getMonth()) {
        this.gap = 'mensal';
      } else {
        this.gap = 'diario';
      }
      this.endDay.setHours(23, 59, 59, 999);
      this.loadIntervalData();
    }
  }

  public historicViewChange(item: string) {
    if (item == 'all') {
      this.range.reset();
      this.gap = 'mensal';
      this.startDay = new Date();
      this.startDay.setDate(this.today.getDay() - 90);
      this.startDay.setDate(1);
      this.startDay.setHours(0, 0, 0, 0);
      this.loadIntervalData();
    } else {
      this.startDay = new Date();
      this.startDay.setHours(0, 0, 0, 0);
      this.endDay = new Date();
      this.endDay.setUTCHours(23, 59, 59, 999);
      this.gap = 'hoje';
      this.loadIntervalData();
    }
  }

  public memberViewChange() {
    if (this.memberView.length != 0) {
      let dataMembersFilter: MachineExecution[] = [];
      this.memberView.map((view) => {
        this.dataExecution.data.filter((e) => {
          if (typeof view === 'string' && e.operator == view) {
            dataMembersFilter.push(e);
          } else if (typeof view !== 'string') {
            let machine: Machine = view;
            if (e.machine?.name == machine.name) dataMembersFilter.push(e);
          }
        });
      });
      this.dataExecution.data = dataMembersFilter;
    }
  }

  public resetMemberView(): void {
    this.memberView = [];
    this.loadIntervalData();
  }

  public expandTable() {
    this.expandList = !this.expandList;
  }

  public taskOption(): void {
    this.taksOptions.data = this.dataTask.data.filter((execution) => {
      return execution.map.id == this.mapView?.id;
    });
    this.loadIntervalData();
  }

  private mapViewChange(): void {
    if (this.mapView == undefined) return;
    this.dataExecution.data = this.dataExecution.data.filter((execution) => {
      return execution.task.map.id == this.mapView?.id;
    });
  }

  private taskViewChange(): void {
    if (this.taskView == undefined) return;
    this.dataExecution.data = this.dataExecution.data.filter((execution) => {
      return execution.task.id == this.taskView?.id;
    });
  }

  public resetTaskView(): void {
    this.taskView = undefined;
    this.loadIntervalData();
  }

  public resetMapView(): void {
    this.mapView = undefined;
    this.taskView = undefined;
    this.historicViewChange(this.historicView);
  }

  private openDialog(): void {
    this.dialog.open(EmptyDataDialogComponent, {
      width: '400px',
    });
  }


}
