<div id="contacts" class="page-layout simple left-sidebar inner-sidebar inner-scroll">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">

    <!-- APP TITLE -->
    <div fxLayout="row" fxLayoutAlign="start center">

      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">widgets
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Gerenciador de Skills
        </span>
      </div>

    </div>
    <!-- / APP TITLE -->

    <!-- SEARCH -->
    <div class="ml-sm-32" fxLayout="row" fxLayoutAlign="center center" fxFlex>

      <div class="search-wrapper mt-16 mt-sm-0">

        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="search-icon">search</mat-icon>
          <input [formControl]="searchInput" placeholder="Procure por alguma skill">
        </div>

      </div>

    </div>
    <!-- / SEARCH -->

  </div>
  <!-- / HEADER -->

  <!-- SELECTED BAR -->
  <alabia-selected-bar class="accent-600" *ngIf="hasSelectedSkills" [@slideInTop]></alabia-selected-bar>
  <!-- / SELECTED BAR -->

  <!-- CONTENT -->
  <div class="content">
    <!-- CENTER -->
    <div class="center p-24 pb-56" fusePerfectScrollbar>

      <!-- CONTENT -->
      <div class="content">
        <!-- <user-list></user-list> -->
        <alabia-skill-list></alabia-skill-list>
      </div>
      <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

  </div>
  <!-- / CONTENT-->

</div>

<!-- ADD CONTACT BUTTON -->
<button mat-fab class="accent" id="add-contact-button" aria-label="add contact"
  [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}" (click)="newSkill()">
  <mat-icon>library_add</mat-icon>
</button>
<!-- / ADD CONTACT BUTTON -->