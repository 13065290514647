<div class="login-phrase">
  <h1 class="login-phrase__welcome">Seja bem vindo a<br> sua Plataforma de Robôs</h1>
</div>

<div class="login-form">
  <form action="#">
    <img src="/assets/images/logos/logo.png" alt="Logo" class="login-form__logo">
    <h1>Login</h1>

    <input type="email" class="login-form__email" placeholder="Email" #email>
    <input type="password" class="login-form_password" placeholder="Senha" #pass>

    <div class="login-form__buttons">
      <button class="login-form__buttons-sigin" (click)="onSubmit(email.value, pass.value)">Entrar</button>
      <!-- <button class="login-form__buttons-create" routerLink="../signup">Criar uma conta</button> -->
    </div>

    <a routerLink="/privacidade">Política de Privacidade</a>
  </form>
</div>