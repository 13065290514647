<h1 mat-dialog-title>Respostas programadas</h1>

<mat-dialog-content>
  <mat-form-field class="search-form">
    <input matInput name="filter" type="text" [(ngModel)]="filterContent">
  </mat-form-field>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let item">{{ item.title }}</td>
    </ng-container>

    <ng-container matColumnDef="content">
      <th mat-header-cell *matHeaderCellDef>Conteúdo</th>
      <td mat-cell *matCellDef="let item">{{ item.content }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th class="button-column" mat-header-cell *matHeaderCellDef></th>
      <td class="button-column" mat-cell *matCellDef="let item">
        <button mat-button (click)="openEdit(item)">Editar</button>
      </td>
    </ng-container>
    <ng-container matColumnDef="delete">
      <th class="button-column" mat-header-cell *matHeaderCellDef></th>
      <td class="button-column" mat-cell *matCellDef="let item">
        <button mat-button (click)="deleteResponse(item)">Deletar</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
  </table>
  <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions>
  <a mat-icon-button [href]="exportedContent()" download="export.csv" title="Exportar">
    <mat-icon>cloud_download</mat-icon>
  </a>
  <button mat-icon-button (click)="uploadFile.click()" title="Importar">
    <mat-icon>cloud_upload</mat-icon>
  </button>

  <button mat-button (click)="openEdit()">Criar</button>
  <button mat-button [mat-dialog-close]="false">Fechar</button>
</mat-dialog-actions>

<input #uploadFile hidden type="file" accept=".json,.csv" (change)="importContent($event)">