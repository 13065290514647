<div id="contacts" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <div class="header accent p-16" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center" fxFlex>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">supervisor_account
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          Customers
        </span>
      </div>

      <!-- SEARCH -->
      <div class="search">
        <div class="ml-sm-32 search-outter-wrapper">
          <div class="search-wrapper mt-16 mt-sm-0">
            <div class="search">
              <mat-icon class="search-icon">search</mat-icon>
              <input [(ngModel)]="search" placeholder="Procure por um cliente">
            </div>
          </div>
        </div>
      </div>
      <!-- / SEARCH -->

      <div></div>
    </div>
  </div>
  <div class="content">
    <div class="center p-24 pb-56" fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="content">
        <alabia-customer-list *ngIf="!viewCustomer" (navigation)="handleNavigation($event)" [filter]="search">
        </alabia-customer-list>
        <alabia-customer-visualizer *ngIf="viewCustomer" (navigation)="handleNavigation($event)">
        </alabia-customer-visualizer>
      </div>
      <!-- / CONTENT -->
    </div>
  </div>
</div>