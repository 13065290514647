import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { YoutubeChannelService } from 'onevoice';

@Component({
  selector: 'main-youtube-channel',
  templateUrl: './youtube-channel.component.html',
  styleUrls: ['./youtube-channel.component.scss']
})
export class YoutubeChannelComponent implements OnInit {

  public message: string = "Iniciando componente de criação de canal";

  constructor(private router: Router, private route: ActivatedRoute, private service: YoutubeChannelService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let botId = Number(sessionStorage.getItem("botId"));
      this.message = `Criando canal para o robo ${botId}`;

      this.service.finish(botId, params['code']).subscribe(channels => {
        this.message = "Canal criado com sucesso. Redirecionando";
        console.log(channels);
        this.router.navigateByUrl(`/bots/${botId}/channels`);
      });
    })
    console.log(this.route.snapshot.queryParams);
  }
}
