import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-format-controll',
  template: ``,
})
export class EditFormatControllComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}

@Component({
  selector: 'edit-format-list-field',
  template: ``,
})
export class EditFormatListFieldComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}

@Component({
  selector: 'edit-format-field',
  template: ``,
})
export class EditFormatFieldComponent implements OnInit {
  constructor() { }
  ngOnInit(): void { }
}


@Component({
  selector: 'oneui-edit-response',
  templateUrl: './edit-response.component.html',
  styleUrls: ['./edit-response.component.css']
})
export class EditResponseComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
