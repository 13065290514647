import { Mood } from "./mood.model";
import { Voice } from "./voice.model";

export enum ModelStatus {
    REQUESTED = "REQUESTED", TRAINING = "TRAINING", FINISHED = "FINISHED", ERROR = "ERROR",
}

export interface VoiceModel {
    id: number;
    voice: Voice;
    mood: Mood;
    status: ModelStatus;
    active: boolean;
    created: Date;
}
