
<div class="alabia-generic-wrapper">
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="name">
            <th class="schedule-title" mat-header-cell *matHeaderCellDef>Nome</th>
            <td class="schedule-title" mat-cell *matCellDef="let item">
                {{ item.user?.details.first_name || 'Não informado' }}

            </td>
        </ng-container>
        <ng-container matColumnDef="task">
            <th class="schedule-task" mat-header-cell *matHeaderCellDef>Tarefas </th>
            <td class="schedule-task" mat-cell *matCellDef="let item">
                <div *ngFor="let task of item.timedTaskList">
                    {{ task.machineTask.name  }}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="repete">
            <th class="schedule-repete" mat-header-cell *matHeaderCellDef>Repetição</th>
            <td class="schedule-repete" mat-cell *matCellDef="let item">
              <div *ngFor="let task of item.timedTaskList">
                {{  repet(task.repeat) }}
            </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="start">
            <th class="schedule-start" mat-header-cell *matHeaderCellDef>Início</th>
            <td class="schedule-start" mat-cell *matCellDef="let item">
              <div *ngFor="let task of item.timedTaskList">
                {{  task.startTime}}
            </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="location">
            <th class="schedule-end" mat-header-cell *matHeaderCellDef>Local</th>
            <td class="schedule-end" mat-cell *matCellDef="let item">
              <div *ngFor="let task of item.timedTaskList">
                {{  task.machineTask.map.title }}
            </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th class="schedule-actions" mat-header-cell *matHeaderCellDef>Ações</th>
            <td class="schedule-actions" mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="editItem(item)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>

        </ng-container>
        <ng-container matColumnDef="delete">
            <th class="schedule-actions" mat-header-cell *matHeaderCellDef>Excluir</th>
            <td class="schedule-actions" mat-cell *matCellDef="let item">
                <button mat-icon-button (click)="delete(item)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>

        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let item; columns: columns"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    <button class="alabia-fab " mat-fab (click)="editItem()">
        <mat-icon>add</mat-icon>
    </button>
</div>
