import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  MachineMapService,
  MachineTask,
  MachineTaskService,
  MapUnitService,
  MapUnit,
  Machine,
  MachineService,
  MachineExecution,
  MachineExecutionService,
  RedirectService,
  Redirect,
} from 'onevoice';
import { EditMaintenanceComponent } from './edit-maintenance/edit-maintenance.component';




export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  public displayedColumnsMachinie: string[] = ['id', 'nickname', 'model', 'location']
  public displayedColumnsTasks: string[] = ['id', 'name', 'description',"actions"]


 loadData: MachineTask[] = [
  {
    description: "Tarefa de execução manual",
    id: 7369,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Tarefa Manual 5",
    type: "MANUAL",
    uniqueId: "null"
  },
  {
    id: 7374,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Manual",
    description: "manual",
    type: "MANUAL",
    uniqueId: "null"
  },
  {
    description: "Manual task",
    id: 7375,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Taskmanual",
    type: "MANUAL",
    uniqueId: "null"
  },
  {
    description: "Criação de tarefa manual.",
    id: 7368,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Tarefa manual 4",
    type: "MANUAL",
    uniqueId: "null"
  },
  {
    description: "TEste",
    id: 7364,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Teste",
    type: "MANUAL",
    uniqueId: "null"
  },
  {
    description: "Teste de criação de tarefa manual.",
    id: 7366,
    map: 	{
      "id": 85,
      "title": "Hospital Asa Sul",
      "path": "",
      "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43",
      "unit": {
        "id": 3,
        "name": "Hospital Asa Sul",
        "description": "Hospital localizado na Asa Sul.",
        "uniqueId": "7bb2e485-2072-4ee3-ab72-2bf177a0dd43"
      },
      "type": "MANUAL"
    },
    name: "Tarefa manual 2",
    type: "MANUAL",
    uniqueId: "null"
  }
 ]

  public dataSourceMachines: MatTableDataSource<Machine> =
    new MatTableDataSource<Machine>();
  public dataSourceMachineFilter: MatTableDataSource<Machine> =
    new MatTableDataSource<Machine>();
  public dataSourceMachineTasks: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();
  public dataSourceMachineTasksFilter: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();
  public dataSourceTaskList: MatTableDataSource<MachineTask> =
    new MatTableDataSource<MachineTask>();
  public machineSelected?: Machine;
  public taskSelected?: MachineTask;
  public taskAllView: boolean = true;

  public redirectList : Redirect[] = [];

  public redirectSelected? : Redirect;


  public lastExecution: MachineExecution | undefined;

  public machineTasks : MachineTask[] = [];

  constructor(
    private machineService: MachineService,
    private machineExecutionService: MachineExecutionService,
    private machineTask : MachineTaskService,
    public redirectService : RedirectService,
    private dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.loadMachines()
    this.loadTasks()
    this.loadRedirect()
  }

  loadRedirect() {
    this.redirectService.list().subscribe(redirectList => {

      this.redirectList = redirectList.reverse();
      console.log(redirectList)
    });
  }

  private loadTasks(){
    let allTasks : MachineTask[] = []
    this.machineTask.list().subscribe((tasksList) => {
      // tasksList.forEach(task=>{
      //   if(task.type == "MANUAL"){
      //     allTasks.push(task)
      //   }
      // })

    });
    this.dataSourceMachineTasks.data = this.loadData;
    console.log("Machines list")
    console.log(this.dataSourceMachineTasks.data)
    this.dataSourceMachineTasksFilter.data = this.loadData.filter((e) => {
      return e.id == this.machineSelected?.id;
    });
  }
  private loadMachines(): void {
    this.machineService.list().subscribe((machineList) => {
      this.dataSourceMachines.data = machineList;
      this.machineSelected = this.dataSourceMachines.data[0];
      this.selectTaskMachine(this.dataSourceMachines.data[0])
      console.log("Machines list")
      console.log(this.dataSourceMachines.data)
      this.dataSourceMachineFilter.data = machineList.filter((e) => {
        return e.id == this.machineSelected?.id;
      });
    });
  }
  public selectedTaskRow(task: MachineTask): void {
    this.taskSelected = task;
    this.taskAllView = false;
    console.log('Task');
    console.log(this.taskSelected);
    this.selectedRedirect(task)
  }
  public selectedRedirect(task: MachineTask): void {
    this.redirectList.forEach(redirect=>{
      if(redirect.destination == task.id){
        this.redirectSelected = redirect;
      }
    })
    console.log('Redirect');
    console.log(this.redirectSelected );
    // this.selectTaskMachine(machine)
  }
  public selectedMachineRow(machine: Machine): void {
    this.machineSelected = machine;
    this.taskAllView = false;
    console.log('Machine');
    console.log(this.machineSelected);
    this.selectTaskMachine(machine)
  }
  public selectTaskRow(task: MachineTask): void {
    this.taskSelected = task;
    this.taskAllView = false;
  }
  public selectTaskMachine(machine: Machine): void {
    this.machineExecutionService.listByMachine(machine.id).subscribe((execution) => {
      this.lastExecution = execution;
      console.log(execution)
    })
  }
  public editTask(data: Partial<MachineTask> = {}): void {
    let reference = this.dialog.open<EditMaintenanceComponent, Partial<MachineTask>, MachineTask>(EditMaintenanceComponent, {
      width: "500px",
      maxWidth: "90%",
      data: data,
    });

    reference.afterClosed().subscribe(task => {
      if (!task) {
        return;
      }

      if (task.id) {
        this.machineTask.update(task).subscribe(task => this.addTaskToList(task));
      } else {
        this.machineTask.create(task).subscribe(task => this.addTaskToList(task));
      }
    });
  }
    public addTaskToList(task: MachineTask): void {
    if (this.dataSourceMachineTasks) {
      let updated = this.dataSourceMachineTasks.data.filter(value => value.id != task.id);
      updated.push(task);
      updated.sort((a, b) => b.id - a.id);
      this.dataSourceMachineTasks.data = updated;
    }
  }
  public convertDate(timestamp: number) {

    const data = new Date(timestamp);

    const horas = ('0' + data.getHours()).slice(-2);
    const minutos = ('0' + data.getMinutes()).slice(-2);
    const dia = ('0' + data.getDate()).slice(-2);
    const mes = ('0' + (data.getMonth() + 1)).slice(-2); // Os meses começam do zero
    const ano = data.getFullYear().toString().slice(-2);

    return `${horas}:${minutos} ${dia}/${mes}/${ano}`;
  }
}
