<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>{{ actionName }} Resposta</h1>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput type="text" formControlName="title">
      <mat-hint>Nome descritivo da resposta</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Conteúdo</mat-label>
      <textarea matInput formControlName="content" rows="8"></textarea>
      <mat-hint>Conteúdo que será enviado ao usuário</mat-hint>
    </mat-form-field>

    <mat-slide-toggle (change)="globalChange($event)" [checked]="globalResponse">Global</mat-slide-toggle>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type="submit">{{ actionName }}</button>
    <button mat-button [mat-dialog-close]="undefined">Cancelar</button>
  </mat-dialog-actions>
</form>