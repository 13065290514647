import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface FacebookHook {
    id?: number;
    idBot?: number;
    idChannel?: number;

    name?: string;
    description?: string;

    hasAudio?: boolean;
    hasImage?: boolean;
    hasText?: boolean;
    hasVideo?: boolean;

    accessToken?: string;
    verifyToken?: string;
    serviceIdentifier?: string;

    urlAlabiaService?: string;
    urlLusCore?: string;
    urlMediaService?: string;
    urlStomp?: string;
}

@Injectable({
    providedIn: 'root'
})
export class FacebookHookService {
    url: string = `${environment.fbService}/facebook`;

    constructor(private _httpClient: HttpClient) {}

    list(): Observable<FacebookHook[]> {
        return this._httpClient.get<FacebookHook[]>(this.url);
    }

    get(id: number): Observable<FacebookHook> {
        return this._httpClient.get<FacebookHook>(`${this.url}/${id}`);
    }

    create(data: FacebookHook): Observable<FacebookHook> {
        return this._httpClient.post<FacebookHook>(this.url, data);
    }

    update(data: FacebookHook): Observable<FacebookHook> {
        return this._httpClient.post<FacebookHook>(
            `${this.url}/${data.id}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        );
    }

    delete(data: FacebookHook): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
