import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapUnitService } from 'onevoice';
import { MapUnit } from 'projects/onevoice/src/lib/hw/map-unit.service';
import * as uuid from 'uuid';

@Component({
  selector: 'main-create-unit',
  templateUrl: './create-unit.component.html',
  styleUrls: ['./create-unit.component.scss']
})
export class CreateUnitComponent implements OnInit {

  public form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MapUnit,
    public mapUnitService : MapUnitService,
    private reference: MatDialogRef<CreateUnitComponent, MapUnit>,
  ) {
    this.form = new FormGroup({
      "name": new FormControl(data.name || "", [Validators.required]),
      "description": new FormControl(data.description || "", [Validators.required]),
      "uniqueId": new FormControl(data.uniqueId || uuid.v4(), [Validators.required]),
    }, []);
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  get title(): string {
    return (this.data.id !== undefined) ? "Editar Unidade" : "Criar Unidade";
  }

  public submitUnitMap(): void {

    this.mapUnitService.save(this.data).subscribe(data=>{
      console.log(data)
    })

    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }
}
