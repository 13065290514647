import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ownable, OwnableService } from '../auth/ownable.service';

export enum RedirectType {
  UNDEFINED = "UNDEFINED",
  MACHINE_TASK = "MACHINE_TASK",
  MANUAL_TASK = "MANUAL_TASK",
  MAINTENANCE_TASK = "MAINTENANCE_TASK",
}

export interface Redirect extends Ownable {
  destination: number;
  type: RedirectType;

}

@Injectable({
  providedIn: 'root'
})
export class RedirectService extends OwnableService<Redirect> {
  protected path: string = `${this.config.endpoint}/qrcode`;

  public getImageUrl(id: number): string {
    return `${this.path}/${id}/image`;
  }

  public downloadBulk(ids: number[]): Observable<Blob> {
    let data = new FormData();
    ids.forEach(id => data.append("ids", `${id}`));

    return this.http.post(`${this.path}/bulk/image`, data, {
      responseType: 'blob',
    });
  }
}
