<h1 mat-dialog-title>Lista de Etiquetas</h1>

<mat-dialog-content>
    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>
                Título
            </th>
            <td mat-cell *matCellDef="let tag">
                {{ tag.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>
                Descrição
            </th>
            <td mat-cell *matCellDef="let tag">
                {{ tag.description }}
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let tag">
                <button mat-icon-button (click)="openTagDialog(tag)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let tag">
                <button mat-icon-button (click)="removeTag(tag)">
                    <mat-icon>close</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fields"></tr>
        <tr mat-row *matRowDef="let tag; columns: fields;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="openTagDialog()">Criar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>