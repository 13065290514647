<button *ngIf="chat && chat.assignment" mat-button class="contact" (click)="output()" [ngClass]="{
  'active' : selectedChat
}">

  <div fxLayout="row" fxLayoutAlign="start center">

    <div class="avatar-wrapper" fxFlex="0 1 auto" fxLayoutAlign="center center">
      <img class="avatar" [src]="contactAvatar(chat)" [alt]="chat.customer.firstName">
      <mat-icon class="s-16 status"></mat-icon>
    </div>

    <div fxLayout="row" fxFlex>

      <div class="pr-4" fxFlex fxLayout="column" fxLayoutAlign="center start">
        <div class="contact-name">
          <small class="chat-target">{{ chat.assignment.bot.name }}</small>
          {{ chat.customer.firstName }}
        </div>
        <div class="contact-last-message">{{ lastMessage(chat).content | ellipsis:50 }}</div>
        <small class="agent-data" *ngIf="chat.assignment.agent">{{
          userFullname(chat.assignment.agent) }}</small>
      </div>

      <div fxLayout="column" fxLayoutAlign="start end">
        <div class="contact-last-message-time">
          {{ lastMessage(chat).date | date }}
        </div>
        <div *ngIf="chat.unread" class="mt-4 unread-message-count accent">{{ chat.unread }}</div>
      </div>
    </div>
  </div>
</button>