import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Bot } from './bot.model';

export interface Intent {
  id: number;
  bot: Bot;
  next?: number;

  name: string;
  subject: string;
  description: string;

  maxFallbacks: number;
  lifespan: number;
}
@Injectable({
  providedIn: 'root'
})
export class IntentService {
  url = `${this.config.endpoint}/api/intents`;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) protected config: ApiConfiguration
  ) { }

  list(botid?: number): Observable<Intent[]> {
    let params: HttpParams = new HttpParams();
    if (botid) {
      params = params.set('bot', `${botid}`);
    }
    return this.http.get<Intent[]>(this.url, {
      params: params
    });
  }

  get(id: number): Observable<Intent> {
    return this.http.get<Intent>(`${this.url}/${id}`);
  }

  create(data: Partial<Intent>): Observable<Intent> {
    return this.http.post<Intent>(this.url, data);
  }

  update(data: Partial<Intent>): Observable<Intent> {
    return this.http.post<Intent>(`${this.url}/${data.id}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  delete(data: Intent): Observable<any> {
    return this.http.delete(`${this.url}/${data.id}`);
  }

  setNext(data: Intent, next?: Intent): Observable<Boolean> {
    let body = new FormData();
    if (next != null) {
      body.set("next", `${next.id}`);
    }
    return this.http.post<Boolean>(`${this.url}/${data.id}/next`, body);
  }
}
