import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { SpeechProject } from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends OwnableService<SpeechProject> {
  protected path: string = `${this.config.mediaservice}/project`;
}
