<div id="products" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    feedback
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{ !selected ? 'Intenções' : selected.name | titlecase }}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Busque por uma intenção">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button (click)="createIntent()" class="add-product-button fuse-white">
                <span>NOVA INTENÇÃO</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <div class="content">

            <div class="bot-details">
                <alabia-sidenav [bot]="bot" [clearIntent]="selectedClear" [amountIntents]="intents?.length">
                </alabia-sidenav>
            </div>

            <!-- CONTENT CARD -->

            <div class="content-card" [ngClass]="{'content-card--intent-view': selected }">
                <alabia-intent-view *ngIf="selected" [intent]="selected" (onDelete)="intentDeleted($event)">
                </alabia-intent-view>

                <mat-table class="products-table" #table [dataSource]="dataSource" matSort [hidden]="selected"
                    [@animateStagger]="{value:'50'}" fusePerfectScrollbar>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                        <mat-cell *matCellDef="let product">
                            <p class="text-truncate">{{ product.name }}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Quantity Column -->
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Frases</mat-header-cell>
                        <mat-cell *matCellDef="let product" fxHide fxShow.gt-sm>
                            <span>{{ COUNTER[product.id] || 0 }}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Active Column -->
                    <ng-container matColumnDef="active">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Ativa</mat-header-cell>
                        <mat-cell *matCellDef="let product" fxHide fxShow.gt-xs>
                            <mat-icon *ngIf="true" class="active-icon green-600 s-16">check</mat-icon>
                            <!-- <mat-icon *ngIf="!product.active" class="active-icon red-500 s-16">close</mat-icon> -->
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let intent; columns: displayedColumns;" class="product" matRipple
                        [routerLink]="['/bots', intent.bot.id, 'intents', intent.id]">
                    </mat-row>

                </mat-table>

                <mat-paginator #paginator [length]="dataSource?.filteredData?.length" [pageIndex]="0" [pageSize]="10"
                    [hidden]="selected" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>

            </div>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>