import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { UserCreateRequest, User } from './user.model';
import { ServiceTokenService } from './service-token.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements HttpInterceptor {
  public current: BehaviorSubject<User | null>;

  public AUTH_TOKEN: string = 'X-Auth-Token';

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: ApiConfiguration,
    private storage: TokenService,
    private sts: ServiceTokenService,
  ) {
    this.current = new BehaviorSubject<User | null>((storage.hasUser()) ? storage.user : null);
  }

  public login(email: string, password: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (this.storage.user) {
        this.current.next(this.storage.user);
        resolve(this.storage.user);
        return;
      }

      let params = new FormData();
      params.set("username", email);
      params.set("password", password);

      this.storage.token = null;
      this.storage.user = null;

      this.http.post<User>(this.config.login(), params, {
        observe: "response",
        withCredentials: true,
        responseType: "json",
      }).subscribe(response => {
        this.storage.token = response.headers.get(this.AUTH_TOKEN);
        this.storage.user = response.body;
        this.current.next(this.storage.user);

        if (!this.storage.user) {
          reject("user not received");
        } else {
          resolve(this.storage.user);
        }
      }, err => reject(err));
    });
  }

  public refresh(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.http.post<User>(`${this.config.endpoint}/auth/me`, {}).subscribe(user => {
        this.storage.user = user;
        this.current.next(this.storage.user);
        resolve(user);
      });
    });
  }

  public logout() {
    this.storage.clear();
    this.current.next(null);
    this.router.navigate(['/login']);
  }

  public systemToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.storage.service) {
        resolve(true);
      }
      this.sts.systemToken().then(token => {
        this.storage.service = token;
        resolve(true);
      }).catch(err => reject(err));
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storage.token && request.url.includes(this.config.endpoint)) {
      console.log("Add auth token");
      request = request.clone({
        setHeaders: {
          [this.AUTH_TOKEN]: this.storage.token,
        },
        withCredentials: true,
      });
    } else if (this.storage.service) {
      console.log("Add auth param");
      request = request.clone({
        setParams: {
          [ServiceTokenService.PARAMETER]: this.storage.service,
        }
      })
    }
    return next.handle(request).pipe(tap(_ => { }, error => {
      if (error instanceof HttpErrorResponse && error.url && error.url.includes('login')) {
        this.logout();
      }
    }));
  }

  public authMediaUrl(url: string): string {
    return `${url}?${ServiceTokenService.PARAMETER}=${this.storage.service}`;
  }

  public changePassword(password: string, newPassword: string) {
    let data: FormData = new FormData();
    data.set("oldPassword", password);
    data.set("newPassword", newPassword);
    return this.http.post<Boolean>(`${this.config.endpoint}/auth/update`, data);
  }
  public create(data: UserCreateRequest): Observable<User> {
    return this.http.post<User>(`${this.config.endpoint}/auth/signup/simple`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}
