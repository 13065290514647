export interface AccountForm {
    confirmPassword?: string;
    email?: string;
    firstName?: string;
    organization?: string;
    password?: string;
    phone?: number;
    lastName?: string;
    username?: string;
    website?: string;
    industry?: string;
    role?: string;
    company_size?: string;
}
