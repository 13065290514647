import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwitchToggle } from 'app/models/switch-toggle';

@Component({
  selector: 'app-toggle-btn',
  templateUrl: './toggle-btn.component.html',
  styleUrls: ['./toggle-btn.component.scss']
})
export class ToggleBtnComponent {

  @Input() btns: SwitchToggle[] = [];
  @Input() size: number = 300;
  @Input() fontSize: number = 12;
  @Input() backgroundColor: string = "purple";
  @Output() changeBtn = new EventEmitter();

  constructor() { }

  clickLabel(btn: SwitchToggle): void {
    this.btns.forEach(b => b.checked = false);
    btn.checked = true;

    if (!btn.disabled) {
      this.changeBtn.emit(btn.value);
    }
  }

}
