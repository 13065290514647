import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[mainAlterColor]'
})
export class AlterColorDirective {

 
  constructor(el:ElementRef) {
    el.nativeElement.style.backgroundColor = this.alterColor();
    
   }
   
   alterColor(): string {
    var colors = [
      '#feff65', '#00ffff', '#7FFF65',
      '#D777C7', '#DE8793', '#7C9BEF'
    ];

    // selecting random color
   return  colors[Math.floor(
      Math.random() * colors.length)];

  }

}
