<div class="page-layout carded fullwidth inner-scroll">
  <div class="organization-form">
    <h1 class="mb-32">Crie uma Organização</h1>

    <mat-form-field appearance="outline">
      <mat-label>Nome</mat-label>
      <input matInput type="text" [(ngModel)]="organization.name">
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="createOrganization()">
      Enviar
    </button>
  </div>
</div>