import { User } from "../auth/user.model";
import { CustomerChannel } from "../crm/customer-channel.model";

export enum Sender {
    USER = "USER",
    AGENT = "AGENT",
    ROBOT = "ROBOT",
}

export enum ResponseType {
    TEXT = "TEXT",
    JSON = "JSON",
    MEDIA = "MEDIA",
}

export enum MediaType {
    UNKNOWN = "UNKNOWN",
    AUDIO = "AUDIO",
    IMAGE = "IMAGE",
    DOCUMENT = "DOCUMENT",
    VIDEO = "VIDEO",
}


export interface ConversationMessage {
    idConversationMessage: number;

    messageTime: number;
    sender: Sender;
    channel: CustomerChannel;

    content: string;
    contentType: ResponseType;
    mediaType: MediaType;

    user?: User;
}
