import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Parameter, ParameterScope, ParameterService } from 'app/alabia/api/parameter.service';
import { EntitiesService, Entity } from 'app/alabia/api/entities.service';
import { LusEntityService, LusEntity } from 'app/alabia/api/lus-entity.service';

@Component({
    selector: 'alabia-parameter-view',
    templateUrl: './parameter-view.component.html',
    styleUrls: ['./parameter-view.component.scss']
})
export class ParameterViewComponent {
    public ParameterScope = ParameterScope;

    requiredValue = {
        true: 'Obrigatório',
        false: 'Opcional'
    };
    lusEntities: LusEntity[] = [];
    selected: LusEntity[] = [];

    entities: Entity[] = [];
    removedEntities: Entity[] = [];

    constructor(
        private reference: MatDialogRef<ParameterViewComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Parameter,

        public _entitiesService: EntitiesService,
        public _lusEntitiesServices: LusEntityService
    ) {
        this._lusEntitiesServices.list().subscribe(lusEntities => {
            this.lusEntities = lusEntities;
            if (data.id) {
                this._entitiesService.list(data.id).subscribe(entities => {
                    this.entities = entities;
                    let selected: LusEntity[] = [];
                    this.entities.forEach(entity => {
                        if (!this.lusEntities) { return; }
                        let le = this.lusEntities.find(
                            (lusEntity: LusEntity) => entity.lusEntity && lusEntity.idLusEntity == entity.lusEntity.idLusEntity,
                        )
                        if (le) {
                            selected = selected.concat([le]);
                        }
                    });
                    this.selected = selected;
                });
            }
        });
    }

    compareRequired = (a: any, b: any): boolean => {
        if (typeof a == 'string') {
            a = a == 'true';
        }
        if (typeof b == 'string') {
            b = b == 'true';
        }
        return a == b;
    };

    compareEntity = (a: any, b: any): boolean => {
        return a.idLusEntity == b.idLusEntity;
    };

    updateEntities() {
        this.selected.forEach(lusEntity => {
            let list = this.entities.find(
                entity => entity.lusEntity && entity.lusEntity.idLusEntity == lusEntity.idLusEntity
            );
            if (!list) {
                this.entities.push({
                    intentActionParameter: this.data,
                    invalidValues: '',
                    validValues: '',
                    lusEntity: lusEntity
                });
            }
        });
        this.entities.forEach(entity => {
            let list = this.selected.find(
                lusEntity =>
                    entity.lusEntity && entity.lusEntity.idLusEntity == lusEntity.idLusEntity
            );
            if (!list) {
                this.removedEntities.push(entity);
            }
        });
    }

    saveEntities(parameter: Parameter) {
        this.removedEntities.forEach(entity => {
            this._entitiesService.delete(entity).subscribe(_ => { });
        });
        this.entities.forEach(entity => {
            entity.intentActionParameter = parameter;
            if (!entity.id) {
                this._entitiesService.create(entity).subscribe(_ => { });
            }
        });
    }

    listScopes(): ParameterScope[] {
        return Object.values(ParameterScope);
    }
}
