import { InMemoryDbService } from 'angular-in-memory-web-api';

import { UsersFakeDb } from './users';
import { ECommerceFakeDb } from './e-commerce';

export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {
            // Contacts
            'contacts-contacts': UsersFakeDb.users,
            'contacts-user': UsersFakeDb.admin,

            // E-Commerce
            'e-commerce-products': ECommerceFakeDb.products,
            'e-commerce-orders': ECommerceFakeDb.orders,
        };
    }
}
