import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { Bot, BotService } from 'onevoice';

@Component({
  selector: 'alabia-widget-creator',
  templateUrl: './widget-creator.component.html',
  styleUrls: ['./widget-creator.component.scss'],
  animations: fuseAnimations,

})
export class WidgetCreatorComponent implements OnInit {

  public bots: Bot[] = [];
  public selected_bot: number = 0;
  public primary_color: string = '#9a75fc';
  public secondary_color: string = '#ffffff';

  constructor(
    public snackBar: MatSnackBar,
    private _botService: BotService,
  ) { }

  ngOnInit() {
    this._botService.list().subscribe(bots => {
      this.bots = bots;
    });

  }

  public onColorChange(color: any, event: string) {
    switch (color) {
      case 'primary_color':
        this.primary_color = event;
        break;
      case 'secondary_color':
        this.secondary_color = event;
        break;
      default: null;
    }
  }

  public onBotChange(event: number) {
    this.selected_bot = event;
  }

  public copyToClipboard(snippet: any) {
    snippet.select();
    document.execCommand('copy');
    snippet.setSelectionRange(0, 0);
    this.snackBar.open(
      'Código copiado para área de transferência', '', {
      duration: 1000
    });
  }
}
