<h1 mat-dialog-title>Parametro</h1>

<mat-dialog-content>
    <mat-form-field>
        <input matInput type="text" [(ngModel)]="data.name" placeholder="Parametro">
    </mat-form-field>
    <mat-form-field>
        <input matInput type="number" [(ngModel)]="data.order" min="0" placeholder="Ordem de pergunta">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Obrigatório</mat-label>
        <mat-select matNativeControl required [(ngModel)]="data.required" [compareWith]="compareRequired">
            <mat-option *ngFor="let required of requiredValue | keyvalue" [value]="required.key">
                {{ required.value }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Tipo do Parâmetro</mat-label>
        <mat-select [(ngModel)]="selected" [compareWith]="compareEntity" multiple (selectionChange)="updateEntities()">
            <mat-option *ngFor="let entity of lusEntities" [value]="entity">
                {{ entity.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="text" [(ngModel)]="data.validValues" placeholder="Valores válidos">
    </mat-form-field>
    <mat-form-field>
        <input matInput type="url" [(ngModel)]="data.validationService" placeholder="Serviço de validação">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Escopo</mat-label>
        <mat-select required [(ngModel)]="data.scope">
            <mat-option *ngFor="let scope of listScopes()" [value]="scope">{{ scope }}</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data">Adicionar</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>