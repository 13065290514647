import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleUser } from 'onevoice';

@Component({
  selector: 'alabia-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  isCreate: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SimpleUser) {
    this.isCreate = (data.cpf.length > 0);
  }

  ngOnInit() {
  }
}
