import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { MachineMap } from './machine-map.service';
import { MachineModel } from './machine-model.service';
import { MapUnit } from './map-unit.service';


export interface Machine extends Ownable {
  model: MachineModel;
  name: string;
  nickName: string;
  uniqueId: string;
  location : MapUnit;
  maps: MachineMap[];
}

@Injectable({
  providedIn: 'root'
})
export class MachineService extends OwnableService<Machine> {
  protected path: string = `${this.config.endpoint}/hw/machines`;
}
