<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')">
                <mat-icon class="">menu</mat-icon>
            </button>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo">
                    <img class="logo-icon" src="assets/images/logos/logo.png">
                </div>
                <h1>Alabia | One Voice</h1>
            </div>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button *ngIf="authService.current.value != null" mat-button [matMenuTriggerFor]="userMenu"
                class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" [src]="users.avatar(authService.current.value.id)"
                        onError="this.src = 'assets/images/avatars/profile.jpg'">
                    <div fxLayout="column">
                        <span *ngIf="authService.current.value.details" class="username mr-12" fxHide fxShow.gt-sm>
                            {{ authService.current.value.details.first_name }}
                        </span>
                        <span *ngIf="authService.current.value.organization !== null" class="organization mr-12" fxHide
                            fxShow.gt-sm>
                            {{ getOrganization() }}
                        </span>
                    </div>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="sendAvatar()">
                    <mat-icon>account_circle</mat-icon>
                    <span>Avatar</span>
                </button>

                <button mat-menu-item (click)="openPasswordDialog()">
                    <mat-icon>security</mat-icon>
                    <span>Mudar Senha</span>
                </button>

                <button mat-menu-item routerLink="/agent">
                    <mat-icon>settings</mat-icon>
                    <span>KPI</span>
                </button>


                <button mat-menu-item class="" (click)="authService.logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>
        </div>
    </div>
</mat-toolbar>