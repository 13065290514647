import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { BackendApiRequestService } from 'app/alabia/api/backend-api-request.service';
import { CustomerFieldDialogComponent } from 'app/alabia/shared/customer-field-dialog/customer-field-dialog.component';
import { AgentService, Bot, Customer, CustomerService, InformationType } from 'onevoice';
import { CustomerInformation } from 'projects/onevoice/src/public-api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'alabia-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {
  dialogRef: any;

  newInfoContent = '';

  @Input() customerData: Customer = {
    id: 0,
    firstName: "",
    lastName: "",
  };
  customerInformation: any = [];

  informationTypes: InformationType[] = [];

  newInfo: any = [];
  isNewInfoEnabled: boolean = false;

  public customerAddInformationForm?: FormGroup;

  private _unsubscribeAll: Subject<any>;
  public bots: Bot[] = [];

  constructor(
    private customerService: CustomerService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _backendApiRequestService: BackendApiRequestService,
    public agent: AgentService,
  ) {
    this._unsubscribeAll = new Subject();
    this._backendApiRequestService.listCustomerInformation(Number(this._router.url.split('?')[0].split('/')[2])).then(response => {
      this.customerInformation = response;

      this._backendApiRequestService.onCustomerInformationChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(customerInformation => {
          this.customerInformation = customerInformation;

          this._backendApiRequestService.onInformationTypesChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(informationTypes => {
              this.informationTypes = informationTypes.filter((t: any) => {
                return !this.customerInformation.map((i: any) => i.information.id).includes(t.id)
              });
            });
        });
    });
  }

  ngOnInit() {
    this.customerAddInformationForm = this.addInformationForm();

    this.agent.retrieveHistory(this.customerData).subscribe(history => {
      this.bots = history.map(value => value.bot).filter((bot, index, array) => array.findIndex(value => value.id == bot.id) == index);
      console.log(this.bots);
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addFieldDialog(): void {
    this.dialogRef = this._matDialog.open(CustomerFieldDialogComponent, {
      panelClass: 'customer-dialog',
      data: {
        data: this.customerData,
      },
    });

    this.dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (!response) {
          return;
        }
      });
  }

  addInformationForm(): FormGroup {
    return new FormGroup({
      content: new FormControl(''),
    });
  }

  typesOnChange(id: number | undefined): void {
    this.isNewInfoEnabled = id ? true : false;
    this.newInfo = id ? this.informationTypes[this.informationTypes.findIndex(t => t.id === id)] : false;
  }

  onSubmit(submitType: string, e: any): void {
    switch (submitType) {
      case 'information':
        const data: CustomerInformation = {
          information: {
            id: this.newInfo.id,
            description: "",
            name: ""
          },
          content: e.value.content,
        }

        this._backendApiRequestService.relateUserToInfoType(this.customerData.id, data);
        this.customerAddInformationForm = this.addInformationForm();
        break;
    }
  }

  deleteInformation(infoID: number): void {
    let reference = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    reference.componentInstance.confirmMessage = 'Tem certeza que deseja excluir a informação?';

    reference.afterClosed()
      .subscribe(result => {
        if (result) {
          this._backendApiRequestService.deleteCustomerInformation(this.customerData.id, infoID)
        }
      });
  }

  updateCustomer() {
    this.customerService.update(this.customerData).subscribe(value => {
      this.customerData = value;
    })
  }
}
