import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Floor, MachineMap, MachineMapService } from 'onevoice';

@Component({
  selector: 'main-floor-edit',
  templateUrl: './floor-edit.component.html',
  styleUrls: ['./floor-edit.component.scss']
})
export class FloorEditComponent {
  public form: FormGroup;
  public mapList?: MachineMap[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<Floor>,
    public reference: MatDialogRef<FloorEditComponent>,

    private maps: MachineMapService,
  ) {
    this.maps.list().subscribe(mapList => {
      this.mapList = mapList;
    })
    this.form = new FormGroup({
      "name": new FormControl(data.name || "", [Validators.required]),
      "maps": new FormControl(data.maps || [], [Validators.required]),
    }, []);
  }

  public ngSubmit() {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  get isEdit() {
    return this.data.id !== undefined;
  }

  public compareWith(o1: Floor, o2: Floor): boolean {
    return o1.id == o2.id;
  }
}
