import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { QrCodeModule } from 'ng-qrcode';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';
import { RedirectComponent } from './redirect/redirect.component';


const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: ListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ":id",
    pathMatch: 'full',
    component: RedirectComponent
  }
];


@NgModule({
  declarations: [
    RedirectComponent,
    ListComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatPaginatorModule,
    MatCheckboxModule,
    QrCodeModule,

    ReactiveFormsModule,
  ]
})
export class QrcodeModule { }
