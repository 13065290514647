import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Context } from 'app/alabia/api/context.service';

@Component({
    selector: 'alabia-context-dialog',
    templateUrl: './context-dialog.component.html',
    styleUrls: ['./context-dialog.component.scss']
})
export class ContextDialogComponent {
    data: Context = {};
    constructor(private reference: MatDialogRef<ContextDialogComponent>) { }
}
