import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AgentService, AssignmentsService, AuthenticationService, ChangeType, Chat, Customer } from 'onevoice';
import { BehaviorSubject, Subject } from 'rxjs';



@Injectable()
export class ChatService implements OnDestroy, Resolve<any> {
  onChatSelected: BehaviorSubject<Chat | null> = new BehaviorSubject<Chat | null>(null);
  onContactSelected: BehaviorSubject<Customer | null> = new BehaviorSubject<Customer | null>(null);
  onLeftSidenavViewChanged: Subject<string> = new Subject();
  onRightSidenavViewChanged: Subject<string> = new Subject();

  constructor(
    public service: AgentService,
    public assignments: AssignmentsService,
    public auth: AuthenticationService,
    public dialog: MatDialog,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  }

  ngOnDestroy(): void {
    console.debug("ChatService: ngOnDestroy");
  }

  selectChat(chat: Chat) {
    if (chat.assignment.status == ChangeType.OPEN || chat.assignment.agent == undefined) {
      this.assignments.updateAssignment(chat.assignment, ChangeType.WIP);
      this.onChatSelected.next(chat);
    } else if (chat.assignment.agent && chat.assignment.agent.id != this.auth.current.value?.id) {
      let reference = this.dialog.open(FuseConfirmDialogComponent, {
        width: "95%",
        maxWidth: "500px"
      });
      reference.componentInstance.confirmMessage = "Tem certeza que deseja tomar este atendimento para si?";

      reference.afterClosed().subscribe(ok => {
        if (ok) {
          this.assignments.updateAssignment(chat.assignment, ChangeType.WIP);
          this.onChatSelected.next(chat);
        }
      });
    } else if (chat.assignment.agent && chat.assignment.agent.id == this.auth.current.value?.id) {
      this.onChatSelected.next(chat);
    } else {
      console.debug("Not getting chat...");
    }
  }

  selectContact(contact: Customer): void {
    this.onContactSelected.next(contact);
  }
}

