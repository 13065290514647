import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Params } from '@angular/router';
import { WebcamImage } from 'ngx-webcam';
import { ChecklistExecution, ChecklistExecutionService, ChecklistItem, ChecklistItemService, MachineExecution, MachineExecutionAssetsService, MachineExecutionService, MachineScheduler, MachineSchedulerService, MachineTask, MachineTaskService, SchedulerInfoService, TimedTask, TriggerType, User, UserService } from 'onevoice';
import { Observable, scheduled, Subject } from 'rxjs';

@Component({
  selector: 'main-manual',
  templateUrl: './manual.component.html',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  public task?: MachineTask
  public able: boolean = true
  public itemChecked: ChecklistItem[] = []
  public comments: string = ''
  public finished: boolean = false
  public haveImage: boolean = false
  public seconds: number = 0;
  public minutes: number = 0;
  public hours: number = 0;
  public operator: String = '';
  public isAdmin: boolean = false;
  selectedDateTime: Date | null = null;
  public timedTask: TimedTask | undefined;
  public machineScheduler: MachineScheduler | undefined;
  public listOfUsers: User[] = [];
  public startTime: String = '';
  public endTime: String = '';
  public currentDate: Date = new Date();
  private trigger: Subject<any> = new Subject();
  public webcamImage: WebcamImage[] = []
  private nextWebcam: Subject<any> = new Subject();
  sysImage = '';

  public form: FormGroup = new FormGroup({

  }, []);
  public typeTask: String[] = ['Terminal', 'Concorrente', 'Revisão']
  public typeExecution: String = 'Concorrente'
  private LAST_TASK: string = "LAST_TASK";

  public checklistItens: ChecklistItem[] = [];
  public executionList: ChecklistExecution[] = [];

  constructor(
    private tasks: MachineTaskService,
    private executions: MachineExecutionService,
    private checklists: ChecklistItemService,
    private checkExecutions: ChecklistExecutionService,
    private assetsService: MachineExecutionAssetsService,
    private _route: ActivatedRoute,
    public user: UserService,
    public timedTaskdata: SchedulerInfoService,
    public machineSchedulerService: MachineSchedulerService,
  ) {
  }

  getTimedTask() {
    this.timedTaskdata.objeto$.subscribe(data => {
      this.timedTask = data;
      console.log(this.timedTask);
      if (this.timedTask != undefined) { this.getMachineScheduler(this.timedTask); }
      console.log(this.timedTask?.startTime)
    })
  }
  listUsers() {
    this.user.list().subscribe(usersList => {
      this.listOfUsers = usersList;
    })
  }
  getMachineScheduler(timedTask: TimedTask) {
    console.log(timedTask?.startTime)
    this.setStartTimeScheduled()
    this.machineSchedulerService.list().subscribe(machineSchedulerList => {
      machineSchedulerList.forEach(scheduler => scheduler.timedTaskList.forEach(timed => {
        if (timed.id === timedTask.id) {
          this.machineScheduler = scheduler;
          this.operator = this.machineScheduler.user.details.last_name;
        }
      }))
    })
  }
  setStartTimeScheduled() {

    const year = this.currentDate.getFullYear();
    const month = String(this.currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(this.currentDate.getDate()).padStart(2, '0');
    const hours = this.timedTask?.startTime.slice(0, 2);
    const minutes = this.timedTask?.startTime.slice(3, 5);;

    this.startTime = new Date(`${year}-${month}-${day}T${hours}:${minutes}`).getTime().toString();
    this.endTime = this.startTime;
  }
  getInfoAdmin() {
    this.user.info().subscribe(data => {
      this.isAdmin = data.authorities.some(auth =>
        auth.authorization === "ADMIN" || auth.authorization === "MANAGER"
      );
    })
  }
  get lastExecution(): (MachineExecution | null) {
    let retrieved = localStorage.getItem(this.LAST_TASK);
    if (retrieved === null) {
      return null;
    }
    return JSON.parse(retrieved) as MachineExecution;
  }

  set lastExecution(data: MachineExecution | null) {
    if (data === null) {
      localStorage.removeItem(this.LAST_TASK);
      return;
    }

    localStorage.setItem(this.LAST_TASK, JSON.stringify(data));
  }

  public ngOnInit(): void {
    this.getInfoAdmin();
    this.getTimedTask();
    this.listUsers();
    this._route.params.subscribe((param: Params) => {
      let pk = param["id"] as number;

      if (pk !== undefined) {
        this.tasks.read(pk).subscribe((task) => {
          console.log(task)
          this.task = task;


          this.getCheckLists(task.id)
        });
      }
    });
  }
  public create() {
    this.executions.create({
      timedTaks: this.timedTask,
      task: this.task,
      operator: this.operator,
      duration: 0.0,
      start: this.startTime,
      end: this.endTime,
      data: this.comments,
      completeness: 0.0,
      trigger: TriggerType.MANUAL,
      ...this.form.value,
    }).subscribe(data => {
      this.createCheckListExecution(data, this.itemChecked);
      console.log(data)
      this.finished = true;
    })

  }
  public createCheckListExecution(machineExecution: MachineExecution, checkListItem: ChecklistItem[]) {
    checkListItem.forEach(checkListIt => {

      this.checkExecutions.create({
        checked: true,
        execution: machineExecution,
        item: checkListIt,
      }).subscribe(created => {
        this.executionList.push(created);
        console.log(created)
      });
    })
  }

  public getCheckLists(taskId: number) {
    this.checklists.getCheckListByIdTask(taskId).subscribe(checklistItens => {
      this.checklistItens = checklistItens;
    });
  }
  public isChecked(item: ChecklistItem) {
    let list = this.executionList.filter(value => value.item.id == item.id).map((item) => item.checked);
    return list.length > 0 && list.reduce((prev, current, index) => prev || current);
  }

  public addCheck(item: ChecklistItem, value: MatCheckboxChange): void {
    // if (this.lastExecution === null) {
    //   return;
    // }
    console.log(value.checked)
    if (this.itemChecked.includes(item) && value.checked === false) {
      this.itemChecked.splice(this.itemChecked.indexOf(item))
    }

    if (!this.itemChecked.includes(item) && value.checked === true) {
      this.itemChecked.push(item);
    }

    console.log(this.itemChecked)

  }

  public startTask(): void {
    this.able = false
    if (this.task === undefined) {
      return;
    }
    let task = this.task;

    this.executions.create({
      task: this.task,
      duration: 0.0,
      start: new Date().getTime(),
      data: this.comments,
      completeness: 0.0,
      trigger: TriggerType.MANUAL,
      ...this.form.value,
    }).subscribe(created => {

      this.executionList = [];
      this.lastExecution = created;

      this.checkExecutions.list().subscribe(executionList => {
        this.executionList = executionList.filter(value => value.item.task.id == task.id && value.execution.id == created.id);
      });
    })
  }

  public endTask(): void {
    this.able = true
    this.finished = true
    let execution = this.lastExecution;
    if (execution === null) {
      console.log('null')
      return;
    }

    if (execution.completeness < 10.0) {
      execution = {
        ...execution,
        completeness: 100.0,
        end: new Date().getTime(),
      };
      execution.duration = execution.end - execution.start;
      execution.data = this.comments;
      this.executionTime(execution.duration)
      this.executions.create(execution).subscribe(execution => {
        console.log(execution)
        this.lastExecution = execution;
        this.uploadImg(execution);
        this.createCheckListExecution(execution, this.itemChecked)
      })
    }

  }
  public uploadImg(execution: MachineExecution) {
    this.webcamImage.forEach(image => {
      console.log(image);
      this.assetsService.uploadManualImg(image, execution.id).subscribe(data => {
        console.log(data);
      })
    })
  }
  executionTime(time: number) {
    this.seconds = Math.trunc(time / 1000);
    this.minutes = Math.trunc(this.seconds / 60);
    this.hours = Math.trunc(this.minutes / 60)


  }

  public running(): boolean {
    return this.lastExecution != null && this.lastExecution.duration <= 0;
  }

  getTotalArea(): number {
    // let total = this.executionList.map(value => value.item.area).reduce((previous, current) => previous + current, 0);

    let total: number = 0;
    this.executionList.forEach(element => {
      total += element.item.area
    });

    return total
  }
  public getSnapshot(): void {
    this.trigger.next(void 0);
  }
  public captureImg(webcamImage: WebcamImage): void {
    console.log(webcamImage)
    this.webcamImage.push(webcamImage);
    this.sysImage = webcamImage!.imageAsDataUrl;

  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  public addImage() {
    this.haveImage = true;
  }
  public removeImage() {
    this.haveImage = false;
    this.webcamImage = []
  }
}
