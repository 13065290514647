import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface RestResponse {
    id?: number;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class RestResponseService {
    url: string = `${environment.coreService}/api/responses/rest`;

    constructor(private _httpClient: HttpClient) {}

    list(): Observable<RestResponse[]> {
        return this._httpClient.get<RestResponse[]>(this.url);
    }

    get(id: number): Observable<RestResponse> {
        return this._httpClient.get<RestResponse>(`${this.url}/${id}`);
    }

    create(data: RestResponse): Observable<RestResponse> {
        return this._httpClient.post<RestResponse>(this.url, data);
    }

    update(data: RestResponse): Observable<RestResponse> {
        return this._httpClient.post<RestResponse>(
            `${this.url}/${data.id}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        );
    }

    delete(data: RestResponse): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
