import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToggleBtnComponent } from './toggle-btn.component';


@NgModule({
  declarations: [
    ToggleBtnComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ToggleBtnComponent
  ]
})
export class ToggleBtnModule { }
