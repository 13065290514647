import { NgModule } from '@angular/core';
import { FormatsModule } from './formats/formats.module';
import { OneUIComponent } from './one-ui.component';
import { EditFormatControllComponent, EditFormatFieldComponent, EditFormatListFieldComponent, EditResponseComponent } from './response-format/edit-response/edit-response.component';



@NgModule({
  declarations: [
    OneUIComponent,

    // Main Components
    EditResponseComponent,

    // Internal Edit Component
    EditFormatControllComponent,
    EditFormatListFieldComponent,
    EditFormatFieldComponent,
  ],
  imports: [
    FormatsModule,
  ],
  exports: [
    OneUIComponent,
    EditResponseComponent
  ]
})
export class OneUIModule { }
