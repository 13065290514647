import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';

export interface BaseModel<ID> {
  id: ID;
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<Model extends BaseModel<ID>, ID> {
  protected abstract path: string;

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(params?: HttpParams): Observable<Model[]> {
    return this.http.get<Model[]>(`${this.path}`, {
      params: params,
    });
  }

  public create(data: Partial<Model>): Observable<Model> {
    return this.http.post<Model>(`${this.path}`, data);
  }

  public read(id: ID): Observable<Model> {
    return this.http.get<Model>(`${this.path}/${id}`);
  }

  public update(data: Model): Observable<Model> {
    return this.http.post<Model>(`${this.path}/${data.id}`, data);
  }

  public delete(id: ID): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${id}`);
  }
}
