import { Component } from '@angular/core';

@Component({
  selector: 'oneui-root',
  template: `<p>one-ui works!</p>`,
  styles: []
})
export class OneUIComponent {
  constructor() { }
}
