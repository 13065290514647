import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Observable } from 'rxjs';

import { AuthenticationService, ExecutionStatus, Mood, MoodService, ProjectExecution, ProjectExecutionService, ProjectService, ScriptLine, ScriptLineService, SpeechProject, TokenService, Voice, VoiceService } from 'onevoice';
import { DownloadComponent } from './download/download.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { isNumber } from 'lodash';


function ByID(p1: SpeechProject, p2: SpeechProject) {
  return p2.id - p1.id;
}

@Component({
  selector: 'main-voice-project',
  templateUrl: './voice-project.component.html',
  styleUrls: ['./voice-project.component.scss']
})
export class VoiceProjectComponent implements AfterViewInit {
  public title: string = "Projetos";
  public icon: string = "mic";
  public projectList?: SpeechProject[];
  public executionList: ProjectExecution[] = [];
  public process: boolean = false;
  public view_icon: boolean = true;
  public selectedIndex: number = 0;
  public actionView: boolean = true;
  public display = 'block';
  public progressBarId: number = -1;
  public displayCard: number = 100;
  public projectDetail?: SpeechProject;
  public script = new MatTableDataSource<ScriptLine>([]);
  public moodSizeID: Number[] = [];
  public voiceSizeID: Number[] = [];
  public moodList: Mood[] = [];
  public voiceList: Voice[] = [];
  public isMobile: boolean = false;
  public datasource = new MatTableDataSource<SpeechProject>([]);
  public datasourceFavorite = new MatTableDataSource<SpeechProject>([]);
  public obs?: Observable<any>;
  public obsFavorite?: Observable<any>;

  public columnsToDisplay: string[] = [
    "favorite", "id", "name", "description", "actions"
  ];

  private listCache: { [pk: number]: Observable<ScriptLine[]> } = {};

  public createForm = new FormGroup({
    "name": new FormControl("", [Validators.required, Validators.minLength(4)]),
    "description": new FormControl("", [Validators.required, Validators.minLength(10)]),
  });

  @ViewChildren(MatPaginator) public paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(
    public service: ProjectService,
    public scriptService: ScriptLineService,
    public execution: ProjectExecutionService,
    public dialogs: MatDialog,
    public auth: AuthenticationService,
    public moodService: MoodService,
    public voiceService: VoiceService,
  ) { }

  ngOnInit() {
    this.auth.systemToken().then( _ => {
      this.loadData();
      this.isMobile = this.getIsMobile;
    });
  }

  loadData(): void {
    this.service.list().subscribe((projects): void => {
      this.datasource.data = projects;
      this.datasourceFavorite.data = this.datasource.data.filter((project) => project.favorite === true);
      this.obs = this.datasource.connect();
      this.obsFavorite = this.datasourceFavorite.connect();
    });
  }

  ngAfterViewInit(): void {
    this.datasource.paginator = this.paginator.toArray()[0];
    this.datasource.sort = this.sort.toArray()[0];
    this.datasourceFavorite.paginator = this.paginator.toArray()[1];
    this.datasourceFavorite.sort = this.sort.toArray()[1];
  }

  public createProject0(): void {
    this.service.create(this.createForm.value).subscribe( _ => {
      this.createForm.reset();
      // if (this.projectList) {
      //   this.projectList.push(project);
      //   this.projectList.sort(ByID);
      // }
    });
  }

  public retrieveProject(project: SpeechProject) {
    let val = this.listCache[project.id];
    if (val === undefined) {
      val = this.scriptService.list(project);
      this.listCache[project.id] = val;
    }
    return val;
  }

  public getVoices(lines: ScriptLine[]) {
    return lines.map(value => value.voice.title).filter((value, index, array) => array.indexOf(value) == index).join(", ");
  }

  public generateVoiceProject(project: SpeechProject): void {
    this.process = this.processing;
    this.progressBarId = project.id;
    this.execution.create({
      project: project,
    }).subscribe(created => {
      this.executionList.push(created);
    });
  }

  public openDownloads(project: SpeechProject): void {
    let reference = this.dialogs.open(DownloadComponent, {
      width: "95%",
      maxWidth: "800px",
      data: project,
    });
  }

  public createProject(): void {
    let reference = this.dialogs.open<ProjectCreateComponent, Partial<SpeechProject>, SpeechProject>(ProjectCreateComponent, {
      width: '500px',
      maxWidth: '95%',
      data: { name: "" },
    });
    reference.afterClosed().subscribe((data: SpeechProject | undefined): void => {
      if (data) {
        this.service.create(data).subscribe(created => {
          if (created) {
            this.datasource.data = this.datasource.data.concat([created]);
          }
        });
      }
    });
  }

  get processing(): boolean {
    return this.executionList.filter(
      value => value.status == ExecutionStatus.REQUESTED || value.status == ExecutionStatus.PROCESSING,
    ).length > 0;
  }

  get getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1100;
    if (w < breakpoint) { return true; }
    else { return false; }
  }

  public deleteProject(project: SpeechProject) {
    this.service.delete(project.id).subscribe(ok => {
      if (project && this.datasource.data.length) {
        this.datasource.data = this.datasource.data.filter(item => item.id != project.id);
      }
    });
  }

  public selected(item: SpeechProject | any): void {
    item.favorite = !item.favorite;
    this.service.update(item).subscribe( (_): void => {
      this.datasourceFavorite.data = this.datasource.data.filter((project) => project.favorite === true);
      if (this.datasource.data.length === 0 || this.datasourceFavorite.data.length === 0) {
        this.displayCard = 100;
      }
    });
  }

  public changeTab(index: MatTabChangeEvent | number): void {
    this.selectedIndex = isNumber(index) ? index : index.index;
    this.displayCard = 100;
  }

  public changeViewIcon(): void {
    this.view_icon = !this.view_icon;
    setTimeout( () => {
      this.datasource.sort = this.sort.toArray()[0];
      this.datasource.paginator = this.paginator.toArray()[0];
      this.datasourceFavorite.sort = this.sort.toArray()[1];
      this.datasourceFavorite.paginator = this.paginator.toArray()[1];
    });
  }

  public actionsView(): void {
      this.actionView = !this.actionView;
  }

  public cardShowInf(project: SpeechProject): void {
    if (!this.isMobile) {
      this.projectDetail = project;
      this.listInformation();
      if (this.displayCard === 100) {
        this.displayCard = 58;
      } else {
        this.displayCard = 100;
        this.projectDetail = undefined;
      }
    }
  }

  public listInformation(): void {
    if (this.projectDetail) {
      this.moodSizeID = [];
      this.voiceSizeID = [];
      this.scriptService.list(this.projectDetail).subscribe(scriptList => {
        this.script.data = scriptList;
        this.script.data.map(e => {
          if (this.moodSizeID.indexOf(e.mood.id) == -1) {
            this.moodSizeID.push(e.mood.id);
          }
          if (this.voiceSizeID.indexOf(e.voice.id) == -1) {
            this.voiceSizeID.push(e.voice.id);
          }
        });
      });
      this.moodService.list().subscribe(moodList => this.moodList = moodList);
      this.voiceService.list().subscribe(voiceList => this.voiceList = voiceList);
    }
  }
}