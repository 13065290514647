<form class="channel-dialog" [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-toolbar class="channel-toolbar" matDialogTitle [color]="color">
        <mat-toolbar-row>
            <span>{{ title }}</span>
            <button mat-icon-button aria-label="Close dialog" [mat-dialog-close]="undefined">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <h3>Informações do Canal</h3>
        <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput formControlName="name" type="text">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Modo</mat-label>
            <mat-select formControlName="status">
                <mat-option *ngFor="let value of statusList" [value]="value">
                    {{ statusName(value) }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <h3>Configurações</h3>
        <ng-container [ngSwitch]="TYPE">
            <ng-container *ngSwitchCase="DialogType.EMAIL">
                <mat-form-field appearance="outline">
                    <mat-label>Endereço de Email</mat-label>
                    <input matInput formControlName="from" type="text">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Usuário</mat-label>
                    <input matInput formControlName="username" type="text">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input *ngIf="form.value.method != AuthMethod.XOAUTH" matInput formControlName="password"
                        type="password">
                    <textarea *ngIf="form.value.method == AuthMethod.XOAUTH" matInput
                        formControlName="password"></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Método de Senha</mat-label>
                    <mat-select formControlName="method">
                        <mat-option *ngFor="let method of methods" [value]="method">
                            {{ method }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Método de Recebimento</mat-label>
                    <mat-select formControlName="receiveMethod">
                        <mat-option *ngFor="let method of receiveMethods" [value]="method">
                            {{ method }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>



                <mat-form-field appearance="outline">
                    <mat-label>Servidor IMAP</mat-label>
                    <input matInput formControlName="imapServer" type="text">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Porta IMAP</mat-label>
                    <input matInput formControlName="imapPort" type="number">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Segurança IMAP</mat-label>
                    <mat-select formControlName="imapSecurity">
                        <mat-option *ngFor="let value of securityList" [value]="value">
                            {{ value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Servidor SMTP</mat-label>
                    <input matInput formControlName="smtpServer" type="text">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Porta SMTP</mat-label>
                    <input matInput formControlName="smtpPort" type="number">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Segurança SMTP</mat-label>
                    <mat-select formControlName="smtpSecurity">
                        <mat-option *ngFor="let value of securityList" [value]="value">
                            {{ value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <ng-container *ngSwitchCase="DialogType.FACEBOOK_PAGE">
                <mat-slide-toggle formControlName="messenger">
                    Habilitar o canal para messenger
                </mat-slide-toggle>
                <mat-slide-toggle formControlName="comments">
                    Habilitar o canal para comentários de páginas
                </mat-slide-toggle>
            </ng-container>
        </ng-container>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button [color]="color" class="save-button" type="submit" [disabled]="form.invalid">
            {{ saveAction }}
        </button>
    </mat-dialog-actions>
</form>