<h1 mat-dialog-title>Medias</h1>

<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="filter">
      <mat-option *ngFor="let type of getMediaTypes()" [value]="type">{{ type }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="image-list">
    <ng-container *ngFor="let media of currentPage()">
      <img class="preview-image" *ngIf="media.type == MediaType.IMAGE" [src]="storage.redirect(media)"
        (click)="select(media)">
      <video class="preview-image" *ngIf="media.type == MediaType.VIDEO" [src]="storage.redirect(media)"
        (click)="select(media)">
        Seu navegador não suporta display direto de vídeo.
      </video>
      <ng-container *ngIf="media.type == MediaType.AUDIO">
        <audio class="preview-audio" [src]="storage.redirect(media)" controls>
          Seu navegador não suporta áudio.
        </audio>
        <button mat-button (click)="select(media)">Select</button>
      </ng-container>
    </ng-container>
    <mat-paginator [length]="listMedia().length" [pageSizeOptions]="pageSizeOptions" (page)="setPage($event)">
    </mat-paginator>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="mediaUploadInput.click()">Upload</button>
  <button mat-button [mat-dialog-close]="null">Cancelar</button>
</mat-dialog-actions>

<input #mediaUploadInput hidden style="display: none; visibility: hidden;" type="file" (change)="uploadFiles($event)">