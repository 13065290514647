import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, UserService } from 'onevoice';

@Component({
  selector: 'main-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  public form: FormGroup = new FormGroup({
    "file": new FormControl(undefined, [Validators.required]),
  });
  constructor(
    private reference: MatDialogRef<AvatarComponent>,
    private users: UserService,
    private auth: AuthenticationService,

    private snack: MatSnackBar,
  ) { }


  public onSubmit() {
    console.log("Uploading avatar");
    this.users.uploadAvatar(this.form.value.file).subscribe(_ => {
      this.snack.open("Avatar alterado com sucesso!", "OK");
      this.reference.close(true);
    })
  }
}
