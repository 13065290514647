import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestMediaComponent } from 'one-ui';
import { Bot, BotService, CustomerChannelType, IdentifierType, Intent, IntentService } from 'onevoice';

@Component({
    selector: 'alabia-bot-create',
    templateUrl: './bot-create.component.html',
    styleUrls: ['./bot-create.component.scss']
})
export class BotCreateComponent {
    slider = {
        tickInterval: 0.1,
        min: 0,
        max: 1,
        showTicks: false,
        step: 1,
        thumbLabel: false,
    }

    public intentList: Intent[] = [];
    public feedback?: Intent;

    public IdentifierType = IdentifierType;
    public CustomerChannelType = CustomerChannelType;

    constructor(
        private reference: MatDialogRef<BotCreateComponent>,
        public intents: IntentService,
        public service: BotService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: Partial<Bot>
    ) {
        if (data.id) {
            intents.list(data.id).subscribe(intentList => {
                this.intentList = intentList;
                this.feedback = this.intentList.find(value => data.feedback_id && value.id == data.feedback_id);
            });
        }
    }

    formatLabel(value: number) {
        return (value * 100) + ' %';
    }

    public saveBot() {
        // result.open = result.open + ':00';
        // result.close = result.close + ':00';
        if (this.data.open) {
            this.data.open = this.fixTime(this.data.open);
        }
        if (this.data.close) {
            this.data.close = this.fixTime(this.data.close);
        }
        if (this.data.id) {
            this.service.update(this.data).subscribe((bot: Bot) => {
                this.service.setFeedback(bot, (this.feedback) ? this.feedback.id : undefined).subscribe(_ => {
                    bot.feedback_id = (this.feedback) ? this.feedback.id : undefined;
                    this.reference.close(bot);
                });
            });
        } else {
            this.service.create(this.data).subscribe((bot: Bot) => this.reference.close(bot));
        }
    }

    private fixTime(value: string) {
        while (value.split(":").length < 3) {
            value = value + ":00";
        }
        return value;
    }

    public identifierList() {
        return Object.values(IdentifierType);
    }

    public contactList() {
        return [CustomerChannelType.EMAIL, CustomerChannelType.MOBILE, CustomerChannelType.WHATSAPP];
    }

    addAvatar(): void {
        if (!this.data.id) {
            return;
        }
        let botid = this.data.id;

        let reference = this.dialog.open(RequestMediaComponent, {
            maxWidth: "80%",
            width: "500px",
        });

        let component = reference.componentInstance;
        component.Send.subscribe((file) => {
            this.service.upload(botid, file).subscribe(ok => {
                if (ok) {
                    component.OnSend.next(true);
                }
            }, err => component.OnSend.next(false))
        });

        reference.afterClosed().subscribe(ok => {
            console.log("Finished: ", ok);
        });

    }


    addBanner(): void {
        if (!this.data.id) {
            return;
        }
        let botid = this.data.id;
        let reference = this.dialog.open(RequestMediaComponent, {
            maxWidth: "80%",
            width: "500px",
        });

        let component = reference.componentInstance;
        component.Send.subscribe((file) => {
            this.service.uploadBanner(botid, file).subscribe(ok => {
                if (ok) {
                    component.OnSend.next(true);
                }
            }, err => component.OnSend.next(false))
        });

        reference.afterClosed().subscribe(ok => {
            console.log("Finished: ", ok);
        });

    }

    addLogo(): void {
        if (!this.data.id) {
            return;
        }
        let botid = this.data.id;
        let reference = this.dialog.open(RequestMediaComponent, {
            maxWidth: "80%",
            width: "500px",
        });

        let component = reference.componentInstance;
        component.Send.subscribe((file) => {
            this.service.uploadLogo(botid, file).subscribe(ok => {
                if (ok) {
                    component.OnSend.next(true);
                }
            }, err => component.OnSend.next(false))
        });

        reference.afterClosed().subscribe(ok => {
            console.log("Finished: ", ok);
        });

    }

}
