<div class="header accent"></div>
<div class="functionality" [routerLink]="['robot-dashboard']">
    <mat-icon class="functionality__icon">
        dashboard
    </mat-icon>
    <h3 class="functionality__title">
        Dashboard
    </h3>
    <span class="functionality__description">
        Informações e gráficos sobre os robôs
    </span>
</div>

<div class="functionality" [routerLink]="['instances']">
    <mat-icon class="functionality__icon">
        local_laundry_service
    </mat-icon>
    <h3 class="functionality__title">
        Máquinas
    </h3>
    <span class="functionality__description">
        Lista de todos os robôs
    </span>
</div>

<div class="functionality" [routerLink]="['maps']">
    <mat-icon class="functionality__icon">
        map
    </mat-icon>
    <h3 class="functionality__title">
        Mapas
    </h3>
    <span class="functionality__description">
        Gerêncie os mapas dos robôs
    </span>
</div>

<div class="functionality" [routerLink]="['tasks']">
    <mat-icon class="functionality__icon">
        done_all
    </mat-icon>
    <h3 class="functionality__title">
        Tarefas
    </h3>
    <span class="functionality__description">
        Lista com todas as tarefas dos robôs
    </span>
</div>

<div class="functionality" [routerLink]="['manualtask']">
  <mat-icon class="functionality__icon">
      done_all
  </mat-icon>
  <h3 class="functionality__title">
      Minhas tarefas
  </h3>
  <span class="functionality__description">
      Lista com tarefas manuais
  </span>
</div>
