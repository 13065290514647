import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthenticationService, LimitService, Tickets, TicketsService, TicketType, TTSHistory, TTSService, TTSTicketID, Voice } from 'onevoice';

@Component({
  selector: 'tts-create',
  templateUrl: './tts-create.component.html',
  styleUrls: ['./tts-create.component.scss']
})
export class TtsCreateComponent implements OnInit, OnChanges {
  private audio: HTMLAudioElement = new Audio();
  public charCount: string = "";
  public generate: string = "";
  public generating: boolean = false;
  public refreshIcon = "refresh-icon";
  public spaceCount: number = 0;
  public tickets: Tickets[] = [];
  // public ttsView: boolean = true;
  public voiceModel?: Tickets;

  @Input() voice?: Tickets;

  @Output() eventEmitter = new EventEmitter();

  @Output() onGenerate = new EventEmitter<Partial<TTSHistory>>()

  constructor(
    private service: TTSService,
    private serviceTickets: TicketsService,
    private limits: LimitService,
    private snack: MatSnackBar,
    private auth: AuthenticationService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.voice.previousValue != undefined) {
      this.voiceModel = changes.voice.currentValue;
    }
  }

  ngOnInit(): void {
    this.auth.systemToken().then(_ => {
      setTimeout(() => {
        this.limits.check().subscribe(_ => {
        });
      }, 10000);

      this.serviceTickets.list().subscribe(tickets => {
        this.tickets = tickets.filter(value => value.type == TicketType.TTS);
        console.log(this.tickets);
        if (tickets.length > 0) {
          this.voiceModel = tickets[0];
        }
      });
    });

    this.voiceModel = this.voice;
  }

  generateAudio(): void {
    if (this.generating) {
      return;
    }

    this.generating = true;
    if (this.voiceModel) {
      // this.service.generate(this.voiceModel, this.generate).subscribe(data => {
      this.service.generateVoice((this.voiceModel?.id as TTSTicketID).voice, this.generate).subscribe(data => {
        this.generating = false;

        // adicionar o load history chamando outro component ******
        // this.loadHistory();
        let text = this.generate;

        let reader = new FileReader();
        reader.addEventListener("loadend", (ev) => {
          if (typeof reader.result === "string") {
            this.audio.src = reader.result;
            this.audio.play();
            this.onGenerate.emit({
              text: text,
              duration: 0,
            });
          } else {
            console.log(typeof reader.result);
            console.log("Not going to play audio");
          }
        });
        reader.readAsDataURL(data);
        this.generate = "";
      }, err => {
        this.generating = false;
        if (err.status == 429) {
          this.snack.open("A sua franquia de geração de áudio não é suficiente.", "OK");
        } else {
          this.snack.open("Aconteceu um erro durante a geração do áudio. Tente novamente.", "OK");
        }
      });
    }
  }

  async refreshText(): Promise<void> {
    this.generate = "";
    this.charCount = "";
    this.refreshIcon = "refresh-icon-animation";
    await this.delay(4000);
    this.refreshIcon = "refresh-icon";
  }

  public delay(ms: number): Promise<unknown> {
    return new Promise((resolve) => setInterval(resolve, ms));
  }

  public textCount(): void {
    this.charCount = this.generate;
    this.charCount = this.charCount.replace(/\s/g, "");
    this.spaceCount = this.generate.length - this.charCount.length;
  }

  public changeVoice(): void {
    this.eventEmitter.emit(this.voiceModel);
  }

  compareWith(o1: { id: number }, o2: { id: number }) {
    if (o1 != null && o2 != null) {
      return o1.id == o2.id;
    } else {
      return false;
    }
  }

  public getVoice(ticket: Tickets): Voice {
    return (ticket.id as TTSTicketID).voice
  }
}
