import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgentService, Chat, StompMedia } from 'onevoice';
import { Subject } from 'rxjs';

@Component({
  selector: 'alabia-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  private files?: FileList;
  public message: string = "";
  private OnSend: Subject<StompMedia> = new Subject<StompMedia>();

  public sent: number = 0;
  public sending: boolean = false;

  constructor(
    public agent: AgentService,
    private reference: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) public chat: Chat,
  ) { }

  ngOnInit() {
    this.OnSend.subscribe((next) => {
      this.sent += 1;

      if (this.files && this.sent == this.files.length) {
        this.reference.close(true);
      }
    })
  }

  fileCount(): number {
    return (this.files) ? this.files.length : 0;
  }

  finish() {
    if (!this.files) { return; }
    this.sending = true;
    for (let i = 0; i < this.files.length; i += 1) {
      let file = this.files.item(i)
      if (file) {
        this.agent.upload(this.chat, this.message, file).then(media => {
          this.OnSend.next(media);
        });
      }
    }
  }

  selectFiles(event: Event) {
    let target = event.target as HTMLInputElement;
    if (target.files) {
      this.files = target.files;
    }
  }
}
