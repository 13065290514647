import { Ownable } from '../auth/ownable.service';
import { CustomerChannelType } from '../crm/customer-channel.model';
import { IdentifierType } from '../crm/customer-identifier.model';

export enum BotStatus {
    DISABLED = "DISABLED",
    ENABLED = "ENABLED",
}


export interface Bot extends Ownable {
    name: string;
    description: string;
    presentation: string;

    audioEnabled?: boolean;
    textEnabled?: boolean;
    videoEnabled?: boolean;
    imageEnabled?: boolean;
    status: BotStatus;
    score?: number;

    open?: string;
    close?: string;
    away?: string;
    feedback_id?: number;

    foregroundColor: string;
    backgroundColor: string;
    backgroundColor2: string;
    bannerText: string;
    bannerColor: string;

    identifiers: IdentifierType[];
    contacts: CustomerChannelType[];
    
    customer?: string;
    notification?: string;
}
