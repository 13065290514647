import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OwnableService, Ownable } from './ownable.service';

export interface ServiceToken extends Ownable {
  name: string;
  active: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class ServiceTokenService extends OwnableService<ServiceToken> {
  protected path: string = `${this.config.endpoint}/token/service`;
  public static PARAMETER: string = "access_token";

  public retrieve(id: number): Observable<string> {
    return this.http.get(`${this.path}/${id}/value`, {
      responseType: "text",
      observe: "body",
    });
  }

  public systemToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.list().subscribe(all => {
        if (all.length > 0) {
          this.retrieve(all[0].id).subscribe(value => {
            resolve(value);
          }, error => reject(error));
        } else {
          this.create({
            id: 0,
            name: "SYSTEM TOKEN",
            active: true,
          }).subscribe(created => {
            this.retrieve(created.id).subscribe(value => { resolve(value); }, error => reject(error))
          }, error => reject(error))
        }
      });
    });
  }
}
