import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgentDataComponent } from './agent-data.component';
import { DashboardModule } from '@alabia/dashboard/dashboard.module';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AgentDataComponent,
  }
];


@NgModule({
  declarations: [AgentDataComponent],
  imports: [
    RouterModule.forChild(routes),
    MatTabsModule,
    DashboardModule,
    CommonModule,
  ]
})
export class AgentDataModule { }
