import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiConfiguration, APP_CONFIG } from './api-configuration';
import { AuthenticationService } from './auth/authentication.service';
import { MessageComponent } from './chat/message/message.component';
import { OnevoiceComponent } from './onevoice.component';

@NgModule({
  declarations: [
    MessageComponent,
    OnevoiceComponent
  ],
  imports: [
  ],
  exports: [
    MessageComponent,
    OnevoiceComponent
  ]
})
export class OnevoiceModule {
  public static forRoot(config: ApiConfiguration): ModuleWithProviders<OnevoiceModule> {
    return {
      ngModule: OnevoiceModule,
      providers: [
        {
          provide: APP_CONFIG,
          useValue: config,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthenticationService,
          multi: true,
        },
      ],
    }
  }
}
