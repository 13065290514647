<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">
        Dados vazios
      </span>
      <button
        mat-icon-button
        aria-label="Close dialog"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="dialog-container">
    <div>
      <h3>Parâmetros selecionados não possuem dados, por favor selecione novos parâmetros.</h3>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button mat-dialog-close>Fechar</button>
    </div>
  </div>
</div>
