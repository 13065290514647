import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {
  Customer,
  CustomerService,
  InformationType,
  CustomerInformation,
  InformationTypeService,
  CustomerInformationService,
  ReportService,
} from 'onevoice';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendApiRequestService implements Resolve<any> {

  customerConversation: any;
  customers: Customer[] = [];
  customerInformation: CustomerInformation[] = [];

  informationTypes: InformationType[] = [];

  onCustomersChanged: BehaviorSubject<any>;
  onCustomerConversationChanged: BehaviorSubject<any>;
  onCustomerInformationChanged: BehaviorSubject<any>;
  onInformationTypesChanged: BehaviorSubject<any>;

  constructor(
    private _customerService: CustomerService,
    private _informationTypeService: InformationTypeService,
    private _customerInformationService: CustomerInformationService,
  ) {
    this.onCustomersChanged = new BehaviorSubject([]);
    this.onCustomerConversationChanged = new BehaviorSubject([]);
    this.onCustomerInformationChanged = new BehaviorSubject([]);
    this.onInformationTypesChanged = new BehaviorSubject([]);
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.listCustomers(),
        this.listInformationTypes(),
      ]).then(([customers, informationTypes]) => {
        this.customers = customers;
        this.informationTypes = informationTypes;
        resolve(true);
      }, reject);
    });
  }

  /* LISTS */
  listInformationTypes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._informationTypeService.list().subscribe((response: InformationType[]) => {
        this.informationTypes = response;
        this.onInformationTypesChanged.next(this.informationTypes);
        resolve(this.informationTypes);
      }, reject);
    });
  }

  listCustomers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._customerService.list().subscribe((response: Customer[]) => {
        this.onCustomersChanged.next(this.customers);
        resolve(response);
      }, reject);
    });
  }

  listCustomerData(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._customerService.read(id).subscribe((response: Customer) => {
        resolve(response);
      }, reject);
    });
  }

  listCustomerInformation(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._customerInformationService.list(id).subscribe((response: CustomerInformation[]) => {
        this.customerInformation = response;
        this.onCustomerInformationChanged.next(this.customerInformation)
        resolve(this.customerInformation);
      }, reject);
    });
  }
  /* /LISTS */

  /* CREATE */
  createInformationType(data: Partial<InformationType>): Promise<any> {
    return new Promise(() => {
      this._informationTypeService.create(data).subscribe(
        res => this.listInformationTypes(),
        err => alert('Erro ao criar tipo de informação'),
      );
    });
  }

  relateUserToInfoType(customerID: number, info: CustomerInformation): Promise<any> {
    return new Promise(() => {
      this._customerInformationService.create(customerID, info).subscribe(
        res => this.listCustomerInformation(customerID),
        err => alert('Erro ao relacionar tipo de informação'),
      );
    });
  }
  /* /CREATE */

  /* DELETE */
  deleteCustomerInformation(customerID: number, infoID: number): Promise<any> {
    return new Promise(() => {
      this._customerInformationService.delete(customerID, infoID).subscribe(
        res => this.listCustomerInformation(customerID),
        err => alert('Erro ao deletar tipo de informação'),
      );
    });
  }
  /* /DELETE */
}