import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'alabia-signup-step5',
  templateUrl: './signup-step5.component.html',
  styleUrls: ['./signup-step5.component.scss', '../signup-step.shared.scss'],
  animations: [
    trigger('formAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateX(100%)', opacity: '0%' }))
      ])
    ]),
    trigger('infoAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('700ms ease', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateY(100%)', opacity: '0%' }))
      ])
    ]),
  ],
})
export class SignupStep5Component implements OnInit {

  public isShowing: boolean = true;
  @Output() nextScreen = new EventEmitter();

  plans = [
    {
      name: 'gratuito',
      price: 'gratuito',
      billing_time: '',
    },
    {
      name: 'básico',
      price: 'R$ 50',
      billing_time: 'mensal',
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  public screenOutputHandler(currentScreen: any, step: any) {
    this.isShowing = !this.isShowing;
    setTimeout(() => {
      this.nextScreen.emit({ currentScreen, step });
    }, 700);
  }

}
