<!-- CHAT -->
<div *ngIf="chat" class="chat" fxFlex fxLayout="column">

    <!-- CHAT TOOLBAR -->
    <mat-toolbar class="chat-toolbar">

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <!-- RESPONSIVE CHATS BUTTON-->
                <button mat-icon-button fxHide.gt-md class="responsive-chats-button mr-16"
                    fuseMatSidenavToggler="chat-left-sidenav" aria-label="chats button">
                    <mat-icon>chat</mat-icon>
                </button>
                <!-- / RESPONSIVE CHATS BUTTON-->

                <!-- CHAT CONTACT-->
                <div class="chat-contact" fxLayout="row" fxLayoutAlign="start center"
                    fuseMatSidenavToggler="chat-right-sidenav" (click)="selectContact()">

                    <div class="avatar-wrapper">
                        <img [src]="contactAvatar()" class="avatar" alt="contactName()" />
                    </div>

                    <div class="chat-contact-name">
                        {{ contactName() }}

                        <small *ngIf="star_rating" class="secondary-text star-rating">
                            {{ star_rating }}&starf;
                        </small>
                    </div>

                </div>
                <!-- / CHAT CONTACT-->
            </div>

            <div class="queueManipulation">
                <mat-icon title="Carregar histórico de conversa" (click)="loadHistory()">history</mat-icon>
                <mat-icon title="Encaminhar chamado" (click)="backAssignment()">redo</mat-icon>
                <mat-icon title="Concluir chamado" (click)="closeAssignment()">check_circle</mat-icon>
            </div>
        </div>
    </mat-toolbar>
    <!-- / CHAT TOOLBAR -->

    <!-- CHAT CONTENT -->
    <div id="chat-content" fxFlex="1 1 auto" fusePerfectScrollbar>

        <!-- CHAT MESSAGES -->
        <div class="chat-messages">

            <!-- MESSAGE -->
            <div *ngFor="let message of chat.dialog; let i = index" class="message-row" [ngClass]="{
                'me': message.sender !== Sender.USER,
                'contact': message.sender === Sender.USER,
                'is-robot': message.sender === Sender.ROBOT,
                'is-agent': message.sender === Sender.AGENT,
                'first-of-group': isFirstMessageOfGroup(message, i),
                'last-of-group': isLastMessageOfGroup(message, i)
            }">

                <img *ngIf="shouldShowContactAvatar(message, i)" [src]="contactAvatar(message)" class="avatar">

                <div class="bubble" (click)="goToPermalink(message)">
                    <a *ngIf="message.contentType == ContentType.MEDIA" class="download"
                        [href]="processDownload(message.content)">Download</a>
                    <div *ngIf="message.contentType == ContentType.TEXT" class="message">
                        <div>{{ processText(message.content) }}</div>
                        <small class="agent-info" *ngIf="message.sender === Sender.AGENT && message.agent">
                            enviado por {{ userFullname(message.agent) }}
                        </small>
                    </div>

                    <div class="message" *ngIf="message.contentType == ContentType.JSON">
                        <oneui-data-container [data]="message.content"></oneui-data-container>
                    </div>

                    <div *ngIf="isLastMessageOfGroup(message, i)" class="time secondary-text">
                        {{message.date | date:'short'}}
                    </div>
                </div>

            </div>
            <!-- / MESSAGE -->

        </div>
        <!-- CHAT MESSAGES -->

    </div>
    <!-- / CHAT CONTENT -->

    <!-- CHAT FOOTER -->
    <div class="chat-footer" fxFlex="0 0 auto" fxLayout="column">

        <!-- REPLY FORM -->
        <div class="reply-form" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">


            <form #replyForm="ngForm" (ngSubmit)="reply($event)" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field class="message-text" fxFlex floatLabel="never" appearance="standard">
                    <textarea matInput #replyTextArea placeholder="Type your message" [formControl]="replyMessage"
                        name="message" [rows]="1" [matTextareaAutosize]="true"></textarea>
                </mat-form-field>

                <small class="response-limit secondary-text">{{ replyMessage.value.length }} / {{ limit() }}</small>

                <button class="send-message-button" [disabled]="!agent.IsConnected" mat-icon-button type="submit"
                    aria-label="Send message">
                    <mat-icon class="secondary-text">send</mat-icon>
                </button>

                <button class="response-list-button" [disabled]="!agent.IsConnected" mat-icon-button type="button"
                    (click)="openAgentResponses()" aria-label="Send message">
                    <mat-icon class="secondary-text">edit</mat-icon>
                </button>

                <button class="send-emoji-button" [disabled]="!agent.IsConnected" mat-icon-button type="button"
                    (click)="openKeyboardEmoji()" aria-label="Send message">
                    <mat-icon class="secondary-text">keyboard</mat-icon>
                </button>

                <button class="send-upload-button" [disabled]="!agent.IsConnected" mat-icon-button type="submit"
                    aria-label="Upload file" (click)="uploadDialog()">
                    <mat-icon class="secondary-text">cloud_upload</mat-icon>
                </button>
            </form>

        </div>
        <!-- / REPLY FORM -->

    </div>
    <!-- / CHAT FOOTER-->

</div>
<!-- / CHAT -->