import { Injectable } from '@angular/core';
import { GenericModel, GenericService } from '../generic.service';
import { MachineMap } from '../hw/machine-map.service';

export interface Floor extends GenericModel<number> {
  name: string;
  maps: MachineMap[];
}

@Injectable({
  providedIn: 'root'
})
export class FloorService extends GenericService<number, Floor> {
  protected path: string = `${this.config.endpoint}/spaces/floors`;
}
