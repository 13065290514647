import { Intent, IntentService } from 'onevoice';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { create } from 'lodash';
import { Bot, ServiceStatus, TriggerCustomer, TriggerCustomerService } from 'onevoice';

@Component({
  selector: 'main-trigger-dialog',
  templateUrl: './trigger-dialog.component.html',
  styleUrls: ['./trigger-dialog.component.scss']
})
export class TriggerDialogComponent {
  public triggers: TriggerCustomer[] = [];
  public intentList: Intent[] = [];

  public ServiceStatus = ServiceStatus;

  constructor(
    private service: TriggerCustomerService,
    private intents: IntentService,
    @Inject(MAT_DIALOG_DATA) public data: Bot
  ) {
    service.list(data.id).subscribe(triggers => {
      intents.list(data.id).subscribe(intents => {
        this.intentList = intents;
        this.triggers = triggers;

        this.statusList.forEach(status => {
          if (this.triggers.find(trigger => trigger.status === status) === undefined) {
            this.triggers.push({
              status: status,
              intent: { id: intents[0].id || 0 },
            });
          }
        })
      });
    });
  }

  get statusList(): ServiceStatus[] {
    return Object.values(ServiceStatus);
  }

  public submit(): void {
    this.triggers.forEach(trigger => {
      if (trigger.id !== undefined) {
        this.service.update(this.data.id, trigger.id, trigger).subscribe(updated => {
          this.triggers = this.triggers.filter(value => value.id !== updated.id);
          this.triggers.push(updated);
        })
      } else {
        this.service.create(this.data.id, trigger).subscribe(created => {
          this.triggers = this.triggers.filter(value => value.status !== created.status);
          this.triggers.push(created);
        });
      }
    })
  }
}
