export class ChannelManagerForm { 
    name: string;
    description: string;
    accessToken: string;
    verifyToken: string;
    constructor(channel: any) {
        {
            this.name = '';
            this.description = '';
            this.accessToken = '';
            this.verifyToken = '';

        }
    }
}
