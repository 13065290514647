// snackbar-image.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-image',
  template: `
  <div style="display: flex; align-items: center; justify-content: center; max-width: 750x; max-height: 1000px;">
  <img [src]="data.url" alt="Minha Imagem" style="width: 100%; height: 100%; object-fit: contain; padding: 15px;">
</div>
<button mat-button style="background-color: purple; margin: 10px" (click)="closeSnackbar()">Fechar</button>
`,
})
export class SnackbarImageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  private snackBarRef: MatSnackBarRef<SnackbarImageComponent>
) {

  }
  closeSnackbar() {
    this.snackBarRef.dismiss(); // Fecha o snackbar
  }
}
