<div *ngIf="bot && fbHook" class="block">
  <div class="block__element">

    <mat-list>
      <h3 mat-list-item mat-subheader>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]="fbHook.name" class="title">
        </mat-form-field>
      </h3>
      <mat-divider></mat-divider>

      <h3 mat-list-item mat-subheader>
        Detalhes
      </h3>
      <mat-list-item>
        <mat-form-field appearance="outline">
          <input matInput type="text" [(ngModel)]="fbHook.description">
        </mat-form-field>
      </mat-list-item>
      <mat-divider></mat-divider>

      <h3 mat-list-item mat-subheader>
        Detalhes do Facebook
      </h3>
      <mat-list-item>
        <mat-form-field appearance="outline" hintLabel="Token de acesso para acessar o facebook">
          <input matInput type="text" [(ngModel)]="fbHook.accessToken" placeholder="Token de acesso">
        </mat-form-field>
      </mat-list-item>
      <mat-list-item>
        <mat-form-field appearance="outline" hintLabel="Token de verificação cadastrar o webhook">
          <input matInput type="text" [(ngModel)]="fbHook.verifyToken" placeholder="Token de verificação">
        </mat-form-field>
      </mat-list-item>
      <mat-divider></mat-divider>

      <h3 mat-list-item mat-subheader>
        Suporte para mídia
      </h3>
      <mat-list-item>
        <mat-checkbox [(ngModel)]="fbHook.hasAudio">Áudio</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [(ngModel)]="fbHook.hasImage">Imagem</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [(ngModel)]="fbHook.hasText">Texto</mat-checkbox>
      </mat-list-item>
      <mat-list-item>
        <mat-checkbox [(ngModel)]="fbHook.hasVideo">Vídeo</mat-checkbox>
      </mat-list-item>
      <mat-divider></mat-divider>

      <h3 mat-list-item mat-subheader>Ações</h3>
      <mat-list-item>
        <button mat-raised-button class="last" type="button" (click)="updateHook()">Atualizar Canal</button>
        <a mat-raised-button class="last" [routerLink]="['/bots', bot.id, 'channels', fbHook.id, 'media']">
          Medias Recebidas
        </a>
      </mat-list-item>
    </mat-list>

  </div>
</div>