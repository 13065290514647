import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthenticationService, Tickets, TicketsService, TicketType, TTSHistory, TTSService, TTSTicketID, Voice } from 'onevoice';

@Component({
  selector: 'alabia-tts',
  templateUrl: './tts.component.html',
  styleUrls: ['./tts.component.scss']
})
export class TtsComponent implements OnInit {
  private audio: HTMLAudioElement = new Audio();

  public datasource: TTSHistory[] = [];

  public generate: string = "";
  public generating: boolean = false;
  public icon: string = "speaker";
  public INTERACTIVE_DELETE: boolean = true;
  public panelOpenState: boolean = false;
  public storePanelState: boolean = false;
  public storePanelWidth: number = 8;
  public tickets: Tickets[] = [];
  public view: boolean = true;
  public voiceModel?: Tickets;
  public voices: Voice[] = [];



  constructor(private auth: AuthenticationService,
    private service: TTSService,
    private serviceTickets: TicketsService,
    private snack: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.auth.systemToken().then(token => {
      this.loadHistory();
      this.loadWidth();

      this.serviceTickets.list().subscribe(tickets => {
        this.tickets = tickets.filter(value => value.type == TicketType.TTS);
        if (tickets.length > 0) {
          this.voiceModel = tickets[0];
        }
      });

      this.serviceTickets.voices().subscribe(voices => {
        this.voices = voices;
      });
    });
  }

  loadHistory(): void {
    this.service.list().subscribe(history => {
      this.datasource = history.sort((a: TTSHistory, b: TTSHistory) => b.id - a.id);
    });
  }

  loadWidth(): void {
    var table = document.getElementById("table");
    var width = table?.getBoundingClientRect().width;
    if (width != null && width < 500) {
      var idPaginator = document.getElementById("idPaginator");
      width = 500;
      if (idPaginator && table) {
        idPaginator.style.width = width?.toFixed(0).toString() + "px";
        table.style.width = width?.toFixed(0).toString() + "px";
      }
    }
  }

  generateAudio(): void {
    if (this.generating) {
      return;
    }

    this.generating = true;
    if (this.voiceModel) {
      // this.service.generate(this.voiceModel, this.generate).subscribe(data => {
      this.service.generateVoice((this.voiceModel.id as TTSTicketID).voice, this.generate).subscribe(data => {
        this.generating = false;

        let reader = new FileReader();
        reader.addEventListener("loadend", (ev) => {
          if (typeof reader.result === "string") {
            this.audio.src = reader.result;
            this.audio.play();
          }
        });
        reader.readAsDataURL(data);
        this.generate = "";
      }, err => {
        this.generating = false;
        if (err.status == 429) {
          this.snack.open("A sua franquia de geração de áudio não é suficiente.", "OK");
        } else {
          this.snack.open("Aconteceu um erro durante a geração do áudio. Tente novamente.", "OK");
        }
      });
    }
  }


  public delay(ms: number): Promise<unknown> {
    return new Promise((resolve) => setInterval(resolve, ms));
  }

  public changeView(): void {
    this.view = !this.view;
  }

  public changeVoice(voice: Tickets): void {
    this.voiceModel = voice;
  }

  public changePanelWidth(): void {
    this.storePanelState ? this.storePanelWidth = 100 : this.storePanelWidth = 8;
  }

  public getVoice(ticket: Tickets): Voice {
    return (ticket.id as TTSTicketID).voice
  }
}
