<div id="alabia-tts-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">TTS</h1><br>
  </div>

  <!-- voices -->
  <div class="content">
    <div class="voice-panel center p-24 pb-2" fusePerfectScrollbar>
      <mat-accordion>
        <mat-expansion-panel [(expanded)]="panelOpenState">
          <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
            <mat-card-title style="color: rgba(0, 0, 0, 0.54);">
              <h2>Vozes</h2>
            </mat-card-title>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>

          <ng-template matExpansionPanelContent>
            <ul>
              <li *ngFor="let ticket of tickets;">
                <mat-checkbox [checked]="voiceModel?.id == ticket.id"
                  (change)="voiceModel = ticket; panelOpenState = !panelOpenState;">
                  <mat-card-title class="mat-tipography">
                    <h3>{{ getVoice(ticket).title }}</h3>
                  </mat-card-title>
                </mat-checkbox>
              </li>
            </ul>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>

      <!-- Store -->
      <mat-accordion class="voice-store" [style.width.vw]="storePanelWidth" (click)="changePanelWidth()">
        <mat-expansion-panel [(expanded)]="storePanelState">
          <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
            <mat-card-title style="color: rgba(0, 0, 0, 0.54);">
              <h2>
                <mat-icon>shopping_cart</mat-icon><span *ngIf="storePanelState" style="padding-left: 32px;">Loja</span>
              </h2>
            </mat-card-title>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>
          <ng-template matExpansionPanelContent>
            <ul>
              <li *ngFor="let voice of voices;" class="mat-body-1">
                {{voice.title}}
              </li>
            </ul>
          </ng-template>
          <mat-action-row>
            <a mat-raised-button color="accent" type="button" href="https://loja.alabia.com.br/"
              target="_blank">Loja</a>
          </mat-action-row>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- Generate Áudio -->
  <tts-create [voice]="voiceModel" (eventEmitter)="changeVoice($event)"></tts-create>

  <!-- TTS Historic -->
  <div class="content ml-6 mr-6">
    <div class="center p-24 pt-0 pb-56" fusePerfectScrollbar>
      <tts-list (eventEmitter)="changeView()" [data]="datasource" *ngIf="view"></tts-list>
      <tts-cards (eventEmitter)="changeView()" [data]="datasource" *ngIf="!view"></tts-cards>
    </div>
  </div>
</div>