import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiConfiguration, APP_CONFIG } from "./api-configuration";


export interface GenericModel<ID> {
  id: ID;
}

@Injectable({
  providedIn: 'root'
})
export abstract class GenericService<ID, Model extends GenericModel<ID>> {
  protected abstract path: string;

  protected JSON_HEADER: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(filter: { [key: string]: string } = {}): Observable<Model[]> {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(filter)) {
      console.log("Logging the filters", [key, value]);
      params = params.append(key, value);
    }

    return this.http.get<Model[]>(`${this.path}`, {
      responseType: "json",
      observe: "body",
      params: params,
    });
  }

  public create(data: Partial<Model>): Observable<Model> {
    return this.http.post<Model>(`${this.path}`, data, {
      responseType: "json",
      observe: "body",

      headers: this.JSON_HEADER,
    });
  }

  public read(id: ID): Observable<Model> {
    return this.http.get<Model>(`${this.path}/${id}`, {
      responseType: "json",
      observe: "body",
    });
  }

  public update(data: Partial<Model>): Observable<Model> {
    return this.http.post<Model>(`${this.path}/${data.id}`, data, {
      responseType: "json",
      observe: "body",

      headers: this.JSON_HEADER,
    });
  }

  public delete(id: ID): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.path}/${id}`);
  }
}
