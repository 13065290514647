import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Bot } from 'onevoice';
import { ClassifierTrainerService, TrainerHistory, TrainerParams } from 'app/alabia/api/classifier-trainer.service';
import { Observable, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'alabia-treinador-content',
  templateUrl: './treinador-content.component.html',
  styleUrls: ['./treinador-content.component.scss']
})
export class TreinadorContentComponent implements OnInit, OnChanges {


  public advanced: boolean = false;
  public training: boolean = true;
  public history: TrainerHistory[] = [];
  public timer: Observable<any>;
  public status: string = '';
  @Input() botData?: Bot;

  params: TrainerParams = {
    epochs: 40,
    dropout: 0.5,
    recurrent_dropout: 0.5,
    num_layers: 1
  };

  constructor(
    private _service: ClassifierTrainerService,
    public snackBar: MatSnackBar
  ) {
    this.timer = timer(0, 10000);
  }

  ngOnChanges() {
    if (this.botData) {
      this.trainer();
    }
  }

  ngOnInit() {

  }

  updateHistory() {
    this._service.history(this.botData?.id || 0).subscribe(history => {
      this.history = history.reverse();
    });
  }

  start() {
    this._service.start(this.botData?.id || 0, this.params).subscribe(answ => {
      this.training = true;
      this.snackBar.open('Treinamento iniciado!', 'OK', {
        duration: 1000
      });
    });
  }

  trainer() {
    this.timer
      .pipe(concatMap(_ => this._service.status(this.botData?.id || 0)))
      .subscribe(value => {
        this.training = value.status == this._service.RUNNING;
        if (value.status == this._service.DONE) {
          this.snackBar.open(
            'Seu treinamento foi realisado com sucesso.',
            'OK'
          );
          this.updateHistory();
        }
        if (value.status != this.status) {
          this.updateHistory();
          this.status = value.status;
        }
      });
  }

}
