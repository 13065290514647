import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { CorpusIntentService } from 'app/alabia/api/corpus-intent.service';
import { IntentDialogComponent } from 'app/alabia/bot/dialogs/intent-dialog/intent-dialog.component';
import { Bot, BotService, Intent, IntentService } from 'onevoice';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'alabia-bot-detail',
    templateUrl: './bot-detail.component.html',
    styleUrls: ['./bot-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class BotDetailComponent implements OnInit, AfterViewInit {
    bot?: Bot;
    intents: Intent[] = [];
    onIntentsChanged: BehaviorSubject<any>;
    selected?: Intent;
    selectedClear: Subject<boolean> = new Subject<boolean>();

    public dataSource: MatTableDataSource<Intent>;
    public displayedColumns = ['name', 'quantity', 'active'];

    @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort?: MatSort;
    @ViewChild('filter', { static: true }) filter?: ElementRef;
    private _unsubscribeAll: Subject<any>;

    public COUNTER: { [intent: number]: number } = {};

    constructor(
        private route: ActivatedRoute,
        private _corpusIntentService: CorpusIntentService,
        private _intentService: IntentService,
        public dialog: MatDialog,
        private _botService: BotService,

    ) {
        this.selectedClear.subscribe(() => this.selected = undefined);
        this._unsubscribeAll = new Subject();
        this.onIntentsChanged = new BehaviorSubject({});

        this.dataSource = new MatTableDataSource();
        this.dataSource.filterPredicate = (data, filter): boolean => {
            if (data.name) {
                return data.name.toLowerCase().includes(filter.toLowerCase());
            }
            return false;
        };
    }
    public ngAfterViewInit(): void {
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        if (this.sort) {
            this.dataSource.sort = this.sort;
        }
    }

    public ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const botid = Number(params.get('id'));

            this._botService.read(botid).subscribe(bot => {
                this.bot = bot;

                this._intentService.list(bot.id).subscribe((intents: Intent[]) => {
                    this.intents = intents;
                    this._corpusIntentService.list(bot.id).subscribe(allIntents => {
                        for (const intent of intents) {
                            this.COUNTER[intent.id] = allIntents.find(i => i.name === intent.name)?.counter || 0;
                        }

                        if (params.has('intent')) {
                            const intentId = Number(params.get('intent'));
                            this.selected = this.intents.find(intent => intent.id === intentId);
                        }

                        this.onIntentsChanged.next(this.intents);
                        this.dataSource.data = this.intents;
                    }, err => {
                        this.intents = intents.map(value => {
                            return value;
                        });

                        if (params.has('intent')) {
                            const intentId = Number(params.get('intent'));
                            this.selected = this.intents.find(intent => intent.id === intentId);
                        }

                        console.error(err);
                        this.onIntentsChanged.next(this.intents);
                        this.dataSource.data = this.intents;
                    });
                });
            });
        });

        if (!this.filter) { return; }
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (this.filter) {
                    this.dataSource.filter = this.filter.nativeElement.value;
                }
            });
    }

    public createIntent(): void {
        if (!this.bot) {
            return;
        }
        const reference = this.dialog.open<
            IntentDialogComponent,
            Partial<Intent>,
            Partial<Intent>
        >(IntentDialogComponent, {
            width: '500px',
            maxWidth: '95%',
            data: {
                name: 'NOVA INTENÇÃO',
                description: '',

                maxFallbacks: 2,
                lifespan: 10000,

                bot: this.bot
            }
        });

        reference.afterClosed().subscribe(intent => {
            if (!intent) {
                return;
            }
            this._intentService.create(intent).subscribe(created => {
                this.selected = created;
                this.intents.push(created);
            });
        });
    }

    public intentDeleted(intent: Intent): void {
        this.intents = this.intents.filter(value => value.id !== intent.id);
        this.selected = undefined;
    }
}
