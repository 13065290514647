import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

@Component({
  selector: 'alabia-signup-step1',
  templateUrl: './signup-step1.component.html',
  styleUrls: ['./signup-step1.component.scss', '../signup-step.shared.scss'],
  animations: [
    trigger('formAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateX(100%)', opacity: '0%' }))
      ])
    ]),
    trigger('infoAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('700ms ease', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateY(100%)', opacity: '0%' }))
      ])
    ]),
  ],
})
export class SignupStep1Component implements OnInit {

  public isShowing: boolean = true;
  public hidePassword: boolean = true;
  public hideConfirmPassword: boolean = true;
  public firstStepSignup?: FormGroup;
  @Output() nextScreen = new EventEmitter();
  @Output() stepData = new EventEmitter();
  @Input() returnData: any;

  constructor() { }

  ngOnInit() {
    this.firstStepSignup = this.firstStepForm();
  }

  public screenOutputHandler(currentScreen: any, step: any) {
    if (currentScreen === 0 && step !== 'backward') {
      this.isShowing = !this.isShowing;
      setTimeout(() => {
        this.nextScreen.emit({ currentScreen, step });
      }, 700);
    }
  }

  firstStepForm(): FormGroup {
    return new FormGroup({
      organization: new FormControl(this.returnData['organization']),
      email: new FormControl(this.returnData['email']),
      password: new FormControl(this.returnData['password']),
      confirmPassword: new FormControl(this.returnData['confirmPassword']),
    }, { validators: passwordMatchValidator });
  }

  onSubmit(formData: any) {
    if (formData.valid) {
      this.stepData.emit({ step: 0, signupData: formData.value });
      this.screenOutputHandler(0, 'forward');
    }
  }

  onInputPass() {
    if (this.firstStepSignup) {
      let control = this.firstStepSignup
        .get('confirmPassword')
      if (!control) { return; }

      control.setErrors(this.firstStepSignup.hasError('passwordMismatch') ? [{ 'passwordMismatch': true }] : null);
    }
  }
}

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let password = control.get('password'),
    confirm = control.get('confirmPassword');
  if (password && confirm && password.value === confirm.value) {
    return null
  }
  return { passwordMismatch: true };
}
