import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[normalizeText]'
})
export class NormalizeTextDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z|-]+)/g, '')
      .toLocaleLowerCase();
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
