import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ReportService,
} from 'onevoice';

@Injectable({
  providedIn: 'root'
})
export class ReportApiRequestService {
  latestCustomersReport: any;
  onLastestCustomerChanges: BehaviorSubject<any>;

  constructor(
    private _reportService: ReportService,
  ) {
    this.onLastestCustomerChanges = new BehaviorSubject([]);
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.listLatestCustomers(),
      ]).then(([lastCustomers]) => {
        this.latestCustomersReport = lastCustomers;
        resolve(lastCustomers);
      }, reject);
    });
  }

  /* LISTS */
  listLatestCustomers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._reportService.latestCustomers().subscribe((response) => {
        this.latestCustomersReport = response;

        this.onLastestCustomerChanges.next(this.latestCustomersReport);
        resolve(this.latestCustomersReport);
      }, reject);
    });
  }
  /* /LISTS */
}