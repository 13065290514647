import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { VoiceBase } from './voice.model';


@Injectable({
  providedIn: 'root'
})
export class VoiceBaseService {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(): Observable<VoiceBase[]> {
    return this.http.get<VoiceBase[]>(`${this.config.mediaservice}/voice-base`);
  }
}
