<mat-card *ngIf="response">
  <mat-card-header>
    <img mat-card-avatar [src]="responseImage(response.type)">
    <mat-card-title>{{ response.type }}</mat-card-title>
    <mat-card-subtitle>Sequencia de execução: {{ response.order }}</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image *ngIf="response.type === ResponseType.MEDIA && response.mediaType == MediaType.IMAGE" [src]="response.content">
  <video mat-card-image *ngIf="response.type === ResponseType.MEDIA && response.mediaType == MediaType.VIDEO" [src]="response.content"></video>
  <mat-card-content *ngIf="response.type !== ResponseType.MEDIA">
    <div #templateElement>{{ response.content }}</div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-icon-button (click)="changeResponseOrder(response, -1)">
      <mat-icon>expand_less</mat-icon>
    </button>
    <button mat-icon-button (click)="changeResponseOrder(response, 1)">
      <mat-icon>expand_more</mat-icon>
    </button>
    <button mat-button (click)="editResponse()">Editar</button>
    <button mat-button (click)="deleteResponse()">Delete</button>
  </mat-card-actions>
</mat-card>