import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, Mood, MoodService, Voice, VoiceModel, VoiceService, VoiceSnippet, VoiceSnippetService } from 'onevoice';
import { VoiceModelDialogComponent } from '../voice-model-dialog/voice-model-dialog.component';
import { VoiceWizardComponent, Wizard } from '../voice-wizard/voice-wizard.component';

@Component({
  selector: 'alabia-voice-snippet',
  templateUrl: './voice-snippet.component.html',
  styleUrls: ['./voice-snippet.component.scss']
})
export class VoiceSnippetComponent implements OnInit {
  public icon: string = "settings_voice";
  public editable: boolean = false;

  public columnsToDisplay: string[] = [
    "text",
    "created",
    "play",
    "delete",
  ]

  public datasource = new MatTableDataSource<VoiceSnippet>([]);

  @ViewChild(MatPaginator, { static: false })
  set setPaginator(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }

  private voice?: Voice;
  public files?: FileList;

  public mood?: Mood;
  public text: string = "";
  public moodList: Mood[] = [];

  public audio: HTMLAudioElement = new Audio();

  constructor(
    private route: ActivatedRoute,
    private voices: VoiceService,
    private snippets: VoiceSnippetService,
    private moods: MoodService,
    private auth: AuthenticationService,

    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(map => {
      const pk = Number(map.get("id"));

      this.voices.read(pk).subscribe(voice => {
        this.voice = voice;
      });

      this.moods.list().subscribe(moods => {
        this.moodList = moods;
        this.mood = moods[0];
      })

      this.auth.systemToken().then(_ => {
        console.log("Requested token");

        this.snippets.list(pk).subscribe(voices => {
          this.datasource.data = voices.sort((a, b) => b.id - a.id);
        });
      });
    });
  }

  get title(): string {
    if (this.voice) {
      return `Voz: ${this.voice.title}`
    }
    return "Voz";
  }


  selectFile(event: any) {
    this.files = event.target.files;
  }

  submitAudio() {
    if (!this.files) {
      console.log("No files")
      return;
    }
    for (let index = 0; index < this.files.length; index++) {
      let file = this.files[index];
      console.log(file);

      if (this.voice && this.mood) {
        this.snippets.create(this.voice.id, this.mood.id, this.text, file).subscribe(created => {
          this.datasource.data = this.datasource.data.concat([created]);
        });
      }
    }
    this.files = undefined;
    this.text = "";
  }

  public playAudio(snippet: VoiceSnippet) {
    this.audio.pause()
    this.audio.src = this.auth.authMediaUrl(this.snippets.snippetMediaUrl(snippet))
    this.audio.play();

  }

  openWizard() {
    if (this.mood && this.voice) {
      let reference = this.dialog.open<VoiceWizardComponent, Wizard, Boolean>(VoiceWizardComponent, {
        width: '500px',
        maxWidth: '95%',
        data: {
          mood: this.mood,
          voice: this.voice,
        },
      });

      reference.afterClosed().subscribe((_: any) => {
      });
    }
  }

  public deleteItem(item: VoiceSnippet) {
    this.snippets.delete(item).subscribe(ok => {
      this.datasource.data = this.datasource.data.filter(removed => removed.id !== item.id);
    });
  }

  public editItem(item: VoiceSnippet) {
    this.snippets.update(item.id, item.text).subscribe(item => {
      this.snack.open("Áudio atualizado com sucesso.", "OK", {
        duration: 2000,
      });
    }, (err) => this.snack.open("Erro durante a atualização da transcrição", "OK"));
  }

  public playItem(item: VoiceSnippet) {
    this.audio.pause()
    this.audio.src = this.auth.authMediaUrl(this.snippets.snippetMediaUrl(item))
    this.audio.play();
  }

  public openModelDialog() {
    if (!this.voice) {
      return;
    }
    let reference = this.dialog.open<VoiceModelDialogComponent, Voice, VoiceModel>(VoiceModelDialogComponent, {
      data: this.voice,
      width: "700px",
    });

    reference.afterClosed().subscribe(model => {
      if (model) {
        this.snack.open("Seu modelo será treinado", "OK");
      }
    })
  }
}
