import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Room } from 'onevoice';

@Component({
  selector: 'main-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrls: ['./room-edit.component.scss']
})
export class RoomEditComponent {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<Room>,
    public reference: MatDialogRef<RoomEditComponent>,
  ) { 
    this.form = new FormGroup({
      "name": new FormControl(data.name || "", [Validators.required]),
    }, []);
  }

  get isEdit() {
    return this.data.id !== undefined;
  }

  public ngSubmit() {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    });
  }
}
