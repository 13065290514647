import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardListService, Graph } from 'onevoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'main-addressable-dashboard',
  templateUrl: './addressable-dashboard.component.html',
  styleUrls: ['./addressable-dashboard.component.scss']
})
export class AddressableDashboardComponent implements OnInit, OnDestroy {
  private _unsubscribe: Subject<void> = new Subject<void>();
  public graphList: { [name: string]: Graph[] } = {};
  public selected?: string;

  constructor(private route: ActivatedRoute, public service: DashboardListService) { }

  public ngOnInit(): void {
    this.route.params.pipe(takeUntil(this._unsubscribe)).subscribe(param => {
      this.selected = param.name;
    });
    this.service.list().subscribe(list => {
      for (const graph of list) {
        if (this.graphList[graph.dashboard] === undefined) {
          this.graphList[graph.dashboard] = [];
        }
        this.graphList[graph.dashboard].push(graph);
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
