<div class="page-layout carded fullwidth inner-scroll">
    <div id="voice-splitter">
        <mat-stepper linear #stepper>
            <mat-step [stepControl]="uploadForm">
                <ng-template matStepLabel>Upload</ng-template>
                <ng-template matStepContent>
                    <form [formGroup]="uploadForm" (ngSubmit)="submitUpload(stepper)">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Arquivo</mat-label>
                            <oneui-file-input matInput formControlName="upload"></oneui-file-input>
                            <mat-hint>
                                Envie um arquivo wav de alta qualidade, com todas as frases ditas com um pequeno
                                intervalo de alguns segundos entre elas.
                            </mat-hint>
                        </mat-form-field>

                        <mat-form-field *ngIf="moodList" appearance="outline">
                            <mat-label>Humor</mat-label>
                            <mat-select formControlName="mood">
                                <mat-option *ngFor="let mood of moodList" [value]="mood.id">
                                    {{ mood.name }}
                                </mat-option>
                            </mat-select>
                            <mat-hint>Escolha a opção que melhor reflete o humor da voz</mat-hint>
                        </mat-form-field>

                        <button type="submit" mat-raised-button [disabled]="!uploadForm.valid || working.value">
                            Enviar
                            <mat-spinner *ngIf="working.value" diameter="16"></mat-spinner>
                        </button>
                    </form>
                </ng-template>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Snippets</ng-template>
                <ng-template matStepContent>
                    <div class="alabia-spinner-wrapper" *ngIf="snippetArray.length <= 0">
                        <mat-spinner class="alabia-view-spinner"></mat-spinner>
                    </div>
                    <form [formGroup]="updateForm" (ngSubmit)="finishStepper(stepper)">
                        <div formArrayName="snippets">

                            <div *ngFor="let snip of snippetArray; let index = index" [formGroupName]="index">
                                <mat-form-field appearance="outline">
                                    <mat-label>Transcrição</mat-label>
                                    <input matInput type="text" formControlName="text"
                                        (change)="updateTranscription(index)" (focus)="playAudio(index)"
                                        (paste)="onPaste(index, $event)">
                                    <mat-icon matPrefix (click)="playAudio(index)">
                                        play_circle_outline
                                    </mat-icon>
                                    <mat-icon matSuffix (click)="removeAudio(index)">
                                        remove
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <button type="submit" mat-raised-button [disabled]="!updateForm.valid">
                            Finalizar
                        </button>
                    </form>
                </ng-template>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Finalizado</ng-template>
                <ng-template matStepContent>
                    <p>Todos os dados foram enviados e estão salvos.</p>
                </ng-template>
            </mat-step>
        </mat-stepper>
    </div>
</div>