<div class="page-layout blank dashboard">
  <div class="detailed-dashboard">
    <div class="search-form">
      <mat-form-field class="search-field" appearance="outline">
        <mat-label>Data inicial</mat-label>
        <input
          matInput
          name="from"
          type="date"
          [(ngModel)]="from"
          (change)="updateAssignments()"
        />
      </mat-form-field>
      <mat-form-field class="search-field" appearance="outline">
        <mat-label>Data final</mat-label>
        <input
          matInput
          name="to"
          type="date"
          [(ngModel)]="to"
          (change)="updateAssignments()"
        />
      </mat-form-field>
      <mat-form-field
        class="search-field"
        appearance="outline"
        *ngIf="userSelectable"
      >
        <mat-label>Todos os Agentes</mat-label>
        <mat-select [(value)]="agent" (selectionChange)="updateAssignments()">
          <mat-option>Todos os agentes</mat-option>
          <mat-option *ngFor="let agent of userList" [value]="agent">{{
            agent.email
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="search-field" appearance="outline">
        <mat-label>Todos os bots</mat-label>
        <mat-select [(value)]="bot" (selectionChange)="updateAssignments()">
          <mat-option [value]="undefined">Todos os bots</mat-option>
          <mat-option *ngFor="let bot of botList" [value]="bot">{{
            bot.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="search-field" appearance="outline">
        <mat-label>Todas as tags</mat-label>

        <mat-select
          [(value)]="tag"
          multiple
          (selectionChange)="updateAssignments()"
        >
          <mat-option *ngFor="let tag of tagList" [value]="tag">
            {{ tag.title }} - {{ tag.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="search-field" appearance="outline">
        <mat-select
          [(value)]="timestep"
          (selectionChange)="updateAssignments()"
        >
          <mat-option *ngFor="let ts of timestepList" [value]="ts[0]">{{
            ts[1]
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="total-assignment">
      <div class="h3 secondary-text">Abertos</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="openedAssignmentsData"
        [labels]="openedAssignmentsLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="total-wip">
      <div class="h3 secondary-text">Em Atendimento</div>

      <div class="font-size-54 font-weight-300 line-height-1 mt-8">
        {{ wip.length }}
      </div>
    </div>

    <div class="total-done">
      <div class="h3 secondary-text">Encerrados</div>

      <div class="font-size-54 font-weight-300 line-height-1 mt-8">
        {{ done.length }}
      </div>
    </div>

    <div class="avg-time">
      <div class="h3 secondary-text">Tempo de Resposta</div>

      <div class="font-size-54 font-weight-300 line-height-1 mt-8">
        {{ displayTime(avgTime) }}
      </div>
    </div>

    <div class="ratio-bot">
      <div class="h3 secondary-text">Razão Robo / Agent</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [datasets]="ratioData"
        [labels]="ratioLabels"
        [options]="lineChartConfig"
        chartType="line"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="answered-line">
      <div class="h3 secondary-text">Chamados atendidos</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="answeredAssignmentsData"
        [labels]="answeredAssignmentsLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="combined-line">
      <div class="h3 secondary-text">Chamados abertos vs Atendidos</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [datasets]="combinedAssignments"
        [labels]="answeredAssignmentsLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="sla-line">
      <div class="h3 secondary-text">SLA</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="slaData"
        [labels]="slaLabels"
        [options]="hourChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="sla-bar">
      <div class="h3 secondary-text">SLA (percentil)</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="slaPerData"
        [labels]="slaPerLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="tag-line">
      <div class="h3 secondary-text">Tags</div>

      <canvas
        baseChart
        [colors]="appColors"
        [data]="tagData"
        [labels]="tagLabels"
        [options]="doughnutChartConfig"
        chartType="doughnut"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="tag-bar">
      <div class="h3 secondary-text">Tags</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="tagData"
        [labels]="tagLabels"
        [options]="lineChartConfig"
        chartType="horizontalBar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="first-answer">
      <div class="h3 secondary-text">Primeira resposta</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="firstResponseData"
        [labels]="firstResponseLabels"
        [options]="hourChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="hourly-data">
      <div class="h3 secondary-text">Chamados abertos por hora</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="hourlyData"
        [labels]="hourlyLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="peak-data">
      <div class="h3 secondary-text">Atividade por hora</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="activityPeakData"
        [labels]="activityPeakLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="evaluation-data">
      <div class="h3 secondary-text">Avaliações dos Agentes</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="evaluationData"
        [labels]="evaluationLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>

    <div class="evaluation-bot-data">
      <div class="h3 secondary-text">Avaliações do Robo</div>

      <canvas
        baseChart
        [colors]="singleColor"
        [data]="evaluationBotData"
        [labels]="evaluationBotLabels"
        [options]="lineChartConfig"
        chartType="bar"
        [plugins]="showLabels"
      ></canvas>
    </div>
  </div>
</div>
