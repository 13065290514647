import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Asset, AssetType, AssetTypeService } from 'onevoice';

@Component({
  selector: 'main-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.scss']
})
export class AssetEditComponent {
  public form: FormGroup;
  public typeList?: AssetType[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<Asset>,
    public reference: MatDialogRef<AssetEditComponent>,

    private types: AssetTypeService,
  ) {
    this.form = new FormGroup({
      "title": new FormControl(data.title || "", [Validators.required]),
      "description": new FormControl(data.description || "", [Validators.required]),
      "type": new FormControl(data.type || undefined, [Validators.required]),
    }, []);

    this.types.list().subscribe(typeList => {
      this.typeList = typeList;
    })
  }

  public ngSubmit() {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  public compareWith(o1: AssetType, o2: AssetType): boolean {
    return o1.id == o2.id;
  }

  get isEdit() {
    return this.data.id !== undefined;
  }
}
