<!-- SIDENAV HEADER -->
<div class="sidenav-header">
  <!-- CHATS TOOLBAR -->
  <mat-toolbar>
    <!-- TOOLBAR BOTTOM -->
    <mat-toolbar-row>
      <!-- SEARCH -->
      <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="s-16 secondary-text">search</mat-icon>
          <input [(ngModel)]="searchText" type="text" placeholder="Search or start new chat" fxFlex>
          <mat-icon class="s-16 secondary-text" (click)="toggleSorting()">filter_list</mat-icon>
        </div>
      </div>
      <!-- / SEARCH -->

    </mat-toolbar-row>
    <!-- / TOOLBAR BOTTOM -->

    <mat-toolbar-row>
      <mat-form-field>
        <mat-label>Robô</mat-label>
        <mat-select [(ngModel)]="selectedBots" multiple>
          <mat-option *ngFor="let bot of bots" [value]="bot">{{ bot.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-toolbar-row>

  </mat-toolbar>
  <!-- / CHATS TOOLBAR -->

</div>
<!-- / SIDENAV HEADER -->

<!-- SIDENAV CONTENT -->
<div class="sidenav-content">

  <!-- CHATS CONTENT -->
  <div *fuseIfOnDom [@animateStagger]="{value:'50'}" style="height: 100%;">

    <!-- CHATS LIST-->
    <div class="chat-list" fxLayout="column" style="height: 100%;">

      <div class="queue-list">
        <alabia-chat-item *ngFor="let chat of filteredChats | chatFilter: searchText" [selectedChat]="chat === selected"
          [chat]="chat" (selected)="getChat(chat)">
        </alabia-chat-item>

        <div class="load-button">
          <button mat-button type="button" *ngIf="agent.outstanding > 0" (click)="loadAssignments()"
            [matBadge]="agent.outstanding">
            Carregar
          </button>
        </div>
      </div>

    </div>
    <!-- / CHATS LIST-->
  </div>
  <!-- / CHATS CONTENT -->

</div>
<!-- / SIDENAV CONTENT -->