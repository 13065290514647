<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>Propriedades da Ação</h1>

    <mat-dialog-content>
        <div *ngIf="properties ; else spinner">
            <table mat-table [dataSource]="datasource">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let property">{{ property.name }}</td>
                    <td mat-footer-cell *matFooterCellDef>
                        <input matInput type="text" formControlName="name" placeholder="nome">
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Valor</th>
                    <td mat-cell *matCellDef="let property">{{ property.value }}</td>
                    <td mat-footer-cell *matFooterCellDef>
                        <input matInput type="text" formControlName="value" placeholder="valor">
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Ações</th>
                    <td mat-cell *matCellDef="let property">
                        <button mat-icon-button type="button" (click)="remove(property)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <button mat-icon-button type="submit" [disabled]="form.invalid">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>
                <tr mat-footer-row *matFooterRowDef="columns"></tr>
            </table>
        </div>
        <ng-template #spinner>
            <mat-spinner class="alabia-view-spinner"></mat-spinner>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Voltar</button>
    </mat-dialog-actions>
</form>