import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MachineTask, MachineTaskService } from 'onevoice';
import { EditComponent } from '../edit/edit.component';

@Component({
  selector: 'main-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public taskList: MatTableDataSource<MachineTask> = new MatTableDataSource<MachineTask>();
  public fields: string[] = [
    "name",
    "map",
    "actions",
  ];

  constructor(
    public tasks: MachineTaskService,
    public dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.tasks.listManual().subscribe(taskList => {
      this.taskList.data = taskList.filter(task => task.type == "MANUAL");
      this.taskList.data = this.taskList.data.sort((a, b) => b.id - a.id);
      console.log(this.taskList.data)
    });
  }

  @ViewChild(MatPaginator)
  set paginator(pager: MatPaginator) {
    this.taskList.paginator = pager;
  }



  public editTask(data: Partial<MachineTask> = {}): void {
    let reference = this.dialog.open<EditComponent, Partial<MachineTask>, MachineTask>(EditComponent, {
      width: "500px",
      maxWidth: "90%",
      data: data,
    });

    reference.afterClosed().subscribe(task => {
      if (!task) {
        return;
      }

      if (task.id) {
        this.tasks.update(task).subscribe(task => this.addTaskToList(task));
      } else {
        this.tasks.create(task).subscribe(task => this.addTaskToList(task));
      }
    });
  }

  public addTaskToList(task: MachineTask): void {
    if (this.taskList) {
      let updated = this.taskList.data.filter(value => value.id != task.id);
      updated.push(task);
      updated.sort((a, b) => b.id - a.id);
      this.taskList.data = updated;
    }
  }
}
