<div id="subscription" class="page-layout simple left-sidebar inner-sidebar inner-scroll">
  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- APP TITLE -->
    <div fxLayout="row" fxLayoutAlign="start center">

      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16">featured_play_list
        </mat-icon>
        <span class="logo-text h1">
          Seleção de Planos
        </span>
      </div>
    </div>
    <!-- / APP TITLE -->
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <!-- CENTER -->
    <div class="center p-24 pb-56" fusePerfectScrollbar>

      <!-- CONTENT -->
      <div class="content">


        <div class="subscription-content">


          <mat-icon class="icon arrow_left" (click)="scroll(scrollDirection.LEFT)" *ngIf="showNavigation">
            keyboard_arrow_left
          </mat-icon>

          <div class="plan-carousel-container">
            <div #planCarouselBox class="plan-carousel">
              <div class="plan-carousel-card"
                *ngFor="let plan of plans; let first = first; let last = last; let i = index"
                [ngClass]="{first: first, last: last}">
                <h4 class="plan-carousel-card--title">{{ plan.title }}</h4>

                <h2 class="plan-carousel-card--price">
                  {{ plan.price === 0 ? 'Grátis' : plan.price / 100 | currency: 'BRL':'symbol':'1.2-2':'pt'}}
                </h2>
                <p class="plan-carousel-card--payment-ratio">
                  Por mês
                </p>

                <div *ngIf="currentUserData" class="plan-carousel-card--button">
                  <button mat-raised-button *ngIf="currentUserData.plan.id !== plan.id">Escolher</button>
                  <p *ngIf="currentUserData.plan.id === plan.id">Plano Atual
                    <br>
                    <span class="current-plan-end">
                      Válido até {{ currentUserData.end | date:'shortDate' }}
                    </span>
                  <p>
                </div>

                <p class="plan-carousel-card--description">
                  {{ plan.description }}
                </p>
              </div>
            </div>
          </div>

          <mat-icon class="icon arrow_right" (click)="scroll(scrollDirection.RIGHT)" *ngIf="showNavigation">
            keyboard_arrow_right
          </mat-icon>

        </div>
      </div>

      <!-- /CONTENT -->

    </div>
    <!-- / CENTER -->
  </div>
  <!-- / CONTENT-->
</div>