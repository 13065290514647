import { Injectable } from '@angular/core';
import { Related, RelatedService } from '../auth/related.service';
import { InformationType } from './information-type.service';

export interface CustomerInformation extends Related {
  information: InformationType;
  content: string;
}


@Injectable({
  providedIn: 'root'
})
export class CustomerInformationService extends RelatedService<CustomerInformation> {
  protected path: string = `${this.config.endpoint}/customer/{related}/information`;
}
