import { Injectable } from '@angular/core';
import { Channel } from './channel.model';
import { ChannelService } from './channel.service';

export enum Security {
  NONE = "NONE",
  SSL = "SSL",
  TLS = "TLS",
}

export enum AuthMethod {
  LOGIN = "LOGIN",
  XOAUTH = "XOAUTH",
}

export enum ReceiveMethod {
  POLLING = "POLLING",
  SMTP = "SMTP",
}


export interface EmailChannel extends Channel {
  from: string;
  username: string;
  password: string;
  method: AuthMethod;
  receiveMethod: ReceiveMethod;

  smtpServer: string;
  smtpPort: number;
  smtpSecurity: Security;

  imapServer: string;
  imapPort: number;
  imapSecurity: Security;
}

@Injectable({
  providedIn: 'root'
})
export class EmailChannelService extends ChannelService<EmailChannel> {
  protected path: string = `${this.config.endpoint}/email`;
}
