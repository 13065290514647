import { Component, OnInit } from '@angular/core';

export interface Member {
  numero: number;
  nome: string;
  mapa: string;
  tarefa: string;
  status: boolean;
  data: string;
  member: string;
  fotos: string[];
  descricao: string;
}

const MEMBER = [
  {
    numero: 1,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 2,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: false, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 3,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 4,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: false, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 5,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: false, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 6,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: false, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 7,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 8,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 9,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  },
  {
    numero: 10,
    nome: 'Lampada quebrada',
    mapa: 'Mapa 2',
    tarefa: 'Vestiario Fem',
    status: true, 
    data: '01/08/2022',
    member: 'Eduardo S.',
    fotos: ['foto1', 'foto2'],
    descricao: 'Lampada queimada no vestiario do 2 andar'
  }
]

@Component({
  selector: 'cleaning-report',
  templateUrl: './cleaning-report.component.html',
  styleUrls: ['./cleaning-report.component.scss']
})
export class CleaningReportComponent implements OnInit {
  public dataSource = MEMBER;
  public displayedColumns: string[] = ['numero', 'nome', 'mapa', 'tarefa', 'status', 'data', 'member', 'fotos', 'actions']
  public dataView = 'list';
  public checked = true;

  constructor() { }

  ngOnInit(): void {
  }

  public changeView(view: string): void {
    this.dataView = view;
  }
  
  public changeStatus(item: Member): void {
    item.status = !item.status;
  }
}
