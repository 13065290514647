import { Injectable } from '@angular/core';
import { BaseModel, BaseService } from '../auth/base.service';
import { MachineTask } from './machine-task.service';
import { Machine } from './machine.service';
import { Observable } from 'rxjs';


export interface ChecklistItem extends BaseModel<number> {
  task: MachineTask;
  machine: Machine;
  content: string;
  area: number;
}

@Injectable({
  providedIn: 'root'
})
export class ChecklistItemService extends BaseService<ChecklistItem, number> {
  protected path: string = `${this.config.endpoint}/hw/checklist`;

  public getCheckListByIdTask(id : number): Observable<ChecklistItem[]>{
    return this.http.get<ChecklistItem[]>(`${this.path}/taskId/` + id);

  }


}
