<div class="new-info">
    <div class="new-info--type">
        <div class="new-info-type--select">
            <mat-form-field class="typeSelect" appearance="outline">
                <mat-label>Tipo de Informação</mat-label>
                <mat-select matInput (valueChange)="typesOnChange($event)">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let type of informationTypes" [value]="type.id">
                        <p>{{type.name}}</p>
                    </mat-option>
                </mat-select>
                <mat-hint>Acrescente Informações ao Customer</mat-hint>
            </mat-form-field>
        </div>
        <div class="new-info-type--button">
            <button mat-button class="new-info-button" type="button" (click)="addFieldDialog()">Defina Nova
                Informação</button>
        </div>
    </div>

    <form class="new-info--form" *ngIf="customerAddInformationForm" [formGroup]="customerAddInformationForm"
        (ngSubmit)="onSubmit('information',customerAddInformationForm)" ngNativeValidate #customerAddInfoForm="ngForm">
        <mat-form-field appearance="outline">
            <mat-label>{{newInfo.name || 'Selecione um tipo de informação'}}</mat-label>
            <input matInput type="text" name="content" formControlName="content" [value]="newInfoContent" required>
            <mat-hint>{{newInfo.description || ''}}</mat-hint>
        </mat-form-field>
        <div *ngIf="isNewInfoEnabled" class="form-button">
            <button mat-button class="new-info-button" type="submit">Salvar informação</button>
        </div>
    </form>
</div>

<div class="customerData">

    <mat-form-field appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput type="text" name="content" [(ngModel)]="this.customerData.firstName" required>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Sobrenome</mat-label>
        <input matInput type="text" name="content" [(ngModel)]="this.customerData.lastName" required>
    </mat-form-field>

    <ng-container *ngFor="let contact of customerData.contacts">
        <mat-form-field *ngIf="contact.identifier && contact.identifier.length > 0" appearance="outline">
            <mat-label>{{ contact.channel }}</mat-label>
            <input matInput type="text" name="content" [value]="contact.identifier" disabled>
        </mat-form-field>
    </ng-container>

    <mat-form-field appearance="outline" *ngFor="let customerInfo of customerInformation">
        <mat-label>{{ customerInfo.information.name }}</mat-label>
        <input matInput type="text" name="content" [value]="customerInfo.content" required>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="deleteInformation(customerInfo.id)">
            <mat-icon>close</mat-icon>
        </button>
        <mat-hint>{{ customerInfo.information.description }}</mat-hint>
    </mat-form-field>
</div>

<button class="update-customer-button" mat-raised-button (click)="updateCustomer()">Atualizar</button>

<mat-divider></mat-divider>

<h3>Históricos</h3>

<a mat-raised-button class="bot-history-button" color="primary" *ngFor="let bot of bots" [routerLink]="['history', bot.id]">
    {{ bot.name }}
</a>