import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface LusEntity {
    idLusEntity?: number;

    name?: string;
    description?: string;

    idValidationType?: number;
    idValidation?: number;
    systemEntity?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LusEntityService {
    url: string = `${environment.coreService}/api/lusentities`;

    constructor(private _httpClient: HttpClient) {}

    list(): Observable<LusEntity[]> {
        return this._httpClient.get<LusEntity[]>(this.url);
    }

    get(id: number): Observable<LusEntity> {
        return this._httpClient.get<LusEntity>(`${this.url}/${id}`);
    }

    create(data: LusEntity): Observable<LusEntity> {
        return this._httpClient.post<LusEntity>(this.url, data);
    }

    update(data: LusEntity): Observable<LusEntity> {
        return this._httpClient.post<LusEntity>(
            `${this.url}/${data.idLusEntity}`,
            data,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        );
    }

    delete(data: LusEntity): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.idLusEntity}`);
    }
}
