<div class="header accent"></div>

<div class="functionality" *ngFor="let item of functionalities" [routerLink]="item.url">
  <mat-icon class="functionality__icon">
    {{ item.icon }}
  </mat-icon>
  <h3 class="functionality__title">
    {{ item.title }}
  </h3>
  <span class="functionality__description">
    {{ item.description }}
  </span>
</div>