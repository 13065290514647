import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface CorpusUtterance {
    id?: number;
    intent_id?: number;
    content?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CorpusUtteranceService {
    url = `${environment.corpusService}/utterances`;
    listLength = 0;

    constructor(private _httpClient: HttpClient) { }

    list(intent_id?: number ): Observable<CorpusUtterance[]> {
        this.listLength = 0;
        let data: HttpParams = new HttpParams();
        if (intent_id) {
            data = data.set('intent_id', `${intent_id}`);
        }
        return this._httpClient.get<CorpusUtterance[]>(this.url, { params: data })
            .pipe(tap((list) => this.listLength = list.length));
    }

    get(id: number): Observable<CorpusUtterance> {
        return this._httpClient.get<CorpusUtterance>(`${this.url}/${id}`);
    }

    create(data: CorpusUtterance): Observable<CorpusUtterance> {
        return this._httpClient.post<CorpusUtterance>(this.url, data);
    }

    update(data: CorpusUtterance): Observable<CorpusUtterance> {
        return this._httpClient.post<CorpusUtterance>(
            `${this.url}/${data.id}`,
            data
        );
    }

    delete(data: CorpusUtterance): Observable<any> {
        return this._httpClient.delete<Boolean>(`${this.url}/${data.id}`);
    }
}
