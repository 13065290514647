import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface CorpusProject {
    id?: number;
    name?: string;
    description?: string;
}

@Injectable({
    providedIn: 'root'
})
export class CorpusProjectService {
    url: string = `${environment.corpusService}/projects`;

    constructor(private _httpClient: HttpClient) { }

    list(): Observable<CorpusProject[]> {
        return this._httpClient.get<CorpusProject[]>(this.url);
    }

    get(id: number): Observable<CorpusProject> {
        return this._httpClient.get<CorpusProject>(`${this.url}/${id}`);
    }

    create(
        data: CorpusProject,
        template?: number
    ): Observable<CorpusProject> {
        let url = this.url;
        if (template) {
            url = url + `?clone_from=${template}`;
        }
        return this._httpClient.post<CorpusProject>(url, data);
    }

    update(data: CorpusProject): Observable<CorpusProject> {
        return this._httpClient.post<CorpusProject>(
            `${this.url}/${data.id}`,
            data
        );
    }

    delete(data: CorpusProject): Observable<any> {
        return this._httpClient.delete(`${this.url}/${data.id}`);
    }
}
