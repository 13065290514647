import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface WhatsappHook {
  id?: number;
  idBot?: number;
  idChannel?: number;

  name?: string;
  description?: string;

  account?: string;
  token?: string;
  number?: string;
}

@Injectable({
  providedIn: 'root'
})
export class WhatsappHookService {

  url: string = `${environment.fbService}/whatsapp`;

  constructor(private _httpClient: HttpClient) { }

  list(): Observable<WhatsappHook[]> {
    return this._httpClient.get<WhatsappHook[]>(this.url);
  }

  get(id: number): Observable<WhatsappHook> {
    return this._httpClient.get<WhatsappHook>(`${this.url}/${id}`);
  }

  create(data: WhatsappHook): Observable<WhatsappHook> {
    return this._httpClient.post<WhatsappHook>(this.url, data);
  }

  update(data: WhatsappHook): Observable<WhatsappHook> {
    return this._httpClient.post<WhatsappHook>(
      `${this.url}/${data.id}`,
      data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    );
  }

  delete(data: WhatsappHook): Observable<any> {
    return this._httpClient.delete(`${this.url}/${data.id}`);
  }
}
