import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { AutosizeModule } from 'ngx-autosize';
import { APIModule } from '../api/api.module';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from './../../pipes/pipes.module';
import { BotComponent } from './bot.component';
import { ActionDialogComponent } from './dialogs/action-dialog/action-dialog.component';
import { ActionPropertiesComponent } from './dialogs/action-properties/action-properties.component';
import { AgentResponseEditComponent } from './dialogs/agent-response-edit/agent-response-edit.component';
import { AgentResponseListComponent } from './dialogs/agent-response-list/agent-response-list.component';
import { BotCreateComponent } from './dialogs/bot-create/bot-create.component';
import { ChannelDialogComponent } from './dialogs/channel-dialog/channel-dialog.component';
import { EmailChannelEditComponent, FacebookPageEditComponent, YoutubeEditComponent } from './dialogs/channel-edit/channel-edit.component';
import { ContextDialogComponent } from './dialogs/context-dialog/context-dialog.component';
import { ForwarderDialogComponent } from './dialogs/forwarder-dialog/forwarder-dialog.component';
import { IntentDialogComponent } from './dialogs/intent-dialog/intent-dialog.component';
import { ParameterViewComponent } from './dialogs/parameter-view/parameter-view.component';
import { PromptViewComponent } from './dialogs/prompt-view/prompt-view.component';
import { ResponseComponent } from './dialogs/response/response.component';
import { TagListDialogComponent } from './dialogs/tag-list-dialog/tag-list-dialog.component';
import { TriggerDialogComponent } from './dialogs/trigger-dialog/trigger-dialog.component';
import { BotDetailComponent } from './pages/bot-detail/bot-detail.component';
import { ChannelDetailComponent } from './pages/channel-detail/channel-detail.component';
import { FacebookChannelComponent } from './pages/facebook-channel/facebook-channel.component';
import { SimuladorContentComponent } from './pages/simulador/simulador-content/simulador-content.component';
import { SimuladorComponent } from './pages/simulador/simulador.component';
import { TreinadorContentComponent } from './pages/treinador/treinador-content/treinador-content.component';
import { TreinadorComponent } from './pages/treinador/treinador.component';
import { CorpusComponent } from './parts/corpus/corpus.component';
import { MessengerComponent } from './parts/messenger/messenger.component';
import { ResponseCardComponent } from './parts/response-card/response-card.component';
import { SidenavComponent } from './parts/sidenav/sidenav.component';
import { TrainerComponent } from './parts/trainer/trainer.component';
import { ChannelsComponent } from './views/channels/channels.component';
import { IntentViewComponent } from './views/intents/intent-view.component';
import { ResponseViewComponent } from './views/intents/response-view/response-view.component';
import { MediasComponent } from './views/medias/medias.component';
import { YoutubeChannelComponent } from './pages/youtube-channel/youtube-channel.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: BotComponent
    },
    {
        path: 'facebook/channel',
        pathMatch: "full",
        component: FacebookChannelComponent,
    },
    {
        path: "youtube/channel",
        pathMatch: "full",
        component: YoutubeChannelComponent,
    },
    {
        path: ':id',
        pathMatch: 'full',
        component: BotDetailComponent,
    },
    {
        path: ':id/channels',
        pathMatch: 'full',
        component: ChannelDetailComponent,
    },
    {
        path: ':id/simulador',
        pathMatch: 'full',
        component: SimuladorComponent,
    },
    {
        path: ':id/treinamento',
        pathMatch: 'full',
        component: TreinadorComponent,
    },
    {
        path: ':id/intents/:intent',
        pathMatch: 'full',
        component: BotDetailComponent,
    },
    {
        path: ':id/channels/:channel',
        pathMatch: 'full',
        component: ChannelDetailComponent,
    },
    {
        path: ':id/channels/:channel/media',
        pathMatch: 'full',
        component: ChannelDetailComponent,
    }
];

@NgModule({
    declarations: [
        BotComponent,
        BotDetailComponent,
        BotCreateComponent,
        IntentViewComponent,
        ParameterViewComponent,
        PromptViewComponent,
        ChannelsComponent,
        SidenavComponent,
        ChannelDetailComponent,
        ContextDialogComponent,
        ForwarderDialogComponent,
        MessengerComponent,
        CorpusComponent,
        TrainerComponent,
        ActionDialogComponent,
        IntentDialogComponent,
        MediasComponent,
        SimuladorComponent,
        SimuladorContentComponent,
        TreinadorComponent,
        TreinadorContentComponent,
        ChannelDialogComponent,
        ResponseCardComponent,
        ResponseComponent,
        ResponseViewComponent,
        TriggerDialogComponent,
        TagListDialogComponent,
        AgentResponseListComponent,
        AgentResponseEditComponent,
        EmailChannelEditComponent,
        FacebookPageEditComponent,
        YoutubeEditComponent,
        ActionPropertiesComponent,
        FacebookChannelComponent,
        YoutubeChannelComponent,
    ],
    entryComponents: [
        BotCreateComponent,
        ParameterViewComponent,
        PromptViewComponent,
        ContextDialogComponent,
        ForwarderDialogComponent,
        ActionDialogComponent,
        IntentDialogComponent,
        ChannelDialogComponent,
        ResponseComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,

        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        APIModule,
        CommonModule,
        AutosizeModule,
        SharedModule,
    ]
})
export class BotModule { }
