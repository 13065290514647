<form [formGroup]="form" (ngSubmit)="submitMap()">
    <h3 mat-dialog-title>
        {{ title }}
    </h3>

    <mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput type="text" formControlName="title">
            <mat-hint>Dê um nome ao seu mapa</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>UUID</mat-label>
            <input matInput type="text" formControlName="uniqueId">
            <mat-hint>Seu mapa precisa de um identificador único</mat-hint>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
        <button mat-button type="submit">Salvar</button>
    </mat-dialog-actions>
</form>