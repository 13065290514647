import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MachineMap, MachineMapService, MachineTask } from 'onevoice';


@Component({
  selector: 'main-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss']
})
export class CreateTaskComponent {
  public form: FormGroup;
  public mapList?: MachineMap[];

  constructor(
    public dialog: MatDialog,
    public reference: MatDialogRef<CreateTaskComponent>,
    public maps: MachineMapService,
    @Inject(MAT_DIALOG_DATA) public data: Partial<MachineTask> = {},
  ) {
    this.form = new FormGroup({
      "map": new FormControl(data.map || undefined, [Validators.required]),
      "name": new FormControl(data.name || "", [Validators.required]),
      "description": new FormControl(data.description || "", [Validators.required]),
    }, []);

    this.maps.list().subscribe(mapList => {
      this.mapList = mapList;
    });
  }

  public submitTask(): void {

    
    console.log(this.form.value)
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  public compareMap(map1: MachineMap, map2: MachineMap) {
    return map1.id == map2.id
  }

}
