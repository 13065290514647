import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Mood } from './mood.model';
import { VoiceModel } from './voice-model.model';
import { Voice } from './voice.model';

@Injectable({
  providedIn: 'root'
})
export class VoiceModelService {
  constructor(public http: HttpClient, @Inject(APP_CONFIG) public config: ApiConfiguration) { }

  public list(voice: Voice): Observable<VoiceModel[]> {
    return this.http.get<VoiceModel[]>(`${this.config.mediaservice}/voice/model/${voice.id}`);
  }

  public train(voice: Voice, mood: Mood): Observable<VoiceModel> {
    let data = new FormData();

    data.set("id", `${voice.id}`);
    data.set("mood", `${mood.id}`);

    return this.http.post<VoiceModel>(`${this.config.mediaservice}/voice/model/train`, data);
  }

  public setActive(model: VoiceModel): Observable<boolean> {
    return this.http.post<boolean>(`${this.config.mediaservice}/voice/model/${model.id}/active`, {});
  }
}
