import { Injectable } from '@angular/core';
import { MachineModel } from './machine-model.service';
import { BaseModel, BaseService } from '../auth/base.service';


export interface MachineModelAsset extends BaseModel<number>{
  model: MachineModel;
  imgPath: string;
}

@Injectable({
  providedIn: 'root'
})
export class MachineModelAssetService extends BaseService<MachineModelAsset,number>{

  protected path: string = `${this.config.endpoint}/hw/modelasset`;

}

