<div id="alabia-view-content" class="page-layout carded fullwidth inner-scroll">
    <div class="header accent p-24">
        <mat-icon>{{ icon }}</mat-icon>
        <h1 class="logo-text">{{ title }}</h1>
    </div>

    <div class="history">
        <!-- CHAT -->
        <div class="chat" fxFlex fxLayout="column">
            <!-- CHAT CONTENT -->
            <div id="chat-content" fxFlex="1 1 auto" fusePerfectScrollbar>
                <!-- CHAT MESSAGES -->
                <div *ngIf="history" class="chat-messages">
                    <!-- MESSAGE -->
                    <div *ngFor="let message of history; let i = index" class="message-row" [ngClass]="{
                    'me': message.sender !== Sender.USER,
                    'contact': message.sender === Sender.USER,
                    'first-of-group': isFirstMessageOfGroup(message, i),
                    'last-of-group': isLastMessageOfGroup(message, i)
                }">
                        <div class="bubble">
                            <div class="message" *ngIf="jsonMessage(message)">
                                <oneui-data-container [data]="message.content"></oneui-data-container>
                                <small class="agent-info" *ngIf="message.sender === Sender.AGENT && message.agent">
                                    enviado por {{ userFullname(message.agent) }}
                                </small>

                            </div>
                            <div class="message" *ngIf="!jsonMessage(message)">
                                {{ processText(message.content) }}
                                <small class="agent-info" *ngIf="message.sender === Sender.AGENT && message.agent">
                                    enviado por {{ userFullname(message.agent) }}
                                </small>
                            </div>
                            <div class="time secondary-text">{{message.date | date:'short'}}</div>
                        </div>
                    </div>
                    <!-- / MESSAGE -->
                </div>
                <!-- CHAT MESSAGES -->
            </div>
            <!-- / CHAT CONTENT -->
        </div>
        <!-- / CHAT -->
    </div>

</div>