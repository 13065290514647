import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Subject } from 'rxjs';
import { SkillService } from 'app/alabia/api/skill.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'alabia-selected-bar',
  templateUrl: './selected-bar.component.html',
  styleUrls: ['./selected-bar.component.scss']
})
export class SelectedBarComponent implements OnInit {

  hasSelectedSkills: boolean = false;
  isIndeterminate: boolean = false;
  selectedSkills: string[] = [];

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _skillService: SkillService,
    public _matDialog: MatDialog
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this._skillService.onSelectedSkillsChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(selectedSkills => {
        this.selectedSkills = selectedSkills;
        setTimeout(() => {
          this.hasSelectedSkills = selectedSkills.length > 0;
          this.isIndeterminate = (selectedSkills.length !== this._skillService.skillList.length && selectedSkills.length > 0);
        }, 0);
      });
  }

  selectAll(): void {
    this._skillService.selectSkills();
  }

  deselectAll(): void {
    this._skillService.deselectSkills();
  }

  deleteSelectedSkills(): void {
    let reference = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    reference.componentInstance.confirmMessage = 'Tem certeza que deseja excluir todos as skills selecionadas?';
    reference.afterClosed()
      .subscribe(result => {
        if (result) {
          this._skillService.deleteSelectedSkills();
        }
      });
  }


}
