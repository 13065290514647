<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row>
            <span class="title dialog-title">{{ title }}</span>
            <button mat-icon-button (click)="dialog.close(undefined)" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <input formControlName="first_name" matInput>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Sobrenome</mat-label>
            <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
            <input formControlName="last_name" matInput>

        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>E-mail</mat-label>
            <mat-icon matSuffix class="secondary-text">email</mat-icon>
            <input formControlName="email" matInput type="email">

        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Telefone</mat-label>
            <mat-icon matSuffix class="secondary-text">phone</mat-icon>
            <input formControlName="phone" matInput phoneMask>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Localização:</mat-label>
          <mat-select formControlName="location">
            <mat-option *ngFor="let location of unitList" [value]="location" (click)="setLocation(location)">
              {{ location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>



        <mat-form-field *ngIf="create" appearance="outline">
            <mat-label>Senha</mat-label>
            <mat-icon matSuffix class="secondary-text">lock</mat-icon>
            <input formControlName="password" type="password" matInput>
        </mat-form-field>

        <mat-form-field *ngIf="create" appearance="outline">
            <mat-label>Confirmação de senha</mat-label>
            <mat-icon matSuffix class="secondary-text">lock</mat-icon>
            <input formControlName="confirm" type="password" matInput>
        </mat-form-field>


        <mat-form-field appearance="outline">
            <mat-label>Autorizações</mat-label>
            <mat-select [formControl]="authorities" multiple>
                <mat-option *ngFor="let authority of AuthorizationList" [value]="authority.key">
                    {{ authority.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
            <mat-label>Atendimentos permitidos</mat-label>
            <mat-select [formControl]="chats" multiple>
                <mat-option *ngFor="let robot of robotList" [value]="robot">
                    {{ robot.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Edições permitidas</mat-label>
            <mat-select [formControl]="robots" multiple>
                <mat-option *ngFor="let robot of robotList" [value]="robot">
                    {{ robot.name }}
                </mat-option>
            </mat-select>
        </mat-form-field> -->


    </mat-dialog-content>

    <div mat-dialog-actions>
        <button mat-button type="submit" class="save-button" [disabled]="form.invalid">
            {{ action }}
        </button>
    </div>
</form>
