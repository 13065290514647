import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';

export enum GraphPeriod {
  LAST_MONTH = "LAST_MONTH",
  LAST_WEEK = "LAST_WEEK",
  LAST_DAY = "LAST_DAY",
  MONTH_TO_DATE = "MONTH_TO_DATE",
  WEEK_TO_DATE = "WEEK_TO_DATE",
  DAY_TO_DATE = "DAY_TO_DATE",
  CUSTOM = "CUSTOM",
}

export enum GraphStep {
  HOURLY = "HOURLY",
  DAILY = "DAILY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export interface Graph extends Ownable {
  dashboard: string;
  kind: string;
  span: number;
  period: GraphPeriod;
  periodStart: number;
  periodEnd: number;
  timestep: GraphStep;

  bots: number[];
  agents: number[];
  tags: number[];
  extra: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardListService extends OwnableService<Graph> {
  protected path: string = `${this.config.endpoint}/graphs`;
}
