<form [formGroup]="form" (ngSubmit)="ngSubmit()">
    <h1 mat-dialog-title>{{ isEdit ? 'Atualizar' : 'Criar' }}</h1>

    <mat-dialog-content>
        <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput type="text" formControlName="title">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Descrição</mat-label>
            <input matInput type="text" formControlName="description">
        </mat-form-field>

        <mat-form-field *ngIf="typeList !== undefined">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="type" [compareWith]="compareWith">
                <mat-option *ngFor="let type of typeList" [value]="type">
                    {{ type.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button type="submit">Salvar</button>
        <button mat-button [mat-dialog-close]="undefined">Fechar</button>
    </mat-dialog-actions>
</form>