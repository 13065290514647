import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { Skill } from 'app/models/skill';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SkillService } from '../../api/skill.service';
import { SkillManagerDialogComponent } from '../skill-manager-dialog/skill-manager-dialog.component';

@Component({
  selector: 'alabia-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.scss'],
  animations: fuseAnimations,

})
export class SkillListComponent implements OnInit, OnDestroy, AfterViewInit {

  skill: any;
  skills: any;

  checkboxes: {} = {};

  dataSource: any;
  displayedColumns: string[] = [
    'name',
    'buttons'
  ];

  @ViewChild(MatPaginator, { static: false }) paginator?: MatPaginator;

  dialogRef: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _matDialog: MatDialog,
    private _skillService: SkillService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getData();

    this._skillService.onSkillChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((_) => {
        this.dataSource.paginator = this.paginator;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getData(): void {
    this._skillService.onSkillChanged.subscribe((data) => {
      this.dataSource = new MatTableDataSource(data);
    });
  }

  deleteSkill(skill: Skill): void {
    let reference = this._matDialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });

    reference.componentInstance.confirmMessage = 'Tem certeza em deletar esta skill?';

    reference.afterClosed().subscribe((result: any) => {
      if (result) {
        this._skillService.deleteSkill(skill);
      }
    });
  }

  editSkill(skill: Skill): void {
    this.dialogRef = this._matDialog.open(SkillManagerDialogComponent, {
      panelClass: 'skill-form-dialog',
      data: {
        skill: skill,
        action: 'edit'
      }
    });

    this.dialogRef.afterClosed()
      .subscribe((response: any[]) => {
        if (!response) { return; }

        const actionType: string = response[0];
        const formData: FormGroup = response[1];

        switch (actionType) {
          case 'save':
            this._skillService.updateSkill(formData.value);
            break;
          case 'delete':
            this.deleteSkill(skill);
            break;
        }
      });
  }

  onSelectedChange(skillId: any): void {
    this._skillService.toggleSelectedSkill(skillId);
  }
}
