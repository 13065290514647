<div id="alabia-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <span class="material-symbols-outlined">
      engineering
    </span>
    <h1 class="logo-text">Manutenção</h1>
    <br />
  </div>
  <div class="mat-headline" class="title-dashboard">Robôs</div>
  <div class="header-range">
    <div style="display: flex;">


      <div class="map-layout-col2">
        <table mat-table [dataSource]="dataSourceMachines" class="mat-elevation-z2">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Nº</th>
            <td mat-cell *matCellDef="let item">
              {{ dataSourceMachines.data.indexOf(item) + 1 }}
            </td>
          </ng-container>


          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Modelo</th>
            <td mat-cell *matCellDef="let item">{{ item.model.model }}</td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Localização</th>
            <td mat-cell *matCellDef="let item">
              {{item.location == undefined ? "Não definido" : item.location.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="nickname">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let item">
              {{item.nickName == undefined ? "Não definido" : item.nickName}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsMachinie"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsMachinie" (click)="selectedMachineRow(row)"
            [ngStyle]="{
        'background-color':
          machineSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
      }"></tr>
        </table>
        <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
          [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
        </mat-paginator>
      </div>

      <div class="mat-elevation-z2 description">
        <h3 style="margin-top: 30px; margin-left: 30px;"> Robô: {{machineSelected?.name}}</h3>
        <div style="display:flex;">
          <div style="margin: 50px;">

            <table>
              <tr>
                <td>Modelo:</td>
                <td>{{machineSelected?.model?.model}}</td>
              </tr>
              <tr>
                <td>Localização :</td>
                <td>{{machineSelected?.location?.name}}</td>
              </tr>

              <tr>
                <td>Utilma utilização: </td>
                <td>{{lastExecution != undefined ? convertDate(lastExecution.end) : "N/A"}}</td>
              </tr>
              <tr>
                <td>Status das escovas:</td>
                <td>{{lastExecution != undefined ? lastExecution.data.deviceStatus.brushUsage : "N/A"}} </td>
              </tr>
              <tr>
                <td>Status das escovas esquerda:</td>
                <td>{{lastExecution != undefined ? lastExecution.data.deviceStatus.leftSideBrushUsage : "N/A"}} </td>
              </tr>
              <tr>
                <td>Status das escovas direita:</td>
                <td>{{lastExecution != undefined ? lastExecution.data.deviceStatus.rightSideBrushUsage : "N/A"}} </td>
              </tr>
              <tr>
                <td>Status Dust Push:</td>
                <td>{{lastExecution != undefined ? lastExecution.data.deviceStatus.dustPushUsage : "N/A"}} </td>
              </tr>
              <tr>
                <td>Status filter:</td>
                <td>{{lastExecution != undefined ? lastExecution.data.deviceStatus.filterUsage : "N/A"}} </td>
              </tr>
              <tr>
                <td>Horas executadas:</td>
                <td>420h</td>
              </tr>
              <tr>
                <td>Tasks executados</td>
                <td>56</td>
              </tr>
              <tr>
                <td>Tasks executados</td>
                <td>56</td>
              </tr>
              <tr>
                <td>Data da última manutenção: </td>
                <td>12/10/2023</td>
              </tr>

            </table>
          </div>

        </div>


      </div>
      <div class="mat-elevation-z2 description">
        <div class="map-layout-col2">
          <table mat-table [dataSource]="dataSourceMachineTasks" class="mat-elevation-z2">
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Nº</th>
              <td mat-cell *matCellDef="let item">
                {{ dataSourceMachineTasksFilter.data.indexOf(item) + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Nome</th>
              <td mat-cell *matCellDef="let item">{{ item.name .slice(0,-10)}}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Descrição</th>
              <td mat-cell *matCellDef="let item">{{ item.description }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                  Ações
              </th>
              <td mat-cell *matCellDef="let element">
                  <a mat-icon-button [routerLink]="[element.id]">
                      <mat-icon>add_circle_outline</mat-icon>
                  </a>
                  <button mat-icon-button (click)="editTask(element)">
                      <mat-icon>edit</mat-icon>
                  </button>
              </td>
          </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsTasks"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTasks" (click)="selectedTaskRow(row)" [ngStyle]="{
          'background-color':
            machineSelected == row ? 'rgba(154, 117, 252, .2)' : '#fff'
        }"></tr>
          </table>
          <mat-paginator class="mat-elevation-z2" [pageSize]="8" [showFirstLastButtons]="true"
            [pageSizeOptions]="[8, 14, 28, 56]" aria-label="Select page">
          </mat-paginator>
        </div>


      </div>

    </div>
    <button class="alabia-fab" mat-fab (click)="editTask()">
      <mat-icon>add</mat-icon>
    </button>

  </div>

</div>
