import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpeechProject } from 'onevoice';

@Component({
  selector: 'main-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {
  public form: FormGroup;
  public isCreate: boolean;


  constructor(
    private reference: MatDialogRef<ProjectCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Partial<SpeechProject>

  ) {
    this.isCreate = data.name == '';
    this.form = new FormGroup({
      "name": new FormControl( "" || data.name, [Validators.required, Validators.minLength(3)]),
      "description": new FormControl( undefined || data.description, [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  ngSubmit(){
    this.reference.close({
      ...this.data, 
      ...this.form.value
    });
  }

}
