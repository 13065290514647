import { ActionPropertiesComponent } from '@alabia/bot/dialogs/action-properties/action-properties.component';
import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BotClientService } from 'app/alabia/api/bot-client.service';
import { ContextIntent, ContextIntentService } from 'app/alabia/api/context-intent.service';
import { Context, ContextService } from 'app/alabia/api/context.service';
import { CorpusIntentService } from 'app/alabia/api/corpus-intent.service';
import { CorpusUtterance, CorpusUtteranceService } from 'app/alabia/api/corpus-utterance.service';
import { IntentForwarder, IntentForwarderService } from 'app/alabia/api/intent-forwarder.service';
import { IntentResponse, IntentResponseService } from 'app/alabia/api/intent-response.service';
import { Parameter, ParameterScope, ParameterService } from 'app/alabia/api/parameter.service';
import { Prompt, PromptService } from 'app/alabia/api/prompt.service';
import { RestResponse, RestResponseService } from 'app/alabia/api/rest-response.service';
import { ParameterViewComponent } from 'app/alabia/bot/dialogs/parameter-view/parameter-view.component';
import { PromptViewComponent } from 'app/alabia/bot/dialogs/prompt-view/prompt-view.component';
import { Action, ActionService, ActionType, ChannelType, GenericResponse, GenericResponseService, Intent, IntentService, MediaType, ResponseType, UploadedMediaService } from 'onevoice';
import { ActionDialogComponent } from '../../dialogs/action-dialog/action-dialog.component';
import { ContextDialogComponent } from '../../dialogs/context-dialog/context-dialog.component';
import { ForwarderDialogComponent } from '../../dialogs/forwarder-dialog/forwarder-dialog.component';
import { ResponseComponent } from '../../dialogs/response/response.component';

const REST_RESPONSE = 4;

function byOrder(r1: GenericResponse, r2: GenericResponse): number {
  if (r1.order < r2.order) {
    return -1;
  } else if (r1.order > r2.order) {
    return 1;
  } else if ((r1.id || 0) < (r2.id || 0)) {
    return -1;
  } else if ((r1.id || 0) > (r2.id || 0)) {
    return 1;
  }
  return 0;
}

function byId(a: any, b: any) {
  return (a.id || 0) - (b.id || 0)
}

@Component({
  selector: 'alabia-intent-view',
  templateUrl: './intent-view.component.html',
  styleUrls: ['./intent-view.component.scss']
})
export class IntentViewComponent implements OnInit {
  public ResponseType = ResponseType;
  @Input()
  public intent?: Intent;

  public next: number | null = null;

  @Output()
  onDelete = new EventEmitter<Intent>();

  corpusProject = 1;
  corpusIntent: number | null = null;

  actions: Action[] = [];

  inContexts: Context[] = [];
  outContexts: Context[] = [];
  allContexts: Context[] = [];
  contextIntents: ContextIntent[] = [];

  responses: GenericResponse[] = [];
  restResponses: RestResponse[] = [];
  intentResponses: IntentResponse[] = [];

  parameters: [Action, Parameter[]][] = [];
  prompts: [Parameter, Prompt[]][] = [];

  utterances: CorpusUtterance[] = [];
  intentForwarders: IntentForwarder[] = [];

  newRestResponse = '';
  newUtterance = '';

  addJSONCard = false;

  displayedColumns = ['name', 'required', 'prompts', 'edit', 'remove'];
  forwarderColumns = ['intent', 'trigger', 'answer', 'delete'];

  tabActive: ChannelType = ChannelType.CHAT;
  tabResponse: ChannelType[] = Object.values(ChannelType).filter(item => item !== ChannelType.TEST);

  filteredOptions: string[] = [];
  otherIntents: Intent[] = [];

  public showLess = true;

  constructor(
    private intentService: IntentService,
    private intentResponseService: IntentResponseService,
    private genericResponseService: GenericResponseService,
    private restResponseService: RestResponseService,
    private actionService: ActionService,
    private parameterService: ParameterService,
    private promptService: PromptService,
    private contextService: ContextService,
    private contextIntentService: ContextIntentService,
    private intentForwarderService: IntentForwarderService,
    private corpusIntentService: CorpusIntentService,
    private botClient: BotClientService,
    public corpusUtteranceService: CorpusUtteranceService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private storage: UploadedMediaService,
    private resolver: ComponentFactoryResolver,
  ) { }

  public setNext(event: any) {
    if (this.intent) {
      let other = this.otherIntents.find(value => value.id == event.value)
      this.intentService.setNext(this.intent, other).subscribe(ok => {
        console.log(ok);
      });
    }
  }

  ngOnInit(): void {
    if (!this.intent) { return }
    this.next = this.intent.next || null;
    let intent = this.intent;

    this.contextService.list().subscribe(allContexts => {
      this.allContexts = allContexts;
      if (!intent.id) {
        return;
      }

      this.contextIntentService.list(intent.id).subscribe(links => {
        this.contextIntents = links;
        links.forEach(link => {
          const toConcat = allContexts.filter(value => link.context && value.id === link.context.id);

          link.input
            ? this.inContexts = this.inContexts.concat(toConcat)
            : this.outContexts = this.outContexts.concat(toConcat);
        });
      });
    });

    if (!this.intent.bot || !this.intent.bot.id) { return; }
    this.intentService.list(this.intent.bot.id).subscribe(intents => {
      this.otherIntents = intents.filter(value => value.id !== intent.id);
      this.actionService.list(intent.id).subscribe(actions => {
        actions.forEach(action => {
          if (!this.intent) {
            return;
          }
          action.intent = this.intent;
          this.actions.push(action);
          this.parameterService.list(action.id).subscribe(parameters => {
            this.parameters.push([action, parameters.sort(byId)]);
            parameters.forEach(parameter => {
              parameter.action = action;
              this.promptService.list(parameter.id).subscribe(prompts => {
                this.prompts.push([parameter, prompts.sort(byId)]);
                prompts.forEach(prompt => { prompt.intentActionParameter = parameter; });
              });
            });
          });
        });

        this.actions.sort(byId);
      });
    });

    this.intentResponseService.list(this.intent.id).subscribe((intentRes: IntentResponse[]) => {
      intentRes.forEach(i => {
        if (i.idType === REST_RESPONSE) {
          this.restResponseService.get(i.idContent).subscribe(restRes => this.restResponses.push(restRes));
          this.intentResponses.push(i);
        }
      });
    });

    this.genericResponseService.list(this.intent.id || 0).subscribe(responses => {
      this.responses = responses.sort(byOrder);
    });

    this.intentForwarderService
      .list(this.intent.id)
      .subscribe(forwarders => this.intentForwarders = forwarders);
  }

  get actionTypes() {
    return Object.values(ActionType);
  }

  changeTab(event: MatTabChangeEvent): void {
    this.tabActive = this.tabResponse[event.index];
  }

  updateAutocomplete(): void {
    this.corpusIntentService.list(this.corpusProject).subscribe(intents => {
      this.filteredOptions = intents
        .filter(value => this.intent && (value.name || "").startsWith(this.intent.name || ""))
        .map<string>(value => (value.name || ""));
    });
  }

  message(content: string): void {
    this.snackBar.open(content, 'Entendi', { duration: 10000 });
  }

  // Intent
  saveIntent(): void {
    if (this.intent) {
      let intent = this.intent;
      this.intentService
        .update(intent)
        .subscribe(() => this.message(`"${intent.name}" foi atualizada`));
    }
  }

  // Actions
  createAction(): void {
    if (!this.intent) {
      return;
    }
    const reference = this.dialog.open<
      ActionDialogComponent,
      Partial<Action>,
      Partial<Action>
    >(ActionDialogComponent, {
      width: '500px',
      maxWidth: '95%',
      data: {
        intent: this.intent || undefined,
        name: '',
        order: this.actions.length,
        type: ActionType.DEFAULT,
      }
    });

    reference.afterClosed().subscribe((action?: Partial<Action>) => {
      if (!action) {
        return;
      }

      this.actionService.create(action).subscribe(created => {
        this.actions.push(created);
        this.parameters.push([created, []]);
        this.message(`Nova ação criada: ${created.name}`);
      });
    });
  }

  public openActionProperties(action: Action) {
    this.dialog.open(ActionPropertiesComponent, {
      width: "95%",
      maxWidth: "500px",
      data: action,
    });
  }

  updateAction(action: Action): void {
    this.actionService.update(action).subscribe(() => this.message('Ação atualizada!'));
  }

  removeAction(action: Action): void {
    this.actionService.delete(action).subscribe(() => {
      this.actions = this.actions.filter(value => value.id !== action.id);
      this.message(`Ação "${action.name}" removida`);
    });
  }

  // Contexts & Context-Intents

  createContext(): void {
    const reference = this.dialog.open<
      ContextDialogComponent,
      Context,
      Context
    >(ContextDialogComponent, {
      width: '1000px',
      maxWidth: '95%'
    });

    reference.afterClosed().subscribe((context: Context | undefined) => {
      if (!context) {
        return;
      }

      if (context.id) {
        this.contextService.update(context).subscribe(() => this.message(`Contexto ${context.name} atualizado!`));
      } else {
        this.contextService.create(context).subscribe(created => {
          this.allContexts = this.allContexts.concat([created]);
          this.message(`Contexto ${created.name} criado!`);
        });
      }
    });
  }

  updateContextIntents(input: boolean, contexts: Context[]): void {
    const inputName = input ? 'Entrada' : 'Saída';

    this.contextIntents
      .filter(value => value.input === input)
      .filter(value => contexts.filter(item => value.context && item.id === value.context.id).length === 0)
      .map(ci => {
        this.contextIntentService.delete(ci).subscribe(() => {
          this.contextIntents = this.contextIntents.filter(item => item.id !== ci.id);
          if (ci.context) { this.message(`Contexto de ${inputName} ${ci.context.name} removido!`); }
        });
      });

    contexts.forEach(context => {
      const link = this.contextIntents.find(value => value.context && value.context.id === context.id && input === value.input);

      if (link === undefined) {
        const obj = { input, context, intent: this.intent, required: true };

        this.contextIntentService
          .create(obj)
          .subscribe(created => {
            this.contextIntents.push(created);
            if (created.context) {
              this.message(`Contexto de ${inputName} ${created.context.name} adicionado!`);
            }
          });
      }
    });
  }

  saveContexts(input: boolean): void {
    let contexts: [boolean, Context[]][] = [
      [true, this.inContexts],
      [false, this.outContexts]
    ];

    contexts
      .filter(([_input, _]) => _input === input)
      .map(([input, contexts]) => this.updateContextIntents(input, contexts));
  }

  compareContext = (o1: any, o2: any): boolean => o1.id == o2.id;
  compareObjects = (a: any, b: any): boolean => a.id == b.id;

  removeResponse(response: GenericResponse): void {
    let reference = this.snackBar.open("Deseja deletar a resposta?", "Sim", {
      duration: 10000,
    });

    reference.afterDismissed().subscribe((value: any) => {
      console.log(value);
    });

    reference.onAction().subscribe((ok: any) => {
      this.genericResponseService.delete(response).subscribe(() => {
        this.responses = this.responses.filter(value => value.id !== response.id);
        this.message(`Resposta removida: ${response.content}`);
      });
    })
  }

  // Rest Responses & IntentResponses
  addRestResponse(): void {
    this.restResponseService
      .create({ url: this.newRestResponse })
      .subscribe(response => {
        this.intentResponseService
          .create({ idContent: response.id || 0, idType: REST_RESPONSE, intent: this.intent })
          .subscribe(link => {
            this.restResponses.push(response);
            this.intentResponses.push(link);
            this.newRestResponse = '';
            this.message(`Resposta adicionada: ${response.url}`);
          });
      });
  }

  updateRestResponse(response: RestResponse): void {
    this.restResponseService.update(response).subscribe(() => this.message(`Resposta atualizada`));
  }

  removeRestResponse(response: RestResponse): void {
    const link = this.intentResponses.find(value => value.idType === REST_RESPONSE && value.idContent === response.id);

    if (link === undefined) {
      console.log(this.intentResponses);
      console.log("not found");
      return;
    }

    this.intentResponseService.delete(link).subscribe(() => {
      this.intentResponses = this.intentResponses.filter(value => value.id !== link.id);
      this.restResponseService.delete(response).subscribe(() => {
        this.restResponses = this.restResponses.filter(value => value.id !== response.id);
        this.message(`Resposta removida: ${response.url}`);
      });
    });
  }

  // Parameters
  getParameters(action: Action): Parameter[] {
    let tuple = this.parameters.find(tuple => tuple[0].id == action.id);
    if (tuple) {
      return tuple[1];
    }
    return [];
  }

  createParameter(action: Action, parameter: Parameter = { scope: ParameterScope.INTENT }): void {
    const reference = this.dialog.open<
      ParameterViewComponent,
      Parameter,
      Parameter
    >(ParameterViewComponent, {
      width: '500px',
      maxWidth: '95%',
      data: { ...parameter }
    });

    let component = reference.componentInstance;

    reference.beforeClosed().subscribe((result: any) => {
      if (!result) {
        return;
      }
      result.action = action;
      if (result.id) {
        this.parameterService.update(result).subscribe(parameter => {
          component.saveEntities(parameter);
          this.message(`Parâmetro ${parameter.name} atualizado`);
          this.parameters
            .filter(tuple => tuple[0].id == action.id)
            .map(tuple => {
              let index = tuple[1].findIndex(
                value => value.id === parameter.id
              );
              tuple[1][index] = parameter;
              tuple[1] = tuple[1].concat([]);
            });
        });
      } else {
        this.parameterService.create(result).subscribe(created => {
          let tuple = this.parameters.find(
            tuple => tuple[0].id === action.id
          );
          if (!tuple) { return; }
          tuple[1] = tuple[1].concat([created]);
          this.prompts.push([created, []]);
          component.saveEntities(created);
          this.message(`Parâmetro ${created.name} criado.`);
        });
      }
    });
  }

  removeParameter(action: Action, parameter: Parameter): void {
    this.parameters
      .filter(([value, _]) => value.id === action.id)
      .map(tuple => {
        this.parameterService.delete(parameter).subscribe(() => {
          tuple[1] = tuple[1].filter(value => value.id !== parameter.id);
          this.message(`Parâmetro ${parameter.name} removido.`);
        });
      });
  }

  // Prompts
  createPrompt(parameter: Parameter): void {
    if (!this.prompts) { return; }
    let filtered = this.prompts.find(
      tuple => tuple[0].id === parameter.id,
    )
    if (!filtered) {
      return;
    }
    let prompts: Prompt[] = filtered[1]


    let before = new Set<number>();
    prompts.forEach(
      (value: Prompt) => {
        if (value.id) {
          before.add(value.id);
        }
      },
    );


    const reference = this.dialog.open<
      PromptViewComponent,
      Prompt[],
      Prompt[]
    >(PromptViewComponent, {
      width: '1000px',
      maxWidth: '95%',
      data: prompts
    });

    reference.afterClosed().subscribe((prompts: Prompt[] | undefined) => {
      if (!prompts) {
        return;
      }

      let tuple = this.prompts.find(i => i[0].id === parameter.id);
      if (!tuple) { return }
      let typeTuple = tuple

      prompts.forEach((prompt: Prompt, index: number) => {
        prompt.intentActionParameter = parameter;
        if (prompt.id) {
          this.promptService.update(prompt).subscribe(updated => {
            prompts[index] = updated;
            typeTuple[1] = prompts.concat([]);
          });
        } else {
          this.promptService.create(prompt).subscribe(created => {
            prompts[index] = created;
            typeTuple[1] = prompts.concat([]);
            this.message(`Pergunta criada: ${created.content}.`);
          });
        }
      });

      const after = new Set<number>();
      prompts.forEach(
        (value: Prompt) => {
          if (value.id) {
            after.add(value.id);
          }
        },
      );

      before.forEach(value => {
        if (!after.has(value)) {
          this.promptService.delete({ id: value }).subscribe(() => this.message(`Prompt ${value} removido!`));
        }
      });
    });
  }

  // Forwarders
  createForwarder(): void {
    const reference = this.dialog.open<
      ForwarderDialogComponent,
      IntentForwarder,
      IntentForwarder
    >(ForwarderDialogComponent, {
      width: '1000px',
      maxWidth: '95%',
      data: {
        origin: this.intent
      }
    });

    reference.afterClosed().subscribe((forwarder: IntentForwarder | undefined) => {
      if (!forwarder) {
        return;
      }

      this.intentForwarderService.create(forwarder).subscribe(created => {
        this.intentForwarders = this.intentForwarders.concat([created]);
        this.message(`Sequencia de intenções criada.`);
      });
    });
  }

  removeForwarder(forwarder: IntentForwarder): void {
    this.intentForwarderService.delete(forwarder).subscribe(() => {
      this.intentForwarders = this.intentForwarders.filter(each => each.id !== forwarder.id);
      this.message(`Sequência de intenções removida.`);
    });
  }

  // Final
  testIntent(): void {
    if (this.intent && this.intent.bot && this.intent.bot.id) {
      const command = { intent: this.intent.name, params: {} };
      this.botClient.sendMessage(this.intent.bot.id, '#' + btoa(JSON.stringify(command)));
    }
  }

  deleteIntent(): void {
    this.snackBar
      .open('Deseja realmente deletar a intenção?', 'Sim', {
        duration: 5000
      })
      .onAction()
      .subscribe((_: any) => {
        this.actions.forEach(action => {
          this.actionService.delete(action).subscribe(_ => {
            this.parameters.forEach(([_, parameters]) => {
              parameters.forEach(parameter => {
                this.parameterService
                  .delete(parameter)
                  .subscribe(() => { });
              });
            });

            this.prompts.forEach(([_, prompts]) =>
              prompts.forEach(prompt => this.promptService.delete(prompt).subscribe(() => { }))
            );
          });
        });

        this.intentForwarders.forEach(forwarder => this.intentForwarderService.delete(forwarder).subscribe(() => { }));
        this.intentResponses.forEach(value => this.intentResponseService.delete(value).subscribe(() => { }));
        this.responses.forEach(response => this.genericResponseService.delete(response).subscribe(() => { }));

        if (this.intent) {
          this.intentService.delete(this.intent).subscribe(() => {
            this.message('A intenção foi removida');
            this.onDelete.emit(this.intent);
          });
        }
      });
  }

  private orderMax(): number {
    return this.responses.map(response => response.order).reduce((previous, current) => previous < current ? current : previous, 0) + 1;
  }

  openCreateResponse(channel: ChannelType, data: GenericResponse | null = null) {
    if (!this.intent || !this.intent.id) {
      return;
    }
    let intentId = this.intent.id;
    if (data === null) {
      data = {
        type: ResponseType.TEXT,
        mediaType: MediaType.UNKNOWN,
        content: "",
        order: this.orderMax(),
        channel: channel,
      };
    }
    let reference = this.dialog.open<ResponseComponent, GenericResponse, GenericResponse | null>(ResponseComponent, {
      width: "80%",
      maxWidth: "500px",
      data: data,
    });

    reference.afterClosed().subscribe((response?: GenericResponse | null) => {
      if (!response) {
        console.debug(`No message created or updated`)
      } else if (!response.id) {
        console.log(`Creating new response`);
        this.genericResponseService.create(intentId, response).subscribe(created => {
          this.responses.push(created);
          this.responses.sort(byOrder);
          this.message(`Resposta ${created.type} adicionada`);
        });
      } else {
        console.log(`Updating response`);
        this.genericResponseService.update(response).subscribe(updated => {
          this.updateResponse(updated);
        });
      }

    })
  }

  public updateResponse(updated: GenericResponse) {
    this.responses = this.responses.filter(value => value.id !== updated.id);
    this.responses.push(updated);
    this.responses.sort(byOrder);
    this.message(`Resposta ${updated.type} atualizada`);
  }

  public deleteResponse(response: GenericResponse) {
    this.snackBar.open("Tem certeza que deseja deletar esta resposta?", "OK").onAction().subscribe((_: any) => {
      this.genericResponseService.delete(response).subscribe(_ => {
        this.responses = this.responses.filter(value => value.id !== response.id);
        this.message(`Resposta ${response.type} removida`);
      }, _ => {
        this.message(`Resposta ${response.type} não foi removida. Aconteceu algum erro.`);
      });
    });
  }
}
