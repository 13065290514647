import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { BackendApiRequestService } from 'app/alabia/api/backend-api-request.service';
import { AssignmentsService, Customer, QueueAssignment, QueueService } from 'onevoice';

@Component({
  selector: 'alabia-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  animations: fuseAnimations,
})
export class CustomerListComponent {
  displayedColumns: string[] = ['name'];

  dataSource: MatTableDataSource<any>;

  @Output() navigation = new EventEmitter();
  public assignmentList: QueueAssignment[] = [];

  @Input("filter")
  set filter(filterValue: string) {
    if (filterValue == undefined || filterValue == null) {
      return;
    }
    let filteredAssignments = this.assignmentList.filter(value => value.protocol_id == filterValue);
    this.dataSource.data = this._backendApiRequestService.customers.filter((value: Customer) => {
      if (value.firstName && value.firstName.includes(filterValue)) {
        return true;
      }

      if (value.lastName && value.lastName.includes(filterValue)) {
        return true;
      }

      if (value.identifiers !== undefined && value.identifiers !== null) {
        for (const identifier of value.identifiers) {
          if (identifier.identifier && identifier.identifier.includes(filterValue)) {
            return true;
          }
        }
      }

      for (const assignment of filteredAssignments) {
        if (assignment.protocol_id == filterValue) {
          return true;
        }
      }
      return false;
    });
  }

  @ViewChild(MatPaginator, { static: false })
  set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  constructor(
    private _backendApiRequestService: BackendApiRequestService,
    private assignments: AssignmentsService,
  ) {
    this.dataSource = new MatTableDataSource<Customer>(_backendApiRequestService.customers);
    this.assignments.list(true).subscribe(assignmentList => {
      this.assignmentList = assignmentList;
    })
  }
}
