import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VoiceProjectComponent } from './voice-project.component';
import { DetailComponent } from './detail/detail.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { DownloadComponent } from './download/download.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ScriptEditComponent } from './script-edit/script-edit.component';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: VoiceProjectComponent
  },
  {
    path: ":id",
    component: DetailComponent
  }
];

@NgModule({
  declarations: [
    VoiceProjectComponent,
    DetailComponent,
    DownloadComponent,
    ProjectCreateComponent,
    ScriptEditComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatDividerModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatGridListModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonToggleModule,
    FormsModule,

    ReactiveFormsModule,
  ]
})
export class VoiceProjectModule { }
