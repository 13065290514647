import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorpusIntentService } from 'app/alabia/api/corpus-intent.service';
import { Intent } from 'onevoice';

@Component({
    selector: 'alabia-intent-dialog',
    templateUrl: './intent-dialog.component.html',
    styleUrls: ['./intent-dialog.component.scss']
})
export class IntentDialogComponent implements OnInit {
    filteredOptions: string[] = [];

    constructor(
        private corpusIntentService: CorpusIntentService,
        @Inject(MAT_DIALOG_DATA) public data: Partial<Intent>
    ) { }

    ngOnInit() { }

    updateAutocomplete() {
        this.corpusIntentService.list(this.data?.bot?.id).subscribe(intents => {
            this.filteredOptions = intents
                .filter(value => value.name && value.name.startsWith(this.data?.name || ""))
                .map<string>(value => value.name || "");
        });
    }
}
