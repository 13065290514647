import { CommonModule, getLocaleNumberSymbol, NumberSymbol, registerLocaleData, DatePipe } from '@angular/common';
import localept from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule, Routes } from '@angular/router';
import { CleaningCalendarComponent } from './cleaning-calendar/cleaning-calendar.component';
import { CleaningDetailComponent } from './cleaning-detail/cleaning-detail.component';
import { CleaningMapsComponent } from './cleaning-maps/cleaning-maps.component';
import { CleaningMemberComponent } from './cleaning-member/cleaning-member.component';
import { CleaningMenuComponent } from './cleaning-menu/cleaning-menu.component';
import { CleaningReportComponent } from './cleaning-report/cleaning-report.component';
import { MaintenanceComponent } from './maintenance/maintenance.component'
import { EditMaintenanceComponent } from './maintenance/edit-maintenance/edit-maintenance.component'
import { EditWorkSchedulerComponent } from './work-scheduler/edit-work-scheduler/edit-work-scheduler.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ChartsModule } from 'ng2-charts';
import { CleaningDashboardComponent } from './cleaning-dashboard/cleaning-dashboard.component';
import { ZoneComponent } from './cleaning-dashboard/zone/zone.component';
import { CreateMapComponent } from './cleaning-maps/create-map/create-map.component';
import { CreateTaskComponent } from './cleaning-maps/create-task/create-task.component';
import { CreateMachineComponent } from './cleaning-maps/create-machine/create-machine.component'
import { MemberAddComponent } from './cleaning-member/cleaning-member/member-add/member-add.component';
import { ListComponent } from '@alabia/hw/tasks/list/list.component';

// calendario
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EmptyDataDialogComponent } from './cleaning-dashboard/empty-data-dialog/empty-data-dialog.component';
import { DailyComponent } from './cleaning-calendar/daily/daily.component';
import { AlterColorDirective } from './cleaning-calendar/calendar-month/diretives/alter-color.directive';
import { WeekComponent } from './cleaning-calendar/week/week.component';
import { TodayColorDirective } from './cleaning-calendar/week/directives/today-color.directive';
import { BackgroundTodayDirective } from './cleaning-calendar/week/directives/background-today.directive';
import { CreateUnitComponent } from './cleaning-maps/create-unit/create-unit.component';
import { WorkSchedulerComponent } from './work-scheduler/work-scheduler.component';
import { MyManualTasksComponent } from '@alabia/hw/my-manual-tasks/my-manual-tasks.component';
import { ManualComponent } from '@alabia/hw/tasks/manual/manual.component';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { SnackbarImageComponent } from './cleaning-detail/snackbar/snackbar-image.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: CleaningMenuComponent,
      }
    ]
  },
  {
    path: "real-time",
    pathMatch: "full",
    component: CleaningDetailComponent,
  },
  {
    path: "tasks",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ListComponent,
      },
      {
        path: ":id",
        component: ManualComponent,
      },
    ]
  },
  {
    path: "maps",
    pathMatch: "full",
    component: CleaningMapsComponent,
  },

  {
    path: "member",
    pathMatch: "full",
    component: CleaningMemberComponent,
  },
  {
    path: "manualtask",
    children: [
      {
        path: "",
        pathMatch: "full",
        component: MyManualTasksComponent,
      }
    ]
  },
  {
    path: "report",
    pathMatch: "full",
    component: CleaningReportComponent,
  },
  {
    path: "calendar",
    pathMatch: "full",
    component: CleaningCalendarComponent,
  },
  {
    path: "maintenance",
    pathMatch: "full",
    component: MaintenanceComponent,
  },
  {
    path: "scheduler",
    pathMatch: "full",
    component: WorkSchedulerComponent,
  },
  {
    path: "dashboard",
    pathMatch: "full",
    component: CleaningDashboardComponent,
    // children: [
    //   {
    //     path: ":zone",
    //     pathMatch: "full",
    //     component: ZoneComponent,
    //   }
    // ]
  },
  // children: [
  {
    path: ":zone",
    pathMatch: "full",
    component: ZoneComponent,
  }
  // ]
]

registerLocaleData(localept, 'pt');

getLocaleNumberSymbol('pt', NumberSymbol.CurrencyDecimal);

@NgModule({
  declarations: [
    CreateMachineComponent,
    CleaningMenuComponent,
    CleaningMapsComponent,
    CleaningMemberComponent,
    CleaningReportComponent,
    CleaningCalendarComponent,

    CleaningDetailComponent,
    CleaningDashboardComponent,
    MaintenanceComponent,
    EditMaintenanceComponent,
    EditWorkSchedulerComponent,
    ZoneComponent,
    CreateMapComponent,
    CreateTaskComponent,
    MemberAddComponent,
    EmptyDataDialogComponent,
    DailyComponent,
    AlterColorDirective,
    WeekComponent,
    TodayColorDirective,
    BackgroundTodayDirective,
    CreateUnitComponent,
    WorkSchedulerComponent,
    SnackbarImageComponent,


    // RobotDashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatIconModule,
    MatTableModule,
    MatCardModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatProgressBarModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatToolbarModule,
    // HwModule,

    ChartsModule,
    MatButtonModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,

    // BrowserAnimationsModule,
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory ,
    // }),



  ],
  entryComponents: [SnackbarImageComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    DatePipe
  ]
})
export class CleaningManagementModule { }
