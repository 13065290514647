<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Filtrar</span>
      <button mat-icon-button aria-label="Close dialog" (click)="matDialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <!-- FILTER -->
    <form class="filter-form" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="20px"
      *ngIf="filterForm" [formGroup]="filterForm">

      <div class="" fxLayout="column" fxFlex>
        <div class="" fxLayout="row" fxLayoutAlign="space-between">
          <mat-label>Valor</mat-label>
          <mat-checkbox formControlName="allValues" (change)="filterCheckbox()">Todos</mat-checkbox>
        </div>
        <mat-form-field appearance="outline">
          <input matInput name="name" formControlName="value">
        </mat-form-field>
      </div>

      <div class="" fxLayout="column" fxFlex>
        <div class="" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
          <mat-label>Data</mat-label>
          <mat-checkbox formControlName="allDates" (change)="filterCheckbox()">Todas</mat-checkbox>
        </div>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Data inicial</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="initialDate">
          <mat-datepicker-toggle matSuffix [for]="picker" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Data final</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="finalDate">
          <mat-datepicker-toggle matSuffix [for]="picker2" tabindex="-1"></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker2></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="" fxLayout="column" fxFlex>
        <mat-label>Atendimento</mat-label>
        <mat-form-field class="example-full-width" appearance="outline" fxFlex>
          <mat-label>Tipo de atendente</mat-label>
          <mat-select formControlName="agentType">
            <mat-option value="all">Todos</mat-option>
            <mat-option value="human">Humano</mat-option>
            <mat-option value="bot">Robô</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline" fxFlex>
          <mat-label>Atendente</mat-label>
          <mat-select name="agent" formControlName="agent">
            <mat-option *ngFor="let agent of agents" value="{{ agent.name }}">{{ agent.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <!-- / FILTER -->
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="mr-8" (click)="clear()" aria-label="Clear" matTooltip="Limpar">
      LIMPAR
    </button>

    <button mat-button class="save-button" aria-label="SAVE" (click)="filterData()">
      FILTRAR
    </button>
  </div>
</div>