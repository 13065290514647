<h1 mat-dialog-title>Treino do Modelo</h1>

<mat-dialog-content>
    <mat-form-field>
        <mat-label>Humor da Voz</mat-label>
        <mat-select [(ngModel)]="mood">
            <mat-option *ngFor="let mood of moodList" [value]="mood">
                {{ mood.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <table mat-table [dataSource]="datasource">
        <ng-container matColumnDef="voice">
            <th mat-header-cell *matHeaderCellDef>Voz</th>
            <td mat-cell *matCellDef="let model">{{ model.voice.title }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let model">{{ model.created | date }}</td>
        </ng-container>


        <ng-container matColumnDef="mood">
            <th mat-header-cell *matHeaderCellDef>Humor</th>
            <td mat-cell *matCellDef="let model">{{ model.mood.name }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let model">{{ model.status }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let model" style="text-align: center;">
                <button *ngIf="!model.active" mat-button (click)="setActive(model)">Usar</button>
                <mat-icon *ngIf="model.active">check_circle</mat-icon>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let model; columns: columns"></tr>

    </table>
    <mat-paginator [length]="datasource?.filteredData?.length" [pageIndex]="0" [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="submitTraining()">Treinar</button>
    <button mat-button [mat-dialog-close]="false">Fechar</button>
</mat-dialog-actions>