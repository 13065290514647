import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Bot, BotService, ConclusionTag, ConclusionTagService, DashboardListService, Graph, GraphPeriod, GraphStep, MemoryService, User, UserService } from 'onevoice';
import { CustomDashboardService } from '../custom-dashboard.service';

@Component({
  selector: 'main-graph-editor',
  templateUrl: './graph-editor.component.html',
  styleUrls: ['./graph-editor.component.scss']
})
export class GraphEditorComponent {
  public title: string = "Criar Gráfico";
  public form: FormGroup;

  public MIN_SPAN: number = 1;
  public MAX_SPAN: number = 3;

  public botList: Bot[] = [];
  public tagList: ConclusionTag[] = [];
  public userList: User[] = [];
  public extraList: string[] = [];

  private spanBetween(value: number): number {
    return Math.min(Math.max(value, this.MIN_SPAN), this.MAX_SPAN);
  }

  constructor(
    public service: DashboardListService,
    public dashService: CustomDashboardService,
    @Inject(MAT_DIALOG_DATA) private graph: Partial<Graph>,
    private reference: MatDialogRef<GraphEditorComponent>,

    botService: BotService,
    userService: UserService,
    tagService: ConclusionTagService,
    memoryService: MemoryService,
  ) {
    this.form = new FormGroup({
      "dashboard": new FormControl(graph.dashboard, [Validators.required]),
      "kind": new FormControl(graph.kind || dashService.KindList[0].name, [Validators.required]),
      "span": new FormControl(this.spanBetween(graph.span || 1), [Validators.required, Validators.min(this.MIN_SPAN), Validators.max(this.MAX_SPAN)]),
      "period": new FormControl(graph.period || GraphPeriod.MONTH_TO_DATE, [Validators.required]),

      "periodStart": new FormControl(graph.periodStart ? moment(new Date(graph.periodStart)) : moment(), []),
      "periodEnd": new FormControl(graph.periodEnd ? moment(new Date(graph.periodEnd)) : moment(), []),

      "bots": new FormControl(graph.bots || [], []),
      "agents": new FormControl(graph.agents || [], []),
      "tags": new FormControl(graph.tags || [], []),
      "extra": new FormControl(graph.extra, []),
      "timestep": new FormControl(graph.timestep || GraphStep.DAILY, []),
    });

    if (this.graph.id) {
      this.title = "Editar Gráfico";
    }

    botService.list().subscribe(bots => {
      this.botList = bots;
    });

    tagService.list().subscribe(tags => {
      this.tagList = tags;
    });

    userService.list().subscribe(users => {
      this.userList = users;
    });

    memoryService.list().subscribe(params => {
      params.map(value => value.name).filter(
        (value, index, array) => array.indexOf(value) == index
      ).forEach(value => {
        if (!value) {
          return;
        }
        this.extraList.push(value);
      })
    });
  }

  get periodList(): GraphPeriod[] {
    return Object.values(GraphPeriod);
  }

  get timestepList(): GraphStep[] {
    return Object.values(GraphStep);
  }

  public onSubmit(): void {
    if (this.graph.id) {
      this.service.update({
        id: this.graph.id,
        ...this.form.value,

        periodStart: this.form.value.periodStart.toDate().getTime(),
        periodEnd: this.form.value.periodEnd.toDate().getTime(),
      }).subscribe(updated => {
        this.reference.close(updated);
      });
    } else {
      this.service.create({ ...this.form.value }).subscribe(created => {
        this.reference.close(created);
      });
    }
  }

  public periodValue(period: GraphPeriod): string {
    let defaultValue = period.toString()

    switch (period) {
      case GraphPeriod.LAST_MONTH:
        return "Mês passado";
      case GraphPeriod.LAST_WEEK:
        return "Semana passada";
      case GraphPeriod.LAST_DAY:
        return "Ontem";
      case GraphPeriod.MONTH_TO_DATE:
        return "Últimos 30 dias";
      case GraphPeriod.WEEK_TO_DATE:
        return "Últimos 7 dias";
      case GraphPeriod.DAY_TO_DATE:
        return "Últimas 24 horas";
      case GraphPeriod.CUSTOM:
        return "Período específico";
      default:
        return defaultValue;
    }
  }

  public timestepValue(period: GraphStep): string {
    let defaultValue = period.toString()

    switch (period) {
      case GraphStep.HOURLY:
        return "Por Hora";
      case GraphStep.DAILY:
        return "Por Dia";
      case GraphStep.MONTHLY:
        return "Por Mês";
      case GraphStep.YEARLY:
        return "Por Ano";
      default:
        return defaultValue;
    }
  }

  get showPeriod(): boolean {
    return this.form.value.period == GraphPeriod.CUSTOM;
  }
}
