<div id="alabia-biometric-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">{{ title }}</h1>
  </div>

  <div class="content">
    <div class="center p-24 pb-56" fusePerfectScrollbar>
      <table class="biometric-list" mat-table [dataSource]="datasource">
        <ng-container matColumnDef="id">
          <th class="biometric-id" mat-header-cell *matHeaderCellDef>CPF</th>
          <td class="biometric-id" mat-cell *matCellDef="let item">{{ item.cpf }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th class="biometric-username" mat-header-cell *matHeaderCellDef>Nome de usuário</th>
          <td class="biometric-username" mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <ng-container matColumnDef="verify">
          <th class="biometric-verify" mat-header-cell *matHeaderCellDef></th>
          <td class="biometric-verify" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="verifyUser(item)">
              <mat-icon>mic</mat-icon>
            </button>
          </td>
        </ng-container>


        <ng-container matColumnDef="record">
          <th class="biometric-record" mat-header-cell *matHeaderCellDef></th>
          <td class="biometric-record" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="createRecording(item)">
              <mat-icon>cloud_upload</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
      </table>
    </div>
  </div>

</div>

<button class="alabia-biometric-add" mat-fab (click)="createUser()">
  <mat-icon>add</mat-icon>
</button>