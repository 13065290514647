import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Customer } from '../crm/customer.service';

function printDate(date: Date) {
  return formatDate(date, "yyyy/MM/dd HH:mm", "en-US");
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private path: string = "/api/report";

  constructor(protected http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public latestCustomers() {
    return this.http.get<Customer[]>(`${this.config.endpoint}${this.path}/customers/latest`, {
      responseType: "json",
      observe: "body",
    });
  }

  public conversationCount(from: Date, to: Date) {
    let params = new HttpParams().set("from", printDate(from)).set("to", printDate(to));
    return this.http.get<number>(`${this.config.endpoint}${this.path}/conversation/count`, {
      params: params,
    });
  }

  public activityPeak(from: Date, to: Date, botid?: number) {
    let params = new HttpParams().set("from", printDate(from)).set("to", printDate(to));
    if (botid) {
      params = params.set("bot", botid);
    }
    return this.http.get<any[][]>(`${this.config.endpoint}${this.path}/activity/peak`, {
      params: params,
    });
  }

  public agenBotRatio(from: Date, to: Date, botid?: number) {
    let params = new HttpParams().set("from", printDate(from)).set("to", printDate(to));
    if (botid) {
      params = params.set("bot", botid);
    }
    return this.http.get<any[][]>(`${this.config.endpoint}${this.path}/agent/ratio`, {
      params: params,
    });
  }

}
