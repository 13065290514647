import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from 'environments/environment';
import { TTSHistory, TTSService } from 'onevoice';
import { Observable } from 'rxjs';

@Component({
  selector: 'tts-cards',
  templateUrl: './tts-cards.component.html',
  styleUrls: ['./tts-cards.component.scss']
})
export class TtsCardsComponent implements OnInit {
  public baseURL: string = environment.staticBaseURL;
  public datasource = new MatTableDataSource<TTSHistory>([]);
  public INTERACTIVE_DELETE: boolean = true;
  public loadObs: boolean = false;
  public obs?: Observable<any>;


  @Input()
  set data(data: TTSHistory[]) {
    this.datasource.data = data;
  }
  @Output() eventEmitter = new EventEmitter();

  @ViewChild(MatPaginator, { static: false })
  set paginator(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }
  @ViewChild(MatSort)
  set sorter(sort: MatSort) {
    this.datasource.sort = sort;
  }

  public view: boolean = false;

  constructor(
    private service: TTSService,
    private snack: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.obs = this.datasource.connect();
  }

  public changeView(): void {
    this.eventEmitter.emit();
  }

  deleteItem(item: TTSHistory): void {
    if (this.INTERACTIVE_DELETE) {
      this.snack.open("Tem certeza?", "Sim").onAction().subscribe(_ => {
        this.service.delete(item.id).subscribe(ok => {
          if (ok) {
            this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
          }
        });
      });
    } else {
      this.service.delete(item.id).subscribe(ok => {
        if (ok) {
          this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
        }
      });
    }
  }

  public removeSpace(text: string): number {
    return text.replace(/\s/g, "").length;
  }
}
