import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { Mood } from './mood.model';

@Injectable({
  providedIn: 'root'
})
export class MoodService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  public list(): Observable<Mood[]> {
    return this.http.get<Mood[]>(`${this.config.mediaservice}/mood`, {});
  }
}
