<ng-container *ngIf="updater && updater.value && updater.value.attachment">
    <div class="alabia-facebook-carousel attachment attachment-template"
        *ngIf="updater.value.attachment.type == 'template'">
        <div class="payload payload-button" *ngIf="updater.value.attachment.payload.template_type == 'button'">
            <div class="text">{{ updater.value.attachment.payload.text }}</div>

            <ng-container *ngIf="updater.value.attachment.payload.elements">
                <mat-tab-group>
                    <mat-tab *ngFor="let element of updater.value.attachment.payload.elements" [label]="element.title">
                        <img class="facebook-carousel-image" [src]="getImage(element.image_url)"
                            onerror="this.src = '/assets/images/alabia/home-robos.png';">

                        <mat-action-list>
                            <button mat-list-item *ngFor="let button of element.buttons"
                                [title]="button.payload || button.url">
                                {{ button.title }}
                            </button>
                        </mat-action-list>
                    </mat-tab>
                </mat-tab-group>
                <mat-divider></mat-divider>
            </ng-container>

            <mat-action-list>
                <button mat-list-item *ngFor="let button of updater.value.attachment.payload.buttons"
                    [title]="button.payload || button.url">
                    {{ button.title }}
                </button>
            </mat-action-list>
        </div>
    </div>
</ng-container>