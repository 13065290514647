<mat-list>
  <mat-divider></mat-divider>
  <h3 mat-list-item mat-subheader>Treinamento</h3>
  <mat-list-item>
    <button mat-flat-button color="primary" (click)="start()" [disabled]="training">Treinar</button>
    <button mat-stroked-button (click)="advanced = !advanced">Avançado</button>
  </mat-list-item>
  <mat-list-item *ngIf="advanced">
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="params.epochs" placeholder="Épocas">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="advanced">
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="params.dropout" placeholder="Dropout">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="advanced">
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="params.recurrent_dropout" placeholder="Recurrent Dropout">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="advanced">
    <mat-form-field>
      <input matInput type="number" [(ngModel)]="params.num_layers" placeholder="Camadas">
    </mat-form-field>
  </mat-list-item>
  <mat-divider></mat-divider>
  <h3 mat-list-item mat-subheader>Histórico</h3>
  <mat-list-item *ngFor="let item of history">{{ item.id }} &raquo; {{ item.state }} &raquo; {{ item.status }}
  </mat-list-item>
</mat-list>