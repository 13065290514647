import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'environments/environment';
import { ApiLimit, AuthenticationService, LimitService, TTSHistory, TTSService } from 'onevoice';

@Component({
  selector: 'alabia-tts-old',
  templateUrl: './tts.component.html',
  styleUrls: ['./tts.component.scss']
})
export class TtsOldComponent implements OnInit {
  public icon: string = "speaker";
  public columnsToDisplay: string[] = [
    "id", "voice-model", "text", "duration", "download", "delete"
  ]

  public generate: string = "";
  public voiceModel: string = "";
  public baseURL: string = environment.staticBaseURL;
  private audio: HTMLAudioElement = new Audio();
  public generating: boolean = false;


  public voices: string[] = [];
  public datasource = new MatTableDataSource<TTSHistory>([]);
  @ViewChild(MatPaginator, { static: false }) public paginator?: MatPaginator;

  public INTERACTIVE_DELETE: boolean = true;
  public limitValue: number = 0;

  constructor(private auth: AuthenticationService, private service: TTSService, private snack: MatSnackBar, private limits: LimitService) { }

  ngOnInit() {
    this.limits.check().subscribe(_ => {
      this.limits.show(ApiLimit.TTS).subscribe(value => {
        this.limitValue = value;
      });
    });
    this.auth.systemToken().then(token => {
      this.loadHistory();
      this.service.voices().subscribe(voices => {
        this.voices = voices;
        if (voices.length > 0) {
          this.voiceModel = voices[0]
        }
      });
    });


  }

  loadHistory() {
    this.service.list().subscribe(history => {
      this.datasource.data = history.sort((a, b) => (a.id < b.id) ? 1 : -1);
      if (this.paginator) { this.datasource.paginator = this.paginator; }
    });
  }

  deleteItem(item: TTSHistory) {
    if (this.INTERACTIVE_DELETE) {
      this.snack.open("Tem certeza?", "Sim").onAction().subscribe(_ => {
        this.service.delete(item.id).subscribe(ok => {
          if (ok) {
            this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
          }
        });
      });
    } else {
      this.service.delete(item.id).subscribe(ok => {
        if (ok) {
          this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
        }
      });
    }
  }

  generateAudio() {
    if (this.generating) {
      return;
    }

    this.generating = true;
    this.service.generate(this.voiceModel, this.generate).subscribe(data => {
      this.generating = false;
      this.loadHistory();

      let reader = new FileReader();
      reader.addEventListener("loadend", (ev) => {
        if (typeof reader.result === "string") {
          this.audio.src = reader.result;
          this.audio.play();
        }
      });
      reader.readAsDataURL(data);
      this.generate = "";

      this.limits.show(ApiLimit.TTS).subscribe(value => {
        this.limitValue = value;
      });
    }, err => {
      this.generating = false;
      if (err.status == 429) {
        this.snack.open("A sua franquia de geração de áudio não é suficiente.", "OK");
      } else {
        this.snack.open("Aconteceu um erro durante a geração do áudio. Tente novamente.", "OK");
      }
    });
  }
}
