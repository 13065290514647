import { Ownable } from "../auth/ownable.service";

export enum ChannelStatus {
    DISABLED = "DISABLED",
    ACTIVE = "ACTIVE",
}

export interface Channel extends Ownable {
    name: string;
    idBot: number;
    status: ChannelStatus;
}
