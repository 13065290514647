import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { AuthenticationService } from '../auth/authentication.service';
import { AgentStompService } from '../stomp/agent-stomp.service';
import { ConclusionTag } from './conclusion-tag.model';
import { ChangeType, QueueAssignment, QueueChange } from './queue-assignment.model';

function printDate(date: Date) {
  return formatDate(date, "yyyy/MM/dd HH:mm", "en-US");
}

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {
  OnChange: Subject<QueueChange>;

  constructor(
    protected http: HttpClient,
    public stomp: AgentStompService,
    public auth: AuthenticationService,
    @Inject(APP_CONFIG) public config: ApiConfiguration,
  ) {
    this.OnChange = this.stomp.OnChange;
  }

  list(all: boolean = false, from?: Date, to?: Date): Observable<QueueAssignment[]> {
    let params = new HttpParams();
    if (from !== undefined) {
      console.log("Add from param");
      params = params.set("from", printDate(from));
    }

    if (to !== undefined) {
      console.log("Add to param");
      params = params.set("to", printDate(to));
    }
    console.log("Params to list assignments", params);

    if (all) {
      return this.http.get<QueueAssignment[]>(`${this.config.endpoint}/queue/changes/all`, {
        params: params,
      });
    }
    return this.http.get<QueueAssignment[]>(`${this.config.endpoint}/queue/changes`);
  }

  retrieve(id: number): Observable<QueueAssignment> {
    return this.http.get<QueueAssignment>(`${this.config.endpoint}/queue/changes/${id}`);
  }

  release(id: number): Observable<Boolean> {
    return this.http.post<Boolean>(`${this.config.endpoint}/queue/changes/${id}`, null);
  }

  updateAssignment(assignment: QueueAssignment, type: ChangeType, tag?: ConclusionTag, description?: string) {
    let user = this.auth.current.value;
    if (user && assignment.queue.id) {
      this.stomp.sendChange({
        assignment: assignment.id,
        conversation: assignment.conversation,
        queue: assignment.queue.id,
        type: type,
        agent: user.id,

        tag: tag ? tag.id : undefined,
        description: description,
      });
    }
  }
}
