<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Adicionar Informação</span>
      <button mat-icon-button aria-label="Close dialog" (click)="matDialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form *ngIf="customerInformationForm" class="filter-form" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="20px" [formGroup]="customerInformationForm" (ngSubmit)="onSubmit(customerInformationForm)" ngNativeValidate #customerInfoForm="ngForm">
      <div class="" fxLayout="column" fxFlex>
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput #fieldName id="name" name="name" formControlName="name" required>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Descrição</mat-label>
          <input matInput #description id="description" name="description" formControlName="description" maxlength="25" required>
          <mat-hint align="end">{{description.value.length}} / 25</mat-hint>
        </mat-form-field>
      </div>
      <div class="" fxLayout="column" fxFlex>
        <mat-label>Pré-visualização</mat-label>
        <mat-form-field appearance="outline">
          <mat-label>{{ fieldName.value || 'Nome do Campo' }}</mat-label>
          <input matInput maxlength="25" required>
          <mat-hint>{{ description.value || 'Descrição do Campo' }}</mat-hint>
        </mat-form-field>
      </div>
    </form>
    <!-- /FIELD CREATOR -->
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    <button mat-button class="mr-8" aria-label="Clear" matTooltip="Limpar">
      LIMPAR
    </button>

    <button mat-button class="save-button" aria-label="SAVE" type="button" (click)="onSubmit(customerInformationForm)">
      CRIAR
    </button>
  </div>
</div>