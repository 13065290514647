import { SkillManagerForm } from './skill-manager-form';

export class Skill {
    id?: number;
    name: string;

    constructor(skillForm: SkillManagerForm) {
        this.name = skillForm.name;
    }
}
