<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h3 mat-dialog-title>{{ title }}</h3>

    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Gráfico</mat-label>
            <mat-select formControlName="kind">
                <mat-option *ngFor="let kind of dashService.KindList" [value]="kind.name">
                    {{ kind.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
                <mat-option *ngFor="let period of periodList" [value]="period">
                    {{ periodValue(period) }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="showPeriod">
            <mat-label>Período Específico</mat-label>

            <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
                <input matStartDate formControlName="periodStart" placeholder="Início">
                <input matEndDate formControlName="periodEnd" placeholder="Fim">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="form.controls.periodStart.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="form.controls.periodEnd.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Granularidade</mat-label>
            <mat-select formControlName="timestep">
                <mat-option *ngFor="let timestep of timestepList" [value]="timestep">
                    {{ timestepValue(timestep) }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field>
            <mat-label>Robôs</mat-label>
            <mat-select formControlName="bots" multiple>
                <mat-option *ngFor="let bot of botList" [value]="bot.id">
                    {{ bot.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Agentes</mat-label>
            <mat-select formControlName="agents" multiple>
                <mat-option *ngFor="let user of userList" [value]="user.id">
                    {{ user.email }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Etiquetas</mat-label>
            <mat-select formControlName="tags" multiple>
                <mat-option *ngFor="let tag of tagList" [value]="tag.id">
                    {{ tag.title }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Parametros</mat-label>
            <mat-select formControlName="extra">
                <mat-option *ngFor="let extra of extraList" [value]="extra">
                    {{ extra }}
                </mat-option>
            </mat-select>

            <mat-hint>Válido apenas para alguns gráficos</mat-hint>
        </mat-form-field>

        <mat-slider [min]="MIN_SPAN" [max]="MAX_SPAN" formControlName="span"></mat-slider>
    </div>

    <div mat-dialog-actions>
        <button mat-button type="submit">Salvar</button>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
    </div>
</form>