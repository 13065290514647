import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendApiRequestService } from 'app/alabia/api/backend-api-request.service';
import { InformationType } from 'onevoice';

@Component({
  selector: 'alabia-customer-field-dialog',
  templateUrl: './customer-field-dialog.component.html',
  styleUrls: ['./customer-field-dialog.component.scss']
})
export class CustomerFieldDialogComponent implements OnInit {
  customerID: any;
  myControl = new FormControl();

  public customerInformationForm?: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public matDialogRef: MatDialogRef<CustomerFieldDialogComponent>,
    private _backendApiService: BackendApiRequestService,
  ) {
    this.customerID = _data.data;
  }

  ngOnInit() {
    this.customerInformationForm = this.informationForm();
  }

  informationForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      description: new FormControl(''),
    });
  }

  onSubmit(e: any) {
    const infoType: Partial<InformationType> = {
      name: e.value.name,
      description: e.value.description,
    };

    if (this._backendApiService.createInformationType(infoType)) {
      this.matDialogRef.close()
    };
  }


}
