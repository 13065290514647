import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mood, Voice, VoiceSnippet, VoiceSnippetService } from 'onevoice';
import { content } from './data';

export interface Wizard {
  voice: Voice,
  mood: Mood,
}

@Component({
  selector: 'alabia-voice-wizard',
  templateUrl: './voice-wizard.component.html',
  styleUrls: ['./voice-wizard.component.scss']
})
export class VoiceWizardComponent implements OnInit, OnDestroy {
  public snippetList: VoiceSnippet[] = [];
  public nextSnippet: string = "";
  public nextIndex: number = 0

  private mr?: MediaRecorder;
  public recording: boolean = false;
  private stream?: MediaStream;
  private file?: File;
  private audio: HTMLAudioElement = new Audio();

  constructor(
    private reference: MatDialogRef<VoiceWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Wizard,
    private snippets: VoiceSnippetService,
  ) { }

  ngOnInit(): void {
    if (!this.data.voice.id) { return }
    this.snippets.list(this.data.voice.id).subscribe(snippetList => {
      this.snippetList = snippetList;
      this.nextSnippet = content[0];

      this.next();
    });

    if (
      navigator.mediaDevices == undefined ||
      navigator.mediaDevices.getUserMedia == null
    ) {
      this.reference.close("Microfone não disponível")
    }
    this.createRecorder();
  }

  ngOnDestroy(): void {
    if (this.stream) {
      this.stream.getTracks()[0].stop();
    }
  }


  private checkDone(): boolean {
    return this.snippetList.filter(snippet => snippet.text.toLowerCase() == this.nextSnippet.toLowerCase()).length > 0
  }

  next() {
    while (this.checkDone()) {
      console.log("Go to next")
      this.nextIndex++;
      if (this.nextIndex >= content.length) {
        console.log("Close");
        this.reference.close();
        return;
      }
      this.nextSnippet = content[this.nextIndex];
    }
  }

  createRecorder() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      stream => {
        this.stream = stream;
        let mr = new MediaRecorder(stream);
        this.mr = mr;

        let chunks: BlobPart[] = [];
        mr.ondataavailable = (data: BlobEvent) => {
          chunks.push(data.data);
          console.log("More data");
        };
        mr.onstop = () => {
          this.file = new File(chunks, 'audio.webm', {
            type: 'audio/ogg; codecs=opus',
          });
          chunks = [];
        };
      },
      err => {
        this.reference.close("Microfone não disponível");
      }
    );
  }

  startMicrofone() {
    try {
      if (this.mr) {
        this.mr.start();
      }
    } catch (error) {
      return false;
    }
    return true;
  }

  stopMicrofone() {
    try {
      if (this.mr) { this.mr.stop(); }
    } catch (error) {
    }
    this.createRecorder();
  }

  toggleMicrofone() {
    if (this.recording) {
      this.stopMicrofone();
      this.recording = false;
    } else {
      this.recording = this.startMicrofone();
    }
  }

  listen() {
    if (!this.file) { return; }
    let reader = new FileReader();
    reader.onloadend = (event) => {
      if (!reader.result) { return; }
      let dataURL = reader.result.toString();
      console.log(dataURL);
      this.audio.src = dataURL;
      this.audio.play();
    }
    reader.readAsDataURL(this.file);
  }

  save() {
    if (!this.data.voice.id || !this.file) { return }
    this.snippets.create(this.data.voice.id, this.data.mood.id, this.nextSnippet, this.file).subscribe(created => {
      this.snippetList.push(created);
      this.next();
    });
  }
}
