import { Component, Input } from '@angular/core';
import { Message } from '../../stomp/message.model';

@Component({
  selector: 'lib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent {
  @Input("message")
  public message?: Message;

  constructor() { }
}
