import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { STTHistory, STTResponse } from './sttresponse.model';

@Injectable({
  providedIn: 'root'
})
export class STTService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  convert(file: File): Observable<STTResponse> {
    const data: FormData = new FormData();
    data.set('file', file);
    return this.http.post<STTResponse>(`${this.config.mediaservice}/audio/stt`, data, {});
  }

  list(): Observable<STTHistory[]> {
    return this.http.get<STTHistory[]>(`${this.config.mediaservice}/stt`, {});
  }

  delete(id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.config.mediaservice}/stt/${id}`, {
      responseType: 'json',
    });
  }
}
