import { UploadComponent } from '@alabia/chat/upload/upload.component';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthenticationService, UserService } from 'onevoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvatarComponent } from './avatar/avatar.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean = false;
    rightNavbar: boolean = false;
    hiddenNavbar: boolean = false;
    currentUser = this.authService.current.value;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        public authService: AuthenticationService,
        public dialog: MatDialog,
        public snack: MatSnackBar,
        public users: UserService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings: any) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value: any): void {
        // Do your search here...
    }

    public openPasswordDialog() {
        let reference = this.dialog.open<ChangePasswordComponent, any, any>(ChangePasswordComponent, {
            width: "500px",
            maxWidth: "80%",
        });
        reference.afterClosed().subscribe(ok => {
            if (ok == null) {
                return;
            }

            this.snack.open(
                ok ? "Sua senha foi atualizada com sucesso" : "Infelizmente não foi possível atualizar sua senha",
                "OK",
            );
        });
    }

    public getOrganization(): string {
        let user = this.authService.current.value;
        if (!user) {
            return "";
        }
        return user.organization.name
    }

    public sendAvatar() {
        this.dialog.open(AvatarComponent);
    }
}
