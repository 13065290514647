import { Pipe } from '@angular/core';

@Pipe({
    name: 'ellipsis'
})
export class EllipsisPipe {
    transform(val: string | undefined, args: number | undefined) {
        if (!args || !val) {
            return val;
        }

        if (val.length > args) {
            return val.substring(0, args) + '...';
        } else {
            return val;
        }
    }
}
