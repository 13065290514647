<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Resposta</h1>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Tipo de Resposta</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of TypeList" [value]="type">{{ type }}</mat-option>
      </mat-select>
      <mat-hint>Que tipo de conteúdo será enviado? (ex.: texto, imagem, JSON)</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="selectedType() === ResponseType.TEXT" appearance="outline">
      <mat-label>Conteúdo</mat-label>
      <textarea matInput formControlName="content" rows="8"></textarea>
      <mat-hint>
        O conteúdo que será enviado. Pode ser enriquecido usando a linguagem de templates
        <a href="https://mustache.github.io/">Mustache</a>.
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="selectedType() === ResponseType.JSON" appearance="outline">
      <mat-label>Conteúdo</mat-label>
      <textarea matInput formControlName="content" rows="8"></textarea>
      <mat-hint>
        O conteúdo que será enviado. Pode ser enriquecido usando a linguagem de templates
        <a href="https://mustache.github.io/">Mustache</a>.
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="selectedType() === ResponseType.MEDIA" appearance="outline">
      <mat-label>Conteúdo</mat-label>
      <input type="url" matInput formControlName="content">
      <button type="button" mat-icon-button matSuffix (click)="openMediaDialog()">
        <mat-icon>perm_media</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field *ngIf="selectedType() === ResponseType.MEDIA" appearance="outline">
      <mat-label>Tipo de Media</mat-label>
      <mat-select formControlName="mediaType">
        <mat-option *ngFor="let type of MediaList" [value]="type">{{ type }}</mat-option>
      </mat-select>
      <mat-hint>Qual é o formato da media enviada? (ex.: vídeo, image, audio)</mat-hint>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="submit" [disabled]="!form.valid" color="primary" mat-button type="submit">
      {{ submitText }}
    </button>
    <button mat-button [mat-dialog-close]="null">Cancelar</button>
  </mat-dialog-actions>
</form>