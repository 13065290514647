<div id="alabia-stt-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">STT</h1>
  </div>

  <!-- Generate Áudio -->
  <form (submit)="submitAudio()">
    <input #fileInput hidden multiple name="content" type="file" (change)="selectFile($event)">

    <mat-card id="alabia-stt-card" class="m-24">
      <button mat-raised-button matPrefix class="mr-8 mb-8" type="button" (click)="fileInput.click()">
        Selecionar Áudios
      </button>

      <button mat-raised-button matSuffix type="submit">
        <mat-icon>send</mat-icon>
      </button>

      <mat-hint class="hint">Limite restante: {{ limitValue }}</mat-hint>
    </mat-card>
  </form>


  <div class="content">
    <div class="center p-24 pb-56" fusePerfectScrollbar>
      <table class="stt-list" mat-table [dataSource]="datasource">
        <ng-container matColumnDef="id">
          <th class="stt-id" mat-header-cell *matHeaderCellDef>ID</th>
          <td class="stt-id" mat-cell *matCellDef="let item">{{ item.id }}</td>
        </ng-container>

        <ng-container matColumnDef="transcription">
          <th class="stt-transcription" mat-header-cell *matHeaderCellDef>Transcrição</th>
          <td class="stt-transcription" mat-cell *matCellDef="let item">{{ item.transcription }}</td>
        </ng-container>

        <ng-container matColumnDef="play">
          <th class="stt-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="stt-action-delete" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="playItem(item)">
              <mat-icon>play_circle_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="download">
          <th class="stt-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="stt-action-delete" mat-cell *matCellDef="let item">
            <a mat-icon-button [href]="downloadUrl(item)">
              <mat-icon>cloud_download</mat-icon>
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th class="stt-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="stt-action-delete" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="deleteItem(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
      </table>
      <mat-paginator pageSize="1000"></mat-paginator>
    </div>
  </div>
</div>