import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ownable, OwnableService } from '../auth/ownable.service';
import { Conversation } from '../dialog/conversation.model';
import { CustomerChannel } from './customer-channel.model';
import { CustomerIdentifier } from './customer-identifier.model';
import { CustomerInformation } from './customer-information.service';

export interface Customer extends Ownable {
  firstName: string;
  lastName: string;

  identifiers?: CustomerIdentifier[];
  contacts?: CustomerChannel[];
  info?: CustomerInformation[];
}


@Injectable({
  providedIn: 'root'
})
export class CustomerService extends OwnableService<Customer> {
  protected path: string = `${this.config.endpoint}/customer`;

  public conversations(id: number): Observable<Conversation[]> {
    return this.http.get<Conversation[]>(`${this.path}/${id}/history`, {
      responseType: "json",
      observe: "body",
    });
  }
}
