import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ChatService } from 'app/alabia/chat/chat.service';
import { BotMemory, Customer, CustomerChannel, CustomerInformation, CustomerInformationService, MemoryService } from 'onevoice';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'chat-contact-sidenav',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatContactSidenavComponent implements OnInit, OnDestroy {
    contact?: Customer;
    public informationList: CustomerInformation[] = [];
    public memoryList: BotMemory[] = [];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ChatService} _chatService
     */
    constructor(
        private _chatService: ChatService,
        public information: CustomerInformationService,
        public memoryService: MemoryService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._chatService.onChatSelected
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(chat => {
                if (chat && chat.assignment) {
                    this.contact = chat.customer;
                    this.information.list(this.contact.id).subscribe(information => {
                        this.informationList = information;
                    });
                    this.memoryService.list(chat.assignment.bot, this.contact).subscribe(memory => {
                        this.memoryList = memory.filter(value => value.assignment == null || chat.assignment === undefined || value.assignment == chat.assignment.id);
                    });
                } else {
                    this.contact = undefined;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public getContacts(): CustomerChannel[] {
        if (this.contact && this.contact.contacts) {
            return this.contact.contacts.filter(value => value.identifier && value.identifier.length > 0);
        }
        return []
    }
}
