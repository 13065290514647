<div *ngIf="redirectList !== undefined; else spinner" class="qrcode-list">
  <div class="menuFilter">
    <div class="filter">
      <mat-form-field appearance="outline" color="accent" class="location">
        <mat-label>Tipo de Tarefa</mat-label>
        <mat-select (selectionChange)="filterByTypeTask($event.value)">
          <mat-option *ngFor="let type of taskTypes" value="{{type}}">{{type}} </mat-option>
          <mat-option value="all">Todos</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter">
      <mat-form-field appearance="outline" color="accent" class="location" *ngIf="typeTask == 'MANUAL'">
        <mat-label>Local</mat-label>
        <mat-select (selectionChange)="selectUnit($event.value)">
          <mat-option *ngFor="let unit of unitList" value="{{unit.id}}">{{unit.name}} </mat-option>
          <mat-option value="all">Todos</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter">
      <mat-form-field appearance="outline" color="accent" class="location" *ngIf="typeTask == 'MAINTENANCE'">
        <mat-label>Maquina</mat-label>
        <mat-select (selectionChange)="selectUnit($event.value)">
          <mat-option *ngFor="let machine of allMachines" value="{{machine}}">{{machine.nickName}} </mat-option>
          <mat-option value="all">Todos</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <mat-paginator class="qrcode-paginator" [length]="10" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>

  <div class="qrcode-grid">
    <mat-card *ngFor="let redirect of redirectList">
      <mat-card-header>
        <mat-card-title>Código #{{ redirect.id }}</mat-card-title>
      </mat-card-header>

      <img mat-card-image [src]="service.getImageUrl(redirect.id)">

      <mat-card-content [ngSwitch]="redirect.type">
        <mat-checkbox [checked]="isSelected(redirect)" (change)="selectRedirect(redirect, $event)">
        </mat-checkbox>

        <ng-container *ngSwitchCase="RedirectType.MACHINE_TASK">
          Redirecionamento para a tarefa #{{ redirect.destination }}
        </ng-container>

        <ng-container *ngSwitchCase="RedirectType.MANUAL_TASK">
          Redirecionamento para a tarefa #{{ redirect.destination }}
        </ng-container>
        <ng-container *ngSwitchCase="RedirectType.MAINTENANCE_TASK">
          Redirecionamento para a tarefa #{{ redirect.destination }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          <small class="qrcode-muted">Código QR não-definido</small>
        </ng-container>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-icon-button (click)="editRedirect(redirect)">
          <mat-icon>edit</mat-icon>
        </button>
        <a mat-icon-button [routerLink]="['/qrcode', redirect.id]">
          <mat-icon>visibility</mat-icon>
        </a>
      </mat-card-actions>
    </mat-card>
  </div>

  <button class="qrcode-list-fab" mat-fab (click)="editRedirect()">
    <mat-icon>add</mat-icon>
  </button>
</div>

<ng-template #spinner>
  <mat-spinner class="alabia-view-spinner"></mat-spinner>
</ng-template>
