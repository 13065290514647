import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as Chart from 'chart.js';
import { AuthenticationService, MachineExecution, MachineExecutionService } from 'onevoice';

@Component({
  selector: 'main-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.scss']
})

export class ZoneComponent implements OnInit {



  constructor( ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

}
