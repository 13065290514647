<div class="page-layout blank dashboard">
    <!-- messages per month -->
    <div class="main-widget">
        <div class="position-relative p-24 purple-600" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="start start">
                <span class="h2">Mensagens</span>
                <span class="h5 secondary-text">Mensagens recebidas por mês</span>
            </div>
        </div>
        <div class="position-relative h-256 pb-16 purple-600">
            <canvas baseChart [data]="mpmData" [labels]="mpmLabels" [options]="mpmConfig" [colors]="mpmColors"
                chartType="line"></canvas>
        </div>
    </div>
    <!-- / messages per month -->
    <div class="content content--grid-template-default">
        <div class="column">
            <div class="column-container row-3">
                <p class="column-container--title">Como estão as conversas</p>
                <div class="column-container-cards">
                    <div class="graph-card">
                        <div class="h3 secondary-text">Conversas por mês</div>
                        <div class="font-size-54 font-weight-300 line-height-1 mt-8">
                            {{ cpmData[cpmData.length-1] }}
                        </div>
                        <canvas *ngIf="cpmData.length !== 0" baseChart chartType="bar" [colors]="appColors"
                            [data]="cpmData" [labels]="cpmLabels" [options]="widgetConfig"></canvas>
                        <div *ngIf="cpmData.length === 0" class="no-data">
                            <p>Sem dados</p>
                        </div>
                    </div>
                    <div class="graph-card">
                        <div class="h3 secondary-text">Usuários por mês</div>
                        <div class="font-size-54 font-weight-300 line-height-1 mt-8">
                            {{ upmData[upmData.length-1] }}
                        </div>
                        <canvas *ngIf="upmData.length !== 0" baseChart [colors]="appColors" [data]="upmData"
                            [labels]="upmLabels" chartType="bar" [options]="widgetConfig"></canvas>
                        <div *ngIf="upmData.length === 0" class="no-data">
                            <p>Sem dados</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="column-container column-1">
                <p class="column-container--title">Intenções</p>
                <div class="column-container-cards">
                    <div class="graph-card">
                        <canvas *ngIf="tiData.length !== 0" baseChart [colors]="appColors" [data]="tiData"
                            [labels]="tiLabels" [options]="tiConfig" chartType="doughnut"></canvas>
                        <div *ngIf="tiData.length === 0" class="no-data">
                            <p>Sem dados</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column-container column-1">
                <p class="column-container--title">De onde os seus usuários estão vindo?</p>
                <div class="column-container-cards">
                    <div class="graph-card">
                        <div class="h3 secondary-text">Conversas por Canal</div>
                        <canvas *ngIf="cppData.length !== 0" baseChart [colors]="appColors" [data]="cppData"
                            [labels]="cppLabels" [options]="cppConfig" chartType="doughnut"></canvas>
                        <div *ngIf="cppData.length === 0" class="no-data">
                            <p>Sem dados</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Last users from 24 HOURS -->
    <div class="content content--grid-template-table">
        <p class="content--title">Usuários das últimas 24 horas</p>
        <mat-table *ngIf="latestsCustomersDataSource" [dataSource]="latestsCustomersDataSource">
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.id}}</mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="firstName">
                <mat-header-cell *matHeaderCellDef> Nome </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.firstName ? element.firstName + ' ' + element.lastName : 'Customer #' + element.id |
                    uppercase}}
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"
                [routerLink]="['/customer', row.id, 'history']" [queryParams]="{returnUrl: 'dashboard'}"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
    </div>
    <!-- / Last users from 24 HOURS -->

</div>