import { sample } from "lodash"

export const sampleLines: string[] = [
    "Olá pessoal, testando a solução de biometria de voz da Alabia. Essa ferramenta pretende revolucionar o mercado de identificação de fraudes.",
    "Tudo bem pessoal, esse é um teste de cadastro e comparação de vozes entre diferentes locutores. Agora podemos acrescentar mais segurança no negócios remotos.",
    "A quantidade de audio suficiente para a comparação está entre dez e quinze segundos de fala. Se a quantidade for essa, vai dar certo!",
    "A Alabia tem empreendido nas mais sofisticadas ferramentas de inteligência artificial para dar suporte a inúmeros negócios.",
    "Tudo mundo esperando o fim da pandemia, no entanto, isso só será possível com uma vacinação em massa.",
    "Inteligência Artificial e usabilidade é a bíblia da Alabia. Por isso, estamos causando uma desrupção no mercado brasileiro.",
]

export function getSampleLine(): string {
    return sample<string>(sampleLines) || sampleLines[0];
}