<div class="training">
  <div class="training__params">
    <h2>Configurações</h2>
    <mat-form-field appearance="outline">
      <mat-label>Épocas</mat-label>
      <input matInput type="number" placeholder="Épocas" [(ngModel)]="params.epochs">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Dropout</mat-label>
      <input matInput type="number" placeholder="Dropout" [(ngModel)]="params.dropout">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Recurrent Dropout</mat-label>
      <input matInput type="number" placeholder="Recurrent Dropout" [(ngModel)]="params.recurrent_dropout">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Camadas</mat-label>
      <input matInput type="number" placeholder="Camadas" [(ngModel)]="params.num_layers">
    </mat-form-field>
    <div class="btn-container">

      <button mat-flat-button color="primary" type="button" (click)="start()" [disabled]="training">Treinar</button>
    </div>
  </div>
  <div class="training__history">
    <h2>Histórico</h2>
    <mat-list-item *ngFor="let item of history">{{ item.id }} &raquo; {{ item.state }} &raquo; {{ item.status }}
    </mat-list-item>
  </div>
</div>