import { Injectable } from '@angular/core';
import { OwnableService } from '../auth/ownable.service';
import { ConclusionTag } from './conclusion-tag.model';

@Injectable({
  providedIn: 'root'
})
export class ConclusionTagService extends OwnableService<ConclusionTag>  {
  protected path: string = `${this.config.endpoint}/queue/tags`;
}
