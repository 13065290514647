import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MachineMap, MachineMapService, MachineTask, User, UserCreateRequest, UserService } from 'onevoice';

@Component({
  selector: 'main-member-add',
  templateUrl: './member-add.component.html',
  styleUrls: ['./member-add.component.scss']
})
export class MemberAddComponent {
  public form: FormGroup;
  public isCreate: boolean = false; 

  constructor(
    public dialog: MatDialog,
    public reference: MatDialogRef<MemberAddComponent>,
    public service: UserService,
    @Inject(MAT_DIALOG_DATA) public data: Partial<User> = {},
  ) {
    this.isCreate = (data.email == null || data.email.length == 0);
    console.log(data)
    this.form = new FormGroup({
      "first_name": new FormControl( data.details?.first_name || undefined, [Validators.required, Validators.minLength(4)]),
      "last_name": new FormControl( data.details?.last_name || undefined, [Validators.required]),
      "email": new FormControl(data.email || undefined, Validators.compose([Validators.email, Validators.required])),
      "phone": new FormControl( data.details?.phone || undefined, [Validators.required]),
      "password": new FormControl(data.password || undefined, [Validators.required]),
    });
  }

  public submitUser(): void {
    this.reference.close({
      ...this.data,
      ...this.form.value,
    })
  }

  public compareUser(map1: User, map2: User) {
    return map1.id == map2.id
  }

  ngOnInit(): void {
  }

}
