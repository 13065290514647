import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SkillManagerForm } from 'app/models/skill-manager-form';
import { Skill } from 'app/models/skill';

@Component({
  selector: 'alabia-skill-manager-dialog',
  templateUrl: './skill-manager-dialog.component.html',
  styleUrls: ['./skill-manager-dialog.component.scss']
})
export class SkillManagerDialogComponent implements OnInit {

  action: string;
  skill: Skill = {
    name: ""
  };

  skillForm?: FormGroup;
  dialogTitle: string = "";

  constructor(
    public matDialogRef: MatDialogRef<SkillManagerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
  ) {

    this.action = _data.action;
    this.open(_data);
  }

  ngOnInit() { }

  open(_data: { skill: Skill; }): void {
    if (this.action === 'edit') {
      this.dialogTitle = 'Editar skill';
      this.skill = _data.skill;
      this.skillForm = this.createSkillForm(false);
    }
    else {
      this.dialogTitle = 'Nova skill';
      this.skill = new SkillManagerForm({});
      this.skillForm = this.createSkillForm(true);
    }
  }

  createSkillForm(isNewSkill: boolean): FormGroup {
    return this._formBuilder.group({
      id: isNewSkill ? '' : this.skill.id,
      name: isNewSkill ? '' : this.skill.name,
    });
  }

  saveData(isNewSkill?: boolean) {
    if (!this.skillForm) {
      return;
    }
    if (isNewSkill) { this.matDialogRef.close(this.skillForm.value); }
    else { this.matDialogRef.close(['save', this.skillForm]); }
  }

}
