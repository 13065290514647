import { Injectable } from '@angular/core';
import { User } from './user.model';

const TOKEN_STORE = "alabia-token";
const USER_STORE = "alabia-user";
const SERVICE_STORE = "alabia-service";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() { }

  public clear() {
    this.token = null;
    this.user = null;
    this.service = null;
  }

  set token(data: string | null) {
    localStorage.setItem(TOKEN_STORE, JSON.stringify(data));
  }
  set user(data: User | null) {
    localStorage.setItem(USER_STORE, JSON.stringify(data));
  }
  set service(data: string | null) {
    localStorage.setItem(SERVICE_STORE, JSON.stringify(data));
  }

  get token(): string | null {
    let content = localStorage.getItem(TOKEN_STORE);
    if (!content) {
      return null;
    }
    return JSON.parse(content);
  }
  get user(): User | null {
    let content = localStorage.getItem(USER_STORE);
    if (!content) {
      return null;
    }
    return JSON.parse(content);
  }
  get service(): string | null {
    let content = localStorage.getItem(SERVICE_STORE);
    if (!content) {
      return null;
    }
    return JSON.parse(content);
  }

  hasUser(): boolean {
    return USER_STORE in localStorage && TOKEN_STORE in localStorage;
  }
}


export class InMemoryTokenService {
  public _token: string | null = null;
  public _user: User | null = null;
  public _service: string | null = null;

  constructor() { }

  public clear() {
    this._token = null;
    this._user = null;
    this._service = null;
  }

  get token(): string | null {
    return this._token;
  }

  get user(): User | null {
    return this._user;
  }

  get service(): string | null {
    return this._service;
  }

  set token(data: string | null) {
    this._token = data;
  }

  set user(data: User | null) {
    this._user = data;
  }

  set service(data: string | null) {
    this._service = data;
  }

  hasUser(): boolean {
    return this.token !== null && this.user !== null;
  }
}
