<form [formGroup]="form" (ngSubmit)="submitMachine()">
    <h3 mat-dialog-title>
        {{ title }}
    </h3>

    <mat-dialog-content>
        <div *ngIf="modelList ; else spinner" class="task-list">
            <mat-form-field appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput type="text" formControlName="name">
                <mat-hint>Dê um nome ao seu robô</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>UUID</mat-label>
                <input matInput type="text" formControlName="uniqueId">
                <mat-hint>Seu robô precisa de um identificador único</mat-hint>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Modelo</mat-label>
                <mat-select formControlName="model" [compareWith]="compareModel">
                    <mat-option *ngFor="let map of modelList" [value]="map">{{ map.model }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-template #spinner>
            <mat-spinner class="alabia-view-spinner"></mat-spinner>
        </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false">Cancelar</button>
        <button mat-button type="submit">Salvar</button>
    </mat-dialog-actions>
</form>