import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'alabia-signup-step3',
  templateUrl: './signup-step3.component.html',
  styleUrls: ['./signup-step3.component.scss', '../signup-step.shared.scss'],
  animations: [
    trigger('formAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('700ms ease', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateX(100%)', opacity: '0%' }))
      ])
    ]),
    trigger('infoAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('700ms ease', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('700ms ease', style({ transform: 'translateY(100%)', opacity: '0%' }))
      ])
    ]),
  ],
})
export class SignupStep3Component implements OnInit {

  public isShowing: boolean = true;
  @Input() returnData: any;
  @Output() nextScreen = new EventEmitter();
  @Output() stepData = new EventEmitter();

  public thirdStepSignup?: FormGroup;


  constructor() { }

  ngOnInit() {
    this.thirdStepSignup = this.thirdStepForm();
  }

  public screenOutputHandler(currentScreen: any, step: any) {
    this.isShowing = !this.isShowing;
    setTimeout(() => {
      this.nextScreen.emit({ currentScreen, step });
    }, 700);
  }

  thirdStepForm(): FormGroup {
    return new FormGroup({
      website: new FormControl(this.returnData['website']),
      industry: new FormControl(this.returnData['industry']),
      role: new FormControl(this.returnData['role']),
    });
  }

  onSubmit(formData: any) {
    if (formData.valid) {
      this.stepData.emit({ step: 2, organizationData: formData.value });
      this.screenOutputHandler(2, 'forward');
    }
  }

}
