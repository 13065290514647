import { Injectable } from '@angular/core';
import { Ownable, OwnableService } from '../auth/ownable.service';

export interface InformationType extends Ownable {
  name: string;
  description: string;
}


@Injectable({
  providedIn: 'root'
})
export class InformationTypeService extends OwnableService<InformationType> {
  protected path: string = `${this.config.endpoint}/customer/information/type`;
}
