import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountForm } from 'app/models/account-form';
import { AccountService } from 'app/alabia/api/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, Status } from 'onevoice';

@Component({
  selector: 'alabia-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public accountForm: AccountForm;
  public organizationForm: AccountForm;

  startScreen: number = 0;

  public screens = [
    false,
    false,
    false,
    false,
    false,
  ];

  constructor(
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthenticationService,

  ) {
    this.accountForm = {
      organization: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      username: '',
    }

    this.organizationForm = {
      website: '',
      industry: '',
      role: '',
      company_size: '',
    }

  }

  ngOnInit() {
    let currentUser = this.authService.current.value;

    if (currentUser) {
      if (currentUser.organization.status !== Status.OK) {
        this.startScreen = 2;
      } else {
        this.router.navigateByUrl('/');
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.screens[this.startScreen] = true
    }, 1000);
  }

  public handleScreenChange(event: any) {
    if (event['step'] === 'forward') {
      if (event['currentScreen'] <= 4) {
        this.screens[event['currentScreen']] = false;
        this.screens[event['currentScreen'] + 1] = true;
      }
    } else {
      if (event['currentScreen'] >= 0) {
        this.screens[event['currentScreen']] = false;
        this.screens[event['currentScreen'] - 1] = true;
      }
    }
  }

  public handleStepData(data: { [x: string]: AccountForm; }) {
    switch (data['step']) {
      case 0:
        this.accountForm = data['signupData'];
        break;
      case 1:
        this.accountForm = { ...this.accountForm, ...data['signupData'] };
        this.accountService.createAcount(this.accountForm).subscribe(
          res => this.authService
            .login(this.accountForm.username || "", this.accountForm.password || "")
            .then(
              () => this.startScreen = 2,
              err => alert("Usuário ou senha inválida!")
            ),
          err => alert('Problema ao criar usuário.')
        );
        break;
      case 2:
        this.organizationForm = data['organizationData'];
        break;
      case 3:
        this.organizationForm = { ...this.organizationForm, ...data['organizationData'] };
        this.accountService.updateOrganization(this.organizationForm).subscribe(
          res => this.router.navigateByUrl('/'),
          err => alert('Problema ao atualizar organização.')
        );
        break;
    }
  }

  private normalize(str: string) {
    return str
      .normalize('NFD')
      .trim()
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
      .replace(/\s+/g, '-')
      .toLocaleLowerCase();
  }

  get returnUrl() {
    return this.route.snapshot.queryParams['returnUrl'] || '/';
  }

}
