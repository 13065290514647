import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MediaType, UploadedMedia, UploadedMediaService } from 'onevoice';

function byPrimaryKey(curr: UploadedMedia, next: UploadedMedia): number {
  return curr.id - next.id;
}

@Component({
  selector: 'alabia-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss']
})
export class MediaDialogComponent implements OnInit {
  public available: UploadedMedia[] = [];
  public filter: MediaType = MediaType.IMAGE;
  public MediaType = MediaType;

  public pageSizeOptions = [5, 10, 20];

  public limit = this.pageSizeOptions[0];
  public offset = 0;

  constructor(
    public storage: UploadedMediaService,
    public reference: MatDialogRef<MediaDialogComponent>
  ) { }

  ngOnInit() {
    this.storage.list().subscribe(mediaList => {
      this.available = mediaList.sort(byPrimaryKey).reverse();
    });
  }

  public setPage(event: PageEvent) {
    console.log(event);
    this.limit = event.pageSize;
    this.offset = event.pageSize * event.pageIndex;
  }

  public listMedia(): UploadedMedia[] {
    return this.available.filter(media => media.type === this.filter);
  }

  public currentPage(): UploadedMedia[] {
    return this.listMedia().slice(this.offset, this.offset + this.limit);;
  }

  public getMediaTypes(): MediaType[] {
    return Object.values(MediaType);
  }

  uploadFiles(event: any) {
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.storage.upload(file).subscribe(media => {
        this.available.push(media);
      });
    }
  }

  select(media: UploadedMedia) {
    this.reference.close(media);
  }
}
