import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from 'onevoice';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthenticationService,
        private router: Router,
    ) {
    }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.current.value && this.authService.current.value.organization == null) {
            this.router.navigateByUrl('/organization');

            return false;
        }
        if (this.authService.current.value) {
            return true;
        }

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
