import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  AuthenticationService,
  MachineExecution,
  MachineExecutionService,
  User,
  UserCreateRequest,
  UserService,
} from 'onevoice';
import { MemberAddComponent } from './cleaning-member/member-add/member-add.component';
import { HttpParams } from '@angular/common/http';

export interface Tarefas {
  nome: string;
  num: number;
  itensTotal: number;
  itensLimpos: number;
  mapa: number;
  tipo: string;
  andar: number;
  local: 'Ed sede';
  area: string;
  status: string;
  duracao: string;
  prioridade: number;
  membro: string;
  descricao: string;
}

const TASK: Tarefas[] = [
  {
    nome: 'Sala de reunioes principal',
    num: 1,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Norte',
    status: 'Em andamento',
    duracao: '00:23:21',
    prioridade: 8,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Teatro',
    num: 2,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Concorrencial',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Leste',
    status: 'Concluido',
    duracao: '00:23:21',
    prioridade: 8,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Biblioteca',
    num: 3,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Concorrente',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Norte',
    status: 'Concluido',
    duracao: '00:23:21',
    prioridade: 8,
    membro: 'João da Silva Oliveira',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Depósito',
    num: 4,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Concorrente',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sul',
    status: 'Em andamento',
    duracao: '00:23:21',
    prioridade: 8,
    membro: 'Priscila Silva',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Corredor Central',
    num: 5,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Central',
    status: 'Em andamento',
    duracao: '00:20:21',
    prioridade: 5,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Teatro',
    num: 6,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sudeste',
    status: 'Em andamento',
    duracao: '00:29:21',
    prioridade: 3,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Sala de segurança',
    num: 7,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sudeste',
    status: 'Em andamento',
    duracao: '00:29:21',
    prioridade: 3,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Vestiário',
    num: 8,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sudeste',
    status: 'Em andamento',
    duracao: '00:29:21',
    prioridade: 3,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Corredor Central',
    num: 9,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sudeste',
    status: 'Em andamento',
    duracao: '00:29:21',
    prioridade: 3,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
  {
    nome: 'Depósito',
    num: 9,
    itensTotal: 15,
    itensLimpos: 5,
    mapa: 2,
    tipo: 'Terminal',
    andar: 2,
    local: 'Ed sede',
    area: 'Setor Sudeste',
    status: 'Em andamento',
    duracao: '00:29:21',
    prioridade: 3,
    membro: 'Eduardo Souza',
    descricao:
      'Limpeza do local, localizada na area nordeste do mapa .., perto da entrada principal',
  },
];

@Component({
  selector: 'cleaning-member',
  templateUrl: './cleaning-member.component.html',
  styleUrls: ['./cleaning-member.component.scss'],
})
export class CleaningMemberComponent implements OnInit {
  public tabView = 'atividades';
  public selectedMember?: User;
  public dataSource = new MatTableDataSource<User>([]);
  public dataSourceExecutions: MachineExecution[] = [];
  public dataSourceExecutionsBackup: MachineExecution[] = [];
  public dataSourceTask: MachineExecution[] = [];
  public dateParams?: Date;
  public todaytasks: number = 0;
  public todayArea: number = 0;
  public todayTime: number = 0;

  public displayedColumns: string[] = [
    'name',
    'organization',
    'status',
    'email',
    'phone',
    'actions',
  ];
  public displayedColumnsTask: string[] = [
    'num',
    'nome',
    'mapa',
    'status',
    'duracao',
  ];

  public dataView = 'list';
  today: any;

  constructor(
    private serviceUser: UserService,
    private auth: AuthenticationService,
    private machineExecutionService: MachineExecutionService,
    public dialogs: MatDialog
  ) { }

  ngOnInit(): void {
    this.auth.systemToken().then((_) => {
      this.loadUsers();
      this.loadExecutions();

    });
  }
  public dailyProgress(user: User) {

    let exec = this.dataSourceExecutions.filter(exec => exec.operator == user.details.first_name);

    this.todaytasks = exec.length

    this.todayArea = exec.reduce((acumulador, exectuion) => acumulador + exectuion.area.covered, 0);
    console.log(exec.reduce((acumulador, exectuion) => acumulador + exectuion.area.covered, 0))
    this.todayTime = exec.reduce((acumulador, exectuion) => acumulador + exectuion.duration, 0);


  }

    private loadExecutions(): void {
    this.dateParams = new Date();
    this.dateParams.setDate(this.dateParams.getDate());
    this.dateParams.setHours(0, 0, 0, 0);
    let params = new HttpParams();
    params = params.append('startDate', this.dateParams.getTime());
    this.machineExecutionService.list(params).subscribe(exec => {
      this.dataSourceExecutions = exec

    })


  }

  public dataSourceTaskbyUser(user: User) {

    this.dataSourceTask = this.dataSourceExecutions.filter(single => single.operator == user.details.first_name)

  }
  private loadUsers(): void {
    this.serviceUser.list().subscribe((users) => {
      this.dataSource.data = users;
      console.log('data source data');
      console.log(this.dataSource.data);
    });
  }

  public changeView(view: string): void {
    this.dataView = view;
    this.tabView = 'atividades';
  }

  public percentProgress(exec: MachineExecution): string {

    if (exec.area.total > 0 && exec.area.covered > 0) {
      return (exec.area.total / exec.area.covered).toString()
    }
    return "N/A"
  }


  public changeTab(event: MatTabChangeEvent): void {
    this.tabView = event.tab.textLabel;
  }

  public selectMember(member: User): void {
    this.dataSourceTaskbyUser(member);
    this.selectedMember = member;
    this.dailyProgress(member);
    this.changeView('detail');
    // console.log(this.selectedMember)
  }

  public createUser(user?: Partial<User>): void {
    let isCreate: boolean;
    isCreate = !user;
    let id = user?.id;
    if (user?.email == undefined) {
      console.log('criaaaaaaaar');
      console.log(isCreate);
    } else {
      console.log('editar');
      console.log(isCreate);
    }
    let reference = this.dialogs.open<
      MemberAddComponent,
      Partial<User>,
      UserCreateRequest
    >(MemberAddComponent, {
      width: '700px',
      maxWidth: '90%',
      data: user,
    });

    reference.afterClosed().subscribe((user) => {
      if (!user) {
        return;
      }

      if (isCreate) {
        console.log('criaaaaaaaaaaaaaaarradsfla ksjdf ')
        let userAction = () => this.serviceUser.create(user);
        userAction().subscribe((user) => {
          let data = this.dataSource.data.filter(
            (value) => value.id != user.id
          );
          data.push(user);
          data.sort((m1, m2) => m2.id - m1.id);
          this.dataSource.data = data;
        });
      } else {
        if (id)
          this.serviceUser.update(id, user).subscribe((user) => {
            let data = this.dataSource.data.filter(
              (value) => value.id != user.id
            );
            data.push(user);
            data.sort((m1, m2) => m2.id - m1.id);
            this.dataSource.data = data;
          });
      }
    });
  }

  public deleteUser(user: User) {
    this.serviceUser.delete(user).subscribe((ok) => {
      if (user && this.dataSource.data.length) {
        this.dataSource.data = this.dataSource.data.filter(
          (item) => item.id != user.id
        );
      }
    });
  }
}
