import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Machine, MachineMap, MachineMapService, MachineService, MachineTask, MachineTaskService, MapUnit, MapUnitService, Redirect, RedirectService, RedirectType } from 'onevoice';
import { EditComponent } from '../edit/edit.component';
import { QrcodeService } from '../qrcode.service';

@Component({
  selector: 'main-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public redirectList: Redirect[] = [];
  public allRedirect: Redirect[] = [];
  public filterRedirect: Redirect[] = [];
  public taskList?: MachineTask[];
  public allMachines: Machine[] = []
  public taskByUnit: MachineTask[] = [];
  public typeTask: String = ""
  public manualTasks: MachineTask[] = [];
  public taskTypes: String[] = ['MANUAL', 'MAINTENANCE']
  public unitList: MapUnit[] = []
  public allUnits: MapUnit[] = [];
  public datasource: MatTableDataSource<Redirect> = new MatTableDataSource<Redirect>();
  public RedirectType = RedirectType;

  constructor(
    public service: RedirectService,
    public mapUnitService: MapUnitService,
    private tasks: MachineTaskService,
    public manualTaskService: MachineTaskService,
    public machineMapService: MachineMapService,
    public machineService: MachineService,
    private dialog: MatDialog,
  ) { }

  @ViewChild(MatPaginator)
  set paginator(pager: MatPaginator) {
    this.datasource.paginator = pager;
  }

  public ngOnInit(): void {
    this.listManualTask();
    this.loadRedirect();
    this.listUnit();

    this.service.list().subscribe(data => {
      this.allRedirect = data;
    })
  }

  loadMachines() {
    this.machineService.list().subscribe(machines => {
      this.allMachines = machines
    })
  }

  loadRedirect() {
    this.service.list().subscribe(redirectList => {

      this.datasource.data = redirectList.reverse();
      this.datasource.connect().subscribe(list => {
        this.redirectList = list;

      });
    });
  }

  public sortRedirects(): void {
    if (this.redirectList === undefined) {
      return;
    }

    this.datasource.data = this.datasource.data.sort((a, b) => b.id - a.id);
  }
  public listUnit() {
    this.mapUnitService.list().subscribe(unitsList => {
      this.unitList = unitsList;

    })

  }

  listManualTask() {

    this.manualTaskService.listManual().subscribe(list => {
      this.manualTasks = list
      console.log(list)
    })
  }

  filterByTypeTask(type: String) {
    this.typeTask = type
    let listRedirect: any = []
    this.manualTaskService.list().subscribe(tasks => {
      this.manualTasks = tasks.filter(task => task.type == type)
      this.manualTasks.forEach(task => {
        this.service.list().subscribe(redirectList => {
          redirectList.forEach(redirect => {
            if (redirect.destination == task.id) {
              listRedirect.push(redirect)
            }
          })
          this.datasource.data = listRedirect
        })

      })
    })
    if (type == "all") {
      this.loadRedirect()
    }
  }

  selectUnit(event: String) {

    this.service.list().subscribe(redirectList => {
      this.datasource.data = redirectList.reverse();
      this.datasource.connect().subscribe(list => {
        this.redirectList = list;

      });

      if (event == "all") {
        this.filterByTypeTask(this.typeTask)
      }

      this.filterRedirect = [];
      let taskFilter: MachineTask[] = [];
      this.manualTasks.forEach(tasks => {
        if (tasks.map?.unit?.id == Number(event)) {
          taskFilter.push(tasks)
        }
      })

      taskFilter.forEach(task => {
        this.datasource.data.forEach(redirect => {
          if (task.id == redirect.destination)
            this.filterRedirect.push(redirect)
        })
      })
      this.datasource.data = this.filterRedirect;

    });
  }

  public editRedirect(redirect: Partial<Redirect> = {}) {
    let reference = this.dialog.open<EditComponent, Partial<Redirect>, Partial<Redirect>>(EditComponent, {
      width: "700px",
      maxWidth: "90%",
      data: redirect,
    });

    reference.afterClosed().subscribe(redirect => {
      if (redirect === undefined) {
        return;
      }

      if (redirect.id) {
        this.service.update(redirect).subscribe(updated => {
          if (this.datasource.data === undefined) {
            return;
          }

          this.datasource.data = this.datasource.data.filter(value => value.id != redirect.id);
          this.datasource.data.push(updated);
          this.sortRedirects();
        });
      } else {
        this.service.create(redirect).subscribe(created => {
          if (this.datasource.data === undefined) {
            return;
          }
          this.datasource.data.push(created);
          this.sortRedirects();
        });
      }
    });

  }

  public selected: Redirect[] = [];

  public isSelected(redirect: Redirect): boolean {
    return this.selected.findIndex(value => value.id == redirect.id) >= 0;
  }

  public selectRedirect(redirect: Redirect, event: MatCheckboxChange): void {
    console.log(event);
    if (event.checked) {
      this.selected.push(redirect);
    } else {
      this.selected = this.selected.filter(value => value.id !== redirect.id);
    }
  }

  public selectAll() {
    this.selected = this.datasource.data;
  }
}
