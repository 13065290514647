import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Machine, MachineMap, MachineMapService, MachineScheduler, MachineTask, MachineTaskService, TimedTask, User, UserService, WeekRepete, MapType, TimedTasksService, MachineSchedulerService } from 'onevoice';
import { Observable } from 'rxjs';

import { map, startWith } from 'rxjs/operators';


class WeekRepeteControl extends FormControl {
  public setValue(value: any, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }): void {
    console.log("set", value, options);
    let repete: WeekRepete = {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    };

    let update: { [key: string]: boolean } = {}

    for (const part of value) {
      update[part] = true;
    }
    super.setValue({
      ...repete,
      ...update,
    }, options);
  }

  public patchValue(value: any, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean;
  }): void {
    console.log("patch", value, options);
    super.patchValue(value, options);
  }

}

@Component({
  selector: 'main-schedule-edit',
  templateUrl: './schedule-edit.component.html',
  styleUrls: ['./schedule-edit.component.scss']
})
export class ScheduleEditComponent implements OnInit {

  public form: FormGroup;
  public days: number[] = [];
  public taskList: MachineTask[] = [];
  public datamap: MachineMap[] = []
  public value: string = ''
  public tasksSelected: TimedTask[] = []
  public timedTask: TimedTask = {
    loopCount: 0,
    notifiedTimestamp: 0,
    machine: {
      model: {
        maker: '',
        model: '',
        mapType: MapType.UNKNOWN,
        id: 9999999999999
      },
      name: '',
      nickName: '',
      uniqueId: '',
      location: {
        name: '',
        description: '',
        uniqueId: '',
        id: 999999999999999999999999999
      },
      maps: [],
      id: 0
    },
    repeat: [],
    startTime: '',
    machineTask: {
      id: 0,
      map: {
        title: '',
        uniqueId: '',
        path: '',
        type: 'string',
        unit: {
          name: 'string',
          description: 'string',
          uniqueId: 'string',
          id: 0
        },
        id: 0
      },
      name: '',
      uniqueId: '',
      description: '',
      type:'',
    },
    id: 999999
  };

  public machineTask! : MachineTask ;

  public weekDays: number[] = []
  public textWeekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  public startTime : string = '';
  filteredOptions: Observable<string[]> | undefined;
  myControl = new FormControl('');
  options: string[] = [];
  option: string = '';
  usersList: User[] = [];
  mapList: MachineMap[] = [];
  selectedDay: string = ''; // Inicialize como uma string vazia

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Partial<MachineScheduler>,
    public reference: MatDialogRef<ScheduleEditComponent>,
    public users: UserService,
    private tasks: MachineTaskService,
    private mapService: MachineMapService,
    private machineSchedulerService : MachineSchedulerService,
    private timedTaskService : TimedTasksService,
  ) {
    console.log(this.data)
    this.form = new FormGroup({
      "date": new FormControl(this.data.date || '', [Validators.required]),
      "id": new FormControl(this.data.id || '', [Validators.required]),
      "user": new FormControl(this.data.user || '', [Validators.required]),
      "startTime": new FormControl(this.data.startTime || '', [Validators.required]),
      "timedTaskList": new FormControl(this.data.timedTaskList || [], [Validators.required]),
    }, []);
    console.log(this.form.value)

  }
  ngOnInit(): void {
    this.users.list().subscribe(users => {

      users.forEach(user=>{
        user.authorities.forEach(authorization=>{
          if(authorization.authorization == "CLEANER"){
            this.usersList.push(user);
          }
        })
      console.log(this.usersList)
    })
  })
    this.mapService.list().subscribe(list => {
      list.forEach(map =>{
        if(map.type == 'MANUAL'){
        this.mapList.push(map);
        }
      })

    })

    this.tasksSelected = this.form.value.timedTaskList

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

  }
  public userName(){
   return  this.isEdit ? this.data.user?.details.first_name + ' ' + this.data.user?.details.last_name: 'Não definido';
  }

  public selectUser(user : User){
    this.data.user = user;
    this.form.value.user = user;
  }
  addTimedTask(){

    this.timedTask.startTime = this.form.value.startTime
    this.timedTask.repeat = this.days;
    this.timedTask.machineTask = this.machineTask;
    let tasks : TimedTask[] = this.tasksSelected;
    this.timedTaskService.create(this.timedTask).subscribe(created =>{
      tasks.push(created)
    })
    this.data.timedTaskList = tasks;
    this.form.value.timedTaskList = tasks
    this.tasksSelected = tasks;
  }

  selectTask(machineTask : MachineTask){
    this.machineTask = machineTask;

    console.log(this.machineTask)
  }
  textDay(day : number[]){
    let listOfDays = ['']
    day.forEach(single=>{
      listOfDays.push(this.textWeekDays[single-1])
    })
    return listOfDays;

  }

  public selectMap(map: MachineMap) {

    console.log(map)

  }
  insertDay(day: number): void {
    if (this.days.includes(day)) {
      this.days = this.days.filter(single => single !== day)
    } else {
      this.days.push(day)
    }
    this.days.sort((a, b) => a - b);

    console.log(this.days)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let filtered = this.options.filter(option => option.toLowerCase().includes(filterValue));

    this.getMap(filterValue)

    return filtered;
  }

  getTasks(map: MachineMap): void {
    this.tasks.listByMap(map).subscribe(list => {

      list.forEach(single =>{
        if(single.type =='MANUAL'){
          this.taskList.push(single)
        }
      })
      console.log(this.taskList)
    })
  }

  addTask(singleTask: TimedTask) {
    this.tasksSelected.push(singleTask)
    this.form.value.timedTaskList = this.tasksSelected

  }
  deleteFromSelecetedList(singleTask: TimedTask) {
    this,this.timedTaskService.delete(singleTask.id).subscribe(teste=>{
    })
    this.tasksSelected = this.tasksSelected.filter(filter => filter.id != singleTask.id)
    this.data.timedTaskList = this.tasksSelected;
    this.form.value.timedTaskList = this.tasksSelected;
  }

  getMap(mapName: String): void {
    this.datamap.forEach(map => {
      if (map.title == mapName) {
        console.log(map)
        this.getTasks(map);
      }
    })
  }

  get isEdit() {
    this.form.value.user = this.data.user;
    return this.data.id !== undefined;
  }

  public ngSubmit() {
    console.log(this.form.value)
    console.log(this.data)
    this.reference.close({

      ...this.data,
      ...this.form.value,
    });
  }

  compareWith(task1: MachineTask, task2: MachineTask) {
    return task1.id === task2.id;
  }

}
