import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, APP_CONFIG } from '../api-configuration';
import { TTSHistory } from './ttsresponse.model';
import { Voice } from './voice.model';

@Injectable({
  providedIn: 'root'
})
export class TTSService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) protected config: ApiConfiguration) { }

  generate(model: string, content: string): Observable<Blob> {
    return this.http.get(`${this.config.mediaservice}/audio/tts`, {
      params: {
        'model': model,
        'content': content,
      },
      responseType: 'blob',
    });
  }

  generateVoice(voice: Voice, content: string): Observable<Blob> {
    return this.http.get(`${this.config.mediaservice}/audio/tts`, {
      params: {
        'voice': voice.id,
        'content': content,
      },
      responseType: 'blob',
    });

  }

  voices(): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.mediaservice}/audio/tts/voices`);
  }

  list(): Observable<TTSHistory[]> {
    return this.http.get<TTSHistory[]>(`${this.config.mediaservice}/tts`, {});
  }

  delete(id: number): Observable<Boolean> {
    return this.http.delete<Boolean>(`${this.config.mediaservice}/tts/${id}`, {});
  }
}
