import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { AgentResponse, AgentResponseService, Bot } from 'onevoice';

function checkContent(full: string, part: string): boolean {
  return full.toLowerCase().includes(part.toLowerCase())
}

@Component({
  selector: 'main-response-selector',
  templateUrl: './response-selector.component.html',
  styleUrls: ['./response-selector.component.scss']
})
export class ResponseSelectorComponent implements OnInit {
  public responseList: AgentResponse[] = [];
  public filterString: string = "";

  public hideItem(response: AgentResponse): boolean {
    if (response.bot && this.bot && response.bot.id !== this.bot.id) {
      return true;
    }
    if (!checkContent(response.title, this.filterString) && !checkContent(response.content, this.filterString)) {
      return true;
    }
    return false;
  }

  constructor(
    responses: AgentResponseService,
    private reference: MatDialogRef<ResponseSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public bot?: Bot,
  ) {
    responses.list().subscribe(list => {
      this.responseList = list;
    });
  }

  ngOnInit(): void {
  }

  public selectOptions(selection: MatSelectionList) {
    this.reference.close(selection.selectedOptions.selected.map(value => value.value));
  }
}
