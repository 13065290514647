<h1 mat-dialog-title>{{ isCreate ? 'Criar Voz' : 'Renomear Voz' }}</h1>

<mat-dialog-content>
    <mat-form-field>
        <input matInput type="text" [(ngModel)]="data.name" placeholder="Nome da Voz">
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data">{{ isCreate ? 'Criar' : 'Editar' }}</button>
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
</mat-dialog-actions>