import { Type } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { ChannelType } from "onevoice";

export class FormatControl<T> {
    constructor(
        public defaultValut: T,
        public validators: ValidatorFn[],
        public choices?: { [text: string]: T },
    ) { }

    public control(initial: T): AbstractControl {
        return new FormControl(initial, this.validators);
    }

    public checkType(value: any): boolean {
        return typeof value === typeof this.defaultValut;
    }
}

export class FormatListField<T> {
    constructor(
        public maxsize: number,
        public controls: FormatField<any>[],
    ) { }

    public validate(data: any[]) {
        return data.length <= this.maxsize;
    }

    public control(initial: T[]): AbstractControl {
        let forms: { [key: string]: AbstractControl } = {}
        initial.forEach((data, index) => {
            forms[index] = createForm(data, this.controls);
        });
        return new FormGroup(forms);
    }
}

export class FormatField<T> {
    constructor(
        public name: string,
        public value: FormatControl<T> | FormatField<any>[] | FormatListField<any>,
    ) { }

    public control(initial: any): AbstractControl {
        if (this.value instanceof FormatControl) {
            return this.value.control(initial);
        } else if (this.value instanceof FormatListField) {
            return this.value.control(initial);
        }
        return createForm(initial, this.value)
    }
}

function createForm(data: any, fields: FormatField<any>[]): AbstractControl {
    let forms: { [key: string]: AbstractControl } = {}
    for (const field of fields) {
        if (Array.isArray(field.value)) {
            forms[field.name] = createForm(data[field.name], field.value);
        } else {
            forms[field.name] = field.value.control(data[field.name]);
        }
    }
    return new FormGroup(forms);
}

export class Format<T> {
    constructor(
        public name: string,
        public channel: ChannelType,
        public fields: FormatField<any>[],
        public component: Type<any>
    ) { }

    private validateField(data: any, field: FormatField<any>): boolean {
        let value = data[field.name];
        if (field.value instanceof FormatControl) {
            let control = field.value.control(value);
            if (!control.valid || !field.value.checkType(value)) {
                return false
            }
        } else if (field.value instanceof FormatListField) {
            if (!Array.isArray(value) || !field.value.validate(value)) {
                return false;
            }
            for (const each of value) {
                if (!this.validateList(field.value.controls, each)) {
                    return false;
                }
            }
        } else if (Array.isArray(field.value)) {
            for (const inner of field.value) {
                if (!this.validateField(value, inner)) {
                    return false;
                }
            }
        }
        return true
    }

    private validateList(fields: FormatField<any>[], data: T): boolean {
        for (const field of fields) {
            console.debug(`Validating: ${field.name}`);
            if (!this.validateField(data, field)) {
                return false;
            }
        }
        return true;
    }

    public validate(content: string): boolean {
        try {
            let data = JSON.parse(content) as T;
            return this.validateList(this.fields, data);
        }
        catch (error) {
            return false;
        }
    }

    public control(data: T): AbstractControl {
        return createForm(data, this.fields);
    }
}
