// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const mainURL = 'https://staging-api.alabiabot.com';

export const PushPublic = "BM7NBRLcetkyy3mWaoTOQY7173WwKYA-5yYRScr_IDghYT9tAYJ0kWkSd4F42Tgiq93Q4bnlKbs8pFsAX1rpmMs";

export const environment = {
    production: false,
    hmr: false,

    // OneVoice system
    iam: `${mainURL}/onevoice`,
    main: `${mainURL}/onevoice`,
    busService: `${mainURL}/onevoice`,
    coreService: `${mainURL}/onevoice`,
    fbService: `${mainURL}/onevoice`,
    conversationService: `${mainURL}/onevoice/alabia-conversation-service`,

    // Angular
    chatSimulator: 'https://staging-chat.alabiabot.com/#/chat/',

    // Middleware
    sttService: 'https://staging-api.alabiabot.com/media',
    biometrics: `https://staging-api.alabiabot.com/biometrics`,

    // Python
    corpusService: 'https://staging-api.alabiabot.com/corpus',
    trainingService: 'https://staging-api.alabiabot.com/training',
    staticBaseURL: "https://static.example.com",
    onevoiceStaticUrl: "https://static.example.com",
    mediaStorage: "https://alabia-mediaservice-stg.s3.sa-east-1.amazonaws.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
