import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, ExecutionStatus, ProjectExecution, ProjectExecutionService, SpeechProject } from 'onevoice';

@Component({
  selector: 'main-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  public executions?: ProjectExecution[];
  public datasource: MatTableDataSource<ProjectExecution> = new MatTableDataSource<ProjectExecution>([]);

  public columns = [
    "icons",
    "status",
    "date",
    "link",
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SpeechProject,
    public service: ProjectExecutionService,
    public auth: AuthenticationService,
  ) {
    this.service.list().subscribe(list => {
      this.executions = list.filter(value => value.project.id == data.id);
      this.datasource.data = this.executions.reverse();
    });
  }

  ngOnInit(): void {
  }

  public getMessage(status: ExecutionStatus): string {
    switch (status) {
      case ExecutionStatus.REQUESTED:
        return "Geração de texto entrou para a fila de execução";

      case ExecutionStatus.PROCESSING:
        return "Geração de texto ainda está sendo processada";

      case ExecutionStatus.DONE:
        return "Geração de texto concluida com sucesso";
      case ExecutionStatus.FAILED:
      default:
        return "Geração de texto falhou";
    }
  }

  public URL(execution: ProjectExecution) {
    return this.auth.authMediaUrl(
      this.service.downloadUrl(execution),
    );
  }

  public zipFilename(execution: ProjectExecution): string {
    return `projeto.zip`;
  }
}
