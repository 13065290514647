import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { environment } from 'environments/environment';
import { TTSHistory, TTSService } from 'onevoice';

@Component({
  selector: 'tts-list',
  templateUrl: './tts-list.component.html',
  styleUrls: ['./tts-list.component.scss']
})
export class TtsListComponent {
  public baseURL: string = environment.staticBaseURL;
  public datasource = new MatTableDataSource<TTSHistory>([]);
  public INTERACTIVE_DELETE: boolean = true;


  @Input()
  set data(data: TTSHistory[]) {
    this.datasource.data = data;
  }

  @Output() eventEmitter = new EventEmitter();

  @ViewChild(MatPaginator, { static: false })
  set pagination(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }
  @ViewChild(MatSort)
  set sorter(sort: MatSort) {
    this.datasource.sort = sort;
  }


  public columnsToDisplay: string[] = [
    "id", "voice-model", "text", "duration", "download", "delete"
  ]


  constructor(
    private service: TTSService,
    private snack: MatSnackBar
  ) { }

  public changeView(): void {
    this.eventEmitter.emit();
  }

  deleteItem(item: TTSHistory): void {
    if (this.INTERACTIVE_DELETE) {
      this.snack.open("Tem certeza?", "Sim").onAction().subscribe(_ => {
        this.service.delete(item.id).subscribe(ok => {
          if (ok) {
            this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
          }
        });
      });
    } else {
      this.service.delete(item.id).subscribe(ok => {
        if (ok) {
          this.datasource.data = this.datasource.data.filter(value => value.id !== item.id);
        }
      });
    }
  }

  public getVoiceName(item: TTSHistory): string {
    if (item.voice != null) {
      return item.voice.title;
    }
    return item.model;
  }

  public getHistoryOrder(item: TTSHistory): number {
    return this.datasource.data.length - this.datasource.data.indexOf(item);
  }
}
