import { User } from "../auth/user.model";
import { Customer } from "../crm/customer.service";
import { Bot } from "../dialog/bot.model";
import { Intent } from "../dialog/intent.service";
import { ConclusionTag } from "./conclusion-tag.model";
import { Queue } from "./queue.model";

export enum ChangeType {
    OPEN = "OPEN",
    WIP = "WIP",
    DONE = "DONE",
    REFERRED = "REFERRED",
    REPLIED = "REPLIED",
}


export interface QueueChange {
    conversation: number;
    agent: number;
    queue: number;
    assignment: number;

    tag?: number;
    description?: string;

    type: ChangeType;
}

export interface QueueAssignment {
    id: number;
    queue: Queue;
    conversation: number;
    customer: Customer;
    interaction_id: number;
    agent?: User;
    description: string;
    status: ChangeType;
    protocol_id: string;

    tag?: ConclusionTag;

    created: number;
    update: number;
    answered?: number;

    bot: Bot;
    intent?: Intent;
}
