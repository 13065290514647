import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';


import { AuthenticationService, ExecutionStatus, Mood, MoodService, ProjectExecution, ProjectExecutionService, ProjectService, ScriptLine, ScriptLineService, SpeechProject, Voice, VoiceService } from 'onevoice';
import { DownloadComponent } from '../download/download.component';
import { ProjectCreateComponent } from '../project-create/project-create.component';
import { ScriptEditComponent } from '../script-edit/script-edit.component';

function ByOrder(s1: ScriptLine, s2: ScriptLine) {
  let orderDiff = s1.order - s2.order;
  if (orderDiff != 0) {
    return orderDiff;
  }
  // Must make sure there is a defined order  
  return s1.id - s2.id;
}

function ByID(p1: SpeechProject, p2: SpeechProject) {
  return p2.id - p1.id;
}

@Component({
  selector: 'main-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  public icon: string = "mic";
  public project?: SpeechProject;
  public script?: MatTableDataSource<ScriptLine>;
  public datasource = new MatTableDataSource<SpeechProject>([]);
  public moodSizeID: Number[] = [];
  public voiceSizeID: Number[] = [];
  public actionView: boolean = true;
  public actionSize: string = '200px';
  public charCount: string = '';
  public allCharCount: number = 0;
  public iconText: boolean = true;
  public iconHistory: boolean = false;



  @ViewChild("textInput")
  private textInput?: ElementRef<HTMLInputElement>;

  @ViewChild('formDirective')
  private formDirective!: NgForm;


  public createForm: FormGroup = new FormGroup({
    "order": new FormControl(1, [Validators.required, Validators.min(1)]),
    "mood": new FormControl(undefined, [Validators.required]),
    "voice": new FormControl(undefined, [Validators.required]),
    "text": new FormControl("", [Validators.required, Validators.minLength(1)]),
  });

  public scriptColumns: string[] = [
    "order",
    "text",
    "mood",
    "voice",
    "actions",
  ];

  public moodList: Mood[] = [];
  public voiceList: Voice[] = [];
  public executionList: ProjectExecution[] = [];

  public interval?: NodeJS.Timeout;

  constructor(
    private route: ActivatedRoute,
    public service: ProjectService,
    public scriptService: ScriptLineService,
    public moodService: MoodService,
    public voiceService: VoiceService,
    private execution: ProjectExecutionService,
    private dialogs: MatDialog,
    private auth: AuthenticationService
  ) {
    this.auth.systemToken().then(_ => {
      this.moodService.list().subscribe(moodList => {this.moodList = moodList});
      this.voiceService.list().subscribe(voiceList => this.voiceList = voiceList);
    });
  }

  get title(): string {
    if (this.project) {
      return this.project.name;
    }
    return "Projetos";
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(value => {
      let id = value.get("id");
      this.service.read(Number(id)).subscribe(project => {
        this.project = project;
        this.retrieveExecutions(project);
        this.scriptService.list(this.project).subscribe(scriptList => {
          this.script = new MatTableDataSource<ScriptLine>(scriptList.sort(ByOrder));
          this.generateInformation();
          for (let index = 0; index < this.script.data.length; index++) {
            this.allCharCount += this.script.data[index].text.length
          }
        });
      });
    });

    this.interval = setInterval(() => {
      if (this.project) {
        this.retrieveExecutions(this.project);
      }
    }, 120000);
  }

  private retrieveExecutions(project: SpeechProject) {
    this.execution.list().subscribe(executionList => {
      this.executionList = executionList.filter(
        value => value.project.id === project.id,
      );
    });
  }

  public ngOnDestroy(): void {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }


  public createScriptLine() {
    if (this.script === undefined || this.project === undefined) { return }
    let script = this.script;
    let order = 1;
    this.charCount = '';

    if (script.data.length > 0) {
      order = Math.max(... this.script.data.map(value => value.order)) + 1;
    }
    this.createForm.setValue({
      ...this.createForm.value,
      "order": order,
    });
    this.scriptService.create(this.project, this.createForm.value).subscribe(created => {
      script.data = script.data.concat([created]).sort(ByOrder);
      this.generateInformation();
      this.createForm.reset();
      this.textInput?.nativeElement.focus();
      this.formDirective.resetForm();
    });
  }

  public changeOrder(line: ScriptLine, delta: number): void {
    let size = Number(this.script?.filteredData.length);
    let pos = Number(line.order - 1) + delta;

    if (this.script === undefined || line.order + delta == 0 || line.order + delta > size) {
      return;
    }

    // let script = this.script;
    line.order = line.order + delta;
    this.updateScript(line);
    // this.scriptService.update(line).subscribe(updated => {
    //   script.data = script.data.sort(ByOrder);
    // });
    // this.scriptService.create(line.project, { ...line }).subscribe(updated => {
    //   script.data = script.data.sort(ByOrder);
    // });

    // let line2: ScriptLine
    line = this.script.filteredData[pos];
    line.order = line.order - delta;
    this.updateScript(line);
    // if (this.script) {
    //   line2 = this.script.filteredData[pos];
    //   line2.order = line2.order - delta;
    //   this.scriptService.update(line2).subscribe(updated => {
    //     script.data = script.data.sort(ByOrder);
    //   });

      // this.scriptService.create(line2.project, { ...line2 }).subscribe(updated => {
      //   script.data = script.data.sort(ByOrder);
      // });
    // }
  }

  public updateScript(line: ScriptLine) {
      this.scriptService.update(line).subscribe(updated => {
        if(this.script) {
          this.script.data = this.script.data.sort(ByOrder);
        }
      });
  }

  public removeScriptLine(line: ScriptLine) {
    var order = line.order;
    this.scriptService.delete(line).subscribe(ok => {
      if (this.script) {
        this.script.data = this.script.data.filter(item => item.id != line.id);
        this.generateInformation();
        if (this.script.data.length >= order) {
          for (let i = order - 1; i < this.script.data.length; i++) {
            var projectUpdate = this.script.data[i];
            projectUpdate.order = i + 1;
            this.scriptService.create(projectUpdate.project, { ...projectUpdate }).subscribe(updated => {
            });
          }
        }
      }
    });
  }

  public generateVoiceProject() {
    if (this.project) {
      this.execution.create({
        project: this.project,
      }).subscribe(created => { this.executionList.push(created); }
      );
    }
  }

  public generateInformation(): void {
    if (this.script?.data) {
      this.moodSizeID = [];
      this.voiceSizeID = [];
      this.script.data.map(e => {
        if (this.moodSizeID.indexOf(e.mood.id) == -1) {
          this.moodSizeID.push(e.mood.id);
        }
        if (this.voiceSizeID.indexOf(e.voice.id) == -1) {
          this.voiceSizeID.push(e.voice.id);
        }
      });
    }
  }

  public openDownloads(project: SpeechProject): void {
    let reference = this.dialogs.open(DownloadComponent, {
      width: "95%",
      maxWidth: "800px",
      data: project
    });
  }

  get processing(): boolean {
    return this.executionList.filter(
      value => value.status == ExecutionStatus.REQUESTED || value.status == ExecutionStatus.PROCESSING,
    ).length > 0;
  }

  public editProject(): void {
    let reference = this.dialogs.open<ProjectCreateComponent, Partial<SpeechProject>, SpeechProject>(ProjectCreateComponent, {
      width: '500px',
      maxWidth: '95%',
      data: this.project
    });
    reference.afterClosed().subscribe((data: SpeechProject | undefined) => {
      if (data) {
        this.service.update(data).subscribe(updated => {
          this.project = updated;
        });
      }
    });
  }

  public editScript(item: ScriptLine): void {
    let reference = this.dialogs.open<ScriptEditComponent, Partial<ScriptLine>, ScriptLine>(ScriptEditComponent, {
      width: '500px',
      maxWidth: '95%',
      data: item
    })
    reference.afterClosed().subscribe((data: ScriptLine | undefined) => {
      if (data) {
        this.scriptService.update(data).subscribe(updated => {
          if (this.script) {
            this.script.data = this.script.data.filter(
              value => value.id !== updated.id).concat([updated]);
            this.script.data = this.script.data.sort(ByOrder);
          }
        });
      }
    });
  }

  public copyScript(item: ScriptLine): void {
    if (this.script) {
      this.createForm.setValue({
        "order": this.script.data.length + 1,
        "mood": item.mood,
        "voice": item.voice,
        "text": item.text,
      });
    }
  }

  public actionsView(): void {
    if (this.actionView) {
      this.actionView = !this.actionView;
      this.actionSize = "96px";
    } else {
      this.actionView = !this.actionView;
      this.actionSize = "200px";
    }
  }

  public textChange(): void {
    if (this.createForm.get('text')?.value) {
      this.charCount = this.createForm.get('text')?.value;
    } else {
      this.charCount = '';
    }
  }

  public compareWith(o1: { id: number }, o2: { id: number }) {
    if(o1 != null && o2 != null) {
      return o1.id == o2.id;
    } else {
      return false;
    }
  }

  public iconChange(groupValue: any[]){
    console.log(groupValue)
    if ( groupValue.length == 0) {
      this.iconText = false;
      this.iconHistory = false; 
    }
    else if( groupValue.length == 2 ){
      this.iconText = true;
      this.iconHistory = true; 
    }
    else{
      if(groupValue[0] == 'iconText') {
        this.iconText = true;
        this.iconHistory = false;
      } else {
        this.iconText = false;
        this.iconHistory = true;
      }
    }
  }
}
