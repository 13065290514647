import { TagCreateComponent } from '@alabia/chat/tag-create/tag-create.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConclusionTag, ConclusionTagService } from 'onevoice';

function byId(tag1: ConclusionTag, tag2: ConclusionTag): number {
  return tag2.id - tag1.id;
}

@Component({
  selector: 'main-tag-list-dialog',
  templateUrl: './tag-list-dialog.component.html',
  styleUrls: ['./tag-list-dialog.component.scss']
})
export class TagListDialogComponent implements OnInit {
  public datasource: MatTableDataSource<ConclusionTag> = new MatTableDataSource<ConclusionTag>([]);

  @ViewChild(MatPaginator, { static: false })
  set addPaginator(paginator: MatPaginator) {
    this.datasource.paginator = paginator;
  }

  public fields: string[] = [
    "title",
    "description",
    "edit",
    "delete",
  ];


  constructor(
    private dialog: MatDialog,
    private tags: ConclusionTagService,
  ) { }

  public ngOnInit(): void {
    this.tags.list().subscribe(list => {
      this.datasource.data = list.sort(byId);
    });
  }

  get length() {
    return this.datasource.data.length;
  }

  public openTagDialog(data: Partial<ConclusionTag> = { archived: false }): void {
    let reference = this.dialog.open<TagCreateComponent, Partial<ConclusionTag>, ConclusionTag>(TagCreateComponent, {
      width: "500px",
      maxWidth: "80%",
      data: data,
    });

    reference.afterClosed().subscribe(tag => {
      if (tag) {
        this.datasource.data = this.datasource.data.filter(value => !data.id || value.id != data.id).concat(tag).sort(byId);
      }
    })
  }

  public removeTag(tag: ConclusionTag): void {
    this.tags.delete(tag.id).subscribe(_ => {
      this.datasource.data = this.datasource.data.filter(value => tag.id && value.id != tag.id).sort(byId);
    });
  }
}
