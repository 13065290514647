<div id="alabia-voice-content-inner" class="page-layout carded fullwidth inner-scroll">
  <div class="header accent p-24">
    <mat-icon>{{ icon }}</mat-icon>
    <h1 class="logo-text">{{ title }}</h1>
  </div>

  <div class="content m-24">
    <div class="center p-24 pt-0 pb-56" fusePerfectScrollbar>
      <table class="voice-content-list" mat-table [dataSource]="datasource">
        <ng-container matColumnDef="id">
          <th class="voice-content-id" mat-header-cell *matHeaderCellDef>ID</th>
          <td class="voice-content-id" mat-cell *matCellDef="let item">{{ item.id }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th class="voice-content-title" mat-header-cell *matHeaderCellDef>Nome</th>
          <td class="voice-content-title" mat-cell *matCellDef="let item">{{ item.title }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th class="voice-content-action-edit" mat-header-cell *matHeaderCellDef>Ações</th>
          <td class="voice-content-action-edit" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="editVoice(item)">
              <mat-icon>edit</mat-icon>
            </button>

            <button mat-icon-button [routerLink]="[item.id]">
              <mat-icon>playlist_play</mat-icon>
            </button>

            <button mat-icon-button [routerLink]="[item.id, 'split']">
              <mat-icon>add</mat-icon>
            </button>
          </td>
        </ng-container>



        <ng-container matColumnDef="delete">
          <th class="voice-content-action-delete" mat-header-cell *matHeaderCellDef></th>
          <td class="voice-content-action-delete" mat-cell *matCellDef="let item">
            <button mat-icon-button (click)="deleteItem(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let item; columns: columnsToDisplay"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="20" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>

<button class="alabia-voice-content-add" mat-fab (click)="createVoice()">
  <mat-icon>add</mat-icon>
</button>