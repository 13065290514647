<div class="create-component">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title" style="padding: 24px; width: 90vw">
        {{ isCreate ? 'Novo membro' : 'Editar membro'}}
      </span>
      <button
        mat-icon-button
        aria-label="Close dialog"
        [mat-dialog-close]="false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <form [formGroup]="form" (ngSubmit)="submitUser()">
    <mat-dialog-content>
      <div class="user-list">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="first_name" />
        </mat-form-field>

        <div style="width: 15px"></div>

        <mat-form-field appearance="outline">
          <mat-label>Sobrenome</mat-label>
          <input matInput type="text" formControlName="last_name" />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Telefone</mat-label>
        <input matInput type="text" formControlName="phone"/>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput type="text" formControlName="email" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Senha</mat-label>
        <input matInput type="password" formControlName="password" />
      </mat-form-field>

      <ng-template #spinner>
        <mat-spinner class="alabia-view-spinner"></mat-spinner>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button type="submit" [disabled]="!form.valid">
        {{ isCreate ? 'Criar' : 'Salvar'}}
      </button>
      <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    </mat-dialog-actions>
  </form>
</div>
