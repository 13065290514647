<div *ngIf="redirect !== undefined" class="redirect-content">
  <div class="current-qrcode">
    <a [href]="service.getRedirectUrl(redirect)" aria-label="Clique para seguir o redirecionamento"
      title="Clique para seguir o redirecionamento">
      <qr-code [value]="service.getPublicUrl(redirect)" size="400" centerImageSize="80" darkColor="#333333"
        lightColor="#ffffff" errorCorrectionLevel="M" centerImageSrc="./assets/alabia/logo-200px.png" [margin]="2"
        class="qrcode-wrapper">
      </qr-code>
    </a>
  </div>

  <ng-container [ngSwitch]="redirect.type">
    <div *ngSwitchCase="RedirectType.MACHINE_TASK">
      Código QR de tarefa
    </div>
    <div *ngSwitchCase="RedirectType.MAINTENANCE_TASK">
      Código QR de tarefa
    </div>
    <div *ngSwitchCase="RedirectType.MANUAL_TASK">
      Código QR de tarefa
    </div>
    <div *ngSwitchCase="RedirectType.UNDEFINED">
      Código QR não definido.
    </div>

    <div *ngSwitchDefault>
      Tipo desconhecido de redirect.
    </div>
  </ng-container>

  <button class="qrcode-list-fab" mat-fab (click)="editRedirect(redirect)">
    <mat-icon>edit</mat-icon>
  </button>
</div>
