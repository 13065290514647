import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel } from './channel.model';
import { ChannelService } from './channel.service';


export interface YoutubeChannel extends Channel {
  token: boolean;
  channelId: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class YoutubeChannelService extends ChannelService<YoutubeChannel> {
  protected path: string = `${this.config.endpoint}/channel/youtube`;

  public start(): Observable<string> {
    return this.http.get(`${this.path}/wizard`, {
      responseType: 'text'
    });
  }

  public finish(bot: number, code: string): Observable<YoutubeChannel[]> {
    let data: FormData = new FormData();

    data.set("code", code);
    data.set("botId", `${bot}`);

    return this.http.post<YoutubeChannel[]>(`${this.path}/wizard`, data);
  }
}
